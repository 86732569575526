import { sortIcon } from '../../SortIcon';
import { Action, TableHeaderState } from './types';

export const tableHeaderReducer = (state: TableHeaderState, action: Action): TableHeaderState => {
  switch (action.type) {
    case 'sortColumnChange': {
      const sortDirection =
        state.sortColumn === action.sortColumn && state.sortDirection === 'asc' ? 'desc' : 'asc';
      return {
        ...state,
        sortColumn: action.sortColumn,
        sortDirection,
        sortIcon: sortIcon(sortDirection),
      };
    }
    case 'restoreCurriculumsReportDefault': {
      if (!action.sortColumn || !action.sortDirection) return state;
      return {
        sortColumn: action.sortColumn,
        sortDirection: action.sortDirection,
        sortIcon: sortIcon(action.sortDirection),
      };
    }
  }
};
