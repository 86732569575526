import React from 'react';

import { getBadgeTypeByVerifyContentStatus } from '../../../../../../lib/all_curriculums_report/getBadgeTypeByVerifyContentStatus';
import initTranslations from '../../../../../../lib/initTranslations';
import Badge from '../../../../../design_system/display/badge';
import { ColumnComponentProps } from '../types';

const t = initTranslations('reports.curriculums_report_table.columns.verify_content_statuses');

const VerifyContentStatus = ({
  record: { verify_content_status: verifyContentStatus },
}: ColumnComponentProps) => {
  return (
    <Badge
      size='md'
      text={t(verifyContentStatus)}
      type={getBadgeTypeByVerifyContentStatus(verifyContentStatus)}
    />
  );
};

export default VerifyContentStatus;
