import { useGetCurrentUserInfoQuery } from '../redux/services/resourceApis/users/usersApi';

const REFETCH_ON_MOUNT_TIMEOUT = 5 * 60; // 5 Minutes

const useCurrentUser = () => {
  // The LoadInitialState component will ensure that this query has completed successfully so there
  // is no need to test the query status for success.
  const { data: currentUser } = useGetCurrentUserInfoQuery(undefined, {
    refetchOnMountOrArgChange: REFETCH_ON_MOUNT_TIMEOUT,
  });

  if (!currentUser) {
    throw new Error("User can't be found");
  }

  return currentUser;
};

export default useCurrentUser;
