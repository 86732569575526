import React, { FC } from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import ResponsibilityLinkedContent from '../ResponsibilityLinkedContent/ResponsibilityLinkedContent';
import Description from './Description/Description';
import ResponsibilityDurationTimeSetter from './ResponsibilityDuration/ResponsibilityDuration';
import { ResponsibilityDetailsContainer, SectionContainer } from './styles';
import { ResponsibilityDetailsProps } from './types';

const ResponsibilityDetails: FC<ResponsibilityDetailsProps> = ({
  contentUrls,
  description,
  workingHours,
}) => {
  const { delegationPlannerEnabled } = useCurrentAccount();

  return (
    <ResponsibilityDetailsContainer>
      <SectionContainer>
        {delegationPlannerEnabled && (
          <>
            <ResponsibilityDurationTimeSetter workingHours={workingHours} />
            <Description description={description} />
          </>
        )}
        <ResponsibilityLinkedContent contentUrls={contentUrls} />
      </SectionContainer>
    </ResponsibilityDetailsContainer>
  );
};

export default ResponsibilityDetails;
