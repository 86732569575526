import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { DropdownMenuOption } from '../components/design_system/Triage/menus/DropdownMenu';
import useDebounce from '../lib/useDebounce';
import { GroupByMenuOption } from '../types/GroupByMenuOption';

interface SearchContext {
  debouncedSearchValue: string;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  selectedSortOption: DropdownMenuOption;
  selectedGroupByOption: GroupByMenuOption | null;
  setSelectedSortOption: (IDropdownMenuOption: DropdownMenuOption) => void;
  setSelectedGroupByOption: (IDropdownMenuOption: GroupByMenuOption) => void;
}

interface Props {
  children: ReactNode;
  groupByOptions?: GroupByMenuOption[];
  sortOptions: DropdownMenuOption[];
  minCharactersToSearch?: number; // The minimum number of characters needed to perform search
}

const SearchContext = createContext({} as SearchContext);
export const useSearch = () => useContext(SearchContext);

export const SearchProvider = ({
  children,
  sortOptions,
  groupByOptions,
  minCharactersToSearch = 2,
}: Props) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const defaultOption = sortOptions[0];
  const groupByDefaultOption = groupByOptions ? groupByOptions[0] : null;
  const [selectedSortOption, setSelectedSortOption] = useState(defaultOption);
  const [selectedGroupByOption, setSelectedGroupByOption] = useState(groupByDefaultOption);
  const debouncedValue = useDebounce<string>(searchValue, 500);
  const debouncedSearchValue = debouncedValue.length >= minCharactersToSearch ? debouncedValue : '';

  // Reset the selected sort option when selected group changes
  useEffect(() => {
    if (selectedGroupByOption?.groupBy !== 'all_employees') {
      setSelectedSortOption(defaultOption);
    }
  }, [defaultOption, selectedGroupByOption]);

  return (
    <SearchContext.Provider
      value={{
        debouncedSearchValue,
        searchValue,
        selectedGroupByOption,
        selectedSortOption,
        setSearchValue,
        setSelectedGroupByOption,
        setSelectedSortOption,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
