import React from 'react';
import { Bar, BarChart, YAxis } from 'recharts';
import { useTheme } from 'styled-components';

import { CompletionChartLayer, CompletionChartResult } from './styles';
import { CompletionChartProps } from './types';

const CompletionChart = ({ data, minValue, maxValue, width = 244 }: CompletionChartProps) => {
  const { vars } = useTheme();

  const CHART_HEIGHT = 60;
  const BAR_WIDTH = (width - 101) / 100;
  const BAR_MARGINS = {
    left: 1,
    right: 1,
  };

  const maxDataValue = Math.max(...data.map((item) => item.count), 0);

  const rangeChartData = data.map((i) => ({
    name: i.name,
    count: Number(i.name) < minValue || Number(i.name) > maxValue ? 0 : i.count,
  }));

  const maxRangeChartValue = Math.max(...rangeChartData.map((item) => item.count), 0);
  const rangeChartHeight = (maxRangeChartValue * CHART_HEIGHT) / maxDataValue;

  return (
    <CompletionChartLayer>
      <BarChart
        barSize={BAR_WIDTH}
        data={data}
        height={CHART_HEIGHT}
        margin={BAR_MARGINS}
        width={width}
      >
        <YAxis domain={[0, maxRangeChartValue]} hide />
        <Bar dataKey='count' fill={vars.accentSubdued1} isAnimationActive={false} />
      </BarChart>
      <CompletionChartResult>
        <BarChart
          barSize={BAR_WIDTH}
          data={rangeChartData}
          height={rangeChartHeight}
          margin={BAR_MARGINS}
          width={width}
        >
          <YAxis domain={[0, maxRangeChartValue]} hide />
          <Bar dataKey='count' fill={vars.accentPrimaryDefault} isAnimationActive={false} />
        </BarChart>
      </CompletionChartResult>
    </CompletionChartLayer>
  );
};

export default CompletionChart;
