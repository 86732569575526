import React from 'react';
import styled from 'styled-components';
import { Route } from 'type-route';

import { useTeammateModal } from '../../../../../../contexts/TeammateContext';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import Link from '../../../../../design_system/Link';
import TaskModal from '../../../../../design_system/overlays/modals/TaskModal';
import P from '../../../../../design_system/text/P';
import { routes } from '../../../../publicApplication/applicationRouter';
import routeTo from '../../../../publicApplication/routeTo';
import { getTeammateConfirmationModalData } from './utils/getTeammateConfirmationModalData';

const StyledP = styled(P)`
  display: inline;
`;

const t = initTranslations('teammate_modal.confirmation_modal');

type ConfirmationProps = {
  setShowTeammateConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  closeModal: () => void;
};

const TeammateConfirmationModal = (props: ConfirmationProps) => {
  const { closeModal, setShowTeammateConfirmationModal } = props;
  const { teammateState } = useTeammateModal();
  const user = teammateState?.user;
  const sendInvite = teammateState?.sendInvite;
  const {
    slug,
    splitFeatures: { perUserPricingEnabled, simplePricingEnabled },
  } = useCurrentAccount();

  if (!user) return <></>;

  const userProfileRoute = routes.userProfile({
    slug,
    id: user.id,
  });
  const managePeopleRoute = routes.manageUsers({ slug });

  const modalData = getTeammateConfirmationModalData(
    sendInvite,
    perUserPricingEnabled,
    simplePricingEnabled,
    user.name
  );

  const handleRedirect = (route: Route<typeof routes.userProfile | typeof routes.manageUsers>) => {
    setShowTeammateConfirmationModal(false);
    routeTo(route);
  };

  return (
    <TaskModal
      heapModalName='teammate-confirmation-modal'
      onCloseRequest={closeModal}
      primaryButtonTask={() => handleRedirect(userProfileRoute)}
      primaryButtonText={t('view_profile')}
      processing={false}
      secondaryButtonText={t('dismiss')}
      title={modalData.title}
    >
      {typeof modalData.content === 'object' ? (
        <>
          <StyledP text={modalData.content.firsPartMessageForAdded} />
          <Link
            behavesAs='button'
            onClick={() => handleRedirect(managePeopleRoute)}
            text={modalData.content.link}
          />
          <StyledP text={modalData.content.secondPartMessageForAdded} />
        </>
      ) : (
        <P text={modalData.content} />
      )}
    </TaskModal>
  );
};

export default TeammateConfirmationModal;
