import React from 'react';

import { usePlanBrandColors } from '../../../../../../../hooks/billing/usePlanBrandColors';
import { SimplePricingBillingPlanData } from '../../../../../../../redux/services/resourceApis/billing/simplePricingTypes';
import PlanDetails from '../PlanDetails/PlanDetails';
import { Action } from '../reducer';
import { PlanCardWrapper } from '../styles';

type Props = {
  billingPlan: SimplePricingBillingPlanData;
  dispatch: React.Dispatch<Action>;
  isHovered: boolean;
  isSimplePricingV3: boolean;
};

const PlanCard = ({ billingPlan, dispatch, isHovered, isSimplePricingV3 }: Props) => {
  const { employeesSize, pricePerMonth, name, interval, active, ctaKey } = billingPlan;
  const planBrandColor = usePlanBrandColors({ name });

  return (
    <PlanCardWrapper
      className={`billing-page-${name}-plan`}
      isActive={active}
      isHovered={isHovered}
      isSimplePricingV3={isSimplePricingV3}
      key={name}
      planBrandColor={planBrandColor}
    >
      <PlanDetails
        ctaKey={ctaKey}
        dispatch={dispatch}
        employeesSize={employeesSize}
        interval={interval}
        isActive={active}
        name={name}
        pricePerMonth={pricePerMonth}
      />
    </PlanCardWrapper>
  );
};

export default PlanCard;
