import DOMPurify from 'dompurify';
import React from 'react';

import { useEmbedlyContext } from '../../../../../contexts/EmbedlyProvider';
import initTranslations from '../../../../../lib/initTranslations';
import EmbedCard from '../../components/EmbedCard/EmbedCard';
import { GOOGLE_DOCS_BASE_URL } from '../../shared/constants/editor';

const t = initTranslations('editor.embedly');

const EmbedlyContent = () => {
  const { inputUrl, embedlyData } = useEmbedlyContext();

  if (!embedlyData) return <></>;

  switch (embedlyData.type) {
    case 'photo':
    case 'pdf':
      return <></>;
    case 'video':
    case 'rich':
      if (inputUrl && inputUrl.includes(GOOGLE_DOCS_BASE_URL)) {
        return (
          <EmbedCard
            contentType='media'
            dataTestId={`embedly-${embedlyData.type}-preview`}
            description={embedlyData.description}
            imgUrl={embedlyData.thumbnail_url}
            isEditable={false}
            size='small'
            title={embedlyData.title || t('no_title')}
          />
        );
      }

      return (
        <div
          className='chromatic-ignore'
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(embedlyData.html, {
              ALLOWED_TAGS: ['iframe'],
              ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
            }),
          }}
          data-testid={`embedly-${embedlyData.type}-preview`}
        />
      );
    case 'link':
      return (
        <EmbedCard
          contentType='media'
          dataTestId='embedly-link-preview'
          description={embedlyData.description}
          imgUrl={embedlyData.thumbnail_url}
          isEditable={false}
          size='small'
          title={embedlyData.title || t('no_title')}
        />
      );
  }
};

export default EmbedlyContent;
