import React from 'react';

import { useAccountTerminology } from '../../../../../../components/AccountTerminologyProvider';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import { useRedirectToBillingOverlay } from '../../../../../../hooks/useRedirectToBillingOverlay';
import ReferFriendBannerImage from '../../../../../../images/billing/refer_a_friend_banner_image.svg';
import initTranslations from '../../../../../../lib/initTranslations';
import {
  BillingPageReferAFriendBody,
  BillingPageReferAFriendTitle,
  BillingPageReferAFriendWrapper,
  BillingPageReferContentWrapper,
  StyledButton,
  StyledTrialingText,
} from './styles';
import { BillingPageReferAFriendProps } from './types';

const t = initTranslations('per_user_pricing.billing_page.refer_a_friend');

const BillingPageReferAFriend = ({
  isTrialing,
  showReferAFriendModal,
}: BillingPageReferAFriendProps) => {
  const {
    splitFeatures: { fullScreenManageTrialPlanOverlayEnabled },
  } = useCurrentAccount();
  const navigateToBillingOverlay = useRedirectToBillingOverlay();

  const handleClickOnReferAFriendButton = () => {
    if (fullScreenManageTrialPlanOverlayEnabled && isTrialing) {
      navigateToBillingOverlay({ type: 'compare-plans' });
    } else {
      showReferAFriendModal();
    }
  };
  const { productTerm } = useAccountTerminology();

  return (
    <BillingPageReferAFriendWrapper id='billing-page-refer-a-friend'>
      <BillingPageReferContentWrapper>
        <BillingPageReferAFriendTitle>{t('title')}</BillingPageReferAFriendTitle>
        <BillingPageReferAFriendBody>
          {t('body', { productTerm })}
          {isTrialing && <StyledTrialingText text={t('pick_plan_copy')} />}
        </BillingPageReferAFriendBody>
        <StyledButton
          buttonType='brand'
          id='refer-a-friend-button'
          onClick={handleClickOnReferAFriendButton}
          size='lg'
          text={t(isTrialing ? 'pick_plan' : 'share_your_link')}
        />
      </BillingPageReferContentWrapper>
      <img alt={t('title')} src={ReferFriendBannerImage} />
    </BillingPageReferAFriendWrapper>
  );
};

export default BillingPageReferAFriend;
