import React from 'react';

import ESignatureStatus from '../../../../shared/ESignatureStatus/ESignatureStatus';
import { ColumnComponentProps } from '../types';

const ESignature = ({ record, userId }: ColumnComponentProps) => {
  const { e_signature, id } = record;

  return (
    <ESignatureStatus curriculumId={id} eSignature={e_signature} kind='by_user' userId={userId} />
  );
};

export default ESignature;
