import React from 'react';

import useSlideout from '../../../../../../hooks/useSlideout';
import initTranslations from '../../../../../../lib/initTranslations';
import { setIndividualUserReportSearchTerm } from '../../../../../../redux/domains/reports/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import SearchField from '../../../../../design_system/Triage/fields/SearchField';
import {
  ActionButtons,
  ActionsHeaderContainer,
  ElementsCount,
  RightAlignedHeaderActions,
  SearchFieldWrapper,
} from '../../../AllUsersReportPage/AllUsersReportTable/ActionsHeader/styles';
import { TableActionsHeaderProps } from '../types';
import IndividualUserDownloadCsvReport from './IndividualUserDownloadCsvReport';

const t = initTranslations('reports.actions_header');

const TableActionsHeader = ({
  isFetching,
  userId,
  totalCurriculumsCount,
  sortDirection,
  sortColumn,
}: TableActionsHeaderProps) => {
  const { curriculum } = useAccountTerminology();
  const dispatch = useAppDispatch();
  const { searchTerm, filters } = useAppSelector((state) => state.reports.individualUserReport);
  const { groupIds, completionScoreMin, completionScoreMax, tests, eSignature, statuses } = filters;

  const appliedFiltersCount =
    groupIds.length +
    tests.length +
    eSignature.length +
    statuses.length +
    (completionScoreMin > 0 || completionScoreMax < 100 ? 1 : 0);

  const { close, open, isOpen } = useSlideout('individual-user-report-filter-slideout');

  const toggleFilters = () => {
    isOpen ? close() : open();
  };

  return (
    <ActionsHeaderContainer id='individual-user-report-actions'>
      <ElementsCount>
        {t('curriculums_count', {
          count: totalCurriculumsCount,
          curriculum: curriculum.singular.toLowerCase(),
        })}
      </ElementsCount>
      <RightAlignedHeaderActions>
        <SearchFieldWrapper>
          <SearchField
            dataLoading={isFetching}
            placeholder={t('search_placeholder')}
            searchValue={searchTerm}
            setSearchValue={(value: string) => dispatch(setIndividualUserReportSearchTerm(value))}
          />
        </SearchFieldWrapper>
        <ActionButtons>
          <DefaultButton
            buttonType='secondary'
            iconName='sliders'
            iconWeight='solid'
            id='individual-user-report-filter-btn'
            onClick={toggleFilters}
            size='md'
            text={t('filters', { count: appliedFiltersCount })}
          />
          <IndividualUserDownloadCsvReport
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            userId={userId}
          />
        </ActionButtons>
      </RightAlignedHeaderActions>
    </ActionsHeaderContainer>
  );
};

export default TableActionsHeader;
