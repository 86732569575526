export const filterOptions = {
  accesses: [
    { label: 'All content access', value: 'all_accesses' },
    { label: 'View', value: 'view' },
    { label: 'Edit', value: 'edit' },
    { label: 'Manage', value: 'manage' },
    { label: 'Owner', value: 'owner' },
  ],
  generalAccesses: [
    { label: 'Discoverable', value: 'discoverable' },
    { label: 'Request', value: 'request' },
    { label: 'Private', value: 'private' },
  ],
  groups: [
    { label: 'All Groups', value: 'all_groups' },
    { label: 'All Users', value: 'all_users' },
  ],
  eSignatures: [
    { label: 'All', value: 'all' },
    { label: 'E-signature required', value: 'required' },
    { label: 'E-signature not required', value: 'not_required' },
  ],
  statuses: [
    { label: 'All statuses', value: 'all_statuses' },
    { label: 'Published', value: 'published' },
    { label: 'Unpublished', value: 'unpublished' },
  ],
  types: [
    { label: 'All types', value: 'all_types', searchableTerm: 'All Types' },
    { label: 'Reference', value: 'reference', searchableTerm: 'Reference' },
    { label: 'Favorites', value: 'favorites', searchableTerm: 'Favorites' },
    { label: 'Archived', value: 'archived', searchableTerm: 'Archived' },
  ],
};
