import React from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import Icon from '../../../../../design_system/display/icons/Icon';
import { routes } from '../../../../publicApplication/applicationRouter';
import {
  BoldText,
  ElementTitle,
  EmojiWrapper,
} from '../../../IndividualUserReportPage/IndividualUserReportTable/Columns/styles';
import { ColumnComponentProps } from '../types';
import { TitleWrapper } from './styles';

const Title = ({ record }: ColumnComponentProps) => {
  const { id, title, emoji } = record;
  const { slug } = useCurrentAccount();

  return (
    <TitleWrapper to={routes.curriculumByUserReport({ slug, id })}>
      <EmojiWrapper id={`curriculum-${id}-emoji`}>
        {emoji ? emoji : <Icon name='file-alt' size='sm' weight='regular' />}
      </EmojiWrapper>
      <ElementTitle>
        <BoldText>{title}</BoldText>
      </ElementTitle>
    </TitleWrapper>
  );
};

export default Title;
