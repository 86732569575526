import { Formik } from 'formik';
import React from 'react';

import { FormikWrapperProps, TeammateModalFormValues } from './InterfaceAndTypes';
import Modal from './Modal';
import validateForm from './ValidateForm';

export const FormikWrapper = ({
  action,
  prefillOptions,
  initialValues,
  closeModal,
  onSuccess,
  userId,
}: FormikWrapperProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {
        /* API request occurs in `/TeammateModal/Modal.tsx` */
      }}
      validate={(values) => validateForm(values as TeammateModalFormValues, action)}
      validateOnMount
    >
      <Modal
        action={action}
        closeModal={closeModal}
        onSuccess={onSuccess}
        prefillOptions={prefillOptions}
        userId={userId}
      />
    </Formik>
  );
};
