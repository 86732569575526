import React, { useCallback, useState } from 'react';

import { useGroup } from '../../../../contexts/GroupContext';
import initTranslations from '../../../../lib/initTranslations';
import { useEditGroupMutation } from '../../../../redux/services/resourceApis/groups/groupsApi';
import AiDescriptionModal from '../AiDescriptionModal/AiDescriptionModal';
import { getGroupPromptMessages } from './GroupPromptMessages';
import { CompletionResponses, GroupDescriptionModalProps } from './types';

const t = initTranslations('groups.groups_edit.group_description_modal');

const GroupDescriptionModal = ({ closeModal, composeModalType }: GroupDescriptionModalProps) => {
  const [currentCompletionResponse, setCurrentCompletionResponse] = useState<CompletionResponses>({
    completion: undefined,
    prevCompletion: undefined,
    hasGeneratedSuccessfully: false,
  });
  const { completion } = currentCompletionResponse;

  const group = useGroup();

  const [editGroup, resultEditGroup] = useEditGroupMutation();

  const saveToGroup = useCallback(() => {
    if (completion?.status !== 'completed') return;

    editGroup({ ...group, description: completion.completion });
  }, [completion, editGroup, group]);

  const { reset } = resultEditGroup;

  return (
    <AiDescriptionModal
      aiFeatureName='composeGroupDescription'
      closeModal={closeModal}
      composeFeatureName='composeGroupDescription'
      composeModalType={composeModalType}
      currentCompletionResponse={currentCompletionResponse}
      entity={group}
      flashNotificationMessage={t('saved_suggested_description')}
      flashNotificationResult={resultEditGroup}
      loadingText={t('generating_group_description')}
      localeKey='groups.groups_edit.group_description_modal'
      modalTitle={t('title', { groupType: group.kind })}
      promptMessages={getGroupPromptMessages(group)}
      resetCompletion={reset}
      saveToEntity={saveToGroup}
      setCurrentCompletionResponse={setCurrentCompletionResponse}
    />
  );
};

export default GroupDescriptionModal;
