import styled, { css } from 'styled-components';

import Icon from '../../../../../design_system/display/icons/Icon';
import { TruncatedText } from '../../../../../design_system/Triage/TruncatedText';
import { mediaBreakpointPxSm } from '../../../../../styled/Breakpoint';

export const sharedPillButton = css`
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};
  text-decoration: none;
  padding: ${({ theme: { constants } }) => `${constants.spacerSm2} ${constants.spacerMd2}`};
  margin: ${({ theme: { constants } }) => `0 ${constants.spacerMd2} ${constants.spacerSm3} 0`};
  box-sizing: border-box;
  max-width: 100%;
`;

export const PillText = styled.span`
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const PillName = styled.span`
  ${TruncatedText({})};
  display: -webkit-box;
  text-align: left;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;

  @media (min-width: ${mediaBreakpointPxSm}) {
    display: initial;
    white-space: nowrap;
  }
`;
