import styled from 'styled-components';

import { fontMd5, fontSm5 } from '../../../../../../styled/TypeSystem';

export const TotalDueSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  padding: ${({ theme: { constants } }) => constants.spacerMd2} 0;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd1};
  color: ${({ theme: { vars } }) => vars.textSubdued};
`;

export const TotalDueSectionDueDateWrapper = styled.div`
  ${fontSm5};
`;

export const TotalDueSectionDueDate = styled.span`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
`;

export const TotalDueSectionPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme: { vars } }) => vars.accentPrimaryPressed};
`;

export const TotalDueSectionPrice = styled.span`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontMd5};
`;

export const TotalDueSectionBillingPeriod = styled.span`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
`;
