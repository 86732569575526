import useCurrentUserAbilities from '../useCurrentUserAbilities';

export const useAbilityToViewProfile = (id: number) => {
  const ability = useCurrentUserAbilities();
  return ability.can('read', 'User-Profile-All') || ability.can('read', `User-Profile-${id}`);
};

export const useAbilityToManageProfile = (id: number | undefined) => {
  const ability = useCurrentUserAbilities();

  if (id === undefined) return false;

  return ability.can('update', 'User-Profile-All') || ability.can('update', `User-Profile-${id}`);
};

export const useAbilityToEditUser = (id: number) => {
  const ability = useCurrentUserAbilities();
  return ability.can('edit', { id, __typename: 'User' });
};
