import styled from 'styled-components';

import {
  mediaBreakpointPxLg,
  mediaBreakpointPxMd,
  mediaBreakpointPxSm,
} from '../../../../styled/Breakpoint';
import { fontMd4, fontSm1, fontSm4, fontSm5 } from '../../../../styled/TypeSystem';

export const ReportHeadContainer = styled.div`
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  @media (min-width: ${mediaBreakpointPxMd}) {
    display: flex;
  }
`;

export const ReportHeadLeftColumn = styled.div`
  display: flex;
  @media (min-width: ${mediaBreakpointPxMd}) {
    width: 50%;
    padding-right: ${({ theme: { constants } }) => constants.spacerMd2};
  }
  @media (min-width: ${mediaBreakpointPxLg}) {
    width: 45%;
  }
`;

export const ReportHeadInfo = styled.div`
  padding-left: ${({ theme: { constants } }) => constants.spacerMd2};
  width: calc(100% - 8rem);
  @media (min-width: ${mediaBreakpointPxSm}) {
    padding-left: ${({ theme: { constants } }) => constants.spacerMd3};
  }
`;

export const ReportHeadTitle = styled.h4`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-bottom: 0;
  word-break: break-word;
  ${fontMd4};
`;

export const ReportHeadSubtitle = styled.div`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontSm5};
`;

export const ReportHeadReportsTo = styled.div`
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontSm4};
`;

export const ReportHeadLastActive = styled.div`
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm1};
  b {
    font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  }
`;

export const ReportHeadRightColumnWrapper = styled.div`
  width: 100%;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  @media (min-width: ${mediaBreakpointPxMd}) {
    width: 50%;
    margin-top: 0;
  }
  @media (min-width: ${mediaBreakpointPxLg}) {
    width: 55%;
  }
`;
