import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { MultilineTruncatedText, TruncatedText } from '../../../design_system/Triage/TruncatedText';
import {
  FontSize,
  FontSizeSelected,
  FontWeightSelected,
  FontWeightType,
} from '../../people/Slideouts/Shared/FontSize';

const sharedColorStyles = css<{ fontColor: TitleColors }>`
  ${({ theme: { vars }, fontColor }) => {
    switch (fontColor) {
      case 'placeholder':
        return css`
          color: ${vars.textPlaceholder};
        `;
      case 'textSubdued':
        return css`
          color: ${vars.textSubdued};
        `;
      case 'stateError':
        return css`
          color: ${vars.stateError};
        `;
      case 'accentPrimaryDefault':
        return css`
          color: ${vars.accentPrimaryDefault};
        `;
      case 'default':
        return css`
          color: ${vars.textDefault};
        `;
    }
  }}
`;

export const sharedClickableStyles = css<{ fontSize?: FontSize }>`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: ${({ theme: { vars } }) => darken(0.15, vars.accentPrimaryDefault)};
  }

  ${FontSizeSelected};
`;

export const ClickableLabelText = styled.button`
  padding: 0;
  background: none;
  border: none;
  ${sharedClickableStyles};
`;

export const ClickableLinkText = styled.a`
  text-decoration: none;
  margin: 0;
  width: fit-content;
  display: initial;
  vertical-align: top;
  ${sharedClickableStyles};
`;

export type Truncate = 'none' | 'oneLine' | 'twoLines';
export type TitleColors =
  | 'default'
  | 'placeholder'
  | 'textSubdued'
  | 'stateError'
  | 'accentPrimaryDefault';

export const TruncationHandler = css<{ truncate?: Truncate }>`
  ${({ truncate }) => {
    switch (truncate) {
      case 'oneLine':
        return TruncatedText({});
      case 'twoLines':
        return MultilineTruncatedText({});
    }
  }}
`;

type TitleProps = {
  truncate?: Truncate;
  fontWeight: FontWeightType;
  fontSize: FontSize;
  fontColor: TitleColors;
};

const sharedTitleStyles = css<TitleProps>`
  text-align: left;

  ${FontWeightSelected};
  ${TruncationHandler};
  ${FontSizeSelected};
`;

export const TitleLink = styled(ClickableLinkText)<Omit<TitleProps, 'fontColor'>>`
  cursor: pointer;
  ${sharedTitleStyles};
`;

export const TitleLabel = styled(ClickableLabelText)<TitleProps>`
  ${sharedColorStyles};
  ${sharedTitleStyles};

  &:hover {
    ${sharedColorStyles};
  }
`;

export const TitleUnclickableLabel = styled.div<TitleProps>`
  margin: 0;
  ${sharedColorStyles};
  ${sharedTitleStyles};
`;
