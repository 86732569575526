import { Option } from '../components/design_system/core/SelectOption/types';
import initTranslations from '../lib/initTranslations';
import { User } from '../types/User';
import sortArray, { SortOrder } from './sortArray';

export const formatUserOptions = (users: User[], identifyCurrentUser?: string): Option[] => {
  const t = initTranslations();

  let userList = users.map((user) => ({
    hasAvatar: true,
    avatarImage: user.avatar,
    avatarName: user.name,
    label: user.name,
    value: String(user.id),
    metaValue: user.title,
    searchableTerm: String(user.name),
  }));

  userList = sortArray({ array: userList, sortKey: 'label', sortOrder: SortOrder.asc });

  if (identifyCurrentUser) {
    const currentUserIndex = userList.findIndex(
      (userOption) => userOption.label === identifyCurrentUser
    );
    const assignToMe = {
      ...userList[currentUserIndex],
      label: t('assign_to_me', { name: identifyCurrentUser }),
    };
    userList.splice(currentUserIndex, 1);
    userList = [assignToMe, ...userList];
  }

  return userList;
};
