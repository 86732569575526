import React, { FC } from 'react';

import Avatar from '../../../../../design_system/display/avatar';
import { Props as AvatarProps } from '../../../../../design_system/display/avatar/Avatar';
import { HeaderAvatarWrapper } from '../../../../DelegationPlanner/modals/ResponsibilityDetailsModal/ModalHeader/styles';
import { ResponsibilityNameWrapper } from './styles';
import { ModalHeaderProps } from './types';

const ModalHeader: FC<ModalHeaderProps> = ({ responsibility, type }) => {
  const isGroupTypeCard = type === 'Group';
  const avatarProps = (image: string | undefined): AvatarProps => {
    const commonProps = {
      name: responsibility.responsibiliableName,
      shape: 'circle',
      size: 'xs',
    } as AvatarProps;

    if (isGroupTypeCard) {
      return { ...commonProps, icon: 'users' };
    } else {
      return { ...commonProps, image };
    }
  };

  return (
    <>
      <ResponsibilityNameWrapper>
        <span>{responsibility.name}</span>
      </ResponsibilityNameWrapper>
      <HeaderAvatarWrapper isGroupAvatar={isGroupTypeCard}>
        <Avatar {...avatarProps(responsibility.avatarUrl)} />
        <span>{responsibility.responsibiliableName}</span>
      </HeaderAvatarWrapper>
    </>
  );
};

export default ModalHeader;
