import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import { TooltipProps } from '../../../design_system/core/Labels/SharedLabelTypes';
import SingleSelectField from '../../../design_system/Triage/fields/SingleSelectField';
import RadioField from '../../../design_system/Triage/RadioField';
import { Option } from '../../../design_system/Triage/RadioField/RadioFieldTypes';
import { addXDaysToDate } from './addXDaysToDate';
import { hoursOptions } from './hoursOptions';
import { TeammateModalFormValues } from './InterfaceAndTypes';
import ScheduledDateInput from './ScheduledDateInput';

const FormGroup = styled.div`
  user-select: none;
  width: 100%;
`;

const DateInputWrapper = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    margin-top: ${constants.spacerSm3};
    margin-left: ${constants.spacerMd2};
    padding-left: ${constants.spacerSm2};
  `
);

const ScheduledInviteAtHourSelect = styled(SingleSelectField)`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  min-width: 8rem;
`;

const t = initTranslations('teammate_modal');

const SendInviteRadioField = () => {
  const { values, touched, errors, isSubmitting, setFieldValue } =
    useFormikContext<TeammateModalFormValues>();

  const {
    noFreeSlots,
    splitFeatures: { perUserPricingEnabled },
  } = useCurrentAccount();

  const tooltipProps: TooltipProps = useMemo(() => {
    if (!perUserPricingEnabled) return {};

    const tooltipId = 'teammate-modal-send-invites-tooltip';
    const tooltipText = noFreeSlots
      ? t('shared.inputs.invite.per_user_no_free_slots_tooltip')
      : t('shared.inputs.invite.per_user_tooltip');

    return {
      tooltipId,
      tooltipText,
    };
  }, [noFreeSlots, perUserPricingEnabled]);

  const inviteOptions: Option[] = useMemo(
    () => [
      {
        text: t('shared.inputs.invite.now.label'),
        value: 'now',
        description: t('shared.inputs.invite.now.description'),
        disabled: (perUserPricingEnabled && noFreeSlots) || isSubmitting,
      },
      {
        text: t('shared.inputs.invite.later.label'),
        value: 'later',
        description: t('shared.inputs.invite.later.description'),
        disabled: isSubmitting,
      },
      {
        text: t('shared.inputs.invite.scheduled.label'),
        value: 'scheduled',
        description: t('shared.inputs.invite.scheduled.description'),
        disabled: (perUserPricingEnabled && noFreeSlots) || isSubmitting,
      },
    ],
    [isSubmitting, noFreeSlots, perUserPricingEnabled]
  );

  return (
    <FormGroup className='send-invite-group'>
      <RadioField
        disabled={isSubmitting}
        fieldLabelText={t('shared.inputs.invite.label')}
        name='send_invite'
        onChange={({ target: { value } }) => {
          setFieldValue('send_invite', value);
          setFieldValue('schedule_invite', value === 'scheduled');

          if (value === 'later') {
            setFieldValue('schedule_archive', false);
          }
          if (
            value === 'scheduled' &&
            values.scheduled_archive_at &&
            values.scheduled_invite_at &&
            values.scheduled_archive_at <= values.scheduled_invite_at
          ) {
            setFieldValue('scheduled_archive_at', addXDaysToDate(values.scheduled_archive_at, 1));
          }
        }}
        options={inviteOptions}
        orientation='vertical'
        value={values.send_invite}
        {...tooltipProps}
      />
      {values.schedule_invite && (
        <DateInputWrapper className='notranslate'>
          <div>
            <ScheduledDateInput
              disabled={isSubmitting}
              errorText={touched.scheduled_invite_at && (errors.scheduled_invite_at as string)}
              minDate={values.tomorrow_date}
              name='scheduled_invite_at'
              onChange={(date: string) => {
                setFieldValue('scheduled_invite_at', date);

                if (values.scheduled_archive_at && values.scheduled_archive_at <= date) {
                  setFieldValue('scheduled_archive_at', addXDaysToDate(date, 1));
                }
              }}
              value={values.scheduled_invite_at}
            />
          </div>
          <ScheduledInviteAtHourSelect
            defaultValue={values.scheduled_invite_at_hour}
            onNonDefaultSelected={(value: string) =>
              setFieldValue('scheduled_invite_at_hour', value)
            }
            options={hoursOptions()}
            value={values.scheduled_invite_at_hour}
          />
        </DateInputWrapper>
      )}
    </FormGroup>
  );
};

export default SendInviteRadioField;
