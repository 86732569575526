import styled from 'styled-components';

import { fontMd2 } from '../../../styled/TypeSystem';

export const EmojiWrapper = styled.div`
  align-items: center;
  background: ${({ theme: { vars } }) => vars.foundationBase2};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  display: flex;
  height: ${({ theme: { constants } }) => constants.heightLg};
  justify-content: center;
  width: 2.5rem;
  min-width: 2.5rem;
  ${fontMd2};
`;
