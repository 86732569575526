import React from 'react';

import { useGroupsModals } from '../../../../../contexts/GroupsModalContext';
import capitalize from '../../../../../lib/capitalize';
import initTranslations from '../../../../../lib/initTranslations';
import { groupKinds } from '../../../../../types/Group';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import GroupKindButton from './GroupKindButton';
import { ButtonsContainer, ButtonsDescription } from './styles';

const t = initTranslations('groups.modals.create_group.create_group_kind');

const CreateGroupKindModal = () => {
  const {
    state: { newGroupKind },
    dispatch,
  } = useGroupsModals();
  const {
    responsibility: { plural: responsibilityPlural },
  } = useAccountTerminology();
  const taskModalArgs: TaskModalProps = {
    heapModalName: 'create-group-kind-modal',
    onCloseRequest: () => {
      dispatch({ type: 'closeGroupKindModal' });
    },
    primaryButtonTask: () => {
      dispatch({ type: 'openGroupNameModal' });
    },
    primaryButtonText: t('next_button'),
    processing: false,
    title: t('header'),
    subtitle: t('description', { responsibilities: responsibilityPlural.toLowerCase() }),
  };

  return (
    <TaskModal {...taskModalArgs}>
      <ButtonsDescription>{t('select_a_group_type')}</ButtonsDescription>
      <ButtonsContainer>
        {groupKinds.map((groupKind) => (
          <GroupKindButton
            autoFocus={newGroupKind === groupKind}
            displayText={capitalize(groupKind)}
            key={groupKind}
            onClick={() => dispatch({ type: 'setNewGroupKind', payload: groupKind })}
            selected={newGroupKind === groupKind}
          />
        ))}
      </ButtonsContainer>
    </TaskModal>
  );
};

export default CreateGroupKindModal;
