import { kebabCase } from 'lodash';
import React from 'react';

import useDisplayFlashOnResponse from '../../../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../../../lib/initTranslations';
import { useDispatchSetShowModal } from '../../../../../../../../redux/domains/modalsSlice/modalsSlice';
import { messageFromError } from '../../../../../../../../redux/errors/helpers';
import {
  useDeleteAddonMutation,
  useFetchCancellationInfoQuery,
} from '../../../../../../../../redux/services/resourceApis/billing/billingApi';
import { Addon } from '../../../../../../../../types/Addon';
import TaskModal, {
  TaskModalProps,
} from '../../../../../../../design_system/overlays/modals/TaskModal';
import P from '../../../../../../../design_system/text/P';
import Loader from '../../../../../../../design_system/Triage/Loader';
import { StyledUl } from './styles';

const t = initTranslations('billing.addon_cancellation_modal');

type AddonCancellationModalProps = {
  name: string;
  modalName: string;
  kind: Addon;
};

const AddonCancellationModal = ({ name, modalName, kind }: AddonCancellationModalProps) => {
  const {
    data,
    error: cancellationInfoError,
    isLoading: isLoadingCancellationInfo,
    isSuccess: isCancellationInfoSuccess,
  } = useFetchCancellationInfoQuery({ addonName: kind });
  const [deleteAddon, deleteAddonResult] = useDeleteAddonMutation();
  const { isLoading: deleteAddonIsLoading, error: deleteAddonError } = deleteAddonResult;
  const isLoading = isLoadingCancellationInfo || deleteAddonIsLoading;
  const error = cancellationInfoError || deleteAddonError;
  const showContent = isCancellationInfoSuccess && data;
  const dispatchShowModal = useDispatchSetShowModal();
  const handleCloseModal = () => {
    dispatchShowModal(modalName, false);
  };

  const taskModalArgs: TaskModalProps = {
    title: t('title', { name }),
    onCloseRequest: handleCloseModal,
    processing: deleteAddonIsLoading,
    isDisabled: isLoading,
    primaryButtonText: t('confirm', { name }),
    primaryButtonTask: () => deleteAddon({ name: kind }),
    secondaryButtonText: t('cancel'),
    desktopSize: 'lg',
    heapModalName: `addon-${kebabCase(name)}-cancellation-modal`,
  };

  useDisplayFlashOnResponse({
    result: deleteAddonResult,
    successMessage: t('success', { name }),
    successFlashType: 'info',
    successFunction: handleCloseModal,
    errorMessage: messageFromError(error)?.join(', '),
    errorFunction: handleCloseModal,
  });

  return (
    <TaskModal {...taskModalArgs}>
      {showContent ? (
        <>
          <P text={data.addonCancellationDescription} />
          <StyledUl>
            {data.features.map((feature, index) => (
              <li dangerouslySetInnerHTML={{ __html: feature }} key={index}></li>
            ))}
          </StyledUl>
        </>
      ) : (
        <Loader size='md' />
      )}
    </TaskModal>
  );
};

export default AddonCancellationModal;
