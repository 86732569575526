import { useEffect } from 'react';

import { getActionCable } from '../lib/actionCable';

export interface ChannelProps {
  channel: string;
  [key: string]: string;
}

type Callbacks<T> = {
  received: (data: T) => void;
};

const useActionCableChannel = <T>(
  channelProps: ChannelProps,
  received: Callbacks<T>['received']
) => {
  useEffect(() => {
    const channel = getActionCable()?.subscriptions.create(channelProps, {
      received: (data: T) => {
        received(data);
        console.log(`${channelProps.channel} channel received: `, data);
      },
      initialized: () => {
        console.log(`${channelProps.channel} channel initialized`);
      },
      connected: () => {
        console.log(`${channelProps.channel} channel connected`);
      },
      disconnected: () => {
        console.log(`${channelProps.channel} channel disconnected`);
      },
    });

    return () => {
      channel?.unsubscribe();
    };
  }, [channelProps, received]);
};

export default useActionCableChannel;
