import styled from 'styled-components';

import { fontSm5 } from '../../../../../../../styled/TypeSystem';

export const StyledUl = styled.ul`
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} 0 0 ${constants.spacerMd3}`};
  ${fontSm5};
  /* Had to come after fontSm5 to override the fontSm5 styles line-height */
  line-height: 2;
`;
