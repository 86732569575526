import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import useWindowResize from '../../../../../hooks/useWindowResize';
import OnHoldBanner from './OnHoldBanner/OnHoldBanner';
import PastDueBanner from './PastDueBanner/PastDueBanner';
import PendingCancellationBanner from './PendingCancellationBanner/PendingCancellationBanner';
import TrialEndedBanner from './TrialEndedBanner/TrialEndedBanner';

const AccountStatusBannerFactory = () => {
  const {
    status: accountStatus,
    cancellationReason,
    cancellationRequestedAt,
    onHoldPlan,
    splitFeatures: { fullScreenManageTrialPlanOverlayEnabled },
  } = useCurrentAccount();
  const { permission } = useCurrentUser();
  const { isMobile } = useWindowResize();

  if (isMobile || permission !== 'billing_admin') return <></>;

  switch (accountStatus) {
    case 'active':
      if (cancellationRequestedAt && cancellationReason) {
        return <PendingCancellationBanner />;
      } else if (onHoldPlan) {
        return <OnHoldBanner />;
      } else {
        return <></>;
      }
    case 'past_due':
      return <PastDueBanner />;
    case 'trial_ended':
      return fullScreenManageTrialPlanOverlayEnabled ? <TrialEndedBanner /> : <></>;
    default:
      return <></>;
  }
};

export default AccountStatusBannerFactory;
