import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import useUserPermissionSettings from '../../../../hooks/users/useUserPermissionSettings';
import { EditUser, UserResponse } from '../../../../hooks/useTeammateState';
import { formatUserOptions } from '../../../../lib/formatUserOptions';
import initTranslations from '../../../../lib/initTranslations';
import sortArray, { SortOrder } from '../../../../lib/sortArray';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import { OptionWithSourceBadgeProps } from '../../../design_system/core/SelectOption/types';
import DetailedAvatar from '../../../design_system/display/DetailedAvatar/DetailedAvatar';
import Checkbox from '../../../design_system/input/controls/Checkbox';
import MultiSelectField from '../../../design_system/Triage/fields/MultiSelectField';
import SingleSelectField from '../../../design_system/Triage/fields/SingleSelectField';
import InputField from '../../../design_system/Triage/InputField';
import { StyledSelect } from '../OrgChartShared/OrgChartConfigSlideout/ReportsToConflictModal';
import { addXDaysToDate } from './addXDaysToDate';
import FormatPermissionOptions from './FormatPermissionOptions';
import { hoursOptions } from './hoursOptions';
import { TeammateAction, TeammateModalFormValues } from './InterfaceAndTypes';
import { TeammateModalTab } from './Modal';
import ScheduledDateInput from './ScheduledDateInput';
import SendInviteRadioField from './SendInviteRadioField';
import { ErrorText } from './Styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const MainSpacing = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const FormGroupWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const FormGroup = styled.div`
  user-select: none;
  width: 100%;
`;

const CheckboxWrapper = styled.div`
  width: fit-content;
`;

const StyledOptionWrapper = styled.div`
  margin: ${({ theme: { constants } }) => `${constants.spacerSm1} 0`};
`;

const ScheduledDateInputWrapper = styled.div`
  display: flex;
`;

const ScheduledHourSelect = styled(SingleSelectField)`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  min-width: 8rem;
`;

const t = initTranslations('teammate_modal');

type ActiveTeammateTabProps = {
  action: TeammateAction;
  tab: TeammateModalTab;
  userId?: number;
  prefillOptions: UserResponse;
};

const ActiveTeammateTab = ({ action, tab, userId, prefillOptions }: ActiveTeammateTabProps) => {
  const { values, touched, errors, isSubmitting, handleChange, setFieldValue } =
    useFormikContext<TeammateModalFormValues>();
  const { groups, users, user } = prefillOptions;
  const ability = useCurrentUserAbilities();
  const {
    curriculum: { singular: subjectTermSingular },
    responsibility: { plural: responsibilityPlural },
  } = useAccountTerminology();
  const { permission: currentUserPermission } = useCurrentUser();
  const { accountPlan, rootNodeId } = useCurrentAccount();

  const { permissionSettings, isHigherPermission } = useUserPermissionSettings();
  const permissions = Object.keys(permissionSettings);
  const defaultPermissionIndex = permissions.indexOf(currentUserPermission);
  const hasHigherPermission = isHigherPermission(values.permission);

  const isBuildPlan = accountPlan === 'build';
  const canUpdateTeammatePermission =
    action === 'add' ||
    (userId && ability.can('update', { id: userId, __typename: 'User' }) && !hasHigherPermission);

  const canInviteUser =
    action === 'add' || (userId && ability.can('invite', { id: userId, __typename: 'User' }));
  const userIsArchived = action === 'edit' && (user as EditUser)?.status === 'archived';

  const showScheduleInviteCheckbox = useMemo(
    () => action === 'edit' && !userIsArchived && canInviteUser,
    [action, userIsArchived, canInviteUser]
  );

  const showArchiveCheckbox = useMemo(
    () => !isBuildPlan && !userIsArchived,
    [isBuildPlan, userIsArchived]
  );

  const groupsWithoutEveryoneGroup = groups.filter((group) => !group.everyone);
  const reportsToOptions = formatUserOptions(users);
  const isRootUser = rootNodeId === userId;
  const groupOptions: OptionWithSourceBadgeProps[] = useMemo(() => {
    const options: (OptionWithSourceBadgeProps & { searchableTerm: string })[] =
      groupsWithoutEveryoneGroup.map(({ id, name, kind }) => {
        return {
          value: `${id}`,
          label: (
            <StyledOptionWrapper>
              <DetailedAvatar
                avatarIcon='users'
                extendedLabel={`${initTranslations('groups.kinds')(kind)}`}
                id={`group-detailed-avatar-${id}`}
                label={name}
              />
            </StyledOptionWrapper>
          ),
          searchableTerm: name,
          sourceName: 'group',
          avatarName: name,
          sourceSubtext: `(${initTranslations('groups.kinds')(kind)})`,
        };
      });

    return sortArray({ array: options, sortKey: 'searchableTerm', sortOrder: SortOrder.asc });
  }, [groupsWithoutEveryoneGroup]);

  const permissionOptions = FormatPermissionOptions(
    defaultPermissionIndex,
    accountPlan === 'build',
    subjectTermSingular
  );

  const minScheduledInviteDate = () => {
    if (
      action === 'edit' &&
      values.scheduled_invite_at &&
      new Date(values.tomorrow_date) > new Date(values.scheduled_invite_at)
    ) {
      const currentDate = new Date();
      return currentDate.toISOString().split('T')[0];
    }
    return values.tomorrow_date;
  };

  const minScheduledArchiveDate = () => {
    if (values.scheduled_invite_at && values.schedule_invite) {
      return addXDaysToDate(values.scheduled_invite_at, 1);
    } else if (
      action === 'edit' &&
      values.scheduled_archive_at &&
      new Date(values.tomorrow_date) > new Date(values.scheduled_archive_at)
    ) {
      const currentDate = new Date();
      return currentDate.toISOString().split('T')[0];
    }
    return values.tomorrow_date;
  };

  switch (tab) {
    case 'general':
      return (
        <Wrapper id='general-tab-display'>
          <MainSpacing>
            <FormGroupWrapper>
              <FormGroup className='name-group'>
                <InputField
                  autoFocus
                  className='analytics-edit-name-field'
                  disabled={isSubmitting}
                  errorText={touched.name && errors.name}
                  id='name'
                  label={t('shared.inputs.name.label')}
                  name='name'
                  onChange={handleChange}
                  placeholder={t('shared.inputs.name.placeholder')}
                  required
                  type='text'
                  value={values.name}
                />
              </FormGroup>
              <FormGroup className='email-group'>
                <InputField
                  className='analytics-edit-email-field'
                  disabled={isSubmitting}
                  errorText={touched.email && errors.email}
                  id='email'
                  label={t('shared.inputs.email.label')}
                  name='email'
                  onChange={handleChange}
                  placeholder={t('shared.inputs.email.placeholder')}
                  required
                  type='email'
                  value={values.email}
                />
              </FormGroup>
            </FormGroupWrapper>
            <FormGroupWrapper>
              <FormGroup className='job-title-group'>
                <InputField
                  className='analytics-edit-job-title-field'
                  disabled={isSubmitting}
                  errorText={touched.title && errors.title}
                  id='job-title'
                  label={t('shared.inputs.job_title.label')}
                  name='title'
                  onChange={handleChange}
                  placeholder={t('shared.inputs.job_title.placeholder')}
                  type='text'
                  value={values.title}
                />
              </FormGroup>
              <FormGroup className='phone-number-group'>
                <InputField
                  className='analytics-edit-phone-number-field'
                  disabled={isSubmitting}
                  errorText={touched.phone_number && errors.phone_number}
                  id='phone-number'
                  label={t('shared.inputs.phone_number.label')}
                  name='phone_number'
                  onChange={handleChange}
                  placeholder={t('shared.inputs.phone_number.placeholder')}
                  type='text'
                  value={values.phone_number || ''}
                />
              </FormGroup>
            </FormGroupWrapper>
            {canUpdateTeammatePermission && (
              <FormGroup className='permissions-group'>
                <SingleSelectField
                  className='permissions-select'
                  defaultValue={values.permission || null}
                  disabled={isSubmitting}
                  fieldLabelText={t('shared.inputs.permission.label')}
                  hideSelectedOptions={false}
                  labelTooltipId='permission-select-tooltip'
                  labelTooltipText={t('shared.inputs.permission.tooltip')}
                  name='permission'
                  onNonDefaultSelected={(selectedOption: string) =>
                    setFieldValue('permission', selectedOption)
                  }
                  options={permissionOptions}
                  required
                  value={values.permission}
                />
              </FormGroup>
            )}
          </MainSpacing>
          <MainSpacing>
            {action === 'add' && <SendInviteRadioField />}
            {showScheduleInviteCheckbox && (
              <FormGroup className='schedule-automatic-invite-group'>
                <CheckboxWrapper>
                  <Checkbox
                    bottomMargin='0'
                    checked={values.schedule_invite}
                    disabled={isSubmitting}
                    id='schedule_invite'
                    label={t('shared.inputs.schedule_invite.label')}
                    name='schedule_invite'
                    onCheck={() => setFieldValue('schedule_invite', !values.schedule_invite)}
                    subLabel={t('shared.inputs.schedule_invite.placeholder')}
                  />
                  {values.schedule_invite && (
                    <ScheduledDateInputWrapper className='notranslate'>
                      <div>
                        <ScheduledDateInput
                          disabled={isSubmitting}
                          errorText={
                            touched.scheduled_invite_at && (errors.scheduled_invite_at as string)
                          }
                          minDate={minScheduledInviteDate()}
                          name='scheduled_invite_at'
                          onChange={(date: string) => setFieldValue('scheduled_invite_at', date)}
                          value={values.scheduled_invite_at}
                        />
                      </div>
                      <ScheduledHourSelect
                        defaultValue={values.scheduled_invite_at_hour}
                        onNonDefaultSelected={(value: string) =>
                          setFieldValue('scheduled_invite_at_hour', value)
                        }
                        options={hoursOptions()}
                        value={values.scheduled_invite_at_hour}
                      />
                    </ScheduledDateInputWrapper>
                  )}
                </CheckboxWrapper>
              </FormGroup>
            )}
          </MainSpacing>
        </Wrapper>
      );

    case 'advanced':
      return (
        <Wrapper id='advanced-tab-display'>
          <MainSpacing>
            <FormGroup className='group-group'>
              <MultiSelectField
                className='analytics-invite-modal-group-select'
                defaultValue={values.group_ids}
                disabled={isSubmitting}
                fieldLabelText={t('shared.inputs.group.label')}
                labelTooltipId='optional-settings-group-tooltip'
                labelTooltipText={t('shared.inputs.group.tooltip', {
                  responsibilities: responsibilityPlural.toLowerCase(),
                })}
                name='group'
                onNonDefaultSelected={(group_ids: string[]) =>
                  setFieldValue('group_ids', group_ids)
                }
                placeholder={t('shared.inputs.group.placeholder')}
                sourceBadgeOptions={groupOptions}
                useSourceBadge
                value={values.group_ids}
              />
              {touched.group_ids && errors.group_ids && <ErrorText>{errors.group_ids}</ErrorText>}
            </FormGroup>
            <FormGroup className='reports-to-group'>
              <StyledSelect
                className='analytics-invite-modal-reports-to-select notranslate'
                defaultValue={values.parent_id}
                disabled={isRootUser}
                disabledFieldTooltipId='highest-ranking-tooltip'
                disabledFieldTooltipText={t('shared.inputs.parent_id.highest-ranking-tooltip')}
                fieldLabelText={t('shared.inputs.parent_id.label')}
                isClearable
                labelTooltipId='optional-settings-reports-to-tooltip'
                labelTooltipText={t('shared.inputs.parent_id.tooltip')}
                onNonDefaultSelected={(parent_id: string) => setFieldValue('parent_id', parent_id)}
                options={reportsToOptions}
                placeholder={t('shared.inputs.parent_id.placeholder')}
                value={values.parent_id}
              />
              {touched.parent_id && errors.parent_id && <ErrorText>{errors.parent_id}</ErrorText>}
            </FormGroup>
            {showArchiveCheckbox && (
              <FormGroup className='schedule-automatic-archive-group'>
                <CheckboxWrapper>
                  <Checkbox
                    bottomMargin='0'
                    checked={values.schedule_archive}
                    disabled={isSubmitting || values.send_invite === 'later'}
                    id='schedule_archive'
                    label={t('shared.inputs.schedule_archive.label')}
                    name='schedule_archive'
                    onCheck={() => setFieldValue('schedule_archive', !values.schedule_archive)}
                    subLabel={
                      values.send_invite === 'later'
                        ? t('shared.inputs.schedule_archive.placeholder_disabled')
                        : t('shared.inputs.schedule_archive.placeholder')
                    }
                  />
                  {values.schedule_archive && (
                    <ScheduledDateInputWrapper>
                      <div>
                        <ScheduledDateInput
                          disabled={isSubmitting}
                          errorText={
                            touched.scheduled_archive_at && (errors.scheduled_archive_at as string)
                          }
                          minDate={minScheduledArchiveDate()}
                          name='scheduled_archive_at'
                          onChange={(date: string) => setFieldValue('scheduled_archive_at', date)}
                          value={values.scheduled_archive_at}
                        />
                      </div>
                      <ScheduledHourSelect
                        defaultValue={values.scheduled_archive_at_hour}
                        onNonDefaultSelected={(value: string) =>
                          setFieldValue('scheduled_archive_at_hour', value)
                        }
                        options={hoursOptions()}
                        value={values.scheduled_archive_at_hour}
                      />
                    </ScheduledDateInputWrapper>
                  )}
                </CheckboxWrapper>
              </FormGroup>
            )}
            <FormGroup className='show-in-directory-group'>
              <Checkbox
                checked={values.show_in_directory}
                className='analytics-invite-modal-show-in-directory-checkbox'
                disabled={isSubmitting}
                id='show_in_directory'
                label={t('shared.inputs.show_in_directory.label')}
                name='show_in_directory'
                onCheck={() => setFieldValue('show_in_directory', !values.show_in_directory)}
                tooltipHoverIcon
                tooltipId='teammate_modal_option_settings_tooltip'
                tooltipText={t('shared.inputs.show_in_directory.tooltip')}
              />
            </FormGroup>
          </MainSpacing>
        </Wrapper>
      );
  }
};

export default ActiveTeammateTab;
