import React from 'react';

import { usePlanBrandColors } from '../../../../../../../../hooks/billing/usePlanBrandColors';
import { usePlanTitle } from '../../../../../../../../hooks/billing/usePlanTitle';
import { SimplePricingBillingPlanName } from '../../../../../../../../types/BillingPlanName';
import { StyledPlanName } from './styles';

type PlanNameProps = {
  isSelected: boolean;
  name: SimplePricingBillingPlanName;
};

const PlanTitle = ({ name, isSelected }: PlanNameProps) => {
  const { color: planBrandColor, backgroundColor } = usePlanBrandColors({ name });
  const planTitle = usePlanTitle({ name });

  return (
    <StyledPlanName
      backgroundColor={backgroundColor}
      isSelected={isSelected}
      planBrandColor={planBrandColor}
    >
      {planTitle}
    </StyledPlanName>
  );
};

export default PlanTitle;
