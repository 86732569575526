import React from 'react';

import Avatar from '../display/avatar';
import Badge from '../display/badge';
import {
  MessageCardAvatarWrapper,
  MessageCardButtonWrapper,
  MessageCardContent,
  MessageCardDetails,
  MessageCardTimeCreated,
  MessageCardWrapper,
} from './styles';
import { MessageCardProps } from './types';

const MessageCard = ({
  actionElement,
  createdAt,
  isClickableAvatar,
  userName,
  userAvatar,
  message,
  onAvatarClick,
  id,
}: MessageCardProps) => {
  return (
    <MessageCardWrapper className='message-card-wrapper' id={id}>
      <MessageCardAvatarWrapper className='message-card-avatar-wrapper'>
        <Avatar
          image={userAvatar}
          name={userName}
          onClick={isClickableAvatar ? onAvatarClick : undefined}
          shape='circle'
          size='sm'
        />
      </MessageCardAvatarWrapper>
      <MessageCardDetails className='message-card-details'>
        <MessageCardContent className='message-card-content' hasActionElement={!!actionElement}>
          {message}
        </MessageCardContent>
        <MessageCardTimeCreated className='message-card-time'>
          <Badge text={createdAt} type='general' />
        </MessageCardTimeCreated>
        {actionElement && <MessageCardButtonWrapper>{actionElement}</MessageCardButtonWrapper>}
      </MessageCardDetails>
    </MessageCardWrapper>
  );
};

export default MessageCard;
