import React from 'react';

import Icon from '../../display/icons/Icon';
import { InputNumberControlDown, InputNumberControlUp, InputNumberStyled } from './styles';
import { Props } from './types';

const InputNumber = ({ max, min, value, onChange, step, valueCharacter = '' }: Props) => {
  return (
    <InputNumberStyled
      downHandler={
        <InputNumberControlDown>
          <Icon name='caret-down' weight='solid' />
        </InputNumberControlDown>
      }
      formatter={(num: number) => num + valueCharacter}
      max={max}
      min={min}
      onChange={onChange}
      step={step}
      upHandler={
        <InputNumberControlUp>
          <Icon name='caret-up' weight='solid' />
        </InputNumberControlUp>
      }
      value={value}
    />
  );
};

export default InputNumber;
