import { FlashType, useFlashNotification } from '../components/FlashNotificationContext';

interface CopyUrlLinkParams {
  path: string;
  flashText: string;
  flashType?: FlashType;
}

const useCopyUrlLink = () => {
  const { flash } = useFlashNotification();

  const copyUrlLink = ({ path, flashText, flashType = 'info' }: CopyUrlLinkParams) => {
    const originUrl = window.location.origin;
    const urlToCopy = originUrl + path;
    const el = document.createElement('input');

    el.value = urlToCopy;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    flash(flashType, flashText);
  };

  return { copyUrlLink };
};

export default useCopyUrlLink;
