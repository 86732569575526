import { FlowResult, flow } from '@prosperstack/flow';
import React, { useEffect, useState } from 'react';

import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../lib/initTranslations';
import { messageFromError } from '../../../../redux/errors/helpers';
import { useFetchProsperstackDataMutation } from '../../../../redux/services/resourceApis/billing/billingApi';
import { useDestroySubscriptionMutation } from '../../../../redux/services/resourceApis/subscriptions/subscriptionsApi';
import { useFlashNotification } from '../../../FlashNotificationContext';

const t = initTranslations();

export interface ProsperStackCancelLinkProps {
  text?: string;
  flowProcessing: boolean;
  triggerFlow: () => void;
}

interface Props {
  children: (props: ProsperStackCancelLinkProps) => React.ReactElement;
}

const ProsperStackCancelLinkContainer = ({ children }: Props) => {
  const [flowProcessing, setFlowProcessing] = useState(false);
  const [destroySubscriptionMutation, destroySubscriptionResult] = useDestroySubscriptionMutation();
  const [fetchProsperstackDataMutation, fetchProsperstackDataResult] =
    useFetchProsperstackDataMutation();
  const {
    isLoading: isLoadingProsperstackData,
    isSuccess: isSuccessFetchProsperstackData,
    error: fetchProsperstackError,
    data: fetchProsperstackData,
  } = fetchProsperstackDataResult;
  const { error: destroySubscriptionError } = destroySubscriptionResult;
  const { flash } = useFlashNotification();
  const isStartingProsperstackFlow = flowProcessing && isLoadingProsperstackData;

  function stopFlowProcessing() {
    setFlowProcessing(false);
  }

  const handleFlowResult = (result: FlowResult) => {
    const { offer_accepted, cancel_reason } = result.flowSession;
    const cancellationReason = cancel_reason?.text ? cancel_reason.text : '';
    const flashConfig = { autoClose: 5000, onClose: stopFlowProcessing };

    if (result.status === 'saved') {
      const offerDetails = offer_accepted?.details.type;

      switch (offerDetails) {
        case 'change_plan':
          flash('info', t('prosperstack.change_plan'), flashConfig);
          break;

        case 'coupon':
          flash('info', t('prosperstack.coupon'), flashConfig);
          break;
      }
    } else if (result.status === 'canceled') {
      destroySubscriptionMutation({
        cancellationType: 'cancel',
        cancellationReason,
      }).then(stopFlowProcessing);
    } else {
      stopFlowProcessing();
    }
  };

  const triggerFlow = () => {
    setFlowProcessing(true);
    fetchProsperstackDataMutation();
  };

  useDisplayFlashOnResponse({
    result: destroySubscriptionResult,
    errorMessage: messageFromError(destroySubscriptionError)?.join(' '),
    successMessage: t('prosperstack.cancel_plan'),
    autoClose: 5000,
    skip: isStartingProsperstackFlow,
  });

  useEffect(() => {
    if (!flowProcessing || isStartingProsperstackFlow) return;

    if (isSuccessFetchProsperstackData && fetchProsperstackData) {
      flow(fetchProsperstackData).then(handleFlowResult);
    } else {
      flash('warn', messageFromError(fetchProsperstackError)?.join(' '));
      stopFlowProcessing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    flowProcessing,
    isSuccessFetchProsperstackData,
    fetchProsperstackData,
    fetchProsperstackError,
    isLoadingProsperstackData,
    isStartingProsperstackFlow,
  ]);

  return children({ flowProcessing, triggerFlow });
};

export default ProsperStackCancelLinkContainer;
