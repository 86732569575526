import React, { useState } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import MessageCard from '../../../../design_system/MessageCard/MessageCard';
import { routes } from '../../../publicApplication/applicationRouter';
import RouteTo from '../../../publicApplication/routeTo';
import RecoverContentModal from '../../../reports/ActivityLogPage/ActivityLogTab/RecoverContentModal';
import NotificationUserName from '../../notifications/NotificationUserName/NotificationUserName';
import ActivityDetails from '../ActivityDetails';
import ActivityRestoreButton from './ActivityRestoreButton/ActivityRestoreButton';
import { ActivityCardProps } from './types';

const ActivityCard = ({ activity, productTerm }: ActivityCardProps) => {
  const { slug } = useCurrentAccount();
  const { created_at, owner, restorable, processing } = activity;
  const [recoverContentModalOpen, setRecoverContentModalOpen] = useState<boolean>(false);
  const [inRecoveryProcess, setInRecoveryProcess] = useState<boolean>(processing);

  const onAvatarClickHandler = () =>
    RouteTo(
      routes.userProfile({
        slug,
        id: owner.id,
        breadcrumb: routes.notifications({ slug }),
      })
    );

  return (
    <div className='activity-card'>
      <MessageCard
        actionElement={
          <ActivityRestoreButton
            id={activity.id}
            inRecoveryProcess={inRecoveryProcess}
            restorable={restorable}
            setRecoverContentModalOpen={setRecoverContentModalOpen}
          />
        }
        createdAt={created_at}
        id={`activity-card-${activity.id}`}
        isClickableAvatar={owner.is_active}
        message={
          <>
            <NotificationUserName user={owner} />
            <ActivityDetails activity={activity} productTerm={productTerm} />
          </>
        }
        onAvatarClick={onAvatarClickHandler}
        userAvatar={owner.avatar}
        userName={owner.name}
      />

      {restorable && !inRecoveryProcess && (
        <RecoverContentModal
          activityId={activity.id}
          closeModal={() => setRecoverContentModalOpen(false)}
          setProcessing={() => setInRecoveryProcess(true)}
          showModal={recoverContentModalOpen}
        />
      )}
    </div>
  );
};

export default ActivityCard;
