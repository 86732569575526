import { Link } from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { Content, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { isEqual } from 'lodash';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { EditorProvider } from '../../../../../contexts/EditorContext';
import { EditorToolbarProvider } from '../../../../../contexts/EditorToolbarContext';
import useContentStyles from '../../../../../hooks/useContentStyles';
import initTranslations from '../../../../../lib/initTranslations';
import { ContentStyles } from '../../../../../types/ContentStyle';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { fontSm5 } from '../../../../styled/TypeSystem';
import editorStyles from '../../EditorStyles';
import EditorHeading from '../../tiptap_extensions/heading';
import Paragraph from '../../tiptap_extensions/paragraph';
import ResizableImage from '../../tiptap_extensions/resizable_image';
import Toolbar, { ButtonGroup, ToolbarSimple } from '../../toolbar';
import BubbleMenuComponent from '../BubbleMenu';

export const EditorWrapper = styled.div<{ editable: boolean }>`
  padding: 0;
  border: ${({ editable, theme: { constants } }) => (editable ? constants.borderWidthSm : 0)} solid
    ${({ theme: { vars } }) => vars.borderSurface2};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const StyledEditorContent = styled(EditorContent)<{
  $editable: boolean;
  $contentStyles: ContentStyles;
}>`
  color: ${({ theme: { vars } }) => vars.textDefault};
  padding: ${({ $editable, theme: { constants } }) =>
    $editable ? `${constants.spacerSm3} ${constants.spacerMd2}` : 0};
  width: 100%;

  .ProseMirror {
    outline: 0;
  }

  .ProseMirror-gapcursor:after {
    display: none;
  }

  ${editorStyles};

  p {
    color: ${({ theme: { vars } }) => vars.textDefault} !important;
    ${fontSm5};
  }
`;

const t = initTranslations('rich_textarea');

type ClickCallbacks =
  | {
      onSave: (content: object | null) => void;
      onCancel: () => void;
    }
  | {
      onSave?: never;
      onCancel?: never;
    };

export type Props = {
  content: Content;
  editable: boolean;
  dockedToolbarButtons?: ButtonGroup[];
  bubbleToolbarButtons?: ButtonGroup[];
} & ClickCallbacks;

const RichTextarea = ({
  onSave,
  onCancel,
  content,
  editable = false,
  dockedToolbarButtons = ToolbarSimple,
  bubbleToolbarButtons = [],
}: Props) => {
  const contentStyles = useContentStyles();
  const editor = useEditor(
    {
      editorProps: {
        attributes: {
          translate: editable ? 'no' : 'yes',
        },
      },
      extensions: [
        StarterKit.configure({ heading: false, paragraph: false }),
        Underline,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        EditorHeading,
        Paragraph,
        ResizableImage,
        Link.configure({
          openOnClick: false,
        }),
      ],
      content,
      editable,
    },
    [editable, content]
  );

  useEffect(() => {
    if (editor && editor.isEditable) {
      editor.commands.focus('start');
    }
  }, [editor]);

  if (!editor) return <></>;

  return (
    <EditorProvider editor={editor}>
      <EditorWrapper editable={editor.isEditable}>
        <EditorToolbarProvider buttons={bubbleToolbarButtons} context='bubble'>
          <BubbleMenuComponent />
        </EditorToolbarProvider>
        <EditorToolbarProvider buttons={dockedToolbarButtons} context='docked'>
          {editor.isEditable && <Toolbar />}
        </EditorToolbarProvider>
        <StyledEditorContent
          $contentStyles={contentStyles}
          $editable={editor.isEditable}
          autoFocus
          editor={editor}
        />
      </EditorWrapper>
      {editor.isEditable && (
        <ButtonWrapper>
          <DefaultButton
            buttonType='secondary'
            id='rich-textarea-cancel-button'
            onClick={() => onCancel && onCancel()}
            text={t('cancel')}
          />
          <DefaultButton
            id='rich-textarea-save-button'
            onClick={() => {
              // If the content is equal to what is in the editor (no changes)
              content && isEqual(editor.getJSON(), content)
                ? // Clicking Save will not update, will just be as if Cancel CTA had been clicked, no flash will appear
                  onCancel && onCancel()
                : // if there are changes, content will save and a flash will appear
                  onSave && onSave(editor.isEmpty ? null : editor.getJSON());
            }}
            text={t('save')}
          />
        </ButtonWrapper>
      )}
    </EditorProvider>
  );
};

export default RichTextarea;
