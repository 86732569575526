import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import styled, { createGlobalStyle } from 'styled-components';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import usePrivateConfigs from '../../../../hooks/usePrivateConfigs';
import initTranslations from '../../../../lib/initTranslations';
import Icon from '../../../design_system/display/icons/Icon';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';

const BeamerNotificatorStyles = createGlobalStyle`
  .beamer_icon.active {
    top: -8px !important;
  }
`;

const BeamerIconButtonWrapper = styled.div`
  display: flex;
  align-self: center;
  cursor: pointer;
  padding-right: ${({ theme: { constants } }) => constants.spacerMd1};
`;

const t = initTranslations('top_nav');

const BeamerIcon = () => {
  const { status, stripePlanId } = useCurrentAccount();
  const { email, firstName, id, lastName, permission } = useCurrentUser();
  const { configs } = usePrivateConfigs();
  const beamerProductId = configs['BEAMER_PRODUCT_ID'];
  const hasPermission = ['billing_admin', 'admin'].includes(permission);

  if (!beamerProductId || !hasPermission) return <></>;

  return (
    <>
      <Tooltip id='beamer-button-tooltip' text={t('beamer_tooltip')} />
      <BeamerIconButtonWrapper className='beamerTrigger' data-for='beamer-button-tooltip' data-tip>
        <Icon name='gift' size='sm' />
      </BeamerIconButtonWrapper>
      <HelmetProvider>
        <Helmet>
          <script>
            {`
             var beamer_config = {
               product_id: '${beamerProductId}',
               stripe_plan_id: '${stripePlanId}',
               account_status: '${status}',
               user_permission: '${permission}',
               user_id: ${id},
               user_firstname: '${firstName}',
               user_lastname: '${lastName}',
               user_email: '${email}',
             };
          `}
          </script>
          <script defer src='https://app.getbeamer.com/js/beamer-embed.js' />
        </Helmet>
      </HelmetProvider>
      <BeamerNotificatorStyles />
    </>
  );
};

export default BeamerIcon;
