import { Coupon } from '../../types/Coupon';
import { Discount } from '../../types/Discount';

export function couponToDiscount(coupon: Coupon | null) {
  if (coupon?.percentOff)
    return {
      type: 'percentOff',
      amount: coupon.percentOff,
    } as Discount;

  if (coupon?.amountOff)
    return {
      type: 'amountOff',
      amount: coupon.amountOff,
    } as Discount;

  return undefined;
}
