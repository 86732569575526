import { Discount } from '../types/Discount';

const numberWithCommas = (x: number) => {
  const currency = x % 1 === 0 ? x.toString() : x.toFixed(2);

  return currency.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const formatPrice = (value: number, discount?: Discount, isAnnualPerMonthPrice?: boolean) => {
  const annualPerMonthPriceWithDiscount = setPriceWithDiscount(value * 12, discount) / 12;
  const annualPriceWithDiscount = setPriceWithDiscount(value, discount);
  const priceWithDiscount = isAnnualPerMonthPrice
    ? annualPerMonthPriceWithDiscount
    : annualPriceWithDiscount;

  return numberWithCommas(priceWithDiscount);
};

export const getFormattedPrice = (
  value: number,
  discount?: Discount,
  isAnnualPerMonthPrice?: boolean
) => {
  const formattedPrice = formatPrice(value, discount, isAnnualPerMonthPrice);

  return value >= 0 ? `$${formattedPrice}` : `-$${formattedPrice.slice(1)}`;
};

const setPriceWithDiscount = (value: number, discount?: Discount) => {
  switch (discount?.type) {
    case 'amountOff':
      return discount.amount / 100.0 > value ? 0 : value - discount.amount / 100.0;
      break;
    case 'percentOff':
      return value - value * (discount.amount / 100.0);
      break;
    default:
      return value;
  }
};

export const getFormattedPricePerUser = (
  value: number,
  seats: number,
  isAnnualInterval: boolean,
  discount?: Discount
) => {
  const priceWithDiscount = setPriceWithDiscount(value, discount);
  const pricePerUser = isAnnualInterval
    ? priceWithDiscount / seats / 12
    : priceWithDiscount / seats;

  return `$${numberWithCommas(pricePerUser)}`;
};
