import React, { useState } from 'react';

import { TeammateModalProvider } from '../../../../../contexts/TeammateContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import usePageTitle from '../../../../../hooks/usePageTitle';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import PageContent from '../../../shared/page_content';
import ReferAFriendModal from '../../shared/ReferAFriendModal/ReferAFriendModal';
import BillingPagePlansSectionFactory from '../../simple_pricing/BillingPage/BillingPagePlansSectionFactory/BillingPagePlansSectionFactory';
import BillingPageInformationCard from '././BillingPageInformationCard/BillingPageInformationCard';
import BillingPageCancelSubscription from './BillingPageCancelSubscription/BillingPageCancelSubscription';
import BillingPageHeader from './BillingPageHeader/BillingPageHeader';
import BillingPagePlanCard from './BillingPagePlanCard/BillingPagePlanCard';
import EmptyState from './BillingPagePlanCard/EmptyState/EmptyState';
import BillingPageReferAFriend from './BillingPageReferAFriend/BillingPageReferAFriend';
import BillingPageSkeleton from './BillingPageSkeleton/BillingPageSkeleton';
import { CardsWrapper } from './shared/styles';
import { useGetBillingData } from './utils/useGetBillingData';

export const BillingPage = () => {
  const [showReferAFriendModal, setShowReferAFriendModal] = useState(false);
  const { billingDetailsData, billingPlansPriceData, planCardData, isLoadingBillingData } =
    useGetBillingData();
  const {
    status: accountStatus,
    onHoldPlan,
    onLegacyPlan,
    onOneOfAnnualUnlimitedPlans,
    unlimitedUserSlots,
    splitFeatures: { fullScreenManageTrialPlanOverlayEnabled, simplePricingEnabled },
  } = useCurrentAccount();
  const isTrialEnded = accountStatus === 'trial_ended';
  const isTrialing = accountStatus === 'trialing';
  const canShowReferAFriendBanner = fullScreenManageTrialPlanOverlayEnabled
    ? !isTrialEnded
    : !(isTrialEnded || isTrialing);

  const { productTerm } = useAccountTerminology();

  usePageTitle({ resourceType: 'billing', productTerm });

  return (
    <PageContent>
      <BillingPageHeader />
      {isLoadingBillingData ? (
        <BillingPageSkeleton />
      ) : (
        <>
          <CardsWrapper>
            {planCardData ? (
              <TeammateModalProvider>
                <BillingPagePlanCard
                  onHoldPlan={onHoldPlan}
                  onLegacyPlan={onLegacyPlan}
                  onOneOfAnnualUnlimitedPlans={onOneOfAnnualUnlimitedPlans}
                  planCardData={planCardData}
                  simplePricingEnabled={simplePricingEnabled}
                  unlimitedUserSlots={unlimitedUserSlots}
                />
              </TeammateModalProvider>
            ) : (
              <EmptyState />
            )}
            {billingDetailsData && (
              <BillingPageInformationCard billingDetailsData={billingDetailsData} />
            )}
          </CardsWrapper>

          {billingPlansPriceData && (
            <BillingPagePlansSectionFactory billingPlansPriceData={billingPlansPriceData} />
          )}

          {canShowReferAFriendBanner && planCardData && (
            <>
              {!onHoldPlan && (
                <BillingPageReferAFriend
                  isTrialing={isTrialing}
                  showReferAFriendModal={() => setShowReferAFriendModal(true)}
                />
              )}
              {!isTrialing && (
                <BillingPageCancelSubscription
                  billingInterval={planCardData.billingInterval}
                  simplePricingEnabled={simplePricingEnabled}
                />
              )}
              <ReferAFriendModal
                onCloseRequest={() => setShowReferAFriendModal(false)}
                showModal={showReferAFriendModal}
              />
            </>
          )}
        </>
      )}
    </PageContent>
  );
};
