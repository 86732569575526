import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../lib/initTranslations';
import {
  toggleSidebar,
  useCheckSidebarState,
} from '../../../../redux/domains/collapsibleSidebar/collapsibleSidebarSlice';
import { useAppDispatch } from '../../../../redux/hooks';
import IconButton from '../../../design_system/buttons/IconButton';
import { mediaBreakpointPxXl } from '../../../styled/Breakpoint';
import FlexContainer from '../../../styled/FlexContainer';

const MenuIconWrapper = styled(FlexContainer)`
  justify-content: center;
  display: block;

  @media (min-width: ${mediaBreakpointPxXl}) {
    display: none;
  }
`;

const StyledIconButton = styled(IconButton)`
  display: flex;
`;

const t = initTranslations('hamburger_menu');

const HamburgerMenu = () => {
  const isExpanded = useCheckSidebarState('isExpanded');
  const dispatch = useAppDispatch();

  return (
    <MenuIconWrapper align='center'>
      <StyledIconButton
        ariaLabel={isExpanded ? t('aria_label_close_menu') : t('aria_label_open_menu')}
        id='top-nav-menu-icon'
        name='bars'
        onClick={() => dispatch(toggleSidebar())}
      />
    </MenuIconWrapper>
  );
};

export default HamburgerMenu;
