import { useReferAFriendModal } from '../../../../../contexts/ReferAFriendContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import { routes } from '../../../publicApplication/applicationRouter';
import routeTo from '../../../publicApplication/routeTo';
import useActiveMenuHandler from '../../../publicApplication/utils/useActiveMenuHandler';
import { USER_MENU_ID } from '../UserMenu';

export const useShowReferralModal = () => {
  const { closeMenu } = useActiveMenuHandler({ menuId: USER_MENU_ID });
  const {
    slug,
    status,
    splitFeatures: { fullScreenManageTrialPlanOverlayEnabled },
  } = useCurrentAccount();
  const { showReferralModal, setShowReferralModal } = useReferAFriendModal();

  const handleClickOnReferAFriendButton = () => {
    if (fullScreenManageTrialPlanOverlayEnabled && status === 'trialing') {
      routeTo(routes.billing({ slug }));
      closeMenu();
    } else {
      closeMenu();
      setShowReferralModal(true);
    }
  };

  return { showReferralModal, setShowReferralModal, handleClickOnReferAFriendButton };
};
