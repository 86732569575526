import queryString from 'query-string';
import { useMemo } from 'react';

import getQueryParams from '../../../../lib/getQueryParams';
import { isSector } from '../../../../lib/isSector';
import { useAppSelector } from '../../../../redux/hooks';
import { Sector } from '../../../../types/Sector';

interface CurriculumParams {
  page: number;
  filtersQuery: string;
  sector: Sector;
}

const defaultSector: Sector = 'process';

const useCurriculumParams = (): CurriculumParams => {
  const {
    page,
    filters: {
      selectedFields: { status, type: kind, contentAccess: content_access, groupId: group_id },
    },
  } = useAppSelector((store) => store.curriculums);
  const q = useMemo(() => {
    return {
      status,
      kind,
      content_access,
      group_id,
    };
  }, [content_access, group_id, status, kind]);

  const [sectorParam] = getQueryParams(['sector']) as Sector[] | null[];
  const filtersQuery = queryString.stringify(q, { skipNull: true });
  const invalidSector = !!sectorParam && !isSector(sectorParam);
  const sector = invalidSector || !sectorParam ? defaultSector : sectorParam;

  return { page, sector, filtersQuery };
};

export default useCurriculumParams;
