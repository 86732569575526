import React, { useEffect } from 'react';

import { useGetDocumentUploadsQuery } from '../../../../redux/services/resourceApis/documentUploads/documentUploadsApi';
import useCurriculumParams from '../../curriculums/hooks/useCurriculumParams';
import { Container } from './styles';
import UploadProgressItem from './UploadProgressItem/UploadProgressItem';

const DocumentUploadProgress = () => {
  const { sector } = useCurriculumParams();
  const { data, refetch } = useGetDocumentUploadsQuery({ sector });

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (!data || !data.documentUploads.length) return <></>;

  return (
    <Container>
      {data.documentUploads.map((documentUpload) => (
        <UploadProgressItem
          documentUpload={documentUpload}
          key={`document-upload-progress-item-${documentUpload.id}`}
        />
      ))}
    </Container>
  );
};

export default DocumentUploadProgress;
