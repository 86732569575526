import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { BillingInterval } from '../../../../../types/BillingInterval';
import { LabelBackground, ToggleWrapper } from './styles';

type BillingIntervalToggleProps = {
  handleChange: React.Dispatch<React.SetStateAction<string>>;
  isAnnualChecked: boolean;
  isLoading: boolean;
  isAnnualValueFirst?: boolean;
  isDisabled?: boolean;
};

const t = initTranslations('simple_pricing.billing_page.plans.billing_interval_toggle');

const BillingIntervalToggle = ({
  isLoading,
  isAnnualChecked,
  isAnnualValueFirst = false,
  handleChange,
  isDisabled = false,
}: BillingIntervalToggleProps) => {
  const generateClassName = (interval: BillingInterval) => {
    const isChecked =
      (isAnnualChecked && interval === 'year') || (!isAnnualChecked && interval === 'month');

    return `billing-interval-toggle-${interval} ${isChecked ? 'checked' : ''}`;
  };

  return (
    <ToggleWrapper
      className='billing-interval-toggle-wrapper'
      isUnclickable={isLoading || isDisabled}
    >
      <LabelBackground
        className={generateClassName('month')}
        isAnnualValueFirst={!isAnnualValueFirst}
        isChecked={!isAnnualChecked}
        isDisabled={isDisabled}
        onClick={() => handleChange('month')}
      >
        {t('monthly')}
      </LabelBackground>
      <LabelBackground
        className={generateClassName('year')}
        isAnnualValueFirst={isAnnualValueFirst}
        isChecked={isAnnualChecked}
        onClick={() => handleChange('year')}
      >
        {t('yearly')}
      </LabelBackground>
    </ToggleWrapper>
  );
};

export default BillingIntervalToggle;
