type QueryParams = (string | null)[];

const fetchUrl = () => {
  return new URL(window.location.href);
};

export const fetchQueryParameters = () => {
  return new URLSearchParams(window.location.search);
};

const setUrlState = (url: URL, turbolinks = true) => {
  // Replacing '+' with '%20' to correctly handle spaces encoded in query strings.
  const formattedUrl = url.toString().replace(/\+/g, '%20');
  const decodedUrl = decodeURIComponent(formattedUrl);

  return window.history.pushState({ turbolinks, url: decodedUrl }, '', decodedUrl);
};

const replaceUrlState = (url: URL) => {
  return window.history.replaceState(null, '', url.toString());
};

export const deleteQueryParam = (key: string, replaceState = false) => {
  const url = fetchUrl();

  url.searchParams.delete(key);
  replaceState ? replaceUrlState(url) : setUrlState(url);
};

export const setQueryParam = (key: string, value: string) => {
  const url = fetchUrl();

  url.searchParams.set(key, value);
  setUrlState(url);
};

export type UpdateQuery =
  | {
      key: string;
      value: string;
      action: 'add';
    }
  | {
      key: string;
      action: 'remove';
    };

// Update query string parameters only if the requested change is necessary.
export const updateQueryParams = (params: UpdateQuery[], turbolinks = true) => {
  if (params.length > 0) {
    const url = fetchUrl();
    let didUpdate = false;

    params.forEach((update: UpdateQuery) => {
      if (update.action === 'add') {
        // To avoid adding a history item only set a parameter if it has changed.
        if (url.searchParams.get(update.key) != update.value) {
          url.searchParams.set(update.key, update.value);
          didUpdate = true;
        }
      } else {
        // Don't delete a key if we are going to just add it again.
        if (!params.some((item) => item.action === 'add' && item.key === update.key)) {
          url.searchParams.delete(update.key);
          didUpdate = true;
        }
      }
    });

    // Only call setUrlState if we made a change to prevent adding an item to history
    if (didUpdate) {
      setUrlState(url, turbolinks);
    }
  }
};

export default function getQueryParams(params: string[]): QueryParams {
  const queryParams = new URLSearchParams(window.location.search);

  return params.map((param) => queryParams.get(param));
}
