import React from 'react';

import { SortProps } from '../../TableTypes';
import { TableColumn } from './AllUsersReportColumn';
import AllUsersReportTableHeaderCell from './AllUsersReportTableHeaderCell';
import { HeaderRow } from './styles';
import { TableHeaderProps } from './types';

const AllUsersReportTableHeader = ({ columns, tableHeaderState, dispatch }: TableHeaderProps) => {
  return (
    <thead>
      <HeaderRow>
        {columns.map((column: TableColumn) => {
          const activeColumn = tableHeaderState.sortColumn === column.columnName;
          let sortProps: SortProps = { isSortable: column.sortable };

          if (column.sortable) {
            const sortIcon = activeColumn ? tableHeaderState.sortIcon : 'sort';
            sortProps = { isSortable: true, sortIcon };
          }

          return (
            <AllUsersReportTableHeaderCell
              activeHeader={activeColumn}
              column={column}
              key={`header-${column.columnName}`}
              setActiveHeader={() => {
                if (column.sortable) {
                  dispatch({ type: 'sortColumnChange', sortColumn: column.columnName });
                }
              }}
              {...sortProps}
            />
          );
        })}
      </HeaderRow>
    </thead>
  );
};

export default AllUsersReportTableHeader;
