import { toCamelCase, toSnakeCase } from '../../../../lib/keyFormatConverter';
import { ConsumptionCourseResponse, EditorCourseResponse } from '../../../../types/Editor';
import { trainualApi } from '../../trainualService';
import { UploadImageResponse } from '../../types';
import { curriculumElementListTags } from '../curriculumElements/helpers';
import { assignUsersListTags } from '../curriculums/helpers';
import {
  ExportTopicParams,
  ToggleESignatureParams,
  UpdateTopicHeaderParams,
  UpdateTopicParams,
} from '../steps/types';
import { editorCourseTags } from './helpers';
import { CourseFirstStepIdResponse } from './types';

export const topicsApi = trainualApi.injectEndpoints({
  endpoints: (builder) => ({
    getEditorTopic: builder.query<EditorCourseResponse, { courseId: number }>({
      query: ({ courseId }) => ({ url: `ajax/editor/${courseId}/edit` }),
      transformResponse: (response: EditorCourseResponse) => {
        return toCamelCase(response);
      },
      providesTags: (result) => editorCourseTags(result),
    }),
    getConsumptionTopic: builder.query<ConsumptionCourseResponse, { courseId: number }>({
      query: ({ courseId }) => ({ url: `ajax/editor/${courseId}` }),
      transformResponse: (response: ConsumptionCourseResponse) => {
        return toCamelCase(response);
      },
      providesTags: (result) => editorCourseTags(result),
    }),
    getEditorCollabToken: builder.query<string, number>({
      query: (id: number) => ({ url: `ajax/editor/${id}/fetch_collab_token` }),
      keepUnusedDataFor: 10 * 60,
    }),
    updateTopic: builder.mutation<undefined, UpdateTopicParams>({
      query: ({ id, title, emoji, custom_reading_time }: UpdateTopicParams) => ({
        url: `ajax/courses/${id}`,
        method: 'PUT',
        body: { title, emoji, custom_reading_time },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Topic', id: arg.id },
        { type: 'CurriculumElement', id: `Element-${arg.id}` },
        { type: 'Topic', id: 'LIST' },
      ],
    }),
    updateTopicCoverPhoto: builder.mutation<UploadImageResponse, UpdateTopicHeaderParams>({
      query: (data) => ({
        url: `ajax/uploads/course_cover_photo?course_id=${data.course_id}`,
        method: 'PUT',
        body: data.image,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Topic', id: arg.course_id },
        { type: 'Topic', id: 'LIST' },
      ],
    }),
    eSignatureToggle: builder.mutation<null, ToggleESignatureParams>({
      query: ({ id, signatureRequired }) => ({
        url: `ajax/e_signature`,
        params: toSnakeCase({ id, signatureRequired }),
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) => [
        ...curriculumElementListTags({ id: arg.id }),
        ...assignUsersListTags(arg.id),
      ],
    }),
    exportTopic: builder.mutation<undefined, ExportTopicParams>({
      query: ({ curriculumId, coursesIds }: ExportTopicParams) => ({
        url: `ajax/courses/print`,
        method: 'PUT',
        body: toSnakeCase({ curriculumId, coursesIds }),
      }),
    }),
    getTopicFirstStepId: builder.query<CourseFirstStepIdResponse, number>({
      query: (id: number) => ({ url: `ajax/courses/${id}/first_step_id` }),
      providesTags: (_result, _errors, id) => {
        return [{ type: 'Topic', id }];
      },
      transformResponse: (response: CourseFirstStepIdResponse) => {
        return toCamelCase(response);
      },
    }),
  }),
});

export const {
  useGetEditorTopicQuery,
  useGetConsumptionTopicQuery,
  useGetEditorCollabTokenQuery,
  useUpdateTopicMutation,
  useESignatureToggleMutation,
  useExportTopicMutation,
  useUpdateTopicCoverPhotoMutation,
  useGetTopicFirstStepIdQuery,
} = topicsApi;
