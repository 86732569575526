import React from 'react';

import useCurriculumElement from '../../../../hooks/useCurriculumElement';
import initTranslations from '../../../../lib/initTranslations';
import titleize from '../../../../lib/titleize';
import SourceBadge from '../../../design_system/navigation/SourceBadge/SourceBadge';
import { getContentTypeFromActivityKey } from './helpers';
import { TextFragment } from './styles';
import { CurriculumElementTrackable } from './types';

const t = initTranslations('reports.activity_log');

export interface StatusChangedProps {
  activityKey: string;
  trackable: CurriculumElementTrackable;
  parameters: { status_was: string; status_now: string };
}

const StatusChanged = ({ trackable, activityKey, parameters }: StatusChangedProps) => {
  const { id, name, is_active } = trackable;
  const elementKind = getContentTypeFromActivityKey(activityKey);
  const { showRoute } = useCurriculumElement()({ elementKind, elementId: id });

  return (
    <>
      <TextFragment>{t(activityKey)}</TextFragment>
      <SourceBadge
        readOnly={!is_active}
        sourceName='curriculum'
        sourceRoute={showRoute}
        sourceText={name}
      />
      <TextFragment>
        <span
          dangerouslySetInnerHTML={{
            __html: t('course.status_changes', {
              was: titleize(parameters.status_was),
              now: titleize(parameters.status_now),
            }),
          }}
        />
      </TextFragment>
    </>
  );
};

export default StatusChanged;
