import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import ListItem from '@tiptap/extension-list-item';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import { useEditor } from '@tiptap/react';

const CustomListItem = ListItem.extend({
  content: 'text*',
});

type Props = {
  placeholderText: string;
};

const useBulletListEditor = ({ placeholderText }: Props) => {
  return useEditor(
    {
      extensions: [
        Document,
        BulletList,
        CustomListItem,
        Text,
        Placeholder.configure({
          includeChildren: true,
          placeholder: placeholderText,
        }),
      ],
    },
    []
  );
};

export default useBulletListEditor;
