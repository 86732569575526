import React, { useEffect } from 'react';
import styled from 'styled-components';

import useCurrentUser from '../../../../hooks/useCurrentUser';
import { RegisteredMenuId } from '../../../../lib/idRegistry';
import Avatar from '../../../design_system/display/avatar';
import Poppable from '../../../Poppable';
import FlexContainer from '../../../styled/FlexContainer';
import useActiveMenuHandler from '../../publicApplication/utils/useActiveMenuHandler';
import OutsideClickHandler from '../../shared/OutsideClickHandler';
import UserMenuNavigation from './UserMenuNavigation/UserMenuNavigation';

export const USER_MENU_ID: RegisteredMenuId = 'top-nav-user-menu';

const CompletionStatusWrapper = styled(FlexContainer)`
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => props.theme.constants.spacerSm3};
  cursor: pointer;
`;

const UserAvatar = () => {
  const { avatarUrl, completionPercentage, name } = useCurrentUser();

  return (
    <CompletionStatusWrapper id='top-nav-user-avatar'>
      <Avatar
        className='top-nav-user-avatar-trigger'
        completionPercent={completionPercentage}
        image={avatarUrl || undefined}
        name={name}
        shape='circle'
        size='sm'
      />
    </CompletionStatusWrapper>
  );
};

const UserMenu = () => {
  const { handleMenuClick, isMenuOpen, setActiveMenuId } = useActiveMenuHandler({
    menuId: USER_MENU_ID,
  });
  const queryParams = new URLSearchParams(window.location.search);
  const initialMenuOpen = queryParams.get('open_user_menu') === 'true';

  useEffect(() => {
    if (initialMenuOpen) {
      setActiveMenuId(USER_MENU_ID);
      const url = new URL(window.location.href);
      url.searchParams.delete('open_user_menu');
      window.history.replaceState(null, '', url);
    }
  }, [initialMenuOpen, setActiveMenuId]);

  return (
    <Poppable
      isOpen={isMenuOpen}
      item={
        <OutsideClickHandler
          onOutsideClick={(e) => {
            if (!(e.target as Element).classList.contains('top-nav-user-avatar-trigger')) {
              handleMenuClick();
            }
          }}
        >
          <UserMenuNavigation id='top-nav-user-menu' />
        </OutsideClickHandler>
      }
      onClick={() => {
        handleMenuClick();
      }}
      placement='bottom-start'
      trigger={<UserAvatar />}
    />
  );
};

export default UserMenu;
