import { Column } from './TableTypes';

export enum LoadingSize {
  small = '2rem',
  medium = '6rem',
  large = '10rem',
}

export const columns: Column[] = [
  {
    columnName: 'name',
    stickyPosition: 'left',
    display: 'static',
    loadingSize: LoadingSize.large,
    sortable: true,
  },
  {
    columnName: 'status',
    display: 'visible',
    loadingSize: LoadingSize.medium,
    sortable: false,
  },
  {
    columnName: 'title',
    display: 'visible',
    loadingSize: LoadingSize.medium,
    sortable: true,
    hideable: true,
  },
  {
    columnName: 'email',
    display: 'visible',
    loadingSize: LoadingSize.large,
    sortable: true,
    hideable: true,
  },
  {
    columnName: 'phone_number',
    display: 'visible',
    loadingSize: LoadingSize.small,
    sortable: false,
    hideable: true,
  },
  {
    columnName: 'reports_to',
    display: 'visible',
    loadingSize: LoadingSize.medium,
    sortable: false,
    hideable: true,
  },
  {
    columnName: 'permission',
    display: 'visible',
    loadingSize: LoadingSize.medium,
    sortable: true,
    hideable: true,
  },
  {
    columnName: 'groups',
    display: 'visible',
    loadingSize: LoadingSize.medium,
    sortable: false,
    hideable: true,
  },
  {
    columnName: 'roles',
    display: 'visible',
    loadingSize: LoadingSize.medium,
    sortable: false,
    hideable: true,
  },
  {
    columnName: 'teams',
    display: 'visible',
    loadingSize: LoadingSize.medium,
    sortable: false,
    hideable: true,
  },
  {
    columnName: 'subject_count',
    display: 'visible',
    contentAlignment: 'end',
    loadingSize: LoadingSize.small,
    sortable: true,
    hideable: true,
  },
  {
    columnName: 'completion_percentage',
    display: 'visible',
    contentAlignment: 'end',
    loadingSize: LoadingSize.small,
    sortable: true,
    hideable: true,
  },
  {
    columnName: 'last_active_at',
    display: 'visible',
    loadingSize: LoadingSize.medium,
    sortable: true,
    hideable: true,
  },
  {
    columnName: 'three_dot_menu',
    stickyPosition: 'right',
    display: 'static',
    loadingSize: LoadingSize.small,
    sortable: false,
  },
];
