import styled from 'styled-components';

import Icon from '../../../../../design_system/display/icons/Icon';
import { TruncatedText } from '../../../../../design_system/Triage/TruncatedText';
import { fontSm4, fontSm5 } from '../../../../../styled/TypeSystem';
import RouterLink from '../../../../publicApplication/RouterLink';

export const QuickSearchResultCardIcon = styled(Icon)<{ isHovered: boolean }>`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  opacity: ${({ isHovered }) => (isHovered ? 1 : 0)};
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 16px;
`;

export const QuickSearchResultCardEmojiWrapper = styled.div<{
  isCircle: boolean;
  isHovered: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 1.5rem;
  height: ${({ theme: { constants } }) => constants.heightXs};
  background: ${({ theme: { vars }, isHovered }) =>
    isHovered ? vars.accentSubdued2 : vars.foundationBase2};
  border-radius: ${({ isCircle, theme: { constants } }) =>
    isCircle ? constants.borderRadiusCircle : constants.borderRadiusMd};
  ${fontSm5};
`;

export const QuickSearchResultCardLink = styled(RouterLink)<{ isHovered: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${({ theme: { vars }, isHovered }) =>
    isHovered ? vars.accentSubdued1 : vars.foundationSurface1};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerSm2} ${constants.spacerLg2} ${constants.spacerSm2} ${constants.spacerLg3}`};
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};

  &:hover,
  &.selected {
    background: ${({ theme: { vars } }) => vars.accentSubdued1};

    > ${QuickSearchResultCardIcon} {
      opacity: 1;
    }

    > ${QuickSearchResultCardEmojiWrapper} {
      background: ${({ theme: { vars } }) => vars.accentSubdued2};
    }
  }

  &:first-of-type {
    margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const QuickSearchResultCardTitleWrapper = styled.div`
  width: calc(100% - 2rem);
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const QuickSearchResultCardTitle = styled.h4`
  margin-top: 0;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm1};
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${TruncatedText({})};
  ${fontSm5};
`;

export const QuickSearchResultCardSubtitle = styled.h6`
  margin: 0;
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${TruncatedText({})};
  ${fontSm4};
`;
