import React from 'react';

import { ColumnComponentProps } from '../types';
import { StyledDateColumn, StyledTime } from './styles';

const LastActiveAt = ({ record: { last_active_at } }: ColumnComponentProps) => {
  return (
    <StyledDateColumn>
      <span>{last_active_at.date}</span>
      <StyledTime>{last_active_at.time}</StyledTime>
    </StyledDateColumn>
  );
};

export default LastActiveAt;
