import styled, { css } from 'styled-components';

import { fontSm5 } from '../../../../styled/TypeSystem';

export const ToggleWrapper = styled.label<{ isUnclickable: boolean }>`
  display: inline-block;
  position: relative;
  width: 10.375rem;
  height: 2.375rem;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};
  overflow: hidden;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { vars, constants } }) =>
    `${constants.borderWidthLg} solid ${vars.trainualBrandBlueStrong}`};
  ${({ isUnclickable }) => isUnclickable && 'pointer-events: none;'};
`;

export const LabelBackground = styled.span<{
  isChecked: boolean;
  isAnnualValueFirst: boolean;
  isDisabled?: boolean;
}>`
  position: absolute;
  top: 0.188rem;
  ${({ isAnnualValueFirst }) => (isAnnualValueFirst ? 'left: 0.188rem;' : 'right: 0.188rem;')};
  width: 50%;
  height: calc(100% - 0.375rem);
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ isDisabled, theme: { vars } }) =>
    `${isDisabled ? vars.textDisabled : vars.textDefault}`};
  transition: background-color 0.1s ease-in-out;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};
  user-select: none;
  &:hover {
    cursor: pointer;
  }
  ${({ theme: { vars, constants }, isChecked }) =>
    isChecked &&
    css`
      background-color: ${isChecked
        ? vars.theOneOffPlanToggleThatShouldNotBeUsed
        : vars.foundationSurface1};
      font-weight: ${isChecked ? constants.fontSemibold : constants.fontMedium};
    `};
  ${fontSm5};
`;
