import React from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import Badge from '../../../../../../design_system/display/badge';
import { BadgeType } from '../../../../../../design_system/display/badge/BadgeTypes';
import { ElementStatus, TableColumnProps } from '../types';

const t = initTranslations('reports.individual_user_report.table.columns.status');

const Status = ({ record: { status } }: TableColumnProps) => {
  const STATUSES_KEYS: { [key in ElementStatus]: BadgeType } = {
    not_started: 'general',
    in_progress: 'info',
    completed: 'success',
    passed: 'success',
    failed: 'error',
  };
  const statusBadgeType = STATUSES_KEYS[status];

  return <Badge text={t(status)} type={statusBadgeType} />;
};

export default Status;
