import { IconName } from '@fortawesome/fontawesome-svg-core';
import React, { ReactNode } from 'react';
import { ColorTokens, TrainualBrandPaletteOption } from 'saguaro';
import styled, { css, useTheme } from 'styled-components';

import { mediaBreakpointPxLg } from '../../../styled/Breakpoint';
import { fontMd1, fontSm1, fontSm3, fontSm4, fontSm5 } from '../../../styled/TypeSystem';
import Icon from '../../display/icons/Icon';
import { TruncatedText } from '../../Triage/TruncatedText';

const getActiveBackgroundColor = (palette: ChipPalette, vars: ColorTokens) => {
  switch (palette) {
    case 'purple':
      return vars.trainualBrandPurpleSurfaceLight;
    case 'green':
      return vars.trainualBrandGreenSurfaceLight;
    case 'yellow':
      return vars.trainualBrandYellowSurfaceLight;
    case 'magenta':
      return vars.trainualBrandMagentaSurfaceLight;
    case 'blue':
      return vars.trainualBrandBlueSurfaceLight;
    case 'brand':
      return vars.accentSubdued1;
    default:
      return vars.trainualBrandPurpleSurfaceLight;
  }
};

const getActiveBorderColor = (palette: ChipPalette, vars: ColorTokens) => {
  switch (palette) {
    case 'purple':
      return vars.trainualBrandPurpleSubdued;
    case 'green':
      return vars.trainualBrandGreenSubdued;
    case 'yellow':
      return vars.trainualBrandYellowMedium;
    case 'magenta':
      return vars.trainualBrandMagentaSubdued;
    case 'blue':
      return vars.trainualBrandBlueSubdued;
    case 'brand':
      return vars.accentPrimaryDefault;
    default:
      return vars.trainualBrandPurpleSubdued;
  }
};

const getSoftDuotoneIconColor = (palette: ChipPalette, vars: ColorTokens) => {
  switch (palette) {
    case 'purple':
      return vars.trainualBrandPurpleSubdued;
    case 'green':
      return vars.trainualBrandGreenSubdued;
    case 'yellow':
      return vars.trainualBrandYellowMedium;
    case 'magenta':
      return vars.trainualBrandMagentaSubdued;
    case 'blue':
      return vars.trainualBrandBlueSubdued;
    case 'brand':
      return vars.accentSubdued4;
    default:
      return vars.trainualBrandPurpleSubdued;
  }
};

const getHeavyDuotoneIconColor = (palette: ChipPalette, vars: ColorTokens) => {
  switch (palette) {
    case 'purple':
      return vars.trainualBrandPurpleStrong;
    case 'green':
      return vars.trainualBrandGreenStrong;
    case 'yellow':
      return vars.trainualBrandYellowStrong;
    case 'magenta':
      return vars.trainualBrandMagentaStrong;
    case 'blue':
      return vars.trainualBrandBlueStrong;
    case 'brand':
      return vars.accentPrimaryHover;
    default:
      return vars.trainualBrandPurpleStrong;
  }
};

const getCardBodyBackgroundColor = (
  active: boolean,
  palette: ChipPalette,
  vars: ColorTokens,
  withGreyBackground: boolean
) => {
  if (active) {
    return getActiveBackgroundColor(palette, vars);
  }

  if (withGreyBackground) {
    return vars.foundationBase1;
  }

  return vars.foundationSurface1;
};

const getActiveTextColor = (hasIcon: boolean, palette: ChipPalette, vars: ColorTokens) => {
  if (palette == 'brand' && !hasIcon) {
    return vars.accentPrimaryDefault;
  } else {
    return vars.textDefault;
  }
};

const CardBody = styled.button<{
  active: boolean;
  fullWidth: boolean;
  palette: ChipPalette;
  disabled: boolean;
  textAlignment: 'start' | 'center';
  hasIcon: boolean;
  withGreyBackground: boolean;
}>`
  background-color: ${({ palette, active, withGreyBackground, theme: { vars } }) =>
    getCardBodyBackgroundColor(active, palette, vars, withGreyBackground)};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  border: ${({ palette, active, theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${
      active ? getActiveBorderColor(palette, vars) : vars.borderSurface2
    }`};
  min-height: ${({ theme: { constants } }) => constants.spacerLg3};
  min-width: ${({ hasIcon }) => (hasIcon ? '6.5rem' : '10.325rem')};
  ${({ fullWidth }) => fullWidth && 'width: 100%'};
  display: flex;
  justify-content: ${({ hasIcon, textAlignment }) =>
    hasIcon || textAlignment === 'start' ? 'flex-start' : textAlignment};
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
  flex-grow: 1;
  color: ${({ hasIcon, palette, theme: { vars }, active }) =>
    !hasIcon && active ? getActiveTextColor(hasIcon, palette, vars) : vars.textDefault};
  transition: all 0.45s ease-in-out;
  cursor: pointer;
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};

  :hover,
  :active,
  :focus {
    background-color: ${({ disabled, palette, theme: { vars } }) =>
      !disabled && getActiveBackgroundColor(palette, vars)};
  }

  ${({ disabled, palette, active, hasIcon }) =>
    disabled &&
    css`
      background-color: ${({ theme: { vars } }) =>
        active ? getActiveBackgroundColor(palette, vars) : vars.foundationBase2};
      border-color: ${({ theme: { vars } }) =>
        active ? getActiveBorderColor(palette, vars) : vars.borderDisabled};
      color: ${({ theme: { vars } }) =>
        active ? getActiveTextColor(hasIcon, palette, vars) : vars.textDisabled};
      cursor: not-allowed;
    `};

  @media (min-width: ${mediaBreakpointPxLg}) {
    padding: ${({
      hasIcon,
      theme: {
        constants: { spacerMd2, spacerSm3 },
      },
    }) => `${hasIcon ? spacerSm3 : spacerMd2} ${spacerMd2}`};
    gap: ${({ theme: { constants } }) => constants.spacerMd2};
  }
`;

const IconWrapper = styled.div<{ active: boolean; disabled: boolean; palette: ChipPalette }>`
  background-color: ${({ palette, active, theme: { vars } }) =>
    active ? vars.foundationSurface1 : getActiveBackgroundColor(palette, vars)};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  aspect-ratio: 1;
  width: ${({ theme: { constants } }) => constants.spacerMd3};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.45s ease-in-out;
  min-height: ${({ theme: { constants } }) => constants.heightSm};
  min-width: ${({ theme: { constants } }) => constants.spacerLg1};

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme: { vars } }) => vars.foundationBase1};
    `};

  @media (min-width: ${mediaBreakpointPxLg}) {
    min-height: ${({ theme: { constants } }) => constants.heightLg};
    min-width: ${({ theme: { constants } }) => constants.spacerLg2};
  }
`;

const TextWrapper = styled.div<{ hasIcon: boolean; textAlignment: TextAlignment }>`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  ${({ textAlignment }) =>
    css`
      align-items: ${textAlignment};
      text-align: ${textAlignment};
    `};
`;

const Text = styled.span<{ hasIcon: boolean }>`
  max-width: 100%;
  white-space: nowrap;
  ${({ hasIcon }) => (hasIcon ? fontSm4 : fontSm5)};

  @media (min-width: ${mediaBreakpointPxLg}) {
    ${({ hasIcon }) => (hasIcon ? fontMd1 : fontSm5)};
  }
`;

const SubText = styled.span`
  width: 100%;
  display: block;
  ${TruncatedText({})};
  ${fontSm1};
  line-height: 0.78rem;

  @media (min-width: ${mediaBreakpointPxLg}) {
    ${fontSm3};
  }
`;

type TextAlignment = 'center' | 'start';

type ChipPalette = TrainualBrandPaletteOption | 'brand';

export type Props = {
  text: string | ReactNode;
  active: boolean;
  fullWidth?: boolean;
  palette: ChipPalette;
  subText?: string;
  onClick: () => void;
  textAlignment?: TextAlignment;
  id: string;
  icon?: IconName;
  disabled?: boolean;
  withGreyBackground?: boolean;
};

const Chip = (props: Props) => {
  const {
    icon,
    fullWidth = false,
    text,
    active,
    subText,
    onClick,
    palette,
    textAlignment = 'center',
    id,
    disabled = false,
    withGreyBackground = false,
  } = props;
  const { vars } = useTheme();

  const showColors = active || !disabled;

  return (
    <CardBody
      active={active}
      data-active={active}
      disabled={disabled}
      fullWidth={fullWidth}
      hasIcon={!!icon}
      id={id}
      onClick={onClick}
      palette={palette}
      textAlignment={textAlignment}
      type='button'
      withGreyBackground={withGreyBackground}
    >
      {icon && (
        <IconWrapper active={active} disabled={disabled} palette={palette}>
          <Icon
            name={icon}
            primaryColor={
              showColors ? getHeavyDuotoneIconColor(palette, vars) : vars.foundationBase4
            }
            secondaryColor={
              showColors ? getSoftDuotoneIconColor(palette, vars) : vars.foundationBase3
            }
            weight='duotone'
          />
        </IconWrapper>
      )}
      <TextWrapper hasIcon={!!icon} textAlignment={textAlignment}>
        <Text hasIcon={!!icon}>{text}</Text>
        {!!subText && <SubText>{subText}</SubText>}
      </TextWrapper>
    </CardBody>
  );
};

export default Chip;
