import React from 'react';
import { useTheme } from 'styled-components';

import { SkeletonLoader } from '../../../shared/SkeletonLoader/styles';

const TableLoader = () => {
  const { constants } = useTheme();
  return (
    <>
      <SkeletonLoader
        borderRadius={constants.borderRadiusXs}
        height={constants.heightSm}
        marginBottom={constants.spacerSm1}
      />
      <SkeletonLoader
        borderRadius={constants.borderRadiusXs}
        height='3.5rem'
        marginBottom={constants.spacerSm1}
      />
      <SkeletonLoader borderRadius={constants.borderRadiusXs} height='3.5rem' />
    </>
  );
};

export default TableLoader;
