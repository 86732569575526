import styled from 'styled-components';

import Icon from '../../../../design_system/display/icons/Icon';
import P from '../../../../design_system/text/P';
import { fontMd3 } from '../../../../styled/TypeSystem';
import { DisabledStyles } from '../../../shared/DisabledState/DisabledState';
import { TitleUnderline } from '../../shared/styles';

export const PlanCardTitle = styled(P)<{ planBrandColor: string }>`
  text-align: center;
  font-weight: ${({ theme: { constants } }) => constants.fontHeavy};
  color: ${({ planBrandColor }) => planBrandColor};
  position: relative;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  &:after {
    ${TitleUnderline};
    height: 0.2rem;
    bottom: -0.2rem;
  }
  ${fontMd3};
`;

export const TooltipIconWrapper = styled.div`
  display: flex;
  width: 1rem;
  height: ${({ theme: { constants } }) => constants.height2xs};
  cursor: pointer;

  &:hover {
    color: ${({ theme: { vars } }) => vars.borderSurface2};
  }
`;

export const TooltipStyledIcon = styled(Icon)`
  pointer-events: none;
`;

export const CardWrapper = styled.div<{ isDisabled?: boolean }>`
  min-height: 16.25rem;
  display: flex;
  flex-direction: column;
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  box-shadow: ${({ theme: { vars } }) => `0 4px 24px ${vars.shadowColorCenterSmall}`};

  ${({ isDisabled }) => isDisabled && DisabledStyles};
`;
