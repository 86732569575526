import { UploadStatus } from '../types';

export type UploadProgressItemState = {
  currentPercentage: number;
  uploadStatus: UploadStatus;
  curriculumId: number | null;
  isVisible: boolean;
  errorMessage: string;
};

export type UploadProgressItemAction =
  | { type: 'setCurrentPercentage'; currentPercentage: number }
  | { type: 'setUploadStatus'; uploadStatus: UploadStatus }
  | { type: 'setCurriculumId'; curriculumId: number | null }
  | { type: 'setIsVisible'; isVisible: boolean }
  | { type: 'setErrorMessage'; errorMessage: string }
  | {
      type: 'updateUploadData';
      currentPercentage: number;
      uploadStatus: UploadStatus;
      curriculumId: number | null;
    };

export const UploadProgressItemReducer = (
  state: UploadProgressItemState,
  action: UploadProgressItemAction
): UploadProgressItemState => {
  switch (action.type) {
    case 'setCurrentPercentage': {
      return { ...state, currentPercentage: action.currentPercentage };
    }
    case 'setUploadStatus': {
      return { ...state, uploadStatus: action.uploadStatus };
    }
    case 'setCurriculumId': {
      return { ...state, curriculumId: action.curriculumId };
    }
    case 'setIsVisible': {
      return { ...state, isVisible: action.isVisible };
    }
    case 'setErrorMessage': {
      return { ...state, errorMessage: action.errorMessage };
    }
    case 'updateUploadData': {
      return {
        ...state,
        currentPercentage: action.currentPercentage,
        curriculumId: action.curriculumId,
        uploadStatus: action.uploadStatus,
      };
    }
  }
};
