import React, { useState } from 'react';

import initTranslations from '../../../../lib/initTranslations';
import {
  CollapsibleSection,
  CollapsibleSectionBody,
  CollapsibleSectionHeader,
  CollapsibleSectionTitle,
  StyledIconButton,
} from './styles';
import { Props } from './types';

const t = initTranslations('reports.filter.collapsible_section.aria_label');

const CollapsibleFiltersSection = ({ children, title, sectorRef }: Props) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <CollapsibleSection ref={sectorRef}>
      <CollapsibleSectionHeader>
        <CollapsibleSectionTitle>{title}</CollapsibleSectionTitle>
        <StyledIconButton
          ariaLabel={collapsed ? t('show_filter') : t('hide_filter')}
          buttonSize='sm'
          className='collapsible-form-element-icon'
          name={collapsed ? 'plus' : 'minus'}
          onClick={() => setCollapsed(!collapsed)}
        />
      </CollapsibleSectionHeader>
      {!collapsed && <CollapsibleSectionBody>{children}</CollapsibleSectionBody>}
    </CollapsibleSection>
  );
};

export default CollapsibleFiltersSection;
