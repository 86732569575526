import React, { useReducer } from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { useGetStepExternalLinksQuery } from '../../../../../../redux/services/resourceApis/stepExternalLinks/stepExternalLinksApi';
import Loader from '../../../../../design_system/Triage/Loader';
import ManageStepExternalLinksForm from '../ManageStepExternalLinksForm/ManageStepExternalLinksForm';
import StepExternalLinksListItem from '../StepExternalLinksListItem/StepExternalLinksListItem';
import { initialState, stepExternalLinksListReducer } from './reducer';
import { ExternalLinkElementWrapper, ListHeader } from './styles';
import { StepExternalLinksListProps } from './types';

const t = initTranslations('step_external_links');

const StepExternalLinksList = ({ stepId }: StepExternalLinksListProps) => {
  const [modalState, dispatch] = useReducer(stepExternalLinksListReducer, initialState);
  const { editId } = modalState;
  const { data, isLoading } = useGetStepExternalLinksQuery({ stepId });

  if (!data) return <></>;

  const { stepExternalLinks } = data;

  return (
    <>
      <ListHeader>{t('added_links')}</ListHeader>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {stepExternalLinks.map((link) => (
            <ExternalLinkElementWrapper
              data-testid={`step-external-link-${link.id}`}
              id={`step-external-link-${link.id}`}
              key={`step-external-link-${link.id}`}
            >
              {link.id === editId ? (
                <ManageStepExternalLinksForm
                  action='update'
                  elementId={link.id}
                  initialUrl={link.url}
                  resetElementState={() => {
                    dispatch({ type: 'resetState' });
                  }}
                />
              ) : (
                <StepExternalLinksListItem dispatchListState={dispatch} stepExternalLink={link} />
              )}
            </ExternalLinkElementWrapper>
          ))}
        </>
      )}
    </>
  );
};

export default StepExternalLinksList;
