import styled from 'styled-components';

import { mediaBreakpointPxXl } from '../../../../styled/Breakpoint';
import FlexContainer from '../../../../styled/FlexContainer';

export const IconsWrapper = styled(FlexContainer)`
  margin-right: ${({ theme }) => theme.constants.spacerMd2};
  margin-left: ${({ theme }) => theme.constants.spacerSm3};
  height: 100%;

  @media (min-width: ${mediaBreakpointPxXl}) {
    margin-right: 3.75rem;
  }
`;

export const DisabledIconWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  opacity: 50%;

  > button {
    pointer-events: none;
  }
`;
