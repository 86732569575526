import styled from 'styled-components';

import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { forceCapitalization } from '../../../../helpers/cssTranslation';
import { fontSm5 } from '../../../../styled/TypeSystem';
import { DisabledStyles } from '../../../shared/DisabledState/DisabledState';

export const MenuItemWrapper = styled.div<{ isClickable: boolean; isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  padding: 0 ${({ theme: { constants } }) => constants.spacerMd2};
  border-top: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  ${fontSm5};

  ${({ isDisabled }) => isDisabled && DisabledStyles};

  &#top-nav-menu-sign-out {
    background-color: ${({ theme: { vars } }) => vars.foundationBase2};
  }
  &:hover {
    background-color: ${({ theme: { vars } }) => vars.foundationHover};
    &#top-nav-menu-sign-out {
      background-color: ${({ theme: { vars } }) => vars.foundationBase2};
      color: ${({ theme: { vars } }) => vars.stateHoverError};
      svg {
        color: ${({ theme: { vars } }) => vars.stateHoverError};
      }
    }
    &#top-nav-menu-language-select {
      background: ${({ theme: { vars } }) => vars.foundationSurface1};
    }
  }
`;

export const MenuIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  color: ${({ theme: { vars } }) => vars.foundationSurface2};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const MenuInner = styled.div`
  display: flex;
  align-items: center;

  ${TruncatedText({})};
  ${forceCapitalization};
`;
