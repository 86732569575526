import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../../lib/initTranslations';
import ProgressBar from '../../../../../design_system/Triage/ProgressBar';
import GroupBadges from '../../../../curriculums/CurriculumEdit/GroupsAssigned/GroupBadges';
import {
  ProgressBarWrapper,
  ReportHeadTeamsOrGroup,
  ReportHeadTeamsOrGroupInner,
  ReportHeadTeamsOrGroupTitle,
} from './styles';
import { Props } from './types';

const t = initTranslations('reports.report_head_card');

const GroupBadgesWrapper = styled.div`
  a {
    max-width: 19rem;
  }
`;

const GroupsColumn = ({
  groups,
  groupsType,
}: Pick<Props, 'groups'> & { groupsType: 'groups' | 'roles' | 'teams' }) => {
  const BADGES_MAX_COUNT = 8;

  const groupsCount = groups.length;
  const title = t(`${groupsType}`);
  if (!groupsCount) return <></>;

  return (
    <ReportHeadTeamsOrGroup>
      <ReportHeadTeamsOrGroupTitle>{title}</ReportHeadTeamsOrGroupTitle>
      <ReportHeadTeamsOrGroupInner>
        <GroupBadgesWrapper>
          <GroupBadges displayCount={BADGES_MAX_COUNT} groups={groups} groupsType={groupsType} />
        </GroupBadgesWrapper>
      </ReportHeadTeamsOrGroupInner>
    </ReportHeadTeamsOrGroup>
  );
};

const ReportHeadRightColumn = ({
  groups,
  completionPercentage,
  completionTitle,
  completionTitleTooltip,
}: Props) => {
  return (
    <>
      <ProgressBarWrapper>
        <ProgressBar
          id='user-completion-rate'
          labelText={completionTitle}
          labelTooltip={completionTitleTooltip}
          percent={completionPercentage}
          thickness='md'
        />
      </ProgressBarWrapper>
      <GroupsColumn groups={groups} groupsType='groups' />
    </>
  );
};

export default ReportHeadRightColumn;
