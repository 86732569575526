import { useEffect } from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import { messageFromError } from '../../../../../../redux/errors/helpers';
import {
  useFetchBillingInformationCardDataQuery,
  useFetchCurrentPlanCardDataQuery,
  useFetchPlansDataQuery,
  useFetchSimplePricingPlansDataQuery,
} from '../../../../../../redux/services/resourceApis/billing/billingApi';
import { useFlashNotification } from '../../../../../FlashNotificationContext';

export const useGetBillingData = () => {
  const {
    splitFeatures: { simplePricingEnabled },
  } = useCurrentAccount();
  const { flash } = useFlashNotification();
  const billingDetailsResult = useFetchBillingInformationCardDataQuery();
  const planCardResult = useFetchCurrentPlanCardDataQuery();

  const simplePricingPlansResult = useFetchSimplePricingPlansDataQuery(undefined, {
    skip: !simplePricingEnabled,
  });
  const plansResult = useFetchPlansDataQuery(undefined, { skip: simplePricingEnabled });
  const billingPlansPriceResult = simplePricingEnabled ? simplePricingPlansResult : plansResult;

  const {
    data: billingDetailsData,
    isLoading: isLoadingBillingDetails,
    error: billingDetailsError,
  } = billingDetailsResult;

  const {
    data: planCardData,
    isLoading: isLoadingCurrentPlan,
    error: currentPlanError,
  } = planCardResult;

  const {
    data: billingPlansPriceData,
    isLoading: isLoadingBillingPlansPrice,
    error: billingPlansPriceError,
  } = billingPlansPriceResult;

  const isLoadingBillingData =
    isLoadingBillingDetails || isLoadingCurrentPlan || isLoadingBillingPlansPrice;
  const errorMessage = messageFromError(
    billingDetailsError || currentPlanError || billingPlansPriceError
  )?.join(', ');

  useEffect(() => {
    errorMessage && flash('error', errorMessage);
  }, [errorMessage, flash]);

  return {
    billingDetailsData,
    billingPlansPriceData,
    planCardData,
    isLoadingBillingData,
  };
};
