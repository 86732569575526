import React, { useContext } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import { useIsAdmin } from '../../../../../hooks/users/useIsPermission';
import initTranslations from '../../../../../lib/initTranslations';
import { AccountTerminologyContext } from '../../../../AccountTerminologyProvider';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { routes } from '../../../publicApplication/applicationRouter';
import NotificationUserName from '../../notifications/NotificationUserName/NotificationUserName';
import { TextFragment } from '../styles';
import { ActivityAssociationParams } from '../types';
import { groupTabFromActivity } from './shared/groupTabFromActivity';

const t = initTranslations('reports.activity_log.group');

export interface GroupRecipientsManageTemplateProps {
  activityKey: string;
  recipientType: string;
  trackable: ActivityAssociationParams;
  parameters: { recipients_count?: number };
  recipient: ActivityAssociationParams;
}

const RecipientsManageTemplate = ({
  activityKey,
  trackable,
  parameters,
  recipient,
  recipientType,
}: GroupRecipientsManageTemplateProps) => {
  const { slug } = useCurrentAccount();
  const { curriculum } = useContext(AccountTerminologyContext);
  const isAdded = ['group.curriculums_added', 'group.users_added', 'group.manager_added'].includes(
    activityKey
  );
  const groupHref = routes.group({
    slug,
    id: trackable.id,
    breadcrumb: routes.groups({ slug }),
    tab: groupTabFromActivity(activityKey),
  });
  const curriculumTerm = curriculum.singular.toLowerCase();
  const isAdmin = useIsAdmin();

  const ACTIVITY_KEYS: { [P: string]: string } = {
    'group.curriculums_added': t('curriculums_added', { curriculum: curriculumTerm }),
    'group.curriculums_removed': t('curriculums_removed', { curriculum: curriculumTerm }),
    'group.users_added': t('users_added'),
    'group.users_removed': t('users_removed'),
    'group.manager_added': t('manager_added'),
    'group.manager_removed': t('manager_removed'),
  };
  const actionLocale = ACTIVITY_KEYS[activityKey];

  return (
    <>
      <TextFragment>{actionLocale}</TextFragment>
      {recipientType == 'User' ? (
        <NotificationUserName
          user={{
            is_active: recipient.is_active,
            id: recipient.id,
            name: recipient.name,
          }}
        />
      ) : (
        <SourceBadge
          readOnly={!recipient.is_active}
          sourceName='curriculum'
          sourceRoute={routes.curriculumEdit({ slug, id: recipient.id })}
          sourceText={recipient.name}
        />
      )}
      <TextFragment>
        {parameters?.recipients_count &&
          `${t('and_others', { count: parameters.recipients_count })} `}
        {isAdded ? t('to_group') : t('from_group')}
      </TextFragment>
      <SourceBadge
        readOnly={!(trackable.is_active && isAdmin)}
        sourceName='group'
        sourceRoute={groupHref}
        sourceText={trackable.name}
      />
    </>
  );
};

export default RecipientsManageTemplate;
