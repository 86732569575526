import React, { useCallback, useState } from 'react';

import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../lib/initTranslations';
import {
  useSendSignedDocumentsByCourseElementMutation,
  useSendSignedDocumentsByUserMutation,
} from '../../../../redux/services/resourceApis/eSignatures/eSignatureApi';
import Hoverable from '../../../design_system/Hoverable';
import Link from '../../../design_system/Link';
import { flash } from '../../../FlashNotificationContext';
import { ESignatureStatusInfo, StyledStatusIcon } from './styles';
import { ESignatureStatusProps } from './types';

const t = initTranslations('e_signature_status');

const ESignatureStatus = ({
  eSignature,
  curriculumId,
  userId,
  courseId,
  kind,
}: ESignatureStatusProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [sendSignedDocumentByUser, resultSignedDocumentByUser] =
    useSendSignedDocumentsByUserMutation();
  const [sendSignedDocumentByElement, resultSignedDocumentByElement] =
    useSendSignedDocumentsByCourseElementMutation();

  const getResult = () => {
    switch (kind) {
      case 'by_course':
        return resultSignedDocumentByElement;
      case 'by_user':
        return resultSignedDocumentByUser;
    }
  };

  const handleExportFiles = () => {
    switch (kind) {
      case 'by_course':
        courseId && sendSignedDocumentByElement({ curriculumId, courseId });
        return;
      case 'by_user':
        userId && sendSignedDocumentByUser({ curriculumId, userId });
        return;
    }
  };

  const successFunction = useCallback(() => {
    flash('info', t('flash.success_message'), {
      customTitle: t('flash.success_title'),
    });
  }, []);

  useDisplayFlashOnResponse({
    result: getResult(),
    errorMessage: t('flash.error_message'),
    successFunction,
  });

  if (!eSignature) return <></>;
  const { signed, all, status } = eSignature;

  switch (status) {
    case 'empty':
      return <></>;
    case 'in_progress':
    case 'signed':
      return (
        <Hoverable setIsHovered={setIsHovered}>
          <ESignatureStatusInfo isHovered={isHovered} isSigned>
            <StyledStatusIcon name='signature' size='sm' weight='regular' />
            {isHovered ? (
              <Link
                behavesAs='button'
                onClick={handleExportFiles}
                text={t('export_files', { count: signed })}
              />
            ) : (
              t('of_signatures', { all, signed })
            )}
          </ESignatureStatusInfo>
        </Hoverable>
      );
    case 'not_signed':
      return (
        <ESignatureStatusInfo isSigned={false}>
          <StyledStatusIcon name='signature-slash' size='sm' weight='regular' />
          {t('not_signed')}
        </ESignatureStatusInfo>
      );
  }
};

export default ESignatureStatus;
