import { DropdownMenuOption } from '../components/design_system/Triage/menus/DropdownMenu';
import initTranslations from './initTranslations';

const t = initTranslations('sort_dropdown');
const groupSortDropdownOptions: DropdownMenuOption[] = [
  {
    label: t('oldest'),
    sortColumn: 'created_at',
    sortDirection: 'asc',
  },
  {
    label: t('newest'),
    sortColumn: 'created_at',
    sortDirection: 'desc',
  },
  {
    label: t('name_asc'),
    sortColumn: 'name',
    sortDirection: 'asc',
  },
  {
    label: t('name_desc'),
    sortColumn: 'name',
    sortDirection: 'desc',
  },
];

export default groupSortDropdownOptions;
