import { ContentType } from '../../../../types/ContentType';

export const getContentTypeFromActivityKey = (activityKey: string) => {
  if (activityKey.includes('survey_survey')) {
    return ContentType.Survey;
  } else if (activityKey.includes('course')) {
    return ContentType.Course;
  } else {
    return ContentType.Flowchart;
  }
};
