import styled from 'styled-components';

import { fontSm4 } from '../../../../../../styled/TypeSystem';

export const ResponsibilityDurationWrapper = styled.div`
  align-items: center;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  height: 1.875rem;
  width: fit-content;
  ${fontSm4};
`;

export const StyledTitle = styled.p`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin: ${({ theme: { constants } }) => `${constants.spacerSm3} 0 ${constants.spacerSm3} 0`};

  ${fontSm4};
`;
