export function stringifyNumber(value: number | null | undefined): string | null {
  if (!value) {
    return null;
  }

  return String(value);
}

export function stringToNumber(value: string | null | undefined): number | null {
  if (!value) {
    return null;
  }

  return Number(value);
}
