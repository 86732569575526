import { LoadingSize } from '../../components/application/people/UsersOutline/Table/Column';
import { TableColumn } from '../../components/application/reports/AllCurriculumsReport/AllCurriculumsReportTable/AllCurriculumsReportColumn';

export function useGetAllCurriculumsColumns(): TableColumn[] {
  return [
    { columnName: 'title', loadingSize: LoadingSize.large, sortable: true },
    { columnName: 'assigned_users_count', loadingSize: LoadingSize.medium, sortable: false },
    {
      columnName: 'completion_percentage',
      loadingSize: LoadingSize.medium,
      sortable: true,
    },
    { columnName: 'updated_at', loadingSize: LoadingSize.medium, sortable: true },
    { columnName: 'verify_content_status', loadingSize: LoadingSize.medium, sortable: true },
    { columnName: 'actions', loadingSize: LoadingSize.medium, sortable: false },
  ];
}
