import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import TrialAccountStatusBanner from '../TrialAccountStatusBanner';

const t = initTranslations('per_user_pricing.account_status_banners.trial_ended');

const TrialEndedBanner = () => {
  return <TrialAccountStatusBanner className='trial-ended-banner' emoji='⌛' title={t('title')} />;
};

export default TrialEndedBanner;
