import { useAccountTerminology } from '../../components/AccountTerminologyProvider';
import { CardType } from '../../components/application/searches/SearchResultsPage/search_result_cards/SearchResultsCard/types';
import capitalize from '../../lib/capitalize';
import initTranslations from '../../lib/initTranslations';

type getQuickSearchResultCardSubtitleArgs = [string?, string?, string?, number?, CardType?];

const t = initTranslations('top_nav.quick_search.result_card_subtitle');

export function useGetQuickSearchResultCardSubtitle(...args: getQuickSearchResultCardSubtitleArgs) {
  const {
    curriculum: { singular: subjectSingular },
    topic: { singular: topicSingular },
    step: { singular: stepSingular },
  } = useAccountTerminology();
  const [parentElementName, sector, templateAuthor, membersCount, cardType] = args;
  const sectorName = sector && capitalize(sector);

  function getSubtitle() {
    switch (cardType) {
      case 'Curriculum':
        return t('element_in', { element: subjectSingular, parentElementName: sectorName });
      case 'Course':
        return t('element_in', { element: topicSingular, parentElementName });
      case 'Flowchart':
        return t('flowchart_in', { parentElementName });
      case 'Step':
        return t('element_in', { element: stepSingular, parentElementName });
      case 'Survey::Survey':
        return t('test_in', { topic: parentElementName });
      case 'Survey::Question':
        return t('test_question_in', { test: parentElementName });
      case 'Group':
        return t('group_with_members', { count: membersCount });
      case 'User':
        return t('teammate');
      case 'Template':
        return t('template_by', { author: templateAuthor });
    }
  }

  return getSubtitle();
}
