import React, { ReactNode, createContext, useContext, useState } from 'react';

interface ReferAFriendContext {
  showReferralModal: boolean;
  setShowReferralModal: React.Dispatch<React.SetStateAction<boolean>>;
}

type ReferAFriendProviderProps = {
  children: ReactNode;
};

const ReferAFriendContext = createContext({} as ReferAFriendContext);
export const useReferAFriendModal = () => useContext(ReferAFriendContext);

export const ReferAFriendProvider = ({ children }: ReferAFriendProviderProps) => {
  const [showReferralModal, setShowReferralModal] = useState<boolean>(false);

  return (
    <ReferAFriendContext.Provider
      value={{
        showReferralModal,
        setShowReferralModal,
      }}
    >
      {children}
    </ReferAFriendContext.Provider>
  );
};
