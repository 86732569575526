import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import Scrollbar from '../../../../styled/Scrollbar';
import { modalContainerVerticalPadding } from '../../../core/CoreModal/CoreModal';
import ModalBodyContainer from '../../../core/Layout/ModalContainers/ModalBodyContainer';
import { MinMaxModalHeight, TaskModalSize, TaskModalState } from './TaskModalTypes';

const StyledModalBodyContainer = styled(ModalBodyContainer)<{ scrollInsideBody: boolean }>`
  ${({ scrollInsideBody }) =>
    scrollInsideBody && {
      padding: '0 !important',
    }};

  @media (min-width: ${mediaBreakpointPxSm}) {
    ${({ scrollInsideBody }) =>
      scrollInsideBody && {
        padding: '0 !important',
      }};
  }
`;

type ScrollInsideBodyStylingProps = {
  minMaxModalHeight: MinMaxModalHeight;
  modalState: TaskModalState;
  scrollInsideBody: boolean;
  desktopSize: TaskModalSize;
};

const ScrollInsideBodyStyling = css<ScrollInsideBodyStylingProps>`
  ${({
    theme,
    scrollInsideBody,
    minMaxModalHeight: { min, max = '80vh' },
    desktopSize,
    modalState: {
      footerAndHeaderHeight,
      bodyBorders: { renderTopBorder, renderBottomBorder },
    },
  }) => {
    if (scrollInsideBody) {
      const coreModalPadding = `(${modalContainerVerticalPadding(
        desktopSize,
        theme
      )} + ${modalContainerVerticalPadding(desktopSize, theme)})`;

      //For setting the height of the Modal body need to subtract Modal padding, footer and head height
      const minHeight = min
        ? `calc(${min} - ${coreModalPadding} - ${footerAndHeaderHeight}px)`
        : undefined;
      const maxHeight = `calc(${max} - ${coreModalPadding} - ${footerAndHeaderHeight}px)`;

      return css`
        min-height: ${minHeight};
        max-height: ${maxHeight};
        border-top: ${theme.constants.borderWidthLg} solid
          ${renderTopBorder ? theme.vars.borderSurface2 : 'transparent'};
        border-bottom: ${theme.constants.borderWidthLg} solid
          ${renderBottomBorder ? theme.vars.borderSurface2 : 'transparent'};
        overflow-y: auto;
        ${Scrollbar};
      `;
    }
  }};
`;

const TaskBodyWrapper = styled.div<ScrollInsideBodyStylingProps>`
  width: 100%;
  color: ${({ theme: { vars } }) => vars.textDefault};

  ${ScrollInsideBodyStyling};
`;

type TaskModalBodyProps = {
  children: ReactNode;
  scrollInsideBody: boolean;
  forwardRef?: React.RefObject<HTMLDivElement>;
  desktopSize: TaskModalSize;
  minMaxModalHeight?: MinMaxModalHeight;
  modalState: TaskModalState;
  setModalState: (value: React.SetStateAction<TaskModalState>) => void;
};

const TaskModalBody = (props: TaskModalBodyProps) => {
  const {
    children,
    desktopSize,
    minMaxModalHeight,
    modalState,
    forwardRef,
    scrollInsideBody,
    setModalState,
  } = props;

  return (
    <StyledModalBodyContainer id='modal-body-container' scrollInsideBody={scrollInsideBody}>
      <TaskBodyWrapper
        desktopSize={desktopSize}
        minMaxModalHeight={{ min: minMaxModalHeight?.min, max: minMaxModalHeight?.max }}
        modalState={modalState}
        onScroll={(e) => {
          const target = e.target;
          if (scrollInsideBody && target instanceof HTMLDivElement) {
            setModalState((prevState) => ({
              ...prevState,
              bodyBorders: {
                renderTopBorder: target.scrollTop > 0,
                renderBottomBorder: target.scrollTop + target.clientHeight < target.scrollHeight,
              },
            }));
          }
        }}
        ref={forwardRef}
        scrollInsideBody={scrollInsideBody}
      >
        {children}
      </TaskBodyWrapper>
    </StyledModalBodyContainer>
  );
};

export default TaskModalBody;
