import { isEqual } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import {
  CurriculumAssignmentPermission,
  CurriculumAssignmentWithAction,
  CurriculumWithAssignmentsResponse,
  GroupCurriculumAssignmentWithAction,
  GroupWithCompositeId,
  ResourceCompositeId,
  UserCurriculumAssignmentWithAction,
  UserWithCompositeId,
  getCompositeId,
} from '../redux/services/resourceApis/curriculumAssignments/types';
import { AssignmentPermission } from '../redux/services/resourceApis/curriculums/types';
import { GroupResponse } from '../redux/services/resourceApis/groups/types';
import { AccessControl } from '../types/SuperShare';
import { User, UserPermission } from '../types/User';

type ActiveGroupReadModalResourceProps = {
  id: number;
  memberCount: number;
  name: string;
};

export type CurrentModal = 'superShare' | 'groupRead' | 'publicShare' | 'refreshPublicShareLink';

export type SuperShareState = {
  generalAccessSetting: AccessControl;
  setGeneralAccessSetting: Dispatch<SetStateAction<AccessControl>>;
  currentModal: CurrentModal | null;
  setCurrentModal: Dispatch<SetStateAction<CurrentModal | null>>;
  activeGroupReadModalResource: ActiveGroupReadModalResourceProps | null;
  completionRequired: boolean;
  curriculumPublished: boolean;
  resourcesToShareWith: (UserWithCompositeId | GroupWithCompositeId)[];
  sharedWithResources: CurriculumAssignmentWithAction[];
  addNewGroupOrUser: (resourceCompositeId: ResourceCompositeId) => void;
  closeActiveGroupReadModal: () => void;
  closeSuperShareModal: () => void;
  modifyCurriculumAssignment: (curriculumAssignment: CurriculumAssignmentWithAction) => void;
  removeAddedCurriculumAssignment: (curriculumAssignment: CurriculumAssignmentWithAction) => void;
  setActiveGroupReadModalResource: Dispatch<
    SetStateAction<ActiveGroupReadModalResourceProps | null>
  >;
  stateChanged: boolean;
  changedCurriculumAssignments: CurriculumAssignmentWithAction[];
};

type ImpliedUserPermissions = {
  [key in UserPermission]: AssignmentPermission;
};

const impliedUserPermissions: ImpliedUserPermissions = {
  admin: 'manage',
  author: 'manage',
  billing_admin: 'manage',
  contributor: 'edit',
  general: 'view',
};

export const getUserImpliedCurriculumAssignmentPermission = (
  userPermission: UserPermission
): AssignmentPermission => {
  return impliedUserPermissions[userPermission];
};

const getUserAssignmentPermission = ({
  userPermission,
  curriculumAssignmentPermission,
}: {
  userPermission: UserPermission;
  curriculumAssignmentPermission: CurriculumAssignmentPermission;
}): CurriculumAssignmentPermission => {
  const impliedPermission = getUserImpliedCurriculumAssignmentPermission(userPermission);

  if (impliedPermission === curriculumAssignmentPermission) {
    return null;
  }

  return curriculumAssignmentPermission;
};

type UseSuperShareStateProps = {
  curriculumWithAssignments: CurriculumWithAssignmentsResponse;
  completionRequired: boolean;
  users: User[];
  groups: GroupResponse[];
  setShowSuperShareModal: Dispatch<SetStateAction<boolean>>;
};

type CurriculumAssignmentsWithActionState = {
  userCurriculumAssignmentsWithAction: UserCurriculumAssignmentWithAction[];
  groupCurriculumAssignmentsWithAction: GroupCurriculumAssignmentWithAction[];
};

export default function useSuperShareState({
  curriculumWithAssignments,
  completionRequired,
  users,
  groups,
  setShowSuperShareModal,
}: UseSuperShareStateProps): SuperShareState {
  const {
    accessControl,
    curriculumAssignments,
    published: curriculumPublished,
  } = curriculumWithAssignments;
  const [generalAccessSetting, setGeneralAccessSetting] = useState<AccessControl>('request');
  const [activeGroupReadModalResource, setActiveGroupReadModalResource] =
    useState<ActiveGroupReadModalResourceProps | null>(null);
  const [resourcesToShareWith, setResourcesToShareWith] = useState<
    (UserWithCompositeId | GroupWithCompositeId)[]
  >([]);
  const [curriculumAssignmentsState, setCurriculumAssignmentsState] =
    useState<CurriculumAssignmentsWithActionState>({
      userCurriculumAssignmentsWithAction: [],
      groupCurriculumAssignmentsWithAction: [],
    });
  const [currentModal, setCurrentModal] = useState<CurrentModal | null>(null);

  useEffect(() => setGeneralAccessSetting(accessControl), [accessControl]);
  useEffect(() => {
    const usersToShareWith: UserWithCompositeId[] = users.map((user) => ({
      ...user,
      type: 'User',
      compositeId: getCompositeId('User', user.id),
    }));

    const groupsToShareWith: GroupWithCompositeId[] = groups.map((group) => ({
      ...group,
      type: 'Group',
      compositeId: getCompositeId('Group', group.id),
    }));

    setResourcesToShareWith([...usersToShareWith, ...groupsToShareWith]);
  }, [groups, users]);

  const initialCurriculumAssignmentsState: CurriculumAssignmentsWithActionState = useMemo(() => {
    const userCurriculumAssignmentsWithAction: UserCurriculumAssignmentWithAction[] = [];
    const groupCurriculumAssignmentsWithAction: GroupCurriculumAssignmentWithAction[] = [];

    curriculumAssignments.map((ca) => {
      const { curriculumAssignableType, curriculumAssignable } = ca;

      if (curriculumAssignableType === 'User') {
        userCurriculumAssignmentsWithAction.push({
          ...ca,
          action: 'unchanged',
          assignableCompositeId: getCompositeId(curriculumAssignableType, curriculumAssignable.id),
        });
      } else {
        groupCurriculumAssignmentsWithAction.push({
          ...ca,
          action: 'unchanged',
          assignableCompositeId: getCompositeId(curriculumAssignableType, curriculumAssignable.id),
        });
      }
    });

    return { userCurriculumAssignmentsWithAction, groupCurriculumAssignmentsWithAction };
  }, [curriculumAssignments]);

  const stateChanged = useMemo(() => {
    return (
      !isEqual(initialCurriculumAssignmentsState, curriculumAssignmentsState) ||
      !isEqual(accessControl, generalAccessSetting)
    );
  }, [
    accessControl,
    curriculumAssignmentsState,
    generalAccessSetting,
    initialCurriculumAssignmentsState,
  ]);

  const findResourceToShareWith = useCallback(
    (lookupCompositeId: ResourceCompositeId) => {
      return resourcesToShareWith.find(({ compositeId }) => compositeId === lookupCompositeId);
    },
    [resourcesToShareWith]
  );

  const findInitialCurriculumAssignmentState = useCallback(
    (lookupCompositeId: ResourceCompositeId) => {
      const { userCurriculumAssignmentsWithAction, groupCurriculumAssignmentsWithAction } =
        initialCurriculumAssignmentsState;
      if (lookupCompositeId.includes('User')) {
        return userCurriculumAssignmentsWithAction.find(
          ({ assignableCompositeId }) => assignableCompositeId === lookupCompositeId
        );
      } else {
        return groupCurriculumAssignmentsWithAction.find(
          ({ assignableCompositeId }) => assignableCompositeId === lookupCompositeId
        );
      }
    },
    [initialCurriculumAssignmentsState]
  );

  const findCurrentCurriculumAssignmentState = useCallback(
    (lookupCompositeId: ResourceCompositeId) => {
      const { userCurriculumAssignmentsWithAction, groupCurriculumAssignmentsWithAction } =
        curriculumAssignmentsState;
      if (lookupCompositeId.includes('User')) {
        return userCurriculumAssignmentsWithAction.find(
          ({ assignableCompositeId }) => assignableCompositeId === lookupCompositeId
        );
      } else {
        return groupCurriculumAssignmentsWithAction.find(
          ({ assignableCompositeId }) => assignableCompositeId === lookupCompositeId
        );
      }
    },
    [curriculumAssignmentsState]
  );

  const changedCurriculumAssignments: CurriculumAssignmentWithAction[] = useMemo(() => {
    const filteredGroupAssignments =
      curriculumAssignmentsState.groupCurriculumAssignmentsWithAction.filter(
        (ca) => ca.action !== 'unchanged'
      );
    const filteredUserAssignments =
      curriculumAssignmentsState.userCurriculumAssignmentsWithAction.filter(
        (ca) => ca.action !== 'unchanged'
      );
    return [...filteredGroupAssignments, ...filteredUserAssignments];
  }, [curriculumAssignmentsState]);

  useEffect(() => {
    setCurriculumAssignmentsState(initialCurriculumAssignmentsState);
  }, [initialCurriculumAssignmentsState]);

  const addNewGroupOrUser = (resourceCompositeId: ResourceCompositeId) => {
    const currentCurriculumAssignmentState =
      findCurrentCurriculumAssignmentState(resourceCompositeId);
    const initialCurriculumAssignmentState =
      findInitialCurriculumAssignmentState(resourceCompositeId);

    if (
      currentCurriculumAssignmentState &&
      currentCurriculumAssignmentState.action === 'remove' &&
      initialCurriculumAssignmentState
    ) {
      modifyCurriculumAssignment(initialCurriculumAssignmentState);
      return;
    }

    const resource = findResourceToShareWith(resourceCompositeId);
    if (resource === undefined) return;

    if (resource.type === 'User') {
      const { compositeId, ...curriculumAssignable } = resource;

      const newCurriculumAssignment: UserCurriculumAssignmentWithAction = {
        action: 'add',
        curriculumAssignableType: 'User',
        assignableCompositeId: compositeId,
        completionRequired,
        permission: null,
        curriculumAssignable,
      };
      setCurriculumAssignmentsState(({ userCurriculumAssignmentsWithAction, ...prevState }) => {
        const newUserCurriculumAssignmentsWithAction = [
          ...userCurriculumAssignmentsWithAction,
          newCurriculumAssignment,
        ];
        return {
          ...prevState,
          userCurriculumAssignmentsWithAction: newUserCurriculumAssignmentsWithAction,
        };
      });
    } else {
      const { compositeId, ...curriculumAssignable } = resource;
      const newCurriculumAssignment: CurriculumAssignmentWithAction = {
        action: 'add',
        curriculumAssignableType: 'Group',
        assignableCompositeId: compositeId,
        completionRequired,
        permission: 'view',
        curriculumAssignable: {
          ...curriculumAssignable,
          memberCount: curriculumAssignable.user_count,
        },
      };
      setCurriculumAssignmentsState(({ groupCurriculumAssignmentsWithAction, ...stateToPass }) => {
        const newGroupCurriculumAssignmentsWithAction = [
          ...groupCurriculumAssignmentsWithAction,
          newCurriculumAssignment,
        ];
        return {
          ...stateToPass,
          groupCurriculumAssignmentsWithAction: newGroupCurriculumAssignmentsWithAction,
        };
      });
    }
  };

  const modifyCurriculumAssignment = (curriculumAssignment: CurriculumAssignmentWithAction) => {
    const { assignableCompositeId: lookupCompositeId, curriculumAssignableType } =
      curriculumAssignment;

    setCurriculumAssignmentsState((prevState) => {
      if (curriculumAssignableType === 'User') {
        const { userCurriculumAssignmentsWithAction, ...stateToPass } = prevState;

        const newUserCurriculumAssignmentsWithAction: UserCurriculumAssignmentWithAction[] =
          userCurriculumAssignmentsWithAction.map((userCurriculumAssignmentWithAction) => {
            const {
              action: currentAction,
              assignableCompositeId,
              curriculumAssignable,
            } = userCurriculumAssignmentWithAction;

            if (assignableCompositeId === lookupCompositeId) {
              const {
                permission: newPermission,
                completionRequired: newCompletionRequired,
                action: newAction,
              } = curriculumAssignment;

              const permission = getUserAssignmentPermission({
                userPermission: curriculumAssignable.permission,
                curriculumAssignmentPermission: newPermission,
              });

              if (currentAction === 'add') {
                return {
                  ...userCurriculumAssignmentWithAction,
                  permission,
                  completionRequired: newCompletionRequired,
                };
              }
              if (currentAction === 'remove') {
                return {
                  ...curriculumAssignment,
                };
              }
              if (newAction === 'remove') {
                return {
                  ...userCurriculumAssignmentWithAction,
                  action: 'remove',
                };
              }
              return {
                ...userCurriculumAssignmentWithAction,
                action: 'update',
                permission,
                completionRequired: newCompletionRequired,
              };
            }
            return userCurriculumAssignmentWithAction;
          });

        return {
          ...stateToPass,
          userCurriculumAssignmentsWithAction: newUserCurriculumAssignmentsWithAction,
        };
      } else {
        const { groupCurriculumAssignmentsWithAction, ...stateToPass } = prevState;

        const newGroupCurriculumAssignmentsWithAction: GroupCurriculumAssignmentWithAction[] =
          groupCurriculumAssignmentsWithAction.map((groupCurriculumAssignmentWithAction) => {
            const { action: currentAction, assignableCompositeId } =
              groupCurriculumAssignmentWithAction;

            if (assignableCompositeId === lookupCompositeId) {
              const {
                permission: newPermission,
                completionRequired: newCompletionRequired,
                action: newAction,
              } = curriculumAssignment;
              const permission = newPermission;

              if (currentAction === 'add') {
                return {
                  ...groupCurriculumAssignmentWithAction,
                  permission,
                  completionRequired: newCompletionRequired,
                };
              }
              if (currentAction === 'remove') {
                return {
                  ...curriculumAssignment,
                };
              }
              if (newAction === 'remove') {
                return {
                  ...groupCurriculumAssignmentWithAction,
                  action: 'remove',
                };
              }
              return {
                ...groupCurriculumAssignmentWithAction,
                action: 'update',
                permission,
                completionRequired: newCompletionRequired,
              };
            }
            return groupCurriculumAssignmentWithAction;
          });
        return {
          ...stateToPass,
          groupCurriculumAssignmentsWithAction: newGroupCurriculumAssignmentsWithAction,
        };
      }
    });
  };

  const removeAddedCurriculumAssignment = (
    curriculumAssignment: CurriculumAssignmentWithAction
  ) => {
    setCurriculumAssignmentsState((prevState) => {
      if (curriculumAssignment.curriculumAssignableType === 'User') {
        const { userCurriculumAssignmentsWithAction, ...stateToPass } = prevState;
        const newUserCurriculumAssignmentsWithAction = userCurriculumAssignmentsWithAction.filter(
          (userCurriculumAssignmentWithAction) =>
            curriculumAssignment !== userCurriculumAssignmentWithAction
        );
        return {
          ...stateToPass,
          userCurriculumAssignmentsWithAction: newUserCurriculumAssignmentsWithAction,
        };
      } else {
        const { groupCurriculumAssignmentsWithAction, ...stateToPass } = prevState;
        const newGroupCurriculumAssignmentsWithAction = groupCurriculumAssignmentsWithAction.filter(
          (groupCurriculumAssignmentWithAction) =>
            curriculumAssignment !== groupCurriculumAssignmentWithAction
        );
        return {
          ...stateToPass,
          groupCurriculumAssignmentsWithAction: newGroupCurriculumAssignmentsWithAction,
        };
      }
    });
  };

  const closeSuperShareModal = useCallback(() => {
    setActiveGroupReadModalResource(null);
    setShowSuperShareModal(false);
  }, [setShowSuperShareModal]);

  const closeActiveGroupReadModal = () => {
    setActiveGroupReadModalResource(null);
    setCurrentModal('superShare');
  };

  const sharedWithResources: CurriculumAssignmentWithAction[] = useMemo(() => {
    const { userCurriculumAssignmentsWithAction, groupCurriculumAssignmentsWithAction } =
      curriculumAssignmentsState;
    const filteredUserCurriculumAssignmentsWithAction = userCurriculumAssignmentsWithAction.filter(
      ({ action }) => action !== 'remove'
    );
    const filteredGroupCurriculumAssignmentsWithAction =
      groupCurriculumAssignmentsWithAction.filter(({ action }) => action !== 'remove');
    return [
      ...filteredUserCurriculumAssignmentsWithAction,
      ...filteredGroupCurriculumAssignmentsWithAction,
    ];
  }, [curriculumAssignmentsState]);

  return {
    currentModal,
    setCurrentModal,
    completionRequired,
    activeGroupReadModalResource,
    curriculumPublished,
    resourcesToShareWith,
    sharedWithResources,
    addNewGroupOrUser,
    closeActiveGroupReadModal,
    closeSuperShareModal,
    modifyCurriculumAssignment,
    removeAddedCurriculumAssignment,
    setActiveGroupReadModalResource,
    stateChanged,
    changedCurriculumAssignments,
    generalAccessSetting,
    setGeneralAccessSetting,
  };
}
