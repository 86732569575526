import styled, { css } from 'styled-components';

import { MenuOption } from '../../core/MenuOptions';
import DefaultButton from '../DefaultButton';
import { ButtonSize, ButtonType } from '../DefaultButton/ButtonTypes';
import IconButton from '../IconButton';
import { getDropdownButtonStyles, getLoaderStyles } from './utils/getStyles';

export const MenuWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const ButtonsWrapper = styled.div`
  position: relative;
`;

export const MainButton = styled(DefaultButton)<{ size?: ButtonSize }>`
  padding-right: ${({ size, theme: { constants } }) =>
    size === 'sm' ? `calc(${constants.spacerLg1 + constants.spacerSm2})` : constants.spacerLg2};

  &:hover,
  &:focus {
    & + button {
      ${({ buttonType, theme: { vars } }) =>
        buttonType === 'secondary' &&
        css`
          background-color: ${vars.accentSubdued1};
        `}
      ${({ buttonType, theme: { vars, constants } }) =>
        buttonType === 'tertiary' &&
        css`
          border: ${`${constants.borderWidthSm} solid ${vars.borderSurface2}`};
        `}
    }
    &:disabled + button {
      background-color: ${({ theme: { vars } }) => vars.foundationBase3};
    }
  }
`;

const DropdownButtonLayout = css<{ mainButtonSize?: ButtonSize }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  padding: 0;
  ${({ mainButtonSize, theme: { constants } }) =>
    css`
      width: ${mainButtonSize === 'sm' ? `${constants.heightXs}` : '1.75rem'};
      height: ${mainButtonSize === 'sm' ? `${constants.heightXs}` : '1.75rem'};
      right: ${mainButtonSize === 'lg' ? '5px' : '4px'};
    `}
`;

export const DropdownButton = styled(IconButton)<{
  mainButtonType: ButtonType;
  mainButtonSize?: ButtonSize;
}>`
  ${({ mainButtonType }) => getDropdownButtonStyles(mainButtonType)};
  ${DropdownButtonLayout};
`;

export const Loader = styled.div<{ mainButtonType: ButtonType; mainButtonSize?: ButtonSize }>`
  ${({ mainButtonType }) => getLoaderStyles(mainButtonType)};
  ${DropdownButtonLayout};
`;

export const StyledMenuOption = styled(MenuOption)`
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

export const MenuOptionsWrapper = styled.div`
  position: absolute;
  right: 0;
  z-index: 10;
`;
