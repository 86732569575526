import React, { useEffect } from 'react';

import { useTeammateModal } from '../../../../../contexts/TeammateContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import { useRedirectToBillingOverlay } from '../../../../../hooks/useRedirectToBillingOverlay';
import { NewUser } from '../../../../../hooks/useTeammateState';
import initTranslations from '../../../../../lib/initTranslations';
import { useGetCurrentAccountInfoQuery } from '../../../../../redux/services/resourceApis/accounts/accountsApi';
import ConfirmationModal from '../../../../design_system/overlays/modals/ConfirmationModal';
import { routes } from '../../../publicApplication/applicationRouter';
import routeTo from '../../../publicApplication/routeTo';
import { tomorrowDate } from '../../../shared/helpers';
import { FormikWrapper } from '../FormikWrapper';
import { TeammateModalFormValues, TeammateModalProps } from '../InterfaceAndTypes';

const t = initTranslations('upgrade_seats_modal');

const AddTeammateModal = ({ onSuccess }: TeammateModalProps) => {
  const {
    slug,
    noFreeSlots,
    usedUserSlotsCount,
    totalUserSlotsCount,
    unlimitedUserSlots,
    onFree3SeatsPlan,
    nonBilling,
    splitFeatures: { perUserPricingEnabled },
  } = useCurrentAccount();
  const { prefillOptions, teammateModalVisible, setTeammateModalVisible } = useTeammateModal();
  const ability = useCurrentUserAbilities();
  const navigateToBillingOverlay = useRedirectToBillingOverlay();
  const canUpdateBillingPlan = ability.can('update', 'BillingPlan');
  const subheader = canUpdateBillingPlan ? 'subheader_1' : 'subheader_2';
  const closeModal = () => setTeammateModalVisible(false);
  const { refetch: refetchCurrentAccount, isLoading: isLoadingCurrentAccount } =
    useGetCurrentAccountInfoQuery();
  const isCheckedSendInviteByDefault = !(noFreeSlots && perUserPricingEnabled);
  const showConfirmationModal =
    !unlimitedUserSlots && noFreeSlots && (!perUserPricingEnabled || onFree3SeatsPlan);
  const showUpgradeNowButton = canUpdateBillingPlan && !nonBilling;

  const redirectToBillingOverlay = () => {
    closeModal();
    onFree3SeatsPlan ? routeTo(routes.billing({ slug })) : navigateToBillingOverlay();
  };

  useEffect(() => {
    if (teammateModalVisible) refetchCurrentAccount();
  }, [refetchCurrentAccount, teammateModalVisible]);

  if (isLoadingCurrentAccount || !teammateModalVisible || !prefillOptions) return <></>;

  const user = prefillOptions.user as NewUser;
  const team_ids = user.prefill_teams.map((teamId) => `${teamId}`);
  const tomorrowString = tomorrowDate.toISOString().slice(0, 10);

  const initialValues: TeammateModalFormValues = {
    schedule_archive: false,
    scheduled_archive_at: tomorrowString,
    send_invite: isCheckedSendInviteByDefault ? 'now' : 'later',
    scheduled_invite_at: tomorrowString,
    scheduled_invite_at_hour: '5:00 AM',
    scheduled_archive_at_hour: '5:00 AM',
    schedule_invite: false,
    name: '',
    email: '',
    title: '',
    permission: 'general',
    group_ids: [],
    role_ids: [],
    team_ids,
    parent_id: null,
    show_in_directory: prefillOptions.directory_enabled,
    phone_number: '',
    tomorrow_date: tomorrowString,
  };

  return showConfirmationModal ? (
    <ConfirmationModal
      actionFunction={redirectToBillingOverlay}
      actionText={t('upgrade_now')}
      hasPrimaryButton={showUpgradeNowButton}
      heapModalName='upgrade-plan-modal'
      message={t(subheader, { usedUserSlotsCount, totalUserSlotsCount })}
      onCloseRequest={closeModal}
      processing={false}
      title={t('header')}
      {...(!canUpdateBillingPlan && { secondaryButtonText: t('close') })}
    />
  ) : (
    <FormikWrapper
      action='add'
      closeModal={closeModal}
      initialValues={initialValues}
      onSuccess={onSuccess}
      prefillOptions={prefillOptions}
    />
  );
};

export default AddTeammateModal;
