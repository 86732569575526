import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import mergeArrayElements from '../../../../../lib/mergeArrayElements';
import { CurriculumGroup } from '../../../../../redux/services/resourceApis/curriculums/types';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { fontMd1 } from '../../../../styled/TypeSystem';

const BadgesWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};

  span {
    white-space: nowrap;
    max-width: 100%;
  }
`;

const AdditionalGroupCount = styled.span`
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontMd1};
`;

interface Props {
  groups: CurriculumGroup[];
  displayCount?: number;
  groupsType?: string;
}

const DISPLAY_GROUP_COUNT = 3;

const GroupsAssigned = ({
  groups,
  groupsType = 'groups',
  displayCount = DISPLAY_GROUP_COUNT,
}: Props) => {
  const toolTipId = `${groupsType}-tooltip`;

  return (
    <BadgesWrapper>
      {groups.slice(0, displayCount).map(({ id, name, kind }) => {
        return (
          <SourceBadge
            key={id}
            readOnly
            sourceName='group'
            sourceSubtext={`(${initTranslations('groups.kinds')(kind)})`}
            sourceText={name}
          />
        );
      })}
      {groups.length > displayCount && (
        <>
          <Tooltip
            id={toolTipId}
            text={mergeArrayElements(
              groups.slice(displayCount, groups.length).map((group) => group.name),
              3
            )}
          />
          <div data-for={toolTipId} data-tip>
            <AdditionalGroupCount>+{groups.length - displayCount}</AdditionalGroupCount>
          </div>
        </>
      )}
    </BadgesWrapper>
  );
};

export default GroupsAssigned;
