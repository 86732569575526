import styled from 'styled-components';

import { fontMd1 } from '../../../styled/TypeSystem';

export const CompletionInputNumberContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${({ theme: { constants } }) => constants.fontExtrabold};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontMd1};
`;

export const CompletionInputNumberWrapper = styled.div`
  width: calc(50% - 2rem);
`;
