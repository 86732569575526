import React, { useEffect, useRef, useState } from 'react';

import initTranslations from '../../../../lib/initTranslations';
import InputNumber from '../../../design_system/Triage/InputNumber/InputNumber';
import InputRange from '../../../design_system/Triage/InputRange/InputRange';
import CollapsibleFiltersSection from '../../shared/CollapsibleFiltersSection/CollapsibleFiltersSection';
import CompletionChart from '../CompletionChart/CompletionChart';
import { CompletionInputNumberContainer, CompletionInputNumberWrapper } from './styles';
import { Props } from './types';

const t = initTranslations('reports.completion_score_filter_section');

const CompletionScoreSection = ({
  data,
  completionScoreMax,
  completionScoreMin,
  setMinCompletionScore,
  setMaxCompletionScore,
}: Props) => {
  const [width, setWidth] = useState(244);
  const ref = useRef<HTMLDivElement>(null);
  const STEP = 1;
  const MAX_VALUE = 100;
  const MIN_VALUE = 0;

  useEffect(() => {
    if (ref?.current?.clientWidth) {
      const resizeObserver = new ResizeObserver(() => {
        ref?.current && setWidth(ref?.current.clientWidth);
      });
      resizeObserver.observe(ref.current);
      return () => resizeObserver.disconnect();
    }
  }, []);

  return (
    <CollapsibleFiltersSection sectorRef={ref} title={t('title')}>
      <CompletionChart
        data={data}
        maxValue={completionScoreMax}
        minValue={completionScoreMin}
        width={width - 20}
      />
      <InputRange
        maxValue={MAX_VALUE}
        minValue={MIN_VALUE}
        setValues={(values) => {
          setMinCompletionScore(values[0]);
          setMaxCompletionScore(values[1]);
        }}
        step={STEP}
        values={[completionScoreMin, completionScoreMax]}
      />
      <CompletionInputNumberContainer>
        <CompletionInputNumberWrapper>
          <InputNumber
            max={completionScoreMax}
            min={MIN_VALUE}
            onChange={(value: number) => setMinCompletionScore(value)}
            step={STEP}
            value={completionScoreMin}
            valueCharacter='%'
          />
        </CompletionInputNumberWrapper>
        {t('to')}
        <CompletionInputNumberWrapper>
          <InputNumber
            max={MAX_VALUE}
            min={completionScoreMin}
            onChange={(value: number) => setMaxCompletionScore(value)}
            step={STEP}
            value={completionScoreMax}
            valueCharacter='%'
          />
        </CompletionInputNumberWrapper>
      </CompletionInputNumberContainer>
    </CollapsibleFiltersSection>
  );
};

export default CompletionScoreSection;
