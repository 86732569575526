import React from 'react';
import styled from 'styled-components';

import Icon from '../../../../../../design_system/display/icons/Icon';
import Tooltip from '../../../../../../design_system/display/Tooltip/Tooltip';
import LegacyPlanInfoTooltipBody from './LegacyPlanInfoTooltipBody/LegacyPlanInfoTooltipBody';

const IconWrapper = styled.div`
  width: 1rem;
  height: ${({ theme: { constants } }) => constants.heightXs};
  &:hover,
  &:focus {
    color: ${({ theme: { vars } }) => vars.borderSurface2};
  }
`;

const StyledIcon = styled(Icon)`
  vertical-align: middle;
  pointer-events: none;
`;

const LegacyPlanInfoIcon = () => {
  return (
    <>
      <Tooltip
        content={<LegacyPlanInfoTooltipBody />}
        id='legacy-plan-info-tooltip'
        place='bottom'
      />
      <IconWrapper data-for='legacy-plan-info-tooltip' data-tip>
        <StyledIcon name='info-circle' />
      </IconWrapper>
    </>
  );
};

export default LegacyPlanInfoIcon;
