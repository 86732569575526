import React from 'react';
import PaginationJs from 'react-js-pagination';
import styled from 'styled-components';

import useWindowResize from '../../../../hooks/useWindowResize';
import initTranslations from '../../../../lib/initTranslations';
import { fontSm5 } from '../../../styled/TypeSystem';
import Icon from '../../display/icons/Icon';

const StyledPaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};

  ul {
    padding-inline-start: 0;
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    ${fontSm5};
  }
  .page-item {
    .page-link {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: ${({ theme: { constants } }) => constants.fontMedium};
      margin: ${({ theme: { constants } }) => constants.spacerSm3};
      min-width: 2rem;
      height: ${({ theme: { constants } }) => constants.heightSm};
      border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};
      color: ${({ theme: { vars } }) => vars.textDefault};
      cursor: pointer;
      text-decoration: none;
      &:hover {
        background-color: ${({ theme: { vars } }) => vars.accentSubdued1};
        color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
        text-decoration: none;
      }
      &:focus {
        box-shadow: none;
        color: ${({ theme: { vars } }) => vars.accentPrimaryPressed};
      }
    }
    &.active {
      .page-link {
        color: ${({ theme: { vars } }) => vars.textSurface};
        background-color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
      }
    }
    &.disabled {
      .page-link {
        color: ${({ theme: { vars } }) => vars.accentSubdued3};
        border-color: ${({ theme: { vars } }) => vars.foundationBase3};
        &:hover {
          background-color: transparent;
        }
      }
    }
    &:first-child {
      .page-link {
        padding: ${({ theme: { constants } }) => `0 ${constants.spacerMd2}`};
      }
    }
    &:last-child {
      .page-link {
        padding: ${({ theme: { constants } }) => `0 ${constants.spacerMd2}`};
      }
    }
  }
`;

const PaginationDetailsWrapper = styled.div`
  justify-content: center;
  display: flex;
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

const t = initTranslations('pagination');

export interface Props {
  activePage: number;
  itemsCountPerPage: number;
  onChange: (page: number) => void;
  totalItemsCount: number;
  totalPages: number;
  showPaginationDetails?: boolean;
  hideFirstLastPages?: boolean;
  pageRangeDisplayed?: number;
}

const Pagination = (props: Props) => {
  const {
    activePage,
    itemsCountPerPage,
    onChange,
    totalItemsCount,
    totalPages,
    showPaginationDetails = false,
    hideFirstLastPages = false,
    pageRangeDisplayed = 3,
  } = props;
  const { isMobile } = useWindowResize();

  return (
    <StyledPaginationContainer>
      {showPaginationDetails && (
        <PaginationDetailsWrapper className='pagination-details'>
          {t('details', {
            start: activePage * itemsCountPerPage - itemsCountPerPage + 1,
            end: activePage === totalPages ? totalItemsCount : activePage * itemsCountPerPage,
            total: totalItemsCount,
          })}
        </PaginationDetailsWrapper>
      )}
      {totalPages > 1 && (
        <PaginationJs
          activePage={activePage}
          firstPageText={t('first')}
          hideFirstLastPages={isMobile || totalPages < 3 || hideFirstLastPages}
          itemClass='page-item'
          itemClassFirst='page-item-first'
          itemClassLast='page-item-last'
          itemsCountPerPage={itemsCountPerPage}
          lastPageText={t('last')}
          linkClass='page-link'
          nextPageText={<Icon className='pagination-next' name='chevron-right' size='sm' />}
          onChange={(page) => onChange(page)}
          pageRangeDisplayed={pageRangeDisplayed}
          prevPageText={<Icon className='pagination-previous' name='chevron-left' size='sm' />}
          totalItemsCount={totalItemsCount}
        />
      )}
    </StyledPaginationContainer>
  );
};

export default Pagination;
