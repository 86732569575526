import React from 'react';

import { useGetAllCurriculumsColumns } from '../../../../../hooks/all_curriculums_report/useGetAllCurriculumsColumns';
import { HeaderRow } from '../../AllUsersReportPage/AllUsersReportTable/styles';
import { SortProps } from '../../TableTypes';
import { TableColumn } from './AllCurriculumsReportColumn';
import AllCurriculumsReportTableHeaderCell from './AllCurriculumsReportTableHeaderCell';
import { TableHeaderProps } from './types';

const AllCurriculumsReportTableHeader = ({ tableHeaderState, dispatch }: TableHeaderProps) => {
  const allCurriculumsColumns = useGetAllCurriculumsColumns();

  return (
    <thead>
      <HeaderRow>
        {allCurriculumsColumns
          .filter(({ display }) => display !== 'hidden')
          .map((column: TableColumn) => {
            const activeColumn = tableHeaderState.sortColumn === column.columnName;
            let sortProps: SortProps = { isSortable: column.sortable };

            if (column.sortable) {
              const sortIcon = activeColumn ? tableHeaderState.sortIcon : 'sort';
              sortProps = { isSortable: true, sortIcon };
            }

            return (
              <AllCurriculumsReportTableHeaderCell
                activeHeader={activeColumn}
                column={column}
                key={`header-${column.columnName}`}
                setActiveHeader={() => {
                  if (column.sortable) {
                    dispatch({ type: 'sortColumnChange', sortColumn: column.columnName });
                  }
                }}
                showTooltip={column.showTooltip}
                {...sortProps}
              />
            );
          })}
      </HeaderRow>
    </thead>
  );
};

export default AllCurriculumsReportTableHeader;
