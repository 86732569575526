import styled from 'styled-components';

import Icon from '../../../../design_system/display/icons/Icon';
import Scrollbar from '../../../../styled/Scrollbar';
import { fontMd3 } from '../../../../styled/TypeSystem';

export const ModalTitle = styled.h2`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  ${fontMd3};
`;

export const ModalContainer = styled.div`
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerLg1}`};
`;

export const ModalTableWrapper = styled.div`
  max-height: 30rem;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  position: relative;
  overflow: auto;
  white-space: nowrap;
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${Scrollbar};
`;

export const ModalTable = styled.table`
  width: 100%;
  overflow: hidden;
  border-collapse: collapse;
`;

export const ModalTbody = styled.tbody`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
`;

export const PlanNameWrapper = styled.div`
  display: block;
  max-width: 12rem;
  white-space: normal;
`;

export const PlanNamesList = styled.ul`
  padding-left: 0;
`;

export const DownloadChargeLinkIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
`;
