import { ConsumptionCourseResponse, EditorCourseResponse } from '../../../../types/Editor';

type EditorCourseTag = {
  type: 'Step' | 'Topic' | 'Curriculum' | 'CurriculumElement';
  id: string | number;
};

export const editorCourseTags = (
  result?: EditorCourseResponse | ConsumptionCourseResponse
): EditorCourseTag[] => {
  let tags: EditorCourseTag[] = [];

  if (result) {
    const {
      id,
      steps,
      curriculum: { id: curriculumId },
    } = result;

    const stepTags: EditorCourseTag[] = steps.map((step) => {
      return { type: 'Step', id: step.id };
    });

    tags = [
      { type: 'Step', id: 'LIST' },
      { type: 'Topic', id },
      { type: 'Curriculum', id: curriculumId },
      { type: 'CurriculumElement', id: 'LIST' },
      ...stepTags,
    ];
  }

  return tags;
};
