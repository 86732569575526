import styled from 'styled-components';

import Badge from '../../../../../design_system/display/badge';
import { fontMd1, fontMd4, fontSm4 } from '../../../../../styled/TypeSystem';

export const AddonListItem = styled.li<{ divideItems?: boolean; isNewItem: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars }, isNewItem }) => (isNewItem ? vars.textDefault : vars.textSubdued)};
  margin: ${({ theme: { constants }, divideItems }) =>
    divideItems ? `${constants.spacerMd2} 0` : `0 0 ${constants.spacerMd2}`};

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const AddonNameWrapper = styled.div<{ alreadyIncluded?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  text-decoration: ${({ alreadyIncluded }) => alreadyIncluded && 'line-through'};
`;

export const AddonItemText = styled.span`
  ${fontMd1};
`;

export const AddonItemName = styled.span<{ nameColor: string; isNewStatus: boolean }>`
  color: ${({ nameColor, isNewStatus }) => isNewStatus && nameColor};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
`;

export const AddonItemPriceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AddonItemPrice = styled.span<{ isNewStatus: boolean }>`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${({ isNewStatus }) => (isNewStatus ? fontMd4 : fontMd1)}
`;

export const AddonItemBillingPeriod = styled.span<{ isNewStatus: boolean }>`
  margin-left: ${({ theme: { constants }, isNewStatus }) => isNewStatus && constants.spacerSm2};
  ${fontSm4};
`;

export const StyledBadge = styled(Badge)`
  flex-shrink: 0;
`;
