import React, { useCallback, useEffect, useState } from 'react';

import { RegisteredId } from '../../../../lib/idRegistry';
import initTranslations from '../../../../lib/initTranslations';
import { MenuOptions } from '../../core/MenuOptions';
import Icon from '../../display/icons/Icon';
import { IconProps } from '../DefaultButton/ButtonTypes';
import {
  ButtonsWrapper,
  DropdownButton,
  Loader,
  MainButton,
  MenuOptionsWrapper,
  MenuWrapper,
  StyledMenuOption,
} from './styles';
import { SplitButtonProps } from './types';

const t = initTranslations('aria');

const SplitButton = (props: SplitButtonProps) => {
  const {
    mainButtonOnClick,
    ref,
    text,
    size,
    buttonType = 'primary',
    loading,
    disabled,
    menuOptions,
    className = '',
    iconName,
    iconWeight = 'regular',
    dropDownClassName = '',
    mainButtonType = 'submit',
    mainMenuButtonId,
    menuId,
    isActiveMenu,
    setActiveMenu,
    closeActiveMenu,
  } = props;

  const [menuOpen, setMenuOpen] = useState(isActiveMenu);

  const isMenuOpen = isActiveMenu === undefined ? menuOpen : isActiveMenu;
  const openMenu = useCallback(
    () => (setActiveMenu ? setActiveMenu() : setMenuOpen(true)),
    [setActiveMenu]
  );
  const closeMenu = useCallback(
    () => (closeActiveMenu ? closeActiveMenu() : setMenuOpen(false)),
    [closeActiveMenu]
  );

  useEffect(() => {
    if (isMenuOpen) {
      window.addEventListener('click', closeMenu);
    }
    return () => window.removeEventListener('click', closeMenu);
  }, [closeMenu, isMenuOpen]);

  const iconButtonId: RegisteredId = `${mainMenuButtonId}-${
    isMenuOpen ? 'close-menu' : 'open-menu'
  }`;
  const iconProps: IconProps = iconName && iconWeight ? { iconName, iconWeight } : {};

  return (
    <MenuWrapper>
      <ButtonsWrapper>
        <MainButton
          buttonType={buttonType}
          className={className}
          disabled={disabled}
          id={mainMenuButtonId}
          onClick={mainButtonOnClick}
          ref={ref}
          size={size}
          text={text}
          type={mainButtonType}
          {...iconProps}
        />
        {loading ? (
          <Loader mainButtonSize={size} mainButtonType={buttonType}>
            <Icon name='spinner-third' size='xs' spinSpeed='fast' weight='solid' />
          </Loader>
        ) : (
          <DropdownButton
            ariaLabel={t('aria_label_split_button_dropdown')}
            buttonSize='sm'
            className={dropDownClassName}
            disabled={disabled}
            id={iconButtonId}
            mainButtonSize={size}
            mainButtonType={buttonType}
            name={`chevron-${isMenuOpen ? 'up' : 'down'}`}
            onClick={openMenu}
            weight='regular'
          />
        )}
      </ButtonsWrapper>
      {isMenuOpen && (
        <MenuOptionsWrapper>
          <MenuOptions id={menuId}>
            {menuOptions.map((option, index) => {
              return (
                <StyledMenuOption
                  key={`menu-option-${index}`}
                  {...option}
                  onClick={(e) => {
                    option.onClick(e);
                    closeMenu();
                  }}
                />
              );
            })}
          </MenuOptions>
        </MenuOptionsWrapper>
      )}
    </MenuWrapper>
  );
};

export default SplitButton;
