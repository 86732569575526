import React from 'react';

import { BodyRow, Loader, LoadingTableCell } from '../../../../people/UsersOutline/Table/Styles';

const LoadingTableRows = ({ rowsCount }: { rowsCount: number }) => {
  return (
    <>
      {[...Array(rowsCount)].map((_, index) => (
        <BodyRow key={`loading_row_${index}`}>
          <LoadingTableCell colSpan={5}>
            <Loader $width='100%' />
          </LoadingTableCell>
        </BodyRow>
      ))}
    </>
  );
};

export default LoadingTableRows;
