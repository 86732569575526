import { CurriculumElementListTag } from './types';

export const curriculumElementListTags = ({
  id,
  curriculumId,
}: {
  id?: number;
  curriculumId?: number;
}): CurriculumElementListTag[] => {
  let tags: CurriculumElementListTag[] = [{ type: 'Curriculum', id: 'LIST' }];

  if (id) {
    tags = [...tags, { type: 'CurriculumElement', id }];
  }

  if (curriculumId) {
    tags = [...tags, { type: 'CurriculumElement', id: `Curriculum-${curriculumId}` }];
  }

  return tags;
};
