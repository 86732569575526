import React from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import Emoji from '../../../../../design_system/display/Emoji/Emoji';
import { routes } from '../../../../publicApplication/applicationRouter';
import { ColumnComponentProps } from '../types';
import { BoldText, ElementTitle, TitleWrapper } from './styles';

const Title = ({ record }: ColumnComponentProps) => {
  const { slug } = useCurrentAccount();
  const { id, title, emoji } = record;

  return (
    <TitleWrapper
      href={routes.curriculumShow({ slug, id }).href}
      text={
        <>
          <Emoji emoji={emoji} id={`curriculum-${id}-emoji`} />
          <ElementTitle>
            <BoldText>{title}</BoldText>
          </ElementTitle>
        </>
      }
    />
  );
};

export default Title;
