import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { openDocumentImportModal } from '../../../../../redux/domains/documentUpload/documentUploadSlice';
import { useAppDispatch } from '../../../../../redux/hooks';
import IconButton from '../../../../design_system/buttons/IconButton';
import { UploadProgressItemAction } from './reducer';
import { StyledLink } from './styles';

const t = initTranslations('document_uploads.upload_progress_item');

const FailureIndicator = ({ dispatch }: { dispatch: React.Dispatch<UploadProgressItemAction> }) => {
  const sliceDispatch = useAppDispatch();
  const handleTryAgainLinkClick = () => {
    dispatch({ type: 'setIsVisible', isVisible: false });
    sliceDispatch(openDocumentImportModal('upload_progress_item'));
  };

  return (
    <>
      <StyledLink behavesAs='button' onClick={handleTryAgainLinkClick} text={t('try_again')} />
      <IconButton
        ariaLabel='test'
        buttonSize='lg'
        name='xmark'
        onClick={() => dispatch({ type: 'setIsVisible', isVisible: false })}
      />
    </>
  );
};

export default FailureIndicator;
