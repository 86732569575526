import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

import useAsyncData, { AsyncData } from './useAsyncData';

type RemoteResult<T> = { result: AsyncData<T>; refresh: () => void };

const MINIMUM_LOADING_MS = 1000;

export default function useAjaxRead<T>(path: string): RemoteResult<T> {
  const [result, setResult] = useAsyncData<T>();
  const [cacheBuster, setCacheBuster] = useState<number>(0);

  const refresh = useCallback(() => {
    setCacheBuster(cacheBuster + 1);
  }, [cacheBuster]);

  useEffect(() => {
    // This file will be removed with the usage of redux, please do not add eslint-disable to code that is not being deprecated
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promises = [axios.get<T>(path)] as Promise<any>[];

    // On development, include a minimum wait time of 1 second to help with verifying loading states.
    if (process.env.NODE_ENV === 'development') {
      const timeOutPromise = new Promise((resolve) => setTimeout(resolve, MINIMUM_LOADING_MS));
      promises.push(timeOutPromise);
    }

    Promise.all(promises)
      .then(([response]) => {
        // This file will be removed with the usage of redux, please do not add eslint-disable to code that is not being deprecated
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let { data } = response as any;
        if (data.data !== undefined) {
          // Ajax operations should return json of the format { data: ... }
          data = data.data;
        }
        setResult({ status: 'success', data });
      })
      .catch((e) => {
        const {
          message,
          response: {
            data: { errors },
            status: statusCode,
          },
        } = e;
        console.log(message);
        setResult({
          status: 'error',
          statusCode,
          baseErrors: errors || [],
        });
      });
  }, [setResult, cacheBuster, path]);

  return {
    result,
    refresh,
  };
}

export function useCallableAjaxRead<T>(path: string, params?: object): () => Promise<AsyncData<T>> {
  const [, setData] = useAsyncData<T>();

  const call = useCallback(async (): Promise<AsyncData<T>> => {
    const fetchPromise = axios.get<T>(path, params);
    let result: AsyncData<T>;

    try {
      const response = await fetchPromise;
      // This file will be removed with the usage of redux, please do not add eslint-disable to code that is not being deprecated
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let { data } = response as any;
      if (data.data !== undefined) {
        // Ajax operations should return json of the format { data: ... }
        data = data.data;
      }
      result = { status: 'success', data };
    } catch (error) {
      const {
        message,
        response: {
          data: { baseErrors },
          status,
        },
      } = error;
      console.log(message);
      result = {
        status: 'error',
        baseErrors: baseErrors || [],
        statusCode: status,
      };
    }
    setData(result);
    return result;
  }, [params, path, setData]);
  return call;
}
