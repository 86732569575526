import React from 'react';

import { AllCurriculumsReportColumn, ColumnComponentProps } from '../types';
import Actions from './Actions';
import AssignedUsersCount from './AssignedUsersCount';
import CompletionPercentage from './CompletionPercentage';
import Title from './Title';
import UpdatedAt from './UpdatedAt';
import VerifyContentStatus from './VerifyContentStatus';

type TableColumnsProps = {
  [key in AllCurriculumsReportColumn]: React.FC<ColumnComponentProps>;
};

const COLUMNS_KEYS: TableColumnsProps = {
  title: Title,
  completion_percentage: CompletionPercentage,
  assigned_users_count: AssignedUsersCount,
  updated_at: UpdatedAt,
  verify_content_status: VerifyContentStatus,
  actions: Actions,
};

const TableColumn = ({ columnName, record }: ColumnComponentProps) => {
  const Column = COLUMNS_KEYS[columnName as keyof TableColumnsProps];

  return <Column columnName={columnName} record={record} />;
};

export default TableColumn;
