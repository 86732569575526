import { DetailedCurriculum } from '../../../../types';
import { AssignUsersListTags, DetailedCurriculumListTag } from './types';

export const detailedCurriculumListTags = (
  result?: DetailedCurriculum
): DetailedCurriculumListTag[] => {
  let tags: DetailedCurriculumListTag[] = [];

  if (result) {
    const { sector, id } = result;

    tags = [
      { type: 'Curriculum', id: 'LIST' as const },
      { type: 'Curriculum', id: `CURRICULUM_LIST_${sector}` },
      { type: 'Curriculum', id },
    ];
  }

  return tags;
};

export const assignUsersListTags = (curriculumId: number | undefined): AssignUsersListTags[] => {
  let tags: AssignUsersListTags[] = [{ type: 'User', id: 'LIST' }];

  if (curriculumId) {
    tags = [
      ...tags,
      { type: 'Curriculum', id: `curriculum-${curriculumId}` },
      { type: 'CurriculumAssignment', id: 'LIST' },
      { type: 'Curriculum', id: curriculumId },
    ];
  }

  return tags;
};
