import { GroupTabType } from '../../../../publicApplication/route_types/groupTab';

export const groupTabFromActivity = (activityKey: string): GroupTabType => {
  switch (activityKey) {
    case 'group.curriculums_added':
    case 'group.curriculums_removed':
      return 'content';
    case 'group.users_added':
    case 'group.users_removed':
    case 'group.manager_added':
    case 'group.manager_removed':
    case 'group.create':
    case 'group.name_changed':
    case 'group.destroy':
    case 'group.description_changed':
      return 'overview';
    default:
      throw new Error(`Group ActivityKey ${activityKey} not recognized`);
  }
};
