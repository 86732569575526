const FILENAME_HEADER_REGEX = /filename\*=UTF-8''([^']+)/;

export default function fetchFilenameFromResponseHeader(header: string) {
  const filenameMatches = header.match(FILENAME_HEADER_REGEX);
  let filename = '';
  if (filenameMatches && filenameMatches[1]) {
    filename = decodeURIComponent(filenameMatches[1]);
    filename = filename.substring(0, filename.lastIndexOf('.'));
  }

  return filename;
}
