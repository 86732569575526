import { FormikErrors, useFormik } from 'formik';
import { truncate } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { useGroupsModals } from '../../../../../contexts/GroupsModalContext';
import useAnalyticsUserEvents from '../../../../../hooks/useAnalyticsUserEvents';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../redux/errors/helpers';
import {
  groupsApi,
  useAddSuggestedGroupsMutation,
  useGetSuggestedGroupsQuery,
} from '../../../../../redux/services/resourceApis/groups/groupsApi';
import { GroupKind } from '../../../../../types/Group';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import CreatableSelectField from '../../../../design_system/Triage/fields/CreatableSelectField/CreatableSelectField';
import { useFlashNotification } from '../../../../FlashNotificationContext';
import { TaskErrorDiv } from '../../../../styled/Modals';
import { routes } from '../../../publicApplication/applicationRouter';
import routeTo from '../../../publicApplication/routeTo';
import { NAME_MAX_CHARACTERS } from '../../shared/constants/groups';
import { initialState, reducer } from '../../shared/modals/Suggestions/reducer';
import UnselectedPills from '../../shared/modals/Suggestions/UnselectedPills';
import { AddBlock, AddBlockWrapper, SelectWrapper, SuggestionsSubheader } from './styles';

const t = initTranslations('groups.modals.create_group.create_group_name');

type CreateGroupNameModalProps = {
  onSuccess?: (groupIds: number[]) => void;
};

const mapDispatch = {
  getValidateGroup: groupsApi.endpoints.getValidateGroup.initiate,
};

const connector = connect(undefined, mapDispatch);

export type ConnectedGroupProps = ConnectedProps<typeof connector> & CreateGroupNameModalProps;

const CreateGroupNameModal = ({ getValidateGroup, onSuccess }: ConnectedGroupProps) => {
  const { cdpSuggestedGroupsModalV1Clicked } = useAnalyticsUserEvents();
  const {
    state: { newGroupKind },
    dispatch: groupsModalsDispatch,
    redirectToNewGroup,
  } = useGroupsModals();
  const { slug } = useCurrentAccount();
  const { flash } = useFlashNotification();
  const [{ unselectedPills, selectedPills }, dispatch] = useReducer(reducer, initialState);
  const [enteredGroupName, setEnteredGroupName] = useState('');
  const [showAddBlock, setShowAddBlock] = useState(false);
  const [
    addSuggestedGroups,
    { isError, error, isLoading: isSubmitting, isSuccess: isSubmitted, data: createdGroups },
  ] = useAddSuggestedGroupsMutation();
  const {
    data: suggestedGroups,
    isError: isSuggestedGroupsError,
    isLoading: isSuggestedGroupsLoading,
    isSuccess: isSuggestedGroupsSuccess,
  } = useGetSuggestedGroupsQuery();

  useDisplayFlashOnResponse({
    result: {
      isSuccess: isSubmitted,
      isError,
      isLoading: isSuggestedGroupsLoading,
    },
    errorMessage: messageFromError(error)?.join(', '),
  });

  useEffect(() => {
    if (isSuggestedGroupsSuccess && suggestedGroups) {
      dispatch({ type: 'loaded', data: suggestedGroups });
    }
  }, [isSuggestedGroupsSuccess, suggestedGroups]);

  useEffect(() => {
    if (isSubmitted && createdGroups) {
      groupsModalsDispatch({ type: 'closeGroupNameModal' });

      if (onSuccess) {
        onSuccess(createdGroups.map(({ id }) => id));
      } else {
        if (redirectToNewGroup && createdGroups.length === 1) {
          const [group] = createdGroups;
          routeTo(
            routes.group({
              slug,
              id: group.id,
              newGroup: true,
              breadcrumb: routes.groups({ slug, group_kind: newGroupKind }),
              tab: 'overview',
            })
          );
        }
      }
    }
  }, [
    createdGroups,
    flash,
    isSubmitted,
    newGroupKind,
    onSuccess,
    redirectToNewGroup,
    slug,
    groupsModalsDispatch,
  ]);

  const formik = useFormik({
    initialValues: {
      name: '',
      kind: newGroupKind,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: async ({ name, kind }) => {
      const trimmedName = name.trim();
      const errors: FormikErrors<{ name: string; kind: GroupKind }> = {};
      const { error } = await getValidateGroup({ name: trimmedName, kind }, { forceRefetch: true });
      const selectedClientGroupExists = selectedPills.find(
        (suggestion) => suggestion.name.toLowerCase() === trimmedName.toLowerCase()
      );

      if (!trimmedName) {
        errors.name = t('inputs.custom_name.error.empty');
      } else if (error) {
        errors.name = messageFromError(error)?.join(', ');
      } else if (selectedClientGroupExists) {
        errors.name = t('inputs.custom_name.error.existing');
      } else if (trimmedName.length > NAME_MAX_CHARACTERS) {
        errors.name = t('inputs.custom_name.error.too_long', { max: NAME_MAX_CHARACTERS });
      }

      return errors;
    },
    onSubmit: ({ name }, { resetForm, setSubmitting }) => {
      setSubmitting(false);
      resetForm();
      dispatch({ type: 'addCustom', name });
      setShowAddBlock(false);
    },
  });

  const taskModalArgs: TaskModalProps = {
    desktopSize: 'xl',
    heapModalName: 'create-group-name-modal',
    isDisabled: selectedPills.length === 0,
    onCloseRequest: () => {
      groupsModalsDispatch({ type: 'closeGroupNameModal' });
    },
    primaryButtonTask: () => {
      cdpSuggestedGroupsModalV1Clicked({
        action: 'groups_saved',
        numberOfCustom: selectedPills.filter(({ kind }) => kind === 'custom').length,
        numberOfSuggested: selectedPills.filter(({ kind }) => kind === 'original').length,
      });
      addSuggestedGroups({ groups: selectedPills, group_kind: newGroupKind });
    },
    primaryButtonText: t('create_button'),
    processing: isSuggestedGroupsLoading || isSubmitting || isSubmitted,
    title: t('header', { group_type: newGroupKind }),
    subtitleWithBoldedText: [
      { text: t('subtitle_fragment1') },
      { bold: true, text: newGroupKind },
      { text: t('subtitle_fragment2') },
    ],
  };

  const handleAddBlockClick = () => {
    formik.handleSubmit();
    setEnteredGroupName('');
  };

  return (
    <TaskModal {...taskModalArgs}>
      {
        // ToDo: move all the callback functions to a separate handlers
        // skipped for now to keep all the FF changes with the less code possible
      }
      <SelectWrapper>
        <CreatableSelectField
          className='analytics-custom-group-name-field'
          errorText={formik.touched.name && formik.errors.name}
          id='custom-group-name'
          inputValue={formik.values.name}
          isValid={!Object.keys(formik.errors).length}
          onBlur={() => {
            setTimeout(() => setShowAddBlock(false), 250);
          }}
          onFocus={() => enteredGroupName && setShowAddBlock(true)}
          onInputChange={(value) => {
            formik.setFieldValue('name', value);
            formik.setErrors({});
            setEnteredGroupName(value || '');
            setShowAddBlock(Number(value?.length) > 0);
          }}
          onValueChanged={() => {
            handleAddBlockClick();
            cdpSuggestedGroupsModalV1Clicked({ action: 'custom_enter' });
          }}
          onValueRemove={(id) => {
            cdpSuggestedGroupsModalV1Clicked({ action: 'deselect_pill' });
            dispatch({ type: 'removePill', id });
          }}
          placeholder={t('inputs.custom_name.updated_placeholder')}
          selectedValues={selectedPills.map((pill) => ({ label: pill.name, value: pill.id }))}
        />
        {showAddBlock && (
          <AddBlockWrapper id='add-group-block' onClick={handleAddBlockClick}>
            <AddBlock>
              {t('inputs.custom_name.add_group_name', {
                group_name: truncate(enteredGroupName, { length: 50 }),
              })}
            </AddBlock>
          </AddBlockWrapper>
        )}
      </SelectWrapper>
      {newGroupKind === 'role' && (
        <>
          <SuggestionsSubheader>{t('suggestions_subheader')}</SuggestionsSubheader>
          <UnselectedPills
            dataIsLoading={isSuggestedGroupsLoading}
            emptyText={t('empty_message')}
            maxDesktopCount={10}
            onModify={(id) => {
              cdpSuggestedGroupsModalV1Clicked({ action: 'select_pill' });
              dispatch({ type: 'addPill', id });
            }}
            resource='group'
            suggestions={unselectedPills}
          />
        </>
      )}
      {isSuggestedGroupsError && <TaskErrorDiv>{t('error_message')}</TaskErrorDiv>}
    </TaskModal>
  );
};

export default connector(CreateGroupNameModal);
