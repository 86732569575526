import documentPdfImage from '../../images/document_pdf.svg';
import googleDocsLogoImage from '../../images/google_docs.svg';
import googleLogoImage from '../../images/google_logo.svg';

export default function getDocumentImageSrc(mimeType: string) {
  switch (mimeType) {
    case 'text/html':
      return googleLogoImage;
    case 'application/pdf':
      return documentPdfImage;
    default:
      return googleDocsLogoImage;
  }
}
