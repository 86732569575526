import { useCallback } from 'react';

import { ComposeModalType } from '../components/application/ai/ComposeModalFactory/ComposeModalFactory';
import useCurrentUser from '../hooks/useCurrentUser';
import { TaskDrivenOnboardingActionId } from '../types/TaskDrivenOnboarding';
import useCurrentAccount from './useCurrentAccount';
import useHeap from './useHeap';
import useHightouch from './useHightouch';

type CtaAction = 'subscribe' | 'upgrade' | 'downgrade';

interface CtaClickedProps {
  cta: string;
  action: CtaAction;
}

interface PublicShareDuplicationToggledProps {
  cta: string;
  subjectId: number;
}

interface SearchResultClickedProps {
  id: number;
  type: string;
  searchHistoryId: string;
  rankingNumber: number;
}

interface cdpSearchListPaginationClickedProps {
  userId: number;
  pageNumber: number;
  searchKind?: string;
  searchHistoryId: string | null;
}

interface BulkUploadModalProps {
  type: string;
}

interface SalesBannerClickedProps {
  cta: string;
  subjectId: number;
}

type SuggestionActions = 'select_pill' | 'deselect_pill' | 'custom_enter';
type SuggestionModalActionProps =
  | {
      action: SuggestionActions;
    }
  | {
      action: 'groups_saved' | 'responsibilities_saved';
      numberOfCustom: number;
      numberOfSuggested: number;
    };

type ComposeProps = { compose_experience: ComposeModalType };
type ComposePropsWithAiCompletion = ComposeProps & { ai_completion_id: number };

type FeedbackProps = {
  compose_experience: ComposeModalType;
  feedbackId: number;
  ai_completion_id: number;
};

export type DocumentImportLaunchLocation =
  | 'universal_add'
  | 'subjects_split_button'
  | 'upload_progress_item'
  | 'content_banner'
  | 'task_driven_onboarding';

type DocumentImportProps = {
  launched_from: DocumentImportLaunchLocation;
};

type EmptyStateVideoProps = {
  action: 'played' | 'dismissed';
  video_type: 'groups' | 'library' | 'delegation-planner-tutorial';
};

type TaskDrivenOnboardingProps = {
  TaskDrivenOnboardingProps: TaskDrivenOnboardingActionId;
};

type FullScreenOnboardingDataProps = {
  prefilled: { company_name: string | null; user_title: string | null; user_name: string | null };
  submitted: { company_name: string; user_title: string; user_name: string };
};

type ResponsibilityDetailsElementDataProps = {
  element: 'rating' | 'time' | 'connect_content';
};

type OverlayType = 'native_app' | 'desktop';

type MobileNudgeOverlayProps = {
  overlayType: OverlayType;
};

export default function useAnalyticsUserEvents() {
  const currentUser = useCurrentUser();
  const currentAccount = useCurrentAccount();

  const { analyticsReset, analyticsTrack } = useHightouch(currentUser, currentAccount);
  const { track: heapTrack } = useHeap(currentUser, currentAccount);
  const track = useCallback(
    (eventName: string, props?: object) => {
      analyticsTrack(eventName, props);
      heapTrack(eventName, props);
    },
    [analyticsTrack, heapTrack]
  );
  const reset = useCallback(() => {
    analyticsReset();
  }, [analyticsReset]);
  const cdpBulkUploadModalDisplayed = useCallback(
    (props: BulkUploadModalProps) => {
      track('bulk_upload_modal_displayed', props);
    },
    [track]
  );
  const cdpCtaClicked = useCallback(
    (props: CtaClickedProps) => {
      track('cta_clicked', props);
    },
    [track]
  );
  const cdpPublicShareDuplicationToggled = useCallback(
    (props: PublicShareDuplicationToggledProps) => {
      track('cta_clicked', props);
    },
    [track]
  );
  const cdpSearchResultClicked = useCallback(
    (props: SearchResultClickedProps) => {
      track('search_result_clicked', props);
    },
    [track]
  );
  const cdpSearchListPaginationClicked = useCallback(
    (props: cdpSearchListPaginationClickedProps) => {
      track('search_list_pagination_clicked', props);
    },
    [track]
  );
  const cdpSalesBannerClicked = useCallback(
    (props: SalesBannerClickedProps) => {
      track('cta_clicked', props);
    },
    [track]
  );
  const cdpSuggestedGroupsModalV1Clicked = useCallback(
    (props: SuggestionModalActionProps) => {
      track(`suggested_role_names_modal_v1_${props.action}`, props);
    },
    [track]
  );
  const cdpSuggestionsResponsibilitiesModalClicked = useCallback(
    (props: SuggestionModalActionProps) => {
      track(`suggestions_responsibilities_modal_${props.action}`, props);
    },
    [track]
  );
  const cdpCompositionTermsPromptDisplayed = useCallback(
    (props: ComposeProps) => {
      track(`compose_terms_modal_displayed`, props);
    },
    [track]
  );
  const cdpCompositionDisplayed = useCallback(
    (props: ComposeProps) => {
      track(`compose_experience_displayed`, props);
    },
    [track]
  );
  const cdpCompositionGenerated = useCallback(
    (props: ComposePropsWithAiCompletion) => {
      track(`compose_experience_generated`, props);
    },
    [track]
  );
  const cdpCompositionRegenerated = useCallback(
    (props: ComposePropsWithAiCompletion) => {
      track(`compose_experience_regenerated`, props);
    },
    [track]
  );
  const cdpCompositionAccepted = useCallback(
    (props: ComposePropsWithAiCompletion) => {
      track(`compose_experience_generation_accepted`, props);
    },
    [track]
  );
  const cdpFeedbackLeft = useCallback(
    (props: FeedbackProps) => {
      track(`compose_experience_feedback_left`, props);
    },
    [track]
  );
  const cdpDocumentImportDisplayed = useCallback(
    (props: DocumentImportProps) => {
      track('document_import_displayed', props);
    },
    [track]
  );
  const cdpEmptyStateVideoClicked = useCallback(
    (props: EmptyStateVideoProps) => {
      track(`empty_state_video_${props.action}`, props);
    },
    [track]
  );
  const cdpTaskDrivenOnboardingClicked = useCallback(
    (props: TaskDrivenOnboardingProps) => {
      track('task_driven_onboarding', props);
    },
    [track]
  );
  const cdpOnboardingDataPrefilled = useCallback(
    (props: FullScreenOnboardingDataProps) => {
      track('onboarding_data_prefilled', props);
    },
    [track]
  );
  const cdpResponsibilityDetailsElementClicked = useCallback(
    (props: ResponsibilityDetailsElementDataProps) => {
      track(`responsibility_${props.element}_clicked`, props);
    },
    [track]
  );
  const cdpMobileNudgeOverlayViewed = useCallback(
    (props: MobileNudgeOverlayProps) => {
      track(`mobile_nudge_${props.overlayType}_overlay_viewed`);
    },
    [track]
  );

  return {
    cdpBulkUploadModalDisplayed,
    cdpCtaClicked,
    cdpPublicShareDuplicationToggled,
    cdpSearchResultClicked,
    cdpSearchListPaginationClicked,
    cdpSalesBannerClicked,
    cdpSuggestedGroupsModalV1Clicked,
    cdpSuggestionsResponsibilitiesModalClicked,
    cdpCompositionTermsPromptDisplayed,
    cdpCompositionDisplayed,
    cdpCompositionGenerated,
    cdpCompositionRegenerated,
    cdpCompositionAccepted,
    cdpFeedbackLeft,
    cdpDocumentImportDisplayed,
    cdpEmptyStateVideoClicked,
    cdpTaskDrivenOnboardingClicked,
    cdpOnboardingDataPrefilled,
    cdpResponsibilityDetailsElementClicked,
    cdpMobileNudgeOverlayViewed,
    resetAnalyticsUser: reset,
  };
}
