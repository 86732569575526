import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { FinchProvider } from '../../../../../types/IntegrationProvider';
import Link from '../../../../design_system/Link';
import { routes } from '../../../publicApplication/applicationRouter';
import { TextFragment } from '../styles';
import { ActivityAssociationParams } from '../types';

export interface UserFinchTemplateProps {
  activityKey: string;
  trackable?: ActivityAssociationParams;
  parameters: { source: FinchProvider };
}

const FinchTemplate = ({ parameters, activityKey }: UserFinchTemplateProps) => {
  const t = initTranslations(`reports.activity_log.${activityKey}`);
  const { slug } = useCurrentAccount();
  const integrationName = initTranslations()(`finch_connect.provider.${parameters.source}`);
  const reauthorizeUrl = routes.integration({ slug, provider: parameters.source }).href;
  const bulkInviteUrl = routes.manageUsers({ slug, open_react_finch_upload_modal: true }).href;

  const ACTIVITY_KEYS: { [P: string]: string } = {
    'user.finch.provider_connected': bulkInviteUrl,
    'user.finch.need_to_reauthorize': reauthorizeUrl,
  };
  const actionUrl = ACTIVITY_KEYS[activityKey];

  return (
    <>
      <TextFragment>
        <span
          dangerouslySetInnerHTML={{
            __html: t('message', { integration: integrationName }),
          }}
        />
      </TextFragment>
      <Link href={actionUrl} text={t('click_link')} underlineBehavior='dynamic' />
    </>
  );
};

export default FinchTemplate;
