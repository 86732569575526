import { useCallback } from 'react';
import { useCookies } from 'react-cookie';

import useCurrentAccount from './useCurrentAccount';

export default function useAccountCookies(
  cookieName: string[]
  // This hook aims to adhere to the interface declared by useCookies.
  //  Therefore, since useCookies uses the "any" type, we are here as well.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): [any, (name: string, value: string | number) => void] {
  const [cookies, setCookie] = useCookies(cookieName);
  const { cookiePath } = useCurrentAccount();
  const setAccountCookie = useCallback(
    (name: string, value: string | number) => {
      setCookie(name, value, { path: cookiePath });
    },
    [setCookie, cookiePath]
  );
  return [cookies, setAccountCookie];
}
