import React, { useMemo } from 'react';

import useCurrentAccount from '../../../../../../../hooks/useCurrentAccount';
import useCurriculumElement from '../../../../../../../hooks/useCurriculumElement';
import initTranslations from '../../../../../../../lib/initTranslations';
import Icon from '../../../../../../design_system/display/icons/Icon';
import { routes } from '../../../../../publicApplication/applicationRouter';
import {
  ElementTitle,
  EmojiWrapper,
  RegularText,
  TextTitleWrapper,
  TitleWrapper,
  TitleWrapperRoute,
} from '../../Columns/styles';
import { TableColumnProps } from '../types';

const t = initTranslations('reports.individual_user_report.table.columns');

const Title = ({ record }: TableColumnProps) => {
  const { slug } = useCurrentAccount();
  const { id, title, elementType, curriculumId, userId, lastAttemptId, attemptsCount } = record;
  const isSurvey = elementType === 'Survey::Survey';

  const { iconName, showRoute } = useCurriculumElement()({ elementType, elementId: id });

  const TitleInner = useMemo(
    () => (
      <>
        <EmojiWrapper id={`curriculum-element-${id}-emoji`}>
          <Icon name={iconName} size='sm' weight='regular' />
        </EmojiWrapper>
        <ElementTitle>
          <RegularText>
            {lastAttemptId ? `${title} ${t('attempts', { count: attemptsCount })}` : title}
          </RegularText>
        </ElementTitle>
      </>
    ),
    [attemptsCount, iconName, id, lastAttemptId, title]
  );

  const ElementTitleWrapper = isSurvey ? (
    <TextTitleWrapper>{TitleInner}</TextTitleWrapper>
  ) : (
    <TitleWrapper {...showRoute.link} text={TitleInner} />
  );

  return (
    <>
      {lastAttemptId ? (
        <TitleWrapperRoute
          to={routes.surveyByUserReport({
            slug,
            attemptId: lastAttemptId,
            surveyId: id,
            curriculumId,
            userId,
          })}
        >
          {TitleInner}
        </TitleWrapperRoute>
      ) : (
        <>{ElementTitleWrapper}</>
      )}
    </>
  );
};

export default Title;
