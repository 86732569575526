import styled, { css } from 'styled-components';

import hexToRGBA from '../../../../lib/HexToRGBA';
import Avatar from '../../../design_system/display/avatar';
import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import FlexContainer from '../../../styled/FlexContainer';
import { fontSm2, fontSm4 } from '../../../styled/TypeSystem';

export const AvatarsGroupWrapper = styled.ul`
  display: flex;
  list-style-type: none;
  margin: ${({ theme: { constants } }) => `0 ${constants.spacerSm3} 0 0`};
  padding: 0;
  transition: 0.2s ease;
`;

export const AvatarItem = styled.li`
  display: flex;
  transition: 0.2s ease;
  margin-right: 0;

  &:nth-child(n + 2) {
    margin-left: -0.625rem;
  }

  ${AvatarsGroupWrapper}:hover & {
    @media (min-width: ${mediaBreakpointPxSm}) {
      margin-right: ${({ theme: { constants } }) => constants.spacerSm1};
    }
  }
`;

// DS Override: Grouped avatars need borders to separate them
export const StyledAvatar = styled(Avatar)`
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.textSurface}`};
`;

export const MoreLabel = styled(FlexContainer)<{
  withTotalUserCountBorder: boolean;
  onClick?: () => void;
  totalUserCount: number;
}>`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  cursor: ${({ onClick }) => onClick && 'pointer'};

  ${({ withTotalUserCountBorder, theme: { constants, vars } }) =>
    withTotalUserCountBorder && {
      minWidth: '2rem',
      height: constants.heightSm,
      justifyContent: 'center',
      padding: constants.spacerSm1,
      lineHeight: '2rem',
      border: `${constants.borderWidthSm} solid ${vars.borderSurface2}`,
      borderRadius: constants.borderRadiusCircle,
      marginLeft: withTotalUserCountBorder ? constants.spacerSm3 : constants.spacerSm2,
      transition: '0.2s ease',

      [`@media (min-width: ${mediaBreakpointPxSm})`]: {
        '&:hover': {
          color: vars.accentPrimaryDefault,
          backgroundColor: hexToRGBA(vars.accentPrimaryDefault, 0.05),
          borderColor: vars.accentPrimaryDefault,
        },
      },
    }};

  ${({ totalUserCount }) => css`
    ${totalUserCount <= 99 && fontSm4}
    ${totalUserCount >= 100 && totalUserCount <= 999 && fontSm2}
    ${totalUserCount >= 1000 && totalUserCount <= 9999 && 'font-size: 0.5rem;'}
    ${totalUserCount >= 10000 && 'font-size: 0.4rem;'}
  `}
`;
