import React, { ReactElement, useContext, useEffect, useState } from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import {
  curriculumsApi,
  useMoveCurriculumMutation,
} from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { MoveCurriculumData } from '../../../../../redux/services/resourceApis/curriculums/types';
import AccountTerminology from '../../../../../types/AccountTerminology';
import { Sector, sectorsToSelectOptions } from '../../../../../types/Sector';
import { AccountTerminologyContext } from '../../../../AccountTerminologyProvider';
import { formatOptions } from '../../../../design_system/core/CoreSelectField/CoreSelectField';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import SingleSelectField from '../../../../design_system/Triage/fields/SingleSelectField';
import Loader from '../../../../design_system/Triage/Loader';
import DeprecatedRestrictMoveCurriculumModal from '../DeprecatedRestrictMoveCurriculumModal/DeprecatedRestrictMoveCurriculumModal';
import { NormalText } from './styles';
import { Props, SelectedOption } from './types';

const t = initTranslations('move_curriculum_modal');

type ErrorType = {
  data: { baseErrors: string };
};

const MoveCurriculumModal = (props: Props): ReactElement => {
  const [showRestrictModal, setShowRestrictModal] = useState(false);
  const { curriculumId, title, showModal, setShowModal } = props;
  const [getCurriculums, getCurriculumsResult] = curriculumsApi.useLazyGetMoveCurriculumDataQuery();
  const { data } = getCurriculumsResult;
  const [disabled, setDisabled] = useState(true);
  const [selectedValue, setSelectedValue] = useState<SelectedOption>({ sector: '' });
  const accountTerminology = useContext(AccountTerminologyContext);
  const {
    curriculum: { singular: curriculumTerm },
  } = accountTerminology;
  const header = t('move_subject', { curriculumTerm });

  const updateSelectedValue = (value: string) => {
    setSelectedValue({ sector: value });
  };

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    showModal && getCurriculums({ id: curriculumId });
  }, [curriculumId, getCurriculums, showModal]);

  useEffect(() => {
    setDefaultValue(defaultValue);
  }, [defaultValue]);

  const [moveCurriculum, result] = useMoveCurriculumMutation();
  const { isSuccess, isLoading, isError, error } = result;
  const moveCurriculumError = error as ErrorType;
  const handleMoveCurriculum = () => {
    moveCurriculum({ id: curriculumId, params: selectedValue });
  };
  useEffect(() => {
    if (isSuccess) {
      window.location.reload();
    }
    if (isError) {
      if (
        moveCurriculumError &&
        moveCurriculumError.data &&
        moveCurriculumError.data.baseErrors[0] === 'moving_is_restricted'
      ) {
        setShowModal(false);
        setShowRestrictModal(true);
      }
    }
  }, [isError, isSuccess, setShowModal, moveCurriculumError]);

  const closeWithoutSaving = () => {
    setDisabled(true);
    setShowModal(false);
  };

  const taskModalArgs: TaskModalProps = {
    title: header,
    onCloseRequest: closeWithoutSaving,
    processing: isLoading,
    primaryButtonText: t('move'),
    primaryButtonTask: handleMoveCurriculum,
    isDisabled: disabled,
    heapModalName: 'move-curriculum-modal',
  };

  return (
    <>
      {showModal && (
        <TaskModal {...taskModalArgs} desktopSize='md'>
          <NormalText
            dangerouslySetInnerHTML={{
              __html: t('move_subject_subtext', { curriculum: title }),
            }}
          />
          {data ? (
            <SingleSelectField
              defaultValue={getDefaultValue(data)}
              onDefaultSelected={() => {
                setDisabled(true);
              }}
              onNonDefaultSelected={(value: Sector | string) => {
                setDisabled(false);
                setDefaultValue('');
                updateSelectedValue(value);
              }}
              options={formatOptions(setOptions(accountTerminology, data.sectors))}
              value={selectedValue?.sector || null}
            />
          ) : (
            <Loader />
          )}
        </TaskModal>
      )}
      <DeprecatedRestrictMoveCurriculumModal
        closeModal={() => setShowRestrictModal(false)}
        showModal={showRestrictModal}
      />
    </>
  );
};

export default MoveCurriculumModal;

const NoneDefaultValue = '0';

type SetOptionsArgs = [AccountTerminology, Sector[]?];

const setOptions = (...args: SetOptionsArgs) => {
  const [accountTerminology, sectors = []] = args;

  return sectorsToSelectOptions(sectors, accountTerminology);
};

type GetDefaultValueArgs = [MoveCurriculumData];

function getDefaultValue(...args: GetDefaultValueArgs) {
  const [data] = args;
  if (!data) return NoneDefaultValue;

  const { sector, sectors } = data;
  if (sectors.length === 0) return NoneDefaultValue;
  return sector;
}
