import { Form, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import * as yup from 'yup';

import { useEmbedlyContext } from '../../../../../contexts/EmbedlyProvider';
import initTranslations from '../../../../../lib/initTranslations';
import isUrl from '../../../../../lib/isUrl';
import InputField from '../../../../design_system/Triage/InputField';
import { FormProps } from './EmbedlyInput';

const t = initTranslations('editor');

export const embedlySchema = () => {
  return yup.object().shape({
    url: yup
      .string()
      .trim()
      .required(t('embedly.form.errors.required'))
      .test('is-valid-url', t('embedly.form.errors.invalid_url'), (url) => !!url && isUrl(url)),
  });
};

export type Values = {
  url: string;
};

const EmbedlyForm = ({ formLabel }: FormProps) => {
  const {
    resetForm: manualResetForm,
    setResetForm,
    setIsFormValid,
    setEmbedlyData,
    setErrorMessage,
    provider,
  } = useEmbedlyContext();
  const { handleChange, submitForm, errors, setErrors, touched, resetForm, values } =
    useFormikContext<Values>();

  const debouncedSubmit = useMemo(
    () =>
      debounce(async () => {
        await submitForm();
      }, 750),
    [submitForm]
  );

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    debouncedSubmit();
    setEmbedlyData(null);
    setErrorMessage(null);
    setErrors({});
  };

  useEffect(() => {
    if (errors && touched.url) setIsFormValid(false);
  }, [errors, setIsFormValid, touched.url]);

  useEffect(() => {
    if (manualResetForm) {
      resetForm({ values: { url: '' } });
      setResetForm(false);
    }
  }, [manualResetForm, resetForm, setResetForm]);

  return (
    <Form>
      <InputField
        autoFocus
        data-testid='embedly-input'
        errorText={touched.url && errors.url}
        id='embedly-input'
        label={formLabel ? formLabel : t(`${provider[0]}.label`)}
        name='url'
        onBlur={({ target }) => target.focus()}
        onChange={onChange}
        placeholder={t(`${provider[0]}.placeholder`)}
        value={values.url}
      />
    </Form>
  );
};

export default EmbedlyForm;
