import React, { ReactNode } from 'react';
import { Collapse } from 'react-collapse';
import styled, { css } from 'styled-components';

import { RegisteredMenuId } from '../../../../../lib/idRegistry';
import initTranslations from '../../../../../lib/initTranslations';
import { fontMd3 } from '../../../../styled/TypeSystem';
import IconButton from '../../../buttons/IconButton';
import ThreeDotMenu from '../../menus/ThreeDotMenu';
import { ThreeDotMenuOptionProps } from '../../menus/ThreeDotMenu/types';
import { CollapseAction } from './reducers/collapse';

const CollapsibleSurfaceWrapper = styled.section`
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
`;

const Header = styled.header<{ collapsed: boolean; isCollapsible: boolean }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerMd3}`};
  border-bottom: ${({ theme: { constants, vars }, collapsed, isCollapsible }) =>
    isCollapsible && collapsed
      ? 'none'
      : `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  cursor: pointer;
`;

const Title = styled.h1`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  margin: 0;
  ${fontMd3};
`;

const ContentWrapper = styled.div<{ collapsed: boolean; isCollapsible: boolean }>(
  ({ theme: { constants }, collapsed, isCollapsible }) => css`
    transition: padding 0.5s ease-out;
    padding: ${isCollapsible && collapsed ? 0 : constants.spacerMd3} ${constants.spacerMd3};
  `
);

export const IconWrapper = styled.div`
  align-items: center;
  display: flex;
`;

/**
 * TODO: Please remove/update this functionality once DS introduces a component
 * that supports icon transitions
 * For more details please reference discussion: https://github.com/trainual/trainual/pull/9856#discussion_r924995738
 */
export const StyledIconButton = styled(IconButton)<{ $collapsed: boolean }>`
  transition: transform 0.2s ease-in;
  transform: ${({ $collapsed }) => ($collapsed ? 'rotate(0deg)' : 'rotate(180deg)')};
`;

export const CollapsibleSection = styled.section`
  .ReactCollapse--collapse {
    transition: height 0.5s ease-in-out;
  }
`;

type CollapsibleContentProps = {
  isCollapsed: boolean;
  isCollapsible?: boolean;
  children: ReactNode | ReactNode[];
};

export const CollapsibleContent = ({
  isCollapsed,
  isCollapsible,
  children,
}: CollapsibleContentProps) => (
  <CollapsibleSection>
    <Collapse isOpened={!isCollapsed || !isCollapsible}>{children}</Collapse>
  </CollapsibleSection>
);

export const transformThreeDotMenuOptions = (
  options: CollapsibleMenuOptionProps[] | undefined,
  isCollapsed: boolean,
  collapseDispatch: React.Dispatch<CollapseAction>
) =>
  options?.map((menuOption): CollapsibleMenuOptionProps => {
    return {
      ...menuOption,
      onClick: (e) => {
        menuOption.shouldToggleCollapse &&
          isCollapsed &&
          collapseDispatch({ type: 'toggle-chevron' });
        return menuOption.onClick(e);
      },
    };
  });

const t = initTranslations('collapsible_surface');

export type CollapsibleMenuOptionProps = ThreeDotMenuOptionProps & {
  shouldToggleCollapse?: boolean;
};

export type MenuOptions =
  | {
      threeDotMenuOptions?: never;
      threeDotMenuId?: never;
    }
  | {
      threeDotMenuOptions: CollapsibleMenuOptionProps[];
      threeDotMenuId: RegisteredMenuId;
    };

export type Props = {
  children: ReactNode | ReactNode[];
  title: string | ReactNode;
  chevronClassName?: string;
  className?: string;
  collapseDispatch: React.Dispatch<CollapseAction>;
  id: string;
  isCollapsed: boolean;
  isCollapsible?: boolean;
} & MenuOptions;

const CollapsibleSurface = ({
  children,
  title,
  chevronClassName = 'collapsible-surface-chevron',
  className = 'collapsible-surface',
  collapseDispatch,
  id,
  isCollapsible = true,
  isCollapsed,
  threeDotMenuOptions,
  threeDotMenuId,
}: Props) => {
  const transformedThreeDotMenuOptions = transformThreeDotMenuOptions(
    threeDotMenuOptions,
    isCollapsed,
    collapseDispatch
  );

  return (
    <CollapsibleSurfaceWrapper className={className} id={id}>
      <Header
        collapsed={isCollapsed}
        isCollapsible={isCollapsible}
        onClick={() => collapseDispatch({ type: 'toggle-chevron' })}
      >
        <Title>{title}</Title>
        <IconWrapper>
          {threeDotMenuId &&
            transformedThreeDotMenuOptions &&
            transformedThreeDotMenuOptions.length > 0 && (
              <ThreeDotMenu
                id={threeDotMenuId}
                menuOptions={transformedThreeDotMenuOptions}
                menuPlacement='left-start'
              />
            )}
          {isCollapsible && (
            <StyledIconButton
              $collapsed={isCollapsed}
              ariaLabel={isCollapsed ? t('aria_label.show_content') : t('aria_label.hide_content')}
              className={chevronClassName}
              name='chevron-down'
            />
          )}
        </IconWrapper>
      </Header>
      <ContentWrapper collapsed={isCollapsed} isCollapsible={isCollapsible}>
        <CollapsibleContent isCollapsed={isCollapsed} isCollapsible={isCollapsible}>
          {children}
        </CollapsibleContent>
      </ContentWrapper>
    </CollapsibleSurfaceWrapper>
  );
};

export default CollapsibleSurface;
