import React from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import { NullableBillingDetails } from '../../../../../../../redux/services/resourceApis/billing/types';
import { BillingAddressDetails } from '../../../../../../../types/BillingAddressDetails';
import { Description } from '../styles';

type BillingAddressProps = {
  billingAddressDetails: NullableBillingDetails<BillingAddressDetails>;
};

const t = initTranslations('per_user_pricing.billing_page.information_card');

const BillingAddress = ({ billingAddressDetails }: BillingAddressProps) => {
  const { addressLine1, addressLine2, addressCity, addressState, addressZip, addressCountry } =
    billingAddressDetails;
  const cityStateZip = `${addressCity}, ${addressState}, ${addressZip}, ${addressCountry}`;

  return (
    <>
      {addressLine1 ? (
        <>
          <Description text={addressLine1} />
          {addressLine2 && <Description text={addressLine2} />}
          <Description text={cityStateZip} />
        </>
      ) : (
        <Description text={t('no_billing_details')} />
      )}
    </>
  );
};

export default BillingAddress;
