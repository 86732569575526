import React, { useCallback, useState } from 'react';

import initTranslations from '../../../../lib/initTranslations';
import {
  useGetEditSurveyQuery,
  useUpdateSurveyMutation,
} from '../../../../redux/services/resourceApis/surveys/surveysApi';
import AiDescriptionModal from '../AiDescriptionModal/AiDescriptionModal';
import { CompletionResponses } from '../GroupDescriptionModal/types';
import { getSurveyDescriptionPromptMessages } from './SurveyPromptMessages';
import { SurveyDescriptionModalProps } from './types';

const t = initTranslations('survey_description_modal');

const SurveyDescriptionModal = ({
  closeModal,
  composeModalType,
  metadata: { surveyId },
}: SurveyDescriptionModalProps) => {
  const { data: survey } = useGetEditSurveyQuery({ id: surveyId });
  const [updateSurvey, updateSurveyResult] = useUpdateSurveyMutation();
  const [currentCompletionResponse, setCurrentCompletionResponse] = useState<CompletionResponses>({
    completion: undefined,
    prevCompletion: undefined,
    hasGeneratedSuccessfully: false,
  });
  const { completion } = currentCompletionResponse;

  const saveToSurvey = useCallback(() => {
    if (completion?.status !== 'completed') return;

    updateSurvey({ id: surveyId, description: completion.completion });
  }, [completion, surveyId, updateSurvey]);

  const { reset } = updateSurveyResult;

  return (
    <AiDescriptionModal
      aiFeatureName='composeSurveyDescription'
      closeModal={closeModal}
      composeFeatureName='composeSurveyDescription'
      composeModalType={composeModalType}
      currentCompletionResponse={currentCompletionResponse}
      entity={survey}
      flashNotificationMessage={t('saved_suggested_description')}
      flashNotificationResult={updateSurveyResult}
      loadingText={t('generating_survey_description')}
      localeKey='survey_description_modal'
      modalTitle={t('title')}
      promptMessages={getSurveyDescriptionPromptMessages(survey)}
      resetCompletion={reset}
      saveToEntity={saveToSurvey}
      setCurrentCompletionResponse={setCurrentCompletionResponse}
    />
  );
};

export default SurveyDescriptionModal;
