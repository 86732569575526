import { FormikErrors } from 'formik';

import initTranslations from '../../../../lib/initTranslations';
import isEmail from '../../../../lib/isEmail';
import isValidFullName from '../../../../lib/isValidName';
import { TeammateModalFormValues } from './InterfaceAndTypes';

const t = initTranslations('teammate_modal');

const validateForm = (
  {
    name,
    email,
    schedule_archive,
    scheduled_invite_at,
    scheduled_archive_at,
    schedule_invite,
  }: TeammateModalFormValues,
  action: string
) => {
  const errors: FormikErrors<TeammateModalFormValues> = {};

  if (!name || !isValidFullName(name)) {
    errors.name = t('shared.inputs.errors.name.required');
  }

  if (!email) {
    errors.email = t('shared.inputs.errors.email.required');
  } else if (!isEmail(email)) {
    errors.email = t('shared.inputs.errors.email.invalid');
  }

  if (schedule_invite) {
    if (!scheduled_invite_at) {
      errors.scheduled_invite_at = t('shared.inputs.errors.scheduled_invite_date.required');
    } else {
      let scheduledDate = new Date(scheduled_invite_at);
      scheduledDate = new Date(scheduledDate.getTime() + scheduledDate.getTimezoneOffset() * 60000);

      if (scheduledDate.toString() === 'Invalid Date') {
        errors.scheduled_invite_at = t('shared.inputs.errors.scheduled_invite_date.invalid');
      } else {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const tomorrow = new Date(currentDate);
        tomorrow.setDate(currentDate.getDate() + 1);
        if (
          (action === 'edit' && scheduledDate < currentDate) ||
          (action !== 'edit' && scheduledDate < tomorrow)
        ) {
          errors.scheduled_invite_at = t('shared.inputs.errors.scheduled_invite_date.past');
        }
      }
    }
  }

  if (schedule_archive) {
    if (!scheduled_archive_at) {
      errors.scheduled_archive_at = t('shared.inputs.errors.scheduled_archive_date.required');
    } else {
      let scheduledDate = new Date(scheduled_archive_at);
      scheduledDate = new Date(scheduledDate.getTime() + scheduledDate.getTimezoneOffset() * 60000);

      if (scheduledDate.toString() === 'Invalid Date') {
        errors.scheduled_archive_at = t('shared.inputs.errors.scheduled_archive_date.invalid');
      } else {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const tomorrow = new Date(currentDate);
        tomorrow.setDate(currentDate.getDate() + 1);
        if (
          (action === 'edit' && scheduledDate < currentDate) ||
          (action !== 'edit' && scheduledDate < tomorrow)
        ) {
          errors.scheduled_archive_at = t('shared.inputs.errors.scheduled_archive_date.past');
        }
      }
    }
  }

  return errors;
};

export default validateForm;
