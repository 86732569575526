import React from 'react';

import { columns } from './Column';
import { BodyRow, Loader, LoadingTableCell, TableStyled, TableWrapper } from './Styles';

const LoadingTable = () => {
  return (
    <TableWrapper>
      <TableStyled>
        <tbody>
          {[...Array(15)].map((_, index) => (
            <BodyRow key={`loading_row_${index}`}>
              {columns
                .filter(({ display }) => display !== 'hidden')
                .map((column, index) => (
                  <LoadingTableCell key={`loading-td-${index}`}>
                    <Loader $width={column.loadingSize} />
                  </LoadingTableCell>
                ))}
            </BodyRow>
          ))}
        </tbody>
      </TableStyled>
    </TableWrapper>
  );
};

export default LoadingTable;
