import React from 'react';

import { Activity } from '../types';
import CommonTemplate, { StepCommonTemplateTemplateProps } from './CommonTemplate';

interface StepsActivitiesProps {
  'step.destroy': React.FC<StepCommonTemplateTemplateProps>;
  'step.restore': React.FC<StepCommonTemplateTemplateProps>;
}

const ACTIVITY_KEYS: StepsActivitiesProps = {
  'step.destroy': CommonTemplate,
  'step.restore': CommonTemplate,
};

export interface StepActivity extends Omit<Activity, 'trackable' | 'parameters'> {
  trackable: { id: number; name: string };
  parameters: { course_id: number; course_title: string; course_active: boolean };
}

interface StepsCardProps {
  activity: StepActivity;
}

const StepActivitiesDetails = ({ activity }: StepsCardProps) => {
  const ActivityMessage = ACTIVITY_KEYS[activity.key as keyof StepsActivitiesProps];

  return (
    <ActivityMessage
      activityKey={activity.key}
      parameters={activity.parameters}
      trackable={activity.trackable}
    />
  );
};

export default StepActivitiesDetails;
