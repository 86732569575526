import React from 'react';
import styled from 'styled-components';

import { useTeammateModal } from '../../../../contexts/TeammateContext';
import initTranslations from '../../../../lib/initTranslations';
import { fontSm5 } from '../../../styled/TypeSystem';

const StyledAddTeammate = styled.div<{ large: boolean }>`
  cursor: pointer;
  padding: ${({ theme: { constants }, large }) =>
    large
      ? `${constants.spacerMd1} ${constants.spacerMd2}`
      : `${constants.spacerSm3} ${constants.spacerMd1}`};
  border-radius: ${({ theme: { constants }, large }) => large && constants.borderRadiusMd};

  &:hover {
    background-color: ${({ theme: { vars } }) => vars.foundationBase2};
  }
`;

const StyledIcon = styled.span<{ large: boolean }>`
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} dashed ${vars.textSubdued}`};
  padding: ${({ large }) => (large ? '0.35rem 0.75rem' : '0.3125rem 0.625rem')};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  /* Can't use TypeSystem var here, circle becomes oval. */
  font-size: ${({ large }) => (large ? '1rem' : '0.703rem')};
  display: inline-block;
`;

const StyledLabel = styled.span`
  margin: ${({
    theme: {
      constants: { spacerSm3 },
    },
  }) => `${spacerSm3} 0 ${spacerSm3} ${spacerSm3}`};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontSm5};
`;

const t = initTranslations('buttons');

type Props = {
  large?: boolean;
};

const AddTeammateMenuListButton = ({ large = false }: Props) => {
  const { setTeammateModalVisible } = useTeammateModal();

  return (
    <StyledAddTeammate
      id='invite-teammate-dropdown-button'
      large={large}
      onClick={() => setTeammateModalVisible(true)}
    >
      <StyledIcon large={large}>+</StyledIcon>
      <StyledLabel>{t('add_teammate_button')}</StyledLabel>
    </StyledAddTeammate>
  );
};

export default AddTeammateMenuListButton;
