import styled from 'styled-components';

import { fontSm4, fontSm5 } from '../../../../../styled/TypeSystem';

export const DropZoneContainer = styled.div<{ isDisabled?: boolean }>`
  background-color: ${({ theme: { vars } }) => vars.foundationHover};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} dashed ${vars.accentPrimaryDefault}`};
  width: 100%;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const Dropzone = styled.div`
  padding: ${({ theme: { constants } }) => `${constants.spacerLg3} 0`};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DropzoneImage = styled.img`
  width: 9rem;
  max-height: 6rem;
`;

export const DropzoneDescription = styled.div`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontSm5};
`;

export const DropzoneHint = styled.div`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm5};
`;

export const DropzoneAssistiveText = styled.p`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  ${fontSm4};
`;
