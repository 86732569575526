import styled from 'styled-components';

import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import { mediaBreakpointPxSm } from '../../../../../styled/Breakpoint';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${mediaBreakpointPxSm}) {
    flex-direction: row;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin-right: 0;

  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  }
`;

export const StyledButton = styled(DefaultButton)`
  width: 100%;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: auto;
    margin-top: 0;
  }
`;
