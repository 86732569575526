import React from 'react';

import { unixTimestampToFormattedDate } from '../../../../../../lib/billing/unixTimestampToFormattedDate';
import initTranslations from '../../../../../../lib/initTranslations';
import { useStatusBannerDataQuery } from '../../../../../../redux/services/resourceApis/accounts/accountsApi';
import AccountStatusBanner from '../AccountStatusBanner';

const t = initTranslations('per_user_pricing.account_status_banners.on_hold');

const OnHoldBanner = () => {
  const { data, isLoading } = useStatusBannerDataQuery();

  if (isLoading || !data) return <></>;

  const formattedCurrentPeriodEndDate = unixTimestampToFormattedDate(data.currentPeriodEnd);
  const messageTitleTranslation = formattedCurrentPeriodEndDate
    ? t('title_with_date', {
        date: formattedCurrentPeriodEndDate,
      })
    : t('title');

  return (
    <AccountStatusBanner
      className='on-hold-banner'
      emoji='😴'
      messageBody={t('body')}
      messageTitle={messageTitleTranslation}
    />
  );
};

export default OnHoldBanner;
