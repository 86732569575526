import React, { ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { useCloseFullScreenOverlay } from '../../../../hooks/billing/useCloseFullScreenOverlay';
import { useDispatchSetShowOverlay } from '../../../../hooks/useDispatchSetShowOverlay';
import useKeyPress from '../../../../hooks/useKeyPress';
import initTranslations from '../../../../lib/initTranslations';
import { FullScreenOverlayCloseIconButton, FullScreenOverlayWrapper } from './styles';

export type FullScreenOverlayProps = {
  id: string;
  isAbsolutePosition?: boolean;
  children: ReactNode;
  fixedCloseIcon?: boolean;
  withoutCloseIcon?: boolean;
  isSlideoutOpen?: boolean;
  withOpenedModal?: boolean;
};

const t = initTranslations('aria');

const FullScreenOverlay = ({
  id,
  isAbsolutePosition = true,
  children,
  fixedCloseIcon = false,
  withoutCloseIcon = false,
  isSlideoutOpen = false,
  withOpenedModal = false,
}: FullScreenOverlayProps) => {
  const closeFullScreenOverlay = useCloseFullScreenOverlay();
  const dispatchShowOverlay = useDispatchSetShowOverlay();

  useKeyPress('Escape', !withOpenedModal ? closeFullScreenOverlay : null);

  useEffect(() => {
    dispatchShowOverlay(id, true);

    return () => {
      dispatchShowOverlay(id, false);
    };
  }, [dispatchShowOverlay, id]);

  return ReactDOM.createPortal(
    <FullScreenOverlayWrapper id={id} isAbsolutePosition={isAbsolutePosition}>
      {children}

      {!withoutCloseIcon && (
        <FullScreenOverlayCloseIconButton
          ariaLabel={t('aria_label_close')}
          buttonSize='md'
          fixed={fixedCloseIcon}
          isSlideoutOpen={isSlideoutOpen}
          name='xmark'
          onClick={closeFullScreenOverlay}
          weight='regular'
        />
      )}
    </FullScreenOverlayWrapper>,
    document.getElementById('react-portal') as HTMLElement
  );
};

export default FullScreenOverlay;
