import React from 'react';

import ProgressBar from '../../../../../../design_system/Triage/ProgressBar';
import { CompletionContainer, CompletionScore, ProgressBarWrapper } from '../../Columns/styles';
import { TableColumnProps } from '../types';

const Score = ({ record: { completionScore } }: TableColumnProps) => {
  return (
    <CompletionContainer>
      <CompletionScore>{completionScore}%</CompletionScore>
      <ProgressBarWrapper>
        <ProgressBar percent={completionScore} thickness='md' />
      </ProgressBarWrapper>
    </CompletionContainer>
  );
};

export default Score;
