import React from 'react';

import usePageTitle from '../../../../hooks/usePageTitle';
import initTranslations from '../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import PageContent from '../../shared/page_content';
import { ReportTableWrapper } from '../Styles';
import AllCurriculumsReportTable from '././AllCurriculumsReportTable';
import AllCurriculumsReportFilters from './AllCurriculumsReportFilters/AllCurriculumsReportFilters';

const t = initTranslations('navigation.header');

const AllCurriculumsReport = () => {
  const { curriculum, productTerm } = useAccountTerminology();
  usePageTitle({ resourceType: 'subject_reports', productTerm });

  return (
    <PageContent>
      <PageHeader
        subtitle={t('reports_subtitle')}
        title={t('curriculums_report_title', { curriculum: curriculum.singular })}
      />

      <ReportTableWrapper>
        <AllCurriculumsReportTable />
      </ReportTableWrapper>

      <AllCurriculumsReportFilters />
    </PageContent>
  );
};

export default AllCurriculumsReport;
