import { truncate } from 'lodash';
import React from 'react';

import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';
import { ColumnComponentProps } from '../types';
import { GroupElement, StyledRow } from './styles';

const Groups = ({ record }: ColumnComponentProps) => {
  const groups = record.groups.filter((group) => !group.everyone).map(({ name }) => name);
  const groupsCount = groups.length;

  return (
    <StyledRow>
      {!!groupsCount && <GroupElement>{truncate(groups[0], { length: 20 })}</GroupElement>}
      {groupsCount > 1 && (
        <>
          <Tooltip id={`user-${record.id}-more-roles-tooltip`} text={groups.join(', ')} />
          <div data-for={`user-${record.id}-more-roles-tooltip`} data-tip>
            <GroupElement>+{groupsCount - 1}</GroupElement>
          </div>
        </>
      )}
    </StyledRow>
  );
};

export default Groups;
