import styled from 'styled-components';

import RouterLink from '../../../../publicApplication/RouterLink';

export const TitleWrapper = styled(RouterLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme: { vars } }) => vars.textDefault};

  &:hover {
    text-decoration: none;
    color: ${({ theme: { vars } }) => vars.textDefault};
  }
`;
