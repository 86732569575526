import React from 'react';
import styled from 'styled-components';

import useProgressColor from '../../../../hooks/useProgressColor';
import { fontSm5 } from '../../../styled/TypeSystem';
import Tooltip from '../../display/Tooltip/Tooltip';

export const thicknesses = ['sm', 'md', 'lg'] as const;
type BarThickness = (typeof thicknesses)[number];

const barThicknesses: { [thickness in BarThickness]: number } = {
  sm: 0.25,
  md: 0.375,
  lg: 0.5,
};

const ProgressWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontSm5};
`;

const Bar = styled.div<{ thickness: BarThickness }>`
  border-radius: 0.625rem;
  height: ${({ thickness }) => barThicknesses[thickness]}rem;
`;

const FullBar = styled(Bar)`
  background-color: ${({ theme: { vars } }) => vars.borderSurface1};
  width: 100%;
`;

const CompletedBar = styled(Bar)<{ percent: number; backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: ${({ percent }) => percent + '%'};
`;

export type Props =
  | {
      id?: string | undefined;
      percent: number;
      thickness?: BarThickness;
      labelText?: string;
      labelTooltip?: undefined;
    }
  | {
      id: string;
      percent: number;
      thickness?: BarThickness;
      labelText: string;
      labelTooltip: string;
    };

const ProgressBar = ({ id, percent, thickness = 'sm', labelText, labelTooltip }: Props) => {
  const backgroundColor = useProgressColor({ percent });

  return (
    <>
      {labelText && (
        <ProgressWrapper>
          <span data-for={`progress-label-${id}`} data-tip>
            {labelText}
          </span>
          {labelText && labelTooltip && <Tooltip id={`progress-label-${id}`} text={labelTooltip} />}
          <span>{percent}%</span>
        </ProgressWrapper>
      )}
      <FullBar thickness={thickness}>
        <CompletedBar
          backgroundColor={backgroundColor}
          id={id}
          percent={percent}
          thickness={thickness}
        />
      </FullBar>
    </>
  );
};

export default ProgressBar;
