import React from 'react';

import { CompletionsCardProps } from '../types';
import CommonTemplate, { CompletionCommonTemplateProps } from './CommonTemplate';

interface CompletionActivitiesProps {
  'completion.complete': React.FC<CompletionCommonTemplateProps>;
  'completion.expire': React.FC<CompletionCommonTemplateProps>;
}

const ACTIVITY_KEYS: CompletionActivitiesProps = {
  'completion.complete': CommonTemplate,
  'completion.expire': CommonTemplate,
};

const CompletionActivitiesDetails = ({ activity }: CompletionsCardProps) => {
  const ActivityMessage = ACTIVITY_KEYS[activity.key as keyof CompletionActivitiesProps];

  return <ActivityMessage activityKey={activity.key} trackable={activity.trackable} />;
};

export default CompletionActivitiesDetails;
