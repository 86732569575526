import { sortIcon } from '../../SortIcon';
import { Action, ManageIndividualUserReportOption } from './types';

export const tableReducer = (
  state: ManageIndividualUserReportOption,
  action: Action
): ManageIndividualUserReportOption => {
  const { sortColumn, sortDirection } = state;

  switch (action.type) {
    case 'sortColumnChange': {
      const newSortDirection =
        sortColumn === action.sortColumn && sortDirection === 'asc' ? 'desc' : 'asc';
      return {
        ...state,
        sortColumn: action.sortColumn,
        sortDirection: newSortDirection,
        sortIcon: sortIcon(newSortDirection),
      };
    }
  }
};
