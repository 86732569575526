import { useCallback, useMemo } from 'react';

import { Sector } from '../types/Sector';

export type Route<T = Record<string, unknown>> = (args: T) => string;

type AddTemplateRouteArgs = { templateId: string; ownerId?: string; sector?: string };
type AddTemplateRoute = Route<AddTemplateRouteArgs>;

type CompletionCertificateRouteArgs = { completionId: number };
type CompletionCertificateRoute = Route<CompletionCertificateRouteArgs>;

type NullableUserRouteArgs = { userId?: number | string; queryParams?: string };
type NullableUserRoute = Route<NullableUserRouteArgs>;

type NullableCurriculumsRouteArgs = { sector?: Sector };
type NullableCurriculumRoute = Route<NullableCurriculumsRouteArgs>;

type requestRouteArgs = { sector?: string | null };
type RequestRoute = Route<requestRouteArgs>;

type PublicCurriculumRouteArgs = { publicCurriculumId?: string };
type PublicCurriculumRoute = Route<PublicCurriculumRouteArgs>;

type SectorArgs = { sector: Sector };
type CurriculumIndexRoute = Route<SectorArgs>;

type SectorTemplatesRouteArgs = { sector?: Sector | null };
type SectorTemplatesRoute = Route<SectorTemplatesRouteArgs>;

type NotifyContentOwnerRouteArgs = { elementId: number };
type NotifyContentOwnerRoute = Route<NotifyContentOwnerRouteArgs>;

export interface Routes {
  ajax: {
    addTemplateToAccount: AddTemplateRoute;
    sectorTemplates: SectorTemplatesRoute;
    bulkUploadPayrollCandidates: Route;
    bulkUploadPayroll: Route;
    bulkUploadResultFiles: Route;
    notifyContentOwner: NotifyContentOwnerRoute;
  };
  view: {
    completionCertificate: CompletionCertificateRoute;
    curriculums: CurriculumIndexRoute;
    curriculumsCards: NullableCurriculumRoute;
    courseEditor: Route;
    duplicateCurriculum: PublicCurriculumRoute;
    downloadSignedDocument: Route;
    people: Route;
    requests: RequestRoute;
    users: NullableUserRoute;
  };
}

const useAccountRoutes = (slug: string) => {
  const accountRoot = useCallback(() => {
    return `/${slug}`;
  }, [slug]);

  const resourceRoot = useCallback(
    (resource: string, id?: number | string) => {
      const pathParts = [accountRoot(), resource];
      if (id) {
        pathParts.push(`${id}`);
      }
      return pathParts.join('/');
    },
    [accountRoot]
  );

  const ajaxRoot = useCallback(
    (resource: string, id?: number | string) => {
      const pathParts = [accountRoot(), 'ajax', resource];
      if (id) {
        pathParts.push(`${id}`);
      }
      return pathParts.join('/');
    },
    [accountRoot]
  );

  const routes = useMemo(
    (): Routes =>
      ({
        ajax: {
          addTemplateToAccount: ({ templateId }: AddTemplateRouteArgs) =>
            `${ajaxRoot('templates', templateId)}/add_to_account`,
          sectorTemplates: ({ sector }: SectorArgs) =>
            `${ajaxRoot('templates/sector_templates')}?sector=${sector}`,
          bulkUploadPayrollCandidates: () => `${accountRoot()}/finch/show_bulk_upload_modal`,
          bulkUploadPayroll: () => `${accountRoot()}/finch/bulk_upload`,
          bulkUploadResultFiles: ({
            file_format,
            result_type,
          }: {
            file_format: string;
            result_type: string;
          }) =>
            `${accountRoot()}/ajax/users/bulk_upload_result_files?file_format=${file_format}&result_type=${result_type}`,
          notifyContentOwner: ({ elementId }: NotifyContentOwnerRouteArgs) =>
            `${ajaxRoot('notify_content_owner')}?id=${elementId}`,
        },
        view: {
          completionCertificate: ({ completionId }: CompletionCertificateRouteArgs) =>
            `${accountRoot()}/completions/${completionId}.pdf`,
          curriculums: ({ sector }: SectorArgs) =>
            `${resourceRoot('curriculums')}?sector=${sector}`,
          curriculumsCards: ({ sector }: NullableCurriculumsRouteArgs) =>
            `${accountRoot()}/curriculums${sector ? `?sector=${sector}` : ''}`,
          courseEditor: ({ courseId }: { courseId: number }) =>
            `${accountRoot()}/courses/${courseId}/edit`,
          duplicateCurriculum: ({ publicCurriculumId }: { publicCurriculumId: string }) =>
            `${accountRoot()}/public_curriculums/${publicCurriculumId}/duplicate`,
          downloadSignedDocument: ({ documentId }: { documentId: string }) =>
            `${accountRoot()}/e_signature/download_signed_document?document_id=${documentId}`,
          people: () => `${accountRoot()}/people`,
          requests: ({ sector }: requestRouteArgs) =>
            `${accountRoot()}/requests${!!sector ? `?sector=${sector}` : ''}`,
          users: ({ userId, queryParams }: NullableUserRouteArgs) =>
            `${resourceRoot('users', userId)}${queryParams ? queryParams : ''}`,
        },
      } as Routes),
    [accountRoot, ajaxRoot, resourceRoot]
  );

  return routes;
};

export default useAccountRoutes;
