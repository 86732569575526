import React from 'react';
import { useTheme } from 'styled-components';

import { SkeletonLoader } from '../../../../shared/SkeletonLoader/styles';

const MessageCardLoader = () => {
  const {
    constants: { borderRadiusXs, borderRadiusLg, heightXs, spacerMd2 },
  } = useTheme();

  return (
    <>
      <SkeletonLoader
        borderRadius={borderRadiusXs}
        height={heightXs}
        marginBottom={spacerMd2}
        width='12rem'
      />
      <SkeletonLoader borderRadius={borderRadiusLg} height='4.25rem' marginBottom={spacerMd2} />
      <SkeletonLoader borderRadius={borderRadiusLg} height='4.25rem' />
    </>
  );
};

export default MessageCardLoader;
