import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Route } from 'type-route';

import { GroupsModalProvider, useGroupsModals } from '../../../../contexts/GroupsModalContext';
import { SearchProvider } from '../../../../contexts/SearchContext';
import { useTeammateModal } from '../../../../contexts/TeammateContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import fetchCurrentRoute from '../../../../lib/fetchCurrentRoute';
import groupSortDropdownOptions from '../../../../lib/groupSortDropdownOptions';
import { RegisteredMenuId } from '../../../../lib/idRegistry';
import initTranslations from '../../../../lib/initTranslations';
import { useAppSelector } from '../../../../redux/hooks';
import Icon from '../../../design_system/display/icons/Icon';
import ConfirmationModal from '../../../design_system/overlays/modals/ConfirmationModal';
import Poppable from '../../../Poppable';
import { mediaBreakpointPxMd } from '../../../styled/Breakpoint';
import { fontMd3 } from '../../../styled/TypeSystem';
import CreateGroupKindModal from '../../groups/modals/CreateGroupKindModal/CreateGroupKindModal';
import CreateGroupNameModal from '../../groups/modals/CreateGroupNameModal/CreateGroupNameModal';
import AddTeammateWithConfirmation from '../../people/TeammateModal/AddTeammateWithConfirmation';
import { routes } from '../../publicApplication/applicationRouter';
import routeTo from '../../publicApplication/routeTo';
import useActiveMenuHandler from '../../publicApplication/utils/useActiveMenuHandler';
import OutsideClickHandler from '../../shared/OutsideClickHandler';
import UniversalAddMenuNavigation from './UniversalAddMenuNavigation/UniversalAddMenuNavigation';

export const UNIVERSAL_ADD_MENU_ID: RegisteredMenuId = 'top-nav-universal-add-menu';

const AddIconWrapper = styled.button`
  display: none;

  @media (min-width: ${mediaBreakpointPxMd}) {
    display: flex;
    align-items: center;
    margin: ${({ theme }) => theme.constants.spacerSm3} ${({ theme }) => theme.constants.spacerMd2};
    justify-content: center;
    border: ${({ theme }) => theme.constants.borderWidthSm} solid
      ${({ theme }) => theme.vars.borderSurface1};
    background-color: ${({ theme }) => theme.vars.accentPrimaryDefault};
    border-radius: ${({ theme }) => theme.constants.borderRadiusCircle};
    height: ${({ theme }) => theme.constants.heightMd};
    width: 2.25rem;
    :hover {
      cursor: pointer;
    }
  }
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.vars.textSurface};
  pointer-events: none;
  ${fontMd3};
`;

const t = initTranslations();

export const UniversalAddIcon = () => {
  return (
    <AddIconWrapper className='top-nav-universal-add-trigger' id='top-nav-universal-add'>
      <StyledIcon name='plus' size='md' />
    </AddIconWrapper>
  );
};

interface GroupConfirmationModalWrapperProps {
  pathName: string;
  slug: string;
}

const GroupConfirmationModalWrapper = ({ pathName, slug }: GroupConfirmationModalWrapperProps) => {
  const [showGroupConfirmationModal, setShowGroupConfirmationModal] = useState(false);
  const {
    state: { activeModal, newGroupKind },
  } = useGroupsModals();
  const [newGroupIds, setNewGroupIds] = useState<number[]>([]);
  const shouldShowConfirmationModal = useMemo(() => {
    return pathName !== routes.groups({ slug }).href;
  }, [pathName, slug]);
  const translationKey = 'groups.modals.top_nav_add_group.confirmation_modal';

  const onSuccess = useCallback(
    (groupIds: number[]) => {
      if (shouldShowConfirmationModal) {
        setShowGroupConfirmationModal(true);
        setNewGroupIds(groupIds);
      }
    },
    [shouldShowConfirmationModal]
  );

  return (
    <>
      {activeModal === 'groupKind' && <CreateGroupKindModal />}
      {activeModal === 'groupName' && <CreateGroupNameModal onSuccess={onSuccess} />}
      {showGroupConfirmationModal && !!newGroupIds.length && (
        <GroupConfirmationModal
          actionLink={
            newGroupIds.length === 1
              ? routes.group({
                  slug,
                  id: newGroupIds[0],
                  newGroup: true,
                  breadcrumb: routes.groups({ slug, group_kind: newGroupKind }),
                  tab: 'overview',
                })
              : routes.groups({ slug, group_kind: newGroupKind })
          }
          actionText={t(`${translationKey}.view_groups`, { count: newGroupIds.length })}
          closeModal={() => {
            setShowGroupConfirmationModal(false);
          }}
          message={t(`${translationKey}.groups_message`, { count: newGroupIds.length })}
          newGroupIds={newGroupIds}
          setShowGroupConfirmationModal={setShowGroupConfirmationModal}
          title={t(`${translationKey}.groups_created`, { count: newGroupIds.length })}
        />
      )}
    </>
  );
};

interface GroupConfirmationModalProps {
  actionLink: Route<typeof routes>;
  actionText: string;
  closeModal: () => void;
  message: string;
  newGroupIds: number[];
  setShowGroupConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
}

const GroupConfirmationModal = ({
  actionLink,
  actionText,
  closeModal,
  message,
  setShowGroupConfirmationModal,
  title,
}: GroupConfirmationModalProps) => {
  const modalCopy = {
    actionText,
    message,
    title,
  };

  return (
    <ConfirmationModal
      {...modalCopy}
      actionFunction={() => {
        routeTo(actionLink);
        setShowGroupConfirmationModal(false);
      }}
      heapModalName='top-nav-group-confirmation-modal'
      onCloseRequest={closeModal}
      processing={false}
      secondaryButtonText={t('groups.modals.top_nav_add_group.confirmation_modal.dismiss')}
    />
  );
};
const UniversalAdd = () => {
  const { handleMenuClick, isMenuOpen } = useActiveMenuHandler({
    menuId: UNIVERSAL_ADD_MENU_ID,
  });
  const ability = useCurrentUserAbilities();
  const { setTeammateModalVisible } = useTeammateModal();
  const canInviteUser = ability.can('create', 'User');
  const pathName = fetchCurrentRoute();
  const {
    slug,
    splitFeatures: { perUserPricingEnabled },
  } = useCurrentAccount();
  const redirectToNewGroup = useMemo(() => {
    return pathName == routes.groups({ slug }).href;
  }, [pathName, slug]);

  const { showCurriculumModal } = useAppSelector((state) => state.curriculumModal);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const inviteOpen = queryParams.get('show_invite') === 'true';
    const url = new URL(window.location.href);

    if (inviteOpen) {
      canInviteUser && setTeammateModalVisible(true);
      url.searchParams.delete('show_invite');
      window.history.replaceState(null, '', url);
    }
  }, [canInviteUser, setTeammateModalVisible]);

  const PoppableMenu = (
    <Poppable
      isOpen={isMenuOpen}
      item={
        <OutsideClickHandler
          onOutsideClick={(e) => {
            if (!(e.target as Element).classList.contains('top-nav-universal-add-trigger')) {
              handleMenuClick();
            }
          }}
        >
          <UniversalAddMenuNavigation />
        </OutsideClickHandler>
      }
      onClick={() => {
        handleMenuClick();
      }}
      placement='bottom'
      trigger={<UniversalAddIcon />}
    />
  );

  return (
    <>
      {ability.can('create', 'Group') ? (
        <SearchProvider sortOptions={groupSortDropdownOptions}>
          <GroupsModalProvider redirectToNewGroup={redirectToNewGroup}>
            {PoppableMenu}
            <GroupConfirmationModalWrapper pathName={pathName} slug={slug} />
          </GroupsModalProvider>
        </SearchProvider>
      ) : (
        <>{PoppableMenu}</>
      )}

      {!showCurriculumModal && (
        <AddTeammateWithConfirmation
          showAddConfirmation
          showInviteConfirmation={!perUserPricingEnabled}
        />
      )}
    </>
  );
};

export default UniversalAdd;
