import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import NotificationUserName from '../../notifications/NotificationUserName/NotificationUserName';
import { TextFragment } from '../styles';
import { ActivityAssociationParams } from '../types';

const t = initTranslations('reports.activity_log');

export interface UserUpdateDateTemplateProps {
  activityKey: string;
  trackable: ActivityAssociationParams;
  parameters: {
    scheduled_archive_at?: string;
    scheduled_invite_at?: string;
    new_scheduled_archive_at?: string | null;
    new_scheduled_invite_at?: string | null;
  };
}

const UpdateDateTemplate = ({
  trackable,
  activityKey,
  parameters,
}: UserUpdateDateTemplateProps) => {
  const removedScheduledInvite = parameters.new_scheduled_invite_at === null;
  const removedScheduledArchive = parameters.new_scheduled_archive_at === null;
  const isRemovalOfScheduledDate = removedScheduledArchive || removedScheduledInvite;

  return (
    <>
      <TextFragment>
        {isRemovalOfScheduledDate
          ? t(`${activityKey}_removal`, {
              date: removedScheduledInvite
                ? parameters.scheduled_invite_at
                : parameters.scheduled_archive_at,
            })
          : t(activityKey)}
      </TextFragment>
      <NotificationUserName
        user={{
          is_active: trackable.is_active,
          id: trackable.id,
          name: trackable.name,
        }}
      />
      {activityKey === 'user.scheduled_invite_update' &&
        parameters.scheduled_invite_at &&
        !removedScheduledInvite && (
          <TextFragment>
            {t('user.scheduled_invite_update_date', {
              date: parameters.scheduled_invite_at,
              newDate: parameters.new_scheduled_invite_at,
            })}
          </TextFragment>
        )}
      {activityKey === 'user.scheduled_archive_update' &&
        parameters.scheduled_archive_at &&
        !removedScheduledArchive && (
          <TextFragment>
            {t('user.scheduled_archive_update_date', {
              date: parameters.scheduled_archive_at,
              newDate: parameters.new_scheduled_archive_at,
            })}
          </TextFragment>
        )}
    </>
  );
};

export default UpdateDateTemplate;
