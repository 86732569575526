import initTranslations from '../../../../../lib/initTranslations';
import AccountTerminology from '../../../../../types/AccountTerminology';

const t = initTranslations('restrict_curriculum_modal');

export type ElementType = 'course' | 'step_with_signed_topic' | 'step_with_signed_new_topic';

export const elementInPolicyText = (
  elementType: ElementType | undefined,
  accountTerminology: AccountTerminology
) => {
  const {
    curriculum: { singular: curriculumTerm },
    topic: { singular: topicTerm },
    step: { singular: stepTerm },
    policy: { plural: policyTermPlural },
  } = accountTerminology;

  switch (elementType) {
    case 'course':
      return t('topic_has_to_stay_in_policies', {
        topic: topicTerm.toLowerCase(),
        policies: policyTermPlural,
      });
    case 'step_with_signed_topic':
      return t('step_with_signed_topic_has_to_stay_in_policies', {
        topic: topicTerm.toLowerCase(),
        step: stepTerm.toLowerCase(),
        policies: policyTermPlural,
      });
    case 'step_with_signed_new_topic':
      return t('step_with_signed_new_topic_cant_be_moved', {
        topic: topicTerm.toLowerCase(),
        step: stepTerm.toLowerCase(),
      });
    default:
      return t('subject_has_to_stay_in_policies', {
        subject: curriculumTerm.toLowerCase(),
        policies: policyTermPlural,
      });
  }
};

export const removeSignatureText = (
  elementType: ElementType | undefined,
  accountTerminology: AccountTerminology
) => {
  const {
    curriculum: { singular: curriculumTerm },
    topic: { singular: topicTerm },
    step: { singular: stepTerm },
    company: { singular: companyTerm },
    process: { plural: processTermPlural },
  } = accountTerminology;

  switch (elementType) {
    case 'course':
    case 'step_with_signed_topic':
      return t('remove_topic_signature', {
        topic: topicTerm.toLowerCase(),
        company: companyTerm,
        processes: processTermPlural,
      });
    case 'step_with_signed_new_topic':
      return t('remove_new_topic_signature', {
        topic: topicTerm.toLowerCase(),
        step: stepTerm.toLowerCase(),
      });
    default:
      return t('remove_all_signatures', {
        subject: curriculumTerm.toLowerCase(),
        company: companyTerm,
        processes: processTermPlural,
      });
  }
};
