import axios from 'axios';

import axiosHeaders from '../lib/axiosHeaders';
import useAsyncData, { AsyncData } from './useAsyncData';

export enum RequestMethod {
  Delete,
  Get,
  Patch,
  Post,
  Put,
}

interface Props {
  requestMethod?: RequestMethod;
  path: string;
  // This file will be removed with the usage of redux, please do not add eslint-disable to code that is not being deprecated
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataItem: any;
  config?: object;
  useAuthenticationToken?: boolean;
}

const requestMethodToFetchPromise = <T>({
  requestMethod = RequestMethod.Post,
  path,
  dataItem,
  config = {},
  useAuthenticationToken = false,
}: Props) => {
  const headers = axiosHeaders({ useAuthentication: useAuthenticationToken });
  const params = { headers, ...config };
  switch (requestMethod) {
    case RequestMethod.Delete:
      return axios.delete<T>(path, params);
    case RequestMethod.Get:
      return axios.get<T>(path, params);
    case RequestMethod.Patch:
      return axios.patch<T>(path, dataItem, params);
    case RequestMethod.Put:
      return axios.put<T>(path, dataItem, params);
    case RequestMethod.Post:
    default:
      return axios.post<T>(path, dataItem, params);
  }
};

export default function useAjaxWrite<T>(props: Props): () => Promise<AsyncData<T>> {
  const [, setData] = useAsyncData<T>();

  const call = async function (): Promise<AsyncData<T>> {
    const fetchPromise = requestMethodToFetchPromise<T>(props);
    let result: AsyncData<T>;

    try {
      const response = await fetchPromise;
      // This file will be removed with the usage of redux, please do not add eslint-disable to code that is not being deprecated
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let { data } = response as any;
      if (data.data !== undefined) {
        // Ajax operations should return json of the format { data: ... }
        data = data.data;
      }
      result = { status: 'success', data };
    } catch (error) {
      const {
        message,
        response: {
          data: { errors, baseErrors },
          status,
        },
      } = error;
      console.log(message);
      result = {
        status: 'error',
        baseErrors: baseErrors || errors || [],
        statusCode: status,
      };
    }
    setData(result);
    return result;
  };

  return call;
}
