import React from 'react';
import { useTheme } from 'styled-components';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import AllContentImage from '../../../../images/all_content_illustration.svg';
import CompanyImage from '../../../../images/company_illustration.svg';
import PolicyImage from '../../../../images/policy_illustration.svg';
import ProcessImage from '../../../../images/process_illustration.svg';
import initTranslations from '../../../../lib/initTranslations';
import { isSector } from '../../../../lib/isSector';
import { openDocumentImportModal } from '../../../../redux/domains/documentUpload/documentUploadSlice';
import { useAppDispatch } from '../../../../redux/hooks';
import { isValidSector } from '../../../../types/Sector';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import DismissableBanner from '../../../design_system/banners/DismissableBanner/DismissableBanner';
import {
  BannerActionButtonProps,
  ContentSector,
  DismissableBannerProps,
} from '../../../design_system/banners/DismissableBanner/types';
import { routes } from '../../publicApplication/applicationRouter';
import routeTo from '../../publicApplication/routeTo';

const t = initTranslations('dismissable_banner');

type ContentSectorMap = {
  [key in ContentSector]: Pick<
    DismissableBannerProps,
    'title' | 'body' | 'image' | 'backgroundColor'
  >;
};

type SectorContentBannerContentProps = {
  contentSector: ContentSector;
};

const ContentBanner = ({ contentSector }: SectorContentBannerContentProps) => {
  const {
    slug,
    splitFeatures: { bulkDocImporterEnabled },
  } = useCurrentAccount();
  const { vars } = useTheme();

  const dispatch = useAppDispatch();
  const ability = useCurrentUserAbilities();
  const canCreateCurriculum = ability.can('create', 'Curriculum');
  const templateRoute =
    contentSector === 'all-content'
      ? routes.templates({ slug })
      : routes.templatesSearch({ slug, sectors: [contentSector] });

  const contentSectorMap: ContentSectorMap = {
    company: {
      title: t('content.company.title'),
      body: t('content.company.body'),
      image: CompanyImage,
      backgroundColor: vars.trainualBrandYellowSurfaceLight,
    },
    policy: {
      title: t('content.policy.title'),
      body: t('content.policy.body'),
      image: PolicyImage,
      backgroundColor: vars.trainualBrandMagentaSurfaceLight,
    },
    process: {
      title: t('content.process.title'),
      body: t('content.process.body'),
      image: ProcessImage,
      backgroundColor: vars.trainualBrandBlueSurfaceLight,
    },
    'all-content': {
      title: t('content.all_content.title'),
      body: canCreateCurriculum
        ? t('content.all_content.body')
        : t('content.all_content.request_access'),
      image: AllContentImage,
      backgroundColor: vars.trainualBrandPurpleSurfaceLight,
    },
  };

  const contentData = contentSectorMap[contentSector];
  const { title, body, image, backgroundColor } = contentData;

  const handleDocumentImportButtonClick = () => {
    const curriculumSector = isSector(contentSector) ? contentSector : 'process';

    if (bulkDocImporterEnabled) {
      routeTo(routes.bulkDocumentImport({ slug, sector: curriculumSector }));
    } else {
      dispatch(openDocumentImportModal('content_banner'));
    }
  };

  const primaryButtonProps: BannerActionButtonProps = canCreateCurriculum
    ? {
        id: 'import-documents-button',
        onClick: handleDocumentImportButtonClick,
        text: t('action_buttons.import_documents'),
      }
    : undefined;

  const secondaryButtonProps: BannerActionButtonProps = canCreateCurriculum
    ? {
        id: 'view-templates-button',
        onClick: () => routeTo(templateRoute),
        text: t('action_buttons.view_templates'),
      }
    : undefined;

  return (
    <>
      <DismissableBanner
        backgroundColor={backgroundColor}
        body={body}
        image={image}
        primaryButtonProps={primaryButtonProps}
        secondaryButtonProps={secondaryButtonProps}
        showCloseIcon={canCreateCurriculum}
        title={title}
        type={contentSector}
      />
    </>
  );
};

const SectorContentBannerContent = ({ contentSector }: SectorContentBannerContentProps) => {
  const ability = useCurrentUserAbilities();
  const canCreateCurriculum = ability.can('create', 'Curriculum');

  const { process, policy, company } = useAccountTerminology();

  const hasCustomTerminology =
    process.singular !== 'Process' ||
    policy.singular !== 'Policy' ||
    company.singular !== 'Company';

  if (!canCreateCurriculum && isValidSector(contentSector)) return <></>;
  if (hasCustomTerminology) return <></>;

  return <ContentBanner contentSector={contentSector} />;
};

export default SectorContentBannerContent;
