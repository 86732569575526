import React from 'react';

import { useGetQuickSearchResultCardSubtitle } from '../../../../../../hooks/search/useGetQuickSearchResultCardSubtitle';
import { useGetSearchResultCardRoute } from '../../../../../../hooks/search/useGetSearchResultCardRoute';
import { getSearchResultCardPlaceholderIcon } from '../../../../../../lib/search/getSearchResultCardPlaceholderIcon';
import Avatar from '../../../../../design_system/display/avatar';
import {
  QuickSearchResultCardEmojiWrapper,
  QuickSearchResultCardIcon,
  QuickSearchResultCardLink,
  QuickSearchResultCardSubtitle,
  QuickSearchResultCardTitle,
  QuickSearchResultCardTitleWrapper,
} from './styles';
import { QuickSearchResultCardProps } from './types';

const QuickSearchResultCard = ({
  avatar,
  cardType,
  emoji,
  parentElementName,
  hovered = false,
  groupMembersCount,
  templateAuthor,
  titleHighlighted,
  sector,
  surveyId,
  itemId,
  searchValue,
  quickSearchResultLinksRef,
}: QuickSearchResultCardProps) => {
  const isCircleEmojiWrapper = ['Group', 'User'].includes(cardType);
  const cardPlaceholderIcon = getSearchResultCardPlaceholderIcon(cardType, '2xs');
  const resourceId = surveyId || itemId;
  const subtitle = useGetQuickSearchResultCardSubtitle(
    parentElementName,
    sector,
    templateAuthor,
    groupMembersCount,
    cardType
  );
  const route = useGetSearchResultCardRoute({ cardType, itemId: resourceId, search: searchValue });

  return (
    <QuickSearchResultCardLink
      isHovered={hovered}
      linkRef={(el) => el && quickSearchResultLinksRef?.current.push(el)}
      to={route}
    >
      {avatar ? (
        <Avatar image={avatar} name='' shape='circle' size='xs' />
      ) : (
        <QuickSearchResultCardEmojiWrapper isCircle={isCircleEmojiWrapper} isHovered={hovered}>
          {emoji || cardPlaceholderIcon}
        </QuickSearchResultCardEmojiWrapper>
      )}
      <QuickSearchResultCardTitleWrapper>
        <QuickSearchResultCardTitle dangerouslySetInnerHTML={{ __html: titleHighlighted }} />
        <QuickSearchResultCardSubtitle>{subtitle}</QuickSearchResultCardSubtitle>
      </QuickSearchResultCardTitleWrapper>
      <QuickSearchResultCardIcon isHovered={hovered} name='arrow-up-right-from-square' size='2xs' />
    </QuickSearchResultCardLink>
  );
};

export default QuickSearchResultCard;
