import React, { useState } from 'react';

import useCurrentUser from '../../../../hooks/useCurrentUser';
import initTranslations from '../../../../lib/initTranslations';
import ConfirmationModal from '../../../design_system/overlays/modals/ConfirmationModal';
import { StyledDownloadCsvButton } from '../ReportTableFilter/ReportsFilterStyles';
import { Props } from './DownloadCsvReportTypes';

const DownloadCsvReport = ({ downloadCsvDocument, id, btnSize = 'lg' }: Props) => {
  const t = initTranslations('reports.download_csv');
  const [showModal, setShowModal] = useState(false);
  const { email } = useCurrentUser();

  const downloadCsv = () => {
    downloadCsvDocument();
    setShowModal(false);
  };

  return (
    <>
      <StyledDownloadCsvButton
        buttonType='primary'
        iconWeight='solid'
        id={id}
        onClick={() => setShowModal(true)}
        size={btnSize}
        text={t('download_csv')}
      />
      {showModal && (
        <ConfirmationModal
          actionFunction={downloadCsv}
          actionText={t('download')}
          heapModalName='download-csv-modal'
          message={t('message', { email })}
          onCloseRequest={() => setShowModal(false)}
          processing={false}
          title={t('title')}
        />
      )}
    </>
  );
};

export default DownloadCsvReport;
