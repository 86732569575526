export default function mergeArrayElements(array: string[], mergeElements: number) {
  return array.reduce((acc: string[], element, index) => {
    let item = `${element}, `;
    if (index + 1 === array.length) {
      item = element;
    }
    if (index % mergeElements === 0) {
      acc.push(item);
    } else {
      acc[acc.length - 1] = acc[acc.length - 1] + item;
    }
    return acc;
  }, []);
}
