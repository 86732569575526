import { IconName } from '@fortawesome/fontawesome-svg-core';
import { kebabCase } from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';

import { TooltipProps } from '../../../design_system/core/Labels/SharedLabelTypes';
import Icon from '../../../design_system/display/icons/Icon';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';
import { FontSize, FontSizeSelected, FontWeightType } from '../../people/Slideouts/Shared/FontSize';
import { Title } from './Title';
import { TitleColors, Truncate, TruncationHandler } from './TitleStyles';

const WIDTH = '20px';

const Wrapper = styled.div`
  display: flex;
  overflow: visible;
  max-width: 100%;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const IconWrapper = styled.div<{ fontSize: FontSize }>`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: ${WIDTH};
  ${FontSizeSelected};
  ${({ fontSize }) => {
    switch (fontSize) {
      case 'sm4':
        return css`
          height: 1rem;
          width: 1rem;
        `;
    }
  }}
`;

const Truncation = styled.div`
  ${TruncationHandler};
`;

const StyledSpan = styled.span`
  width: ${WIDTH};
`;

export type ClickBehavior =
  | {
      route: string;
      onClick?: never;
    }
  | {
      route?: never;
      onClick: () => void;
    }
  | {
      route?: never;
      onClick?: never;
    };

type IconType =
  | {
      iconName: IconName;
      emoji?: never;
    }
  | {
      iconName?: never;
      emoji: string | null | undefined;
    }
  | {
      iconName?: never;
      emoji?: never;
    };

export type DetailedTitleProps = {
  title: string;
  truncate?: Truncate;
  fontWeight?: FontWeightType;
  fontSize?: FontSize;
  fontColor?: TitleColors;
  id?: string;
} & ClickBehavior &
  IconType &
  TooltipProps;

// TODO: Rename to IconText
const DetailedTitle = ({
  title,
  iconName = 'file-lines',
  emoji,
  route,
  truncate = 'none',
  onClick,
  fontWeight = 'medium',
  fontSize = 'sm5',
  fontColor = 'default',
  tooltipText,
  tooltipId,
  id,
}: DetailedTitleProps) => {
  const tooltipProps = tooltipId && tooltipText;

  const titleIcon = emoji ? (
    <StyledSpan>{emoji}</StyledSpan>
  ) : (
    <Icon name={iconName} weight='regular' />
  );

  return (
    <Title
      fontColor={fontColor}
      fontSize={fontSize}
      fontWeight={fontWeight}
      id={id}
      onClick={onClick}
      route={route}
      title={
        <Wrapper>
          {tooltipProps && <Tooltip id={tooltipId} multiline text={tooltipText} />}
          <IconWrapper
            data-for={tooltipId}
            data-tip='true'
            fontSize={fontSize}
            id={`wrapper-${kebabCase(title)}`}
          >
            {titleIcon}
          </IconWrapper>
          <Truncation truncate={truncate}>{title}</Truncation>
        </Wrapper>
      }
      truncate={truncate}
    />
  );
};

export default DetailedTitle;
