import React from 'react';

import StatusChanged, { StatusChangedProps } from '../StatusChanged';
import { Activity, CurriculumElementTrackable } from '../types';
import CommonTemplate, { SurveyCommonTemplateProps } from './CommonTemplate';

interface SurveyActivitiesProps {
  'survey_survey.create': React.FC<SurveyCommonTemplateProps>;
  'survey_survey.destroy': React.FC<SurveyCommonTemplateProps>;
  'survey_survey.update': React.FC<SurveyCommonTemplateProps>;
  'survey_survey.status_changed': React.FC<StatusChangedProps>;
  'survey_survey.restore': React.FC<SurveyCommonTemplateProps>;
}

const ACTIVITY_KEYS: SurveyActivitiesProps = {
  'survey_survey.create': CommonTemplate,
  'survey_survey.destroy': CommonTemplate,
  'survey_survey.update': CommonTemplate,
  'survey_survey.restore': CommonTemplate,
  'survey_survey.status_changed': StatusChanged,
};

interface SurveysActivity extends Omit<Activity, 'trackable' | 'parameters'> {
  trackable: CurriculumElementTrackable;
  parameters: {
    curriculum_id: number;
    curriculum_title: string;
    status_was: string;
    status_now: string;
  };
}

interface SurveyCardProps {
  activity: SurveysActivity;
}

const SurveyActivitiesDetails = ({ activity }: SurveyCardProps) => {
  const ActivityMessage = ACTIVITY_KEYS[activity.key as keyof SurveyActivitiesProps];

  return (
    <ActivityMessage
      activityKey={activity.key}
      parameters={activity.parameters}
      trackable={activity.trackable}
    />
  );
};

export default SurveyActivitiesDetails;
