import useUserPermissionSettings from '../../../../hooks/users/useUserPermissionSettings';
import initTranslations from '../../../../lib/initTranslations';
import { PermissionKeys } from '../../../../types/Permission';
import { Option } from '../../../design_system/core/SelectOption/types';

const t = initTranslations('teammate_modal');

const FormatPermissionOptions = (
  defaultPermissionIndex: number,
  isBuildPlan: boolean,
  subjectTermSingular: string
): Option[] => {
  const { permissionSettings } = useUserPermissionSettings();
  return Object.keys(permissionSettings)
    .filter((_permission, index) => index >= defaultPermissionIndex)
    .map((key: PermissionKeys) => {
      return {
        disabled: isBuildPlan ? permissionSettings[key].restrictedOnBuildPlan : false,
        label: t(`shared.permissions.${key}.title`),
        metaValue: `${t(`shared.permissions.${key}.description`, {
          subject: subjectTermSingular.toLowerCase(),
        })}${isBuildPlan ? t(`shared.permissions.only_available_on`) : ''}`,
        searchableTerm: t(`shared.permissions.${key}.title`).toLowerCase(),
        value: key,
      };
    });
};

export default FormatPermissionOptions;
