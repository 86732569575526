import { TaskDrivenOnboardingTaskName } from '../../types/TaskDrivenOnboarding';

export const TASK_DRIVEN_ONBOARDING_STATE_KEY = 'task_driven_onboarding_state';

type ParsedOnboardingState =
  | Partial<Record<TaskDrivenOnboardingTaskName, boolean>>
  | Record<string, never>;

export function getSavedOnboardingState() {
  const savedOnboardingState = localStorage.getItem(TASK_DRIVEN_ONBOARDING_STATE_KEY);
  const parsedSavedOnboardingState: ParsedOnboardingState =
    savedOnboardingState && JSON.parse(savedOnboardingState);
  return parsedSavedOnboardingState || {};
}
