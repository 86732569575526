import { GroupWithAssociations } from '../../../../redux/services/resourceApis/groups/types';
import { ChatMessage } from '../../../../redux/services/resourceApis/openAI/types';

const DESCRIPTION_PROMPT_MESSAGES: ChatMessage[] = [
  {
    role: 'system',
    content:
      'You are a helpful AI writing assistant that writes internal descriptions for teams, departments, roles and locations, referred to as group types.',
  },
  {
    role: 'user',
    content:
      'Given an internal group name and group type, write a description for the group. Maximum should be about 50 words in a single paragraph.' +
      'This description is displayed on the page for that group type, as such it should be written for people to learn about that type.' +
      'Do not direct this to any specific person, but rather to a general audience.',
  },
  {
    role: 'assistant',
    content: 'What is the name of the group?',
  },
];

export const getGroupPromptMessages = (group: GroupWithAssociations): ChatMessage[] => {
  return [
    ...DESCRIPTION_PROMPT_MESSAGES,
    { role: 'user', content: `The group name is ${group.name}` },
    {
      role: 'assistant',
      content: 'What is the group type?',
    },
    { role: 'user', content: `The group type is ${group.kind}` },
  ];
};
