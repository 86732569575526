import { isEqual } from 'lodash';
import { darken } from 'polished';
import React, { useCallback, useMemo } from 'react';
import { ActionMeta } from 'react-select';
import { SaguaroTheme } from 'saguaro';

import CoreSelectField, { Props } from '../../../core/CoreSelectField/CoreSelectField';
import {
  DisabledFieldTooltipProps,
  LabelTooltipProps,
} from '../../../core/Labels/SharedLabelTypes';
import { Option } from '../../../core/SelectOption/types';

export interface OptionProp {
  isSelected: boolean;
  isFocused: boolean;
  selectProps: SaguaroTheme;
}

export interface SelectProp {
  selectProps: SaguaroTheme;
  isFocused: boolean;
  data: { isFixed: boolean; isInvalid: boolean };
}

export const MultiSelectStyles = {
  option: (provided: object, { isSelected, isFocused, selectProps }: OptionProp) => {
    const {
      vars: { accentPrimaryDefault, foundationBase2, foundationSurface1 },
    } = selectProps;

    return {
      ...provided,
      backgroundColor: isSelected || isFocused ? foundationBase2 : foundationSurface1,
      color: isSelected || isFocused ? accentPrimaryDefault : 'initial',
    };
  },

  valueContainer: (provided: object, { selectProps: { constants } }: SelectProp) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: constants.spacerMd2,
    paddingRight: 0,
  }),

  multiValue: (
    provided: object,
    { data: { isFixed }, selectProps: { constants, vars } }: SelectProp
  ) => {
    return isFixed
      ? { ...provided, backgroundColor: 'gray', marginLeft: 0, marginRight: constants.spacerSm2 }
      : {
          ...provided,
          backgroundColor: vars.accentPrimaryDefault,
          color: vars.textSurface,
          marginLeft: 0,
          marginRight: constants.spacerSm2,
        };
  },

  multiValueLabel: (provided: object, { data: { isFixed }, selectProps: { vars } }: SelectProp) => {
    return isFixed
      ? { ...provided, color: vars.textSurface, paddingRight: 6 }
      : {
          ...provided,
          cursor: 'pointer',
          color: vars.textSurface,
        };
  },

  multiValueRemove: (
    provided: object,
    { isFocused, data: { isFixed }, selectProps: { vars } }: SelectProp
  ) => {
    return isFixed
      ? { ...provided, display: 'none' }
      : {
          ...provided,
          background: isFocused
            ? darken(0.1, vars.accentPrimaryDefault || vars.trainualBrandPurpleMedium)
            : vars.accentPrimaryDefault,
          cursor: 'pointer',
          ':hover': {
            backgroundColor: darken(
              0.1,
              vars.accentPrimaryDefault || vars.trainualBrandPurpleMedium
            ),
            color: vars.textSurface,
          },
        };
  },
};

export type MultiSelectProps = Props & {
  defaultValue: string[] | null;
  value: string[] | null;
  onNonDefaultSelected?: (value: string[] | null) => void;
};

const MultiSelectField = (props: MultiSelectProps) => {
  const {
    closeMenuOnSelect = false,
    defaultValue: defaultValues,
    value: values,
    placeholder,
    onNonDefaultSelected,
    onDefaultSelected,
  } = props;

  const options = props.useSourceBadge ? props.sourceBadgeOptions : props.options;

  const getDefaultOptions = useCallback(
    (options: Option[]) => {
      if (defaultValues) {
        const defaultOptions = options.filter((option) => defaultValues.includes(option.value));
        return defaultOptions.length > 0 ? defaultOptions : placeholder;
      } else {
        return placeholder || options[0];
      }
    },
    [defaultValues, placeholder]
  );

  const optionFromValue = useMemo(() => {
    if (values) {
      return options.filter((option) => values.includes(option.value));
    }
  }, [options, values]);

  const handleSelectChanged = useCallback(
    (options: Option[] | null, actionMeta: ActionMeta<Option>) => {
      let fixedOptions: string[] = [];

      if (!options || options?.length === 0) {
        switch (actionMeta.action) {
          case 'pop-value':
            if (actionMeta.removedValue === undefined || actionMeta.removedValue.isFixed) {
              return;
            }
            break;
          case 'clear':
            fixedOptions = actionMeta.removedValues
              ?.filter((option) => option.isFixed)
              .map((option) => option.value);
            break;
        }

        onNonDefaultSelected && onNonDefaultSelected(fixedOptions || []);
        return;
      }

      const values = options && options.map((option) => option.value);
      const isDefaultValues = isEqual(values, defaultValues);

      if (isDefaultValues) {
        onDefaultSelected && onDefaultSelected();
      } else {
        onNonDefaultSelected && onNonDefaultSelected(values);
      }
    },
    [defaultValues, onDefaultSelected, onNonDefaultSelected]
  );

  const {
    labelTooltipId,
    labelTooltipText,
    disabledFieldTooltipId,
    disabledFieldTooltipText,
    ...propsToPass
  } = props;
  const labelTooltipProps: LabelTooltipProps =
    labelTooltipId && labelTooltipText ? { labelTooltipId, labelTooltipText } : {};
  const fieldTooltipProps: DisabledFieldTooltipProps =
    disabledFieldTooltipId && disabledFieldTooltipText
      ? { disabledFieldTooltipId, disabledFieldTooltipText }
      : {};

  return (
    <CoreSelectField
      {...propsToPass}
      {...labelTooltipProps}
      {...fieldTooltipProps}
      closeMenuOnSelect={closeMenuOnSelect}
      componentStyles={MultiSelectStyles}
      getDefaultOption={getDefaultOptions}
      handleSelectChanged={handleSelectChanged}
      isMulti
      tabSelectsValue={false}
      value={optionFromValue}
    />
  );
};

export default MultiSelectField;
