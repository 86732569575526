import capitalize from '../../lib/capitalize';
import initTranslations from '../../lib/initTranslations';
import {
  BillingPlanName,
  SimplePricingBillingPlanName,
  simplePricingBillingPlanTypes,
  simplePricingV3BillingPlanTypes,
} from '../../types/BillingPlanName';
import useCurrentAccount from '../useCurrentAccount';

const t = initTranslations('simple_pricing.billing_page.plans.card.title');

type PlanTitleProps = {
  name: BillingPlanName;
};

export const usePlanTitle = ({ name }: PlanTitleProps) => {
  const {
    splitFeatures: { simplePricingV3Enabled },
  } = useCurrentAccount();

  const planTypes = simplePricingV3Enabled
    ? simplePricingV3BillingPlanTypes
    : simplePricingBillingPlanTypes;

  const nameFromTranslations = [...planTypes, 'hold'].includes(
    name as SimplePricingBillingPlanName
  );

  return nameFromTranslations ? t(name) : capitalize(name);
};
