import React from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import { ColumnComponentProps, IndividualUsersReportColumn } from '../types';
import ESignature from './ESignature';
import Score from './Score';
import SignatureStatus from './SignatureStatus';
import Status from './Status';
import Title from './Title';

type TableColumnsProps = {
  [key in Exclude<IndividualUsersReportColumn, 'actions'>]: React.FC<ColumnComponentProps>;
};

const TableColumn = ({ columnName, record, userId }: ColumnComponentProps) => {
  const { hasESignatureFeature } = useCurrentAccount();

  const ESignatureInfo = hasESignatureFeature ? ESignature : SignatureStatus;

  const COLUMNS_KEYS: TableColumnsProps = {
    title: Title,
    status: Status,
    e_signature: ESignatureInfo,
    score: Score,
  };

  const Column = COLUMNS_KEYS[columnName as keyof TableColumnsProps];

  return <Column columnName={columnName} record={record} userId={userId} />;
};

export default TableColumn;
