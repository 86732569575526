import React, { useCallback, useEffect } from 'react';

import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../lib/initTranslations';
import { messageFromError } from '../../../../redux/errors/helpers';
import { useAutogenerateRoleChartMutation } from '../../../../redux/services/resourceApis/openAI/openAiAPI';
import { useFlashNotification } from '../../../FlashNotificationContext';
import { ComposeModalProps } from '../shared/modals/shared';

const t = initTranslations(`org_chart.group_config_slideout.header.ai`);

const GenerateRoleChart = ({
  closeModal,
  setComposeModalLoading,
  setComposeModalDisabled,
}: ComposeModalProps) => {
  const { flash } = useFlashNotification();
  const [autogenerateRoleChart, result] = useAutogenerateRoleChartMutation();
  const { isLoading, error } = result;

  useEffect(() => {
    autogenerateRoleChart();
  }, [autogenerateRoleChart]);

  const successFunction = useCallback(() => {
    closeModal();
    flash('info', t('generate_success'));
    // As component unmounts, closeModal was changing and causing flash
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flash]);

  const errorFunction = useCallback(() => {
    closeModal();
    flash('error', messageFromError(error)?.join(', '));
    // As component unmounts, closeModal was changing and causing flash
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, flash]);

  useEffect(() => {
    setComposeModalLoading(isLoading);
    setComposeModalDisabled(isLoading);
  }, [isLoading, setComposeModalLoading, setComposeModalDisabled]);

  useDisplayFlashOnResponse({
    result,
    successFunction,
    errorFunction,
  });

  return <></>;
};

export default GenerateRoleChart;
