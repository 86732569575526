import React, { ReactNode } from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { ESignatureStatus } from '../../../../../../types/ESignatureStatus';
import { ColumnComponentProps } from '../types';
import { SignatureStatusWrapper, StyledStatusIcon } from './styles';

const t = initTranslations('reports.individual_user_report.table.columns.signature');

const SignatureStatus = ({ record }: ColumnComponentProps) => {
  const { e_signature } = record;
  const { signed, all, status } = e_signature;

  const STATUSES_KEYS: { [key in ESignatureStatus]: ReactNode } = {
    empty: '',
    in_progress: t(status, { all, signed }),
    signed: (
      <>
        <StyledStatusIcon name='circle-check' size='sm' weight='regular' />
        {t(status)}
      </>
    ),
    not_signed: (
      <>
        <StyledStatusIcon name='circle-xmark' size='sm' weight='regular' />
        {t(status)}
      </>
    ),
  };

  return <SignatureStatusWrapper>{STATUSES_KEYS[status]}</SignatureStatusWrapper>;
};

export default SignatureStatus;
