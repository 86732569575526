import { kebabCase } from 'lodash';
import React from 'react';

import { getCardIcon } from '../../../../../lib/getCardIcon';
import initTranslations from '../../../../../lib/initTranslations';
import { useDispatchSetShowModal } from '../../../../../redux/domains/modalsSlice/modalsSlice';
import { NullableBillingDetails } from '../../../../../redux/services/resourceApis/billing/types';
import { BillingCardDetails } from '../../../../../types/BillingCardDetails';
import P from '../../../../design_system/text/P';
import {
  CardDetailsWrapper,
  CardIcon,
  CardIconWrapper,
  Description,
  StyledIconButton,
} from './styles';

const t = initTranslations('per_user_pricing.card_details');

type CardDetailsSectionProps = {
  cardDetails: NullableBillingDetails<BillingCardDetails>;
};

const CardDetails = ({ cardDetails }: CardDetailsSectionProps) => {
  const { brand, last4, expMonth, expYear } = cardDetails;
  const cardIcon = brand ? getCardIcon({ cardBrand: brand }) : undefined;
  const expiredDate = `${expMonth}/${expYear}`;
  const dispatchShowModal = useDispatchSetShowModal();

  return (
    <>
      <div id='billing-page-information-card-details'>
        {brand ? (
          <CardDetailsWrapper>
            <CardIconWrapper id={`${kebabCase(brand)}`}>
              <CardIcon src={cardIcon} />
            </CardIconWrapper>
            <div>
              <P text={t('ending_in', { last4 })} />
              <Description text={t('expired_date', { date: expiredDate })} />
            </div>
          </CardDetailsWrapper>
        ) : (
          <P text={t('no_payment_method')} />
        )}
      </div>
      <StyledIconButton
        ariaLabel={t('aria_label_edit')}
        buttonSize='sm'
        id='update-payment-method-button'
        name='pencil'
        onClick={() => dispatchShowModal('updatePaymentMethod', true)}
      />
    </>
  );
};

export default CardDetails;
