import React from 'react';
import styled from 'styled-components';

import Icon from '../../../../../design_system/display/icons/Icon';
import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';

export const StyledIcon: React.ElementType = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

export const RestoreIcon: React.ElementType = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const RestoreButtonInner: React.ElementType = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTooltip: React.ElementType = styled(Tooltip)`
  max-width: 12rem;
`;
