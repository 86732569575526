import React, { useCallback } from 'react';

import { useAppSelector } from '../../../../redux/hooks';
import { reportsApi } from '../../../../redux/services/resourceApis/reports/reportsApi';
import DownloadCsvReport from '../DownloadCsvReport/DownloadCsvReport';

const LatestActivityDownloadCsvReport = () => {
  const { userIds, curriculumIds, activityType } = useAppSelector(
    (state) => state.reports.activityLog.filters
  );
  const [getCsv] = reportsApi.useLazyGetActivityLogCsvQuery();

  const downloadCsvDocument = useCallback(() => {
    getCsv({ userIds, curriculumIds, activityType });
  }, [activityType, curriculumIds, getCsv, userIds]);

  return (
    <DownloadCsvReport
      btnSize='md'
      downloadCsvDocument={downloadCsvDocument}
      id='latest-activity-button-download-csv'
    />
  );
};

export default LatestActivityDownloadCsvReport;
