import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useAccountTerminology } from '../../../../../components/AccountTerminologyProvider';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import {
  NotificationsDate,
  NotificationsGroupWrapper,
} from '../../../notifications/NotificationsIndexView/shared/styles';
import { routes as routesReact } from '../../../publicApplication/applicationRouter';
import RouterLink from '../../../publicApplication/RouterLink';
import ActivityCard from '../ActivityCard/ActivityCard';
import { EmptyActivityCardsListText, LinkWrapper, Title } from '../styles';
import { Activity, ActivityCardsListProps } from '../types';

const NotificationsWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const t = initTranslations('user_profile.latest_activities');

const ActivityCardsList = ({ activityGroups, title, seeAll, userId }: ActivityCardsListProps) => {
  const groups = useMemo(() => Object.entries(activityGroups), [activityGroups]);
  const { slug } = useCurrentAccount();
  const { productTerm } = useAccountTerminology();

  return (
    <div id='latest-activities'>
      {title && <Title>{title}</Title>}
      {groups.length ? (
        <NotificationsWrapper>
          {groups.map(([groupKey, value]) => (
            <NotificationsGroupWrapper
              className='group-of-activities'
              key={`activities-on-${groupKey}`}
            >
              <NotificationsDate>{groupKey}</NotificationsDate>
              {value.map((activity: Activity) => (
                <ActivityCard
                  activity={activity}
                  key={`activity-${activity.id || activity.trackable_uuid}`}
                  productTerm={productTerm}
                />
              ))}
            </NotificationsGroupWrapper>
          ))}
          {seeAll && (
            <LinkWrapper>
              <RouterLink to={routesReact.latestActivityReport({ slug, filter_user_id: userId })}>
                {seeAll}
              </RouterLink>
            </LinkWrapper>
          )}
        </NotificationsWrapper>
      ) : (
        <EmptyActivityCardsListText>{t('no_activity_yet')}</EmptyActivityCardsListText>
      )}
    </div>
  );
};

export default ActivityCardsList;
