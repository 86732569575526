import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { routes } from '../../../publicApplication/applicationRouter';
import NotificationUserName from '../../notifications/NotificationUserName/NotificationUserName';
import { TextFragment } from '../styles';
import { ActivityAssociationParams, AssociatedCurriculumParams } from '../types';

const t = initTranslations('reports.activity_log');

export interface RequestResponseTemplateProps {
  activityKey: string;
  recipient: ActivityAssociationParams;
  trackable: { associated_params: AssociatedCurriculumParams };
}

const RequestResponseTemplate = ({
  activityKey,
  trackable,
  recipient,
}: RequestResponseTemplateProps) => {
  const { slug } = useCurrentAccount();
  const { curriculum_id, curriculum_title, is_active_curriculum } = trackable.associated_params;

  return (
    <>
      <TextFragment>{t(activityKey)}</TextFragment>
      <NotificationUserName
        user={{
          is_active: recipient.is_active,
          id: recipient.id,
          name: recipient.name,
        }}
      />
      <TextFragment>{t('request.request_to_access')}</TextFragment>
      <SourceBadge
        readOnly={!is_active_curriculum}
        sourceName='curriculum'
        sourceRoute={routes.curriculumEdit({ slug, id: curriculum_id })}
        sourceText={curriculum_title}
      />
    </>
  );
};

export default RequestResponseTemplate;
