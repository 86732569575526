import {
  collapseSidebar,
  expandSidebar,
} from '../redux/domains/collapsibleSidebar/collapsibleSidebarSlice';
import { SlideoutArgs, closeSlideout, openSlideout } from '../redux/domains/slideOut/slideoutSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import useWindowResize from './useWindowResize';

type SlideControl = {
  isOpen: boolean;
  open: (args?: SlideoutArgs) => void;
  close: () => void;
  args: SlideoutArgs | undefined;
};

export default (type: string): SlideControl => {
  const dispatch = useAppDispatch();
  const { isDesktopXl } = useWindowResize();
  const currentSlideout = useAppSelector((state) => state?.slideout?.currentSlideout);
  const isExpandedByCollapseButton = useAppSelector(
    (state) => state?.collapsibleSidebar?.isExpandedByCollapseButton
  );

  return {
    isOpen: type === currentSlideout?.type,
    open: (args?) => {
      dispatch(collapseSidebar());
      dispatch(openSlideout({ type, args }));
    },
    close: () => {
      dispatch(closeSlideout());
      if (isDesktopXl) {
        isExpandedByCollapseButton === true && dispatch(collapseSidebar());
        isExpandedByCollapseButton === false && dispatch(expandSidebar());
      }
    },
    args: currentSlideout?.args,
  };
};
