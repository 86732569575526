import { GroupKindValue } from '../components/application/ContentLibrary/ContentLibraryPage/libraryReducer';
import { Option } from '../components/design_system/core/SelectOption/types';
import initTranslations from '../lib/initTranslations';
import sortArray, { SortOrder } from '../lib/sortArray';
import { DetailedCurriculum } from './Curriculum';
import { Responsibility } from './Responsibility';
import { User } from './User';

export type Group = {
  id: number;
  name: string;
  description: string | null;
  kind: GroupKind;
  everyone: boolean;
  __typename: string;
};

export type DetailedGroup = Group & {
  users: User[];
  responsibilities: Responsibility[];
  subjects: DetailedCurriculum[];
};

export type SimpleGroup = Pick<Group, 'id' | 'name' | 'kind'>;

export const groupKinds = [
  GroupKindValue.Role,
  GroupKindValue.Team,
  GroupKindValue.Department,
  GroupKindValue.Location,
  GroupKindValue.Other,
] as const;

export type GroupKind = (typeof groupKinds)[number];

export type GroupKindWithAll = 'all' | GroupKind;

export const groupsToOptions = (groups: Group[]) => {
  const groupOptions: Option[] = groups.map(({ id, name, kind }) => {
    return {
      value: `${id}`,
      label: `${name} (${initTranslations('groups.kinds')(kind)})`,
      searchableTerm: name,
      sourceName: 'group',
      avatarName: name,
      sourceSubtext: `(${initTranslations('groups.kinds')(kind)})`,
    };
  });

  return sortArray({ array: groupOptions, sortKey: 'searchableTerm', sortOrder: SortOrder.asc });
};
