import React, { Dispatch, ReactNode, SetStateAction, createContext, useContext } from 'react';

import useSuperShareState, { SuperShareState } from '../hooks/useSuperShareState';
import { CurriculumWithAssignmentsResponse } from '../redux/services/resourceApis/curriculumAssignments/types';
import { GroupResponse } from '../redux/services/resourceApis/groups/types';
import { User } from '../types/User';

type Props = {
  curriculumWithAssignments: CurriculumWithAssignmentsResponse;
  completionRequired: boolean;
  users: User[];
  groups: GroupResponse[];
  children: ReactNode;
  setShowSuperShareModal: Dispatch<SetStateAction<boolean>>;
};

const SuperShareContext = createContext({} as SuperShareState);
export const useSuperShare = () => useContext(SuperShareContext);

export const SuperShareProvider = ({ children, ...propsToPass }: Props) => {
  const state = useSuperShareState(propsToPass);
  return <SuperShareContext.Provider value={{ ...state }}>{children}</SuperShareContext.Provider>;
};
