import createConsumer, { Consumer } from '../../channels/consumer';

let actionCable: typeof Consumer | null = null;

export const initializeGlobalActionCable = (slug: string) => {
  if (!actionCable) {
    actionCable = createConsumer(`/${slug}/cable`);
  }
};

export const getActionCable = () => {
  if (!actionCable && !process.env.STORYBOOK_ENV) {
    throw new Error('Global actionCable not initialized');
  }

  return actionCable;
};
