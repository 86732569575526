import styled from 'styled-components';

import Icon from '../../../../design_system/display/icons/Icon';
import { fontSm4, fontSm5 } from '../../../../styled/TypeSystem';

export const ReferralCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TermsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};

  ${fontSm4};
`;

export const TermsClickable = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};

  ${fontSm5};
`;

export const TermsBody = styled.div`
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const StyledIcon = styled(Icon)`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
`;
