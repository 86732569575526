import React, { useContext } from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { AccountTerminologyContext } from '../../../../AccountTerminologyProvider';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import { DefaultHeaderStyled } from '../../../people/UsersOutline/Table/Styles';
import { StyledHeaderCell } from '../../AllUsersReportPage/AllUsersReportTable/styles';
import { StyledSortIcon } from '../../Styles';
import { TableHeaderCellProps } from './types';

const AllCurriculumsReportTableHeaderCell = ({
  column: { columnName, stickyPosition = 'center', contentAlignment = 'start' },
  activeHeader,
  setActiveHeader,
  sortIcon,
  isSortable,
  showTooltip = false,
}: TableHeaderCellProps) => {
  const t = initTranslations('reports.curriculums_report_table');
  const { curriculum } = useContext(AccountTerminologyContext);
  const tooltipOptions = {
    'data-for': `curriculums-report-tooltip_${columnName}`,
    'data-tip': true,
  };

  return (
    <DefaultHeaderStyled>
      <StyledHeaderCell
        active={activeHeader}
        className={isSortable ? `sortable-column ${columnName}` : undefined}
        contentAlignment={contentAlignment}
        {...(showTooltip ? tooltipOptions : {})}
        isSortable={isSortable}
        onClick={() => setActiveHeader && setActiveHeader()}
        stickyPosition={stickyPosition}
      >
        {columnName === 'title' ? curriculum.singular : t(`headers.${columnName}`)}
        {sortIcon && <StyledSortIcon name={sortIcon} size='2xs' weight='solid' />}
        {showTooltip && (
          <Tooltip
            id={`curriculums-report-tooltip_${columnName}`}
            text={t(`headers.${columnName}_tooltip`, {
              curriculum: curriculum.singular.toLowerCase(),
            })}
          ></Tooltip>
        )}
      </StyledHeaderCell>
    </DefaultHeaderStyled>
  );
};

export default AllCurriculumsReportTableHeaderCell;
