import styled from 'styled-components';

import Icon from '../../../design_system/display/icons/Icon';

export const ESignatureStatusInfo = styled.div<{ isSigned: boolean; isHovered?: boolean }>`
  display: flex;
  align-items: center;
  min-width: 10rem;
  color: ${({ isSigned, isHovered, theme: { vars } }) =>
    isHovered ? vars.accentPrimaryHover : isSigned ? vars.textDefault : vars.textDisabled};
`;

export const StyledStatusIcon = styled(Icon)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
`;
