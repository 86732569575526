import React from 'react';

import PageContent from '../../shared/page_content';
import IndividualUserReportFilters from './IndividualUserReportFilters/IndividualUserReportFilters';
import IndividualUserReportTable from './IndividualUserReportTable/IndividualUserReportTable';
import { IndividualUserReportProps } from './IndividualUserReportTable/types';
import ReportHeadCard from './ReportHeadCard/ReportHeadCard';

const IndividualUserReportPage = ({
  route: {
    params: { id, breadcrumb, breadcrumbText },
  },
}: IndividualUserReportProps) => {
  return (
    <PageContent>
      <ReportHeadCard breadcrumb={breadcrumb} breadcrumbText={breadcrumbText} id={id} />
      <IndividualUserReportTable userId={id} />
      <IndividualUserReportFilters userId={id} />
    </PageContent>
  );
};

export default IndividualUserReportPage;
