import React from 'react';

import { useAccountTerminology } from '../../../../components/AccountTerminologyProvider';
import usePageTitle from '../../../../hooks/usePageTitle';
import initTranslations from '../../../../lib/initTranslations';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import PageContent from '../../shared/page_content';
import { FiltersWrapper, TabsWrapper } from '../Styles';
import ActivityLogFilter from './ActivityLogFilter';
import ActivityLogTab from './ActivityLogTab';
import ActivityLogTimeFilter from './ActivityLogTimeFilter';
import LatestActivityDownloadCsvReport from './LatestActivityDownloadCsvReport';

const t = initTranslations('navigation.header');

const ActivityLogPage = () => {
  const { productTerm } = useAccountTerminology();
  usePageTitle({ resourceType: 'latest_activity_report', productTerm });

  return (
    <PageContent>
      <PageHeader subtitle={t('reports_subtitle')} title={t('activity_log_title')} />
      <TabsWrapper>
        <FiltersWrapper>
          <ActivityLogTimeFilter />
          <ActivityLogFilter />
        </FiltersWrapper>
        <LatestActivityDownloadCsvReport />
      </TabsWrapper>
      <ActivityLogTab />
    </PageContent>
  );
};

export default ActivityLogPage;
