import React from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import { ESignatureSubscriptionInfo } from '../../../../../../../redux/services/resourceApis/billing/types';
import Tooltip from '../../../../../../design_system/display/Tooltip/Tooltip';
import { TooltipIconWrapper, TooltipStyledIcon } from '../../../../per_user_pricing/shared/styles';
import ESignatureTooltipBody from './ESignatureTooltipBody/ESignatureTooltipBody';
import { ESignatureText, ESignatureWrapper } from './styles';

const t = initTranslations('per_user_pricing.billing_page.plan_card.e_signature');

type ESignatureTooltipProps = {
  eSignatureSubscriptionInfo: ESignatureSubscriptionInfo;
};

const ESignatureSubtitle = ({ eSignatureSubscriptionInfo }: ESignatureTooltipProps) => {
  return (
    <ESignatureWrapper>
      <ESignatureText text={t('title')} />
      <TooltipIconWrapper data-for='e-signature-tooltip' data-tip>
        <TooltipStyledIcon name='circle-info' weight='regular' />
      </TooltipIconWrapper>
      <Tooltip
        content={<ESignatureTooltipBody eSignatureSubscriptionInfo={eSignatureSubscriptionInfo} />}
        id='e-signature-tooltip'
        place='bottom'
      />
    </ESignatureWrapper>
  );
};

export default ESignatureSubtitle;
