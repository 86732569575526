import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import { useIsAdmin } from '../../../../../hooks/users/useIsPermission';
import initTranslations from '../../../../../lib/initTranslations';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { routes as applicationRouterRoutes } from '../../../publicApplication/applicationRouter';
import { TextFragment } from '../styles';
import { ActivityAssociationParams } from '../types';
import { groupTabFromActivity } from './shared/groupTabFromActivity';

const t = initTranslations('reports.activity_log');

export interface GroupCommonTemplateProps {
  activityKey: string;
  recipientType?: string;
  trackable: ActivityAssociationParams;
  parameters: { recipients_count?: number; name_was: string; new_name: string };
  recipient?: object;
}

const CommonTemplate = ({ activityKey, trackable, parameters }: GroupCommonTemplateProps) => {
  const { slug } = useCurrentAccount();
  const groupHref = applicationRouterRoutes.group({
    slug,
    id: trackable.id,
    breadcrumb: applicationRouterRoutes.groups({ slug }),
    tab: groupTabFromActivity(activityKey),
  });
  const isAdmin = useIsAdmin();

  return (
    <>
      <TextFragment>
        {activityKey == 'group.name_changed' ? (
          <span
            dangerouslySetInnerHTML={{
              __html: t('group.name_changed_group', { name: parameters.name_was }),
            }}
          />
        ) : (
          t(`${activityKey}_group`)
        )}
      </TextFragment>
      <SourceBadge
        readOnly={!(trackable.is_active && isAdmin)}
        sourceName='group'
        sourceRoute={groupHref}
        sourceText={parameters?.new_name || trackable.name}
      />
    </>
  );
};

export default CommonTemplate;
