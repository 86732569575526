import styled from 'styled-components';

import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import { mediaBreakpointPxMd } from '../../../../../styled/Breakpoint';
import { fontLg1, fontSm5 } from '../../../../../styled/TypeSystem';
import { StyledP } from '../styles';

export const BillingPageReferAFriendWrapper = styled.div`
  display: flex;
  background: ${({ theme: { vars } }) => vars.trainualBrandPurpleMedium};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  flex-direction: column;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerLg1};

  @media (min-width: ${mediaBreakpointPxMd}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const BillingPageReferContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd3} ${constants.spacerLg1}`};
`;

export const BillingPageReferAFriendTitle = styled.div`
  color: ${({ theme: { vars } }) => vars.textSurface};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontLg1};
`;

export const BillingPageReferAFriendBody = styled.div`
  color: ${({ theme: { vars } }) => vars.textSurface};
  padding: ${({ theme: { constants } }) => constants.spacerSm3} 0;
  max-width: 30rem;
  ${fontSm5};
`;

export const StyledTrialingText = styled(StyledP)`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

// DS Override - to match design
export const StyledButton = styled(DefaultButton)`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  max-width: 11rem;
  padding: ${({ theme: { constants } }) => constants.spacerMd3};
`;
