import React from 'react';

import { Activity, SurveyAttemptTrackable } from '../types';
import ResultTemplate, { SurveyAttemptResultTemplateProps } from './ResultTemplate';

interface SurveyAttemptActivitiesProps {
  'survey_attempt.pass': React.FC<SurveyAttemptResultTemplateProps>;
  'survey_attempt.fail': React.FC<SurveyAttemptResultTemplateProps>;
}

const ACTIVITY_KEYS: SurveyAttemptActivitiesProps = {
  'survey_attempt.pass': ResultTemplate,
  'survey_attempt.fail': ResultTemplate,
};

interface SurveyAttemptsActivity extends Omit<Activity, 'trackable'> {
  trackable: SurveyAttemptTrackable;
}

interface SurveyAttemptCardProps {
  activity: SurveyAttemptsActivity;
}

const SurveyAttemptActivitiesDetails = ({ activity }: SurveyAttemptCardProps) => {
  const ActivityMessage = ACTIVITY_KEYS[activity.key as keyof SurveyAttemptActivitiesProps];

  return <ActivityMessage activityKey={activity.key} trackable={activity.trackable} />;
};

export default SurveyAttemptActivitiesDetails;
