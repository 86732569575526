import { IconName } from '@fortawesome/fontawesome-svg-core';
import React, { useContext, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { PaywallContext } from '../../../../contexts/PaywallContext';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import { useRedirectToBillingOverlay } from '../../../../hooks/useRedirectToBillingOverlay';
import useWindowResize from '../../../../hooks/useWindowResize';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import { ButtonSize } from '../../../design_system/buttons/DefaultButton/ButtonTypes';
import PaywallTooltip from '../../shared/tooltips/PaywallTooltip';

const StyledButton = styled(DefaultButton)<{ opacity?: string }>`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  svg {
    color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  }

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
    color: ${({ theme: { vars } }) => vars.textSurface};
    border-color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
    box-shadow: none;

    svg {
      color: ${(props) =>
        props.disabled ? props.theme.vars.accentPrimaryDefault : props.theme.vars.textSurface};
    }
  }

  font-weight: normal;
  letter-spacing: 0.025rem;
  white-space: nowrap;
`;

const StyledDisabledButton = styled(StyledButton)`
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};

  div {
    margin-right: 5px;
  }

  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
    background-color: #e3e3e3 !important;
    border-color: #c6c6c6 !important;
    color: #aaaaaa !important;

    svg {
      color: #aaaaaa !important;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface Props {
  description: string;
  iconName: IconName;
  link: string;
  title: string;
  size?: ButtonSize;
}

const PaywallButton = ({ description, title, size = 'sm' }: Props) => {
  const theme = useTheme();
  const [buttonHovered, setButtonHovered] = useState(false);
  const [btnIcon, setBtnIcon] = useState<'lock' | 'unlock' | 'share-alt'>('lock');
  const paywallCtx = useContext(PaywallContext);
  const navigateToBillingOverlay = useRedirectToBillingOverlay();
  const hasPublicSharePaywalled = paywallCtx.includes('public_curriculums');
  const { isMobile } = useWindowResize();
  const ability = useCurrentUserAbilities();
  const canUpdateBillingPlan = ability.can('update', 'BillingPlan');

  const paywallClicked = () => {
    navigateToBillingOverlay({ plan: 'scale' });
    setButtonHovered(false);
  };

  const mouseEnter = () => {
    setButtonHovered(true);
    setBtnIcon('unlock');
  };
  const mouseLeave = () => {
    setButtonHovered(false);
    setBtnIcon('lock');
  };

  useEffect(() => {
    if (hasPublicSharePaywalled) {
      setBtnIcon('lock');
    }
  }, [hasPublicSharePaywalled]);

  return (
    <Wrapper>
      {!isMobile ? (
        <div>
          <PaywallTooltip
            description={description}
            modifiers={[{ name: 'offset', options: { offset: [50, 0] } }]}
            shouldBeVisible={buttonHovered}
            title={title}
          />
          <StyledDisabledButton
            className='react-upgrade-modal-trigger'
            data-toggle='tooltip'
            iconColor={theme.vars.accentPrimaryDefault ?? theme.vars.textDefault}
            iconName={btnIcon}
            id='paywall-btn'
            onClick={() => canUpdateBillingPlan && paywallClicked()}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            opacity='0.5'
            size={size}
            text={title}
          />
        </div>
      ) : (
        <StyledDisabledButton
          className='react-upgrade-modal-trigger'
          data-toggle='tooltip'
          iconColor={theme.vars.accentPrimaryDefault ?? theme.vars.textDefault}
          iconName={btnIcon}
          id='paywall-btn'
          onClick={() => canUpdateBillingPlan && paywallClicked()}
          onMouseEnter={mouseEnter}
          onMouseLeave={mouseLeave}
          opacity='0.5'
          size={size}
          text={title}
        />
      )}
    </Wrapper>
  );
};

export default PaywallButton;
