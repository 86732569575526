import React, { ReactNode } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { mediaBreakpointPxMd } from '../../../styled/Breakpoint';

type Mode = 'normal' | 'fill-content';

const TopWrapper = styled.div`
  height: 100%;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
`;

const Wrapper = styled.div<{ customTopPadding?: string }>`
  padding-top: ${({ theme: { constants }, customTopPadding }) =>
    customTopPadding ? customTopPadding : constants.spacerLg2};
  padding-bottom: ${({ theme: { constants } }) => constants.spacerLg2};
  padding-inline: ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxMd}) {
    padding-inline: ${({ theme: { constants } }) => constants.spacerLg2};
  }
`;

const NormalContentDiv = styled.div`
  width: 100%;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  max-width: 80rem;
`;

const FillContentDiv = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const FillContentGlobalStyles = createGlobalStyle`
  .main-content-container {
    /* Calculate the height of the content area so that the footer remains visible */
    height: calc(100% - 60px);
  }

  .main-page-content {
    overflow: hidden;

    #main-content-wrapper {
      height: 100%;
      padding-bottom: 0;
      padding-right: 0;
      padding-left: 0;

      .react-root,
      .react-root .react-icon-provider {
        height: 100%;
      }
    }
  }
`;

interface Props {
  children: ReactNode | ReactNode[];
  className?: string;
  id?: string;
  mode?: Mode;
  customTopPadding?: string;
}

const PageContent = ({ children, className, customTopPadding, mode = 'normal', id }: Props) => {
  return (
    <TopWrapper className={className}>
      {mode === 'normal' ? (
        <Wrapper customTopPadding={customTopPadding}>
          <NormalContentDiv id={id}>{children}</NormalContentDiv>
        </Wrapper>
      ) : (
        <FillContentDiv id={id}>
          <FillContentGlobalStyles />
          {children}
        </FillContentDiv>
      )}
    </TopWrapper>
  );
};

/**
 * This component should be used as the top level component for most fully react pages.
 */
export default PageContent;
