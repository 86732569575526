import React from 'react';

import { TeammateModalProvider } from '../../../../../contexts/TeammateContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import { useDisableNavigationItem } from '../../../../../hooks/useDisableNavigationItem';
import ReferAFriendModal from '../../../billing/shared/ReferAFriendModal/ReferAFriendModal';
import BeamerIcon from '../BeamerIcon';
import HelpIcon from '../HelpIcon';
import Notifications from '../Notifications';
import UniversalAdd, { UniversalAddIcon } from '../UniversalAdd';
import UserMenu from '../UserMenu';
import { useShowReferralModal } from '../utils/useShowReferralModal';
import { DisabledIconWrapper, IconsWrapper } from './styles';

const Icons = () => {
  const ability = useCurrentUserAbilities();
  const canInviteTeammates = ability.can('create', 'User');
  const canCreateSubject = ability.can('create', 'Curriculum');
  const showUniversalAdd = canInviteTeammates || canCreateSubject;
  const {
    splitFeatures: { perUserPricingEnabled },
  } = useCurrentAccount();
  const { notifications } = useCurrentUser();

  const { isDisabledNavItem } = useDisableNavigationItem();
  const { showReferralModal, setShowReferralModal } = useShowReferralModal();

  return (
    <IconsWrapper align='center'>
      <BeamerIcon />
      <HelpIcon />
      {ability.can('read', 'Notifications') && <Notifications notifications={notifications} />}

      <TeammateModalProvider showFlashOnTeammateStateUpdate={perUserPricingEnabled}>
        {showReferralModal && (
          <ReferAFriendModal
            onCloseRequest={() => setShowReferralModal(false)}
            showModal={showReferralModal}
          />
        )}
        {showUniversalAdd &&
          (isDisabledNavItem ? (
            <DisabledIconWrapper>
              <UniversalAddIcon />
            </DisabledIconWrapper>
          ) : (
            <UniversalAdd />
          ))}
        <UserMenu />
      </TeammateModalProvider>
    </IconsWrapper>
  );
};

export default Icons;
