import React from 'react';

import AnnualSavingsSvg from '../../../../../../../images/billing/annual_savings.svg';
import RaysSvg from '../../../../../../../images/billing/rays.svg';
import initTranslations from '../../../../../../../lib/initTranslations';
import { BillingInterval } from '../../../../../../../types/BillingInterval';
import { HeaderWrapper } from '../../../../../../design_system/Triage/headers/Header';
import BillingIntervalToggle from '../../../../shared/BillingIntervalToggler/BillingIntervalToggle';
import {
  AnnualSavingsImg,
  BillingIntervalToggleWrapper,
  CardsHeaderWrapper,
  RaysImg,
} from './styles';

const t = initTranslations('simple_pricing.billing_page.plans.header');

type PlanCardsHeaderProps = {
  selectedInterval: BillingInterval;
  setSelectedInterval: React.Dispatch<React.SetStateAction<BillingInterval>>;
};

const PlanCardsHeader = ({ selectedInterval, setSelectedInterval }: PlanCardsHeaderProps) => {
  return (
    <CardsHeaderWrapper>
      <HeaderWrapper subtitle={t('subtitle')} title={t('title')} />
      <BillingIntervalToggleWrapper>
        <AnnualSavingsImg alt={t('alt_annual_savings')} src={AnnualSavingsSvg} />
        <BillingIntervalToggle
          handleChange={setSelectedInterval}
          isAnnualChecked={selectedInterval === 'year'}
          isLoading={false}
        />
        <RaysImg alt={t('alt_rays')} src={RaysSvg} />
      </BillingIntervalToggleWrapper>
    </CardsHeaderWrapper>
  );
};

export default PlanCardsHeader;
