import React from 'react';

import { useAccountTerminology } from '../../../../components/AccountTerminologyProvider';
import usePageTitle from '../../../../hooks/usePageTitle';
import initTranslations from '../../../../lib/initTranslations';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import PageContent from '../../shared/page_content';
import AllUsersReportFilters from './AllUsersReportFilters/AllUsersReportFilters';
import AllUsersReportTable from './AllUsersReportTable/AllUsersReportTable';

const t = initTranslations('reports.all_users_report.page');

const AllUsersReportPage = () => {
  const { productTerm } = useAccountTerminology();
  usePageTitle({ resourceType: 'people_reports', productTerm });

  return (
    <PageContent>
      <PageHeader subtitle={t('subtitle')} title={t('title')} />
      <AllUsersReportTable />
      <AllUsersReportFilters />
    </PageContent>
  );
};

export default AllUsersReportPage;
