import { useEffect } from 'react';

import initTranslations from '../lib/initTranslations';

const t = initTranslations('contextual_tab_names');

const i18n = ({ resourceType, productTerm, resourceName = '' }: pageTitleProps) => {
  switch (resourceType) {
    case 'course':
    case 'course_reports':
    case 'curriculum':
    case 'group':
    case 'individual_user_report':
    case 'home':
    case 'report':
    case 'template':
    case 'user':
      return t(`${resourceType}`, { resource_name: resourceName, productTerm });
    case 'account_settings':
    case 'billing':
    case 'content':
    case 'content_order':
    case 'delegation_planner':
    case 'directory':
    case 'groups':
    case 'integrations':
    case 'latest_activity_report':
    case 'manage_users':
    case 'my_settings':
    case 'notifications':
    case 'org_chart_index':
    case 'people_reports':
    case 'reports':
    case 'role_chart_index':
    case 'subject_reports':
    case 'templates':
      return t(`${resourceType}`, { productTerm });
    default:
      return t('default');
  }
};

interface pageTitleProps {
  resourceType: string;
  productTerm: string;
  resourceName?: string | null;
}

export default function usePageTitle({
  resourceType,
  productTerm,
  resourceName = '',
}: pageTitleProps) {
  const title = i18n({ resourceType, resourceName, productTerm });

  useEffect(() => {
    document.title = title;
  }, [title]);
}
