import React from 'react';

import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import { RegisteredMenuId } from '../../../../../../lib/idRegistry';
import initTranslations from '../../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../../redux/errors/helpers';
import { useDeleteStepExternalLinkMutation } from '../../../../../../redux/services/resourceApis/stepExternalLinks/stepExternalLinksApi';
import ThreeDotMenu from '../../../../../design_system/Triage/menus/ThreeDotMenu';
import { StepExternalLinksListAction } from '../StepExternalLinksList/types';
import { ElementWrapper, LinkUrl } from './styles';

const t = initTranslations('step_external_links');

interface Props {
  stepExternalLink: { id: number; url: string };
  dispatchListState: React.Dispatch<StepExternalLinksListAction>;
}

const StepExternalLinksListItem = ({ dispatchListState, stepExternalLink: { id, url } }: Props) => {
  const [deleteLink, result] = useDeleteStepExternalLinkMutation();
  const menuId: RegisteredMenuId = `step-external-link-three-dot-${id}`;

  useDisplayFlashOnResponse({
    result,
    errorMessage: messageFromError(result.error)?.join(', '),
  });

  return (
    <ElementWrapper>
      <LinkUrl>{url}</LinkUrl>
      <ThreeDotMenu
        id={menuId}
        menuOptions={[
          {
            title: t('edit'),
            onClick: () => dispatchListState({ type: 'editIdChanged', editId: id }),
            iconName: 'pencil',
            id: 'step-external-link-edit',
            visible: true,
          },
          {
            title: t('remove'),
            onClick: () => deleteLink(id),
            iconName: 'trash-alt',
            id: 'step-external-link-delete',
            visible: true,
          },
        ]}
      />
    </ElementWrapper>
  );
};

export default StepExternalLinksListItem;
