import { LoadingSize } from '../../../../people/UsersOutline/Table/Column';
import { Column } from '../../../../people/UsersOutline/Table/TableTypes';
import { ElementsColumn } from './types';

interface TableColumn extends Omit<Column, 'columnName' | 'sortable'> {
  columnName: ElementsColumn;
}

export const getColumns = (isSignaturePaywalled: boolean) => {
  const column: TableColumn[] = [
    { columnName: 'title', loadingSize: LoadingSize.large },
    { columnName: 'status', loadingSize: LoadingSize.medium },
    { columnName: 'completion_score', loadingSize: LoadingSize.medium },
    { columnName: 'actions', loadingSize: LoadingSize.medium, contentAlignment: 'end' },
  ];

  if (!isSignaturePaywalled) {
    column.splice(3, 0, { columnName: 'e_signature_status', loadingSize: LoadingSize.medium });
  }
  return column;
};
