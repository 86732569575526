import React, { useCallback } from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../redux/errors/helpers';
import { useRecoverDeletedContentFromActivityMutation } from '../../../../../redux/services/resourceApis/reports/reportsApi';
import ConfirmationModal from '../../../../design_system/overlays/modals/ConfirmationModal';

const t = initTranslations('reports.activity_log.recover_content_modal');

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  setProcessing: () => void;
  activityId: number;
}

const RecoverContentModal = ({ showModal, closeModal, setProcessing, activityId }: ModalProps) => {
  const [recoverContent, result] = useRecoverDeletedContentFromActivityMutation();
  const { isLoading: isProcessing, error: recoveryError, reset: contentRecoveryReset } = result;

  const successFunction = useCallback(() => {
    contentRecoveryReset();
    setProcessing();
  }, [contentRecoveryReset, setProcessing]);

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success'),
    successFunction,
    errorMessage: messageFromError(recoveryError)?.join(', '),
  });

  return (
    <>
      {showModal && (
        <ConfirmationModal
          actionFunction={() => {
            recoverContent(activityId);
          }}
          actionText={t('recover')}
          heapModalName='recover-content-modal'
          message={t('confirmation_text')}
          onCloseRequest={closeModal}
          processing={isProcessing}
          title={t('title')}
        />
      )}
    </>
  );
};

export default RecoverContentModal;
