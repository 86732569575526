import React, { useContext } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { AccountTerminologyContext } from '../../../../AccountTerminologyProvider';
import Link from '../../../../design_system/Link';
import { routes } from '../../../publicApplication/applicationRouter';
import { TextFragment } from '../styles';
import { ActivityAssociationParams } from '../types';

const t = initTranslations('reports.activity_log');

export interface UserOverdueCurriculumsProps {
  activityKey: string;
  trackable?: ActivityAssociationParams;
  parameters: { assignment_ids: number[]; curriculum_id: number };
}

const OverdueCurriculumsTemplate = ({ parameters, activityKey }: UserOverdueCurriculumsProps) => {
  const { curriculum } = useContext(AccountTerminologyContext);
  const { slug } = useCurrentAccount();

  return (
    <>
      <TextFragment>{t('user.due_date.has')}</TextFragment>
      <Link
        href={routes.curriculumShow({ slug, id: parameters.curriculum_id }).href}
        text={t(activityKey, {
          count: parameters.assignment_ids.length,
          curriculum: curriculum.singular.toLowerCase(),
        })}
        underlineBehavior='dynamic'
      />
    </>
  );
};

export default OverdueCurriculumsTemplate;
