import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import useWindowResize from '../../../../../hooks/useWindowResize';
import { stringToNumber, stringifyNumber } from '../../../../../lib/convertValues';
import initTranslations from '../../../../../lib/initTranslations';
import { useResolveOrgChartConflictMutation } from '../../../../../redux/services/resourceApis/orgChart/orgChartApi';
import {
  OrgChartResourceType,
  ReportsToResource,
  Resource,
} from '../../../../../redux/services/resourceApis/orgChart/types';
import { Option } from '../../../../design_system/core/SelectOption/types';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import SingleSelectField from '../../../../design_system/Triage/fields/SingleSelectField';
import { mediaBreakpointPxSm, mediaBreakpointSm } from '../../../../styled/Breakpoint';
import { TaskErrorDiv } from '../../../../styled/Modals';

export const StyledSelect = styled(SingleSelectField)`
  margin: 0 !important;
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${mediaBreakpointPxSm}) {
    flex-direction: row;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`;

const LabelSpacer = styled.div`
  display: none;

  @media (min-width: ${mediaBreakpointPxSm}) {
    display: block;
  }
`;

const LabelWrapper = styled.div`
  display: none;

  @media (min-width: ${mediaBreakpointPxSm}) {
    display: block;
    flex-basis: 70%;
    padding-left: 1.5rem;
  }
`;

const SelectWrapper = styled.div`
  width: 100%;

  @media (min-width: ${mediaBreakpointPxSm}) {
    flex: 0 0 70%;
    padding-left: 1.5rem;
  }
`;

const ResourceName = styled.p`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 1.5rem;
  text-align: left;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd1};
  width: 100%;

  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

type ErrorShape = {
  failed_to_save_parent: {
    resource_id: number;
    parent_id: number;
    parent_of_parent_id: number;
  };
};

type ConflictError = { data: { baseErrors: ErrorShape[] } } | { data: ErrorShape[] };

export const parseOrgChartConflictError = (error: unknown): ErrorShape | undefined => {
  const castedError = error as ConflictError;
  if (!castedError) return undefined;

  if ('baseErrors' in castedError.data) {
    const { baseErrors } = castedError.data;
    if (baseErrors[0].failed_to_save_parent) {
      return baseErrors[0];
    }
  }

  return undefined;
};

export const extractResourcesFromError = (error: ErrorShape, resources: Resource[]) => {
  const {
    resource_id: resourceId,
    parent_id: parentId,
    parent_of_parent_id: parentOfParentId,
  } = error.failed_to_save_parent;
  const errorResource = resources.find((resource) => resource.id === resourceId);
  const errorParent = resources.find((resource) => resource.id === parentId);
  const errorParentOfParent = resources.find((resource) => resource.id === parentOfParentId);

  return { errorResource, errorParent, errorParentOfParent };
};

interface ResourceSection {
  resources: Resource[];
  conflictResource: ReportsToResource;
  submitting: boolean;
  setConflictResources: React.Dispatch<React.SetStateAction<ReportsToResource[]>>;
  placeholder: string;
  mobileLabel: string;
  formatResourceOptions: (...args: unknown[]) => Option[];
}

const ResourceSection = ({
  formatResourceOptions,
  resources,
  conflictResource,
  submitting,
  setConflictResources,
  placeholder,
  mobileLabel,
}: ResourceSection) => {
  const resourceOptions = resources.filter(
    (resource) => resource.id !== conflictResource.resource.id
  );
  const selectOptions = formatResourceOptions(resourceOptions);
  const { width } = useWindowResize();
  const reportsToId = useMemo(
    () =>
      conflictResource.reportsToResource && stringifyNumber(conflictResource.reportsToResource.id),
    [conflictResource.reportsToResource]
  );

  const handleSelectedParentId = (value: string | null) => {
    const numberId = stringToNumber(value);
    const resource = resources.find((resource) => resource.id === numberId) || null;

    setConflictResources((conflictResources) =>
      conflictResources.map((reportsToResource) => {
        if (reportsToResource.resource.id !== conflictResource.resource.id) {
          return reportsToResource;
        }

        return {
          ...reportsToResource,
          reportsToResource: resource,
        };
      })
    );
  };

  return (
    <FlexDiv id={`resource-${conflictResource.resource.id}`}>
      <ResourceName>
        {conflictResource.resource.name}
        {width < mediaBreakpointSm && mobileLabel}
      </ResourceName>
      <SelectWrapper>
        <StyledSelect
          className='reports-to-select'
          defaultValue={reportsToId}
          disabled={submitting}
          isClearable
          key={`conflict-resource-${conflictResource.resource.id}`}
          loading={submitting}
          onNonDefaultSelected={(value: string) => handleSelectedParentId(value)}
          options={selectOptions}
          placeholder={placeholder}
          value={reportsToId}
        />
      </SelectWrapper>
    </FlexDiv>
  );
};

const t = initTranslations('org_chart.conflict_modal');

interface Props {
  closeModal: () => void;
  conflictResources: ReportsToResource[];
  resources: Resource[];
  resourceType: OrgChartResourceType;
  formatResourceOptions: (...args: unknown[]) => Option[];
}

const ReportsToConflictModal = ({
  closeModal,
  conflictResources: initialConflictResources,
  formatResourceOptions,
  resourceType,
  resources,
}: Props) => {
  const [conflictResources, setConflictResources] = useState(initialConflictResources);
  const [error, setError] = useState<string | null>(null);
  const conflictResourceOne = conflictResources[0];
  const conflictResourceTwo = conflictResources[1];
  const [resolveConflict, result] = useResolveOrgChartConflictMutation();
  const { error: resolveConflictError, isLoading, reset } = result;

  const updatedResources = useMemo(() => {
    return conflictResources.map((reportsToResource) => {
      return {
        id: reportsToResource.resource.id,
        reports_to_id: reportsToResource.reportsToResource?.id || null,
      };
    });
  }, [conflictResources]);

  const disableConfirm = useMemo(() => {
    if (updatedResources.length !== 2) return true;

    return (
      updatedResources[0].id === updatedResources[1].reports_to_id &&
      updatedResources[1].id === updatedResources[0].reports_to_id
    );
  }, [updatedResources]);

  useDisplayFlashOnResponse({
    result,
    successMessage: t('reports_to_success', {
      resourceName: conflictResourceOne.resource.name,
      parentName: conflictResourceTwo.resource.name,
    }),
    successFunction: closeModal,
  });

  useEffect(() => {
    const error = parseOrgChartConflictError(resolveConflictError);

    if (error) {
      const { errorResource, errorParent } = extractResourcesFromError(error, resources);
      if (errorResource && errorParent) {
        reset();

        setError(
          t('errors.autosave_error', {
            resourceName: errorResource.name,
            parentName: errorParent.name,
          })
        );
      }
    }
  }, [reset, resolveConflictError, resources]);

  const taskModalArgs: TaskModalProps = {
    title: t('header'),
    subtitle: t('description', {
      resourceName: conflictResourceOne.resource.name,
      parentName: conflictResourceTwo.resource.name,
    }),
    iconName: 'exclamation-circle',
    iconWeight: 'regular',
    onCloseRequest: () =>
      resolveConflict({ resources: updatedResources, clear: true, resourceType }),
    primaryButtonText: t('confirm'),
    primaryButtonTask: () =>
      resolveConflict({ resources: updatedResources, clear: false, resourceType }),
    processing: isLoading,
    isDisabled: disableConfirm,
    secondaryButtonText: t('clear'),
    isStaticModal: true,
    heapModalName: 'reports-to-conflict-modal',
  };

  return (
    <TaskModal {...taskModalArgs} desktopSize='lg'>
      <div id='conflict-modal-body'>
        <FlexDiv>
          <LabelSpacer />
          <LabelWrapper>{t('desktop_label')}</LabelWrapper>
        </FlexDiv>
        {conflictResources.map((conflictResource) => (
          <ResourceSection
            conflictResource={conflictResource}
            formatResourceOptions={formatResourceOptions}
            key={`select-resource-${conflictResource.resource.id}`}
            mobileLabel={t('mobile_label')}
            placeholder={t('select_placeholder')}
            resources={resources}
            setConflictResources={setConflictResources}
            submitting={isLoading}
          />
        ))}
        {error && <TaskErrorDiv>{error}</TaskErrorDiv>}
      </div>
    </TaskModal>
  );
};

export default ReportsToConflictModal;
