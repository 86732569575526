import React, { ReactNode, createContext, useCallback, useContext, useEffect } from 'react';

import { useFlashNotification } from '../components/FlashNotificationContext';
import useCurrentAccount from '../hooks/useCurrentAccount';
import useTeammateState, { MainTeammateState, TeammateState } from '../hooks/useTeammateState';
import initTranslations from '../lib/initTranslations';

const t = initTranslations('teammate_modal');

interface Props {
  children: ReactNode;
  userId?: number;
  showFlashOnTeammateStateUpdate?: boolean;
}

const TeammateModalContext = createContext({} as MainTeammateState);
export const useTeammateModal = () => useContext(TeammateModalContext);

export const TeammateModalProvider = ({
  children,
  showFlashOnTeammateStateUpdate = true,
  userId,
}: Props) => {
  const state = useTeammateState({ userId });
  const { teammateState, setTeammateState, teammateConfirmationModalVisible } = state;
  const {
    splitFeatures: { perUserPricingEnabled },
  } = useCurrentAccount();

  const { flash } = useFlashNotification();

  const showRelatedSuccessMessage = useCallback(
    (teammateState: TeammateState) => {
      const {
        sendInvite,
        user: { name, email },
      } = teammateState;

      if (sendInvite === 'now') {
        flash('info', t('add.success_message.default.invite_now', { email }));
      } else if (sendInvite === 'scheduled') {
        flash('info', t('add.success_message.default.invite_scheduled'));
      } else if (!perUserPricingEnabled) {
        flash('info', t('add.success_message.default.invite_later', { name }));
      }
    },
    [flash, perUserPricingEnabled]
  );

  useEffect(() => {
    if (teammateState && showFlashOnTeammateStateUpdate && !teammateConfirmationModalVisible) {
      userId
        ? flash('info', t('edit.success_message.edited'))
        : showRelatedSuccessMessage(teammateState);

      setTeammateState(null);
    }
  }, [
    flash,
    setTeammateState,
    showFlashOnTeammateStateUpdate,
    showRelatedSuccessMessage,
    teammateConfirmationModalVisible,
    teammateState,
    userId,
  ]);

  return (
    <TeammateModalContext.Provider value={{ ...state }}>{children}</TeammateModalContext.Provider>
  );
};
