import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import NotificationUserName from '../../notifications/NotificationUserName/NotificationUserName';
import { TextFragment } from '../styles';
import { ActivityAssociationParams } from '../types';

const t = initTranslations('reports.activity_log');

export interface UserCommonTemplateWithDateProps {
  activityKey: string;
  trackable: ActivityAssociationParams;
  parameters: {
    scheduled_archive_at?: string;
    scheduled_invite_at?: string;
  };
}

const CommonTemplateWithDate = ({
  trackable,
  activityKey,
  parameters,
}: UserCommonTemplateWithDateProps) => {
  return (
    <>
      <TextFragment>{t(activityKey)}</TextFragment>
      <NotificationUserName
        user={{
          is_active: trackable.is_active,
          id: trackable.id,
          name: trackable.name,
        }}
      />
      {activityKey === 'user.scheduled_invite' && parameters.scheduled_invite_at && (
        <TextFragment>
          {t('user.scheduled_invite_date', { date: parameters.scheduled_invite_at })}
        </TextFragment>
      )}
      {activityKey === 'user.scheduled_archive' && parameters.scheduled_archive_at && (
        <TextFragment>
          {t('user.scheduled_archive_date', { date: parameters.scheduled_archive_at })}
        </TextFragment>
      )}
    </>
  );
};

export default CommonTemplateWithDate;
