import { Form, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import React, { useEffect, useMemo } from 'react';

import { useEmbedlyContext } from '../../../../../../contexts/EmbedlyProvider';
import initTranslations from '../../../../../../lib/initTranslations';
import { StyledTextAreaField } from '../../../shared/styles';

const t = initTranslations('editor');

type Values = {
  url: string;
};

const EmbedCodeForm = () => {
  const {
    resetForm: manualResetForm,
    setResetForm,
    setEmbedlyData,
    setErrorMessage,
  } = useEmbedlyContext();
  const { resetForm, handleChange, submitForm } = useFormikContext<Values>();

  const debouncedSubmit = useMemo(
    () =>
      debounce(async () => {
        await submitForm();
      }, 500),
    [submitForm]
  );

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    handleChange(e);
    debouncedSubmit();

    setEmbedlyData(null);
    setErrorMessage(null);
  };

  useEffect(() => {
    if (manualResetForm) {
      resetForm({ values: { url: '' } });
      setResetForm(false);
    }
  }, [manualResetForm, resetForm, setResetForm]);

  return (
    <Form>
      <StyledTextAreaField
        data-testid='embedded-code-textarea'
        id='embedded-code-textarea'
        name='embeddedCode'
        onChange={onChange}
        placeholder={t('video.embedded_code_placeholder')}
      />
    </Form>
  );
};

export default EmbedCodeForm;
