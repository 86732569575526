import React from 'react';
import styled from 'styled-components';

import Link from '../../../../design_system/Link';
import { fontMd4, fontSm5 } from '../../../../styled/TypeSystem';
import Tag from '../../../display/Tag';
import useHeaderRoute, { HeaderProps } from './useHeaderRoute';

const TitleRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledP = styled.p`
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin: ${({ theme: { constants } }) => constants.spacerSm2} 0
    ${({ theme: { constants } }) => constants.spacerMd3} 0;
  ${fontSm5};
`;

const StyledH4 = styled.h4`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  margin: 0;
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontMd4};
`;

const LearnMore = styled.span`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export type HeaderWrapperProps = HeaderProps;

export const HeaderWrapper = ({
  title,
  subtitle = '',
  subtitleAddOnText,
  isBeta = false,
  learnMoreText,
  learnMoreHref,
  learnMoreExternal = false,
}: HeaderWrapperProps) => {
  return (
    <>
      <TitleRow>
        <StyledH4>{title}</StyledH4>
        {isBeta && <Tag />}
      </TitleRow>
      {subtitle && (
        <StyledP>
          {subtitle} {!!subtitleAddOnText && <strong>{subtitleAddOnText}</strong>}
          {learnMoreText && learnMoreHref && (
            <LearnMore>
              <Link external={learnMoreExternal} href={learnMoreHref} text={learnMoreText} />
            </LearnMore>
          )}
        </StyledP>
      )}
    </>
  );
};

const Header = () => {
  const { title, subtitle, isBeta } = useHeaderRoute();

  return <HeaderWrapper isBeta={isBeta} subtitle={subtitle} title={title} />;
};

export default Header;
