import React from 'react';
import styled from 'styled-components';

import DefaultButton from '../../../design_system/buttons/DefaultButton';
import { IconWrapper } from '../../../design_system/buttons/DefaultButton/ButtonStyles';
import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';

export const StyledFilterButton: React.ElementType = styled(DefaultButton)`
  // DS Override - No pointer events so event passes to the wrapper with className 'flyout-trigger' for Flyout handling
  ${IconWrapper} {
    pointer-events: none;
  }

  @media (max-width: ${mediaBreakpointPxSm}) {
    margin-bottom: ${({ theme }) => theme.constants.spacerMd2};
  }
`;

export const StyledDownloadCsvButton: React.ElementType = styled(DefaultButton)`
  min-width: max-content;
  margin-left: ${({ theme }) => theme.constants.spacerSm3};

  @media (max-width: ${mediaBreakpointPxSm}) {
    margin-bottom: ${({ theme }) => theme.constants.spacerMd2};
  }
`;

export const SelectField = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;
