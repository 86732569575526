import initTranslations from '../../lib/initTranslations';
import { Discount } from '../../types/Discount';

const t = initTranslations('discounts_label');

export function useDiscountsLabel(discount: Discount | undefined) {
  switch (discount?.type) {
    case 'amountOff':
      return t('amount_off', { amount: discount.amount / 100 });
    case 'percentOff':
      return t('percents_off', { percent: `${discount.amount}%` });
  }
}
