import React, { ReactElement, ReactNode, createContext, useContext, useState } from 'react';

import {
  LOCALE_OPTIONS,
  Locales,
  LocalesDropdownOption,
} from '../components/application/locales/types';
import { SettingsFormValues } from '../components/application/people/Settings/InterfaceAndTypes';
import useCurrentAccount from '../hooks/useCurrentAccount';
import { useChangeUserSettingsMutation } from '../redux/services/resourceApis/users/usersApi';

interface initContext {
  locale: LocalesDropdownOption;
  updateLocale: (locale: Locales) => void;
  initLocale: (locale: Locales) => void;
}

const LocalesContext = createContext({} as initContext);

type Props = {
  children: ReactNode;
};

export const useLocalesContext = () => useContext(LocalesContext);

const LocalesProvider = ({ children }: Props): ReactElement => {
  const [locale, setLocale] = useState(LOCALE_OPTIONS[0]);
  const [updateSettings] = useChangeUserSettingsMutation();
  const { multiLanguageEnabled } = useCurrentAccount();

  const updateLocale = (updLocale: Locales) => {
    if (!multiLanguageEnabled) return;
    if (updLocale === locale.value) return;

    const values = { user_configuration_attributes: { language: updLocale } } as SettingsFormValues;
    updateSettings(values);
  };

  const initLocale = (updLocale: Locales) => {
    if (!multiLanguageEnabled) return;
    if (updLocale === locale.value) return;

    const localeOption = LOCALE_OPTIONS.find((option) => option.value === updLocale);
    if (!localeOption) return;

    setLocale(localeOption);

    if (updLocale === 'en') {
      document.body.classList.add('notranslate');
    } else {
      document.body.classList.remove('notranslate');
    }

    const element = document.querySelector('.goog-te-combo') as HTMLInputElement;
    element.value = updLocale;
    element.dispatchEvent(new Event('change'));
  };

  return (
    <LocalesContext.Provider value={{ locale, updateLocale, initLocale }}>
      {children}
    </LocalesContext.Provider>
  );
};

export { LocalesProvider };
