import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { routes } from '../../../publicApplication/applicationRouter';
import { TextFragment } from '../styles';
import { CompletionTrackable } from '../types';

const t = initTranslations('reports.activity_log');

export interface CompletionCommonTemplateProps {
  activityKey: string;
  trackable: CompletionTrackable;
}

const CommonTemplate = ({ trackable, activityKey }: CompletionCommonTemplateProps) => {
  const { slug } = useCurrentAccount();
  const { curriculum_id: id, curriculum_title, is_active_curriculum } = trackable.associated_params;

  return (
    <>
      <TextFragment>{t(activityKey)}</TextFragment>
      <SourceBadge
        readOnly={!is_active_curriculum}
        sourceName='curriculum'
        sourceRoute={routes.curriculumEdit({ slug, id })}
        sourceText={curriculum_title}
      />
      {activityKey == 'completion.expire' && (
        <TextFragment>{t('completion.expired_on', { date: trackable.expired_at })}</TextFragment>
      )}
    </>
  );
};

export default CommonTemplate;
