import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import { DefaultHeaderStyled } from '../../../people/UsersOutline/Table/Styles';
import { StyledHeaderCell } from '../../AllUsersReportPage/AllUsersReportTable/styles';
import { StyledSortIcon } from '../../Styles';
import { TableHeaderCellProps } from './types';

const t = initTranslations('reports.individual_user_report.table');

const IndividualUserReportTableHeaderCell = ({
  column: { columnName, stickyPosition = 'center', contentAlignment = 'start' },
  activeHeader,
  setActiveHeader,
  sortIcon,
  isSortable,
}: TableHeaderCellProps) => {
  const { curriculum } = useAccountTerminology();

  return (
    <DefaultHeaderStyled>
      <StyledHeaderCell
        active={activeHeader}
        className={isSortable ? `sortable-column ${columnName}` : undefined}
        contentAlignment={contentAlignment}
        isSortable={isSortable}
        onClick={() => setActiveHeader && setActiveHeader()}
        stickyPosition={stickyPosition}
      >
        {columnName === 'title' ? curriculum.singular : t(`headers.${columnName}`)}
        {sortIcon && <StyledSortIcon name={sortIcon} size='2xs' weight='solid' />}
      </StyledHeaderCell>
    </DefaultHeaderStyled>
  );
};

export default IndividualUserReportTableHeaderCell;
