import React from 'react';
import { preventDefaultLinkClickBehavior } from 'type-route';

import { FontSize, FontWeightType } from '../../people/Slideouts/Shared/FontSize';
import { session, useRouterHelper } from '../../publicApplication/applicationRouter';
import { TitleColors, TitleLabel, TitleLink, TitleUnclickableLabel, Truncate } from './TitleStyles';

export type TitleProps = {
  title: string | JSX.Element;
  route?: string;
  onClick?: () => void;
  fontWeight?: FontWeightType;
  fontSize?: FontSize;
  truncate?: Truncate;
  fontColor?: TitleColors;
  id?: string;
};

// TODO: Rename to ControlledText or something different than Title as this is not used as a Title everywhere, and a new name should suit this better
export const Title = ({
  route,
  onClick,
  title,
  truncate = 'none',
  fontSize = 'sm5',
  fontWeight = 'medium',
  fontColor = 'default',
  id,
}: TitleProps) => {
  const routeState = useRouterHelper();

  if (route) {
    return (
      <TitleLink
        fontSize={fontSize}
        fontWeight={fontWeight}
        href={route}
        id={id}
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
          /*
          type-route: Temporarily needed while app is being migrated to fully use type-route library.
          When we are fully migrated we will require a route to be passed from the router instead of href
          */
          if (routeState && preventDefaultLinkClickBehavior(event)) {
            const button = event.target as HTMLAnchorElement;
            button.blur();

            session.push(route);
          }
        }}
        truncate={truncate}
      >
        {title}
      </TitleLink>
    );
  } else if (onClick) {
    return (
      <TitleLabel
        fontColor={fontColor}
        fontSize={fontSize}
        fontWeight={fontWeight}
        id={id}
        onClick={onClick}
        truncate={truncate}
      >
        {title}
      </TitleLabel>
    );
  }

  return (
    <TitleUnclickableLabel
      fontColor={fontColor}
      fontSize={fontSize}
      fontWeight={fontWeight}
      id={id}
      truncate={truncate}
    >
      {title}
    </TitleUnclickableLabel>
  );
};
