import React, { useContext } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { AccountTerminologyContext } from '../../../../AccountTerminologyProvider';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { routes } from '../../../publicApplication/applicationRouter';
import { TextFragment } from '../styles';
import { CurriculumElementTrackable } from '../types';

const t = initTranslations('reports.activity_log');

export interface SurveyCommonTemplateProps {
  trackable: CurriculumElementTrackable;
  parameters: { curriculum_id: number; curriculum_title: string };
  activityKey: string;
}

const CommonTemplate = ({ trackable, activityKey, parameters }: SurveyCommonTemplateProps) => {
  const { slug } = useCurrentAccount();
  const { id, name, is_active, associated_params } = trackable;
  const { curriculum } = useContext(AccountTerminologyContext);
  const isActiveCurriculum =
    associated_params.is_active_curriculum || activityKey == 'survey_survey.destroy';
  const curriculumTitle =
    activityKey == 'survey_survey.destroy'
      ? parameters.curriculum_title
      : associated_params.curriculum_title;
  const surveyEditUrl = routes.surveyEditor({ slug, id }).href;

  return (
    <>
      <TextFragment>{t(activityKey)}</TextFragment>
      <SourceBadge
        readOnly={!is_active}
        sourceHref={surveyEditUrl}
        sourceName='curriculum'
        sourceText={name}
      />
      <TextFragment>
        {t('survey_survey.in_curriculum', { curriculum: curriculum.singular.toLowerCase() })}
      </TextFragment>
      <SourceBadge
        readOnly={!isActiveCurriculum}
        sourceName='curriculum'
        sourceRoute={routes.curriculumEdit({
          slug,
          id: parameters.curriculum_id || associated_params.curriculum_id,
        })}
        sourceText={curriculumTitle}
      />
    </>
  );
};

export default CommonTemplate;
