import React from 'react';

import { useAccountTerminology } from '../../../../../components/AccountTerminologyProvider';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import usePageTitle from '../../../../../hooks/usePageTitle';
import initTranslations from '../../../../../lib/initTranslations';
import { useGetUserPrimaryDetailsQuery } from '../../../../../redux/services/resourceApis/users/usersApi';
import Avatar from '../../../../design_system/display/avatar';
import Breadcrumbs from '../../../../design_system/navigation/Breadcrumbs';
import ProfileRouterLink from '../../../people/Profile/ProfileRouterLink';
import { routes } from '../../../publicApplication/applicationRouter';
import ReportHeadRightColumn from './ReportHeadRightColumn/ReportHeadRightColumn';
import {
  ReportHeadContainer,
  ReportHeadInfo,
  ReportHeadLastActive,
  ReportHeadLeftColumn,
  ReportHeadReportsTo,
  ReportHeadRightColumnWrapper,
  ReportHeadSubtitle,
  ReportHeadTitle,
} from './styles';
import { Props } from './types';

const t = initTranslations('reports.individual_user_report.user_card');

const ReportHeadCard = ({ id, breadcrumb, breadcrumbText }: Props) => {
  const { slug } = useCurrentAccount();
  const defaultBreadcrumb = routes.allUsersReport({ slug });
  const { data, isError } = useGetUserPrimaryDetailsQuery(id);
  const { productTerm } = useAccountTerminology();
  usePageTitle({ resourceName: data?.name, resourceType: 'individual_user_report', productTerm });

  if (isError) return <div>{t('error')}</div>;
  if (!data) return <></>;

  const {
    avatar,
    groups,
    name,
    title,
    completion_percentage: completionPercentage,
    reports_to: reportsTo,
    last_active_at: lastActiveAt,
  } = data;

  return (
    <>
      <Breadcrumbs
        currentPageText={t('breadcrumbs.current_page', { user: name })}
        redirectPath={breadcrumb?.href || defaultBreadcrumb.href}
        redirectText={
          breadcrumb?.name === 'curriculumByUserReport' && breadcrumbText
            ? t('breadcrumbs.redirect_dynamic_page', { curriculum: breadcrumbText })
            : t('breadcrumbs.redirect_page')
        }
      />
      <ReportHeadContainer className='report-head-container'>
        <ReportHeadLeftColumn>
          <ProfileRouterLink
            breadcrumb={routes.individualUserReport({ slug, id })}
            className='avatar-styled-router-link'
            id={id}
          >
            <Avatar image={avatar} name={name} shape='square' size='xl' />
          </ProfileRouterLink>
          <ReportHeadInfo>
            <ProfileRouterLink breadcrumb={routes.individualUserReport({ slug, id })} id={id}>
              <ReportHeadTitle>{name}</ReportHeadTitle>
            </ProfileRouterLink>
            <ReportHeadSubtitle>{title}</ReportHeadSubtitle>
            <ReportHeadReportsTo>
              {t('reports_to')}&nbsp;
              {reportsTo ? (
                <ProfileRouterLink
                  breadcrumb={routes.individualUserReport({ slug, id })}
                  id={reportsTo.id}
                >
                  <span>{reportsTo.name}</span>
                </ProfileRouterLink>
              ) : (
                t('not_set')
              )}
            </ReportHeadReportsTo>
            <ReportHeadLastActive>
              {t('last_active')} <b>{lastActiveAt}</b>
            </ReportHeadLastActive>
          </ReportHeadInfo>
        </ReportHeadLeftColumn>
        <ReportHeadRightColumnWrapper>
          <ReportHeadRightColumn
            completionPercentage={completionPercentage}
            completionTitle={t('completion_rate')}
            groups={groups.filter(({ everyone }) => !everyone)}
          />
        </ReportHeadRightColumnWrapper>
      </ReportHeadContainer>
    </>
  );
};

export default ReportHeadCard;
