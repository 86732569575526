import queryString from 'query-string';

import { toCamelCase, toSnakeCase } from '../../../../lib/keyFormatConverter';
import { trainualApi } from '../../trainualService';
import {
  AiSurveyComposerModalParams,
  AiSurveyComposerModalResponse,
  ComposeQuestionsParams,
  ComposeQuestionsResponse,
  SmartTestModalDataParams,
  SmartTestModalDataResponse,
  SurveyConsumptionResponse,
  SurveyEditResponse,
  UpdateSurveyParams,
} from './types';

export const surveysApi = trainualApi.injectEndpoints({
  endpoints: (builder) => ({
    getEditSurvey: builder.query<SurveyEditResponse, { id: number }>({
      query: ({ id }) => ({ url: `ajax/surveys/${id}/edit` }),
      providesTags: (result) => {
        return result
          ? [
              { type: 'Survey', id: result.id },
              { type: 'CurriculumElement', id: result.curriculumElementId },
            ]
          : [];
      },
      transformResponse: (returnValue: SurveyEditResponse) => {
        return toCamelCase<SurveyEditResponse>(returnValue);
      },
    }),
    getSurveyConsumption: builder.query<SurveyConsumptionResponse, { id: number }>({
      query: ({ id }) => ({ url: `ajax/surveys/${id}` }),
      providesTags: (_result, _errors, { id }) => [{ type: 'Survey', id }],
      transformResponse: (returnValue: SurveyConsumptionResponse) => {
        return toCamelCase<SurveyConsumptionResponse>(returnValue);
      },
    }),
    updateSurvey: builder.mutation<undefined, UpdateSurveyParams>({
      query: ({ id, ...params }: UpdateSurveyParams) => ({
        url: `ajax/surveys/${id}`,
        method: 'PUT',
        body: toSnakeCase(params),
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'Survey', id: arg.id },
        { type: 'Survey', id: 'LIST' },
        { type: 'Curriculum', id: 'LIST' },
        { type: 'CurriculumElement', id: `Element-${arg.id}` },
      ],
    }),
    getSmartTestModalData: builder.query<SmartTestModalDataResponse, SmartTestModalDataParams>({
      query: (params) => ({ url: `ajax/surveys/${params.id}/smart_test_modal_data`, params }),
      transformResponse: (returnValue: SmartTestModalDataResponse) => {
        return toCamelCase<SmartTestModalDataResponse>(returnValue);
      },
    }),
    getAiSurveyComposerModalData: builder.query<
      AiSurveyComposerModalResponse,
      AiSurveyComposerModalParams
    >({
      query: (params) => {
        return {
          url: `ajax/surveys/${params.id}/ai_survey_composer_modal_data?${queryString.stringify(
            toSnakeCase(params),
            {
              arrayFormat: 'bracket',
            }
          )}`,
        };
      },
      transformResponse: (returnValue: AiSurveyComposerModalResponse) => {
        return toCamelCase<AiSurveyComposerModalResponse>(returnValue);
      },
    }),
    composeQuestions: builder.mutation<ComposeQuestionsResponse, ComposeQuestionsParams>({
      query: ({ id, ...params }: ComposeQuestionsParams) => ({
        url: `ajax/surveys/${id}/compose_questions`,
        method: 'PUT',
        body: toSnakeCase(params),
      }),
      transformResponse: (returnValue: ComposeQuestionsResponse) => {
        return toCamelCase<ComposeQuestionsResponse>(returnValue);
      },
    }),
  }),
});

export const {
  useGetEditSurveyQuery,
  useGetSurveyConsumptionQuery,
  useUpdateSurveyMutation,
  useGetSmartTestModalDataQuery,
  useComposeQuestionsMutation,
} = surveysApi;
