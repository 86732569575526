import React from 'react';

import { FinchProvider } from '../../../../../types/IntegrationProvider';
import { Activity, ActivityAssociationParams } from '../types';
import CommonTemplate, { UserCommonTemplateProps } from './CommonTemplate';
import CommonTemplateWithDate, { UserCommonTemplateWithDateProps } from './CommonTemplateWithDate';
import FinchTemplate, { UserFinchTemplateProps } from './FinchTemplate';
import OverdueCurriculumsTemplate, {
  UserOverdueCurriculumsProps,
} from './OverdueCurriculumsTemplate';
import UpdateDateTemplate, { UserUpdateDateTemplateProps } from './UpdateDateTemplate';

interface UserActivitiesProps {
  'user.accept_invite': React.FC<UserCommonTemplateProps>;
  'user.activate': React.FC<UserCommonTemplateProps>;
  'user.added': React.FC<UserCommonTemplateProps>;
  'user.deactivate': React.FC<UserCommonTemplateProps>;
  'user.destroy': React.FC<UserCommonTemplateProps>;
  'user.due_date.overdue': React.FC<UserOverdueCurriculumsProps>;
  'user.due_date.upcoming': React.FC<UserOverdueCurriculumsProps>;
  'user.finch.need_to_reauthorize': React.FC<UserFinchTemplateProps>;
  'user.finch.provider_connected': React.FC<UserFinchTemplateProps>;
  'user.scheduled_archive': React.FC<UserCommonTemplateWithDateProps>;
  'user.scheduled_archive_update': React.FC<UserUpdateDateTemplateProps>;
  'user.scheduled_invite': React.FC<UserCommonTemplateWithDateProps>;
  'user.scheduled_invite_update': React.FC<UserUpdateDateTemplateProps>;
}

const ACTIVITY_KEYS: UserActivitiesProps = {
  'user.accept_invite': CommonTemplate,
  'user.activate': CommonTemplate,
  'user.added': CommonTemplate,
  'user.deactivate': CommonTemplate,
  'user.destroy': CommonTemplate,
  'user.due_date.overdue': OverdueCurriculumsTemplate,
  'user.due_date.upcoming': OverdueCurriculumsTemplate,
  'user.finch.need_to_reauthorize': FinchTemplate,
  'user.finch.provider_connected': FinchTemplate,
  'user.scheduled_archive': CommonTemplateWithDate,
  'user.scheduled_archive_update': UpdateDateTemplate,
  'user.scheduled_invite': CommonTemplateWithDate,
  'user.scheduled_invite_update': UpdateDateTemplate,
};

interface UsersActivity extends Omit<Activity, 'trackable' | 'parameters'> {
  parameters: {
    assignment_ids: number[];
    source: FinchProvider;
    curriculum_id: number;
    invite_now: boolean;
  };
  trackable: ActivityAssociationParams;
  productTerm: string;
}

interface UsersCardProps {
  activity: UsersActivity;
  productTerm?: string;
}

const UserActivitiesDetails = ({ activity, productTerm }: UsersCardProps) => {
  const ActivityMessage = ACTIVITY_KEYS[activity.key as keyof UserActivitiesProps];

  return (
    <ActivityMessage
      activityKey={activity.key}
      parameters={activity.parameters}
      productTerm={productTerm}
      trackable={activity.trackable}
    />
  );
};

export default UserActivitiesDetails;
