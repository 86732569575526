import React from 'react';
import styled from 'styled-components';

import { useAccountTerminology } from '../../../../../../components/AccountTerminologyProvider';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import PageHeader from '../../../../../design_system/Triage/headers/PageHeader';

const t = initTranslations('per_user_pricing.billing_page.header');

const BillingPageHeaderWrapper = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const BillingPageHeader = () => {
  const { status: accountStatus } = useCurrentAccount();
  const { productTerm } = useAccountTerminology();
  const statusKey =
    accountStatus === 'trialing' || accountStatus === 'trial_ended' ? accountStatus : 'other';
  const subtitle = accountStatus === 'trialing' ? '' : t(`${statusKey}.subtitle`, { productTerm });

  return (
    <BillingPageHeaderWrapper id='billing-page-header'>
      <PageHeader subtitle={subtitle} title={t(`${statusKey}.title`)} />
    </BillingPageHeaderWrapper>
  );
};

export default BillingPageHeader;
