import {
  BillingPlanName,
  PerUserBillingPlanName,
  billingPlanTypes,
} from '../../types/BillingPlanName';
import useCurrentAccount from '../useCurrentAccount';
import { useGetTrialDefaultPlan } from './useGetTrialDefaultPlan';

export function useGetCastedAccountPlan(): BillingPlanName | PerUserBillingPlanName {
  const {
    accountPlan,
    onSmallPlan,
    onMediumPlan,
    onGrowthPlan,
    onCustomPlan,
    status,
    splitFeatures: { simplePricingEnabled, simplePricingV3Enabled },
  } = useCurrentAccount();
  const isTrialingOrTrialEnded = status === 'trialing' || status === 'trial_ended';
  const trialDefaultPlan = useGetTrialDefaultPlan();

  function getCastedAccountPlan() {
    if (simplePricingV3Enabled) {
      return isTrialingOrTrialEnded ? trialDefaultPlan : verifyUpgradePlan();
    }

    if (simplePricingEnabled) {
      return 'growth';
    }

    if (accountPlan && billingPlanTypes.includes(accountPlan as PerUserBillingPlanName)) {
      return accountPlan;
    }

    return 'scale';
  }

  // Need to make sure that we push users to a higher plan than their current plan
  // We have a DEFAULT_SIMPLE_PRICING_STRIPE_PLAN_ID env variable and we need to set a higher plan than we define in this variable
  function verifyUpgradePlan() {
    if (onSmallPlan) {
      return 'medium';
    }

    if (onMediumPlan || onGrowthPlan) {
      return 'growth';
    }

    if (onCustomPlan) {
      return 'custom';
    }

    return 'growth';
  }

  return getCastedAccountPlan();
}
