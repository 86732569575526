import React, { useContext } from 'react';

import { AccountRoutesContext } from '../../../../../contexts/AccountRoutesContext';
import initTranslations from '../../../../../lib/initTranslations';
import { AccountTerminologyContext } from '../../../../AccountTerminologyProvider';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { TextFragment } from '../styles';
import { StepActivity } from './StepActivitiesDetails';

const t = initTranslations('reports.activity_log');

export type StepCommonTemplateTemplateProps = Pick<StepActivity, 'trackable' | 'parameters'> & {
  activityKey: string;
};

const CommonTemplate = ({
  activityKey,
  trackable,
  parameters,
}: StepCommonTemplateTemplateProps) => {
  const routes = useContext(AccountRoutesContext);
  const { course_id, course_title, course_active } = parameters;
  const { topic } = useContext(AccountTerminologyContext);

  return (
    <>
      <TextFragment>{t(activityKey)}</TextFragment>
      <b>{trackable.name}</b>
      <TextFragment>{t('step.in_course', { course: topic.singular.toLowerCase() })}</TextFragment>
      {course_active ? (
        <SourceBadge
          sourceHref={routes.view.courseEditor({ courseId: course_id })}
          sourceName='curriculum'
          sourceText={course_title}
        />
      ) : (
        <b>{course_title}</b>
      )}
    </>
  );
};

export default CommonTemplate;
