import styled from 'styled-components';

import { mediaBreakpointPxLg } from '../../../../../../styled/Breakpoint';

export const PlansCardTabsWrapper = styled.nav`
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerMd2}`};
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};

  @media (min-width: ${mediaBreakpointPxLg}) {
    padding: ${({ theme: { constants } }) => `0 ${constants.spacerLg2}`};
  }
`;

export const StyledTabs = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  height: 4rem;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
`;
