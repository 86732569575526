import initTranslations from '../../../../../../../lib/initTranslations';
import { SendInviteOption } from '../../../InterfaceAndTypes';

const t = initTranslations('teammate_modal.confirmation_modal');

export const getTeammateConfirmationModalData = (
  sendInvite: SendInviteOption | undefined,
  perUserPricingEnabled: boolean,
  simplePricingEnabled: boolean,
  userName: string
) => {
  if (sendInvite === 'now' && !perUserPricingEnabled) {
    return {
      title: t('title_for_invited'),
      content: t('message_for_invite_sent'),
    };
  } else if (sendInvite === 'scheduled' && !perUserPricingEnabled) {
    return {
      title: t('title_for_scheduled_invite'),
      content: t('message_for_scheduled_invite'),
    };
  } else if (sendInvite == 'later' && !perUserPricingEnabled) {
    return {
      title: t('title_for_added'),
      content: t('message_for_invite_not_sent'),
    };
  } else if (simplePricingEnabled) {
    return {
      title: t('per_user_pricing.title_for_added'),
      content: {
        firsPartMessageForAdded: t('simple_pricing.message_for_added_1', { name: userName }),
        link: t('per_user_pricing.manage_people_link'),
        secondPartMessageForAdded: t('simple_pricing.message_for_added_2'),
      },
    };
  } else {
    return {
      title: t('per_user_pricing.title_for_added'),
      content: {
        firsPartMessageForAdded: t('per_user_pricing.message_for_added_1', { name: userName }),
        link: t('per_user_pricing.manage_people_link'),
        secondPartMessageForAdded: t('per_user_pricing.message_for_added_2'),
      },
    };
  }
};
