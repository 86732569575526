import { TCurriculumAssignee } from '../../../../types/Curriculum';
import { Emoji } from '../../../../types/Emoji';

export enum TypeName {
  Step = 'Step',
  Course = 'Course',
  Curriculum = 'Curriculum',
}

export enum ExpandedEntityKeys {
  Curriculum = 'expanded_courses',
  Course = 'expanded_steps',
}

export enum Status {
  Draft = 'draft',
  PendingReview = 'pending_review',
  Finished = 'finished',
}

type BaseItem = {
  [key in ExpandedEntityKeys]?: (Course | Step)[];
} & {
  id: number;
  title: string;
  title_highlighted: string;
  emoji?: Emoji;
  __typename: TypeName;
};

export interface Step extends BaseItem {
  __typename: TypeName.Step;
}

export interface Course extends BaseItem {
  status: Status;
  steps_count?: number;
  __typename: TypeName.Course;
}

export interface Curriculum extends BaseItem {
  published: boolean;
  courses_count?: number;
  __typename: TypeName.Curriculum;
}

export interface ContentFlyoutProps {
  contentAssigneeType: TCurriculumAssignee;
  contentAssigneeId: number;
  previouslySelectedItems?: SelectedItem[];
  onCancel: () => void;
  onSubmit: (selectedItems: SelectedItem[]) => void;
}

export interface SelectedItem {
  id: number;
  title: string;
  __typename: TypeName;
  _destroy?: boolean;
}

export interface ContentBadgeProps {
  typeName: TypeName;
}
