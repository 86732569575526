import React from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import initTranslations from '../../../../../lib/initTranslations';
import { Group } from '../../../../../types/Group';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { routes } from '../../../publicApplication/applicationRouter';

const GroupPillWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  padding-top: ${({ theme: { constants } }) => constants.spacerSm3};
`;

type ProfileGroupPillsProps = {
  groups: Group[];
};

const ProfileGroupPills = ({ groups }: ProfileGroupPillsProps) => {
  const ability = useCurrentUserAbilities();
  const { onHoldPlan, slug, status: accountStatus } = useCurrentAccount();
  const isTrialEndedOrOnHold = accountStatus === 'trial_ended' || onHoldPlan;
  const groupClickable = ability.can('create', 'Group') && !isTrialEndedOrOnHold;

  return (
    <GroupPillWrapper id='groups-wrapper'>
      {groups.map((group) => (
        <SourceBadge
          key={`group-pill-${group.id}`}
          readOnly={!groupClickable}
          sourceName='group'
          sourceRoute={routes.group({
            slug,
            id: group.id,
            breadcrumb: routes.groups({ slug }),
            tab: 'overview',
          })}
          sourceSubtext={`(${initTranslations('groups.kinds')(group.kind)})`}
          sourceText={group.name}
        />
      ))}
    </GroupPillWrapper>
  );
};

export default ProfileGroupPills;
