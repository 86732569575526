import React from 'react';

import initTranslations from '../../../../lib/initTranslations';
import { RestrictedBulkUser } from '../../../../redux/services/resourceApis/users/types';
import { SimpleUser, User } from '../../../../types/User';
import { AvatarSize } from '../../../design_system/display/avatar/AvatarTypes';
import { AvatarItem, AvatarsGroupWrapper, MoreLabel, StyledAvatar } from './styles';

export interface AvatarsGroupProps {
  className?: string;
  users: Array<User | SimpleUser | RestrictedBulkUser>;
  totalUserCount: number;
  i18Key: string;
  avatarSize?: AvatarSize;
  withTotalUserCountBorder?: boolean;
  clickMoreUsersHandler?: () => void;
}

const AvatarsGroup = ({
  className,
  users,
  totalUserCount,
  i18Key,
  avatarSize = 'sm',
  withTotalUserCountBorder = false,
  clickMoreUsersHandler,
}: AvatarsGroupProps) => {
  const t = initTranslations(i18Key);
  const showMoreUsersLabel = totalUserCount > users.length;

  if (totalUserCount === 0) {
    return <span>{t('no_users_added')}</span>;
  }

  return (
    <AvatarsGroupWrapper className={className}>
      {users.map(({ id, name, avatar }, i) => (
        <AvatarItem key={`${id}-${i}`}>
          <StyledAvatar image={avatar} name={name} shape='circle' size={avatarSize} />
        </AvatarItem>
      ))}
      {showMoreUsersLabel && (
        <MoreLabel
          align='center'
          totalUserCount={totalUserCount - users.length}
          withTotalUserCountBorder={withTotalUserCountBorder}
          {...(clickMoreUsersHandler && { onClick: clickMoreUsersHandler })}
        >
          +{totalUserCount - users.length}
        </MoreLabel>
      )}
    </AvatarsGroupWrapper>
  );
};

export default AvatarsGroup;
