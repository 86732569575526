import { ChatMessage } from '../../../../redux/services/resourceApis/openAI/types';
import { SurveyEditResponse } from '../../../../redux/services/resourceApis/surveys/types';

const DESCRIPTION_PROMPT_MESSAGES: ChatMessage[] = [
  {
    role: 'system',
    content:
      'You are an AI writing assistant tasked with creating descriptions for tests within our organization. ' +
      'These tests, covering various subjects, are crucial for assessing knowledge and skills in our internal training and evaluation programs. ' +
      'Your role involves writing concise, informative descriptions based on provided test title, subject title optionally accompanied by a table of contents',
  },
  {
    role: 'user',
    content:
      'Please generate a description for a test based on its title, the title of subject, and optionally, its table of contents. ' +
      "The description should be in a single paragraph, summarizing the test's scope, its significance, and key areas it covers. " +
      'This description is aimed at employees who are considering taking the test for skill assessment or learning progress evaluation.',
  },
  {
    role: 'assistant',
    content:
      'Could you please provide the test title and subject title, along with the test questions?',
  },
];

export const getSurveyDescriptionPromptMessages = (
  survey: SurveyEditResponse | undefined
): ChatMessage[] => {
  const surveyQuestions = survey?.questions.map((question) => question.plainText);
  const formattedQuestionsList = surveyQuestions
    ? `\nQuestions list:\n${surveyQuestions.join('\n')}`
    : '';

  return [
    ...DESCRIPTION_PROMPT_MESSAGES,
    {
      role: 'user',
      content: `Please generate a compact description(about 20 - 40 words) for the test titled "${survey?.name}", subject title "${survey?.curriculum.title}". ${formattedQuestionsList}`,
    },
  ];
};
