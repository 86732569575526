import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import NotificationUserName from '../../notifications/NotificationUserName/NotificationUserName';
import { TextFragment } from '../styles';
import { ActivityAssociationParams } from '../types';

const t = initTranslations('reports.activity_log');

export interface UserCommonTemplateProps {
  activityKey: string;
  trackable: ActivityAssociationParams;
  parameters?: { invite_now: boolean };
  productTerm?: string;
}

const CommonTemplate = ({
  trackable,
  activityKey,
  parameters,
  productTerm,
}: UserCommonTemplateProps) => {
  return (
    <>
      <TextFragment>
        {activityKey === 'user.added' && parameters?.invite_now === true
          ? t('user.invite', { productTerm })
          : t(activityKey, { productTerm })}
      </TextFragment>
      {activityKey !== 'user.accept_invite' && (
        <NotificationUserName
          user={{
            is_active: trackable.is_active,
            id: trackable.id,
            name: trackable.name,
          }}
        />
      )}
    </>
  );
};

export default CommonTemplate;
