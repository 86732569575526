import React from 'react';
import { MenuListProps, components } from 'react-select';

import AddTeammateMenuListButton from '../AddTeammateMenuListButton';

const AddTeammateMenuList = (props: MenuListProps) => (
  <components.MenuList {...props}>
    {props.children}
    <AddTeammateMenuListButton />
  </components.MenuList>
);

export default AddTeammateMenuList;
