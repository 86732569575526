import React from 'react';

import useSlideout from '../../../../../../hooks/useSlideout';
import initTranslations from '../../../../../../lib/initTranslations';
import { setAllUsersReportSearchTerm } from '../../../../../../redux/domains/reports/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import SearchField from '../../../../../design_system/Triage/fields/SearchField';
import {
  ActionButtons,
  ActionsHeaderContainer,
  ElementsCount,
  RightAlignedHeaderActions,
  SearchFieldWrapper,
} from './styles';
import { TableActionsHeaderProps } from './types';
import UsersDownloadCsvReport from './UsersDownloadCsvReport';

const t = initTranslations('reports.actions_header');

const TableActionsHeader = ({
  isFetching,
  sortColumn,
  sortDirection,
  totalUsersCount,
}: TableActionsHeaderProps) => {
  const dispatch = useAppDispatch();
  const { appliedFiltersCount, searchTerm } = useAppSelector(
    (state) => state.reports.allUsersReport
  );

  const { close, open, isOpen } = useSlideout('all-users-filter-slideout');

  const toggleFilters = () => {
    isOpen ? close() : open();
  };

  return (
    <ActionsHeaderContainer className='users-outline-table-action-header'>
      <ElementsCount>{t('users_count', { count: totalUsersCount })}</ElementsCount>
      <RightAlignedHeaderActions>
        <SearchFieldWrapper>
          <SearchField
            dataLoading={isFetching}
            placeholder={t('search_placeholder')}
            searchValue={searchTerm}
            setSearchValue={(value: string) => dispatch(setAllUsersReportSearchTerm(value))}
          />
        </SearchFieldWrapper>
        <ActionButtons>
          <DefaultButton
            buttonType='secondary'
            iconName='sliders'
            iconWeight='solid'
            id='all-users-report-filter-btn'
            onClick={toggleFilters}
            size='md'
            text={t('filters', { count: appliedFiltersCount })}
          />
          <UsersDownloadCsvReport sortColumn={sortColumn} sortDirection={sortDirection} />
        </ActionButtons>
      </RightAlignedHeaderActions>
    </ActionsHeaderContainer>
  );
};

export default TableActionsHeader;
