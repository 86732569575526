import React, { useContext } from 'react';

import { AccountRoutesContext } from '../../../../../../../contexts/AccountRoutesContext';
import Icon from '../../../../../../design_system/display/icons/Icon';
import { SignedDocumentDownloadLink } from '../../Columns/styles';
import { TableColumnProps } from '../types';

const Actions = ({ record: { documentId } }: TableColumnProps) => {
  const routes = useContext(AccountRoutesContext);

  return (
    <>
      {documentId && (
        <SignedDocumentDownloadLink
          href={routes.view.downloadSignedDocument({ documentId })}
          rel='noreferrer'
          target='_blank'
        >
          <Icon name='arrow-down-to-line' size='md' weight='regular' />
        </SignedDocumentDownloadLink>
      )}
    </>
  );
};

export default Actions;
