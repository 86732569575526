import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Poppable, { Modifiers } from '../../../Poppable';
import { fontSm5 } from '../../../styled/TypeSystem';

const TooltipWrapper = styled.div`
  position: relative;
`;

const PaywallTooltipContainer = styled.div<{ position: Position }>`
  z-index: 2;
  padding: ${({ theme }) => theme.constants.spacerSm3};
  border-radius: ${(props) => props.theme.constants.borderRadiusMd};
  background-color: ${({ theme }) => theme.vars.foundationSurface2};
  color: ${({ theme }) => theme.vars.foundationSurface1};
  position: absolute;
  min-width: 18rem;
  bottom: ${({ theme: { constants }, position }) =>
    position === 'top' ? constants.spacerSm3 : 'inherit'};
  top: ${({ theme: { constants }, position }) =>
    position === 'bottom' ? constants.spacerLg1 : 'inherit'};
  right: 0;
  ${fontSm5};
`;

const TooltipHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.constants.spacerSm3};

  > strong {
    color: ${({ theme }) => theme.vars.foundationSurface1};
  }
`;

type Position = 'bottom' | 'top';

interface Props {
  description: string;
  title: string;
  shouldBeVisible: boolean;
  modifiers?: Modifiers;
  position?: Position;
}

const PaywallTooltip = ({
  description,
  title,
  shouldBeVisible,
  position = 'top',
  modifiers,
}: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (shouldBeVisible) {
      timer = setTimeout(() => {
        setIsVisible(true);
      }, 50);
    } else {
      setIsVisible(false);
    }

    return () => clearTimeout(timer);
  }, [shouldBeVisible]);

  return (
    <Poppable
      isOpen={isVisible}
      item={
        <TooltipWrapper>
          <PaywallTooltipContainer id='paywall-tooltip' position={position}>
            <TooltipHeader>
              <strong>{title}</strong>
            </TooltipHeader>
            {description}
          </PaywallTooltipContainer>
        </TooltipWrapper>
      }
      modifiers={modifiers}
      onClick={() => ({})}
      placement='top'
      strategy='fixed'
      trigger={<></>}
    />
  );
};

export default PaywallTooltip;
