import { htmlEncode } from '../../../../lib/htmlEncode';
import { EmbedSizes } from '../tiptap_extensions/helpers/parseEmbedlySize';

type Props = {
  embedSizeClass?: EmbedSizes;
  jsonString: string;
  html: string;
  originalUrl: string | undefined;
};

export const htmlEncodedContent = ({
  embedSizeClass = 'large-embed',
  jsonString,
  html,
  originalUrl,
}: Props) => {
  return `<div class="${embedSizeClass}" data-original-result="${htmlEncode(
    jsonString
  )}" data-original-url="${originalUrl}">${html}</div>`;
};
