import styled from 'styled-components';

import P from '../../../../../../design_system/text/P';
import { fontMd1, fontSm4 } from '../../../../../../styled/TypeSystem';
import { TertiaryButton } from '../../shared/styles';

export const BillingInfoEmptyStateWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} 0`};
`;

export const BillingInfoEmptyStateTitle = styled(P)`
  padding-top: ${({ theme: { constants } }) => constants.spacerSm3};
  padding-bottom: ${({ theme: { constants } }) => constants.spacerSm1};
  text-align: center;
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontMd1};
`;

export const BillingInfoEmptyStateSubtitle = styled(P)`
  text-align: center;
  ${fontSm4};
`;

export const BillingInfoEmptyStateImage = styled.img`
  width: 8.625rem;
  height: 5.375rem;
`;

export const BillingInfoEmptyStateCta = styled(TertiaryButton)`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;
