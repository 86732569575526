import { isEqual } from 'lodash';
import React, { useContext, useEffect, useMemo, useReducer } from 'react';

import { PaywallContext } from '../../../../../contexts/PaywallContext';
import initTranslations from '../../../../../lib/initTranslations';
import { toSnakeCase } from '../../../../../lib/keyFormatConverter';
import {
  IndividualUserReportPageFilterState,
  setIndividualUserReportFilters,
} from '../../../../../redux/domains/reports/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useGetIndividualUserReportTableFiltersDataQuery } from '../../../../../redux/services/resourceApis/reports/reportsApi';
import { groupsToOptions } from '../../../../../types/Group';
import Badge from '../../../../design_system/display/badge';
import Checkbox from '../../../../design_system/input/controls/Checkbox';
import MultiSelectField from '../../../../design_system/Triage/fields/MultiSelectField';
import CollapsibleFiltersSection from '../../../shared/CollapsibleFiltersSection/CollapsibleFiltersSection';
import CompletionScoreSection from '../../CompletionScoreSection/CompletionScoreSection';
import ReportFiltersSlideout from '../../ReportFiltersSlideout/ReportFiltersSlideout';
import { filterReducer } from './reducer';
import { CheckboxWrapper } from './styles';

const t = initTranslations('reports.user_report_filters');

const IndividualUserReportFilters = ({ userId }: { userId: number }) => {
  const paywallCtx = useContext(PaywallContext);
  const isSignaturePaywalled = paywallCtx.includes('e_signature');
  const dispatch = useAppDispatch();
  const { filters: appliedFilters } = useAppSelector((state) => state.reports.individualUserReport);

  const initialFilterState: IndividualUserReportPageFilterState = useMemo(
    () => ({
      completionScoreMin: 0,
      completionScoreMax: 100,
      eSignature: [],
      groupIds: [],
      teamIds: [],
      roleIds: [],
      tests: [],
      statuses: [],
    }),
    []
  );

  const STATUSES = ['not_started', 'in_progress', 'completed', 'overdue'];

  const [filterState, dispatchState] = useReducer(filterReducer, initialFilterState);
  const {
    completionScoreMax,
    completionScoreMin,
    groupIds,
    teamIds,
    roleIds,
    tests,
    eSignature,
    statuses,
  } = filterState;

  const appliedFiltersCount =
    groupIds.length +
    tests.length +
    eSignature.length +
    statuses.length +
    (completionScoreMin > 0 || completionScoreMax < 100 ? 1 : 0);

  const noAppliedFilters = appliedFiltersCount === 0;
  const isDisabledApplyBtn = isEqual(appliedFilters, filterState) && noAppliedFilters;

  const handleCheckboxes = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string[],
    stateName: 'statuses' | 'eSignature' | 'tests'
  ) => {
    let updatedList = [...value];
    if (event.target.checked) {
      updatedList = [...value, event.target.name];
    } else {
      updatedList.splice(value.indexOf(event.target.name), 1);
    }
    switch (stateName) {
      case 'eSignature':
        return dispatchState({
          type: 'setSignature',
          eSignature: updatedList,
        });
      case 'tests':
        return dispatchState({
          type: 'setTests',
          tests: updatedList,
        });
      case 'statuses':
        return dispatchState({
          type: 'setStatuses',
          statuses: updatedList,
        });
    }
  };

  const statusLabel = (status: string) => {
    switch (status) {
      case 'not_started':
        return <Badge text={t(`statuses.${status}`)} type='general' />;
      case 'in_progress':
        return <Badge text={t(`statuses.${status}`)} type='info' />;
      case 'completed':
        return <Badge text={t(`statuses.${status}`)} type='success' />;
      case 'overdue':
        return <Badge text={t(`statuses.${status}`)} type='error' />;
    }
  };

  const clearFilters = () => {
    dispatch(setIndividualUserReportFilters(initialFilterState));
    dispatchState({ type: 'setDefaultFilters' });
  };

  const applyFilters = () => {
    dispatch(
      setIndividualUserReportFilters({
        groupIds,
        eSignature,
        teamIds,
        roleIds,
        tests,
        completionScoreMax,
        completionScoreMin,
        statuses,
      })
    );
  };

  useEffect(() => {
    dispatch(setIndividualUserReportFilters(initialFilterState));
  }, [dispatch, initialFilterState]);

  const { data } = useGetIndividualUserReportTableFiltersDataQuery({ id: userId });

  if (!data) return <></>;

  const { curriculumsCompletionData, groups, testsCount, eSignatureCount } = data;

  const teams = groups.filter(({ kind }) => kind === 'team');
  const roles = groups.filter(({ kind }) => kind === 'role');

  const completionData = Object.entries(curriculumsCompletionData).map((item) => ({
    name: item[0],
    count: item[1],
  }));

  return (
    <ReportFiltersSlideout
      applyFilters={applyFilters}
      clearFilters={clearFilters}
      disabledApplyButton={isDisabledApplyBtn}
      disabledClearButton={noAppliedFilters}
      slideoutType='individual-user-report-filter-slideout'
      title={t('title')}
    >
      <CollapsibleFiltersSection title={t('statuses.title')}>
        {STATUSES.map((status) => (
          <CheckboxWrapper key={status}>
            <Checkbox
              checked={statuses.includes(status)}
              id={`curriculum-status-${status}`}
              label={statusLabel(status)}
              name={status}
              onCheck={(e) => handleCheckboxes(e, statuses, 'statuses')}
            />
          </CheckboxWrapper>
        ))}
      </CollapsibleFiltersSection>
      <CompletionScoreSection
        completionScoreMax={completionScoreMax}
        completionScoreMin={completionScoreMin}
        data={completionData}
        setMaxCompletionScore={(values) => {
          dispatchState({
            type: 'setMaxCompletionScore',
            completionScoreMax: values,
          });
        }}
        setMinCompletionScore={(values) => {
          dispatchState({
            type: 'setMinCompletionScore',
            completionScoreMin: values,
          });
        }}
      />

      {!isSignaturePaywalled && (
        <CollapsibleFiltersSection title={t('e_signature.title')}>
          {Object.entries(toSnakeCase(eSignatureCount)).map(([key, count]) => (
            <Checkbox
              checked={eSignature.includes(key)}
              disabled={count === 0}
              id={`curriculum-e-signature-${key}`}
              key={key}
              label={t(`e_signature.${key}`, { count })}
              name={key}
              onCheck={(e) => handleCheckboxes(e, eSignature, 'eSignature')}
            />
          ))}
        </CollapsibleFiltersSection>
      )}

      <CollapsibleFiltersSection title={t('tests.title')}>
        {Object.entries(toSnakeCase(testsCount)).map(([key, count]) => (
          <Checkbox
            checked={tests.includes(key)}
            disabled={count === 0}
            id={`curriculum-tests-${key}`}
            key={key}
            label={t(`tests.${key}`, {
              count,
            })}
            name={key}
            onCheck={(e) => handleCheckboxes(e, tests, 'tests')}
          />
        ))}
      </CollapsibleFiltersSection>
      <CollapsibleFiltersSection title={t('group')}>
        <MultiSelectField
          defaultValue={[]}
          onNonDefaultSelected={(value: string[]) => {
            dispatchState({ type: 'setGroups', groupIds: value });
            const teamIds = teams.map(({ id }) => `${id}`);
            const roleIds = roles.map(({ id }) => `${id}`);
            const newTeamIds = value.filter((id) => teamIds.includes(id));
            const newRoleIds = value.filter((id) => roleIds.includes(id));
            dispatchState({ type: 'setRoles', roleIds: newRoleIds });
            dispatchState({ type: 'setTeams', teamIds: newTeamIds });
          }}
          options={groupsToOptions(groups)}
          placeholder={t('group_placeholder')}
          value={groupIds}
        />
      </CollapsibleFiltersSection>
    </ReportFiltersSlideout>
  );
};

export default IndividualUserReportFilters;
