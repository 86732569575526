import React, { FC } from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import EmptyState from '../../../../DelegationPlanner/modals/ResponsibilityDetailsModal/ResponsibilityLinkedContent/EmptyState/EmptyState';
import { extractDomainName } from '../../../../DelegationPlanner/modals/utils';
import ContentBadge from '../../../../shared/ContentFlyout/ContentBadge/ContentBadge';
import { SectionWrapper } from '../styles';
import {
  ConnectedContentWrapper,
  IconWrapper,
  StyledIcon,
  StyledParagraph,
  StyledTitle,
} from './styles';
import { ResponsibilityLinkedContentProps } from './types';

const t = initTranslations('delegation_planner.modals.responsibility_details_modal.linked_content');

const ResponsibilityLinkedContent: FC<ResponsibilityLinkedContentProps> = ({ contentUrls }) => {
  const isContentUrlsEmpty = contentUrls.length === 0;

  return (
    <SectionWrapper>
      <StyledTitle>{t('connected_content')}</StyledTitle>
      {isContentUrlsEmpty ? (
        <EmptyState title={t('view_only_empty_state')} />
      ) : (
        <>
          {contentUrls.map(({ contentableType, title, url, id }) => (
            <ConnectedContentWrapper href={url} key={id} rel='noopener noreferrer' target='_blank'>
              <IconWrapper>
                {!!contentableType ? (
                  <ContentBadge typeName={contentableType} />
                ) : (
                  <StyledIcon name='link-simple' size='sm' />
                )}
              </IconWrapper>
              <StyledParagraph>{title || extractDomainName(url) || ''}</StyledParagraph>
            </ConnectedContentWrapper>
          ))}
        </>
      )}
    </SectionWrapper>
  );
};

export default ResponsibilityLinkedContent;
