import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import ProgressBar from '../../../../design_system/Triage/ProgressBar';
import { ProgressBarWrapper, ProgressPercentage, UploadStatusText } from './styles';

const t = initTranslations('document_uploads.upload_progress_item');

const ProcessingIndicator = ({ percentage }: { percentage: number }) => {
  return (
    <>
      <UploadStatusText>{t('processing')}</UploadStatusText>
      <ProgressBarWrapper>
        <ProgressBar percent={percentage} thickness='lg' />
      </ProgressBarWrapper>
      <ProgressPercentage>{percentage}%</ProgressPercentage>
    </>
  );
};

export default ProcessingIndicator;
