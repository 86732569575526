import React from 'react';

import { usePlanBrandColors } from '../../../../../../../hooks/billing/usePlanBrandColors';
import { usePlanTitle } from '../../../../../../../hooks/billing/usePlanTitle';
import useCurrentAccount from '../../../../../../../hooks/useCurrentAccount';
import { couponToDiscount } from '../../../../../../../lib/billing/stripeCouponToDiscount';
import {
  getFormattedPrice,
  getFormattedPricePerUser,
} from '../../../../../../../lib/getFormattedPrice';
import initTranslations from '../../../../../../../lib/initTranslations';
import { FetchCurrentPlanCardDataResponse } from '../../../../../../../redux/services/resourceApis/billing/perUserPricingTypes';
import { SpaceBetweenWrapper } from '../../../../shared/styles';
import ESignatureSubtitle from '../ESignatureSubtitle/ESignatureSubtitle';
import LegacyPlanInfoIcon from '../LegacyPlanInfoIcon/LegacyPlanInfoIcon';
import {
  PerBillingPeriodText,
  PerSeatText,
  PlanCardTitleWrapper,
  PlanPrice,
  PlanPricePerSeat,
  PlanPriceWrapper,
  PlanText,
  StyledPlanCardTitle,
} from '../styles';

const t = initTranslations('per_user_pricing.billing_page.plan_card');

type PlanCardTitleProps = {
  isTrialEnded: boolean;
  planCardData: FetchCurrentPlanCardDataResponse;
  onLegacyPlan: boolean;
  onHoldPlan: boolean;
  simplePricingEnabled: boolean;
};

const PlanCardTitle = ({
  isTrialEnded,
  planCardData,
  onLegacyPlan,
  onHoldPlan,
  simplePricingEnabled,
}: PlanCardTitleProps) => {
  const { totalUserSlotsCount, onFree3SeatsPlan } = useCurrentAccount();
  const { productName, billingInterval, planPrice, eSignatureSubscriptionInfo, coupon } =
    planCardData;
  const isAnnualInterval = billingInterval === 'year';
  const showPlanPricePerSeat = !onHoldPlan && !onLegacyPlan && !simplePricingEnabled;
  const planTitle = usePlanTitle({ name: productName });
  const { color: planBrandColor } = usePlanBrandColors({
    name: productName,
  });
  const discount = couponToDiscount(coupon);
  const showPlanPrice = planPrice > 0 || onFree3SeatsPlan;

  const getCardTitle = () => {
    return (
      <>
        {planTitle}
        <PlanText>
          {t('plan')} {isTrialEnded && t('trial')}
        </PlanText>
      </>
    );
  };

  return (
    <>
      <SpaceBetweenWrapper>
        <PlanCardTitleWrapper>
          <StyledPlanCardTitle planBrandColor={planBrandColor} text={getCardTitle()} />
          {onLegacyPlan && <LegacyPlanInfoIcon />}
        </PlanCardTitleWrapper>
        {showPlanPrice && (
          <PlanPriceWrapper>
            {onFree3SeatsPlan ? (
              <PlanPrice>{getFormattedPrice(planPrice)}</PlanPrice>
            ) : (
              <>
                <PlanPrice strike={!!discount}>{getFormattedPrice(planPrice)}</PlanPrice>
                {discount && <PlanPrice>{getFormattedPrice(planPrice, discount)}</PlanPrice>}
              </>
            )}
            <PerBillingPeriodText>
              {isAnnualInterval ? t('per_year') : t('per_month')}
            </PerBillingPeriodText>
          </PlanPriceWrapper>
        )}
      </SpaceBetweenWrapper>
      <SpaceBetweenWrapper>
        {!onHoldPlan && eSignatureSubscriptionInfo && (
          <ESignatureSubtitle eSignatureSubscriptionInfo={eSignatureSubscriptionInfo} />
        )}
        {showPlanPricePerSeat && (
          <PlanPriceWrapper>
            <PlanPricePerSeat strike={!!discount}>
              {getFormattedPricePerUser(planPrice, totalUserSlotsCount, isAnnualInterval)}
            </PlanPricePerSeat>
            {discount && (
              <PlanPricePerSeat>
                {getFormattedPricePerUser(
                  planPrice,
                  totalUserSlotsCount,
                  isAnnualInterval,
                  discount
                )}
              </PlanPricePerSeat>
            )}
            <PerSeatText>{t('seat_per_month')}</PerSeatText>
          </PlanPriceWrapper>
        )}
      </SpaceBetweenWrapper>
    </>
  );
};

export default PlanCardTitle;
