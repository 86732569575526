import React from 'react';
import styled from 'styled-components';

import useWindowResize from '../../../../../hooks/useWindowResize';
import { HeapModalAltActionId } from '../../../../../types/TaskTracking';
import PaywallButton from '../../../../application/curriculums/public_share/PaywallButton';
import { mediaBreakpointSm } from '../../../../styled/Breakpoint';
import DefaultButton from '../../../buttons/DefaultButton';
import Tooltip from '../../../display/Tooltip/Tooltip';
import { ControlState, TertiaryButton } from './TaskModalTypes';

const TertiaryButtonWrapper = styled.div`
  width: fit-content;
`;

type TertiaryButtonControlProps = TertiaryButton &
  ControlState & {
    heapModalActionId: HeapModalAltActionId;
  };

const TertiaryButtonControl = ({
  heapModalActionId,
  icon,
  isDisabled = false,
  paywallDescription,
  paywallTitle,
  processing = false,
  task,
  text,
  tooltipId,
  tooltipText,
}: TertiaryButtonControlProps) => {
  const { width } = useWindowResize();

  if (paywallDescription && paywallTitle && isDisabled) {
    return (
      <PaywallButton
        description={paywallDescription}
        iconName='lock'
        link=''
        size='md'
        title={paywallTitle}
      />
    );
  }

  return (
    <TertiaryButtonWrapper>
      {tooltipText && tooltipId && <Tooltip id={tooltipId} text={tooltipText} />}
      <div data-for={tooltipId} data-tip>
        <DefaultButton
          buttonType='tertiary'
          className={heapModalActionId}
          disabled={isDisabled}
          fullWidth={width < mediaBreakpointSm}
          id={heapModalActionId}
          onClick={() => !processing && task()}
          size='md'
          text={text}
          {...icon}
        />
      </div>
    </TertiaryButtonWrapper>
  );
};

export default TertiaryButtonControl;
