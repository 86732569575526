import React from 'react';

import { useSuperShare } from '../../../../../contexts/SuperShareContext';
import initTranslations from '../../../../../lib/initTranslations';
import {
  CurriculumAssignmentAction,
  GroupCurriculumAssignment,
  UserCurriculumAssignment,
} from '../../../../../redux/services/resourceApis/curriculumAssignments/types';
import DetailedAvatar from '../../../../design_system/display/DetailedAvatar/DetailedAvatar';
import { getBadge } from '../shared/functions';
import { SharedWithRowProps } from './SharedWithRow';

const t = initTranslations('supershare.detailed_avatar');

type UserDetailedAvatarProps = {
  action: CurriculumAssignmentAction;
} & Pick<UserCurriculumAssignment, 'curriculumAssignable' | 'permission'>;

const UserDetailedAvatar = ({
  permission: assignmentPermission,
  curriculumAssignable,
  action,
}: UserDetailedAvatarProps) => {
  const { id, name, avatar, permission: userPermission } = curriculumAssignable;

  return (
    <DetailedAvatar
      avatarImage={avatar}
      extendedLabel={assignmentPermission === 'owner' ? t('owner') : undefined}
      id={`user-detailed-avatar-${id}`}
      label={name}
      subLabel={`${initTranslations('users.permissions')(userPermission)}`}
      {...getBadge(action === 'add', t('new_badge'))}
    />
  );
};

type GroupDetailedAvatarProps = {
  action: CurriculumAssignmentAction;
} & Pick<GroupCurriculumAssignment, 'curriculumAssignable'>;

const GroupDetailedAvatar = ({ curriculumAssignable, action }: GroupDetailedAvatarProps) => {
  const { setActiveGroupReadModalResource } = useSuperShare();
  const { id, name, memberCount } = curriculumAssignable;

  return (
    <DetailedAvatar
      avatarIcon='users'
      id={`group-detailed-avatar-${id}`}
      label={name}
      subLabel={t('members', { count: memberCount })}
      subLabelOnClick={() => setActiveGroupReadModalResource({ id, memberCount, name })}
      {...getBadge(action === 'add', t('new_badge'))}
    />
  );
};

const SharedWithDetailedAvatar = ({
  assignmentWithAction: { permission: assignmentPermission, curriculumAssignable, action },
}: SharedWithRowProps) => {
  if (curriculumAssignable.type === 'User') {
    return (
      <UserDetailedAvatar
        action={action}
        curriculumAssignable={curriculumAssignable}
        permission={assignmentPermission}
      />
    );
  }

  return <GroupDetailedAvatar action={action} curriculumAssignable={curriculumAssignable} />;
};

export default SharedWithDetailedAvatar;
