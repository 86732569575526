import { useMemo } from 'react';

import { PermissionKeys } from '../../types/Permission';
import useCurrentUser from '../useCurrentUser';

type PermissionSettings = {
  [key in PermissionKeys]: {
    restrictedOnBuildPlan: boolean;
  };
};

const permissionSettings: PermissionSettings = {
  billing_admin: { restrictedOnBuildPlan: false },
  admin: { restrictedOnBuildPlan: false },
  author: { restrictedOnBuildPlan: true },
  contributor: { restrictedOnBuildPlan: true },
  general: { restrictedOnBuildPlan: false },
};

const useUserPermissionSettings = () => {
  const { permission: currentUserPermission } = useCurrentUser();

  return useMemo(() => {
    const isHigherPermission = (permission: PermissionKeys) => {
      switch (currentUserPermission) {
        case 'billing_admin':
          return false;
        case 'admin':
          return ['billing_admin'].includes(permission);
        case 'author':
          return ['billing_admin', 'admin'].includes(permission);
        case 'contributor':
          return ['billing_admin', 'admin', 'author'].includes(permission);
        case 'general':
          return ['billing_admin', 'admin', 'author', 'contributor'].includes(permission);
        default:
          throw 'Invalid permission';
      }
    };

    return { permissionSettings, isHigherPermission };
  }, [currentUserPermission]);
};

export default useUserPermissionSettings;
