import styled from 'styled-components';

import Icon from '../../../../design_system/display/icons/Icon';
import { fontMd1 } from '../../../../styled/TypeSystem';

export const TitleWrapper = styled.p`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontMd1}
`;

export const ModalTitleText = styled.span`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const StyledFooterText = styled.p`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const ResettingTextWrapper = styled.div`
  padding: ${({ theme: { constants } }) => constants.spacerMd3}
    ${({ theme: { constants } }) => constants.spacerLg1};
  background: ${({ theme: { vars } }) => vars.stateBackgroundError};
  border-radius: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.stateError};
`;

export const ResettingText = styled.span`
  ${fontMd1}
`;

export const CheckBoxWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;
