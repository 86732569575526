import React, { useEffect } from 'react';

import usePublicConfigs from '../../../../hooks/usePublicConfigs';
import initTranslations from '../../../../lib/initTranslations';
import { useRefreshPublicShareLinkMutation } from '../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { PublicCurriculum } from '../../../../types/Curriculum';
import ConfirmationModal from '../../../design_system/overlays/modals/ConfirmationModal';
import { useFlashNotification } from '../../../FlashNotificationContext';
import { getShareDomain } from '../../people/SuperShare/PublicShareModal/PublicShareModal';

const t = initTranslations('public_share.refresh_share_link');

export interface Props {
  curriculumTitle: string;
  publicCurriculum: PublicCurriculum;
  baseDomain: string;
  closeRefreshModal: () => void;
}

const RefreshLinkModal = (props: Props) => {
  const { publicCurriculum, closeRefreshModal, baseDomain, curriculumTitle } = props;
  const { flash } = useFlashNotification();
  const message = t('message', { title: curriculumTitle });
  const [refreshPublicLink, result] = useRefreshPublicShareLinkMutation();
  const { data, error, isSuccess, isLoading } = result;
  const { configs } = usePublicConfigs();
  const shareSubdomain = configs['SHARE_SUBDOMAIN'];

  useEffect(() => {
    if (isSuccess) {
      flash('info', t('refresh_notification_success'));
      const url = `${getShareDomain(baseDomain, shareSubdomain)}/subject/${data?.id}`;
      navigator?.clipboard?.writeText(url);
      closeRefreshModal();
    }
  }, [flash, isSuccess, closeRefreshModal, data?.id, baseDomain, shareSubdomain]);

  useEffect(() => {
    if (error) {
      flash('warn', t('refresh_notification_error'));
    }
  }, [flash, error, closeRefreshModal]);

  return (
    <ConfirmationModal
      actionFunction={() => {
        refreshPublicLink({
          id: publicCurriculum.id,
          curriculum_id: publicCurriculum.curriculum_id,
        });
      }}
      actionText={t('refresh')}
      desktopSize='md'
      heapModalName='refresh-public-curriculum-modal'
      message={message}
      onCloseRequest={() => {
        closeRefreshModal();
      }}
      processing={isLoading}
      secondaryButtonText={t('cancel')}
      title={t('title')}
    />
  );
};

export default RefreshLinkModal;
