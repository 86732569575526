import React from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import { FetchPlansDataResponse } from '../../../../../../redux/services/resourceApis/billing/perUserPricingTypes';
import { FetchSimplePricingPlansDataResponse } from '../../../../../../redux/services/resourceApis/billing/simplePricingTypes';
import BillingPagePlans from '../../../shared/BillingPage/BillingPagePlans/BillingPagePlans';
import SimplePricingBillingPagePlans from '../BillingPagePlans/BillingPagePlans';
import SimplePricingBillingPagePlansWithTrainualPlus from '../BillingPagePlansWithTrainualPlus/BillingPagePlansWithTrainualPlus';

type BillingPagePlansSectionFactoryProps = {
  billingPlansPriceData: FetchSimplePricingPlansDataResponse | FetchPlansDataResponse;
};

const BillingPagePlansSectionFactory = ({
  billingPlansPriceData,
}: BillingPagePlansSectionFactoryProps) => {
  const {
    splitFeatures: {
      billingPagePlansWithTrainualPlusEnabled,
      simplePricingEnabled,
      simplePricingV3Enabled,
    },
  } = useCurrentAccount();

  return (
    <>
      {simplePricingEnabled ? (
        billingPagePlansWithTrainualPlusEnabled ? (
          <SimplePricingBillingPagePlansWithTrainualPlus
            billingPlansPriceData={billingPlansPriceData as FetchSimplePricingPlansDataResponse}
          />
        ) : (
          <SimplePricingBillingPagePlans
            billingPlansPriceData={billingPlansPriceData as FetchSimplePricingPlansDataResponse}
            isSimplePricingV3={simplePricingV3Enabled}
          />
        )
      ) : (
        <BillingPagePlans billingPlansPriceData={billingPlansPriceData as FetchPlansDataResponse} />
      )}
    </>
  );
};

export default BillingPagePlansSectionFactory;
