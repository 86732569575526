import React from 'react';

import Icon from '../icons/Icon';
import { EmojiWrapper } from './styles';
import { Props } from './types';

const Emoji = ({ emoji, id, defaultIconName = 'file-alt' }: Props) => {
  return (
    <EmojiWrapper id={id}>
      {emoji ? emoji : <Icon name={defaultIconName} size='sm' weight='regular' />}
    </EmojiWrapper>
  );
};

export default Emoji;
