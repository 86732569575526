export enum SortOrder {
  asc = 'ASC',
  desc = 'DESC',
}
type SortArrayArgs<T, Key extends keyof T, NestedKey extends keyof T[Key]> = {
  array: T[];
  sortKey: Key;
  sortOrder: SortOrder;
  nestedKey?: NestedKey;
};
const sortArray = <T, Key extends keyof T, NestedKey extends keyof T[Key]>({
  array,
  sortKey,
  sortOrder,
  nestedKey,
}: SortArrayArgs<T, Key, NestedKey>): T[] => {
  const lessThanValue = sortOrder === SortOrder.asc ? -1 : 1;
  const greaterThanValue = sortOrder === SortOrder.asc ? 1 : -1;
  return array.sort((a, b) => {
    const aValue = nestedKey ? a[sortKey][nestedKey] : a[sortKey];
    const bValue = nestedKey ? b[sortKey][nestedKey] : b[sortKey];
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return aValue.localeCompare(bValue, undefined, { sensitivity: 'base' });
    }
    return aValue < bValue ? lessThanValue : aValue > bValue ? greaterThanValue : 0;
  });
};
export default sortArray;
