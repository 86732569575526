import styled from 'styled-components';

import { fontMd1 } from '../../../../../styled/TypeSystem';

export const ExternalLinkElementWrapper = styled.div`
  padding-top: ${({ theme: { constants } }) => constants.spacerSm3};
  padding-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
`;

export const ListHeader = styled.h6`
  ${({ theme: { constants, vars } }) => ({
    color: vars.textDefault,
    fontWeight: constants.fontSemibold,
  })};
  margin: ${({ theme: { constants } }) => constants.spacerMd2} 0 0 0;
  ${fontMd1}
`;
