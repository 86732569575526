import styled, { css } from 'styled-components';

import { fontSm3 } from '../../../styled/TypeSystem';
import Icon from '../../display/icons/Icon';
import { Orientation } from './RadioFieldTypes';

export const OptionsWrapper = styled.div<{ orientation: Orientation }>`
  ${({ orientation }) =>
    orientation === 'horizontal' &&
    css`
      display: flex;
    `}
`;

export const RadioWrapper = styled.div<{ disabled?: boolean; orientation: Orientation }>`
  display: flex;
  align-items: flex-start;
  padding-right: ${({ theme: { constants } }) => constants.spacerMd2};
  min-height: ${({ theme: { constants } }) => constants.heightSm};
  ${({ orientation }) =>
    orientation === 'vertical' &&
    css`
      padding-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
      &:last-child {
        padding-bottom: 0;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `};
`;

export const StyledRadio = styled.input<{ disabled?: boolean; isCustomRadioField?: boolean }>`
  margin: ${({ theme: { constants } }) => constants.spacerSm1}
    ${({ theme: { constants } }) => constants.spacerSm3} 0 0;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:checked {
    accent-color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  }

  ${({ isCustomRadioField }) =>
    isCustomRadioField &&
    css`
      opacity: 0;
      position: absolute;
      width: inherit;
      height: inherit;
      margin: 0;
    `};
`;

export const Description = styled.label<{ disabled?: boolean }>`
  display: block;
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm1};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${fontSm3};
`;

export const RadioInputWrapper = styled.div`
  position: relative;
  display: flex;
  width: ${({ theme: { constants } }) => constants.spacerMd2};
  height: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm1};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const StyledIcon = styled(Icon)<{ checked: boolean; disabled?: boolean }>`
  color: ${({ checked, theme: { vars } }) =>
    checked ? vars.accentPrimaryDefault : vars.textDefault};
`;
