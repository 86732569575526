import styled from 'styled-components';

import P from '../../../../../design_system/text/P';
import { fontSm3 } from '../../../../../styled/TypeSystem';
import { CardTitle, HighlightedBlock } from '../shared/styles';

export const BillingAddressRow = styled.div<{ cardDetails: boolean }>`
  ${({ cardDetails, theme: { constants } }) =>
    cardDetails && {
      paddingLeft: `calc(${constants.spacerLg3} + ${constants.spacerSm3} + ${constants.spacerSm2})`,
    }};
`;

export const BillingInformationWrapper = styled(HighlightedBlock)`
  flex-direction: column;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const BillingInformationCardDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const BillingInformationCardTitle = styled(CardTitle)`
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
`;

export const AccountCreatedWrapper = styled.div`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const Description = styled(P)`
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm3};
`;
