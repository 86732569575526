import React from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import usePublicConfigs from '../../../../../../hooks/usePublicConfigs';
import { useRedirectToBillingOverlay } from '../../../../../../hooks/useRedirectToBillingOverlay';
import capitalize from '../../../../../../lib/capitalize';
import initTranslations from '../../../../../../lib/initTranslations';
import Link from '../../../../../design_system/Link';
import ProsperStackCancelLinkContainer, {
  ProsperStackCancelLinkProps,
} from '../../../../integrations/ProsperStackCancelLink/ProsperStackCancelLinkContainer';
import {
  BillingPageCancelSubscriptionProps,
  BillingPageCancelSubscriptionSectionLinkProps,
} from './types';

const t = initTranslations('per_user_pricing.billing_page.cancel_subscription');

const ChangeTeamSize = ({
  billingInterval,
  disabled,
}: BillingPageCancelSubscriptionSectionLinkProps) => {
  const navigateToBillingOverlay = useRedirectToBillingOverlay();

  return (
    <Link
      behavesAs='button'
      className='change-team-size'
      disabled={disabled}
      onClick={() => navigateToBillingOverlay({ type: 'manage-seats', interval: billingInterval })}
      text={t('change_team_size')}
    />
  );
};

const ChangePaymentTerms = ({
  billingInterval,
  disabled,
}: BillingPageCancelSubscriptionSectionLinkProps) => {
  const navigateToBillingOverlay = useRedirectToBillingOverlay();
  const isAnnualInterval = billingInterval === 'year';
  const overlayType = isAnnualInterval ? 'downgrade' : 'upgrade';
  const newBillingInterval = isAnnualInterval ? 'month' : 'year';

  return (
    <Link
      behavesAs='button'
      className='change-payment-terms'
      disabled={disabled}
      onClick={() => navigateToBillingOverlay({ type: overlayType, interval: newBillingInterval })}
      text={t('change_payment_terms')}
    />
  );
};

const CancelSubscription = () => {
  const {
    onHoldPlan,
    onOneOfUnlimitedPlans,
    splitFeatures: { simplePricingEnabled },
  } = useCurrentAccount();
  const capitalizeTranslation = (onHoldPlan || onOneOfUnlimitedPlans) && !simplePricingEnabled;

  return (
    <ProsperStackCancelLinkContainer>
      {({ flowProcessing, triggerFlow }: ProsperStackCancelLinkProps) => (
        <Link
          behavesAs='button'
          className='prosper-cancel-link'
          disabled={flowProcessing}
          onClick={triggerFlow}
          text={
            capitalizeTranslation ? capitalize(t('cancel_subscription')) : t('cancel_subscription')
          }
        />
      )}
    </ProsperStackCancelLinkContainer>
  );
};

const BillingPageCancelSubscription = ({
  billingInterval,
  simplePricingEnabled,
}: BillingPageCancelSubscriptionProps) => {
  const { onHoldPlan, onCustomPlan, onFree3SeatsPlan, onOneOfUnlimitedPlans, status } =
    useCurrentAccount();
  const showTeamSizeAndPaymentTermsLinks = !(onHoldPlan || onOneOfUnlimitedPlans);
  const isPastDue = status === 'past_due';
  const { configs } = usePublicConfigs();
  const helpPageUrl = configs['HELP_PAGE_URL'];
  const showOrText = simplePricingEnabled || showTeamSizeAndPaymentTermsLinks;
  const showCancelSubscription = !(onFree3SeatsPlan || onCustomPlan);

  return (
    <div id='billing-page-cancel-subscription'>
      {simplePricingEnabled ? (
        <Link
          behavesAs='button'
          onClick={() => window.open(helpPageUrl, '_blank')}
          text={t('get_help')}
        />
      ) : (
        showTeamSizeAndPaymentTermsLinks && (
          <>
            <ChangeTeamSize billingInterval={billingInterval} disabled={isPastDue} />
            {', '}
            <ChangePaymentTerms billingInterval={billingInterval} disabled={isPastDue} />
          </>
        )
      )}
      {showCancelSubscription && (
        <>
          {showOrText && t('or')}
          <CancelSubscription />
        </>
      )}
      .
    </div>
  );
};

export default BillingPageCancelSubscription;
