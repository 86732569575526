import React from 'react';

import { useTeammateModal } from '../../../../../../contexts/TeammateContext';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import { useRedirectToBillingOverlay } from '../../../../../../hooks/useRedirectToBillingOverlay';
import initTranslations from '../../../../../../lib/initTranslations';
import { FetchCurrentPlanCardDataResponse } from '../../../../../../redux/services/resourceApis/billing/perUserPricingTypes';
import AddTeammateWithConfirmation from '../../../../people/TeammateModal/AddTeammateWithConfirmation';
import { routes } from '../../../../publicApplication/applicationRouter';
import routeTo from '../../../../publicApplication/routeTo';
import { CardWrapper } from '../../../per_user_pricing/shared/styles';
import { PrimaryButton, StyledBoldP, StyledSpan } from '../shared/styles';
import AddonsSectionFactory from './AddonsSectionFactory/AddonsSectionFactory';
import DiscountsInlineSection from './DiscountsInlineSection/DiscountsInlineSection';
import PlanCardTitle from './PlanCardTitle/PlanCardTitle';
import {
  ActionsWrapper,
  CardFooter,
  Divider,
  ManageSeatsButton,
  PlanCardBody,
  StyledAvatarsGroup,
} from './styles';
import TotalDueSection from './TotalDueSection/TotalDueSection';

const t = initTranslations('per_user_pricing.billing_page.plan_card');

export type BillingPagePlanCardProps = {
  planCardData: FetchCurrentPlanCardDataResponse;
  onHoldPlan: boolean;
  onLegacyPlan: boolean;
  onOneOfAnnualUnlimitedPlans: boolean;
  unlimitedUserSlots: boolean;
  simplePricingEnabled: boolean;
};

const BillingPagePlanCard = ({
  planCardData,
  onHoldPlan,
  onLegacyPlan,
  onOneOfAnnualUnlimitedPlans,
  simplePricingEnabled,
  unlimitedUserSlots,
}: BillingPagePlanCardProps) => {
  const { slug, totalUserSlotsCount, status, onFree3SeatsPlan, onCustomPlan } = useCurrentAccount();
  const { usersCount, users, coupon, productName, billingInterval, addonItems, total } =
    planCardData;
  const isTrialEnded = status === 'trial_ended';
  const isManageSeatsDisabled = onHoldPlan || status === 'past_due';
  const navigateToBillingOverlay = useRedirectToBillingOverlay();
  const { setTeammateModalVisible } = useTeammateModal();
  const managePeopleRoute = routes.manageUsers({ slug });
  const isAnnualInterval = billingInterval === 'year';
  const showYearlyBillingPerk = isAnnualInterval && !onOneOfAnnualUnlimitedPlans && !onCustomPlan;
  const canShowDiscounts = (coupon || showYearlyBillingPerk) && !onFree3SeatsPlan;
  const showUsersSection = unlimitedUserSlots || (!onHoldPlan && totalUserSlotsCount > 0);
  const showTotalDueSection = total > 0;

  const getUsedSeatsText = () => {
    const isExceedsUserSlotsCount = usersCount > totalUserSlotsCount;

    const unlimitedSeatsDetails = <StyledSpan>{usersCount}</StyledSpan>;

    const totalSeatsDetails = (
      <>
        <StyledSpan isExceedsTotalUserSlotsCount={isExceedsUserSlotsCount}>{usersCount}</StyledSpan>
        <StyledSpan>{t('total_seats_details', { totalUserSlotsCount })}</StyledSpan>
      </>
    );

    return (
      <>
        {simplePricingEnabled ? t('active_users') : t('seats_used')}
        {unlimitedUserSlots ? unlimitedSeatsDetails : totalSeatsDetails}
      </>
    );
  };

  return (
    <>
      <CardWrapper
        {...(isTrialEnded && { className: 'disabled', isDisabled: isTrialEnded })}
        id='billing-page-plan-card'
      >
        {planCardData && (
          <PlanCardTitle
            isTrialEnded={isTrialEnded}
            onHoldPlan={onHoldPlan}
            onLegacyPlan={onLegacyPlan}
            planCardData={planCardData}
            simplePricingEnabled={simplePricingEnabled}
          />
        )}
        <PlanCardBody>
          <DiscountsInlineSection
            billingInterval={planCardData.billingInterval}
            canShowDiscounts={canShowDiscounts}
            coupon={coupon}
            onHoldPlan={onHoldPlan}
            productName={productName}
            showYearlyBillingPerk={showYearlyBillingPerk}
          />
          {addonItems && (
            <AddonsSectionFactory addonItems={addonItems} billingInterval={billingInterval} />
          )}
          {showTotalDueSection && <TotalDueSection planCardData={planCardData} />}
        </PlanCardBody>
        <Divider />
        {showUsersSection && <StyledBoldP text={getUsedSeatsText()} />}
        <CardFooter>
          {showUsersSection && (
            <StyledAvatarsGroup
              clickMoreUsersHandler={() => routeTo(managePeopleRoute)}
              i18Key='avatars'
              totalUserCount={usersCount}
              users={users}
              withTotalUserCountBorder
            />
          )}
          <ActionsWrapper>
            {!simplePricingEnabled && (
              <ManageSeatsButton
                buttonType='tertiary'
                disabled={isManageSeatsDisabled}
                id='manage-seats-button'
                onClick={() =>
                  navigateToBillingOverlay({ type: 'manage-seats', interval: billingInterval })
                }
                text={t('manage_seats')}
              />
            )}
            <PrimaryButton
              buttonType='primary'
              disabled={onHoldPlan}
              id='invite-users-button'
              onClick={() => setTeammateModalVisible(true)}
              text={t('invite_users')}
            />
          </ActionsWrapper>
        </CardFooter>
      </CardWrapper>
      <AddTeammateWithConfirmation showAddConfirmation />
    </>
  );
};

export default BillingPagePlanCard;
