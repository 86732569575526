import React, { useCallback, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled, { css } from 'styled-components';

import { useAccountTerminology } from '../../../../../components/AccountTerminologyProvider';
import useAnalyticsUserEvents from '../../../../../hooks/useAnalyticsUserEvents';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import usePublicConfigs from '../../../../../hooks/usePublicConfigs';
import { CurrentModal } from '../../../../../hooks/useSuperShareState';
import initTranslations from '../../../../../lib/initTranslations';
import {
  useCreatePublicCurriculumMutation,
  useUpdatePublicCurriculumMutation,
} from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { Curriculum } from '../../../../../types';
import Icon from '../../../../design_system/display/icons/Icon';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import Toggle from '../../../../design_system/input/controls/Toggle';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import InputField from '../../../../design_system/Triage/InputField';
import { fontSm4 } from '../../../../styled/TypeSystem';

const t = initTranslations('supershare.public_share');

export const getShareDomain = (baseDomain: string, subdomain: string) => {
  const protocol = window.location.protocol;

  // Early return for development environment when a port is available
  if (process.env.NODE_ENV === 'development') {
    const port = window.location.port;

    return `${protocol}//${subdomain}.${baseDomain}:${port}`;
  }

  return `${protocol}//${subdomain}.${baseDomain}`;
};

const ToggleWrapper = styled.div`
  ${({ theme: { constants } }) => css`
    align-items: center;
    display: flex;
    margin: ${constants.spacerMd3} 0 ${constants.spacerSm3} 0;
  `};
`;

const InfoText = styled.p`
  ${({ theme: { vars } }) => css`
    color: ${vars.textSubdued};
    line-height: 1.25rem;
  `};
`;

const StyledInputGroup = styled.div<{ isEnabled?: boolean }>`
  ${({ isEnabled, theme: { constants, vars } }) => css`
    align-items: center;
    background-color: ${isEnabled ? 'transparent' : vars.foundationSurface1};
    border: ${constants.borderWidthSm} solid ${vars.borderSurface2};
    border-radius: ${constants.borderRadiusMd};
    display: flex;
    height: ${constants.heightMd};
    justify-content: space-between;
    margin-top: ${constants.spacerSm3};
    padding: ${constants.spacerSm3} ${constants.spacerMd1};

    :focus-within {
      border-color: ${vars.accentPrimaryDefault};
      caret-color: ${vars.textDefault};
      color: ${vars.textDefault};
      outline: none;
    }
  `};
`;

const StyledInputField = styled(InputField)`
  ${({ theme: { constants } }) => css`
    background: transparent;
    border: none;
    box-shadow: none;
    height: ${constants.height2xs};
    padding: 0 ${constants.spacerSm3} 0 0;
    -webkit-box-shadow: none;
    width: 100%;
  `};
`;

const InputIcon = styled.button<{ isEnabled?: boolean }>`
  ${({ isEnabled, theme: { constants, vars } }) => css`
    background: none;
    border: none;
    color: ${vars.textDefault};
    cursor: ${isEnabled ? 'pointer' : 'not-allowed'};
    font-weight: ${constants.fontSemibold};
    gap: ${constants.spacerSm2};
    margin-left: ${constants.spacerSm3};
    opacity: ${isEnabled ? '1.0' : '0.5'};

    &:focus-visible {
      border: ${constants.borderWidthSm} solid ${isEnabled ? undefined : vars.accentPrimaryDefault};
      border-radius: ${constants.borderRadiusMd};
    }
  `};
`;

const StyledIcon = styled(Icon)`
  pointer-events: none;
`;

const CopyLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const CopyLinkContainer = styled.button<{ isEnabled?: boolean }>`
  ${({ isEnabled, theme: { constants, vars } }) => css`
    align-items: center;
    background: none;
    border: none;
    color: ${isEnabled ? vars.accentPrimaryDefault : vars.textDisabled};
    cursor: ${isEnabled ? 'pointer' : undefined};
    display: flex;
    font-weight: ${constants.fontSemibold};
    gap: ${constants.spacerSm2};
    height: ${constants.heightXs};
  `};
`;

const SignatureInfo = styled.p`
  ${({ theme: { constants, vars } }) => css`
    align-items: center;
    color: ${vars.textSubdued};
    display: flex;
    font-style: italic;
    gap: ${constants.spacerSm3};
    margin-top: ${constants.spacerSm3};
    ${fontSm4};
  `};
`;

const SignatureIcon = styled(Icon)`
  ${({ theme: { vars } }) => css`
    color: ${vars.accentPrimaryDefault};
  `};
`;

const LinkCopiedWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLinkIcon = styled(Icon)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
`;

type PublicShareModalProps = {
  currentModal: string;
  curriculum: Curriculum;
  setCurrentModal: (modal: CurrentModal) => void;
};

const PublicShareModal = ({ currentModal, curriculum, setCurrentModal }: PublicShareModalProps) => {
  const [publicShareEnabled, setPublicShareEnabled] = useState(
    curriculum.public_curriculum?.active ?? false
  );
  const [duplicationEnabled, setDuplicationEnabled] = useState(
    curriculum.public_curriculum?.copy_curriculum_enabled ?? true
  );
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const { configs } = usePublicConfigs();
  const { rootDomain } = useCurrentAccount();
  const publicShareUrl = `${getShareDomain(rootDomain, configs['SHARE_SUBDOMAIN'])}/subject/${
    curriculum?.public_curriculum?.id
  }`;
  const { cdpPublicShareDuplicationToggled } = useAnalyticsUserEvents();
  const curriculumAttributesDataset = document.getElementById(
    `curriculum-attributes-${curriculum.id}`
  )?.dataset;
  const eSignatureRequired = curriculumAttributesDataset?.signatureRequired === 'true';

  const [createPublicCurriculum] = useCreatePublicCurriculumMutation();
  const [updatePublicCurriculum] = useUpdatePublicCurriculumMutation();
  const { productTerm } = useAccountTerminology();

  const handlePublicShareToggle = useCallback(() => {
    setPublicShareEnabled((prevState) => !prevState);
    if (curriculum.public_curriculum) {
      updatePublicCurriculum({
        id: curriculum.public_curriculum.id,
        active: !publicShareEnabled,
        copy_curriculum_enabled: duplicationEnabled,
        curriculum_id: curriculum.id,
      });
    } else {
      createPublicCurriculum({ id: curriculum.id });
    }
  }, [
    createPublicCurriculum,
    curriculum.id,
    curriculum.public_curriculum,
    duplicationEnabled,
    publicShareEnabled,
    updatePublicCurriculum,
  ]);

  const handleAllowDuplicationToggle = useCallback(() => {
    setDuplicationEnabled((prevState) => !prevState);
    cdpPublicShareDuplicationToggled({
      cta: duplicationEnabled
        ? 'public_share_duplication_toggled_off'
        : 'public_share_duplication_toggled_on',
      subjectId: curriculum.id,
    });
    if (curriculum.public_curriculum) {
      updatePublicCurriculum({
        id: curriculum.public_curriculum.id,
        active: publicShareEnabled,
        copy_curriculum_enabled: !duplicationEnabled,
        curriculum_id: curriculum.id,
      });
    } else {
      createPublicCurriculum({ id: curriculum.id });
    }
  }, [
    cdpPublicShareDuplicationToggled,
    createPublicCurriculum,
    curriculum.id,
    curriculum.public_curriculum,
    duplicationEnabled,
    publicShareEnabled,
    updatePublicCurriculum,
  ]);

  useEffect(() => {
    setIsLinkCopied(false);
  }, [currentModal, publicShareEnabled]);

  const taskModalArgs: TaskModalProps = {
    desktopSize: 'xl',
    heapModalName: 'public-share-modal',
    onCloseRequest: () => setCurrentModal('superShare'),
    processing: false,
    secondaryButtonText: t('close'),
    subtitle: t('subtitle', { curriculum_name: curriculum?.title, productTerm }),
    title: t('title'),
  };

  return (
    <TaskModal {...taskModalArgs}>
      <Toggle
        checked={publicShareEnabled}
        handleToggle={handlePublicShareToggle}
        id='public-share-toggle'
        name='enable_public_sharing'
        primaryLabel={t('toggle_allow_public_sharing')}
      />
      <StyledInputGroup isEnabled={publicShareEnabled}>
        <StyledInputField
          disabled={!publicShareEnabled}
          id='public-url-input'
          readOnly
          value={publicShareUrl}
        />
        <div data-for='refresh-icon-tooltip' data-tip>
          {publicShareEnabled && (
            <Tooltip id='refresh-icon-tooltip' place='bottom' text={t('refresh_icon_tooltip')} />
          )}
          <InputIcon
            id='refresh-public-url-icon'
            isEnabled={publicShareEnabled}
            onClick={() => {
              if (publicShareEnabled) {
                setCurrentModal('refreshPublicShareLink');
              }
            }}
            tabIndex={!publicShareEnabled ? -1 : undefined}
          >
            <StyledIcon name='sync' weight='regular' />
          </InputIcon>
        </div>
      </StyledInputGroup>
      <CopyLinkWrapper>
        <CopyToClipboard text={publicShareUrl}>
          <CopyLinkContainer
            id='copy-public-share-link'
            isEnabled={publicShareEnabled}
            onClick={publicShareEnabled ? () => setIsLinkCopied(true) : () => ({})}
            tabIndex={!publicShareEnabled ? -1 : undefined}
          >
            {isLinkCopied && (
              <>
                <Tooltip id='link-copied-tooltip' place='right' text={t('link_copied_tooltip')} />
                <LinkCopiedWrapper data-for='link-copied-tooltip' data-tip>
                  <StyledLinkIcon name='link' weight='regular' />
                  <span>{t('link_copied')}</span>
                </LinkCopiedWrapper>
              </>
            )}
            {!isLinkCopied && (
              <>
                <Icon name='link' weight='regular' />
                <span>{t('copy_link')}</span>
              </>
            )}
          </CopyLinkContainer>
        </CopyToClipboard>
      </CopyLinkWrapper>
      <ToggleWrapper>
        <div data-for='allow-public-share-tooltip' data-tip>
          <Toggle
            checked={duplicationEnabled}
            disabled={!publicShareEnabled}
            handleToggle={handleAllowDuplicationToggle}
            id='enable-curriculum-duplication'
            name='allow_duplication'
            primaryLabel={t('allow_duplication')}
          />
        </div>
        {!publicShareEnabled && (
          <Tooltip id='allow-public-share-tooltip' text={t('allow_public_sharing_tooltip')} />
        )}
      </ToggleWrapper>
      <InfoText>{t('duplication_toggle_description')}</InfoText>
      {eSignatureRequired && (
        <SignatureInfo>
          <SignatureIcon name='circle-info' />
          {t('duplication_toggle_description')}
        </SignatureInfo>
      )}
    </TaskModal>
  );
};

export default PublicShareModal;
