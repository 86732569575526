import useCurrentAccount from '../useCurrentAccount';

export function useCheckNoTrialFlow() {
  const { noTrial, status, backdropSetupStatus } = useCurrentAccount();

  return (
    noTrial &&
    ['trialing', 'trial_ended'].includes(status) &&
    ['backdrop_setup_finished'].includes(backdropSetupStatus)
  );
}
