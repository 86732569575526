import React from 'react';
import styled from 'styled-components';

import useWindowResize from '../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../lib/initTranslations';
import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';
import CopyableContainer from '../../../shared/CopyableContainer';
import { Title } from '../../../shared/DetailedTitle/Title';
import { LabelText } from './SecondaryUserDetail';
import { LabelWrapper, mobileFontSize } from './styles';

const EmailLabelText = styled(LabelText)`
  cursor: pointer;
  ${TruncatedText({})};
`;

interface Props {
  emailValue: string;
}

const t = initTranslations('user_profile');

const UserEmail = ({ emailValue }: Props) => {
  const { isExtraSmWindow } = useWindowResize();

  return (
    <LabelWrapper>
      <Title
        fontSize={mobileFontSize(isExtraSmWindow)}
        fontWeight='semibold'
        title={t('email.value')}
      />
      <CopyableContainer toolTipId='email-label-tooltip' valueToCopy={emailValue}>
        <EmailLabelText
          className='notranslate'
          data-for='email-label-tooltip'
          data-tip
          fontSize={mobileFontSize(isExtraSmWindow)}
        >
          {emailValue}
        </EmailLabelText>
      </CopyableContainer>
    </LabelWrapper>
  );
};

export default UserEmail;
