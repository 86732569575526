import React, { useReducer } from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import { useRedirectToBillingOverlay } from '../../../../../../hooks/useRedirectToBillingOverlay';
import initTranslations from '../../../../../../lib/initTranslations';
import { FetchPlansDataResponse } from '../../../../../../redux/services/resourceApis/billing/perUserPricingTypes';
import { PerUserBillingPlanName } from '../../../../../../types/BillingPlanName';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import { HeaderWrapper as Header } from '../../../../../design_system/Triage/headers/Header';
import { CardsWrapper } from '../shared/styles';
import PlanDetails from './PlanDetails/PlanDetails';
import { PlanFeaturesList } from './PlanFeaturesList/PlanFeaturesList';
import { PlanCardWrapper, PlanFeaturesListWrapper, PlansHeaderWrapper } from './styles';
import { upgradeButtonsReducer } from './utils/upgradeButtonsReducer';

const t = initTranslations('per_user_pricing.billing_page.plans');

type BillingPagePlansProps = {
  billingPlansPriceData: FetchPlansDataResponse;
};

const BillingPagePlans = ({ billingPlansPriceData }: BillingPagePlansProps) => {
  const initialState = {
    trainButtonIsHovered: false,
    scaleButtonIsHovered: false,
  };
  const [upgradeButtonsState, dispatch] = useReducer(upgradeButtonsReducer, initialState);
  const { accountPlan, onLegacyPlan, status } = useCurrentAccount();
  const isTrialingOrTrialEnded = status === 'trialing' || status === 'trial_ended';
  const navigateToBillingOverlay = useRedirectToBillingOverlay();

  return (
    <div className='billing-page-plans'>
      <PlansHeaderWrapper>
        <Header title={t('title')} />
        <DefaultButton
          buttonType='secondary'
          id='compare-plans-button'
          onClick={() => navigateToBillingOverlay({ type: 'compare-plans' })}
          text={t('compare_plans')}
        />
      </PlansHeaderWrapper>
      <CardsWrapper>
        {billingPlansPriceData.billingPlans.map((billingPlan) => {
          const { interval, seatsIncluded, unitPrice, name: planName } = billingPlan;
          const isTrainPlan = planName === 'train';
          const isActive = accountPlan === planName && !isTrialingOrTrialEnded && !onLegacyPlan;

          return (
            <PlanCardWrapper
              className={`billing-page-${planName}-plan`}
              isActive={isActive}
              isHovered={
                upgradeButtonsState[`${planName as PerUserBillingPlanName}ButtonIsHovered`]
              }
              isTrainPlan={isTrainPlan}
              key={planName}
            >
              <PlanDetails
                dispatch={dispatch}
                interval={interval}
                isTrialingOrTrialEnded={isTrialingOrTrialEnded}
                planName={planName}
                seatsIncluded={seatsIncluded}
                unitPrice={unitPrice}
              />
              <PlanFeaturesListWrapper>
                <PlanFeaturesList isTrainPlan={isTrainPlan} />
              </PlanFeaturesListWrapper>
            </PlanCardWrapper>
          );
        })}
      </CardsWrapper>
    </div>
  );
};

export default BillingPagePlans;
