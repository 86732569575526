import { IconName } from '@fortawesome/fontawesome-svg-core';
import DOMPurify from 'dompurify';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import useActionCableChannel from '../../../../hooks/useActionCableChannel';
import useAnalyticsUserEvents from '../../../../hooks/useAnalyticsUserEvents';
import useContentStyles from '../../../../hooks/useContentStyles';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import { messageFromError } from '../../../../redux/errors/helpers';
import { useCreateChatCompletionMutation } from '../../../../redux/services/resourceApis/openAI/openAiAPI';
import {
  ChatMessage,
  CompletionResponse,
} from '../../../../redux/services/resourceApis/openAI/types';
import { useGetStepQuery } from '../../../../redux/services/resourceApis/steps/stepsApi';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import CoreModal from '../../../design_system/core/CoreModal';
import FieldLabel from '../../../design_system/core/FieldLabel';
import { ErrorText } from '../../../design_system/Triage/InputField';
import Loader from '../../../design_system/Triage/Loader';
import { fontSm4, fontSm5 } from '../../../styled/TypeSystem';
import {
  StyledEditorContent as EditorContent,
  EditorWrapper as EditorWrapperBase,
} from '../../editor/components/RichTextarea';
import useBulletListEditor from '../BulletListEditor/useBulletListEditor';
import { ComposeModalType } from '../ComposeModalFactory/ComposeModalFactory';
import Feedback, { FeedbackStatus } from '../Feedback/Feedback';
import { CONTENT_PROMPT_MESSAGES } from '../prompts/prompts';
import { Button, ComposeModalProps, H1, ModalHeaderContainer } from '../shared/modals/shared';

const GeneratedContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme: { vars } }) => vars.foundationBase1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  overflow: hidden;

  p {
    text-align: left;
    margin: ${({ theme: { constants } }) =>
      `0 ${constants.spacerLg1} ${constants.spacerSm3} ${constants.spacerLg1}`};
    font-weight: ${({ theme: { constants } }) => constants.fontRegular};
    color: ${({ theme: { vars } }) => vars.textDefault};
    ${fontSm4};
    /* Had to come after fontSm5 to override the fontSm5 styles line-height */
    line-height: 1.25rem;
  }
`;

const H2 = styled.h2`
  color: ${({ theme: { vars } }) => vars.trainualBrandPurpleMedium};
  margin: ${({ theme: { constants } }) => `${constants.spacerMd3} 0 0 0`};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontSm5};
`;

const StepTitle = styled.span`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin: ${({ theme: { constants } }) => `${constants.spacerSm2} ${constants.spacerLg1}`};
  ${fontSm4};
`;

const ExperienceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 4px
    ${({ theme: { mode } }) =>
      mode === 'light' ? 'rgba(0, 0, 0, 0.15)' : 'rgba(255, 255, 255, 0.2)'};
  gap: ${({ theme: { constants } }) => constants.spacerSm1};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} 0`};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};

  p {
    color: ${({ theme: { vars } }) => vars.textDefault};
    ${fontSm5};
  }

  & > * {
    margin: 0;
  }
`;

const EditorWrapper = styled(EditorWrapperBase)`
  min-height: 8rem;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};

  * {
    margin: 0;
  }
`;

const StyledEditorContent = styled(EditorContent)`
  .ProseMirror li.is-empty:first-child::before {
    color: ${({ theme: { vars } }) => vars.textDisabled};
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

type ButtonIconProps = { iconName: IconName } | { iconName?: never };

type CompletionResponses = {
  completion: CompletionResponse | undefined;
  prevCompletion: CompletionResponse | undefined;
  hasGeneratedSuccessfully: boolean;
};

export type ComposeStepModalMetadata = { stepId: number };

const t = initTranslations('compose.compose_step_modal');

export type Props = ComposeModalProps & {
  composeModalType: ComposeModalType;
  metadata: ComposeStepModalMetadata;
  onSuccess?: (generatedContent: string) => void;
};

const ComposeStepModal = ({
  closeModal,
  composeModalType,
  onSuccess,
  metadata: { stepId },
}: Props) => {
  const { cdpCompositionGenerated, cdpCompositionRegenerated, cdpCompositionAccepted } =
    useAnalyticsUserEvents();
  const [feedbackStatus, setFeedbackStatus] = useState<FeedbackStatus>('un-started');
  const { name } = useCurrentAccount();
  const contentStyles = useContentStyles();
  const [inputErrorMessage, setInputErrorMessage] = useState<string | null>(null);
  const [currentCompletionResponse, setCurrentCompletionResponse] = useState<CompletionResponses>({
    completion: undefined,
    prevCompletion: undefined,
    hasGeneratedSuccessfully: false,
  });
  const { completion, prevCompletion, hasGeneratedSuccessfully } = currentCompletionResponse;
  const { isLoading: isStepLoading, data: step } = useGetStepQuery(stepId, {
    refetchOnMountOrArgChange: true,
  });
  const [trigger, result] = useCreateChatCompletionMutation();
  const { error } = result;
  const editor = useBulletListEditor({
    placeholderText: t('experiences.fill_in_the_blanks.placeholder'),
  });

  const isLoading = useMemo(() => completion?.status === 'pending', [completion]);

  const getUserInput = useCallback(() => {
    if (!editor || !step) return { userInputList: null, combinedUserInput: null };

    const userInputList = editor.view.dom.innerHTML;
    const combinedUserInput = `List:\n${userInputList}\nTopic title: ${step.topicTitle}\nStep title: ${step.title}`;

    return { userInputList, combinedUserInput };
  }, [editor, step]);

  const generateTrigger = useCallback(() => {
    const { userInputList, combinedUserInput } = getUserInput();

    if (!userInputList || !combinedUserInput) {
      return;
    }

    const hasEmptyBullets = userInputList.includes('ProseMirror-trailingBreak');

    if (hasEmptyBullets) {
      setInputErrorMessage(t('experiences.fill_in_the_blanks.validation_non_empty'));
    } else {
      const promptMessage: ChatMessage = {
        role: 'user',
        content: `Company Name: ${name}\n${combinedUserInput}\nProcess:`,
      };

      trigger({
        user_input: combinedUserInput,
        model: 'gpt-3.5-turbo',
        messages: [...CONTENT_PROMPT_MESSAGES, promptMessage],
        feature_name: composeModalType,
        temperature: 0.5,
        max_tokens: 1500,
        frequency_penalty: 0.1,
        presence_penalty: 0.1,
      });
    }
  }, [composeModalType, getUserInput, name, trigger]);

  const channelProps = useMemo(() => {
    return {
      channel: 'AiCompletionsChannel',
      compose_feature: composeModalType,
    };
  }, [composeModalType]);

  const received = useCallback(
    (completionResponse: CompletionResponse) => {
      setCurrentCompletionResponse((prevState) => {
        let hasGeneratedSuccessfully = prevState.hasGeneratedSuccessfully;
        if (completionResponse.status === 'completed') {
          if (hasGeneratedSuccessfully) {
            cdpCompositionRegenerated({
              ai_completion_id: completionResponse.id,
              compose_experience: composeModalType,
            });
          } else {
            cdpCompositionGenerated({
              ai_completion_id: completionResponse.id,
              compose_experience: composeModalType,
            });
          }
          hasGeneratedSuccessfully = true;
        }

        return {
          completion: completionResponse,
          prevCompletion: prevState.completion,
          hasGeneratedSuccessfully,
        };
      });
    },
    [cdpCompositionGenerated, cdpCompositionRegenerated, composeModalType]
  );

  useActionCableChannel<CompletionResponse>(channelProps, received);

  useEffect(() => {
    // Focus on the editor when the modal is opened
    if (step && editor && editor.isEditable) editor.commands.focus('start');
  }, [editor, step]);

  useEffect(() => {
    // Clear the editor error if one exists as soon as the user starts typing
    if (!editor) return;

    if (inputErrorMessage) {
      editor.on('update', () => {
        setInputErrorMessage(null);
      });
    } else {
      editor.off('update');
    }
    return () => {
      editor.off('update');
    };
  }, [editor, inputErrorMessage]);

  const errorMessage = useMemo(() => {
    // If there are errors from API request or ActionCable response return them
    if (error) {
      return messageFromError(error)?.join(', ');
    } else if (completion?.status === 'failed') {
      return completion.error_message;
    }
  }, [completion, error]);

  useEffect(() => {
    // Clear input error message if there is one and the user starts typing
    if (isLoading) {
      setInputErrorMessage(null);
      setFeedbackStatus('un-started');
    } else if (errorMessage) {
      setInputErrorMessage(errorMessage);
    }
  }, [errorMessage, isLoading]);

  const saveToStep = useCallback(() => {
    // Close modal and return the completion to the parent component if status is completed
    if (completion?.status !== 'completed') return;

    onSuccess && onSuccess(completion.completion);
    closeModal();
  }, [closeModal, completion, onSuccess]);

  useEffect(() => {
    if (step && !step.title) {
      setInputErrorMessage(t('step_title_required'));
    } else {
      setInputErrorMessage(null);
    }
  }, [step]);

  const buttonText = useMemo(() => {
    if (completion?.status === 'pending') return t('submit_title_loading');

    return completion?.status === 'completed' ? t('submit_title_use') : t('submit_title');
  }, [completion?.status]);

  const primaryButtonIconProps: ButtonIconProps = useMemo(
    () => (completion?.status === 'completed' ? {} : { iconName: 'magic-wand-sparkles' }),
    [completion]
  );

  const RetryButton = feedbackStatus === 'complete' ? Button : DefaultButton;

  if (!editor) return <></>;

  return (
    <CoreModal
      closeIconAriaLabel={t('aria_label_cancel')}
      desktopSize='lg'
      heapModalName='compose-step-modal'
      onCloseRequest={closeModal}
    >
      <ModalHeaderContainer>
        <H1>{t('title')}</H1>
      </ModalHeaderContainer>
      {!hasGeneratedSuccessfully && (
        <ExperienceContainer>
          <H2>{t('experiences.fill_in_the_blanks.title')}</H2>
          <p>{t('experiences.fill_in_the_blanks.subtitle')}</p>
        </ExperienceContainer>
      )}
      {isStepLoading && <Loader />}
      {step &&
        (hasGeneratedSuccessfully ? (
          <GeneratedContentWrapper>
            <H2>{t('generated_text_title')}</H2>
            {step.title && (
              <StepTitle>{t('generated_step_title', { step_title: step.title })}</StepTitle>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  completion?.completion || prevCompletion?.completion || ''
                ),
              }}
              id='generated-content'
            />
            {!isLoading && completion && (
              <Feedback
                aiCompletionId={completion.id}
                composeModalType={composeModalType}
                feedbackStatus={feedbackStatus}
                setFeedbackStatus={setFeedbackStatus}
              />
            )}
          </GeneratedContentWrapper>
        ) : (
          <>
            {step.title && (
              <>
                <FieldLabel text={t('input_label')} />
                <EditorWrapper editable>
                  <StyledEditorContent
                    $contentStyles={contentStyles}
                    $editable
                    autoFocus
                    data-testid='editor'
                    editor={editor}
                  />
                </EditorWrapper>
              </>
            )}
            {inputErrorMessage && <ErrorText>{inputErrorMessage}</ErrorText>}
          </>
        ))}
      <ButtonGroup>
        {hasGeneratedSuccessfully && (
          <RetryButton
            buttonType={feedbackStatus === 'complete' ? 'primary' : 'tertiary'}
            disabled={isLoading || feedbackStatus === 'started' || !!inputErrorMessage}
            fullWidth
            iconName='rotate-right'
            id='compose-step-button-regenerate'
            loading={isLoading}
            onClick={generateTrigger}
            text={isLoading ? t('submit_title_loading') : t('regenerate_content')}
          />
        )}
        {feedbackStatus !== 'complete' && (
          <Button
            disabled={isLoading || feedbackStatus === 'started' || !!inputErrorMessage}
            fullWidth
            id='compose-step-button-generate'
            loading={isLoading}
            onClick={() => {
              if (completion?.status === 'completed') {
                saveToStep();
                cdpCompositionAccepted({
                  ai_completion_id: completion.id,
                  compose_experience: composeModalType,
                });
              } else {
                generateTrigger();
              }
            }}
            text={buttonText}
            {...primaryButtonIconProps}
          />
        )}
      </ButtonGroup>
    </CoreModal>
  );
};

export default ComposeStepModal;
