import styled from 'styled-components';

import Link from '../../../../../../../design_system/Link';
import { fontSm4, fontSm5 } from '../../../../../../../styled/TypeSystem';

export const AddonListItem = styled.li<{ isHovered: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars }, isHovered }) => (isHovered ? vars.textHover : vars.textSubdued)};
  height: ${({ theme: { constants } }) => constants.heightXs};
  cursor: default;
`;

export const AddonItemText = styled.span`
  ${fontSm5};
`;

export const AddonItemName = styled.span`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
`;

export const AddonItemPriceWrapper = styled.div<{ isHovered: boolean }>`
  display: ${({ isHovered }) => (!isHovered ? 'flex' : 'none')};
  align-items: center;
`;

export const AddonItemPrice = styled.span`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
`;

export const AddonItemBillingPeriod = styled.span`
  ${fontSm4};
`;

export const StyledLink = styled(Link)<{ isHovered: boolean }>`
  display: ${({ isHovered }) => (isHovered ? 'block' : 'none')};
`;
