import React, { ReactElement, ReactNode, useContext } from 'react';

import useAccountRoutes, { Routes } from '../hooks/useAccountRoutes';
import useCurrentAccount from '../hooks/useCurrentAccount';

type Props = {
  children: ReactNode;
};

export const AccountRoutesContext = React.createContext<Routes>({} as Routes);
export const useAccountRoutesContext = () => useContext(AccountRoutesContext);

const AccountRoutesProvider = ({ children }: Props): ReactElement => {
  const { slug } = useCurrentAccount();
  const routes = useAccountRoutes(slug);
  return <AccountRoutesContext.Provider value={routes}>{children}</AccountRoutesContext.Provider>;
};

export { AccountRoutesProvider };
