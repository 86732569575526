import { skipToken } from '@reduxjs/toolkit/query/react';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { useTeammateModal } from '../../../../../contexts/TeammateContext';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import { formatUserOptions } from '../../../../../lib/formatUserOptions';
import initTranslations from '../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../redux/errors/helpers';
import { useGetCurriculumForEditModalQuery } from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { useGetModalUsersQuery } from '../../../../../redux/services/resourceApis/users/usersApi';
import { Sector } from '../../../../../types/Sector';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import LoadingIndicator from '../../../../design_system/Triage/fields/SharedSelect/SelectLoadingIndicator/LoadingIndicator';
import AddTeammateWithConfirmation from '../../../people/TeammateModal/AddTeammateWithConfirmation';
import SubjectModalForm from './SubjectModalForm';

const CenteredDiv = styled.div`
  text-align: center;
`;

const t = initTranslations('curriculums_modal');

export interface Props {
  curriculumId?: number;
  optionalSettingsOpen?: boolean;
  closeRequest: () => void;
  sector?: Sector;
  providedFormTitle?: string;
  providedFormSubtitle?: string;
  afterCreateCurriculumAction?: (curriculumId: number, curriculumTitle: string) => void;
}

const CurriculumModal = ({
  curriculumId,
  optionalSettingsOpen = false,
  closeRequest,
  providedFormTitle,
  providedFormSubtitle,
  sector,
  afterCreateCurriculumAction,
}: Props): ReactElement => {
  const { data: users, isLoading: isUsersLoading, error: usersError } = useGetModalUsersQuery();
  const {
    data: curriculum,
    isLoading: isCurriculumLoading,
    error: curriculumError,
  } = useGetCurriculumForEditModalQuery(curriculumId ?? skipToken);
  const {
    curriculum: { singular: subjectTermSingular },
  } = useAccountTerminology();
  const { name } = useCurrentUser();
  const isUpdate = Boolean(curriculumId);
  const modalTitle = t('title', {
    action_verb: isUpdate ? t('edit') : t('create'),
    subject: subjectTermSingular.toLowerCase(),
  });
  const modalSubtitle = isUpdate
    ? t('update_subtitle', { subject: subjectTermSingular.toLowerCase() })
    : t('create_subtitle', { subject: subjectTermSingular.toLowerCase() });
  const { teammateModalVisible, teammateConfirmationModalVisible } = useTeammateModal();
  const showAddTeammateWithConfirmation = teammateModalVisible || teammateConfirmationModalVisible;

  const taskModalArgs: TaskModalProps = {
    title: modalTitle,
    subtitle: modalSubtitle,
    onCloseRequest: closeRequest,
    heapModalName: 'curriculum-modal',
    primaryButtonText: isUpdate ? t('save_changes') : t('create'),
    primaryButtonTask: () => ({}),
    processing: false,
    isDisabled: true,
    secondaryButtonText: t('cancel'),
  };

  if (isCurriculumLoading || isUsersLoading)
    return (
      <TaskModal {...taskModalArgs} desktopSize='lg'>
        <CenteredDiv>
          <LoadingIndicator />
        </CenteredDiv>
      </TaskModal>
    );

  return (
    <>
      <SubjectModalForm
        afterCreateCurriculumAction={afterCreateCurriculumAction}
        closeRequest={closeRequest}
        curriculum={curriculum}
        errorMessage={
          messageFromError(curriculumError)?.join(', ') || messageFromError(usersError)?.join(', ')
        }
        isUpdate={isUpdate}
        modalSubtitle={providedFormSubtitle || modalSubtitle}
        modalTitle={providedFormTitle || modalTitle}
        optionalSettingsOpen={optionalSettingsOpen}
        sector={sector}
        userOptions={formatUserOptions(users || [], name)}
        visible={!showAddTeammateWithConfirmation}
      />

      {showAddTeammateWithConfirmation && <AddTeammateWithConfirmation />}
    </>
  );
};

export default CurriculumModal;
