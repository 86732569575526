import styled, { css } from 'styled-components';

import Icon from '../../../../../design_system/display/icons/Icon';
import { TruncatedText } from '../../../../../design_system/Triage/TruncatedText';
import { fontSm4 } from '../../../../../styled/TypeSystem';

export const ConnectedContentWrapper = styled.a`
  ${({ theme: { constants, vars } }) => css`
    align-items: center;
    border: ${constants.borderWidthMd} solid ${vars.borderSurface2};
    border-radius: ${constants.borderRadiusMd};
    display: flex;
    gap: ${constants.spacerSm3};
    justify-content: space-between;
    min-height: 4rem;
    padding: 0 ${constants.spacerMd1};
    text-decoration: none;
    color: inherit;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ${constants.spacerMd1};
    }
  `};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 3.5rem;
`;

export const StyledTitle = styled.p`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  padding-bottom: ${({ theme: { constants } }) => constants.spacerMd1};
  ${fontSm4};
`;

export const StyledParagraph = styled.p`
  ${({ theme: { constants, vars } }) => css`
    margin-left: ${constants.spacerSm3};
    flex: 1;
    padding: ${({ theme: { constants } }) => `${constants.spacerMd1} 0 ${constants.spacerMd1} 0`};
    color: ${vars.textDefault};
    overflow-wrap: break-word;
    word-break: break-word;
    ${TruncatedText};
    ${fontSm4};
  `};
`;

export const StyledIcon = styled(Icon)`
  margin-left: ${({ theme: { constants } }) =>
    `calc(${constants.spacerMd2} + ${constants.spacerSm2})`};
`;
