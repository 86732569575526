import { truncate } from 'lodash';
import React from 'react';

import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';
import { ColumnComponentProps } from '../types';
import { GroupElement, StyledRow } from './styles';

const Roles = ({ record }: ColumnComponentProps) => {
  const { roles } = record;
  const rolesCount = roles.length;

  return (
    <StyledRow>
      {!!rolesCount && <GroupElement>{truncate(roles[0], { length: 20 })}</GroupElement>}
      {rolesCount > 1 && (
        <>
          <Tooltip id={`user-${record.id}-more-roles-tooltip`} text={roles.join(', ')} />
          <div data-for={`user-${record.id}-more-roles-tooltip`} data-tip>
            <GroupElement>+{rolesCount - 1}</GroupElement>
          </div>
        </>
      )}
    </StyledRow>
  );
};

export default Roles;
