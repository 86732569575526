import React from 'react';
import { useCookies } from 'react-cookie';

import { useGroupsModals } from '../../../../../contexts/GroupsModalContext';
import { useTeammateModal } from '../../../../../contexts/TeammateContext';
import { FULL_SCREEN_OVERLAY_PREVIOUS_PATH_COOKIE_NAME } from '../../../../../hooks/billing/useCloseFullScreenOverlay';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import initTranslations from '../../../../../lib/initTranslations';
import { openCurriculumModal } from '../../../../../redux/domains/curriculumModal/curriculumModalSlice';
import { openDocumentImportModal } from '../../../../../redux/domains/documentUpload/documentUploadSlice';
import { useAppDispatch } from '../../../../../redux/hooks';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import { routes } from '../../../publicApplication/applicationRouter';
import routeTo from '../../../publicApplication/routeTo';
import useActiveMenuHandler from '../../../publicApplication/utils/useActiveMenuHandler';
import MenuItem from '../MenuItem/MenuItem';
import { Props as MenuItemsProps } from '../MenuItem/types';
import { UNIVERSAL_ADD_MENU_ID } from '../UniversalAdd';
import { UniversalAddMenu } from './styles';

const t = initTranslations('universal_add_menu');

const UniversalAddMenuNavigation = () => {
  const {
    slug,
    splitFeatures: { bulkDocImporterEnabled, createCurriculumFullScreenOverlayEnabled },
  } = useCurrentAccount();
  const dispatch = useAppDispatch();
  const [, setCookie] = useCookies([FULL_SCREEN_OVERLAY_PREVIOUS_PATH_COOKIE_NAME]);
  const { closeMenu } = useActiveMenuHandler({ menuId: UNIVERSAL_ADD_MENU_ID });
  const { setTeammateModalVisible } = useTeammateModal();
  const ability = useCurrentUserAbilities();
  const { dispatch: groupsModalsDispatch } = useGroupsModals();
  const {
    curriculum: { singular: curriculum },
  } = useAccountTerminology();

  const handleCreateCurriculumOverlayOpen = () => {
    const location = window.location;
    const currentPath = location.pathname + location.search;

    setCookie(FULL_SCREEN_OVERLAY_PREVIOUS_PATH_COOKIE_NAME, currentPath, {
      path: `/${slug}`,
    });
    closeMenu();
    routeTo(routes.createCurriculumFullscreenOverlay({ slug }));
  };

  const handleCreateCurriculumOptionClick = () => {
    if (createCurriculumFullScreenOverlayEnabled) {
      handleCreateCurriculumOverlayOpen();
    } else {
      dispatch(openCurriculumModal());
      closeMenu();
    }
  };

  const handleImportFromDocument = () => {
    if (bulkDocImporterEnabled) {
      routeTo(routes.bulkDocumentImport({ slug, sector: 'process' }));
    } else {
      dispatch(openDocumentImportModal('universal_add'));
    }
  };

  const MenuItems: MenuItemsProps[] = [
    {
      iconName: 'user-plus',
      onClick: () => {
        setTeammateModalVisible(true);
        closeMenu();
      },
      title: t('invite_teammate'),
      id: 'top-nav-menu-invite-teammate',
      isShow: ability.can('create', 'User'),
    },
    {
      iconName: 'list',
      onClick: () => {
        groupsModalsDispatch({ type: 'openGroupKindModal' });
        closeMenu();
      },
      title: t('create_group'),
      id: 'top-nav-menu-create-group',
      isShow: ability.can('create', 'Group'),
    },
    {
      iconName: 'pen-to-square',
      onClick: handleCreateCurriculumOptionClick,
      title: t('create_curriculum', { curriculum: curriculum.toLowerCase() }),
      id: 'top-nav-menu-create-subject',
      isShow: ability.can('create', 'Curriculum'),
    },
    {
      iconName: 'file-export',
      onClick: () => {
        handleImportFromDocument();
        closeMenu();
      },
      title: t('import_document'),
      id: 'top-nav-menu-import-document',
      isShow: ability.can('create', 'Curriculum') && !createCurriculumFullScreenOverlayEnabled,
    },
  ];

  return (
    <UniversalAddMenu id='universal-add-menu'>
      {MenuItems.map(({ children, iconName, title, onClick, isClickable, id, isShow }) => (
        <MenuItem
          iconName={iconName}
          id={id}
          isClickable={isClickable}
          isShow={isShow}
          key={id}
          onClick={onClick}
          title={title}
        >
          {children}
        </MenuItem>
      ))}
    </UniversalAddMenu>
  );
};

export default UniversalAddMenuNavigation;
