import { useCookies } from 'react-cookie';

import { routes } from '../components/application/publicApplication/applicationRouter';
import routeTo from '../components/application/publicApplication/routeTo';
import { BillingInterval } from '../types/BillingInterval';
import { BillingPlanName } from '../types/BillingPlanName';
import { FullScreenOverlayType } from '../types/FullScreenOverlay';
import { useCheckNoTrialFlow } from './billing/useCheckNoTrialFlow';
import { FULL_SCREEN_OVERLAY_PREVIOUS_PATH_COOKIE_NAME } from './billing/useCloseFullScreenOverlay';
import { useGetCastedAccountPlan } from './billing/useGetCastedAccountPlan';
import useCurrentAccount from './useCurrentAccount';
import { useSeatsOptions } from './useSeatsOptions';

export type RedirectToBillingOverlayProps = Partial<{
  type: FullScreenOverlayType;
  interval: BillingInterval;
  plan: BillingPlanName;
  quantity: number;
}>;

export const useRedirectToBillingOverlay = () => {
  const {
    slug,
    totalUserSlotsCount,
    billingInterval,
    status,
    splitFeatures: { simplePricingEnabled, simplePricingV3Enabled },
  } = useCurrentAccount();
  const castedAccountPlan = useGetCastedAccountPlan();
  const isTrialingOrTrialEnded = status === 'trialing' || status === 'trial_ended';
  const [range] = useSeatsOptions(castedAccountPlan, status);
  const defaultType = 'upgrade';
  const defaultBillingInterval = billingInterval || 'year';
  const defaultQuantity = totalUserSlotsCount || Number(range[0]);
  const [cookies, setCookie] = useCookies();
  const isNoTrialFlow = useCheckNoTrialFlow();

  const getPreviousPathValue = () => {
    const location = window.location;
    const previousPath = cookies[FULL_SCREEN_OVERLAY_PREVIOUS_PATH_COOKIE_NAME];
    const currentPath = location.pathname + location.search;
    const isArrayOfPreviousPaths = Array.isArray(previousPath);

    if (isArrayOfPreviousPaths) {
      return [...previousPath, currentPath];
    } else if (previousPath && !isArrayOfPreviousPaths) {
      return [previousPath, currentPath];
    } else {
      return currentPath;
    }
  };

  const setPreviousPathCookie = () => {
    if (!isNoTrialFlow) {
      setCookie(FULL_SCREEN_OVERLAY_PREVIOUS_PATH_COOKIE_NAME, getPreviousPathValue(), {
        path: `/${slug}`,
      });
    }
  };

  if (simplePricingEnabled || simplePricingV3Enabled)
    return ({
      interval = defaultBillingInterval,
      plan = castedAccountPlan,
    }: RedirectToBillingOverlayProps = {}) => {
      setPreviousPathCookie();
      plan = ['train', 'scale'].includes(plan) ? 'growth' : plan;

      if (isTrialingOrTrialEnded) {
        routeTo(
          routes.pickASimplePricingPlan({
            slug,
            interval,
            plan: plan as BillingPlanName,
          })
        );
      } else {
        routeTo(
          routes.upgradeSimplePricingPlan({
            slug,
            interval,
            plan: plan as BillingPlanName,
          })
        );
      }
    };

  return ({
    type = defaultType,
    interval = defaultBillingInterval,
    plan = castedAccountPlan,
    quantity = defaultQuantity,
  }: RedirectToBillingOverlayProps = {}) => {
    const params = { slug, interval, plan: plan as BillingPlanName, quantity };

    if (isTrialingOrTrialEnded && type !== 'configure-plan') type = 'compare-plans';

    setPreviousPathCookie();

    switch (type) {
      case 'upgrade':
        routeTo(routes.upgradePlan(params));
        break;
      case 'downgrade':
        routeTo(routes.downgradePlan(params));
        break;
      case 'manage-seats':
        routeTo(routes.manageSeats(params));
        break;
      case 'configure-plan':
        routeTo(routes.configurePlan(params));
        break;
      case 'compare-plans':
        routeTo(routes.comparePlans({ slug }));
        break;
    }
  };
};
