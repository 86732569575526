import React from 'react';

import { couponToDiscount } from '../../../../../../../lib/billing/stripeCouponToDiscount';
import { getFormattedPrice } from '../../../../../../../lib/getFormattedPrice';
import initTranslations from '../../../../../../../lib/initTranslations';
import { FetchCurrentPlanCardDataResponse } from '../../../../../../../redux/services/resourceApis/billing/perUserPricingTypes';
import {
  TotalDueSectionBillingPeriod,
  TotalDueSectionDueDate,
  TotalDueSectionDueDateWrapper,
  TotalDueSectionPrice,
  TotalDueSectionPriceWrapper,
  TotalDueSectionWrapper,
} from './styles';

const t = initTranslations('per_user_pricing.billing_page.plan_card');

type TotalDueSectionProps = {
  planCardData: FetchCurrentPlanCardDataResponse;
};

const TotalDueSection = ({ planCardData }: TotalDueSectionProps) => {
  const { billingInterval, total, nextPaymentDate, coupon } = planCardData;
  const isAnnualInterval = billingInterval === 'year';
  const interval = isAnnualInterval ? t('per_year') : t('per_month');
  const discount = couponToDiscount(coupon);
  const totalPrice = getFormattedPrice(total, discount);

  return (
    <TotalDueSectionWrapper>
      <TotalDueSectionDueDateWrapper>
        {t('total_due')}
        <TotalDueSectionDueDate>{nextPaymentDate}</TotalDueSectionDueDate>
      </TotalDueSectionDueDateWrapper>
      <TotalDueSectionPriceWrapper>
        <TotalDueSectionPrice>{totalPrice}</TotalDueSectionPrice>
        <TotalDueSectionBillingPeriod>{interval}</TotalDueSectionBillingPeriod>
      </TotalDueSectionPriceWrapper>
    </TotalDueSectionWrapper>
  );
};

export default TotalDueSection;
