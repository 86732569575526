import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import React from 'react';

import initTranslations from '../../lib/initTranslations';
import { messageFromError } from './helpers';

const t = initTranslations('error');

interface ErrorProps {
  error: FetchBaseQueryError | SerializedError | unknown;
}

const BasicErrorDisplay = ({ error }: ErrorProps) => {
  return (
    <div>
      {t('title')} {messageFromError(error)?.join(', ')}
    </div>
  );
};

export default BasicErrorDisplay;
