import React from 'react';

import { useAnnualPercentageOfSavings } from '../../../../../../../hooks/billing/useAnnualPercentageOfSavings';
import { useDiscountsLabel } from '../../../../../../../hooks/billing/useDiscountsLabel';
import { couponToDiscount } from '../../../../../../../lib/billing/stripeCouponToDiscount';
import initTranslations from '../../../../../../../lib/initTranslations';
import { BillingInterval } from '../../../../../../../types/BillingInterval';
import { BillingPlanName } from '../../../../../../../types/BillingPlanName';
import { Coupon } from '../../../../../../../types/Coupon';
import Badge from '../../../../../../design_system/display/badge/Badge';
import {
  BillingIntervalTitle,
  DiscountsInlineSectionBadgeWrapper,
  DiscountsInlineSectionBadgesWrapper,
  DiscountsInlineSectionWrapper,
} from './styles';

type DiscountsInlineSectionProps = {
  billingInterval: BillingInterval;
  canShowDiscounts: boolean;
  coupon: Coupon;
  onHoldPlan: boolean;
  showYearlyBillingPerk: boolean;
  productName: BillingPlanName;
};

const t = initTranslations('discounts_inline_section');

const DiscountsInlineSection = ({
  canShowDiscounts,
  coupon,
  billingInterval,
  onHoldPlan,
  showYearlyBillingPerk,
  productName,
}: DiscountsInlineSectionProps) => {
  const isAnnualInterval = billingInterval === 'year';
  const interval = isAnnualInterval ? t('annual_billing') : t('monthly_billing');

  const discount = couponToDiscount(coupon);
  const discountLabel = useDiscountsLabel(discount);

  const yearlyPerkPercent = useAnnualPercentageOfSavings(productName);

  return (
    <DiscountsInlineSectionWrapper id='discounts-inline-section'>
      {!onHoldPlan && <BillingIntervalTitle>{interval}</BillingIntervalTitle>}
      {canShowDiscounts && (
        <DiscountsInlineSectionBadgesWrapper>
          {coupon && <Badge text={`${discountLabel} ${coupon.duration}`} type='trainual-purple' />}
          {showYearlyBillingPerk && (
            <DiscountsInlineSectionBadgeWrapper>
              <Badge
                text={t('percents_off', { percent: yearlyPerkPercent })}
                type='trainual-purple'
              />
            </DiscountsInlineSectionBadgeWrapper>
          )}
        </DiscountsInlineSectionBadgesWrapper>
      )}
    </DiscountsInlineSectionWrapper>
  );
};

export default DiscountsInlineSection;
