import React from 'react';
import styled from 'styled-components';

import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { fontSm5 } from '../../../../styled/TypeSystem';
import { SubtitleLink, SubtitleWithBoldedText } from './TaskModalTypes';

const TaskSubtitle = styled.h5<{ withBetaTag?: boolean }>`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
  margin-bottom: 0;
  max-width: ${({ withBetaTag }) => (withBetaTag ? '70%' : '100%')};

  @media (min-width: ${mediaBreakpointPxSm}) {
    font-size: 0.875rem;
  }
  ${fontSm5};
`;
const TaskSubtitleLink = styled.a`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
`;

interface SubtitleProps {
  subtitle?: string;
  subtitleLink?: SubtitleLink;
  subtitleWithBoldedText?: SubtitleWithBoldedText;
  withBetaTag?: boolean;
}

const Subtitle = (props: SubtitleProps) => {
  const { subtitle, subtitleLink, subtitleWithBoldedText, withBetaTag } = props;

  if (subtitleLink) {
    const { subtitle, linkUrl, linkText } = subtitleLink;

    return (
      <TaskSubtitle withBetaTag={withBetaTag}>
        {subtitle}{' '}
        <TaskSubtitleLink href={linkUrl} rel='noreferrer' target='_blank'>
          {linkText}
        </TaskSubtitleLink>
      </TaskSubtitle>
    );
  }

  if (subtitleWithBoldedText) {
    return (
      <TaskSubtitle withBetaTag={withBetaTag}>
        {subtitleWithBoldedText.map(({ bold = false, text }) =>
          bold ? <strong key={text}>{text + ' '}</strong> : text + ' '
        )}
      </TaskSubtitle>
    );
  }

  return <TaskSubtitle withBetaTag={withBetaTag}>{subtitle}</TaskSubtitle>;
};

export default Subtitle;
