import React from 'react';
import styled from 'styled-components';

import hexToRGBA from '../../../../lib/HexToRGBA';
import initTranslations from '../../../../lib/initTranslations';
import { fontMd1, fontMd3, fontSm4, fontSm5 } from '../../../styled/TypeSystem';
import { TagSize, TagType } from './TagTypes';

const StyledTag = styled.p<{ size: TagSize; type: TagType; borderless?: boolean }>`
  width: fit-content;
  height: fit-content;
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-top: ${({ borderless }) => (borderless ? '0' : '-0.75rem')};
  text-transform: uppercase;
  ${(props) => getTagSize(props.size)};
  color: ${({ type, theme: { vars } }) => getTagColor(type, vars)};
  cursor: default;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin-bottom: 0;
  border: ${({ type, theme: { constants, vars }, borderless }) =>
    borderless ? 'none' : `${constants.borderWidthSm} solid ${getTagColor(type, vars)}`};
  border-radius: ${({ theme: { constants }, borderless }) =>
    borderless ? '0' : constants.borderRadiusMd};
  padding: ${({ theme: { constants }, borderless }) =>
    borderless ? '0' : `${constants.spacerSm2} ${constants.spacerSm3}`};
  background-color: ${({ type, theme: { vars }, borderless }) =>
    borderless ? 'transparent' : hexToRGBA(getTagColor(type, vars), 0.05)};
`;

export interface Props {
  size?: TagSize;
  className?: string;
  text?: string;
  type?: TagType;
  borderless?: boolean;
}

const getTagSize = (size: TagSize) => {
  switch (size) {
    case 'xs':
      return fontSm4;
    case 'sm':
      return fontSm5;
    case 'md':
      return fontMd1;
    case 'lg':
      return fontMd3;
  }
};

const getTagColor = (type: TagType, vars: { [key: string]: string }) => {
  switch (type) {
    case 'default':
      return vars.accentPrimaryDefault;
    case 'accent':
      return vars.accentPrimaryDefault;
    case 'neutral':
      return vars.textSubdued;
    case 'placeholder':
      return vars.textPlaceholder;
  }
};

const t = initTranslations('design_system');

const Tag = ({
  className = '',
  size = 'sm',
  text,
  type = 'default',
  borderless = false,
}: Props) => {
  return (
    <StyledTag borderless={borderless} className={className} size={size} type={type}>
      {text || t('beta')}
    </StyledTag>
  );
};

export default Tag;
