import React from 'react';

import useCurrentUserAbilities from '../../../../../../hooks/useCurrentUserAbilities';
import { RegisteredMenuId } from '../../../../../../lib/idRegistry';
import initTranslations from '../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';
import CurriculumThreeDotMenu from '../CurriculumThreeDotMenu/CurriculumThreeDotMenu';
import { CurriculumThreeDotProps } from './types';

const t = initTranslations('curriculums.tooltips');

const CurriculumThreeDot = ({
  archived,
  curriculumId,
  description,
  isLocked,
  title,
  renameClickHandler,
}: CurriculumThreeDotProps) => {
  const threeDotProps = { archived, description, curriculumId, title, renameClickHandler };
  const ability = useCurrentUserAbilities();
  const canSeeThreeDotMenu = ability.can('read', `CurriculumThreeDotMenu-${curriculumId}`);
  const menuId: RegisteredMenuId = `curriculum-${curriculumId}`;
  const {
    curriculum: { singular: curriculumTermSingular },
  } = useAccountTerminology();

  return (
    <>
      {isLocked && (
        <Tooltip
          id={`locked-curriculum-${curriculumId}`}
          text={t('subject_locked', { subject: curriculumTermSingular })}
        />
      )}

      <div
        id={`curriculum-${curriculumId}-three-dot-wrapper`}
        {...(isLocked && {
          'data-for': `locked-curriculum-${curriculumId}`,
          'data-tip': true,
        })}
      >
        {canSeeThreeDotMenu && (
          <CurriculumThreeDotMenu id={menuId} isDisabled={isLocked} {...threeDotProps} />
        )}
      </div>
    </>
  );
};

export default CurriculumThreeDot;
