import { VerifyContentStatus } from '../../redux/services/resourceApis/reports/types';

export function getBadgeTypeByVerifyContentStatus(status: VerifyContentStatus) {
  switch (status) {
    case 'verified':
      return 'info';
    case 'unverified':
      return 'error';
    default:
      return 'general';
  }
}
