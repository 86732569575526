import React, { useContext, useState } from 'react';

import { PaywallContext } from '../../../../../contexts/PaywallContext';
import { BodyRow } from '../../../people/UsersOutline/Table/Styles';
import { StyledColumn } from '../../AllUsersReportPage/AllUsersReportTable/styles';
import Actions from './Columns/Actions';
import TableColumn from './Columns/TableColumn';
import ElementsTable from './ElementsTable/ElementsTable';
import { getColumns } from './IndividualUserReportColumn';
import { TableRowProps } from './types';

const TableRow = ({ curriculum, userId }: TableRowProps) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const paywallCtx = useContext(PaywallContext);
  const isSignaturePaywalled = paywallCtx.includes('e_signature');

  return (
    <>
      <BodyRow id={`individual-user-report-tr-${curriculum.id}`}>
        {getColumns(isSignaturePaywalled).map(({ columnName, contentAlignment }) => (
          <StyledColumn
            contentAlignment={contentAlignment}
            id={`individual-user-report-column-${curriculum.id}-${columnName}`}
            key={`individual-user-report-column-${curriculum.id}-${columnName}`}
          >
            {columnName === 'actions' ? (
              <Actions isRowCollapsed={collapsed} setCollapsed={setCollapsed} />
            ) : (
              <TableColumn columnName={columnName} record={curriculum} userId={userId} />
            )}
          </StyledColumn>
        ))}
      </BodyRow>
      {collapsed ? (
        <></>
      ) : (
        <ElementsTable
          curriculumElementsCount={curriculum.curriculum_elements_count}
          curriculumId={curriculum.id}
          userId={userId}
        />
      )}
    </>
  );
};

export default TableRow;
