import React from 'react';

import { getFormattedPrice } from '../../../../../../lib/getFormattedPrice';
import initTranslations from '../../../../../../lib/initTranslations';
import { AddonItem } from '../../../../../../redux/services/resourceApis/billing/types';
import AddonAlreadyIncluded from '../AddonAlreadyIncluded/AddonAlreadyIncluded';
import { Divider } from '../styles';
import {
  AddonItemBillingPeriod,
  AddonItemName,
  AddonItemPrice,
  AddonItemPriceWrapper,
  AddonItemText,
  AddonListItem,
  AddonNameWrapper,
  StyledBadge,
} from './styles';

type AddonItemProps = {
  interval: string;
  divideItems?: boolean;
  primaryColor?: string;
  isLastItem: boolean;
} & AddonItem;

const t = initTranslations('billing.addons_section');

const AddonItem = ({
  name,
  price,
  interval,
  status,
  divideItems,
  primaryColor = '',
  isLastItem,
}: AddonItemProps) => {
  const formattedPrice = getFormattedPrice(price);
  const showDivider = divideItems && !isLastItem;
  const isNewStatus = status === 'new';
  const isDeletedStatus = status === 'deleted';
  const showPrice = formattedPrice && !isDeletedStatus;

  const getClassName = () => {
    switch (status) {
      case 'new':
        return 'new-addon';
      case 'deleted':
        return 'deleted-addon';
      default:
        return 'unchanged-addon';
    }
  };

  return (
    <>
      <AddonListItem className={getClassName()} divideItems={divideItems} isNewItem={isNewStatus}>
        <AddonNameWrapper alreadyIncluded={isDeletedStatus}>
          <AddonItemText>
            <AddonItemName isNewStatus={isNewStatus} nameColor={primaryColor}>
              {name}
            </AddonItemName>
            {t('add_on')}
          </AddonItemText>
          {isNewStatus && <StyledBadge text={t('new')} type='success' />}
          {isDeletedStatus && <AddonAlreadyIncluded name={name} />}
        </AddonNameWrapper>
        {showPrice && (
          <AddonItemPriceWrapper>
            <AddonItemPrice isNewStatus={isNewStatus}>{formattedPrice}</AddonItemPrice>
            <AddonItemBillingPeriod isNewStatus={isNewStatus}>{interval}</AddonItemBillingPeriod>
          </AddonItemPriceWrapper>
        )}
      </AddonListItem>
      {showDivider && <Divider />}
    </>
  );
};

export default AddonItem;
