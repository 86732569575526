import styled from 'styled-components';

import IconButton from '../../../../design_system/buttons/IconButton';
import P from '../../../../design_system/text/P';
import { fontSm3 } from '../../../../styled/TypeSystem';

export const StyledIconButton = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme: { constants } }) => constants.spacerLg1};
  height: ${({ theme: { constants } }) => constants.spacerLg1};
  color: ${({ theme: { vars } }) => vars.stateBadgeGeneral};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderDefault}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  &:hover {
    background-color: ${({ theme: { vars } }) => vars.foundationHover};
  }
  ${fontSm3};
`;

export const CardIcon = styled.img`
  width: 100%;
  max-height: 100%;
  padding: ${({ theme: { constants } }) => constants.spacerSm1};
  object-fit: contain;
`;

export const CardIconWrapper = styled.div`
  width: 2.75rem;
  height: 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusSm};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
`;

export const Description = styled(P)`
  ${fontSm3};
`;

export const CardDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;
