import React from 'react';

import { getChevronIconName } from '../../../../shared/helpers';
import { ActionsColumnProps } from '../types';
import { StyledActionsButton } from './styles';

const Actions = ({ setCollapsed, isRowCollapsed }: ActionsColumnProps) => {
  return (
    <StyledActionsButton
      ariaLabel='test'
      buttonSize='lg'
      name={getChevronIconName({ isActive: !isRowCollapsed, initialDirection: 'up' })}
      onClick={() => setCollapsed(!isRowCollapsed)}
      weight='solid'
    />
  );
};

export default Actions;
