import React from 'react';

import StatusChanged, { StatusChangedProps } from '../StatusChanged';
import { Activity, CurriculumElementTrackable } from '../types';
import CommonTemplate, { FlowchartCommonTemplateProps } from './CommonTemplate';

interface FlowchartActivitiesProps {
  'flowchart.create': React.FC<FlowchartCommonTemplateProps>;
  'flowchart.status_changed': React.FC<StatusChangedProps>;
  'flowchart.destroy': React.FC<FlowchartCommonTemplateProps>;
  'flowchart.complete': React.FC<FlowchartCommonTemplateProps>;
  'flowchart.restore': React.FC<FlowchartCommonTemplateProps>;
}

const ACTIVITY_KEYS: FlowchartActivitiesProps = {
  'flowchart.create': CommonTemplate,
  'flowchart.status_changed': StatusChanged,
  'flowchart.destroy': CommonTemplate,
  'flowchart.complete': CommonTemplate,
  'flowchart.restore': CommonTemplate,
};

interface FlowchartActivity extends Omit<Activity, 'trackable' | 'parameters'> {
  trackable: CurriculumElementTrackable;
  parameters: {
    curriculum_id: number;
    curriculum_title: string;
    status_was: string;
    status_now: string;
  };
}

interface FlowchartCardProps {
  activity: FlowchartActivity;
}

const FlowchartActivitiesDetails = ({ activity }: FlowchartCardProps) => {
  const ActivityMessage = ACTIVITY_KEYS[activity.key as keyof FlowchartActivitiesProps];

  return (
    <ActivityMessage
      activityKey={activity.key}
      parameters={activity.parameters}
      trackable={activity.trackable}
    />
  );
};

export default FlowchartActivitiesDetails;
