import styled from 'styled-components';

export const AnnualSavingsImg = styled.img`
  height: 2.2rem;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const BillingIntervalToggleWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: baseline;
  padding-top: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const CardsHeaderWrapper = styled.div`
  display: block;
  position: relative;
`;

export const RaysImg = styled.img`
  position: absolute;
  top: -6px;
  right: -4px;
  height: ${({ theme: { constants } }) => constants.height2xs};
`;
