import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { SendInviteOption } from '../components/application/people/TeammateModal/InterfaceAndTypes';
import { useFlashNotification } from '../components/FlashNotificationContext';
import initTranslations from '../lib/initTranslations';
import { usersApi } from '../redux/services/resourceApis/users/usersApi';
import { Group } from '../types/Group';
import { PermissionKeys } from '../types/Permission';
import { User, UserStatus } from '../types/User';

export type TeammateState = {
  sendInvite?: SendInviteOption;
  user: User;
};

export type NewUser = {
  prefill_teams: number[];
};

export type EditUser = {
  id: number;
  name: string;
  email: string;
  title: string | undefined;
  phone_number: string | null;
  permission: PermissionKeys;
  groups: Group[];
  reports_to: { id: number; name: string } | null;
  status: UserStatus;
  show_in_directory: boolean;
  scheduled_archive_at: string | null;
  scheduled_invite_at: string | null;
  scheduled_invite_at_hour?: string;
  scheduled_archive_at_hour?: string;
};

export interface UserResponse {
  directory_enabled: boolean;
  groups: Group[];
  user: NewUser | EditUser;
  users: User[];
}

export type MainTeammateState = {
  teammateModalVisible: boolean;
  teammateConfirmationModalVisible: boolean;
  setTeammateConfirmationModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setTeammateModalVisible: (teammateModalVisible: boolean) => void;
  teammateState: TeammateState | null;
  setTeammateState: Dispatch<SetStateAction<TeammateState | null>>;
  prefillOptions: UserResponse | undefined;
  loadingPrefillOptions: boolean;
};

const t = initTranslations('teammate_modal');

interface Props {
  userId?: number;
}

export default function useTeammateState({ userId }: Props): MainTeammateState {
  const [teammateModalVisible, setTeammateModalVisible] = useState(false);
  const [teammateConfirmationModalVisible, setTeammateConfirmationModalVisible] = useState(false);
  const [teammateState, setTeammateState] = useState<TeammateState | null>(null);
  const { flash } = useFlashNotification();
  const [editTrigger, editResult] = usersApi.useLazyPrefillUserEditQuery();
  const [trigger, result] = usersApi.useLazyPrefillUserNewQuery();
  const { data, isError, isLoading } = userId ? editResult : result;

  useEffect(() => {
    if (isError) {
      flash('error', t('shared.error'));
    }
  }, [isError, flash]);

  const toggleModal = (isOpening: boolean) => {
    if (isOpening) {
      userId
        ? editTrigger(userId, false).then(() => {
            setTeammateModalVisible(true);
          })
        : trigger(undefined, false).then(() => {
            setTeammateModalVisible(true);
          });
    } else {
      setTeammateModalVisible(false);
    }
  };

  return {
    teammateConfirmationModalVisible,
    teammateModalVisible,
    setTeammateModalVisible: toggleModal,
    setTeammateConfirmationModalVisible,
    teammateState,
    setTeammateState,
    prefillOptions: isLoading ? undefined : data,
    loadingPrefillOptions: isLoading,
  };
}
