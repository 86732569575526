import React from 'react';

import ProgressBar from '../../../../../design_system/Triage/ProgressBar';
import { ColumnComponentProps } from '../types';
import { CompletionContainer, CompletionScore, ProgressBarWrapper } from './styles';

const Score = ({ record }: ColumnComponentProps) => {
  const { completion_percentage } = record;

  return (
    <CompletionContainer>
      <CompletionScore>{completion_percentage}%</CompletionScore>
      <ProgressBarWrapper>
        <ProgressBar percent={completion_percentage} thickness='md' />
      </ProgressBarWrapper>
    </CompletionContainer>
  );
};

export default Score;
