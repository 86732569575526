import { BillingPlanName } from '../../../../../../types/BillingPlanName';

type UpgradeButtonsHoverState = {
  smallButtonIsHovered: boolean;
  growthButtonIsHovered: boolean;
  unlimitedButtonIsHovered: boolean;
  mediumButtonIsHovered: boolean;
  customButtonIsHovered: boolean;
};

export type Action = { type: BillingPlanName; isHovered: boolean };

export const upgradeButtonsReducer = (
  state: UpgradeButtonsHoverState,
  action: Action
): UpgradeButtonsHoverState => {
  switch (action.type) {
    case 'small': {
      return { ...state, smallButtonIsHovered: action.isHovered };
    }
    case 'growth': {
      return { ...state, growthButtonIsHovered: action.isHovered };
    }
    case 'unlimited': {
      return { ...state, unlimitedButtonIsHovered: action.isHovered };
    }
    case 'medium': {
      return { ...state, mediumButtonIsHovered: action.isHovered };
    }
    case 'custom': {
      return { ...state, customButtonIsHovered: action.isHovered };
    }
    default: {
      return state;
    }
  }
};
