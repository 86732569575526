import React from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import { routes } from '../../../../publicApplication/applicationRouter';
import RouterLink from '../../../../publicApplication/RouterLink';
import { ColumnComponentProps } from '../types';

const t = initTranslations('reports.all_users_report.table');

const Actions = ({ record }: ColumnComponentProps) => {
  const { slug } = useCurrentAccount();
  const { id } = record;

  return (
    <RouterLink
      to={routes.individualUserReport({
        slug,
        id,
        breadcrumb: routes.allUsersReport({ slug }),
      })}
    >
      {t('view_details')}
    </RouterLink>
  );
};

export default Actions;
