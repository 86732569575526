import styled from 'styled-components';

import IconButton from '../../../../../design_system/buttons/IconButton';
import Icon from '../../../../../design_system/display/icons/Icon';
import Link from '../../../../../design_system/Link';
import { MultilineTruncatedText } from '../../../../../design_system/Triage/TruncatedText';
import { fontMd1, fontMd2 } from '../../../../../styled/TypeSystem';
import RouterLink from '../../../../publicApplication/RouterLink';

export const EmojiWrapper = styled.div`
  align-items: center;
  background: ${({ theme: { vars } }) => vars.foundationBase2};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  display: flex;
  height: ${({ theme: { constants } }) => constants.heightLg};
  justify-content: center;
  min-width: 2.5rem;
  ${fontMd2};
`;

export const TitleWrapper = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme: { vars } }) => vars.textDefault};

  &:hover {
    text-decoration: none;
    color: ${({ theme: { vars } }) => vars.textDefault};
  }
`;

export const TextTitleWrapper = styled.span`
  display: flex;
  align-items: center;
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

export const TitleWrapperRoute = styled(RouterLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme: { vars } }) => vars.textDefault};

  &:hover {
    text-decoration: none;
    color: ${({ theme: { vars } }) => vars.textDefault};
  }
  p:hover {
    text-decoration: underline;
  }
`;

export const ElementTitle = styled.div`
  max-width: 12rem;
  margin-left: ${({ theme: { constants } }) => constants.spacerMd2};

  * {
    ${MultilineTruncatedText({})};
  }
`;

export const CompletionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CompletionScore = styled.span`
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  text-align: end;
  width: 2.5rem;
  ${fontMd1}
`;

export const ProgressBarWrapper = styled.div`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  width: 5.75rem;
`;

const P = styled.p`
  margin: 0;
  ${fontMd1}
`;

export const BoldText = styled(P)`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
`;

export const RegularText = styled(P)`
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
`;

export const StyledActionsButton = styled(IconButton)`
  display: inline;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};

  svg {
    color: ${({ theme: { vars } }) => vars.textDefault};
  }
`;

export const StyledStatusIcon = styled(Icon)<{ isSuccess?: boolean }>`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
  color: ${({ isSuccess, theme: { vars } }) => (isSuccess ? vars.stateSuccess : vars.textDefault)};
`;

export const SignatureStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  ${fontMd1}
`;

export const SignedDocumentDownloadLink = styled.a`
  text-decoration: none;
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-right: ${({ theme: { constants } }) => constants.spacerMd3};

  &:hover {
    text-decoration: none;
    color: ${({ theme: { vars } }) => vars.textDefault};
  }
`;
