import { useEffect } from 'react';

import { FlashType, useFlashNotification } from '../components/FlashNotificationContext';

export type ReduxDisplayResult = {
  isSuccess: boolean; // Query has data from a successful load.
  isError: boolean; // Query is currently in an "error" state.
  isLoading: boolean; // Query is currently in a "loading" state.
  isFetching?: boolean; // Query is currently in a "loading" state. - optional because mutations do not have isFetching
};

interface Props {
  result: ReduxDisplayResult;
  successMessage?: string;
  /* Pass function directly or use useCallback to avoid infinite loops that can occur with anonymous functions */
  successFunction?: () => void;
  errorMessage?: string;
  /* Pass function directly or use useCallback to avoid infinite loops that can occur with anonymous functions */
  errorFunction?: () => void;
  autoClose?: number;
  skip?: boolean;
  successFlashType?: FlashType;
}

const useDisplayFlashOnResponse = ({
  result,
  successMessage,
  successFunction,
  errorMessage,
  errorFunction,
  autoClose,
  skip = false,
  successFlashType = 'info',
}: Props) => {
  const { flash } = useFlashNotification();
  const { isSuccess, isLoading, isFetching, isError } = result;

  useEffect(() => {
    if (isSuccess && !isLoading && !isFetching && !skip) {
      if (successMessage) flash(successFlashType, successMessage, { autoClose });
      if (successFunction) successFunction();
    }
  }, [
    autoClose,
    flash,
    isFetching,
    isLoading,
    isSuccess,
    skip,
    successFlashType,
    successFunction,
    successMessage,
  ]);

  useEffect(() => {
    if (isError && !isLoading && !isFetching && !skip) {
      flash('error', errorMessage, { autoClose });
      if (errorFunction) errorFunction();
    }
  }, [autoClose, errorFunction, errorMessage, flash, isError, isFetching, isLoading, skip]);
};

export default useDisplayFlashOnResponse;
