import React from 'react';

import { getFormattedPrice } from '../../../../../lib/getFormattedPrice';
import initTranslations from '../../../../../lib/initTranslations';
import { Discount } from '../../../../../types/Discount';
import { PerBillingPeriodText } from '../../shared/BillingPage/BillingPagePlans/styles';

const t = initTranslations('per_user_pricing.billing_page.plans');

export const getFormattedPlanPricePerUser = (planPricePerUser: number, discount?: Discount) => {
  return (
    <>
      {getFormattedPrice(planPricePerUser, discount)}
      <PerBillingPeriodText>{t('user_per_month')}</PerBillingPeriodText>
    </>
  );
};
