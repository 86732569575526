import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';

import initTranslations from '../../../../lib/initTranslations';
import Icon from '../../../design_system/display/icons/Icon';
import Banner from '../../../design_system/Triage/Banner';
import { useFlashNotification } from '../../../FlashNotificationContext';
import { mediaBreakpointPxLg, mediaBreakpointPxMd } from '../../../styled/Breakpoint';

const StyledDeprecatedUrlBannerIcon = styled(Icon)`
  display: none;
  color: ${(props) => props.theme.vars.stateInfo};

  @media (min-width: ${mediaBreakpointPxLg}) {
    display: block;
  }
`;

const StyledDeprecatedUrlBannerText = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  margin-left: 0.25rem;
  text-align: center;
  color: ${(props) => props.theme.vars.textDefault};

  @media (min-width: ${mediaBreakpointPxMd}) {
    font-size: 0.875rem;
  }
`;

const StyledDeprecatedUrlBannerCopyPlainText = styled.div`
  margin-left: 0.5rem;
  font-size: 0.75rem;
  color: ${(props) => props.theme.vars.accentPrimaryDefault};
  cursor: pointer;

  @media (min-width: ${mediaBreakpointPxMd}) {
    font-size: 0.875rem;
  }
`;

const t = initTranslations('deprecated_url_banner');

interface Props {
  copyablePath: string;
}

const DeprecatedUrlBanner = ({ copyablePath }: Props) => {
  const [isShowBanner, setIsShowBanner] = useState(true);
  const { flash } = useFlashNotification();
  const showFlashNotification = () => flash('info', t('url_copied'));

  return isShowBanner ? (
    <Banner onCloseRequest={() => setIsShowBanner(false)}>
      <StyledDeprecatedUrlBannerIcon name='exclamation-triangle' weight='solid' />
      <StyledDeprecatedUrlBannerText>{t('text')}</StyledDeprecatedUrlBannerText>
      <StyledDeprecatedUrlBannerCopyPlainText>
        <CopyToClipboard onCopy={showFlashNotification} text={copyablePath}>
          <span>{t('copy_text')}</span>
        </CopyToClipboard>
      </StyledDeprecatedUrlBannerCopyPlainText>
    </Banner>
  ) : (
    <></>
  );
};

export default DeprecatedUrlBanner;
