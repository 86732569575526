import dayjs from 'dayjs';
import React, { useState } from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import { BillingDetails } from '../../../../../../types/BillingDetails';
import Link from '../../../../../design_system/Link';
import { CardWrapper } from '../../../per_user_pricing/shared/styles';
import BillingHistoryModal from '../../../shared/BillingHistoryModal/BillingHistoryModal';
import CardDetails from '../../../shared/CardDetails/CardDetails';
import { StyledBoldP, StyledMediumP } from '../shared/styles';
import BillingAddress from './BillingAddress/BillingAddress';
import BillingInfoEmptyState from './BillingInfoEmptyState/BillingInfoEmptyState';
import {
  AccountCreatedWrapper,
  BillingAddressRow,
  BillingInformationCardDetailsWrapper,
  BillingInformationCardTitle,
  BillingInformationWrapper,
  CardFooter,
} from './styles';

const t = initTranslations('per_user_pricing.billing_page.information_card');

type BillingPageInformationCardProps = {
  billingDetailsData: BillingDetails;
};

const BillingPageInformationCard = ({ billingDetailsData }: BillingPageInformationCardProps) => {
  const {
    status: accountStatus,
    adminAccount: { accountType },
    nonLocalizedCreatedAtString,
  } = useCurrentAccount();
  const [showStripeBillingHistoryModal, setShowStripeBillingHistoryModal] = useState(false);
  const accountCreatedDate = dayjs(nonLocalizedCreatedAtString).format('MMM. D, YYYY');
  const { billingAddressDetails, cardDetails } = billingDetailsData;
  const isNotTrialing = accountStatus !== 'trialing';
  const showViewBillingHistoryBtn = accountType === 'super_admin' && isNotTrialing;

  const handleBillingHistoryModal = () => {
    setShowStripeBillingHistoryModal(true);
  };

  return (
    <CardWrapper id='billing-page-information-card'>
      {cardDetails.brand ? (
        <>
          <BillingInformationCardTitle text={t('title')} />

          <BillingInformationWrapper>
            <BillingInformationCardDetailsWrapper>
              <CardDetails cardDetails={cardDetails} />
            </BillingInformationCardDetailsWrapper>

            <BillingAddressRow cardDetails={Boolean(cardDetails?.brand)} id='billing-page-address'>
              <StyledBoldP text={t('billing_address')} />
              <BillingAddress billingAddressDetails={billingAddressDetails} />
            </BillingAddressRow>
          </BillingInformationWrapper>

          <CardFooter>
            <AccountCreatedWrapper>
              <StyledBoldP text={t('account_created')} />
              <StyledMediumP text={accountCreatedDate} />
            </AccountCreatedWrapper>
            {showViewBillingHistoryBtn && (
              <>
                <Link
                  behavesAs='button'
                  onClick={handleBillingHistoryModal}
                  text={t('view_billing_history')}
                />
                {showStripeBillingHistoryModal && (
                  <BillingHistoryModal
                    onCloseRequest={() => setShowStripeBillingHistoryModal(false)}
                  />
                )}
              </>
            )}
          </CardFooter>
        </>
      ) : (
        <BillingInfoEmptyState />
      )}
    </CardWrapper>
  );
};

export default BillingPageInformationCard;
