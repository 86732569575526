import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  &:last-child {
    margin-bottom: 0;
  }
  label {
    align-items: center;
  }
`;
