import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

import usePageTitle from '../hooks/usePageTitle';
import { EditorCourse, EditorCurriculum } from '../types/Editor';

type DisabledEditorModalStates = {
  showCurriculumModal: boolean;
  showResetModal: boolean;
  setShowCurriculumModal: Dispatch<SetStateAction<boolean>>;
  setShowResetModal: Dispatch<SetStateAction<boolean>>;
};

type EditorCourseProps = {
  children: ReactNode;
  course: EditorCourse;
  curriculum: EditorCurriculum;
  stepId: number;
  productTerm: string;
};

type EditorCourseContextType = DisabledEditorModalStates & EditorCourseProps;

const EditorCourseContext = React.createContext({} as Omit<EditorCourseContextType, 'children'>);
export const useEditorCourseContext = () => useContext(EditorCourseContext);
const EditorCourseProvider = ({
  children,
  course,
  curriculum,
  stepId,
  productTerm,
}: EditorCourseProps): ReactElement => {
  usePageTitle({ resourceName: course.title, resourceType: 'course', productTerm });
  const [showResetModal, setShowResetModal] = useState(false);
  const [showCurriculumModal, setShowCurriculumModal] = useState(true);

  return (
    <EditorCourseContext.Provider
      value={{
        setShowResetModal,
        setShowCurriculumModal,
        showResetModal,
        showCurriculumModal,
        course,
        curriculum,
        stepId,
        productTerm,
      }}
    >
      {children}
    </EditorCourseContext.Provider>
  );
};

export { EditorCourseProvider };
