import React from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import LabelToolbarButton from '../LabelToolbarButton';

const t = initTranslations('editor');

interface Props {
  active: boolean;
  className: string;
  onClick: () => void;
}

const AddVideoToolbarButton = ({ active, className, onClick }: Props) => {
  return (
    <LabelToolbarButton
      active={active}
      ariaLabel={t('aria_label_video_button')}
      className={className}
      dataTestId='video-flyout-button'
      iconName='video'
      iconType='name'
      id='video-flyout-button'
      label={t('video_button')}
      onClick={onClick}
    />
  );
};

export default AddVideoToolbarButton;
