import useCurrentAccount from '../useCurrentAccount';
import { useGetTrialDefaultPlan } from './useGetTrialDefaultPlan';

export function useGetDefaultAccountPlan() {
  const { onSmallPlan, onMediumPlan, onGrowthPlan, onCustomPlan, status } = useCurrentAccount();
  const isTrialingOrTrialEnded = status === 'trialing' || status === 'trial_ended';
  const trialDefaultPlan = useGetTrialDefaultPlan();

  function getActiveDefaultPlan() {
    if (onSmallPlan) {
      return 'small';
    }

    if (onMediumPlan) {
      return 'medium';
    }

    if (onGrowthPlan) {
      return 'growth';
    }

    if (onCustomPlan) {
      return 'custom';
    }

    return 'growth';
  }

  return isTrialingOrTrialEnded ? trialDefaultPlan : getActiveDefaultPlan();
}
