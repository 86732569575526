import React, { useEffect, useState } from 'react';

import initTranslations from '../../../../lib/initTranslations';
import {
  BannerContainer,
  BannerLeftSection,
  BannerTitle,
  BodyText,
  ButtonsRow,
  ImageContainer,
  StyledIconButton,
  StyledLink,
  StyledPrimaryButton,
  StyledSecondaryButton,
} from './styles';
import { DismissableBannerProps } from './types';

const t = initTranslations('dismissable_banner.action_buttons');

const DismissableBanner = (props: DismissableBannerProps) => {
  const {
    title,
    body,
    image,
    backgroundColor,
    type,
    linkProps,
    primaryButtonProps,
    secondaryButtonProps,
    showCloseIcon = false,
    onDismiss,
  } = props;
  const localStorageKey = `banner-dismissed-${type}`;
  const localStorageBannerDismissed = localStorage.getItem(localStorageKey) === 'true';
  const [bannerDismissed, setBannerDismissed] = useState(true);

  const handleClickOnDismissButton = () => {
    localStorage.setItem(localStorageKey, 'true');
    setBannerDismissed(true);
    onDismiss && onDismiss();
  };

  useEffect(() => {
    setBannerDismissed(localStorageBannerDismissed);
  }, [localStorageBannerDismissed]);

  if (localStorageBannerDismissed || bannerDismissed) return <></>;

  return (
    <BannerContainer
      backgroundColor={backgroundColor}
      className='dismissable-banner'
      id={`${type}-banner`}
    >
      {showCloseIcon && (
        <StyledIconButton
          ariaLabel={t('dismiss')}
          className='banner-dismiss-button'
          name='times'
          onClick={handleClickOnDismissButton}
          weight='solid'
        />
      )}
      <BannerLeftSection>
        <BannerTitle>{title}</BannerTitle>
        <BodyText>{body}</BodyText>
        <ButtonsRow>
          {primaryButtonProps && (
            <StyledPrimaryButton
              buttonType='primary'
              id={primaryButtonProps.id}
              onClick={primaryButtonProps.onClick}
              text={primaryButtonProps.text}
            />
          )}
          {secondaryButtonProps && (
            <StyledSecondaryButton
              buttonType='secondary'
              id={secondaryButtonProps.id}
              onClick={secondaryButtonProps.onClick}
              text={secondaryButtonProps.text}
            />
          )}
          {!showCloseIcon && (
            <StyledSecondaryButton
              buttonType='secondary'
              id={`dismiss-banner-${type}-button`}
              onClick={() => {
                handleClickOnDismissButton();
              }}
              size='lg'
              text={t('dismiss')}
            />
          )}
          {linkProps && <StyledLink href={linkProps.href} target='_blank' text={linkProps.title} />}
        </ButtonsRow>
      </BannerLeftSection>
      <ImageContainer>
        <img alt={title} src={image} />
      </ImageContainer>
    </BannerContainer>
  );
};

export default DismissableBanner;
