import { filter, remove } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { Suggestion } from '../../../../../../types/Suggestion';

type Action =
  | { type: 'loaded'; data: Suggestion[] }
  | { type: 'addPill'; id: string }
  | { type: 'removePill'; id: string }
  | { type: 'addCustom'; name: string };

interface State {
  unselectedPills: Suggestion[];
  selectedPills: Suggestion[];
}

export const initialState: State = {
  unselectedPills: [],
  selectedPills: [],
};

export const reducer = (state: State, action: Action): State => {
  const { type } = action;
  const { unselectedPills, selectedPills } = state;

  let selectedPillsState: Suggestion[];
  let unselectedPillsState: Suggestion[];

  switch (type) {
    case 'loaded': {
      selectedPillsState = [];
      unselectedPillsState = [...action.data];
      break;
    }
    case 'addPill': {
      const selectedSuggestion = remove(
        unselectedPills,
        (suggestion) => suggestion.id === action.id
      );
      selectedPillsState = [...selectedPills, ...selectedSuggestion];
      unselectedPillsState = unselectedPills;
      break;
    }
    case 'removePill': {
      const selectedSuggestion = remove(
        selectedPills,
        (suggestion) => suggestion.id === action.id
      )[0];
      selectedPillsState = selectedPills;
      unselectedPillsState = unselectedPills;
      if (selectedSuggestion && selectedSuggestion.kind !== 'custom') {
        unselectedPillsState = [...unselectedPillsState, selectedSuggestion];
      }
      break;
    }
    case 'addCustom': {
      let suggestion = remove(
        unselectedPills,
        (suggestion) => suggestion.name.toLowerCase() === action.name.toLowerCase()
      )[0];

      unselectedPillsState = unselectedPills;
      if (!suggestion) {
        /*
        Adding a custom uuid id here to avoid any possible collisions with the pills key
        Setting a hardcoded ranking here as the ranking does not apply for selected pills
       */
        suggestion = {
          id: `${uuidv4()}-custom-${action.name}`,
          name: action.name,
          frequency: 1,
          kind: 'custom',
        };
      }
      selectedPillsState = filter(
        [...selectedPills, suggestion],
        (suggestion) => suggestion.name.trim() !== ''
      );

      break;
    }
  }
  return {
    unselectedPills: unselectedPillsState,
    selectedPills: selectedPillsState,
  };
};
