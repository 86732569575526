import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import { useIsAdmin } from '../../../../../hooks/users/useIsPermission';
import initTranslations from '../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { routes } from '../../../publicApplication/applicationRouter';
import NotificationUserName from '../../notifications/NotificationUserName/NotificationUserName';
import { TextFragment } from '../styles';
import { ActivityAssociationParams } from '../types';

const t = initTranslations('reports.activity_log');

export interface AssignedResponsibilityCommonTemplateProps {
  activityKey: string;
  recipient: ActivityAssociationParams;
  parameters: { responsibiliable_type: string; trackable_name: string };
}

const CommonTemplate = ({
  recipient,
  activityKey,
  parameters,
}: AssignedResponsibilityCommonTemplateProps) => {
  const { slug } = useCurrentAccount();
  const {
    responsibility: { singular: responsibilitySingular },
  } = useAccountTerminology();
  const isAdmin = useIsAdmin();
  const isGroup = parameters.responsibiliable_type == 'Group';
  const isSourceActive = recipient.is_active && (!isGroup || (isGroup && isAdmin));
  const recipientTranslation = isGroup
    ? t('assigned_responsibility.the_group')
    : t('assigned_responsibility.the_user');

  const groupRoute = routes.group({
    slug,
    id: recipient.id,
    breadcrumb: routes.groups({ slug }),
    tab: 'overview',
  });

  return (
    <>
      <TextFragment>
        {t(activityKey, { responsibility: responsibilitySingular.toLowerCase() })}
      </TextFragment>
      <b>{parameters.trackable_name}</b>
      <TextFragment>
        <>
          {activityKey == 'assigned_responsibility.destroy'
            ? `${t('assigned_responsibility.from')} `
            : `${t('assigned_responsibility.for')} `}
          {recipientTranslation}
        </>
      </TextFragment>
      {isGroup ? (
        <SourceBadge
          readOnly={!isSourceActive}
          sourceName='group'
          sourceRoute={groupRoute}
          sourceText={recipient.name}
        />
      ) : (
        <NotificationUserName
          user={{
            is_active: isSourceActive,
            id: recipient.id,
            name: recipient.name,
          }}
        />
      )}
    </>
  );
};

export default CommonTemplate;
