import React, { useCallback, useState } from 'react';

import initTranslations from '../../../../lib/initTranslations';
import {
  useGetCurriculumQuery,
  useUpdateCurriculumServiceMutation,
} from '../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import AiDescriptionModal from '../AiDescriptionModal/AiDescriptionModal';
import { CompletionResponses } from '../GroupDescriptionModal/types';
import { getSubjectPromptMessages } from './SubjectPromptMessages';
import { SubjectDescriptionModalProps } from './types';

const t = initTranslations('curriculum_description_modal');

const SubjectDescriptionModal = ({
  closeModal,
  composeModalType,
  metadata: { curriculumId },
}: SubjectDescriptionModalProps) => {
  const { data: curriculum } = useGetCurriculumQuery(curriculumId);
  const [updateCurriculum, resultUpdateCurriculum] = useUpdateCurriculumServiceMutation();
  const [currentCompletionResponse, setCurrentCompletionResponse] = useState<CompletionResponses>({
    completion: undefined,
    prevCompletion: undefined,
    hasGeneratedSuccessfully: false,
  });
  const { completion } = currentCompletionResponse;
  const {
    curriculum: { singular: curriculumSingular },
  } = useAccountTerminology();

  const saveToCurriculum = useCallback(() => {
    if (completion?.status !== 'completed') return;
    if (!curriculum) return;

    updateCurriculum({ id: curriculum.id, description: completion.completion });
  }, [completion, updateCurriculum, curriculum]);

  const { reset } = resultUpdateCurriculum;

  return (
    <AiDescriptionModal
      aiFeatureName='composeCurriculumDescription'
      closeModal={closeModal}
      composeFeatureName='composeCurriculumDescription'
      composeModalType={composeModalType}
      currentCompletionResponse={currentCompletionResponse}
      entity={curriculum}
      flashNotificationMessage={t('saved_suggested_description', { subject: curriculumSingular })}
      flashNotificationResult={resultUpdateCurriculum}
      loadingText={t('generating_subject_description', {
        subject: curriculumSingular.toLowerCase(),
      })}
      localeKey='curriculum_description_modal'
      modalTitle={t('title', { subject: curriculumSingular.toLowerCase() })}
      promptMessages={getSubjectPromptMessages(curriculum)}
      resetCompletion={reset}
      saveToEntity={saveToCurriculum}
      setCurrentCompletionResponse={setCurrentCompletionResponse}
    />
  );
};

export default SubjectDescriptionModal;
