import styled, { css } from 'styled-components';

import cssSpacing from '../../../../../../lib/cssSpacing';
import Icon from '../../../../../design_system/display/icons/Icon';
import { fontMd1, fontMd4, fontSm4 } from '../../../../../styled/TypeSystem';
import { SpaceBetweenWrapper } from '../../styles';

export const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme: { constants } }) => `${constants.spacerLg1}`};
`;

export const PlanName = styled.span<{ planBrandColor: string }>`
  color: ${({ planBrandColor }) => planBrandColor};
  font-weight: ${({ theme: { constants } }) => constants.fontExtrabold};
  ${fontMd1};
`;

export const PlanPrice = styled.span`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontMd4};
`;

export const PlanCreditTitle = styled.span`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const summarySectionDividerStyles = css`
  background-color: ${({ theme: { vars } }) => vars.borderSurface2};
  width: auto;
  height: 0.063rem;
`;

export const Divider = styled.div`
  ${cssSpacing.vertical.margin(0.5)};
  ${summarySectionDividerStyles};
`;

export const PlanCreditWrapper = styled(SpaceBetweenWrapper)`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${fontMd1};
`;

export const StyledIcon = styled(Icon)`
  pointer-events: none;
`;

export const PlanCreditTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DueTodayWrapper = styled(SpaceBetweenWrapper)`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontMd1};
`;

export const AdditionalInfo = styled.span`
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontSm4};
`;

export const AddonsSectionWrapper = styled.div`
  margin: ${({ theme: { constants } }) => constants.spacerSm3} 0;
`;

export const TooltipIconWrapper = styled.div`
  display: flex;
  width: 1rem;
  height: ${({ theme: { constants } }) => constants.height2xs};
  cursor: pointer;

  &:hover {
    color: ${({ theme: { vars } }) => vars.borderSurface2};
  }
`;
