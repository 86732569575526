import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import useWindowResize from '../../../../hooks/useWindowResize';
import { mediaBreakpointPxXl, mediaBreakpointXl } from '../../../styled/Breakpoint';
import FlexContainer from '../../../styled/FlexContainer';
import Logo from '../Logo/Logo';
import HamburgerMenu from './HamburgerMenu';
import QuickSearchField from './QuickSearch/QuickSearchField/QuickSearchField';

const LogoAndSearchWrapper = styled(FlexContainer)`
  flex-direction: row-reverse !important;
  justify-content: flex-end;
  width: 100%;
  margin-left: ${({ theme }) => theme.constants.spacerSm3};

  @media (min-width: ${mediaBreakpointPxXl}) {
    flex-direction: row !important;
    justify-content: flex-start;
    margin-left: 0;
  }
`;

const LogoWrapper = styled.div<{ hideLogo: boolean }>`
  display: ${({ hideLogo }) => (hideLogo ? 'none' : 'flex')};
  justify-content: center;
  width: 100%;
  @media (min-width: ${mediaBreakpointPxXl}) {
    width: auto;
  }
`;

const LogoAndSearchBar = () => {
  const [inputFocused, setInputFocused] = useState(false);
  const { width } = useWindowResize();
  const isMobile = useMemo(() => width < mediaBreakpointXl, [width]);
  const ability = useCurrentUserAbilities();
  const logoHidden = !isMobile || inputFocused;

  return (
    <LogoAndSearchWrapper align='center'>
      <LogoWrapper hideLogo={logoHidden} id='top-nav-home'>
        <Logo hideLogo={logoHidden} />
      </LogoWrapper>
      {ability.can('read', 'NavigationSearch') && (
        <QuickSearchField inputFocused={inputFocused} setInputFocused={setInputFocused} />
      )}
      {isMobile && <HamburgerMenu />}
    </LogoAndSearchWrapper>
  );
};

export default LogoAndSearchBar;
