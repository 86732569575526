import React, { ReactNode } from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import { SignatureStatusWrapper, StyledStatusIcon } from '../../Columns/styles';
import { ElementSignatureStatus, TableColumnProps } from '../types';

const t = initTranslations('reports.individual_user_report.table.columns.signature');

const SignatureStatus = ({ record: { eSignatureStatus } }: TableColumnProps) => {
  const STATUSES_KEYS: { [key in ElementSignatureStatus]: ReactNode } = {
    empty: '',
    in_progress: (
      <>
        <StyledStatusIcon name='clock' size='sm' weight='regular' />
        {t('waiting')}
      </>
    ),
    signed: (
      <>
        <StyledStatusIcon isSuccess name='check' size='sm' weight='solid' />
        {t(eSignatureStatus)}
      </>
    ),
    not_signed: (
      <>
        <StyledStatusIcon name='circle-xmark' size='sm' weight='regular' />
        {t(eSignatureStatus)}
      </>
    ),
  };

  return <SignatureStatusWrapper>{STATUSES_KEYS[eSignatureStatus]}</SignatureStatusWrapper>;
};

export default SignatureStatus;
