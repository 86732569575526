import React, { FC } from 'react';

import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import { BADGE_TYPES } from '../_data';
import { ContentBadgeProps, TypeName } from '../types';
import { StyledBadge } from './styles';

const ContentBadge: FC<ContentBadgeProps> = ({ typeName }) => {
  const {
    curriculum: { singular: curriculumSingular },
    topic: { singular: topicSingular },
    step: { singular: stepSingular },
  } = useAccountTerminology();

  const BADGE_COPIES: Record<TypeName, string> = {
    [TypeName.Curriculum]: curriculumSingular,
    [TypeName.Course]: topicSingular,
    [TypeName.Step]: stepSingular,
  };

  const hasBorder = typeName === TypeName.Curriculum;

  return (
    <StyledBadge hasBorder={hasBorder} text={BADGE_COPIES[typeName]} type={BADGE_TYPES[typeName]} />
  );
};

export default ContentBadge;
