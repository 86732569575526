export type BillingPlanName =
  | SimplePricingBillingPlanName
  | PerUserBillingPlanName
  | 'hold'
  | 'premium'
  | 'pro'
  | 'free'
  | 'build';

export type SimplePricingBillingPlanName = 'small' | 'growth' | 'unlimited' | 'medium' | 'custom';
export type PerUserBillingPlanName = 'train' | 'scale';

export const billingPlanTypes = ['train', 'scale'] as PerUserBillingPlanName[];
export const simplePricingBillingPlanTypes = [
  'small',
  'growth',
  'unlimited',
] as SimplePricingBillingPlanName[];

export const simplePricingV3BillingPlanTypes = [
  'small',
  'medium',
  'growth',
  'custom',
] as SimplePricingBillingPlanName[];
