import React from 'react';

import useCurrentAccount from '../../../../../../../hooks/useCurrentAccount';
import { AddonItem as AddonItemType } from '../../../../../../../redux/services/resourceApis/billing/types';
import { BillingInterval } from '../../../../../../../types/BillingInterval';
import AddonsSection from '../../../AddonsSection/AddonsSection';
import ManageableAddonsSection from '../ManageableAddonsSection/ManageableAddonsSection';
import { AddonsSectionWrapper } from '../styles';

export type AddonsSectionProps = {
  billingInterval?: BillingInterval;
  addonItems: AddonItemType[];
};

const AddonsSectionFactory = ({ addonItems, billingInterval }: AddonsSectionProps) => {
  const {
    hasAnyAddons,
    splitFeatures: { trainualPlusAddonWidgetEnabled },
  } = useCurrentAccount();
  const sectionProps = { addonItems, billingInterval };
  const showManageableAddonsSection = hasAnyAddons && trainualPlusAddonWidgetEnabled;

  return (
    <AddonsSectionWrapper>
      {showManageableAddonsSection ? (
        <ManageableAddonsSection {...sectionProps} />
      ) : (
        <AddonsSection {...sectionProps} />
      )}
    </AddonsSectionWrapper>
  );
};

export default AddonsSectionFactory;
