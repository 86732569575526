import React from 'react';
import { Route } from 'type-route';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import { routes } from '../../publicApplication/applicationRouter';
import RouterLink from '../../publicApplication/RouterLink';

type Props = {
  className?: string;
  children: JSX.Element;
  id: number;
  breadcrumb: Route<typeof routes>;
};

/*
 type-route: This is a temporary component to accommodate the transition from the old legacy
 views to the new client side routing. This component will be removed once all the legacy views
 have been converted to client side routing.

 Notes to deprecate: Just use RouterLink directly
 */
const ProfileRouterLink = ({ children, className, id, breadcrumb }: Props) => {
  const { slug } = useCurrentAccount();
  const routeBreadCrumb = breadcrumb.name === false ? routes.manageUsers({ slug }) : breadcrumb;

  return (
    <RouterLink
      className={className}
      to={routes.userProfile({ slug, id, breadcrumb: routeBreadCrumb })}
    >
      {children}
    </RouterLink>
  );
};

export default ProfileRouterLink;
