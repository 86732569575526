import { IndividualUserReportPageFilterState } from '../../../../../redux/domains/reports/reportsSlice';
import { Action } from './types';

export const filterReducer = (
  state: IndividualUserReportPageFilterState,
  action: Action
): IndividualUserReportPageFilterState => {
  switch (action.type) {
    case 'setMinCompletionScore': {
      return {
        ...state,
        completionScoreMin: action.completionScoreMin,
      };
    }
    case 'setMaxCompletionScore': {
      return {
        ...state,
        completionScoreMax: action.completionScoreMax,
      };
    }
    case 'setGroups': {
      return {
        ...state,
        groupIds: action.groupIds,
      };
    }
    case 'setTeams': {
      return {
        ...state,
        teamIds: action.teamIds,
      };
    }
    case 'setRoles': {
      return {
        ...state,
        roleIds: action.roleIds,
      };
    }
    case 'setStatuses': {
      return {
        ...state,
        statuses: action.statuses,
      };
    }
    case 'setSignature': {
      return {
        ...state,
        eSignature: action.eSignature,
      };
    }
    case 'setTests': {
      return {
        ...state,
        tests: action.tests,
      };
    }
    case 'setDefaultFilters': {
      return {
        ...state,
        completionScoreMin: 0,
        completionScoreMax: 100,
        eSignature: [],
        groupIds: [],
        teamIds: [],
        tests: [],
        statuses: [],
      };
    }
  }
};
