import styled from 'styled-components';

import Icon from '../../../../../../design_system/display/icons/Icon';
import P from '../../../../../../design_system/text/P';
import { TitleUnderline } from '../../../../shared/styles';

export const FeaturesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FeatureWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const IncludedTrainFeaturesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const TrainText = styled.span`
  color: ${({ theme: { vars } }) => vars.trainualBrandPurpleMedium};
`;

export const IncludedTrainFeaturesText = styled(P)`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};

  &:after {
    ${TitleUnderline};
    display: block;
    position: relative;
    bottom: -0.1rem;
    height: 0.175rem;
  }
`;

export const ArrowIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;
