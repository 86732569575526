import styled, { css } from 'styled-components';

import { mediaBreakpointPxMd } from '../../../styled/Breakpoint';
import { fontLg1, fontSm5 } from '../../../styled/TypeSystem';
import DefaultButton from '../../buttons/DefaultButton';
import IconButton from '../../buttons/IconButton';
import Link from '../../Link';

export const BannerContainer = styled.div<{ backgroundColor: string }>(
  ({ backgroundColor, theme: { constants } }) => css`
    display: flex;
    justify-content: space-between;
    position: relative;
    background: ${backgroundColor};
    border-radius: ${constants.borderRadiusLg};
    margin-bottom: ${constants.spacerMd2};
  `
);

export const BannerLeftSection = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: ${constants.spacerMd3} ${constants.spacerLg2};
  `
);

export const ImageContainer = styled.div(
  ({ theme: { constants } }) => css`
    display: none;
    overflow: hidden;
    min-width: 15rem;
    margin-right: ${constants.spacerLg2};

    @media (min-width: ${mediaBreakpointPxMd}) {
      display: flex;
      justify-content: flex-end;
    }

    img {
      height: auto;
      width: 100%;
    }
  `
);

export const BannerTitle = styled.div(
  ({ theme: { constants, vars } }) => css`
    color: ${vars.textDefault};
    font-weight: ${constants.fontSemibold};
    ${fontLg1};
  `
);

export const BodyText = styled.div(
  ({ theme: { constants, vars } }) => css`
    color: ${vars.textDefault};
    padding-top: ${constants.spacerSm3};
    max-width: 40rem;
    ${fontSm5};
  `
);

export const ButtonsRow = styled.div(
  ({ theme: { constants } }) => css`
    align-items: baseline;
    display: flex;
    justify-content: flex-start;
    margin-top: ${constants.spacerMd3};
  `
);

const StyledButton = styled(DefaultButton)(
  ({ theme: { constants } }) => css`
    font-weight: ${constants.fontMedium};
    border-width: ${constants.borderWidthSm};
    border-style: solid;
    border-color: transparent;

    :first-child {
      margin-right: ${constants.spacerMd2};
    }
  `
);

export const StyledPrimaryButton = styled(StyledButton)(
  ({ theme: { vars } }) => css`
    color: ${vars.foundationSurface1};
    background-color: ${vars.trainualBrandBlueStrong};

    &:hover {
      background-color: ${vars.borderHover};
      color: ${vars.foundationSurface1};
      border-color: transparent;
    }

    &:focus {
      color: ${vars.foundationSurface1};
      background-color: ${vars.trainualBrandBlueStrong};
      border-color: transparent;
    }
  `
);

export const StyledSecondaryButton = styled(StyledButton)(
  ({ theme: { vars } }) => css`
    color: ${vars.foundationSurface2};
    background-color: ${vars.foundationSurface1};

    &:hover {
      background-color: ${vars.foundationSurface1};
      color: ${vars.foundationSurface2};
      border-color: ${vars.borderHover};
    }
  `
);

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const StyledLink = styled(Link)(
  ({ theme: { vars } }) => css`
    color: ${vars.textDefault};
  `
);
