import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import useActionCableChannel from '../../../../hooks/useActionCableChannel';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import { useMarkAllNotificationsAsReadMutation } from '../../../../redux/services/resourceApis/notifications/notificationsApi';
import { UserNotifications } from '../../../../types/CurrentUser';
import IconButton from '../../../design_system/buttons/IconButton';
import Badge from '../../../design_system/display/badge';
import { mediaBreakpointPxMd } from '../../../styled/Breakpoint';
import { routes } from '../../publicApplication/applicationRouter';
import routeTo from '../../publicApplication/routeTo';

const ClickableNotificationIcon = styled(IconButton)`
  padding: ${(props) => props.theme.constants.spacerSm2};
  padding-top: 6px;
`;

const NotificationBadge = styled(Badge)`
  position: absolute;
  top: 7px;
  right: 1px;
  height: 13px;
  width: 13px;
  cursor: pointer;

  @media (min-width: ${mediaBreakpointPxMd}) {
    height: 10px;
    width: 10px;
  }
`;

const NotificationBadgeWrapper = styled.div`
  position: relative;
  margin-left: ${(props) => props.theme.constants.spacerSm2};

  @media (min-width: ${mediaBreakpointPxMd}) {
    margin-left: ${(props) => props.theme.constants.spacerSm3};
    margin-right: ${(props) => props.theme.constants.spacerSm3};
  }
`;

const t = initTranslations('top_nav');

const Notifications = ({ notifications }: UserNotifications) => {
  const { slug } = useCurrentAccount();
  const [markAllAsRead] = useMarkAllNotificationsAsReadMutation();
  const [showUnreadNotifications, setShowUnreadNotifications] = React.useState(
    notifications.unreadCount > 0
  );

  const redirectWithMarkAllRead = () => {
    if (showUnreadNotifications) {
      markAllAsRead();
    }
    routeTo(routes.notifications({ slug }));
  };

  const channelProps = useMemo(() => {
    return {
      channel: 'NotificationsChannel',
    };
  }, []);

  const received = useCallback((notificationsResponse: { show_unread_notifications: boolean }) => {
    setShowUnreadNotifications(notificationsResponse.show_unread_notifications);
  }, []);

  useActionCableChannel<{ show_unread_notifications: boolean }>(channelProps, received);

  return (
    <NotificationBadgeWrapper id='top-nav-notifications' onClick={redirectWithMarkAllRead}>
      {showUnreadNotifications && (
        <NotificationBadge className='notification-badge' size='circle' type='error' />
      )}

      <ClickableNotificationIcon
        ariaLabel={
          showUnreadNotifications
            ? t('aria_label_new_notifications')
            : t('aria_label_notifications')
        }
        id='notification-icon'
        name='bell'
      />
    </NotificationBadgeWrapper>
  );
};

export default Notifications;
