import React from 'react';

import AddTeammateModal from './AddTeammateModal/AddTeammateModal';
import EditTeammateModal from './EditTeammateModal/EditTeammateModal';
import { Props } from './InterfaceAndTypes';

const TeammateModal = ({ action, onSuccess }: Props) => {
  if (action === 'edit') {
    return <EditTeammateModal onSuccess={onSuccess} />;
  }

  return <AddTeammateModal onSuccess={onSuccess} />;
};

export default TeammateModal;
