import mojs from '@mojs/core';
import { darken } from 'polished';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import useMoAnimation from '../../../../hooks/useMoAnimation';
import initTranslations from '../../../../lib/initTranslations';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';
import ComposeModalFactory, { ComposeModalType } from '../ComposeModalFactory/ComposeModalFactory';
import { ComposeModalMetadata } from '../shared/modals/shared';

type ComposeButtonBackground = 'transparent' | 'gray';

const StyledComposeButton = styled(DefaultButton)<{ background: ComposeButtonBackground }>(
  ({ background, disabled, theme: { vars, mode } }) => css`
    border: none;
    padding: 0;
    margin: 0;
    color: ${vars.trainualBrandPurpleMedium};
    svg {
      color: ${vars.trainualBrandPurpleMedium};
    }

    &:hover,
    &:active,
    &:focus {
      ${background === 'transparent' &&
      !disabled &&
      css`
        background-color: inherit;
      `};

      color: ${darken(0.2, mode === 'light' ? vars.trainualBrandPurpleMedium : vars.textDefault)};
      svg {
        color: ${darken(0.2, mode === 'light' ? vars.trainualBrandPurpleMedium : vars.textDefault)};
      }
    }
  `
);

const ButtonWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const AnimatedButtonWrapper = styled.div<{ visible: boolean }>`
  position: relative;
  width: auto;
  top: ${({ visible }) => (visible ? '0' : '-2rem')};
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  max-height: ${({ visible }) => (visible ? '4rem' : '0')};
  transition: opacity 0.5s, top 0.5s, max-height 0.3s;
`;

const t = initTranslations('compose');

type DisabledProps =
  | {
      disabled: boolean;
      disabledTooltipText?: string;
    }
  | {
      disabled?: never;
      disabledTooltipText?: never;
    };

export type Props = {
  composeModalType: ComposeModalType;
  customButton?: (onClick: () => void | undefined) => ReactElement<HTMLButtonElement>;
  background?: ComposeButtonBackground;
  onComposeButtonLoadingChange?: (loading: boolean) => void;
  onComposeButtonDisabledChange?: (disabled: boolean) => void;
  text?: string;
  metadata?: ComposeModalMetadata;
  showButton?: boolean;
} & DisabledProps;

const ComposeButton = ({
  composeModalType,
  customButton,
  background = 'transparent',
  disabled = false,
  disabledTooltipText,
  onComposeButtonLoadingChange,
  onComposeButtonDisabledChange,
  text = t('button_text'),
  metadata,
  showButton = true,
}: Props) => {
  const [composeButtonLoading, setComposeButtonLoading] = useState(false);
  const [composeButtonDisabled, setComposeButtonDisabled] = useState(disabled);
  const [modalOpen, setModalOpen] = useState(false);
  const iconContainerRef = useRef<HTMLDivElement | null>(null);
  const animationRef = useRef<ShapeAnimation | null>(null);
  const isFirstMount = useRef(true);
  const loadingRef = useRef(composeButtonLoading);

  const configureAnimation = useCallback(() => {
    if (animationRef.current || customButton) return;

    animationRef.current = new mojs.Burst({
      radius: { 10: 18 },
      count: 15,
      parent: iconContainerRef.current,
      children: {
        shape: 'polygon',
        points: 6,
        radius: 1.5,
        fill: { '#6A28EA': '#B594F5' },
        rotate: { 360: 0 },
        strokeWidth: { 1: 0 },
        duration: 2000,
        repeat: 999, // infinite
        delay: 'stagger( rand(0, 200) )',
      },
    });
  }, [customButton]);

  useMoAnimation({ configureAnimation });

  const handleHover = useCallback((isHovering) => {
    isHovering ? animationRef.current?.play() : animationRef.current?.stop();
  }, []);

  useEffect(() => {
    setComposeButtonDisabled(disabled);
    onComposeButtonDisabledChange?.(disabled);
  }, [disabled, onComposeButtonDisabledChange]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    loadingRef.current = composeButtonLoading;
    if (isFirstMount.current) {
      animationRef.current?.play();

      timeout = setTimeout(() => {
        if (!loadingRef.current) {
          animationRef.current?.stop();
        }
      }, 10000);

      isFirstMount.current = false;
      return;
    }

    onComposeButtonLoadingChange?.(composeButtonLoading);
    composeButtonLoading ? animationRef.current?.play() : animationRef.current?.stop();

    return () => clearTimeout(timeout);
  }, [composeButtonLoading, onComposeButtonLoadingChange]);

  useEffect(() => {
    if (!modalOpen) {
      setComposeButtonLoading(false);
      setComposeButtonDisabled(disabled);
    }
  }, [disabled, modalOpen]);

  return (
    <>
      <>
        {disabledTooltipText && disabled && (
          <Tooltip id='compose-tooltip' place='bottom' text={disabledTooltipText} />
        )}
        {customButton ? (
          <AnimatedButtonWrapper visible={showButton}>
            {showButton ? customButton(() => setModalOpen(true)) : customButton}
          </AnimatedButtonWrapper>
        ) : (
          showButton && (
            <ButtonWrapper
              data-for='compose-tooltip'
              data-tip
              onMouseEnter={() => handleHover(true)}
              onMouseLeave={() => handleHover(false)}
            >
              <StyledComposeButton
                background={background}
                buttonType={background === 'transparent' ? 'secondary' : 'tertiary'}
                disabled={composeButtonDisabled}
                fullWidth
                iconName='wand-magic-sparkles'
                iconRef={iconContainerRef}
                id='compose-button-open-modal'
                loading={composeButtonLoading}
                onClick={() => setModalOpen(true)}
                text={text}
                type='button'
              />
            </ButtonWrapper>
          )
        )}
      </>
      <ComposeModalFactory
        closeModal={() => setModalOpen(false)}
        composeModalType={composeModalType}
        isComposeModalDisabled={composeButtonDisabled}
        isComposeModalLoading={composeButtonLoading}
        metadata={metadata}
        modalOpen={modalOpen}
        setComposeModalDisabled={setComposeButtonDisabled}
        setComposeModalLoading={setComposeButtonLoading}
      />
    </>
  );
};

export default ComposeButton;
