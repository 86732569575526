import React, { useState } from 'react';

import useCopyUrlLink from '../../../../../../hooks/useCopyUrlLink';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../../../hooks/useCurrentUserAbilities';
import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../lib/initTranslations';
import { useToggleArchivingMutation } from '../../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import ThreeDotMenu from '../../../../../design_system/Triage/menus/ThreeDotMenu';
import { ThreeDotMenuOptionProps } from '../../../../../design_system/Triage/menus/ThreeDotMenu/types';
import { routes } from '../../../../publicApplication/applicationRouter';
import DeleteSubjectModal from '../../.././CurriculumThreeDotOptions/DeleteSubjectModal';
import DuplicateModal from '../../.././CurriculumThreeDotOptions/DuplicateSubjectModal/DuplicateModal';
import ExportPdfModal from '../../../modals/ExportPDFModal';
import MoveCurriculumModal from '../../../modals/MoveCurriculumModal';
import { Props } from './types';

const t = initTranslations('curriculums.three_dot_menu');

const CurriculumThreeDotMenu = ({
  curriculumId,
  archived,
  description,
  title,
  renameClickHandler,
  ...propsToPass
}: Props) => {
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const ability = useCurrentUserAbilities();
  const {
    slug,
    splitFeatures: { curriculumPrintViewEnabled },
  } = useCurrentAccount();

  const [showMoveCurriculumModal, setShowMoveCurriculumModal] = useState(false);
  const [showDupSubjectModal, setShowDupSubjectModal] = useState(false);
  const [showDeleteSubjectModal, setShowDeleteSubjectModal] = useState(false);
  const [archive, archivedResult] = useToggleArchivingMutation();
  const [unarchive, unArchivedResult] = useToggleArchivingMutation();
  const curriculumRoute = routes.curriculumShow({ slug, id: curriculumId }).href;
  const curriculumEditRoute = routes.curriculumEdit({ slug, id: curriculumId }).href;
  const curriculumPrintRoute = routes.curriculumPrint({ slug, id: curriculumId }).href;
  const navigateToCurriculumEdit = () => (window.location.href = curriculumEditRoute);
  const navigateToPrintView = () => window.open(curriculumPrintRoute, '_blank');

  const {
    curriculum: { singular: subjectTermSingular },
  } = useAccountTerminology();
  const { copyUrlLink } = useCopyUrlLink();
  const copy = () => {
    curriculumRoute && copyUrlLink({ path: curriculumRoute, flashText: t('copy_link_flash') });
  };

  // TODO: Remove with Card View Code Removal - GS2-4299
  const contentPage = window.location.pathname.includes('content');

  const curriculumRestrictedToModify = ability.can(
    'read',
    `CurriculumRestrictedToModify-${curriculumId}`
  );

  //Archived
  useDisplayFlashOnResponse({
    result: archivedResult,
    successMessage: t('archive_success'),
    errorMessage: t('archive_error', { subject: subjectTermSingular }),
  });

  //Unarchived
  useDisplayFlashOnResponse({
    result: unArchivedResult,
    successMessage: t('unarchive_success'),
    errorMessage: t('unarchive_error', { subject: subjectTermSingular }),
  });

  const options: ThreeDotMenuOptionProps[] = [
    {
      title: t('edit'),
      onClick: () => {
        navigateToCurriculumEdit();
      },
      iconName: 'edit',
      iconWeight: 'regular',
      id: 'td-curriculum-edit',
      visible: ability.can('update', `EditCurriculum-${curriculumId}`),
    },
    {
      title: t('rename'),
      onClick: () => {
        if (renameClickHandler) {
          renameClickHandler();
        }
      },
      iconName: 'pen',
      iconWeight: 'regular',
      id: 'td-curriculum-rename',
      visible: contentPage,
    },
    {
      title: t('duplicate'),
      onClick: () => {
        setShowDupSubjectModal(true);
      },
      iconName: 'copy',
      iconWeight: 'regular',
      id: 'td-curriculum-duplicate',
      visible: ability.can('update', `CopyCurriculum-${curriculumId}`),
    },
    {
      title: t('move_to'),
      onClick: () => {
        setShowMoveCurriculumModal(true);
      },
      iconName: 'square-arrow-right',
      iconWeight: 'regular',
      id: 'td-curriculum-move',
      visible: ability.can('update', `MoveCurriculum-${curriculumId}`),
    },
    {
      title: t('copy_link'),
      onClick: () => {
        copy();
      },
      iconName: 'link',
      iconWeight: 'regular',
      id: 'td-curriculum-copy-link',
      visible: true,
    },
    {
      title: t('print'),
      onClick: () => {
        navigateToPrintView();
      },
      iconName: 'file-export',
      iconWeight: 'regular',
      id: 'td-curriculum-show-print-pdf',
      visible:
        curriculumPrintViewEnabled && ability.can('create', `ExportCurriculum-${curriculumId}`),
    },
    {
      title: t('email_pdf'),
      onClick: () => {
        setOpenPdfModal(true);
      },
      iconName: 'envelope',
      iconWeight: 'regular',
      id: 'td-curriculum-export-pdf',
      visible: ability.can('create', `ExportCurriculum-${curriculumId}`),
    },
    {
      title: t('archive'),
      onClick: () => {
        archive(curriculumId);
      },
      iconName: 'archive',
      iconWeight: 'regular',
      id: 'td-curriculum-archive',
      isDisabled: curriculumRestrictedToModify,
      tooltipText: curriculumRestrictedToModify ? t('disabled_by_e_signature') : '',
      visible: ability.can('update', `ArchiveCurriculum-${curriculumId}`) && !archived,
    },
    {
      title: t('unarchive'),
      onClick: () => {
        unarchive(curriculumId);
      },
      iconName: 'box-open',
      iconWeight: 'regular',
      id: 'td-curriculum-unarchive',
      visible: ability.can('update', `UnarchiveCurriculum-${curriculumId}`) && archived,
    },
    {
      title: 'Delete',
      onClick: () => {
        setShowDeleteSubjectModal(true);
      },
      iconName: 'trash-alt',
      iconWeight: 'regular',
      id: 'td-curriculum-delete',
      isDisabled: curriculumRestrictedToModify,
      tooltipText: curriculumRestrictedToModify ? t('disabled_by_e_signature') : '',
      visible: ability.can('update', `DestroyCurriculum-${curriculumId}`),
    },
  ];

  return (
    <>
      <ThreeDotMenu menuOptions={options} menuStrategy='absolute' {...propsToPass} />
      {showMoveCurriculumModal && (
        <MoveCurriculumModal
          curriculumId={curriculumId}
          setShowModal={setShowMoveCurriculumModal}
          showModal={showMoveCurriculumModal}
          title={title}
        />
      )}
      {showDupSubjectModal && (
        <DuplicateModal
          closeRequest={() => setShowDupSubjectModal(false)}
          description={description}
          id={curriculumId}
          title={title}
        />
      )}
      {openPdfModal && (
        <ExportPdfModal
          closePdfModal={() => setOpenPdfModal(false)}
          id={curriculumId}
          title={title}
        />
      )}
      {showDeleteSubjectModal && (
        <DeleteSubjectModal
          closeDeleteModal={() => setShowDeleteSubjectModal(false)}
          id={curriculumId}
          title={title}
        />
      )}
    </>
  );
};

export default CurriculumThreeDotMenu;
