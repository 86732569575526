import React, { ReactNode, useEffect, useRef, useState } from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import CoreModal, { ModalProps } from '../../../core/CoreModal';
import TaskModalBody from './TaskModalBody';
import TaskModalFooter from './TaskModalFooter';
import TaskModalHeader from './TaskModalHeader';
import {
  HeaderBadge,
  HeaderIcon,
  HeaderSubtitle,
  MinMaxModalHeight,
  PrimaryButton,
  TaskHead,
  TaskModalSize,
  TaskModalState,
  TertiaryButton,
} from './TaskModalTypes';

const t = initTranslations('task_modal');

const initialModalState: TaskModalState = {
  footerAndHeaderHeight: 0,
  bodyBorders: { renderTopBorder: false, renderBottomBorder: false },
};

export type Props = Omit<ModalProps, 'closeIconAriaLabel'> &
  PrimaryButton &
  TaskHead & {
    processing: boolean;
    tertiaryButton?: TertiaryButton;
    desktopSize?: TaskModalSize;
    secondaryButtonText?: string;
    scrollInsideBody?: boolean;
    closeIconAriaLabel?: string;
    footerContent?: ReactNode;
    headerImage?: string;
    minMaxModalHeight?: MinMaxModalHeight;
  };

const TaskModal = (props: Props) => {
  const {
    title,
    subtitle,
    subtitleLink,
    subtitleWithBoldedText,
    children,
    processing,
    primaryButtonTask,
    tertiaryButton,
    scrollInsideBody = false,
    headerImage,
    heapModalName,
    secondaryButtonText,
    onCloseRequest,
    iconName,
    iconWeight = 'regular',
    desktopSize = 'lg',
    primaryButtonText,
    isDisabled = false,
    badgeText,
    badgeType,
    badgeFontWeight,
    closeIconAriaLabel = t('aria_label_cancel'),
    headerChildren,
    headerDisclaimer,
    footerContent,
    minMaxModalHeight,
    withBetaTag,
    ...propsToPass
  } = props;
  const [modalState, setModalState] = useState<TaskModalState>(initialModalState);
  const headerRef = useRef<HTMLDivElement>(null);
  const bodyWrapperRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollInsideBody && headerRef.current && footerRef.current && bodyWrapperRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        if (scrollInsideBody && headerRef.current && footerRef.current && bodyWrapperRef.current) {
          setModalState({
            footerAndHeaderHeight: headerRef.current.clientHeight + footerRef.current.clientHeight,
            bodyBorders: {
              renderTopBorder: bodyWrapperRef.current.scrollTop > 0,
              renderBottomBorder:
                bodyWrapperRef.current.scrollHeight > bodyWrapperRef.current.offsetHeight,
            },
          });
        }
      });
      resizeObserver.observe(bodyWrapperRef.current);
      return () => resizeObserver.disconnect();
    }
  }, [scrollInsideBody]);

  const taskModalFooterPrimaryButtonProps: PrimaryButton =
    primaryButtonTask && primaryButtonText
      ? {
          primaryButtonTask,
          primaryButtonText,
          isDisabled,
        }
      : {};

  const headerIconProps: HeaderIcon = iconName ? { iconName, iconWeight } : {};
  const headerBadgeProps: HeaderBadge =
    badgeText && badgeType ? { badgeText, badgeType, badgeFontWeight } : {};
  const headerSubtitleProps: HeaderSubtitle = subtitle
    ? {
        subtitle,
      }
    : subtitleLink
    ? { subtitleLink }
    : subtitleWithBoldedText
    ? { subtitleWithBoldedText }
    : {};

  return (
    <CoreModal
      closeIconAriaLabel={closeIconAriaLabel}
      desktopSize={desktopSize}
      headerImage={headerImage}
      heapModalName={heapModalName}
      onCloseRequest={onCloseRequest}
      {...propsToPass}
    >
      <TaskModalHeader
        {...headerIconProps}
        {...headerBadgeProps}
        {...headerSubtitleProps}
        forwardRef={headerRef}
        headerChildren={headerChildren}
        headerDisclaimer={headerDisclaimer}
        title={title}
        withBetaTag={withBetaTag}
      />
      <TaskModalBody
        desktopSize={desktopSize}
        forwardRef={bodyWrapperRef}
        minMaxModalHeight={minMaxModalHeight}
        modalState={modalState}
        scrollInsideBody={scrollInsideBody}
        setModalState={setModalState}
      >
        {children}
      </TaskModalBody>
      <TaskModalFooter
        {...taskModalFooterPrimaryButtonProps}
        footerContent={footerContent}
        forwardRef={footerRef}
        heapModalName={heapModalName}
        onCloseRequest={onCloseRequest}
        processing={processing}
        secondaryButtonText={secondaryButtonText}
        tertiaryButton={tertiaryButton}
      />
    </CoreModal>
  );
};

export default TaskModal;
