import { useFormik } from 'formik';
import React, { useCallback } from 'react';

import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../lib/initTranslations';
import isUrl from '../../../../../../lib/isUrl';
import { messageFromError } from '../../../../../../redux/errors/helpers';
import {
  useCreateStepExternalLinkMutation,
  useUpdateStepExternalLinkMutation,
} from '../../../../../../redux/services/resourceApis/stepExternalLinks/stepExternalLinksApi';
import AssistiveText from '../../../../../design_system/core/AssistiveText';
import FieldLabel from '../../../../../design_system/core/FieldLabel';
import InputField from '../../../../../design_system/Triage/InputField';
import OutsideClickHandler from '../../../../shared/OutsideClickHandler';
import { FormWrapper, InputWrapper, StyledButton } from './styles';
import { FormErrors, ManageStepExternalLinksFormProps } from './types';

const t = initTranslations('step_external_links.manage_step_external_links_form');

const ManageStepExternalLinksForm = ({
  elementId,
  action,
  initialUrl = '',
  resetElementState,
}: ManageStepExternalLinksFormProps) => {
  const isCreateForm = action === 'create';
  const [createStepExternalLink, createResult] = useCreateStepExternalLinkMutation();
  const [updateStepExternalLink, updateResult] = useUpdateStepExternalLinkMutation();

  const formik = useFormik({
    initialValues: {
      url: initialUrl,
    },
    enableReinitialize: true,
    validate: ({ url }) => {
      const errors: FormErrors = {} as FormErrors;

      if (!isUrl(url)) {
        errors.url = t('errors.url.invalid');
      }

      return errors;
    },
    onSubmit: ({ url }) => {
      handleSubmit(url);
    },
  });

  const handleSubmit = (url: string) => {
    isCreateForm
      ? createStepExternalLink({ stepId: elementId, url })
      : updateStepExternalLink({ id: elementId, url });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      formik.handleSubmit();
    }
  };

  const { resetForm } = formik;
  const successFunction = useCallback(() => {
    resetElementState && resetElementState();
    resetForm();
  }, [resetForm, resetElementState]);

  useDisplayFlashOnResponse({
    result: createResult,
    successFunction,
    successMessage: t('success_message'),
    errorMessage: messageFromError(createResult.error)?.join(', '),
  });

  useDisplayFlashOnResponse({
    result: updateResult,
    successFunction,
    successMessage: t('success_message'),
    errorMessage: messageFromError(updateResult.error)?.join(', '),
  });

  return (
    <>
      {isCreateForm && <FieldLabel text={t('input_label')} />}
      <OutsideClickHandler onOutsideClick={() => resetElementState && resetElementState()}>
        <FormWrapper>
          <InputWrapper>
            <InputField
              autoFocus
              data-testid={`input-step-external-link-${elementId}`}
              id={`update-step-external-link-${elementId}`}
              name='url'
              onChange={formik.handleChange}
              onKeyDown={handleKeyDown}
              placeholder={t('input_placeholder')}
              required
              type='text'
              value={formik.values.url}
            />
            {formik.touched.url && formik.errors.url && (
              <AssistiveText
                id={`assistive-text-${action}-help-${elementId}`}
                text={formik.errors.url}
                type='error'
              />
            )}
          </InputWrapper>
          <StyledButton
            id={
              isCreateForm ? 'create-step-external-link' : `update-step-external-link-${elementId}`
            }
            onClick={() => formik.handleSubmit()}
            text={t(isCreateForm ? 'create' : 'save')}
          />
        </FormWrapper>
      </OutsideClickHandler>
    </>
  );
};

export default ManageStepExternalLinksForm;
