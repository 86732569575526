import { useFormikContext } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { AccountRoutesContext } from '../../../../../contexts/AccountRoutesContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import usePublicConfigs from '../../../../../hooks/usePublicConfigs';
import fetchCurrentRoute from '../../../../../lib/fetchCurrentRoute';
import initTranslations from '../../../../../lib/initTranslations';
import { closeDocumentImportModal } from '../../../../../redux/domains/documentUpload/documentUploadSlice';
import { useAppDispatch } from '../../../../../redux/hooks';
import { useUploadWithAiMutation } from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { Sector } from '../../../../../types/Sector';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import Link from '../../../../design_system/Link';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import { routes } from '../../../publicApplication/applicationRouter';
import routeTo from '../../../publicApplication/routeTo';
import FileUpload from './FileUpload/FileUpload';
import SectorSelection from './SectorSelection/SectorSelection';
import { HelpLinkWrapper } from './styles';
import { DocumentImportData, DocumentImportProps, FormValues } from './types';

const t = initTranslations('document_import_modal');

const DocumentImportModalInner = () => {
  const accountRoutes = useContext(AccountRoutesContext);
  const { curriculum, productTerm } = useAccountTerminology();
  const { slug } = useCurrentAccount();
  const { values, handleSubmit } = useFormikContext<FormValues>();
  const urlParams = new URLSearchParams(window.location.search);
  const sector = urlParams.get('sector');
  const defaultSector: Sector = 'process';
  const initialSector = !sector ? defaultSector : (sector as Sector);
  const dispatch = useAppDispatch();
  const { configs } = usePublicConfigs();

  const defaultDocImportData: DocumentImportData = {
    title: '',
    file: null,
    sector: initialSector,
  };
  const [docImportData, setDocImportDataData] = useState<DocumentImportData>(defaultDocImportData);
  const [uploadWithAi, { isLoading, isSuccess, error }] = useUploadWithAiMutation();

  const cardViewCurriculumsUrl = accountRoutes.view.curriculumsCards({
    sector: docImportData.sector,
  });
  const sectorLibrariesUrl = routes.content({ slug, sector: docImportData.sector });

  const handlePrimaryButtonAction = () => {
    if (!!docImportData.file) {
      const formData: FormData = new FormData();
      formData.append('curriculum_title', docImportData.title);
      formData.append('sector', docImportData.sector);
      formData.append('file', docImportData.file);

      uploadWithAi(formData);
    } else {
      handleSubmit();
    }
  };

  const handleRedirectFromNonCurriculumLocation = useCallback(() => {
    const pathName = fetchCurrentRoute();
    const curriculumUrls = {
      [`/${slug}/curriculums`]: cardViewCurriculumsUrl,
      [`/${slug}/content`]: sectorLibrariesUrl,
    };

    const isCurriculumsPath = Object.keys(curriculumUrls).includes(pathName);

    if (isCurriculumsPath) {
      if (sector === docImportData.sector) return;

      routeTo(curriculumUrls[pathName]);
    } else {
      routeTo(sectorLibrariesUrl);
    }
  }, [cardViewCurriculumsUrl, docImportData.sector, sector, sectorLibrariesUrl, slug]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(closeDocumentImportModal());
      handleRedirectFromNonCurriculumLocation();
    }
  }, [dispatch, handleRedirectFromNonCurriculumLocation, isSuccess]);

  const taskModalArgs: TaskModalProps = {
    title: t('title'),
    subtitle: t('subtitle', { subjects: curriculum.plural.toLowerCase(), productTerm }),
    onCloseRequest: () => dispatch(closeDocumentImportModal()),
    heapModalName: 'document-import-modal',
    primaryButtonText: t('next'),
    primaryButtonTask: handlePrimaryButtonAction,
    processing: isLoading,
    isDisabled: !docImportData.file && !values.documentLink,
    secondaryButtonText: t('cancel'),
  };

  const sectionArgs: DocumentImportProps = {
    documentImportData: docImportData,
    setDocumentImportData: (data) => setDocImportDataData(data),
    uploadError: error,
  };

  return (
    <TaskModal {...taskModalArgs} desktopSize='xl'>
      <HelpLinkWrapper>
        <Link
          href={configs['AI_DOCUMENT_UPLOAD_HELP_PAGE_URL']}
          target='_blank'
          text={t('review_best_practices')}
        />
      </HelpLinkWrapper>
      {!!docImportData.file ? (
        <SectorSelection {...sectionArgs} />
      ) : (
        <FileUpload {...sectionArgs} />
      )}
    </TaskModal>
  );
};

export default DocumentImportModalInner;
