import { useContext } from 'react';

import { AccountRoutesContext } from '../../../../../contexts/AccountRoutesContext';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import fetchCurrentRoute from '../../../../../lib/fetchCurrentRoute';
import getQueryParams from '../../../../../lib/getQueryParams';
import initTranslations from '../../../../../lib/initTranslations';
import { sectorToAccountTerminology } from '../../../../../types/Sector';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';

const t = initTranslations('navigation.header');

export type LearnMoreProps =
  | {
      learnMoreText: string;
      learnMoreHref: string;
      learnMoreExternal?: boolean;
    }
  | {
      learnMoreText?: never;
      learnMoreHref?: never;
      learnMoreExternal?: never;
    };

export type HeaderProps = {
  title: string;
  subtitle?: string;
  subtitleAddOnText?: string;
  isBeta?: boolean;
} & LearnMoreProps;

export default function useHeaderRoute(): HeaderProps {
  const ability = useCurrentUserAbilities();
  const terminology = useAccountTerminology();
  const pathName = fetchCurrentRoute();
  const [sectorParam] = getQueryParams(['sector']);
  const routes = useContext(AccountRoutesContext);

  const route = `${pathName}${sectorParam ? `?sector=${sectorParam}` : ''}`;

  let title = '';
  let subtitle = '';
  // Using const here because isBeta is not currently being reassigned
  const isBeta = false;

  switch (route) {
    case routes.view.users({}): {
      title = t('users_title');
      subtitle = ability.can('read', 'All') ? t('users_subtitle') : '';
      break;
    }
    case routes.view.people({}): {
      title = t('people_title');
      subtitle = ability.can('read', 'All')
        ? t('people_subtitle_admin')
        : t('people_subtitle_general');
      break;
    }
    case routes.view.curriculumsCards({ sector: 'company' }):
    case routes.view.curriculums({ sector: 'company' }): {
      title = sectorToAccountTerminology('company', terminology);
      subtitle = t('company_subtitle');
      break;
    }
    case routes.view.curriculumsCards({ sector: 'process' }):
    case routes.view.curriculumsCards({}):
    case routes.view.curriculums({ sector: 'process' }): {
      const processTerminology = sectorToAccountTerminology('process', terminology);
      title = processTerminology !== 'Processes' ? processTerminology : t('all_processes');
      subtitle = t('processes_subtitle', { title: processTerminology.toLowerCase() });
      break;
    }
    case routes.view.curriculumsCards({ sector: 'policy' }):
    case routes.view.curriculums({ sector: 'policy' }): {
      title = sectorToAccountTerminology('policy', terminology);
      subtitle = t('policies_subtitle', { title: title.toLowerCase() });
      break;
    }
  }

  return {
    title,
    subtitle,
    isBeta,
  };
}
