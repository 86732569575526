import React, { MouseEvent, ReactNode, useCallback, useMemo, useReducer } from 'react';

import useActionCableChannel from '../../../../../hooks/useActionCableChannel';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { CurriculumUploadResponse } from '../../../curriculums/CurriculumUploadWithAi/types';
import { routes } from '../../../publicApplication/applicationRouter';
import routeTo from '../../../publicApplication/routeTo';
import { UploadProgressItemProps, UploadStatus } from '../types';
import FailureIndicator from './FailureIndicator';
import ProcessingIndicator from './ProcessingIndicator';
import { UploadProgressItemReducer, UploadProgressItemState } from './reducer';
import {
  Container,
  DocumentImage,
  DocumentInfoWrapper,
  DocumentTitle,
  DocumentTitleSection,
  UploadStatusText,
} from './styles';
import SuccessIndicator from './SuccessIndicator';

const t = initTranslations('document_uploads.upload_progress_item');

const UploadProgressItem = ({ documentUpload }: UploadProgressItemProps) => {
  const { slug } = useCurrentAccount();
  const { uploadPercentage, aiCompletionId, title } = documentUpload;
  const initialState: UploadProgressItemState = {
    currentPercentage: uploadPercentage,
    uploadStatus: 'processing',
    curriculumId: null,
    isVisible: true,
    errorMessage: '',
  };

  const [state, dispatch] = useReducer(UploadProgressItemReducer, initialState);
  const { currentPercentage, uploadStatus, curriculumId, isVisible, errorMessage } = state;

  const channelProps = useMemo(() => {
    return {
      channel: 'CurriculumImportChannel',
      ai_completion_id: String(aiCompletionId),
    };
  }, [aiCompletionId]);

  const received = useCallback((uploadResponse: CurriculumUploadResponse) => {
    if (!!uploadResponse.error_messages) {
      dispatch({ type: 'setUploadStatus', uploadStatus: 'failed' });
      dispatch({
        type: 'setErrorMessage',
        errorMessage: !!uploadResponse.error_messages.length
          ? uploadResponse.error_messages.join(', ')
          : t('base_error'),
      });
    }
    if (!!uploadResponse.curriculum_id) {
      dispatch({
        type: 'updateUploadData',
        currentPercentage: uploadResponse.percentage,
        curriculumId: uploadResponse.curriculum_id,
        uploadStatus: 'uploaded',
      });
    }
    if (!!uploadResponse.percentage) {
      dispatch({ type: 'setCurrentPercentage', currentPercentage: uploadResponse.percentage });
    }
  }, []);

  useActionCableChannel<CurriculumUploadResponse>(channelProps, received);

  const STATUSES_KEYS: { [key in UploadStatus]: ReactNode } = {
    processing: <ProcessingIndicator percentage={currentPercentage} />,
    failed: <FailureIndicator dispatch={dispatch} />,
    uploaded: <SuccessIndicator curriculumId={curriculumId} />,
  };

  const handleDocumentInfoClick = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    !!curriculumId && routeTo(routes.curriculumEdit({ slug, id: curriculumId }));
  };

  if (!isVisible) return <></>;

  return (
    <Container>
      <DocumentInfoWrapper isClickable={!!curriculumId} onClick={handleDocumentInfoClick}>
        <DocumentImage>📄</DocumentImage>
        <DocumentTitleSection>
          <DocumentTitle>{title}</DocumentTitle>
          {!!errorMessage && <UploadStatusText isError>{errorMessage}</UploadStatusText>}
        </DocumentTitleSection>
      </DocumentInfoWrapper>
      <DocumentInfoWrapper>{STATUSES_KEYS[uploadStatus]}</DocumentInfoWrapper>
    </Container>
  );
};

export default UploadProgressItem;
