import { StepExternalLinksListAction, StepExternalLinksListState } from './types';

export const initialState: StepExternalLinksListState = {
  editId: null,
};

export const stepExternalLinksListReducer = (
  state: StepExternalLinksListState,
  action: StepExternalLinksListAction
): StepExternalLinksListState => {
  switch (action.type) {
    case 'editIdChanged': {
      return { ...state, editId: action.editId };
    }
    case 'resetState': {
      return initialState;
    }
  }
};
