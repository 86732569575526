import React from 'react';

import getDocumentImageSrc from '../../../../../../lib/document_import/getDocumentImageSrc';
import initTranslations from '../../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../../redux/errors/helpers';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import Chip from '../../../../../design_system/buttons/Chip/Chip';
import IconButton from '../../../../../design_system/buttons/IconButton';
import { DocumentImportProps } from '../types';
import {
  CloseIconContainer,
  DocumentImage,
  DocumentInfoContainer,
  DocumentName,
  DocumentPlaceholderContainer,
  ErrorIcon,
  ErrorMessage,
  ErrorText,
  SectorContainer,
  SectorSelectionWrapper,
  SectorsTitle,
  SourceIconWrapper,
} from './styles';

const t = initTranslations('document_import_modal.sector_selection');

const SectorSelection = ({
  documentImportData,
  setDocumentImportData,
  uploadError,
}: DocumentImportProps) => {
  const {
    process: { plural: processTermPlural },
    policy: { plural: policyTermPlural },
    company: { singular: companyTermSingular },
  } = useAccountTerminology();
  const { sector, title } = documentImportData;
  const fileMimeType: string = documentImportData.file?.type || '';

  return (
    <SectorSelectionWrapper>
      <DocumentPlaceholderContainer isError={!!uploadError}>
        <CloseIconContainer>
          <IconButton
            ariaLabel={t('remove_selection_aria_label')}
            buttonSize='sm'
            className='remove-document-selection'
            name='xmark'
            onClick={() => setDocumentImportData({ ...documentImportData, file: null })}
            weight='solid'
          />
        </CloseIconContainer>
        <SourceIconWrapper>
          <DocumentImage src={getDocumentImageSrc(fileMimeType)} />
        </SourceIconWrapper>
        <DocumentInfoContainer>
          <DocumentName>{title}</DocumentName>
          {!!uploadError && (
            <ErrorMessage>
              <ErrorIcon name='circle-xmark' size='sm' weight='solid' />
              <ErrorText>{messageFromError(uploadError)?.join(', ')}</ErrorText>
            </ErrorMessage>
          )}
        </DocumentInfoContainer>
      </DocumentPlaceholderContainer>
      <SectorsTitle>{t('select_sector_title')}</SectorsTitle>
      <SectorContainer>
        <Chip
          active={sector === 'company'}
          icon='file-alt'
          id='company-sector-chip'
          onClick={() => setDocumentImportData({ ...documentImportData, sector: 'company' })}
          palette='yellow'
          subText={t('company_sector_description')}
          text={companyTermSingular}
          textAlignment='start'
        />
        <Chip
          active={sector === 'policy'}
          icon='file-alt'
          id='policy-sector-chip'
          onClick={() => setDocumentImportData({ ...documentImportData, sector: 'policy' })}
          palette='magenta'
          subText={t('policy_sector_description')}
          text={policyTermPlural}
          textAlignment='start'
        />
        <Chip
          active={sector === 'process'}
          icon='file-alt'
          id='process-sector-chip'
          onClick={() => setDocumentImportData({ ...documentImportData, sector: 'process' })}
          palette='blue'
          subText={t('process_sector_description')}
          text={processTermPlural}
          textAlignment='start'
        />
      </SectorContainer>
    </SectorSelectionWrapper>
  );
};

export default SectorSelection;
