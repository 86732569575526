import { CardBrands } from 'types/CardBrands';

import Amex from '../images/billing/cards_icons/amex.svg';
import DinersClub from '../images/billing/cards_icons/diners_club.svg';
import Discover from '../images/billing/cards_icons/discover.svg';
import JCB from '../images/billing/cards_icons/jcb.svg';
import MasterCard from '../images/billing/cards_icons/mastercard.svg';
import UnionPay from '../images/billing/cards_icons/union_pay.svg';
import Visa from '../images/billing/cards_icons/visa.svg';

type Props = {
  cardBrand: CardBrands | string;
};

export const getCardIcon = ({ cardBrand }: Props) => {
  switch (cardBrand) {
    case 'American Express':
      return Amex;
    case 'Diners Club':
      return DinersClub;
    case 'Discover':
      return Discover;
    case 'JCB':
      return JCB;
    case 'MasterCard':
      return MasterCard;
    case 'UnionPay':
      return UnionPay;
    case 'Visa':
      return Visa;
  }
};
