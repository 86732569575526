import DOMPurify from 'dompurify';
import React, { useCallback } from 'react';

import ComposeStepModal, { Props } from '../ComposeStepModal/ComposeStepModal';

const FroalaComposeStepModal = (props: Props) => {
  const onSuccess = useCallback((generatedContent: string) => {
    // This file will be removed with the react transition, please do not add eslint-disable to code that is not being deprecated
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const editor = (document.querySelector('.step-editor-wrapper .froala-editor') as any)[
      'data-froala.editor'
    ];

    if (!editor || !generatedContent) return;

    editor.html.insert(DOMPurify.sanitize(generatedContent));
    editor.undo.saveStep();
  }, []);

  return <ComposeStepModal {...props} onSuccess={onSuccess} />;
};

export default FroalaComposeStepModal;
