import React from 'react';

import useSlideout from '../../../../hooks/useSlideout';
import initTranslations from '../../../../lib/initTranslations';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import SlideoutPanel from '../../shared/slideout';
import { FooterButtonWrapper, ReportFiltersBody, ReportFiltersFooter } from './styles';
import { Props } from './types';

const t = initTranslations('reports.filter');

const ReportFiltersSlideout = ({
  children,
  disabledApplyButton,
  disabledClearButton,
  applyFilters,
  clearFilters,
  slideoutType,
  title,
}: Props) => {
  const { close } = useSlideout(slideoutType);

  const clear = () => {
    close();
    clearFilters();
  };

  const apply = () => {
    close();
    applyFilters();
  };

  return (
    <SlideoutPanel
      headerText={title}
      id='report-filters-slideout-panel'
      slideoutType={slideoutType}
      slideoutWidth='20rem'
      titleAlign='flex-start'
    >
      <>
        <ReportFiltersBody>{children}</ReportFiltersBody>
        <ReportFiltersFooter>
          <FooterButtonWrapper>
            <DefaultButton
              buttonType='secondary'
              disabled={disabledClearButton}
              fullWidth
              id='report-filter-clear-button'
              onClick={clear}
              size='md'
              text={t('clear')}
            />
          </FooterButtonWrapper>
          <FooterButtonWrapper>
            <DefaultButton
              buttonType='primary'
              disabled={disabledApplyButton}
              fullWidth
              id='report-filter-apply-button'
              onClick={apply}
              size='md'
              text={t('apply')}
            />
          </FooterButtonWrapper>
        </ReportFiltersFooter>
      </>
    </SlideoutPanel>
  );
};

export default ReportFiltersSlideout;
