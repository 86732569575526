import React from 'react';
import styled from 'styled-components';

import NoUsersEmptyStateDark from '../../../../../../../assets/images/dark_mode/empty_state/no-users-exist-groups-empty-state.svg';
import NoUsersEmptyStateLight from '../../../../../../../assets/images/empty_state/no-users-exist-groups-empty-state.svg';
import initTranslations from '../../../../../lib/initTranslations';
import NoResults from '../../../../design_system/Triage/NoResults';

const t = initTranslations('supershare.supershare_modal.empty_state');

const EmptyStateWrapper = styled.div`
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
`;

const EmptyState = () => {
  return (
    <EmptyStateWrapper>
      <NoResults
        className='supershare-modal-empty-state'
        darkImage={NoUsersEmptyStateDark}
        heading={t('heading')}
        iconWidth='30%'
        lightImage={NoUsersEmptyStateLight}
        minHeight='unset'
        showBackground={false}
        showBorder={false}
        subHeaderText={t('action_text')}
      />
    </EmptyStateWrapper>
  );
};

export default EmptyState;
