import React, { ChangeEvent, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useSuperShare } from '../../../../../contexts/SuperShareContext';
import initTranslations from '../../../../../lib/initTranslations';
import { useGetGroupQuery } from '../../../../../redux/services/resourceApis/groups/groupsApi';
import { GroupShowUser } from '../../../../../redux/services/resourceApis/groups/types';
import IconButton from '../../../../design_system/buttons/IconButton';
import CoreModal, { ModalProps } from '../../../../design_system/core/CoreModal';
import DetailedAvatar from '../../../../design_system/display/DetailedAvatar/DetailedAvatar';
import InputField from '../../../../design_system/Triage/InputField';
import Loader from '../../../../design_system/Triage/Loader';
import DetailedTitle from '../../../shared/DetailedTitle/DetailedTitle';

const t = initTranslations('supershare.group_read_modal');

const GroupReadHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd3};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const GroupReadBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerLg1};
`;

const GroupReadUserListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const ListedUsers = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  max-height: 28.5rem;
  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0.5rem;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
    background-color: ${({ theme: { vars } }) => vars.borderSurface2};
  }
`;

const DetailedAvatarWrapper = styled.div`
  display: flex;
  padding: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const StyledContainer = styled.div`
  display: flex;
  background: ${({ theme }) => theme.vars.foundationSurface1};
  width: 100%;
  border-radius: ${({ theme }) => theme.constants.borderRadiusMd};
  padding: ${({ theme }) => `0 ${theme.constants.spacerSm2}`};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderDefault}`};
  overflow-x: hidden;
`;

const StyledInput = styled(InputField)`
  border: none !important;
  width: 100%;
  padding: 0;
`;

const StyledIconButton = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorText = styled.div`
  color: ${({ theme: { vars } }) => vars.stateError};
`;

export type GroupReadModalProps = {
  id: number;
  groupName: string;
  memberCount: number;
};

const GroupReadModal = ({ id, groupName, memberCount }: GroupReadModalProps) => {
  const { closeActiveGroupReadModal } = useSuperShare();
  const { data: group, error, isLoading } = useGetGroupQuery(id);
  const [searchText, setSearchText] = useState('');

  const filteredUserList = useMemo(() => {
    if (!group) return;
    const { groupUsers, groupManagers } = group.users.reduce(
      (acc: { groupUsers: GroupShowUser[]; groupManagers: GroupShowUser[] }, user) => {
        user.is_group_manager ? acc.groupManagers.push(user) : acc.groupUsers.push(user);
        return acc;
      },
      { groupUsers: [], groupManagers: [] }
    );

    const loweredSearchText = searchText.toLowerCase();

    const filteredManagers = groupManagers
      .filter((user) => user.name.toLowerCase().includes(loweredSearchText))
      .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'case' }));

    const filteredUsers = groupUsers
      .filter((user) => user.name.toLowerCase().includes(loweredSearchText))
      .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'case' }));

    return [...filteredManagers, ...filteredUsers];
  }, [group, searchText]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value);

  const coreModalArgs: ModalProps = {
    desktopSize: 'xl',
    heapModalName: 'group-read-modal',
    onCloseRequest: closeActiveGroupReadModal,
    closeIconAriaLabel: 'cancel-aria-label',
  };

  return (
    <CoreModal {...coreModalArgs}>
      <GroupReadHeaderWrapper>
        <DetailedTitle
          fontWeight='regular'
          iconName='arrow-left'
          onClick={closeActiveGroupReadModal}
          title={t('back_to_supershare')}
        />
        <DetailedAvatar
          avatarIcon='users'
          id='detailed-avatar-header-group-read-modal'
          label={groupName}
          size='lg'
          subLabel={t('members', { count: memberCount })}
        />
        <StyledContainer id='group-read-member-search-container'>
          <StyledIconButton ariaLabel={t('aria_label', { group: groupName })} name='search' />
          <StyledInput
            aria-describedby='group-read-modal-member-search'
            onChange={onChange}
            placeholder={t('search_placeholder', { group: groupName })}
            value={searchText}
          />
        </StyledContainer>
      </GroupReadHeaderWrapper>
      <GroupReadBodyWrapper>
        <GroupReadUserListWrapper id='group-members-list'>
          <ListedUsers>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <ErrorText>{t('error')}</ErrorText>
            ) : (
              group &&
              filteredUserList &&
              filteredUserList.map((user) => (
                <DetailedAvatarWrapper key={`id-${user.id}`}>
                  <DetailedAvatar
                    extendedLabel={user.is_group_manager ? t('manager') : undefined}
                    id={`group-read-modal-detailed-avatar-${user.id}`}
                    label={user.name}
                    subLabel={`${initTranslations('users.permissions')(user.permission)}`}
                  />
                </DetailedAvatarWrapper>
              ))
            )}
          </ListedUsers>
        </GroupReadUserListWrapper>
      </GroupReadBodyWrapper>
    </CoreModal>
  );
};

export default GroupReadModal;
