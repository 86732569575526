import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledFooterContent = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const FooterContent = ({ children }: { children: ReactNode }) => {
  return <StyledFooterContent id='footer-content'>{children}</StyledFooterContent>;
};

export default FooterContent;
