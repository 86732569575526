import styled from 'styled-components';

import { mediaBreakpointPxSm } from './Breakpoint';
import FlexContainer from './FlexContainer';
import { fontSm5 } from './TypeSystem';

export const ModalTitle = styled.h4`
  margin-bottom: 0.5rem;
  font-weight: 800;
  font-size: 1.25rem;
`;

export const ModalSubtitle = styled.div`
  margin-bottom: 1.75rem;
`;

export const ModalBodyContainer = styled(FlexContainer)`
  justify-content: center;
`;

export const ModalFooterContainer = styled(FlexContainer)`
  flex-direction: column;
  padding: ${({ theme: { constants } }) => `${constants.spacerLg1} 0 ${constants.spacerMd3}`};

  @media (min-width: ${mediaBreakpointPxSm}) {
    flex-direction: row;
    justify-content: flex-end;
    padding: ${({ theme: { constants } }) => `3.25rem 0 ${constants.spacerMd2}`};
  }
`;

export const TaskErrorDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars } }) => vars.stateError};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontSm5};
`;

export const Form = styled.form`
  display: flex;
`;
