import React from 'react';

import { getFormattedPrice } from '../../../../../../lib/getFormattedPrice';
import initTranslations from '../../../../../../lib/initTranslations';
import Link from '../../../../../design_system/Link';
import { DataCell, DataRow } from '../../../../groups/shared/table_styles';
import { DownloadChargeLinkIcon, PlanNameWrapper, PlanNamesList } from '../styles';
import { Charge } from '../types';

const t = initTranslations('billing_history_modal.charge_row');

type ChargeRowProps = {
  charge: Charge;
};

const ChargeRow = ({ charge }: ChargeRowProps) => {
  const { createdAt, description, amount, downloadLink } = charge;

  return (
    <DataRow className='data-row'>
      <DataCell>{createdAt}</DataCell>
      <DataCell>
        <PlanNameWrapper>
          {description.length > 1 ? (
            <PlanNamesList>
              {description.map((desc, index) => (
                <li key={index}>{desc}</li>
              ))}
            </PlanNamesList>
          ) : (
            description[0]
          )}
        </PlanNameWrapper>
      </DataCell>
      <DataCell>{getFormattedPrice(amount)}</DataCell>
      <DataCell>
        <Link
          href={downloadLink}
          target='_blank'
          text={
            <>
              {t('download')}
              <DownloadChargeLinkIcon name='arrow-down-to-line' />
            </>
          }
          underlineBehavior='dynamic'
        />
      </DataCell>
    </DataRow>
  );
};

export default ChargeRow;
