import styled from 'styled-components';

import { fontSm5 } from '../../../../styled/TypeSystem';

export const NormalText = styled.div`
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};
  ${fontSm5};
`;
