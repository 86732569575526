import React, { useMemo } from 'react';

import FieldLabel from '../../core/FieldLabel';
import ControlLabel, { IconHoverTooltipProps } from '../../core/Labels/ControlLabel/ControlLabel';
import { TooltipProps } from '../../core/Labels/SharedLabelTypes';
import { ErrorText } from '../InputField';
import { Option, Orientation } from './RadioFieldTypes';
import {
  Description,
  OptionsWrapper,
  RadioInputWrapper,
  RadioWrapper,
  StyledIcon,
  StyledRadio,
} from './styles';

const DEFAULT_OPTION = '__DEFAULT__';

export type Props = {
  isCustomRadioField?: boolean;
  fieldLabelText?: string;
  required?: boolean;
  error?: string;
  name?: string;
  errorText?: string | false;
  value: string | number | null;
  options: Option[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  orientation?: Orientation;
} & TooltipProps;

export default function RadioField(props: Props) {
  const {
    isCustomRadioField,
    fieldLabelText,
    options,
    name,
    errorText,
    tooltipText,
    tooltipId,
    onChange,
    orientation = 'horizontal',
    disabled = false,
    required = false,
  } = props;
  const randomName = useMemo(() => `${Math.random()}`, []);

  const selectedValue = props.value === null ? DEFAULT_OPTION : props.value;

  const tooltipProps: TooltipProps = tooltipId && tooltipText ? { tooltipId, tooltipText } : {};

  return (
    <>
      {fieldLabelText && <FieldLabel required={required} text={fieldLabelText} {...tooltipProps} />}
      <OptionsWrapper className='RadioField' orientation={orientation}>
        {options.map((option) => {
          const {
            component,
            description,
            disabled: disabledOption,
            name: optionName,
            text,
            tooltipId: optionTooltipId,
            tooltipText: optionTooltipText,
            value,
          } = option;

          const radioButtonId = optionName ? `rad-${optionName}-${value}` : `rad-${value}`;
          const optionTooltipProps: IconHoverTooltipProps =
            optionTooltipId && optionTooltipText
              ? {
                  tooltipId: optionTooltipId,
                  tooltipText: optionTooltipText,
                  tooltipHoverIcon: true,
                }
              : {};
          const isChecked = value === selectedValue;
          const isDisabled = disabled || disabledOption;
          const radioInputProps = {
            checked: isChecked,
            disabled: isDisabled,
            id: radioButtonId,
            name: name || optionName || randomName,
            type: 'radio',
            value,
            isCustomRadioField,
            onChange,
          };

          return (
            <RadioWrapper key={value} orientation={orientation}>
              {isCustomRadioField ? (
                <RadioInputWrapper>
                  <StyledRadio {...radioInputProps} />
                  <StyledIcon
                    checked={isChecked}
                    disabled={isDisabled}
                    name={isChecked ? 'circle-dot' : 'circle'}
                  />
                </RadioInputWrapper>
              ) : (
                <StyledRadio {...radioInputProps} />
              )}

              <div>
                <ControlLabel
                  disabled={isDisabled}
                  htmlFor={radioButtonId}
                  key={value}
                  {...(text ? { text } : { component })}
                  {...optionTooltipProps}
                />

                {description && (
                  <Description disabled={isDisabled} htmlFor={radioButtonId}>
                    {description}
                  </Description>
                )}
              </div>
            </RadioWrapper>
          );
        })}
      </OptionsWrapper>
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </>
  );
}
