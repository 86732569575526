import { Formik } from 'formik';
import React from 'react';

import { useEmbedlyContext } from '../../../../../../contexts/EmbedlyProvider';
import usePrivateConfigs from '../../../../../../hooks/usePrivateConfigs';
import initTranslations from '../../../../../../lib/initTranslations';
import AssistiveText from '../../../../../design_system/core/AssistiveText';
import FieldLabel from '../../../../../design_system/core/FieldLabel';
import { EditorInputContainer } from '../../../shared/styles';
import EmbedCodeForm from './EmbedCodeForm';

const t = initTranslations('editor');

type Values = {
  embeddedCode: string;
};

const EmbedCodeTextArea = () => {
  const { configs } = usePrivateConfigs();
  const apiKey = configs['EMBEDLY_KEY'];
  const { errorMessage, embedlyData, setEmbedlyData, trigger, setErrorMessage } =
    useEmbedlyContext();

  const initialValues: Values = {
    embeddedCode: '',
  };

  const getSrcFromValue = (value: string): string | null => {
    const srcRegex = /src="([^"]*)"/;
    const srcMatch = value.match(srcRegex);
    // Consider adding check for url on srcMatch[1] to ensure it's a valid url
    return srcMatch ? srcMatch[1] : null;
  };

  const onSubmit = ({ embeddedCode }: Values) => {
    if (embedlyData) setEmbedlyData(null);
    const src = getSrcFromValue(embeddedCode);

    const clearOrErrorMessage = () => {
      !embeddedCode ? setErrorMessage(null) : setErrorMessage(t('embed.error_message'));
    };

    if (src) {
      trigger({ apiKey, url: src });
    } else {
      clearOrErrorMessage();
    }
  };

  return (
    <EditorInputContainer>
      <FieldLabel text={t('video.embedded_code')} />
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <EmbedCodeForm />
      </Formik>
      {errorMessage && (
        <AssistiveText id='embed-code-error' text={t('embed.error_message')} type='error' />
      )}
    </EditorInputContainer>
  );
};

export default EmbedCodeTextArea;
