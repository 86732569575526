import styled from 'styled-components';

import { fontSm5 } from '../../../../../../styled/TypeSystem';

export const DiscountsInlineSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const DiscountsInlineSectionBadgesWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;

export const DiscountsInlineSectionBadgeWrapper = styled.div`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const BillingIntervalTitle = styled.h6`
  margin: ${({ theme: { constants } }) => constants.spacerSm2} 0;
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontSm5};
`;
