import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import Badge from '../../../../../design_system/display/badge';
import { BadgeType } from '../../../../../design_system/display/badge/BadgeTypes';
import { ColumnComponentProps, CurriculumStatus } from '../types';

const t = initTranslations('reports.individual_user_report.table.columns.status');

const Status = ({ record: { status } }: ColumnComponentProps) => {
  const STATUSES_KEYS: { [key in CurriculumStatus]: BadgeType } = {
    not_started: 'general',
    in_progress: 'info',
    completed: 'success',
    overdue: 'error',
  };
  const statusBadgeType = STATUSES_KEYS[status];

  return <Badge text={t(status)} type={statusBadgeType} />;
};

export default Status;
