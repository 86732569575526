import React, { useState } from 'react';

export type AsyncData<T> =
  | { status: 'loading' }
  | { status: 'error'; baseErrors: string[]; statusCode?: number }
  | { status: 'success'; data: T };

export default function useAsyncData<T>(): [
  AsyncData<T>,
  React.Dispatch<React.SetStateAction<AsyncData<T>>>
] {
  const [data, setData] = useState<AsyncData<T>>({
    status: 'loading',
  });

  return [data, setData];
}
