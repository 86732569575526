import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useEditorContext } from '../../../../../contexts/EditorContext';
import { useEditorToolbarContext } from '../../../../../contexts/EditorToolbarContext';
import { useEmbedlyContext } from '../../../../../contexts/EmbedlyProvider';
import { ReactComponent as EmbedCodeIcon } from '../../../../../images/editor/embed_code.svg';
import { ReactComponent as LoomIcon } from '../../../../../images/editor/loom_icon.svg';
import { ReactComponent as UploadVideoIcon } from '../../../../../images/editor/upload_video.svg';
import { ReactComponent as VimeoIcon } from '../../../../../images/editor/vimeo.svg';
import { ReactComponent as YoutubeIcon } from '../../../../../images/editor/youtube.svg';
import initTranslations from '../../../../../lib/initTranslations';
import Flyout from '../../../../design_system/overlays/flyout';
import useActiveMenuHandler from '../../../publicApplication/utils/useActiveMenuHandler';
import MediaFlyoutMenu, { MenuItem } from '../../components/MediaFlyoutMenu/MediaFlyoutMenu';
import { htmlEncodedContent } from '../../lib/htmlEncodedContent';
import { insertEditorContent } from '../../lib/insertEditorContent';
import { SharedFlyoutStyles } from '../../shared/styles';
import AddVideoToolbarButton from '../../toolbar/buttons/components/insert/AddVideoToolbarButton';
import { EmbedlyFlyoutId } from '../Embedly/EmbedlyFlyout/EmbedlyFlyout';
import EmbedlyInput from '../Embedly/EmbedlyInput';
import { EditorPluginProps } from '../types';
import EmbedCodeTextArea from './embed_code/EmbedCodeTextArea';

const t = initTranslations('editor.video');

// DS Override: Expanding the flyout and changing the borders to match the designs in Figma
const StyledFlyout = styled(Flyout)`
  width: 37.5rem;
  overflow: hidden;
  ${SharedFlyoutStyles};

  .flyout-footer {
    margin-top: ${({ theme }) => theme.constants.spacerMd2};
  }
`;

const VideoFlyout = ({
  editor: passedEditor,
  popupPlacement = 'bottom-start',
  initialMenuItemId,
  displayMenuItems = true,
}: EditorPluginProps) => {
  const { editor: contextEditor } = useEditorContext();
  const editor = passedEditor || contextEditor;
  const {
    errorMessage,
    setResetForm,
    setErrorMessage,
    embedlyData,
    setEmbedlyData,
    setProviderType,
  } = useEmbedlyContext();
  const menuId = 'video-flyout';
  const { isMenuOpen, setActiveMenuId, closeMenu } = useActiveMenuHandler({ menuId });

  const items = useMemo(
    () => [
      {
        Component: EmbedlyInput,
        SvgIcon: UploadVideoIcon,
        title: t('embed_link'),
        id: 'upload',
      },
      {
        Component: EmbedlyInput,
        SvgIcon: YoutubeIcon,
        title: t('youtube'),
        id: 'youtube',
      },
      {
        Component: EmbedlyInput,
        SvgIcon: VimeoIcon,
        title: t('vimeo'),
        id: 'vimeo',
      },
      {
        Component: EmbedlyInput,
        componentProps: { formLabel: t('loom_field_label') },
        SvgIcon: LoomIcon,
        title: t('loom'),
        id: 'loom',
      },
      {
        Component: EmbedCodeTextArea,
        SvgIcon: EmbedCodeIcon,
        title: t('embed_code'),
        id: 'embed-code',
      },
    ],
    []
  );
  const initialItem = items.find((item) => item.id === initialMenuItemId) || items[0];
  const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItem>(initialItem);
  const { selectedEmbedMenuItem, setSelectedEmbedMenuItem } = useEditorToolbarContext();

  useEffect(() => {
    setProviderType(['video']);
  }, [setProviderType]);

  useEffect(() => {
    const item = items.find((item) => item.id === selectedEmbedMenuItem);
    if (item) setSelectedMenuItem(item);
  }, [items, selectedEmbedMenuItem]);

  const button = (
    <AddVideoToolbarButton
      active={isMenuOpen}
      className='flyout-trigger'
      onClick={() => {
        setSelectedEmbedMenuItem(undefined);
      }}
    />
  );

  const handleEmbedlyResponse = useCallback(() => {
    if (embedlyData) {
      const { type } = embedlyData;

      if (type === 'video') {
        const jsonString = JSON.stringify(embedlyData);

        insertEditorContent({
          editor,
          content: htmlEncodedContent({
            jsonString,
            html: embedlyData.html,
            originalUrl: embedlyData.url,
          }),
        });
      }
    }
  }, [editor, embedlyData]);

  const resetMedia = useCallback(() => {
    setResetForm(true);
    setEmbedlyData(null);
    setErrorMessage(null);
  }, [setResetForm, setEmbedlyData, setErrorMessage]);

  const resetFlyout = useCallback(() => {
    resetMedia();
    setSelectedMenuItem(initialItem);
    setSelectedEmbedMenuItem(undefined);
  }, [resetMedia, initialItem, setSelectedEmbedMenuItem]);

  const navigateBackToEmbedlyMenu = useCallback(() => {
    setActiveMenuId(selectedEmbedMenuItem ? EmbedlyFlyoutId : null);
  }, [selectedEmbedMenuItem, setActiveMenuId]);

  return (
    <StyledFlyout
      className='video-flyout'
      id={menuId}
      onClose={resetFlyout}
      placement={popupPlacement}
      primaryButtonDisabled={!embedlyData || !!errorMessage}
      primaryButtonTask={() => {
        handleEmbedlyResponse();
        resetFlyout();
        closeMenu();
      }}
      primaryButtonText={t('primary_button')}
      secondaryButtonTask={() => {
        resetFlyout();
        navigateBackToEmbedlyMenu();
      }}
      secondaryButtonText={selectedEmbedMenuItem ? t('back') : t('cancel')}
      title={t('title')}
      triggerButton={button}
    >
      <MediaFlyoutMenu
        displayMenuItems={displayMenuItems}
        items={items}
        resetMedia={resetMedia}
        selectedMenuItem={selectedMenuItem}
        setSelectedMenuItem={setSelectedMenuItem}
      />
    </StyledFlyout>
  );
};

export default VideoFlyout;
