import React, { HTMLAttributes, MouseEvent } from 'react';
import styled from 'styled-components';
import { Route, preventDefaultLinkClickBehavior } from 'type-route';

import { routes } from './applicationRouter';
import routeTo from './routeTo';

const StyledRouterLink = styled.a`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

type Props = {
  children: React.ReactNode;
  className?: string;
  id?: string;
  to: Route<typeof routes>;
  linkRef?: React.Ref<HTMLAnchorElement>;
} & HTMLAttributes<HTMLAnchorElement>;

const RouterLink = ({ children, linkRef, className, to, id }: Props) => {
  const handleLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (preventDefaultLinkClickBehavior(event)) routeTo(to);
  };

  return (
    <StyledRouterLink
      className={className}
      href={to.href}
      id={id}
      onClick={handleLinkClick}
      ref={linkRef}
    >
      {children}
    </StyledRouterLink>
  );
};

export default RouterLink;
