import React from 'react';

import InputField from '../InputField';

export interface DateInputProps {
  className?: string;
  disabled?: boolean;
  errorText?: string | false;
  max?: string;
  min?: string;
  name: string;
  onChange: (value: string) => void;
  value?: string | number;
  required?: boolean;
}

const DateInput: React.FC<DateInputProps> = ({
  className,
  disabled,
  errorText,
  max,
  min,
  name,
  onChange,
  value,
  required = false,
}) => {
  return (
    <InputField
      className={className}
      disabled={disabled}
      errorText={errorText}
      max={max}
      min={min}
      name={name}
      onChange={({ target: { value } }) => onChange(value)}
      required={required}
      type='date'
      value={value}
    />
  );
};

export default DateInput;
