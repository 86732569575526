import styled from 'styled-components';

export const CardTitleWrapper = styled.div`
  position: relative;
`;

export const RecommendedImg = styled.img`
  position: absolute;
  top: ${({ theme: { constants } }) => `-${constants.spacerMd3}`};
  left: ${({ theme: { constants } }) => `${constants.spacerLg3}`};
`;
