import { FormikErrors, useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import { stringifyNumber } from '../../../../../lib/convertValues';
import initTranslations from '../../../../../lib/initTranslations';
import { isSector } from '../../../../../lib/isSector';
import { messageFromError } from '../../../../../redux/errors/helpers';
import {
  useCreateCurriculumMutation,
  useUpdateCurriculumMutation,
} from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { CurriculumShowResponse } from '../../../../../types';
import { Sector } from '../../../../../types/Sector';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import FieldLabel from '../../../../design_system/core/FieldLabel';
import { Option } from '../../../../design_system/core/SelectOption/types';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import InputField from '../../../../design_system/Triage/InputField';
import { TaskErrorDiv } from '../../../../styled/Modals';
import { fontSm5 } from '../../../../styled/TypeSystem';
import { routes } from '../../../publicApplication/applicationRouter';
import routeTo from '../../../publicApplication/routeTo';
import { FormGroup } from '../../../shared/FormGroup';
import EditSettings from './EditSettings';
import SectorChipSelect, { ProcessChipType } from './SectorChipSelect';

const SectorMoveRestrictedMessage = styled.p`
  color: ${({ theme: { vars } }) => vars.textDefault};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerLg1}`};
  ${fontSm5};
`;

export interface AdvancedSettings {
  completionRequired: boolean;
  disableTranslation: boolean;
  dueDate: boolean;
  forcedOrder: boolean;
  expiringCompletion: boolean;
  locked: boolean;
  displayOwner: boolean;
  completionCertificates: boolean;
  verifyContent: boolean;
}

const DEFAULT_ADVANCED_SETTINGS: AdvancedSettings = {
  completionRequired: true,
  disableTranslation: false,
  dueDate: false,
  forcedOrder: false,
  expiringCompletion: false,
  locked: false,
  displayOwner: true,
  completionCertificates: false,
  verifyContent: false,
};

export interface FormValues {
  title: string;
  ownerAssignment: string | null;
  description: string;
  dueDateRadio: string;
  dueDate: string;
  expirationRadio: string;
  expiration: string;
  checkBoxes: AdvancedSettings;
  sectorChip: ProcessChipType;
  verifyContentFrequency: string;
  verifyContentRadio: string;
}

interface FormData {
  title: string;
  owner_assignment: string | null;
  description: string | null;
  due_date: string | null;
  expiring_completion_in_days_after_completed: string | null;
  completion_required: string | undefined;
  forced_order: string;
  expiring_completion: string;
  locked: string;
  display_owner: string;
  completion_certificates: boolean;
  disable_translation: boolean;
  sector?: Sector | null;
  verify_content: string | null;
  verify_content_frequency_in_days: string | null;
}

const t = initTranslations('curriculums_modal');

interface Props {
  curriculum: CurriculumShowResponse | undefined;
  errorMessage: string | undefined;
  optionalSettingsOpen: boolean;
  userOptions: Option[];
  closeRequest: () => void;
  isUpdate: boolean;
  modalTitle: string;
  modalSubtitle: string;
  sector?: Sector | null;
  visible: boolean;
  afterCreateCurriculumAction?: (curriculumId: number, curriculumTitle: string) => void;
}

const SubjectModalForm = ({
  curriculum,
  errorMessage,
  optionalSettingsOpen,
  userOptions,
  closeRequest,
  isUpdate,
  modalTitle,
  modalSubtitle,
  sector,
  visible,
  afterCreateCurriculumAction,
}: Props) => {
  const ability = useCurrentUserAbilities();
  const { id: currentUserId } = useCurrentUser();
  const { slug, paywalledFeatures, hasESignatureFeature } = useCurrentAccount();
  const {
    curriculum: { singular: subjectTermSingular, plural: subjectTermPlural },
    policy: { plural: policyTermPlural },
    topic: { singular: topicTermSingular },
  } = useAccountTerminology();
  const {
    id,
    title,
    description,
    sector: curriculumSector,
    due_date: dueDateNumber,
    expiring_completion_in_days_after_completed: expirationNumber,
    verify_content_frequency_in_days: verifyContentNumber,
    advanced_settings: advancedSettings,
    owner_id: ownerId,
    locked,
    signatures_required: signaturesRequired,
  } = curriculum || {};

  const dueDate = useMemo(() => stringifyNumber(dueDateNumber), [dueDateNumber]);
  const expiration = useMemo(() => stringifyNumber(expirationNumber), [expirationNumber]);
  const verifyContentFrequency = useMemo(
    () => stringifyNumber(verifyContentNumber),
    [verifyContentNumber]
  );
  const dueDateRadio = useMemo(
    () => (dueDate !== '7' && dueDate !== '14' ? 'custom' : dueDate),
    [dueDate]
  );
  const expirationRadio = useMemo(() => {
    return expiration !== '30' && expiration !== '90' && expiration !== '365'
      ? 'custom'
      : expiration;
  }, [expiration]);
  const verifyContentRadio = useMemo(() => {
    return verifyContentFrequency !== '30' && verifyContentFrequency !== '365'
      ? 'custom'
      : verifyContentFrequency;
  }, [verifyContentFrequency]);
  const advancedSettingsLocked = paywalledFeatures.includes('curriculum_advanced_settings');
  const showSectorMoveRestrictedMessage = !!signaturesRequired && !hasESignatureFeature;
  const lockedSettings = useMemo(
    () =>
      advancedSettingsLocked
        ? {
            completionRequired: false,
            displayOwner: false,
            availableInLibrary: false,
            verifyContent: false,
          }
        : {},
    [advancedSettingsLocked]
  );
  const editingIsDisabled = document.getElementById('curriculum-modal')?.dataset.disabled == 'true';

  const initialSector = useMemo(
    () => curriculumSector || sector || null,
    [curriculumSector, sector]
  );

  const [selectedChip, setSelectedChip] = useState<ProcessChipType>(initialSector);
  const [updateCurriculum, updateResult] = useUpdateCurriculumMutation();
  const [createCurriculum, createResult] = useCreateCurriculumMutation();

  useDisplayFlashOnResponse({
    result: isUpdate ? updateResult : createResult,
    errorMessage: messageFromError(isUpdate ? updateResult.error : createResult.error)?.join(', '),
  });

  const canEditNew = useMemo(() => {
    return (
      createResult.isSuccess && ability.can('update', `EditCurriculum-${createResult.data.id}`)
    );
  }, [createResult, ability]);

  useEffect(() => {
    if (updateResult.isSuccess) {
      closeRequest();
    }

    if (canEditNew && createResult.data) {
      closeRequest();
      if (afterCreateCurriculumAction) {
        afterCreateCurriculumAction(createResult.data.id, createResult.data.title);
        return;
      }

      routeTo(routes.curriculumEdit({ slug, id: createResult.data.id }));
    }
  }, [
    createResult,
    id,
    slug,
    canEditNew,
    closeRequest,
    updateResult.isSuccess,
    afterCreateCurriculumAction,
  ]);

  const validateForm = (values: FormValues) => {
    const {
      title,
      checkBoxes,
      dueDateRadio,
      dueDate,
      expirationRadio,
      expiration,
      verifyContentFrequency,
      verifyContentRadio,
    } = values;
    const errors: FormikErrors<FormValues> = {};
    if (!title) errors.title = t('inputs.errors.required_field');
    if (title.length > 100) errors.title = t('inputs.errors.title_too_long');
    if (checkBoxes.dueDate) {
      if (dueDateRadio == 'custom' && !dueDate) {
        errors.dueDate = t('inputs.errors.required_field');
      } else if (!dueDateRadio) {
        errors.dueDateRadio = t('inputs.errors.required_field');
      }
    }
    if (checkBoxes.verifyContent) {
      if (verifyContentRadio == 'custom' && !verifyContentFrequency) {
        errors.verifyContentFrequency = t('inputs.errors.required_field');
      } else if (!verifyContentRadio) {
        errors.verifyContentRadio = t('inputs.errors.required_field');
      } else if (Number(verifyContentFrequency) > 365) {
        errors.verifyContentFrequency = t('inputs.errors.maximum_verification_period');
      }
    }
    if (checkBoxes.expiringCompletion) {
      if (expirationRadio == 'custom' && !expiration) {
        errors.expiration = t('inputs.errors.required_field');
      } else if (!expirationRadio) {
        errors.expirationRadio = t('inputs.errors.required_field');
      }
    }
    return errors;
  };

  const submitForm = useCallback(
    (values: FormValues) => {
      let calcDueDate: string | null = values.dueDateRadio;
      if (!values.checkBoxes.dueDate) {
        calcDueDate = null;
      } else if (calcDueDate === 'custom') {
        calcDueDate = values.dueDate;
      }
      let calcExpiration: string | null = values.expirationRadio;
      if (!values.checkBoxes.expiringCompletion) {
        calcExpiration = null;
      } else if (calcExpiration === 'custom') {
        calcExpiration = values.expiration;
      }
      let calcVerifyContentFrequency: string | null = values.verifyContentRadio;
      if (!values.checkBoxes.verifyContent) {
        calcVerifyContentFrequency = null;
      } else if (calcVerifyContentFrequency === 'custom') {
        calcVerifyContentFrequency = values.verifyContentFrequency;
      }
      const formattedData: FormData = {
        title: values.title,
        owner_assignment: values.ownerAssignment,
        description: values.description,
        due_date: calcDueDate,
        expiring_completion_in_days_after_completed: calcExpiration,
        completion_required: values.checkBoxes.completionRequired ? '1' : '0',
        forced_order: values.checkBoxes.forcedOrder ? '1' : '0',
        expiring_completion: values.checkBoxes.expiringCompletion ? '1' : '0',
        locked: values.checkBoxes.locked ? '1' : '0',
        display_owner: values.checkBoxes.displayOwner ? '1' : '0',
        completion_certificates: values.checkBoxes.completionCertificates,
        disable_translation: values.checkBoxes.disableTranslation,
        sector: values.sectorChip,
        verify_content: values.checkBoxes.verifyContent ? '1' : '0',
        verify_content_frequency_in_days: calcVerifyContentFrequency,
        ...lockedSettings,
      };

      id ? updateCurriculum({ id, ...formattedData }) : createCurriculum(formattedData);
    },
    [lockedSettings, id, updateCurriculum, createCurriculum]
  );

  const { values, handleChange, handleSubmit, setFieldValue, touched, errors, dirty } = useFormik({
    initialValues: {
      title: title || '',
      ownerAssignment: !isUpdate ? stringifyNumber(currentUserId) : stringifyNumber(ownerId),
      description: description || '',
      dueDateRadio: dueDate ? dueDateRadio : '7',
      dueDate: dueDate || '',
      expirationRadio: expiration ? expirationRadio : '30',
      expiration: expiration || '',
      verifyContentFrequency: verifyContentFrequency || '',
      verifyContentRadio: verifyContentFrequency ? verifyContentRadio : '30',
      checkBoxes: !advancedSettingsLocked
        ? advancedSettings
          ? {
              completionRequired: advancedSettings.completion_required,
              disableTranslation: Boolean(advancedSettings.disable_translation),
              displayOwner: advancedSettings.display_owner,
              expiringCompletion: advancedSettings.expiring_completion,
              forcedOrder: advancedSettings.forced_order,
              locked: locked || false,
              completionCertificates: advancedSettings.completion_certificates,
              verifyContent: Boolean(advancedSettings.verify_content),
              dueDate: Boolean(dueDate),
            }
          : DEFAULT_ADVANCED_SETTINGS
        : {
            ...DEFAULT_ADVANCED_SETTINGS,
            ...lockedSettings,
          },
      sectorChip: selectedChip && isSector(selectedChip) ? selectedChip : 'process',
    },
    validate: (values: FormValues) => validateForm(values),
    onSubmit: (values: FormValues) => submitForm(values),
  });

  const handleChipSelect = useCallback(
    (selectedChip: ProcessChipType) => {
      setFieldValue('sectorChip', selectedChip);
      setSelectedChip(selectedChip);
    },
    [setFieldValue]
  );

  const taskModalArgs: TaskModalProps = {
    title: modalTitle,
    subtitle: modalSubtitle,
    onCloseRequest: closeRequest,
    heapModalName: 'curriculum-modal',
    processing: createResult.isLoading || updateResult.isLoading,
    isDisabled: isUpdate && !dirty,
    primaryButtonText: isUpdate ? t('save_changes') : t('create'),
    primaryButtonTask: handleSubmit,
    secondaryButtonText: t('cancel'),
    visible,
  };

  const { title: curriculumTitle } = values;

  return (
    <TaskModal {...taskModalArgs} desktopSize='lg'>
      <form
        id='subject-modal-form'
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            // Block page reload
            event.preventDefault();
          }
        }}
      >
        <FormGroup id='subject-name-group'>
          <InputField
            autoFocus
            disabled={editingIsDisabled}
            disabledFieldTooltipId='disabled-by-e-signature-tooltip'
            disabledFieldTooltipText={t('disabled_by_e_signature')}
            errorText={touched.title && errors.title}
            id='title'
            label={t('subject_name_label', { subject: subjectTermSingular })}
            name='title'
            onChange={handleChange}
            required
            type='text'
            value={curriculumTitle}
          />
        </FormGroup>
        <FormGroup id='subject-sector-group'>
          <FieldLabel required text={t('sector_chip_select')} />
          <SectorChipSelect
            disabled={showSectorMoveRestrictedMessage}
            onChipSelect={handleChipSelect}
            sector={selectedChip && isSector(selectedChip) ? selectedChip : 'process'}
          />
          {showSectorMoveRestrictedMessage && (
            <SectorMoveRestrictedMessage
              dangerouslySetInnerHTML={{
                __html: t('sector_move_restricted', {
                  policies: policyTermPlural,
                  subjects: subjectTermPlural,
                  topic: topicTermSingular.toLowerCase(),
                  curriculum: curriculumTitle,
                }),
              }}
            />
          )}
        </FormGroup>
        {isUpdate && (
          <EditSettings
            errors={errors}
            handleChange={handleChange}
            optionalSettingsOpen={optionalSettingsOpen}
            ownerId={ownerId}
            setFieldValue={setFieldValue}
            signaturesRequired={signaturesRequired}
            touched={touched}
            userOptions={userOptions}
            values={values}
          />
        )}
        {errorMessage && <TaskErrorDiv>{errorMessage}</TaskErrorDiv>}
      </form>
    </TaskModal>
  );
};

export default SubjectModalForm;
