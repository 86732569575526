import { useTheme } from 'styled-components';

import { BillingPlanName } from '../../types/BillingPlanName';

export const usePlanBrandColors = ({ name }: { name: BillingPlanName }) => {
  const theme = useTheme();

  switch (name) {
    case 'train':
    case 'small':
      return {
        color: theme.vars.trainualBrandPurpleMedium,
        backgroundColor: theme.vars.trainualBrandPurpleSurfaceLight,
      };
    case 'medium':
      return {
        color: theme.vars.trainualBrandBlueMedium,
        backgroundColor: theme.vars.trainualBrandBlueSurfaceLight,
      };
    case 'free':
    case 'scale':
    case 'growth':
      return {
        color: theme.vars.trainualBrandGreenMedium,
        backgroundColor: theme.vars.trainualBrandGreenSurfaceLight,
      };
    case 'unlimited':
    case 'custom':
      return {
        color: theme.vars.trainualBrandYellowMedium,
        backgroundColor: theme.vars.trainualBrandYellowSurfaceLight,
      };
    default:
      return {
        color: theme.vars.foundationSurface2,
        backgroundColor: theme.vars.foundationHover,
      };
  }
};
