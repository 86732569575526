import React from 'react';

import { featuresListData } from '../../../../../../../lib/featuresListData';
import initTranslations from '../../../../../../../lib/initTranslations';
import P from '../../../../../../design_system/text/P';
import { FeatureIcon } from '../styles';
import {
  ArrowIcon,
  FeatureWrapper,
  FeaturesListWrapper,
  IncludedTrainFeaturesText,
  IncludedTrainFeaturesWrapper,
  TrainText,
} from './styles';

const t = initTranslations('per_user_pricing.billing_page.plans');

type PlanFeaturesListProps = {
  isTrainPlan: boolean;
};

export const PlanFeaturesList = ({ isTrainPlan }: PlanFeaturesListProps) => {
  const getItemKey = (feature: string) => {
    return feature
      .replace(/[^a-z]+/gi, '-')
      .replace(/(^-|-$)/g, '')
      .toLowerCase()
      .substring(0, 20);
  };

  const getIncludedTrainFeaturesText = () => {
    return (
      <>
        <span>{t('all_train_features_1')}</span>
        <TrainText>{t('all_train_features_2')}</TrainText>
        <span>{t('all_train_features_3')}</span>
      </>
    );
  };

  const features = featuresListData
    .filter((feature) => (isTrainPlan ? feature.train : !feature.train))
    .map((feature) => feature.title);

  return (
    <div>
      {!isTrainPlan && (
        <IncludedTrainFeaturesWrapper>
          <ArrowIcon name='arrow-left' size='sm' weight='solid' />
          <IncludedTrainFeaturesText text={getIncludedTrainFeaturesText()} />
        </IncludedTrainFeaturesWrapper>
      )}
      <FeaturesListWrapper>
        {features.map((feature) => (
          <FeatureWrapper key={`${getItemKey(feature)}`}>
            <FeatureIcon
              isFeatureIncluded
              isTrainPlan={isTrainPlan}
              name='circle-check'
              size='sm'
              weight='solid'
            />
            <P text={feature} />
          </FeatureWrapper>
        ))}
      </FeaturesListWrapper>
    </div>
  );
};
