type Props = {
  stepId: string | number;
};

type StepTag = {
  type: 'Step' | 'Account';
  id: string | number;
};

export const stepTags = ({ stepId }: Props): StepTag[] => {
  return [
    { type: 'Step', id: 'LIST' },
    { type: 'Step', id: stepId },
    { type: 'Account', id: 'CURRENT_ACCOUNT' },
  ];
};
