import styled from 'styled-components';

import P from '../../../../../../design_system/text/P';
import { SpaceBetweenWrapper } from '../../../../shared/styles';

export const ESignatureWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ESignatureText = styled(P)`
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.constants.spacerSm2};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  font-style: italic;
`;

export const TooltipWrapper = styled.div`
  width: 20rem;
  font-style: normal;
`;

export const SignaturesUsedRow = styled(SpaceBetweenWrapper)`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;
