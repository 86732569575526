import React, { useState } from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import { CollapsibleContent } from '../../../../design_system/Triage/layout/CollapsibleSurface';
import { getChevronIconName } from '../../../shared/helpers';
import { StyledIcon, TermsBody, TermsClickable, TermsWrapper } from './styles';

const t = initTranslations('refer_a_friend_modal');

const Terms = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const dynamicChevron = getChevronIconName({ isActive: !isCollapsed, initialDirection: 'up' });
  const { productTerm } = useAccountTerminology();

  return (
    <TermsWrapper>
      <TermsClickable onClick={() => setIsCollapsed(!isCollapsed)}>
        {t('terms_label')}
        <StyledIcon name={dynamicChevron} />
      </TermsClickable>
      <CollapsibleContent isCollapsed={isCollapsed} isCollapsible>
        <TermsBody>{t('offers.special_250.terms.body', { productTerm })}</TermsBody>
      </CollapsibleContent>
    </TermsWrapper>
  );
};

export default Terms;
