import { Route } from 'type-route';

import { routes } from './applicationRouter';

/**
 * Handles routing in the application based on the specified route and availability of Turbolinks.
 *
 * @param {string | Route<typeof routes>} route - The route to navigate to. Can be a string for legacy routing or a Route object for client-side routing.\
 *
 * @throws {Error} If the requested method of navigation is not supported.
 *
 * @note Legacy routing will be removed once the application is fully transitioned to React.
 */
const routeTo = (route: string | Route<typeof routes>) => {
  const legacyRoutingRequested = typeof route === 'string';
  const clientSideRoutingRequested = !legacyRoutingRequested;
  const turbolinksAvailable = !!window.Turbolinks;

  if (clientSideRoutingRequested && !turbolinksAvailable) {
    route.push();
    return;
  } else if (clientSideRoutingRequested && turbolinksAvailable) {
    window.Turbolinks.visit(route.href);
    return;
  } else if (legacyRoutingRequested && turbolinksAvailable) {
    window.Turbolinks.visit(route);
    return;
  } else if (legacyRoutingRequested && !turbolinksAvailable) {
    window.location.href = route;
    return;
  }

  throw new Error('Router navigation requested method of navigation is not supported');
};

export default routeTo;
