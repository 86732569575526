import React from 'react';
import { useTheme } from 'styled-components';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import { SkeletonLoader } from '../../../../shared/SkeletonLoader/styles';
import {
  BillingIntervalToggleWrapper,
  CardsHeaderWrapper,
} from '../../../simple_pricing/BillingPage/shared/PlanCardHeader/styles';
import { CardsWrapper } from '../shared/styles';

const BillingPageSkeleton = () => {
  const {
    constants: { spacerSm2, spacerMd3, borderRadiusSm, borderRadiusMd, borderRadiusLg },
  } = useTheme();

  const {
    splitFeatures: { billingPagePlansWithTrainualPlusEnabled },
  } = useCurrentAccount();

  return (
    <>
      <CardsWrapper>
        <SkeletonLoader borderRadius={borderRadiusMd} height='18.5rem' />
        <SkeletonLoader borderRadius={borderRadiusMd} height='18.5rem' />
      </CardsWrapper>

      <CardsHeaderWrapper>
        <SkeletonLoader borderRadius={borderRadiusSm} height='1.8rem' width='3.875rem' />
        <SkeletonLoader
          borderRadius={borderRadiusSm}
          height='1.125rem'
          marginBottom={spacerMd3}
          marginTop={spacerSm2}
          width='15.75rem'
        />
        <BillingIntervalToggleWrapper>
          <SkeletonLoader borderRadius={borderRadiusSm} height='2.375rem' width='17.5rem' />
        </BillingIntervalToggleWrapper>
      </CardsHeaderWrapper>
      <CardsWrapper>
        {billingPagePlansWithTrainualPlusEnabled ? (
          <>
            <SkeletonLoader
              borderRadius={borderRadiusLg}
              height='19.5rem'
              width='calc((100% - 3rem) / 2)'
            />
            <SkeletonLoader
              borderRadius={borderRadiusLg}
              height='19.5rem'
              width='calc((100% - 3rem) / 2)'
            />
          </>
        ) : (
          <>
            <SkeletonLoader
              borderRadius={borderRadiusLg}
              height='17rem'
              width='calc((100% - 6rem) / 4)'
            />
            <SkeletonLoader
              borderRadius={borderRadiusLg}
              height='17rem'
              width='calc((100% - 6rem) / 4)'
            />
            <SkeletonLoader
              borderRadius={borderRadiusLg}
              height='17rem'
              width='calc((100% - 6rem) / 4)'
            />
            <SkeletonLoader
              borderRadius={borderRadiusLg}
              height='17rem'
              width='calc((100% - 6rem) / 4)'
            />
          </>
        )}
      </CardsWrapper>
    </>
  );
};

export default BillingPageSkeleton;
