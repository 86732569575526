import ReactOnRails from 'react-on-rails';
import { AuthenticityHeaders } from 'react-on-rails/node_package/lib/types';

interface Props {
  useAuthentication: boolean;
}

const axiosHeaders = ({ useAuthentication = false }: Props): AuthenticityHeaders => {
  const contentType = { 'Content-Type': 'application/json' };
  let authHeaders: AuthenticityHeaders | null = null;

  if (useAuthentication) {
    authHeaders = {
      ...contentType,
      'X-CSRF-Token': ReactOnRails.authenticityToken() || '',
      'X-Requested-With': 'XMLHttpRequest',
    };
  }

  return ReactOnRails.authenticityHeaders(authHeaders || contentType);
};

export default axiosHeaders;
