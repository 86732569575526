import React, { Dispatch, ReactNode, createContext, useContext, useReducer } from 'react';

import { GroupKindValue } from '../components/application/ContentLibrary/ContentLibraryPage/libraryReducer';
import { Group, GroupKind, GroupKindWithAll } from '../types/Group';

type State = {
  groupToDelete: Group | null;
  newGroupKind: GroupKind;
  activeModal: 'groupKind' | 'groupName' | 'deleteGroup' | null;
};

type Action =
  | { type: 'setNewGroupKind'; payload: GroupKindWithAll }
  | { type: 'openGroupKindModal' }
  | { type: 'openGroupNameModal'; payload?: GroupKind }
  | { type: 'openDeleteGroupModal'; payload: Group }
  | { type: 'closeDeleteGroupModal' }
  | { type: 'closeGroupKindModal' }
  | { type: 'closeGroupNameModal' };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setNewGroupKind': {
      const newGroupKind = action.payload === 'all' ? GroupKindValue.Role : action.payload;
      return {
        ...state,
        newGroupKind,
      };
    }
    case 'openGroupKindModal': {
      return {
        ...state,
        activeModal: 'groupKind',
      };
    }
    case 'openGroupNameModal': {
      const newGroupKind = action.payload || state.newGroupKind;
      return {
        ...state,
        newGroupKind,
        activeModal: 'groupName',
      };
    }
    case 'openDeleteGroupModal': {
      return {
        ...state,
        groupToDelete: action.payload,
        activeModal: 'deleteGroup',
      };
    }
    case 'closeDeleteGroupModal': {
      return {
        ...state,
        groupToDelete: null,
        activeModal: null,
      };
    }
    case 'closeGroupKindModal':
    case 'closeGroupNameModal': {
      return {
        ...state,
        activeModal: null,
      };
    }
  }
};

interface Props {
  children: ReactNode;
  redirectToNewGroup?: boolean;
}

type GroupsModalProviderContext = {
  dispatch: Dispatch<Action>;
  state: State;
  redirectToNewGroup?: boolean;
};

const initialState: State = {
  groupToDelete: null,
  newGroupKind: GroupKindValue.Role,
  activeModal: null,
};

const GroupsModalContext = createContext({} as GroupsModalProviderContext);
export const useGroupsModals = () => useContext(GroupsModalContext);

export const GroupsModalProvider = ({ children, redirectToNewGroup = true }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GroupsModalContext.Provider value={{ state, dispatch, redirectToNewGroup }}>
      {children}
    </GroupsModalContext.Provider>
  );
};
