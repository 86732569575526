import React, { useCallback } from 'react';

import { useAppSelector } from '../../../../../../redux/hooks';
import { reportsApi } from '../../../../../../redux/services/resourceApis/reports/reportsApi';
import DownloadCsvReport from '../../../DownloadCsvReport/DownloadCsvReport';
import { IndividualUserDownloadCsvReportProps } from '../types';

const IndividualUserDownloadCsvReport = ({
  userId,
  sortColumn,
  sortDirection,
}: IndividualUserDownloadCsvReportProps) => {
  const { searchTerm, filters } = useAppSelector((state) => state.reports.individualUserReport);
  const [getCsv] = reportsApi.useLazyGetIndividualUserReportCsvQuery();

  const downloadCsvDocument = useCallback(() => {
    getCsv({
      ...filters,
      searchTerm,
      id: userId,
      sortCol: sortColumn,
      sortColDir: sortDirection,
    });
  }, [filters, getCsv, searchTerm, sortColumn, sortDirection, userId]);

  return (
    <DownloadCsvReport
      btnSize='md'
      downloadCsvDocument={downloadCsvDocument}
      id='individual-user-button-download-csv'
    />
  );
};

export default IndividualUserDownloadCsvReport;
