import React, { useEffect, useState } from 'react';

import useAnalyticsUserEvents from '../../../../hooks/useAnalyticsUserEvents';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import ComposeStepModal, {
  Props as ComposeStepModalProps,
} from '../ComposeStepModal/ComposeStepModal';
import ComposeTermsModal from '../ComposeTermsModal/ComposeTermsModal';
import EditorComposeStepModal from '../EditorComposeStepModal/EditorComposeStepModal';
import FroalaComposeStepModal from '../FroalaComposeStepModal/FroalaComposeStepModal';
import GenerateRoleChart from '../GenerateRoleChart/GenerateRoleChart';
import GroupDescriptionModal from '../GroupDescriptionModal/GroupDescriptionModal';
import { GroupDescriptionModalProps } from '../GroupDescriptionModal/types';
import {
  ComposeModalMetadata,
  ComposeModalProps,
  ComposeModalStateProps,
} from '../shared/modals/shared';
import SubjectDescriptionModal from '../SubjectDescriptionModal/SubjectDescriptionModal';
import { SubjectDescriptionModalProps } from '../SubjectDescriptionModal/types';
import SurveyDescriptionModal from '../SurveyDescriptionModal/SurveyDescriptionModal';
import { SurveyDescriptionModalProps } from '../SurveyDescriptionModal/types';

type ComposeModal = (props: ComposeModalProps) => JSX.Element;

const ComposeModalRegistry: Record<string, ComposeModal> = {
  composeStep: (props: ComposeStepModalProps) => <ComposeStepModal {...props} />,
  editorComposeStep: (props: ComposeStepModalProps) => <EditorComposeStepModal {...props} />,
  froalaComposeStep: (props: ComposeStepModalProps) => <FroalaComposeStepModal {...props} />,
  autogenerateRoleChart: (props: ComposeStepModalProps) => <GenerateRoleChart {...props} />,
  groupDescription: (props: GroupDescriptionModalProps) => <GroupDescriptionModal {...props} />,
  subjectDescription: (props: SubjectDescriptionModalProps) => (
    <SubjectDescriptionModal {...props} />
  ),
  SurveyDescriptionModal: (props: SurveyDescriptionModalProps) => (
    <SurveyDescriptionModal {...props} />
  ),
};

export type ComposeModalType = keyof typeof ComposeModalRegistry;

const shouldOpenModal = (
  modalOpen: boolean,
  acceptedAiTerms: boolean,
  modalType: 'terms' | 'compose'
): boolean => {
  switch (modalType) {
    case 'terms':
      return modalOpen && !acceptedAiTerms;
    case 'compose':
      return modalOpen && acceptedAiTerms;
  }
};

type Props = {
  closeModal: () => void;
  modalOpen: boolean;
  composeModalType: ComposeModalType;
  metadata?: ComposeModalMetadata;
} & ComposeModalStateProps;

const ComposeModalFactory = ({
  closeModal,
  isComposeModalDisabled,
  isComposeModalLoading,
  setComposeModalDisabled,
  setComposeModalLoading,
  modalOpen,
  composeModalType,
  metadata,
}: Props) => {
  const { cdpCompositionTermsPromptDisplayed, cdpCompositionDisplayed } = useAnalyticsUserEvents();
  const { acceptedAiTerms } = useCurrentUser();
  const [termsModalOpen, setTermsModalOpen] = useState(
    shouldOpenModal(modalOpen, acceptedAiTerms, 'terms')
  );
  const [aiModalOpen, setAiModalOpen] = useState(
    shouldOpenModal(modalOpen, acceptedAiTerms, 'compose')
  );

  useEffect(() => {
    setTermsModalOpen(shouldOpenModal(modalOpen, acceptedAiTerms, 'terms'));
    setAiModalOpen(shouldOpenModal(modalOpen, acceptedAiTerms, 'compose'));
  }, [acceptedAiTerms, modalOpen]);

  useEffect(() => {
    if (termsModalOpen) {
      cdpCompositionTermsPromptDisplayed({ compose_experience: composeModalType });
    }
  }, [cdpCompositionTermsPromptDisplayed, composeModalType, termsModalOpen]);

  useEffect(() => {
    if (aiModalOpen) {
      cdpCompositionDisplayed({ compose_experience: composeModalType });
    }
  }, [aiModalOpen, cdpCompositionDisplayed, composeModalType]);

  return (
    <>
      {termsModalOpen && (
        <ComposeTermsModal closeModal={closeModal} composeModalType={composeModalType} />
      )}
      {aiModalOpen &&
        ComposeModalRegistry[composeModalType]({
          closeModal,
          composeModalType,
          isComposeModalDisabled,
          isComposeModalLoading,
          setComposeModalDisabled,
          setComposeModalLoading,
          metadata,
        })}
    </>
  );
};

export default ComposeModalFactory;
