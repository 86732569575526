import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled from 'styled-components';

import useSlideout from '../../../../hooks/useSlideout';
import initTranslations from '../../../../lib/initTranslations';
import Icon from '../../../design_system/display/icons/Icon';
import { fontSm5 } from '../../../styled/TypeSystem';

const CloseButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const CloseClickableArea = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const CloseButtonText = styled.span`
  display: flex;
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  cursor: pointer;
  ${fontSm5};
`;

const CloseIconWrapper = styled.div`
  display: flex;
  padding-right: ${({ theme: { constants } }) => constants.spacerSm3};
  cursor: pointer;
  button {
    padding: 0;
  }
`;

const t = initTranslations('slideout');

export type Props = {
  slideoutType: string;
  customCloseButtonIcon?: IconName | undefined;
};

const CloseSlideoutButton = ({ slideoutType, customCloseButtonIcon }: Props) => {
  const { close } = useSlideout(slideoutType);
  return (
    <CloseButtonWrapper>
      <CloseClickableArea id='slideout-close-button' onClick={close}>
        <CloseIconWrapper>
          {customCloseButtonIcon ? (
            <Icon name={customCloseButtonIcon} size='xs' />
          ) : (
            <>
              <Icon name='chevron-right' size='xs' />
              <Icon name='bars' size='xs' />
            </>
          )}
        </CloseIconWrapper>
        <CloseButtonText>{t('close_panel')}</CloseButtonText>
      </CloseClickableArea>
    </CloseButtonWrapper>
  );
};

export default CloseSlideoutButton;
