import { isEqual } from 'lodash';
import React, { useReducer } from 'react';

import capitalize from '../../../../../lib/capitalize';
import initTranslations from '../../../../../lib/initTranslations';
import {
  AllUsersReportPageFilterState,
  LastActiveAt,
  setAllUsersReportFilters,
} from '../../../../../redux/domains/reports/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useGetAllUsersReportTableFiltersDataQuery } from '../../../../../redux/services/resourceApis/reports/reportsApi';
import { LastActive } from '../../../../../redux/services/resourceApis/reports/types';
import { groupsToOptions } from '../../../../../types/Group';
import { formatOptions } from '../../../../design_system/core/CoreSelectField/CoreSelectField';
import { BaseOption } from '../../../../design_system/core/SelectOption/types';
import MultiSelectField from '../../../../design_system/Triage/fields/MultiSelectField';
import SingleSelectField from '../../../../design_system/Triage/fields/SingleSelectField';
import CollapsibleFiltersSection from '../../../shared/CollapsibleFiltersSection/CollapsibleFiltersSection';
import CompletionScoreSection from '../../CompletionScoreSection/CompletionScoreSection';
import ReportFiltersSlideout from '../../ReportFiltersSlideout/ReportFiltersSlideout';
import { filterReducer } from './reducer';

const t = initTranslations('reports.users_report_filters');

const AllUsersReportFilters = () => {
  const dispatch = useAppDispatch();
  const { appliedFiltersCount, filters: appliedFilters } = useAppSelector(
    (state) => state.reports.allUsersReport
  );

  const initialFilterState: AllUsersReportPageFilterState = {
    completionScoreMin: 0,
    completionScoreMax: 100,
    lastActiveAt: null,
    groupIds: [],
    roleIds: [],
    teamIds: [],
  };

  const appliedFilterState: AllUsersReportPageFilterState = {
    completionScoreMin: appliedFilters.completionScoreMin,
    completionScoreMax: appliedFilters.completionScoreMax,
    lastActiveAt: appliedFilters.lastActiveAt,
    groupIds: appliedFilters.groupIds,
    roleIds: [],
    teamIds: [],
  };

  const [filterState, dispatchState] = useReducer(filterReducer, appliedFilterState);
  const { completionScoreMax, completionScoreMin, groupIds, roleIds, teamIds, lastActiveAt } =
    filterState;
  const noAppliedFilters = appliedFiltersCount === 0;
  const isDisabledApplyBtn = isEqual(appliedFilters, filterState);

  const clearFilters = () => {
    dispatch(setAllUsersReportFilters(initialFilterState));
    dispatchState({ type: 'setDefaultFilters' });
  };

  const applyFilters = () => {
    dispatch(
      setAllUsersReportFilters({
        groupIds,
        roleIds,
        teamIds,
        lastActiveAt,
        completionScoreMax,
        completionScoreMin,
      })
    );
  };

  const lastActiveToOptions = (options: LastActive) => {
    const optionsData: BaseOption[] = Object.entries(options).map((option) => ({
      label: `${capitalize(option[0])} (${option[1]})`,
      value: option[0],
      disabled: option[1] === 0,
    }));
    return formatOptions(optionsData);
  };

  const { data } = useGetAllUsersReportTableFiltersDataQuery();

  if (!data) return <></>;

  const { usersCompletionData, groups, usersLastActiveData } = data;

  const teams = groups.filter(({ kind }) => kind === 'team');
  const roles = groups.filter(({ kind }) => kind === 'role');

  const completionData = Object.entries(usersCompletionData).map((item) => ({
    name: item[0],
    count: item[1],
  }));

  return (
    <ReportFiltersSlideout
      applyFilters={applyFilters}
      clearFilters={clearFilters}
      disabledApplyButton={isDisabledApplyBtn}
      disabledClearButton={noAppliedFilters}
      slideoutType='all-users-filter-slideout'
      title={t('title')}
    >
      <CompletionScoreSection
        completionScoreMax={completionScoreMax}
        completionScoreMin={completionScoreMin}
        data={completionData}
        setMaxCompletionScore={(values) => {
          dispatchState({
            type: 'setMaxCompletionScore',
            completionScoreMax: values,
          });
        }}
        setMinCompletionScore={(values) => {
          dispatchState({
            type: 'setMinCompletionScore',
            completionScoreMin: values,
          });
        }}
      />
      <CollapsibleFiltersSection title={t('group')}>
        <MultiSelectField
          defaultValue={[]}
          onNonDefaultSelected={(value: string[]) => {
            dispatchState({ type: 'setGroups', groupIds: value });
            const teamIds = teams.map(({ id }) => `${id}`);
            const roleIds = roles.map(({ id }) => `${id}`);
            const newTeamIds = value.filter((id) => teamIds.includes(id));
            const newRoleIds = value.filter((id) => roleIds.includes(id));
            dispatchState({ type: 'setRoles', roleIds: newRoleIds });
            dispatchState({ type: 'setTeams', teamIds: newTeamIds });
          }}
          options={groupsToOptions(groups)}
          placeholder={t('group_placeholder')}
          value={groupIds}
        />
      </CollapsibleFiltersSection>
      <CollapsibleFiltersSection title={t('last_active')}>
        <SingleSelectField
          defaultValue={lastActiveAt}
          onNonDefaultSelected={(value: LastActiveAt) => {
            dispatchState({ type: 'setLastActive', lastActiveAt: value });
          }}
          options={lastActiveToOptions(usersLastActiveData)}
          placeholder={t('last_active_placeholder')}
          value={lastActiveAt}
        />
      </CollapsibleFiltersSection>
    </ReportFiltersSlideout>
  );
};

export default AllUsersReportFilters;
