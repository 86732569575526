import React from 'react';

import { RequestsCardProps } from '../types';
import Create, { RequestCreateProps } from './Create';
import RequestResponseTemplate, { RequestResponseTemplateProps } from './RequestResponseTemplate';

interface RequestActivitiesProps {
  'request.create': React.FC<RequestCreateProps>;
  'request.approved': React.FC<RequestResponseTemplateProps>;
  'request.denied': React.FC<RequestResponseTemplateProps>;
}

const ACTIVITY_KEYS: RequestActivitiesProps = {
  'request.create': Create,
  'request.approved': RequestResponseTemplate,
  'request.denied': RequestResponseTemplate,
};

const RequestActivitiesDetails = ({ activity }: RequestsCardProps) => {
  const ActivityMessage = ACTIVITY_KEYS[activity.key as keyof RequestActivitiesProps];

  return (
    <ActivityMessage
      activityKey={activity.key}
      recipient={activity.recipient}
      trackable={activity.trackable}
    />
  );
};

export default RequestActivitiesDetails;
