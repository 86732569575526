import styled from 'styled-components';

export const RangeThumb = styled.div`
  height: 1.25rem;
  width: 1.25rem;
  position: relative;
  z-index: 1 !important;
  border-radius: 50%;
  background-color: ${({ theme: { vars } }) => vars.accentSubdued1};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthLg} solid ${vars.accentPrimaryDefault}`};
  transform-style: preserve-3d;
  &:before,
  &:after {
    content: '';
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    top: -4px;
    background-color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
    transform: translateZ(-1px);
    border-radius: 1.5rem;
    right: -4px;
  }
`;

export const RangeContainer = styled.div`
  height: 1.5rem;
  display: flex;
  width: calc(100% - 1.5rem);
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  position: relative;
  background-color: ${({ theme: { vars } }) => vars.foundationBase2};
  margin: 0 ${({ theme: { constants } }) => constants.spacerMd1};
  &:before,
  &:after {
    content: '';
    display: block;
    height: 1.5rem;
    width: 2.25rem;
    position: absolute;
    top: 0;
    background-color: ${({ theme: { vars } }) => vars.foundationBase2};
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
    z-index: -1;
  }
  &:before {
    left: -12px;
  }
  &:after {
    right: -12px;
  }
`;

export const RangeBar = styled.div`
  height: 1.5rem;
  width: 100%;
  align-self: center;
`;

export const RangeProgressBar = styled.div<{ rangeValue: number[] }>`
  position: absolute;
  height: 1.5rem;
  width: ${({ rangeValue }) =>
    rangeValue[1]
      ? `calc(100% - ${rangeValue[0]}% - (100% - ${rangeValue[1]}%))`
      : `${rangeValue[0]}%`};
  align-self: center;
  background-color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  left: ${({ rangeValue }) => (rangeValue[1] ? `${rangeValue[0]}%` : 0)};
  right: ${({ rangeValue }) => (rangeValue[1] ? `${rangeValue[1]}%` : `${rangeValue[0]}%`)};
  &:before {
    content: '';
    display: ${({ rangeValue }) => (rangeValue[1] ? 'none' : 'block')};
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 1.5rem;
    background-color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
    left: -12px;
    position: absolute;
  }
`;
