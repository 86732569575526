import React from 'react';

import { SimplePricingBillingPlanData } from '../../../../../../../redux/services/resourceApis/billing/simplePricingTypes';
import { SimplePricingBillingPlanName } from '../../../../../../../types/BillingPlanName';
import PlanName from './PlanName/PlanName';
import { PlansCardTabsWrapper, StyledTabs } from './styles';

export type PlansCardTabsProps = {
  selectedPlan: SimplePricingBillingPlanData;
  plans: SimplePricingBillingPlanData[];
  setSelectedPlanName: React.Dispatch<React.SetStateAction<SimplePricingBillingPlanName>>;
};

const PlansCardTabs = ({ plans, selectedPlan, setSelectedPlanName }: PlansCardTabsProps) => {
  return (
    <PlansCardTabsWrapper>
      <StyledTabs id='plans-card-tabs'>
        {plans.map(({ name }) => {
          const isSelected = name === selectedPlan.name;

          return (
            <li key={name} onClick={() => setSelectedPlanName(name)}>
              <PlanName isSelected={isSelected} name={name} />
            </li>
          );
        })}
      </StyledTabs>
    </PlansCardTabsWrapper>
  );
};

export default PlansCardTabs;
