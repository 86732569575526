import styled from 'styled-components';

import { fontSm5 } from '../../../../styled/TypeSystem';
import { FontSize, FontSizeSelected } from '../../Slideouts/Shared/FontSize';

export const JobTitle = styled.p<{ fontSize?: FontSize }>`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  text-align: center;
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  margin-bottom: 0;
  ${FontSizeSelected};
`;

export const LabelWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const NoResultsText = styled.div`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-style: italic;
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  padding-left: ${({ theme: { constants } }) => constants.spacerMd3};
  margin: ${({ theme: { constants } }) => `${constants.spacerSm3} 0 0 ${constants.spacerSm2}`};
  ${fontSm5};
`;

export const mobileFontSize = (isExtraSmWindow: boolean) => {
  return isExtraSmWindow ? 'sm4' : undefined;
};
