import styled from 'styled-components';

export const QuickSearchDropdownWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  /* 4 px is added to the width to count the border (2 px on each side) */
  width: calc(100% + 4px);
  position: absolute;
  top: -2px;
  left: -2px;
  margin: 0;
  padding: ${({ theme: { constants } }) => `${constants.heightMd} 0 0`};
  overflow: hidden;
  list-style-type: none;
  /* box-sizing is necessary for correct display in the storybook */
  box-sizing: border-box;
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthLg} solid ${vars.accentPrimaryDefault}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopLarge};
`;

export const QuickSearchDropdownDivider = styled.div<{ withTopMargin?: boolean }>`
  width: 100%;
  height: 1px;
  margin-top: ${({ withTopMargin, theme: { constants } }) =>
    withTopMargin ? constants.spacerSm3 : 0};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  background: ${({ theme: { vars } }) => vars.borderSurface1};
  &:last-of-type {
    display: none;
  }
`;
