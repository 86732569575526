import Abstract from '../../../../../images/abstract.svg';
import Airtable from '../../../../../images/airtable.svg';
import Avocode from '../../../../../images/avocode.svg';
import Codepen from '../../../../../images/codepen.svg';
import Codesandbox from '../../../../../images/codesandbox.svg';
import Dribble from '../../../../../images/dribble.svg';
import Droplr from '../../../../../images/droplr.svg';
import EmbedLink from '../../../../../images/embed_link.svg';
import Facebook from '../../../../../images/facebook.svg';
import Figma from '../../../../../images/figma.svg';
import CodeFile from '../../../../../images/file-code-regular.svg';
import Github from '../../../../../images/github.svg';
import GoogleDataStudio from '../../../../../images/google_data_studio.svg';
import GoogleDocs from '../../../../../images/google_docs.svg';
import GoogleMaps from '../../../../../images/google_maps.svg';
import GoogleSlides from '../../../../../images/google_slides.svg';
import Instagram from '../../../../../images/instagram.svg';
import Keynote from '../../../../../images/keynote.svg';
import Libsyn from '../../../../../images/libsyn.svg';
import Loom from '../../../../../images/loom_logo.svg';
import Marvelapp from '../../../../../images/marvel_app.svg';
import Meetup from '../../../../../images/meetup.svg';
import Overflow from '../../../../../images/overflow.svg';
import Pdf from '../../../../../images/pdf.svg';
import PollEverywhere from '../../../../../images/poll_everywhere.svg';
import RadioPublic from '../../../../../images/radio_public.svg';
import Soundcloud from '../../../../../images/soundcloud.svg';
import Spotify from '../../../../../images/spotify.svg';
import TikTok from '../../../../../images/tik_tok.svg';
import TrainualCapture from '../../../../../images/trainual_capture_logo.svg';
import Trainual from '../../../../../images/trainual_logo_avatar.svg';
import Vectary from '../../../../../images/vectary.svg';
import Vimeo from '../../../../../images/vimeo.svg';
import Yelp from '../../../../../images/yelp.svg';
import Youtube from '../../../../../images/youtube.svg';
import initTranslations from '../../../../../lib/initTranslations';
import { EmbedlyMenuItemProps } from './EmbedlyMenu/EmbedlyMenu';

const t = initTranslations('editor.embedly');

export type EmbedlyItemsSections = {
  generic: EmbedlyMenuItemProps[];
  video: EmbedlyMenuItemProps[];
  maps: EmbedlyMenuItemProps[];
  development: EmbedlyMenuItemProps[];
  design: EmbedlyMenuItemProps[];
  socialMedia: EmbedlyMenuItemProps[];
  engagement: EmbedlyMenuItemProps[];
  project: EmbedlyMenuItemProps[];
};

export const items: EmbedlyItemsSections = {
  generic: [
    {
      id: 'embed',
      source: 'generic',
      svgSrc: EmbedLink,
      svgClassName: 'generic-embedly-embed',
      title: t('embed'),
    },
    {
      id: 'embed-iframe',
      source: 'embed-iframe',
      svgSrc: CodeFile,
      svgClassName: 'iframe-embed',
      title: t('embed_iframe'),
    },
    {
      id: 'pdf',
      source: 'file',
      svgSrc: Pdf,
      svgClassName: 'embedly-pdf',
      caption: t('pdf_caption'),
      title: t('pdf'),
    },
    {
      id: 'trainual',
      source: 'trainual',
      svgSrc: Trainual,
      svgClassName: 'embed-trainual',
      caption: t('trainual_caption'),
      title: t('trainual'),
    },
    {
      id: 'trainual-capture',
      source: 'trainual_capture',
      svgSrc: TrainualCapture,
      svgClassName: 'embed-trainual-capture',
      caption: t('trainual_capture_caption'),
      title: t('trainual_capture'),
    },
  ],
  video: [
    {
      id: 'upload',
      source: 'video',
      svgSrc: Droplr,
      svgClassName: 'embedly-droplr',
      caption: t('project'),
      title: t('droplr'),
    },
    {
      id: 'loom',
      source: 'video',
      svgSrc: Loom,
      svgClassName: 'embedly-loom',
      caption: t('video'),
      title: t('loom'),
    },
    {
      id: 'vimeo',
      source: 'video',
      svgSrc: Vimeo,
      svgClassName: 'embedly-vimeo',
      caption: t('video'),
      title: t('vimeo'),
    },
    {
      id: 'youtube',
      source: 'video',
      svgSrc: Youtube,
      svgClassName: 'embedly-youtube',
      caption: t('video'),
      title: t('youtube'),
    },
  ],
  maps: [
    {
      id: 'google-maps',
      source: 'maps',
      svgSrc: GoogleMaps,
      svgClassName: 'embedly-maps',
      caption: t('maps'),
      title: t('google_maps'),
    },
    {
      id: 'yelp',
      source: 'maps',
      svgSrc: Yelp,
      svgClassName: 'embedly-yelp',
      caption: t('reviews'),
      title: t('yelp'),
    },
  ],
  engagement: [
    {
      id: 'google-slides',
      source: 'engagement',
      svgSrc: GoogleSlides,
      svgClassName: 'embedly-google-slides',
      caption: t('presentation'),
      title: t('google_slides'),
    },
    {
      id: 'keynote',
      source: 'engagement',
      svgSrc: Keynote,
      svgClassName: 'embedly-keynote',
      caption: t('presentation'),
      title: t('keynote'),
    },
    {
      id: 'meetup',
      source: 'engagement',
      svgSrc: Meetup,
      svgClassName: 'embedly-meetup',
      caption: t('events'),
      title: t('meetup'),
    },
    {
      id: 'poll-everywhere',
      source: 'engagement',
      svgSrc: PollEverywhere,
      svgClassName: 'embedly-poll-everywhere',
      caption: t('polling'),
      title: t('poll_everywhere'),
    },
  ],
  project: [
    {
      id: 'airtable',
      source: 'project',
      svgSrc: Airtable,
      svgClassName: 'embedly-airtable',
      caption: t('project'),
      title: t('airtable'),
    },
    {
      id: 'google-data-studio',
      source: 'project',
      svgSrc: GoogleDataStudio,
      svgClassName: 'embedly-google-data-studio',
      caption: t('heading_1'),
      title: t('google_data_studio'),
    },
    {
      id: 'google-docs',
      source: 'project',
      svgSrc: GoogleDocs,
      svgClassName: 'embedly-google-docs',
      caption: t('document'),
      title: t('google_docs'),
    },
  ],
  development: [
    {
      id: 'github',
      source: 'development',
      svgSrc: Github,
      svgClassName: 'embedly-github',
      caption: t('project'),
      title: t('github'),
    },
    {
      id: 'codepen',
      source: 'development',
      svgSrc: Codepen,
      svgClassName: 'embedly-codepen',
      caption: t('project'),
      title: t('codepen'),
    },
    {
      id: 'codesandbox',
      source: 'development',
      svgSrc: Codesandbox,
      svgClassName: 'embedly-codesandbox',
      caption: t('project'),
      title: t('codesandbox'),
    },
  ],
  socialMedia: [
    {
      id: 'instagram',
      source: 'socialMedia',
      svgSrc: Instagram,
      svgClassName: 'embedly-instagram',
      caption: t('social_media'),
      title: t('instagram'),
    },
    {
      id: 'facebook',
      source: 'socialMedia',
      svgSrc: Facebook,
      svgClassName: 'embedly-facebook',
      caption: t('social_media'),
      title: t('facebook'),
    },
    {
      id: 'tik-tok',
      source: 'video',
      svgSrc: TikTok,
      svgClassName: 'embedly-tik-tok',
      caption: t('social_media'),
      title: t('tik_tok'),
    },
    {
      id: 'spotify',
      source: 'socialMedia',
      svgSrc: Spotify,
      svgClassName: 'embedly-spotify',
      caption: t('music'),
      title: t('spotify'),
    },
    {
      id: 'libsyn',
      source: 'socialMedia',
      svgSrc: Libsyn,
      svgClassName: 'embedly-libsyn',
      caption: t('podcast'),
      title: t('libsyn'),
    },
    {
      id: 'radio-public',
      source: 'socialMedia',
      svgSrc: RadioPublic,
      svgClassName: 'embedly-radio-public',
      caption: t('podcast'),
      title: t('radio_public'),
    },
    {
      id: 'soundcloud',
      source: 'socialMedia',
      svgSrc: Soundcloud,
      svgClassName: 'embedly-soundcloud',
      caption: t('music'),
      title: t('soundcloud'),
    },
    {
      id: 'dribble',
      source: 'socialMedia',
      svgSrc: Dribble,
      svgClassName: 'embedly-dribble',
      caption: t('social_media'),
      title: t('dribble'),
    },
  ],
  design: [
    {
      id: 'figma',
      source: 'design',
      svgSrc: Figma,
      svgClassName: 'embedly-figma',
      caption: t('design'),
      title: t('figma'),
    },
    {
      id: 'abstract',
      source: 'design',
      svgSrc: Abstract,
      svgClassName: 'embedly-abstract',
      caption: t('design'),
      title: t('abstract'),
    },
    {
      id: 'avocode',
      source: 'design',
      svgSrc: Avocode,
      svgClassName: 'embedly-avocode',
      caption: t('design'),
      title: t('avocode'),
    },
    {
      id: 'overflow',
      source: 'design',
      svgSrc: Overflow,
      svgClassName: 'embedly-overflow',
      caption: t('design'),
      title: t('overflow'),
    },
    {
      id: 'marvel-app',
      source: 'design',
      svgSrc: Marvelapp,
      svgClassName: 'embedly-marvelapp',
      caption: t('design'),
      title: t('marvel_app'),
    },
    {
      id: 'vectary',
      source: 'design',
      svgSrc: Vectary,
      svgClassName: 'embedly-vectary',
      caption: t('design'),
      title: t('vectary'),
    },
  ],
};
