import { toCamelCase, toSnakeCase } from '../../../../lib/keyFormatConverter';
import { trainualApi } from '../../trainualService';
import { CurriculumAssignmentsPayload, CurriculumWithAssignmentsResponse } from './types';

const curriculumAssignmentsApi = trainualApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurriculumAssignments: builder.query<CurriculumWithAssignmentsResponse, number>({
      query: (id: number) => ({
        url: `ajax/curriculums/${id}/curriculum_assignments`,
        method: 'GET',
      }),
      providesTags: (result, errors, curriculumId) => {
        return [
          { type: 'CurriculumAssignment', id: 'LIST' },
          { type: 'Curriculum', id: curriculumId },
        ];
      },
      transformResponse: (returnValue: CurriculumWithAssignmentsResponse) =>
        toCamelCase<CurriculumWithAssignmentsResponse>(returnValue),
    }),
    updateCurriculumAssignments: builder.mutation<undefined, CurriculumAssignmentsPayload>({
      query: ({ accessControl, id, curriculumAssignments }) => ({
        url: `ajax/curriculums/${id}/curriculum_assignments`,
        method: 'PUT',
        body: toSnakeCase({ accessControl, curriculumAssignments }),
      }),
      invalidatesTags: (_, __, arg) => [
        { type: 'CurriculumAssignment', id: 'LIST' },
        { type: 'Curriculum', id: arg.id },
        { type: 'Curriculum', id: 'LIST' },
        { type: 'User', id: 'LIST' },
        { type: 'Group', id: 'LIST' },
      ],
    }),
  }),
});

export const { useGetCurriculumAssignmentsQuery, useUpdateCurriculumAssignmentsMutation } =
  curriculumAssignmentsApi;
