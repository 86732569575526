import React from 'react';

import P from '../../../../design_system/text/P';
import {
  AccountStatusBannerActionButton,
  AccountStatusBannerContentWrapper,
  AccountStatusBannerEmojiWrapper,
  AccountStatusBannerMessageTitle,
  AccountStatusBannerMessageWrapper,
  AccountStatusBannerWrapper,
} from './styles';
import { AccountStatusBannerProps } from './types';

const AccountStatusBanner = ({
  buttonOnClick,
  buttonText,
  className,
  emoji,
  messageTitle,
  messageBody,
  isLoading = false,
  backgroundColor,
}: AccountStatusBannerProps) => {
  return (
    <AccountStatusBannerWrapper backgroundColor={backgroundColor} className={className}>
      <AccountStatusBannerContentWrapper>
        {emoji && <AccountStatusBannerEmojiWrapper>{emoji}</AccountStatusBannerEmojiWrapper>}
        <AccountStatusBannerMessageWrapper>
          <AccountStatusBannerMessageTitle>{messageTitle}</AccountStatusBannerMessageTitle>
          <P text={messageBody} />
        </AccountStatusBannerMessageWrapper>
      </AccountStatusBannerContentWrapper>
      {buttonText && (
        <AccountStatusBannerActionButton
          disabled={isLoading}
          id='account-status-banner-button'
          loading={isLoading}
          onClick={buttonOnClick}
          text={buttonText}
        />
      )}
    </AccountStatusBannerWrapper>
  );
};

export default AccountStatusBanner;
