import { LoadingSize } from '../../../people/UsersOutline/Table/Column';
import { Column } from '../../../people/UsersOutline/Table/TableTypes';
import { IndividualUsersReportColumn } from './types';

export interface TableColumn extends Omit<Column, 'columnName'> {
  columnName: IndividualUsersReportColumn;
}

export const getColumns = (isSignaturePaywalled: boolean) => {
  const column: TableColumn[] = [
    { columnName: 'title', loadingSize: LoadingSize.large, sortable: true },
    { columnName: 'status', loadingSize: LoadingSize.medium, sortable: false },
    { columnName: 'score', loadingSize: LoadingSize.medium, sortable: true },
    {
      columnName: 'actions',
      loadingSize: LoadingSize.medium,
      contentAlignment: 'end',
      sortable: false,
    },
  ];

  if (!isSignaturePaywalled) {
    column.splice(3, 0, {
      columnName: 'e_signature',
      loadingSize: LoadingSize.medium,
      sortable: false,
    });
  }
  return column;
};
