export default function titleize(text: string): string {
  const value = text.valueOf().replace('_', ' ');
  let words = value.split(' ');

  words = words.map((word) => {
    word = word.charAt(0).toUpperCase() + word.substring(1);
    return word;
  });

  return words.join(' ');
}
