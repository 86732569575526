import styled from 'styled-components';

import IconButton from '../../../design_system/buttons/IconButton';
import { fontSm5 } from '../../../styled/TypeSystem';

export const CollapsibleSection = styled.section`
  padding-top: ${({ theme: { constants } }) => constants.spacerSm3};
  padding-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  border-top: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  &:first-child {
    border-top: none;
  }
`;

export const CollapsibleSectionHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CollapsibleSectionTitle = styled.h5`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin-bottom: 0;
  ${fontSm5};
`;

export const StyledIconButton = styled(IconButton)`
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const CollapsibleSectionBody = styled.div`
  padding-top: ${({ theme: { constants } }) => constants.spacerSm3};
`;
