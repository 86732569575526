import React from 'react';

import { ElementsColumn, TableColumnProps } from '../types';
import Actions from './Actions';
import Score from './Score';
import SignatureStatus from './SignatureStatus';
import Status from './Status';
import Title from './Title';

type TableColumnsProps = { [key in ElementsColumn]: React.FC<TableColumnProps> };

const COLUMNS_KEYS: TableColumnsProps = {
  title: Title,
  status: Status,
  e_signature_status: SignatureStatus,
  completion_score: Score,
  actions: Actions,
};

const TableColumn = ({ columnName, record }: TableColumnProps) => {
  const Column = COLUMNS_KEYS[columnName as keyof TableColumnsProps];

  return <Column columnName={columnName} record={record} />;
};

export default TableColumn;
