import React from 'react';

import useWindowResize from '../../../../../hooks/useWindowResize';
import { HeapModalCancelActionId } from '../../../../../types/TaskTracking';
import { mediaBreakpointSm } from '../../../../styled/Breakpoint';
import DefaultButton from '../../../buttons/DefaultButton';
import { ControlState, SecondaryButton } from './TaskModalTypes';

type CancelControlProps = SecondaryButton &
  ControlState & {
    heapModalActionId: HeapModalCancelActionId;
  };

const CancelControlButton = ({
  onCloseRequest,
  processing = false,
  secondaryButtonText,
  heapModalActionId,
}: CancelControlProps) => {
  const { width } = useWindowResize();
  return (
    <DefaultButton
      buttonType='secondary'
      className={heapModalActionId}
      fullWidth={width < mediaBreakpointSm}
      id={heapModalActionId}
      onClick={() => !processing && onCloseRequest()}
      size='md'
      text={secondaryButtonText}
    />
  );
};

export default CancelControlButton;
