type VerticalMargin = { marginTop: string; marginBottom: string };
type HorizontalMargin = { marginLeft: string; marginRight: string };
type VerticalPadding = { paddingTop: string; paddingBottom: string };
type HorizontalPadding = { paddingLeft: string; paddingRight: string };

class Spacing {
  space: string;

  constructor(value: number) {
    this.space = `${value}rem`;
  }

  verticalPadding() {
    return { paddingTop: this.space, paddingBottom: this.space };
  }

  verticalMargin() {
    return { marginTop: this.space, marginBottom: this.space };
  }

  horizontalMargin() {
    return { marginLeft: this.space, marginRight: this.space };
  }

  horizontalPadding() {
    return { paddingLeft: this.space, paddingRight: this.space };
  }
}

interface CssSpacing {
  vertical: {
    margin: (value: number) => VerticalMargin;
    padding: (value: number) => VerticalPadding;
  };
  horizontal: {
    margin: (value: number) => HorizontalMargin;
    padding: (value: number) => HorizontalPadding;
  };
}

export default {
  vertical: {
    margin: (value: number) => new Spacing(value).verticalMargin(),
    padding: (value: number) => new Spacing(value).verticalPadding(),
  },
  horizontal: {
    margin: (value: number) => new Spacing(value).horizontalMargin(),
    padding: (value: number) => new Spacing(value).horizontalPadding(),
  },
} as CssSpacing;

type Options = {
  v?: string;
  h?: string;
  t?: string;
  r?: string;
  b?: string;
  l?: string;
};

type SpaceArgs = ['margin' | 'padding', Options];

interface ResultProps {
  [key: string]: string;
}

const Space = (...args: SpaceArgs) => {
  const [spacingType, { v, h, t, r, b, l }] = args;
  const result: ResultProps = {};

  const top = v || t;
  const right = h || r;
  const bottom = v || b;
  const left = h || l;

  if (top) result[`${spacingType}Top`] = top;
  if (right) result[`${spacingType}Right`] = right;
  if (bottom) result[`${spacingType}Bottom`] = bottom;
  if (left) result[`${spacingType}Left`] = left;
  return result;
};

export const SetPadding = (options: Options) => Space('padding', options);
