import { FormikErrors, FormikTouched } from 'formik';
import React from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import { useIsAdmin, useIsCurriculumOwner } from '../../../../../hooks/users/useIsPermission';
import initTranslations from '../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import { Option } from '../../../../design_system/core/SelectOption/types';
import DeprecatedTextareaWithCharacterCount from '../../../../design_system/ToBeDeprecated/DeprecatedTextareaWithCharacterCount';
import SingleSelectField from '../../../../design_system/Triage/fields/SingleSelectField';
import AddTeammateMenuList from '../../../people/AddTeammateMenuList';
import { FormGroup } from '../../../shared/FormGroup';
import OptionalSettings from './OptionalSettings';
import { FormValues } from './SubjectModalForm';

const t = initTranslations('curriculums_modal');

const StyledSelect = styled(SingleSelectField)`
  margin: 0 !important;
  padding: 0;
`;

type EditSettingsProps = {
  userOptions: Option[];
  optionalSettingsOpen: boolean;
  signaturesRequired: boolean | undefined;
  ownerId: number | null | undefined;
  handleChange(e: React.ChangeEvent): void;
  touched: FormikTouched<FormValues>;
  values: FormValues;
  setFieldValue: (
    field: string,
    value: string,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<FormValues>> | Promise<void>;
  errors: FormikErrors<FormValues>;
};

const EditSettings = ({
  userOptions,
  optionalSettingsOpen,
  signaturesRequired,
  handleChange,
  touched,
  ownerId,
  values,
  setFieldValue,
  errors,
}: EditSettingsProps) => {
  const ability = useCurrentUserAbilities();
  const {
    curriculum: { singular: subjectTermSingular, plural: subjectTermPlural },
  } = useAccountTerminology();
  const { paywalledFeatures } = useCurrentAccount();
  const { description: curriculumDescription, ownerAssignment } = values;
  const canInviteUser = ability.can('create', 'User');
  const isOwner = useIsCurriculumOwner(ownerId);
  const advancedSettingsLocked = paywalledFeatures.includes('curriculum_advanced_settings');
  const editingIsDisabled = document.getElementById('curriculum-modal')?.dataset.disabled == 'true';
  const isAdminPlus = useIsAdmin();
  const canAccessManagerialSettings = isOwner || isAdminPlus;

  return (
    <div id='subject-edit-settings'>
      {canAccessManagerialSettings && !advancedSettingsLocked && (
        <FormGroup>
          <StyledSelect
            MenuList={canInviteUser ? AddTeammateMenuList : undefined}
            className='subject-owner-select'
            defaultValue={ownerAssignment || null}
            fieldLabelText={t('owner_label')}
            isClearable
            labelTooltipId='subject-owner-tooltip'
            labelTooltipText={t('owner_tooltip', {
              subject: subjectTermSingular.toLowerCase(),
            })}
            name='ownerAssignment'
            onNonDefaultSelected={(ownerAssignment: string) =>
              setFieldValue('ownerAssignment', ownerAssignment)
            }
            options={userOptions}
            placeholder={t('display_owner_placeholder')}
            required={false}
            value={ownerAssignment}
          />
        </FormGroup>
      )}
      <FormGroup>
        <DeprecatedTextareaWithCharacterCount
          attributeName={t('description')}
          autofocus={false}
          disabled={editingIsDisabled}
          id='curriculumDescription'
          label={t('description_label')}
          maxCharacters={500}
          name='description'
          noTopMargin
          onChange={handleChange}
          placeholder={t('description_placeholder', {
            subject: subjectTermSingular.toLowerCase(),
          })}
          required={false}
          resourceName={subjectTermSingular}
          rows={3}
          tooltipId='description-tooltip'
          tooltipText={
            editingIsDisabled
              ? t('disabled_by_e_signature')
              : t('description_tooltip', { subject: subjectTermSingular.toLowerCase() })
          }
          value={curriculumDescription}
        />
      </FormGroup>
      {canAccessManagerialSettings && (
        <OptionalSettings
          errors={errors}
          handleChange={handleChange}
          isAdminPlus={isAdminPlus}
          isOpen={optionalSettingsOpen}
          isOwner={isOwner}
          ownerAssignment={!!ownerAssignment}
          signatureLoss={signaturesRequired || false}
          subjectTermPlural={subjectTermPlural}
          subjectTermSingular={subjectTermSingular}
          touched={touched}
          values={values}
        />
      )}
    </div>
  );
};

export default EditSettings;
