import { PublishStatus } from '../../redux/services/resourceApis/reports/types';

export function getBadgeTypeByPublishStatus(status: PublishStatus) {
  switch (status) {
    case 'published':
      return 'info';
    case 'unpublished':
      return 'error';
    default:
      return 'general';
  }
}
