import React, { useContext } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { AccountTerminologyContext } from '../../../../AccountTerminologyProvider';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { routes } from '../../../publicApplication/applicationRouter';
import ActivityLinkTemplate from '../ActivityItemTemplates/ActivityLinkTemplate';
import { TextFragment } from '../styles';
import { ActivityAssociationParams } from '../types';

const t = initTranslations('reports.activity_log');

export interface CurriculumCommonTemplateProps {
  activityKey: string;
  trackable: ActivityAssociationParams;
  parameters: { sector_title: string };
  recipient?: object;
}

const CommonTemplate = ({ activityKey, trackable, parameters }: CurriculumCommonTemplateProps) => {
  const { slug } = useCurrentAccount();
  const { curriculum } = useContext(AccountTerminologyContext);

  return (
    <>
      <TextFragment>
        {t(activityKey, { curriculum: curriculum.singular.toLowerCase() })}
      </TextFragment>
      <SourceBadge
        readOnly={!trackable.is_active}
        sourceName='curriculum'
        sourceRoute={routes.curriculumEdit({ slug, id: trackable.id })}
        sourceText={trackable.name}
      />
      {activityKey == 'curriculum.updated_sector' && (
        <>
          <TextFragment>{t('curriculum.to')}</TextFragment>
          <ActivityLinkTemplate
            isActive={trackable.is_active}
            linkText={parameters.sector_title}
            linkUrl={routes.content({ slug }).href}
          />
        </>
      )}
    </>
  );
};

export default CommonTemplate;
