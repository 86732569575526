import initTranslations from '../../../../lib/initTranslations';
import {
  ContentAccess,
  ESignature,
  GeneralAccess,
  Kind,
  Status,
} from '../../../../redux/domains/curriculums/curriculumsSlice';
import { SimpleUser } from '../../../../types/User';
import { DropdownMenuOption } from './FilterSection/SetDefaultDropdownMenu/SetDefaultDropdownMenu';

export type ViewByGroup = {
  id: number;
  title: string;
  totalUserCount: number;
  totalCurriculumsCount: number;
  users: SimpleUser[];
};

export enum GroupKindValue {
  Role = 'role',
  Team = 'team',
  Department = 'department',
  Location = 'location',
  Other = 'other',
  AllContent = 'all_content',
  AllGroups = 'all_groups',
}

export enum SortByValue {
  TrainingOrder = 'training order',
  Alphabetical = 'alphabetical',
  ReverseAlphabetical = 'reverse alphabetical',
  LastUpdate = 'last update',
  Newest = 'newest',
  Last = 'last',
}

export type GroupsState = ViewByGroup & { filterParam: string };

type TableState = {
  searchValue: string;
  loading: boolean;
  viewBySelection: DropdownMenuOption;
  sortBySelection: DropdownMenuOption;
  groupIds: string[] | null;
  roleIds: string[] | null;
  teamIds: string[] | null;
  userId: string | null;
  access: ContentAccess;
  generalAccess: GeneralAccess;
  status: Status;
  kind: Kind;
  groups?: GroupsState[];
  defaultsHaveBeenSet: boolean;
  eSignature: ESignature;
};

export type TableAction =
  | { type: 'setSearchValue'; searchValue: string }
  | { type: 'setLoading'; loading: boolean }
  | { type: 'setViewBySelection'; viewBy: DropdownMenuOption }
  | { type: 'setSortBy'; sortBy: DropdownMenuOption }
  | {
      type: 'applyFilters';
      groupIds: string[] | null;
      roleIds: string[] | null;
      teamIds: string[] | null;
      userId: string | null;
      access: ContentAccess;
      generalAccess: GeneralAccess;
      status: Status;
      kind: Kind;
      eSignature: ESignature;
    }
  | { type: 'setGroups'; groups?: GroupsState[] }
  | { type: 'resetFilters' }
  | { type: 'setDefaults' };

export const libraryReducer = (state: TableState, action: TableAction): TableState => {
  switch (action.type) {
    case 'setLoading': {
      return { ...state, loading: action.loading };
    }
    case 'setSearchValue': {
      return {
        ...state,
        searchValue: action.searchValue,
      };
    }
    case 'setViewBySelection': {
      return {
        ...state,
        viewBySelection: action.viewBy,
      };
    }
    case 'setSortBy': {
      return {
        ...state,
        sortBySelection: action.sortBy,
      };
    }
    case 'applyFilters': {
      return {
        ...state,
        groupIds: action.groupIds,
        roleIds: action.roleIds,
        teamIds: action.teamIds,
        userId: action.userId,
        access: action.access,
        generalAccess: action.generalAccess,
        status: action.status,
        kind: action.kind,
        eSignature: action.eSignature,
      };
    }
    case 'setGroups': {
      return {
        ...state,
        groups: action.groups,
      };
    }
    case 'resetFilters': {
      return initialTableState;
    }
    case 'setDefaults': {
      return {
        ...state,
        defaultsHaveBeenSet: true,
      };
    }
  }
};

const t = initTranslations('curriculums.content');

export const GROUP_VIEW_BY_OPTIONS: DropdownMenuOption[] = [
  { id: 0, label: t('view_by_options.all_content'), value: GroupKindValue.AllContent },
  { id: 1, label: t('view_by_options.teams'), value: GroupKindValue.Team },
  { id: 2, label: t('view_by_options.locations'), value: GroupKindValue.Location },
  { id: 3, label: t('view_by_options.departments'), value: GroupKindValue.Department },
  { id: 5, label: t('view_by_options.roles'), value: GroupKindValue.Role },
  { id: 6, label: t('view_by_options.others'), value: GroupKindValue.Other },
  { id: 7, label: t('view_by_options.all_groups'), value: GroupKindValue.AllGroups },
];

export const SORT_OPTIONS: DropdownMenuOption[] = [
  { id: 0, label: t('sort_by_options.training_order'), value: SortByValue.TrainingOrder },
  { id: 1, label: t('sort_by_options.alphabetical'), value: SortByValue.Alphabetical },
  {
    id: 2,
    label: t('sort_by_options.reverse_alphabetical'),
    value: SortByValue.ReverseAlphabetical,
  },
  { id: 3, label: t('sort_by_options.last_updated'), value: SortByValue.LastUpdate },
  { id: 4, label: t('sort_by_options.newest'), value: SortByValue.Newest },
  { id: 5, label: t('sort_by_options.oldest'), value: SortByValue.Last },
];

export const initialTableState = {
  loading: false,
  searchValue: '',
  groupIds: null,
  roleIds: null,
  teamIds: null,
  userId: null,
  access: null,
  generalAccess: null,
  status: null,
  kind: null,
  userOptions: [],
  viewBySelection: GROUP_VIEW_BY_OPTIONS[0],
  sortBySelection: SORT_OPTIONS[0],
  defaultsHaveBeenSet: false,
  eSignature: null,
};
