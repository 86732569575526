import styled from 'styled-components';

import Badge from '../../../../design_system/display/badge';
import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';

export const StyledBadge = styled(Badge)`
  max-width: 3.8rem;
  flex: none;
  cursor: pointer;
  ${TruncatedText};
`;
