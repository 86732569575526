import styled, { css } from 'styled-components';

import P from '../../../design_system/text/P';
import { fontLg1, fontSm5 } from '../../../styled/TypeSystem';

export const SpaceBetweenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PlanPriceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleUnderline = css`
  content: '';
  position: absolute;
  left: 0;
  bottom: -0.4rem;
  width: 100%;
  height: 0.25rem;
  background-color: ${({ theme: { vars } }) => vars.trainualBrandBlueStrong};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
`;

export const PlanPrice = styled(P)`
  white-space: nowrap;
  text-align: center;
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  color: ${({ theme: { vars } }) => vars.foundationSurface2};
  ${fontLg1};
`;

export const PlanInterval = styled.span`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  color: ${({ theme: { vars } }) => vars.foundationSurface2};
  ${fontSm5};
`;

export const BillingIntervalText = styled(P)`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  font-style: italic;
`;

export const PlanNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;
