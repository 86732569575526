import styled from 'styled-components';

import { fontSm5 } from '../../../styled/TypeSystem';
import RouterLink from '../../publicApplication/RouterLink';

export const TextFragment = styled.span`
  margin: 0 ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const NotificationLink = styled(RouterLink)`
  padding: 0;
  border: none;
  background-color: transparent;
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};

  &:hover,
  &:focus {
    outline: none;
    color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  }
  ${fontSm5};
`;
