import styled, { css } from 'styled-components';

import { mediaBreakpointPxXl } from '../../../styled/Breakpoint';
import RouterLink from '../../publicApplication/RouterLink';

const styledLogo = css<{
  hidden: boolean;
  logoBackgroundColor: string | null;
}>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  width: 100%;
  max-width: 13.5rem;
  height: 3.75rem;
  align-items: center;
  margin-right: 0;
  position: relative;
  z-index: 2;

  ${({ logoBackgroundColor }) => {
    if (logoBackgroundColor) return { backgroundColor: logoBackgroundColor };
  }};

  @media (min-width: ${mediaBreakpointPxXl}) {
    transition: all 0.5s ease;
    padding: ${({ theme }) => theme.constants.spacerSm2};
  }
`;

export const LogoWithRoute = styled(RouterLink)`
  ${styledLogo};
`;

export const LogoWithoutRoute = styled.a`
  ${styledLogo};
`;

export const StyledImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
`;
