import styled from 'styled-components';

import { fontMd1, fontSm5 } from '../../../../styled/TypeSystem';

export const ButtonsDescription = styled.div`
  padding-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontMd1};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const StyledButton = styled.button<{ selected: boolean }>`
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  border: ${({ theme: { constants, vars }, selected }) =>
    `${constants.borderWidthSm} solid ${
      selected ? vars.accentPrimaryDefault : vars.borderSurface2
    }`};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
  background-color: ${({ theme: { vars }, selected }) =>
    selected ? vars.accentSubdued1 : vars.foundationSurface1};
  color: ${({ theme: { vars }, selected }) =>
    selected ? vars.accentPrimaryDefault : vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  cursor: pointer;
  :hover,
  :active,
  :focus {
    background-color: ${({ theme: { vars } }) => vars.accentSubdued1};
  }
  ${fontSm5};
`;
