import React, { useContext } from 'react';

import { PaywallContext } from '../../../../../contexts/PaywallContext';
import { HeaderRow } from '../../AllUsersReportPage/AllUsersReportTable/styles';
import { SortProps } from '../../TableTypes';
import { TableColumn, getColumns } from './IndividualUserReportColumn';
import IndividualUserReportTableHeaderCell from './IndividualUserReportTableHeaderCell';
import { TableHeaderProps } from './types';

const IndividualUserReportTableHeader = ({ tableHeaderState, dispatch }: TableHeaderProps) => {
  const paywallCtx = useContext(PaywallContext);
  const isSignaturePaywalled = paywallCtx.includes('e_signature');

  return (
    <thead>
      <HeaderRow>
        {getColumns(isSignaturePaywalled).map((column: TableColumn) => {
          const activeColumn = tableHeaderState.sortColumn === column.columnName;
          let sortProps: SortProps = { isSortable: column.sortable };

          if (column.sortable) {
            const sortIcon = activeColumn ? tableHeaderState.sortIcon : 'sort';
            sortProps = { isSortable: true, sortIcon };
          }

          return (
            <IndividualUserReportTableHeaderCell
              activeHeader={activeColumn}
              column={column}
              key={`header-${column.columnName}`}
              setActiveHeader={() => {
                if (column.sortable) {
                  dispatch({ type: 'sortColumnChange', sortColumn: column.columnName });
                }
              }}
              {...sortProps}
            />
          );
        })}
      </HeaderRow>
    </thead>
  );
};

export default IndividualUserReportTableHeader;
