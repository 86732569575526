import styled, { css } from 'styled-components';

import { fontSm5 } from '../../../styled/TypeSystem';

export const ButtonGroup = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${constants.spacerMd2};
    margin-top: ${constants.spacerMd2};
  `
);

export const GeneratedContentWrapper = styled.div(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${vars.foundationBase1};
    border-radius: ${constants.borderRadiusLg};
    overflow: hidden;
    text-align: center;
    padding: ${constants.spacerMd2} ${constants.spacerLg1} ${constants.spacerSm3};

    #generated-content {
      text-align: left;
    }
  `
);

export const StyledImage = styled.img`
  width: 7rem;
`;

export const StyledLoadingText = styled.p`
  color: ${(props) => props.theme.vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontSm5};
`;

export const ModalContentWrapper = styled.div<{ isLoading: boolean }>`
  ${({ isLoading }) => isLoading && { 'text-align': 'center', padding: '7rem' }};
`;
