import React from 'react';

import useCurrentUser from '../../../../../hooks/useCurrentUser';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../redux/errors/helpers';
import { useExportPdfMutation } from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import ConfirmationModal from '../../../../design_system/overlays/modals/ConfirmationModal';

export interface Props {
  id: number;
  closePdfModal: () => void;
  title: string;
}

const t = initTranslations('export_pdf_modal');

const ExportPdfModal = ({ id, closePdfModal, title }: Props) => {
  const [exportPdf, result] = useExportPdfMutation();
  const { isLoading, error } = result;
  const { email } = useCurrentUser();

  useDisplayFlashOnResponse({
    result,
    successFunction: closePdfModal,
    errorMessage: messageFromError(error)?.join(', '),
  });

  return (
    <ConfirmationModal
      actionFunction={() => {
        exportPdf(id);
      }}
      actionText={t('action')}
      desktopSize='lg'
      heapModalName='export-pdf-modal'
      message={t('message', { curriculum: title, email })}
      onCloseRequest={() => {
        closePdfModal();
      }}
      processing={isLoading}
      title={t('title')}
    />
  );
};

export default ExportPdfModal;
