import React from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../../../hooks/useCurrentUserAbilities';
import initTranslations from '../../../../../../lib/initTranslations';
import { safeDecodeURIComponent } from '../../../../../../lib/safeDecodeURIComponent';
import { getQuickSearchDropdownSectionData } from '../../../../../../lib/search/getQuickSearchDropdownSectionData';
import {
  CompanyQuickSearchResult,
  TemplatesQuickSearchResult,
} from '../../../../../../redux/services/resourceApis/searches/types';
import { QuickSearchDropdownItemProps } from '../../../../../../types/QuickSearch';
import { CollapsibleContent } from '../../../../../design_system/Triage/layout/CollapsibleSurface';
import { routes } from '../../../../publicApplication/applicationRouter';
import { QuickSearchDropdownDivider } from '../QuickSearchDropdown/styles';
import QuickSearchResultCard from '../QuickSearchResultCard/QuickSearchResultCard';
import { QuickSearchResultCardProps } from '../QuickSearchResultCard/types';
import {
  QuickSearchArrowIcon,
  QuickSearchDropdownIcon,
  QuickSearchDropdownItemWrapper,
  QuickSearchDropdownSection,
  QuickSearchLinkText,
  QuickSearchResultCardWrapper,
} from './styles';

const t = initTranslations('top_nav.quick_search.dropdown');

const QuickSearchDropdownItem = ({
  kind,
  searchValue,
  quickSearchResultData,
  quickSearchResultLinksRef,
}: QuickSearchDropdownItemProps) => {
  const { slug, name } = useCurrentAccount();
  const { translationKey } = getQuickSearchDropdownSectionData()[kind];
  const ability = useCurrentUserAbilities();
  const canSearchTemplates = ability.can('read', 'SearchTemplates');
  const isCompanySection = kind === 'company';
  const isTemplatesSection = kind === 'templates';
  const listOfSearchResult = quickSearchResultData && quickSearchResultData[`${kind}Result`];
  const isCollapsed = !listOfSearchResult?.length;
  const hideTemplatesSection = !canSearchTemplates && isTemplatesSection;

  if (hideTemplatesSection) return <></>;

  const getResultCardConditionalProps = (
    item: CompanyQuickSearchResult | TemplatesQuickSearchResult
  ) => {
    if (isCompanySection) {
      const companyItem = item as CompanyQuickSearchResult;

      return {
        avatar: companyItem.avatar,
        groupMembersCount: companyItem.groupMembersCount,
        parentElementName: companyItem.parentElementName,
        sector: companyItem.sector,
        surveyId: companyItem.surveyId,
      };
    } else {
      const templateItem = item as TemplatesQuickSearchResult;

      return {
        templateAuthor: templateItem.author,
      };
    }
  };

  return (
    <>
      <QuickSearchDropdownItemWrapper id={`quick-search-dropdown-${kind}-section`}>
        <QuickSearchDropdownSection
          linkRef={(el) => el && quickSearchResultLinksRef.current.push(el)}
          role='menuitem'
          to={routes.search({ slug, search: safeDecodeURIComponent(searchValue), kind })}
        >
          <QuickSearchDropdownIcon className='search-icon' name='search' size='sm' />
          <QuickSearchLinkText>
            {t(translationKey, isCompanySection ? { companyName: name } : undefined)}
          </QuickSearchLinkText>
          <QuickSearchArrowIcon name='arrow-right' weight='regular' />
        </QuickSearchDropdownSection>
        <CollapsibleContent isCollapsed={isCollapsed}>
          <QuickSearchResultCardWrapper>
            {listOfSearchResult?.map((item) => {
              const searchResultCardProps: QuickSearchResultCardProps = {
                cardType: item.type,
                emoji: item.emoji,
                itemId: item.id,
                titleHighlighted: item.titleHighlighted,
                searchValue,
                quickSearchResultLinksRef,
                ...getResultCardConditionalProps(item),
              };

              return (
                <QuickSearchResultCard key={`${item.type}-${item.id}`} {...searchResultCardProps} />
              );
            })}
          </QuickSearchResultCardWrapper>
        </CollapsibleContent>
      </QuickSearchDropdownItemWrapper>
      <QuickSearchDropdownDivider withTopMargin />
    </>
  );
};

export default QuickSearchDropdownItem;
