import React from 'react';

import { Sector } from '../../../../../types/Sector';
import { Activity, ActivityAssociationParams } from '../types';
import Assign, { CurriculumAssignProps } from './Assign';
import CommonTemplate, { CurriculumCommonTemplateProps } from './CommonTemplate';
import Unassign, { CurriculumUnassignProps } from './Unassign';

interface CurriculumsActivitiesProps {
  'curriculum.create': React.FC<CurriculumCommonTemplateProps>;
  'curriculum.destroy': React.FC<CurriculumCommonTemplateProps>;
  'curriculum.restore': React.FC<CurriculumCommonTemplateProps>;
  'curriculum.archive': React.FC<CurriculumCommonTemplateProps>;
  'curriculum.unarchive': React.FC<CurriculumCommonTemplateProps>;
  'curriculum.publish': React.FC<CurriculumCommonTemplateProps>;
  'curriculum.unpublish': React.FC<CurriculumCommonTemplateProps>;
  'curriculum.updated_sector': React.FC<CurriculumCommonTemplateProps>;
  'curriculum.assign': React.FC<CurriculumAssignProps>;
  'curriculum.assign_owner': React.FC<CurriculumAssignProps>;
  'curriculum.assign-owner': React.FC<CurriculumAssignProps>;
  'curriculum.unassign_owner': React.FC<CurriculumUnassignProps>;
}

const ACTIVITY_KEYS: CurriculumsActivitiesProps = {
  'curriculum.create': CommonTemplate,
  'curriculum.destroy': CommonTemplate,
  'curriculum.restore': CommonTemplate,
  'curriculum.archive': CommonTemplate,
  'curriculum.unarchive': CommonTemplate,
  'curriculum.publish': CommonTemplate,
  'curriculum.unpublish': CommonTemplate,
  'curriculum.assign': Assign,
  'curriculum.assign_owner': Assign,
  'curriculum.assign-owner': Assign,
  'curriculum.unassign_owner': Unassign,
  'curriculum.updated_sector': CommonTemplate,
};

interface CurriculumsActivity extends Omit<Activity, 'trackable' | 'parameters'> {
  trackable: ActivityAssociationParams;
  parameters: { new_sector: Sector; sector_title: string };
}

interface CurriculumsCardProps {
  activity: CurriculumsActivity;
}

const CurriculumActivitiesDetails = ({ activity }: CurriculumsCardProps) => {
  const ActivityMessage = ACTIVITY_KEYS[activity.key as keyof CurriculumsActivitiesProps];

  return (
    <ActivityMessage
      activityKey={activity.key}
      parameters={activity.parameters}
      recipient={activity.recipient}
      trackable={activity.trackable}
    />
  );
};

export default CurriculumActivitiesDetails;
