import { FormikErrors, useFormik } from 'formik';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import { useDuplicateSubjectMutation } from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import TextAreaField from '../../../../design_system/Triage/fields/TextAreaField';
import InputField from '../../../../design_system/Triage/InputField';
import { useFlashNotification } from '../../../../FlashNotificationContext';

const InputWrapper = styled.div`
  width: 100%;
`;

const Spacer = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const t = initTranslations('duplicate_subject_modal');

export interface Props extends FormValues {
  closeRequest: () => void;
}

interface FormValues {
  id: number;
  description: string | undefined;
  title: string;
}

const DuplicateModal = ({ closeRequest, id, description, title }: Props): ReactElement => {
  const { flash } = useFlashNotification();
  const [duplicateSubject, result] = useDuplicateSubjectMutation();
  const { isError, isSuccess } = result;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = useCallback((values: FormValues) => {
    const { title } = values;
    const errors: FormikErrors<FormValues> = {};
    if (!title || title.trim() === '') {
      errors.title = t('blank_title_error');
    }
    return errors;
  }, []);

  const submitForm = useCallback(
    (formValues) => {
      setIsSubmitting(true);
      duplicateSubject(formValues);
    },
    [duplicateSubject]
  );

  const formik = useFormik({
    initialValues: {
      id,
      title: `${title} Duplicate`,
      description,
    },
    validate: (values: FormValues) => validateForm(values),
    onSubmit: (values: FormValues) => submitForm(values),
  });

  useEffect(() => {
    if (isSuccess) {
      flash('info', t('success_flash'));
      closeRequest();
    }
  }, [isSuccess, flash, closeRequest, title]);

  useEffect(() => {
    if (isError) {
      flash('error', t('error_flash', { subject_title: title }));
      closeRequest();
    }
  }, [isError, flash, closeRequest, title]);

  const taskModalArgs: TaskModalProps = {
    title: t('duplicate_subject'),
    onCloseRequest: closeRequest,
    heapModalName: 'duplicate-subject-modal',
    primaryButtonText: t('save'),
    primaryButtonTask: () => formik.handleSubmit(),
    processing: isSubmitting,
    isDisabled: false,
    secondaryButtonText: t('cancel'),
  };

  return (
    <TaskModal {...taskModalArgs}>
      <InputWrapper>
        <InputField
          errorText={formik.touched.title && formik.errors.title}
          id='subject-title'
          label={t('title_label')}
          name='title'
          onChange={formik.handleChange}
          value={formik.values.title}
        />
      </InputWrapper>
      <Spacer />
      <TextAreaField
        errorText={formik.touched.description && formik.errors.description}
        label={t('description_label')}
        name='description'
        onChange={formik.handleChange}
        value={formik.values.description || undefined}
      />
    </TaskModal>
  );
};

export default DuplicateModal;
