import React, { useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';

import {
  billingGroup,
  session as routeSession,
  routes,
  useRouterHelper,
} from '../../components/application/publicApplication/applicationRouter';
import routeTo from '../../components/application/publicApplication/routeTo';
import { UpdateQuery, updateQueryParams } from '../../lib/getQueryParams';
import useCurrentAccount from '../useCurrentAccount';
import { useCheckNoTrialFlow } from './useCheckNoTrialFlow';

type CloseFullScreenOverlayProps = [
  (Event | React.MouseEvent<HTMLButtonElement>)?,
  boolean?,
  UpdateQuery[]?
];
export const FULL_SCREEN_OVERLAY_PREVIOUS_PATH_COOKIE_NAME = 'full_screen_overlay_prev_path';

export const useCloseFullScreenOverlay = () => {
  const isNoTrialFlow = useCheckNoTrialFlow();
  const { slug } = useCurrentAccount();
  const [cookies, setCookie, removeCookie] = useCookies([
    FULL_SCREEN_OVERLAY_PREVIOUS_PATH_COOKIE_NAME,
  ]);
  const routeState = useRouterHelper();
  const previousPath = cookies[FULL_SCREEN_OVERLAY_PREVIOUS_PATH_COOKIE_NAME];

  const removeCookieWithPath = useCallback(() => {
    removeCookie(FULL_SCREEN_OVERLAY_PREVIOUS_PATH_COOKIE_NAME, {
      path: `/${slug}`,
    });
  }, [removeCookie, slug]);

  const pushAndRemoveCookie = useCallback(
    (path: string, queryParams?: UpdateQuery[]) => {
      routeSession.push(path);
      queryParams && updateQueryParams(queryParams, true);
      removeCookieWithPath();
    },
    [removeCookieWithPath]
  );

  // We may want to replace this logic with useRouterHelper and previousRoute after fully migrating to SPA
  const closeFullScreenOverlay = useCallback(
    (...args: CloseFullScreenOverlayProps) => {
      // Fixed issue with back button in case of no trial backdrop flow (GS3-1299)
      // We have to use the default browser behavior in this case, without logic with cookies
      if (isNoTrialFlow) return;

      const [, returnToOriginalPath, queryParams] = args;

      if (!previousPath) {
        const isBillingGroupRoute = !!routeState?.route && billingGroup.has(routeState.route);
        const redirectPath = isBillingGroupRoute ? routes.billing({ slug }) : routes.home({ slug });

        routeTo(redirectPath);
        return;
      }

      // We can trigger an overlay from another overlay, so we need to save an array of paths
      // (example with compare plans and configure plan overlays)
      const isPreviousPathArray = Array.isArray(previousPath);
      const pathsArray = isPreviousPathArray ? [...previousPath] : [previousPath];
      const originalPath = pathsArray[0];
      const pathsLength = pathsArray.length;
      const lastPath = pathsArray[pathsLength - 1];

      if (returnToOriginalPath || !isPreviousPathArray || pathsLength === 1) {
        pushAndRemoveCookie(originalPath, queryParams);
        return;
      }

      routeSession.push(lastPath);
      setCookie(FULL_SCREEN_OVERLAY_PREVIOUS_PATH_COOKIE_NAME, pathsArray.slice(0, -1), {
        path: `/${slug}`,
      });
    },
    [isNoTrialFlow, previousPath, pushAndRemoveCookie, routeState, setCookie, slug]
  );

  useEffect(() => {
    window.addEventListener('popstate', closeFullScreenOverlay);
    //remove cookie when user leaves overlay by changing the URL
    window.addEventListener('beforeunload', removeCookieWithPath);

    return () => {
      window.removeEventListener('popstate', closeFullScreenOverlay);
      window.removeEventListener('beforeunload', removeCookieWithPath);
    };
  }, [closeFullScreenOverlay, removeCookieWithPath]);

  return closeFullScreenOverlay;
};
