import React from 'react';

import StatusChanged, { StatusChangedProps } from '../StatusChanged';
import { CourseCardProps } from '../types';
import CommonTemplate, { CourseCommonTemplateProps } from './CommonTemplate';

interface CourseActivitiesProps {
  'course.create': React.FC<CourseCommonTemplateProps>;
  'course.complete': React.FC<CourseCommonTemplateProps>;
  'course.restore': React.FC<CourseCommonTemplateProps>;
  'course.destroy': React.FC<CourseCommonTemplateProps>;
  'course.update': React.FC<CourseCommonTemplateProps>;
  'course.status_changed': React.FC<StatusChangedProps>;
}

const ACTIVITY_KEYS: CourseActivitiesProps = {
  'course.create': CommonTemplate,
  'course.complete': CommonTemplate,
  'course.restore': CommonTemplate,
  'course.destroy': CommonTemplate,
  'course.update': CommonTemplate,
  'course.status_changed': StatusChanged,
};

const CourseActivitiesDetails = ({ activity }: CourseCardProps) => {
  const ActivityMessage = ACTIVITY_KEYS[activity.key as keyof CourseActivitiesProps];

  return (
    <ActivityMessage
      activityKey={activity.key}
      parameters={activity.parameters}
      trackable={activity.trackable}
    />
  );
};

export default CourseActivitiesDetails;
