import styled from 'styled-components';

import { mediaBreakpointPxXl } from '../../../styled/Breakpoint';

export const SIDEBAR_EXPANDED_WIDTH = '220px';
export const SIDEBAR_COLLAPSED_WIDTH = '80px';
export const SIDEBAR_Z_INDEX = 100;

export const Sidebar = styled.div<{ isExpanded: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 12;
  display: ${({ isExpanded }) => (isExpanded ? 'flex' : 'none')};
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: ${SIDEBAR_EXPANDED_WIDTH};

  ${({ isExpanded }) =>
    isExpanded && {
      flexDirection: 'column',
    }};

  @media (min-width: ${mediaBreakpointPxXl}) {
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: ${SIDEBAR_Z_INDEX};
    width: ${({ isExpanded }) => (isExpanded ? SIDEBAR_EXPANDED_WIDTH : SIDEBAR_COLLAPSED_WIDTH)};
    transition: all 0.5s ease;
  }
`;

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
`;

export const MenuOptionsWrapper = styled.div<{
  isExpanded: boolean;
}>`
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  flex-grow: 1;
  padding: ${({ theme: { constants }, isExpanded }) => (isExpanded ? constants.spacerMd3 : 0)};
  overflow: hidden scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${mediaBreakpointPxXl}) {
    display: block;
    padding: ${({ theme: { constants } }) =>
      `${constants.spacerSm3} ${constants.spacerMd2} ${constants.spacerMd2}`};
  }
`;

export const ToggleButton = styled.button`
  z-index: 2;
  cursor: pointer;
  display: none;

  @media (min-width: ${mediaBreakpointPxXl}) {
    position: absolute;
    top: 14px;
    right: -16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ theme: { constants } }) => constants.heightSm};
    height: ${({ theme: { constants } }) => constants.heightSm};
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
    border: none;
    background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

    &:hover {
      color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
    }

    &:before {
      content: '';
      width: 19px;
      height: 42px;
      background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
      position: absolute;
      top: -5px;
      left: -3px;
    }

    &:focus {
      outline: none;
    }

    svg {
      position: relative;
      z-index: 2;
    }
  }
`;

export const SubNavItems = styled.div`
  margin-top: -14px;
`;

export const Backdrop = styled.div<{ isExpanded: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100vw;
  height: 100%;
  background-color: rgb(74, 77, 84);
  opacity: ${({ isExpanded }) => (isExpanded ? 0.8 : 0)};
  visibility: ${({ isExpanded }) => (isExpanded ? 'visible' : 'hidden')};
  transition: all 0.5s ease-in-out;
`;

export const NotificationIconWrapper = styled.div<{ isExpandedSidebar: boolean }>`
  pointer-events: none;
  position: absolute;
  right: ${({ isExpandedSidebar }) => (isExpandedSidebar ? '10px' : '6px')};
  top: ${({ isExpandedSidebar }) => (isExpandedSidebar ? '7px' : '4px')};
  transition: 0.2s ease-in-out;
`;

export const LogoWrapper = styled.div`
  @media (min-width: ${mediaBreakpointPxXl}) {
    padding: ${({ theme: { constants } }) => constants.spacerMd2};
  }
`;
