import React from 'react';
import styled, { css } from 'styled-components';
import { Route } from 'type-route';

import { routes as routerRoutes } from '../../../application/publicApplication/applicationRouter';
import RouterLink from '../../../application/publicApplication/RouterLink';
import Tag from '../../display/Tag';

const TabsWrapper = styled.nav`
  overflow: auto hidden;
  white-space: nowrap;
`;

const StyledTabs = styled.ul`
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
`;

const TabItem = styled.li<{ isActive: boolean }>(
  ({ isActive, theme: { constants, vars } }) => css`
    a {
      color: ${isActive ? vars.textDefault : vars.textSubdued};
      font-weight: ${constants.fontSemibold};
      align-items: center;
      display: flex;
      font-size: 1rem;
      line-height: 1.375rem;
      padding: ${constants.spacerSm3} 0;
      text-decoration: none;
      &:hover,
      &:focus {
        color: ${vars.textDefault};
      }
    }

    margin-right: ${constants.spacerMd2};
    &:last-child {
      margin-right: 0;
    }
    border-bottom: ${isActive
      ? `${constants.borderWidthLg} solid ${vars.accentPrimaryDefault}`
      : ''};
    display: inline-block;
  `
);

type LinkProps =
  | {
      url: string;
      to?: never;
    }
  | {
      url?: never;
      to: Route<typeof routerRoutes>;
    };

export type LinkTab = {
  count?: string;
  name: string;
  is_beta: boolean;
  tabId?: string;
} & LinkProps;

export type Props = {
  id?: string;
  isUrlParams?: boolean;
  tabs: LinkTab[];
};

const LinkTabs = (props: Props) => {
  const { id, tabs, isUrlParams = true } = props;

  const decodeUrl = (url: string): string => {
    return decodeURIComponent(
      encodeURI(url).replace(/%20/g, '+').replace(/%2520/g, '+').replace('%25', '%')
    );
  };

  const isActive = (tabUrl: string, index: number) => {
    const url = new URL(window.location.href);
    const urlSearchParams = new URLSearchParams(url.search);
    urlSearchParams.delete('page');
    urlSearchParams.delete('clicksource');

    const currentUrl = window.location.pathname + '?' + urlSearchParams.toString();

    return isUrlParams
      ? (url.search === '' && index === 0) || decodeUrl(currentUrl) === decodeUrl(tabUrl)
      : window.location.pathname === tabUrl;
  };

  if (tabs.length <= 1) return <></>;
  return (
    <TabsWrapper id={id}>
      <StyledTabs>
        {tabs.map(({ count, name, url, to, is_beta, tabId = '' }, index) => {
          const active = isActive(to?.href || url || '', index);
          const tabContent = (
            <>
              {name}
              {count}
              {is_beta && <Tag />}
            </>
          );

          return (
            <TabItem id={tabId} isActive={active} key={`${name}-${index}`}>
              {to ? <RouterLink to={to}>{tabContent}</RouterLink> : <a href={url}>{tabContent}</a>}
            </TabItem>
          );
        })}
      </StyledTabs>
    </TabsWrapper>
  );
};

export default LinkTabs;
