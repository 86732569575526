import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { PaywallContext } from '../../../../../contexts/PaywallContext';
import { SuperShareProvider, useSuperShare } from '../../../../../contexts/SuperShareContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import sortArray, { SortOrder } from '../../../../../lib/sortArray';
import { useGetAccountSettingsQuery } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import {
  useGetCurriculumAssignmentsQuery,
  useUpdateCurriculumAssignmentsMutation,
} from '../../../../../redux/services/resourceApis/curriculumAssignments/curriculumAssignmentsApi';
import {
  CurriculumAssignmentWithAction,
  CurriculumAssignmentsPayload,
  GroupWithCompositeId,
  ResourceCompositeId,
  UserWithCompositeId,
  getCompositeId,
} from '../../../../../redux/services/resourceApis/curriculumAssignments/types';
import {
  useGetCurriculumForEditModalQuery,
  useGetCurriculumGroupsQuery,
} from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { useGetModalUsersQuery } from '../../../../../redux/services/resourceApis/users/usersApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import FieldLabel from '../../../../design_system/core/FieldLabel';
import { Option } from '../../../../design_system/core/SelectOption/types';
import DetailedAvatar, {
  DetailedAvatarProps,
} from '../../../../design_system/display/DetailedAvatar/DetailedAvatar';
import { OptionalSourceProps } from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import P from '../../../../design_system/text/P';
import MultiSelectField from '../../../../design_system/Triage/fields/MultiSelectField';
import { useFlashNotification } from '../../../../FlashNotificationContext';
import RefreshLinkModal from '../../../curriculums/public_share/RefreshLinkModal';
import EmptyState from '../EmptyState/EmptyState';
import GeneralAccessSettings from '../GeneralAccessSettings/GeneralAccessSettings';
import GroupReadModal from '../GroupReadModal/GroupReadModal';
import PublicShareModal from '../PublicShareModal/PublicShareModal';
import SharedWithRow, { ColumnWrapper, SharedWithRowWrapper } from '../SharedWithRow/SharedWithRow';

const t = initTranslations('supershare');

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const StyledOptionWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  justify-content: space-between;
  margin: ${({ theme: { constants } }) => `${constants.spacerSm2} 0`};
`;

const DetailedAvatarWrapper = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
`;

const AlreadyAddedLabel = styled(P)`
  flex-basis: 30%;
  text-align: right;
`;

const StyledColumnWrapper = styled(ColumnWrapper)`
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const getDetailedAvatarProps = (
  resource: UserWithCompositeId | GroupWithCompositeId
): DetailedAvatarProps => {
  if (resource.type === 'User') {
    return {
      avatarImage: resource.avatar,
      id: `user-detailed-avatar-${resource.id}-option`,
      label: resource.name,
      subLabel: `${initTranslations('users.permissions')(resource.permission)}`,
    };
  } else {
    return {
      avatarIcon: 'users',
      id: `group-detailed-avatar-${resource.id}-option`,
      label: resource.name,
      subLabel: t('shared_with_row.members', { count: resource.user_count }),
    };
  }
};

const ResourceMultiSelect = () => {
  const { addNewGroupOrUser, resourcesToShareWith, sharedWithResources } = useSuperShare();

  const searchOptions: Option[] = useMemo(() => {
    const updatedResourceOptions = resourcesToShareWith.map((resource) => {
      const { name, compositeId, type } = resource;
      const detailedAvatarProps = getDetailedAvatarProps(resource);
      const sourceProps: OptionalSourceProps =
        type === 'User'
          ? { sourceName: 'users', sourceImageUrl: resource.avatar }
          : { sourceName: 'group' };
      const isAlreadyAdded = sharedWithResources.some(
        ({ assignableCompositeId }) => assignableCompositeId === compositeId
      );
      const option = {
        value: compositeId,
        label: (
          <StyledOptionWrapper>
            <DetailedAvatarWrapper>
              <DetailedAvatar {...detailedAvatarProps} />
            </DetailedAvatarWrapper>
            {isAlreadyAdded && <AlreadyAddedLabel text={t('supershare_modal.already_added')} />}
          </StyledOptionWrapper>
        ),
        disabled: isAlreadyAdded,
        searchableTerm: name,
        ...sourceProps,
      };

      return option;
    });
    const orderedOptions = sortArray({
      array: updatedResourceOptions,
      sortKey: 'searchableTerm',
      sortOrder: SortOrder.asc,
    });

    return sortArray({ array: orderedOptions, sortKey: 'disabled', sortOrder: SortOrder.asc });
  }, [resourcesToShareWith, sharedWithResources]);

  return (
    <Wrapper>
      <MultiSelectField
        closeMenuOnSelect
        defaultValue={null}
        disabled={false}
        isClearable
        isMulti
        isSearchable
        isValid
        loading={false}
        menuPosition='absolute'
        onNonDefaultSelected={(memberIds: string[]) => {
          const compositeId = memberIds[0] as ResourceCompositeId;
          addNewGroupOrUser(compositeId);
        }}
        options={searchOptions}
        placeholder={t('supershare_modal.search_people_and_groups')}
        searchControlIcon
        size='md'
        value={null}
      />
    </Wrapper>
  );
};

type OrderedResourcesByCategory = {
  owner: CurriculumAssignmentWithAction | undefined;
  currentUser: CurriculumAssignmentWithAction | undefined;
  justAdded: CurriculumAssignmentWithAction[];
  existing: CurriculumAssignmentWithAction[];
};

type SharedWithSectionProps = {
  sharedWithResources: CurriculumAssignmentWithAction[];
};

const SharedWithSection = ({ sharedWithResources }: SharedWithSectionProps) => {
  const { id: currentUserId } = useCurrentUser();
  const currentUserCompositeId = getCompositeId('User', currentUserId);

  const orderedResources = useMemo(() => {
    const orderedResourcesByCategory: OrderedResourcesByCategory = {
      owner: undefined,
      currentUser: undefined,
      justAdded: [],
      existing: [],
    };

    sharedWithResources.forEach((resource) => {
      const { permission, assignableCompositeId, action } = resource;

      if (permission === 'owner' && !orderedResourcesByCategory.owner) {
        orderedResourcesByCategory.owner = resource;
      } else if (
        assignableCompositeId === currentUserCompositeId &&
        !orderedResourcesByCategory.currentUser
      ) {
        orderedResourcesByCategory.currentUser = resource;
      } else if (action === 'add') {
        orderedResourcesByCategory.justAdded.push(resource);
      } else {
        orderedResourcesByCategory.existing.push(resource);
      }
    });

    const { owner, currentUser, justAdded, existing } = orderedResourcesByCategory;

    const justAddedOrdered = sortArray({
      array: justAdded,
      sortKey: 'curriculumAssignable',
      sortOrder: SortOrder.asc,
      nestedKey: 'name',
    });
    const existingOrdered = sortArray({
      array: existing,
      sortKey: 'curriculumAssignable',
      sortOrder: SortOrder.asc,
      nestedKey: 'name',
    });

    const ownerResult = owner ? [owner] : [];
    const currentUserResult = currentUser ? [currentUser] : [];

    return [...ownerResult, ...currentUserResult, ...justAddedOrdered, ...existingOrdered];
  }, [sharedWithResources, currentUserCompositeId]);

  if (orderedResources.length === 0) return <EmptyState />;

  return (
    <>
      {orderedResources.map((assignmentWithAction) => (
        <SharedWithRow
          assignmentWithAction={assignmentWithAction}
          key={`${assignmentWithAction.curriculumAssignable.type}-${assignmentWithAction.curriculumAssignable.id}`}
        />
      ))}
    </>
  );
};

const CurrentModal = ({
  curriculumId,
  setShowSuperShareModal,
  showSuperShareModal,
}: SuperShareModalProps) => {
  const { activeGroupReadModalResource, currentModal, setCurrentModal } = useSuperShare();
  const { rootDomain } = useCurrentAccount();
  const { data: curriculum } = useGetCurriculumForEditModalQuery(curriculumId);

  useEffect(() => {
    if (showSuperShareModal) setCurrentModal('superShare');
  }, [showSuperShareModal, setCurrentModal]);

  if (currentModal === 'superShare') {
    return (
      <MainModal
        curriculumId={curriculumId}
        setShowSuperShareModal={setShowSuperShareModal}
        showSuperShareModal={showSuperShareModal}
      />
    );
  } else if (currentModal === 'groupRead' && activeGroupReadModalResource !== null) {
    return (
      <GroupReadModal
        groupName={activeGroupReadModalResource.name}
        id={activeGroupReadModalResource.id}
        memberCount={activeGroupReadModalResource.memberCount}
      />
    );
  } else if (currentModal === 'publicShare' && curriculum) {
    return (
      <PublicShareModal
        currentModal={currentModal}
        curriculum={curriculum}
        setCurrentModal={setCurrentModal}
      />
    );
  } else if (
    currentModal === 'refreshPublicShareLink' &&
    curriculum &&
    curriculum.public_curriculum
  ) {
    return (
      <RefreshLinkModal
        baseDomain={rootDomain}
        closeRefreshModal={() => setCurrentModal('publicShare')}
        curriculumTitle={curriculum.title}
        publicCurriculum={curriculum.public_curriculum}
      />
    );
  }

  return <></>;
};

const StyledSharedWithRowGrid = styled(SharedWithRowWrapper)`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
  justify-content: flex-end;
  padding-left: ${({ theme: { constants } }) => constants.spacerSm3};
  padding-right: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const MainModal = ({ curriculumId }: SuperShareModalProps) => {
  const {
    setCurrentModal,
    sharedWithResources,
    curriculumPublished,
    closeSuperShareModal,
    activeGroupReadModalResource,
    stateChanged,
    changedCurriculumAssignments,
    generalAccessSetting,
  } = useSuperShare();
  const [updateCurriculumAssignments, result] = useUpdateCurriculumAssignmentsMutation();
  const {
    curriculum: { singular: subjectTermSingular },
  } = useAccountTerminology();
  const { isLoading: isSubmitting } = result;
  const paywallCtx = useContext(PaywallContext);
  const publicCurriculumsPaywalled = paywallCtx.includes('public_curriculums');
  const { data } = useGetAccountSettingsQuery();
  const publicCurriculumsEnabled = data?.advanced_settings?.public_curriculums;

  useDisplayFlashOnResponse({
    result,
    successFunction: closeSuperShareModal,
    errorMessage: t('supershare_modal.saving_error'),
  });

  const curriculumAssignmentsPayload: CurriculumAssignmentsPayload = {
    accessControl: generalAccessSetting,
    id: curriculumId,
    curriculumAssignments: changedCurriculumAssignments,
  };

  const taskModalArgs: TaskModalProps = {
    badgeFontWeight: 'regular',
    badgeType: curriculumPublished ? 'success' : 'caution',
    badgeText: curriculumPublished
      ? t('supershare_modal.badge_published')
      : t('supershare_modal.badge_unpublished'),
    desktopSize: 'xl',
    footerContent: <GeneralAccessSettings />,
    headerChildren: (
      <>
        <ResourceMultiSelect />
        {!!sharedWithResources.length && (
          <StyledSharedWithRowGrid>
            <FieldLabel text={t('supershare_modal.shared_with.shared_with_header')} />
            <ColumnWrapper>
              <FieldLabel text={t('supershare_modal.shared_with.completion_header')} />
            </ColumnWrapper>
            <StyledColumnWrapper>
              <FieldLabel text={t('supershare_modal.shared_with.access_header')} />
            </StyledColumnWrapper>
          </StyledSharedWithRowGrid>
        )}
      </>
    ),
    heapModalName: 'supershare-modal',
    isDisabled: !stateChanged || isSubmitting,
    onCloseRequest: closeSuperShareModal,
    primaryButtonTask: () => {
      updateCurriculumAssignments(curriculumAssignmentsPayload);
    },
    primaryButtonText: t('supershare_modal.save_button'),
    processing: isSubmitting,
    subtitle: t('supershare_modal.subheader'),
    tertiaryButton: publicCurriculumsEnabled
      ? {
          icon: { iconName: publicCurriculumsPaywalled ? 'lock' : 'share-nodes' },
          isDisabled: !curriculumPublished || publicCurriculumsPaywalled,
          task: () => setCurrentModal('publicShare'),
          text: t('supershare_modal.public_share_button'),
          tooltipId: 'supershare-modal-public-share-tooltip',
          tooltipText: !curriculumPublished
            ? t('supershare_modal.publish_to_share_tooltip', {
                subject: subjectTermSingular.toLowerCase(),
              })
            : '',
          paywallTitle: publicCurriculumsPaywalled
            ? t('supershare_modal.upgrade_tooltip_title')
            : '',
          paywallDescription: publicCurriculumsPaywalled
            ? t('supershare_modal.upgrade_tooltip_description')
            : '',
        }
      : undefined,
    title: t('supershare_modal.header'),
    scrollInsideBody: !!sharedWithResources.length,
    minMaxModalHeight: { min: '43.125rem', max: '90vh' },
  };

  useEffect(() => {
    if (activeGroupReadModalResource !== null) {
      setCurrentModal('groupRead');
    }
  }, [activeGroupReadModalResource, setCurrentModal]);

  return (
    <TaskModal {...taskModalArgs}>
      <div id='shared-with-rows'>
        <SharedWithSection sharedWithResources={sharedWithResources} />
      </div>
    </TaskModal>
  );
};

export type SuperShareModalProps = {
  curriculumId: number;
  setShowSuperShareModal: Dispatch<SetStateAction<boolean>>;
  showSuperShareModal: boolean;
};

const SuperShareModal = ({
  curriculumId,
  setShowSuperShareModal,
  showSuperShareModal,
}: SuperShareModalProps) => {
  const { flash } = useFlashNotification();
  const {
    data: curriculumWithAssignments,
    isLoading: isCurriculumWithAssignmentsLoading,
    error: curriculumWithAssignmentsError,
  } = useGetCurriculumAssignmentsQuery(curriculumId);
  const { data: users, isLoading: isUsersLoading, error: usersError } = useGetModalUsersQuery();
  const {
    data: groups,
    error: groupsError,
    isLoading: isGroupsLoading,
  } = useGetCurriculumGroupsQuery(curriculumId);

  const isLoading = isCurriculumWithAssignmentsLoading || isUsersLoading || isGroupsLoading;
  const error = curriculumWithAssignmentsError || usersError || groupsError;

  useEffect(() => {
    if (error) {
      setShowSuperShareModal(false);
      flash('error', t('supershare_modal.error'));
    }
  }, [error, flash, setShowSuperShareModal]);

  if (isLoading) return <></>;
  if (!curriculumWithAssignments || !users || !groups) return <></>;

  return (
    <SuperShareProvider
      completionRequired={curriculumWithAssignments.completionRequired}
      curriculumWithAssignments={curriculumWithAssignments}
      groups={groups}
      setShowSuperShareModal={setShowSuperShareModal}
      users={users}
    >
      <CurrentModal
        curriculumId={curriculumId}
        setShowSuperShareModal={setShowSuperShareModal}
        showSuperShareModal={showSuperShareModal}
      />
    </SuperShareProvider>
  );
};

export default SuperShareModal;
