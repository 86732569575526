import InputNumber from 'rc-input-number';
import styled from 'styled-components';

import { fontSm5 } from '../../../styled/TypeSystem';

export const InputNumberStyled = styled(InputNumber)`
  position: relative;
  input {
    width: 100%;
    height: ${({ theme: { constants } }) => constants.heightMd};
    padding-left: ${({ theme: { constants } }) => constants.spacerMd2};
    padding-right: ${({ theme: { constants } }) => constants.spacerLg1};
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
    background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
    color: ${({ theme: { vars } }) => vars.textDefault};
    border: ${({ theme: { constants, vars } }) =>
      `${constants.borderWidthSm} solid ${vars.borderDefault}`};
    box-sizing: border-box;
    ${fontSm5};
    &:focus {
      outline: none;
    }
  }
`;

export const InputNumberControl = styled.div`
  width: 10px;
  height: 6px;
  position: absolute;
  right: 16px;
  cursor: pointer;
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  svg {
    display: block;
    width: 10px;
  }
`;

export const InputNumberControlUp = styled(InputNumberControl)`
  top: 10px;
  svg {
    margin-top: -4px;
  }
`;

export const InputNumberControlDown = styled(InputNumberControl)`
  bottom: 10px;
  svg {
    margin-top: -6px;
  }
`;
