import styled, { css } from 'styled-components';

import { fontMd1 } from '../../../../styled/TypeSystem';

export const SuggestionsSubheader = styled.h3`
  margin: ${({ theme: { constants } }) => `${constants.spacerMd3} 0 ${constants.spacerSm3} 0`};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontMd1};
`;

export const SelectWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const AddBlockWrapper = styled.div(
  ({ theme: { constants, vars } }) => css`
    width: 100%;
    position: absolute;
    padding: ${constants.spacerSm3} ${constants.spacerSm3};
    background-color: ${vars.foundationSurface1};
    border: ${constants.borderRadiusXs} solid ${vars.borderSurface2};
    border-radius: ${constants.borderRadiusLg};
    box-shadow: ${vars.shadowTopSmall};
    cursor: pointer;
    z-index: 1;
  `
);

export const AddBlock = styled.div(
  ({ theme: { constants, vars } }) => css`
    border-radius: ${constants.borderRadiusMd};
    padding: ${constants.spacerSm3} ${constants.spacerMd2};
    background-color: ${vars.foundationBase1};
  `
);
