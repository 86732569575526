import React, { useCallback } from 'react';
import { useCookies } from 'react-cookie';

import { useAppSelector } from '../../../../redux/hooks';
import { reportsApi } from '../../../../redux/services/resourceApis/reports/reportsApi';
import DownloadCsvReport from '../DownloadCsvReport/DownloadCsvReport';

const AllCurriculumsDownloadCsvReport = () => {
  const { filters, searchTerm } = useAppSelector((state) => state.reports.allCurriculumsReport);
  const [getCsv] = reportsApi.useLazyGetAllCurriculumsReportCsvQuery();
  const [cookies] = useCookies(['all_curriculums_report_selected_sort']);
  const isCookies = cookies.all_curriculums_report_selected_sort;
  const sortCol = isCookies && cookies.all_curriculums_report_selected_sort['column'];
  const sortColDir = isCookies && cookies.all_curriculums_report_selected_sort['dir'];

  const downloadCsvDocument = useCallback(() => {
    getCsv({ ...filters, searchTerm, sortCol, sortColDir });
  }, [filters, getCsv, searchTerm, sortCol, sortColDir]);

  return (
    <DownloadCsvReport
      btnSize='md'
      downloadCsvDocument={downloadCsvDocument}
      id='all-curriculums-button-download-csv'
    />
  );
};

export default AllCurriculumsDownloadCsvReport;
