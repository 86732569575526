import React from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import { routes } from '../../../../publicApplication/applicationRouter';
import RouterLink from '../../../../publicApplication/RouterLink';
import { ColumnComponentProps } from '../types';

const t = initTranslations('reports.curriculums_report_table.columns');

const Actions = ({ record }: ColumnComponentProps) => {
  const { slug } = useCurrentAccount();
  const { id } = record;

  return (
    <RouterLink to={routes.curriculumByUserReport({ slug, id })}>{t('view_details')}</RouterLink>
  );
};

export default Actions;
