import React from 'react';

import { AllUsersReportColumn, AllUsersReportUser, ColumnComponentProps } from '../types';
import Actions from './Actions';
import Groups from './Groups';
import LastActiveAt from './LastActiveAt';
import Name from './Name';
import Roles from './Roles';
import Score from './Score';

type TableColumnsProps = { [key in AllUsersReportColumn]: React.FC<ColumnComponentProps> };

const COLUMNS_KEYS: TableColumnsProps = {
  name: Name,
  actions: Actions,
  groups: Groups,
  roles: Roles,
  completion_percentage: Score,
  last_active_at: LastActiveAt,
};

type Props = {
  columnName: AllUsersReportColumn;
  record: AllUsersReportUser;
};

const TableColumn = ({ columnName, record }: Props) => {
  const Column = COLUMNS_KEYS[columnName as keyof TableColumnsProps];

  return <Column columnName={columnName} record={record} />;
};

export default TableColumn;
