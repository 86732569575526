import React, { useContext } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { AccountTerminologyContext } from '../../../../AccountTerminologyProvider';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { routes } from '../../../publicApplication/applicationRouter';
import NotificationUserName from '../../notifications/NotificationUserName/NotificationUserName';
import { TextFragment } from '../styles';
import { ActivityAssociationParams } from '../types';

const t = initTranslations('reports.activity_log.curriculum');

export interface CurriculumAssignProps {
  activityKey: string;
  trackable: ActivityAssociationParams;
  recipient: ActivityAssociationParams;
  parameters?: object;
}

const Assign = ({ activityKey, trackable, recipient }: CurriculumAssignProps) => {
  const { slug } = useCurrentAccount();
  const { curriculum } = useContext(AccountTerminologyContext);

  return (
    <>
      <TextFragment>{t('assign', { curriculum: curriculum.singular.toLowerCase() })}</TextFragment>
      <SourceBadge
        readOnly={!trackable.is_active}
        sourceName='curriculum'
        sourceRoute={routes.curriculumEdit({ slug, id: trackable.id })}
        sourceText={trackable.name}
      />
      <TextFragment>{t('to')}</TextFragment>
      <NotificationUserName
        user={{
          is_active: recipient.is_active,
          id: recipient.id,
          name: recipient.name,
        }}
      />
      {activityKey == 'curriculum.assign_owner' && (
        <TextFragment>{t('with_owner_access')}</TextFragment>
      )}
    </>
  );
};

export default Assign;
