import React, { ReactNode } from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../lib/initTranslations';
import { mediaBreakpointPxLg } from '../../../styled/Breakpoint';
import FlexContainer from '../../../styled/FlexContainer';
import { fontMd1 } from '../../../styled/TypeSystem';
import IconButton from '../../buttons/IconButton';

const StyledBanner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 1.5rem;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};

  @media (min-width: ${mediaBreakpointPxLg}) {
    flex-direction: row;
    justify-content: center;
  }
`;

const StyledContent = styled(FlexContainer)`
  flex: 1;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${mediaBreakpointPxLg}) {
    flex-direction: row;
  }
`;
const StyledBannerDismissButton = styled(IconButton)`
  position: absolute;
  top: ${({ theme: { constants } }) => constants.spacerSm2};
  right: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars } }) => vars.accentSubdued3};
  ${fontMd1};
`;

const ariaTranslate = initTranslations('aria');

export interface Props {
  onCloseRequest: () => void;
  children: ReactNode[] | ReactNode;
}

const Banner = ({ onCloseRequest, children }: Props) => {
  return (
    <StyledBanner>
      <StyledContent align='center'>{children}</StyledContent>

      <StyledBannerDismissButton
        ariaLabel={ariaTranslate('aria_label_close')}
        id='banner-dismiss'
        name='times'
        onClick={onCloseRequest}
      />
    </StyledBanner>
  );
};

export default Banner;
