import React from 'react';

import { usePlanBrandColors } from '../../../../../../../hooks/billing/usePlanBrandColors';
import { usePlanTitle } from '../../../../../../../hooks/billing/usePlanTitle';
import useCurrentAccount from '../../../../../../../hooks/useCurrentAccount';
import usePublicConfigs from '../../../../../../../hooks/usePublicConfigs';
import { useRedirectToBillingOverlay } from '../../../../../../../hooks/useRedirectToBillingOverlay';
import { getFormattedPrice } from '../../../../../../../lib/getFormattedPrice';
import initTranslations from '../../../../../../../lib/initTranslations';
import { BillingInterval } from '../../../../../../../types/BillingInterval';
import { BillingPlanName } from '../../../../../../../types/BillingPlanName';
import { PlanInterval, PlanPrice, PlanPriceWrapper } from '../../../../shared/styles';
import { Action } from '../reducer';
import {
  ActivePlanIndicator,
  AnnuallyBilledText,
  PlanCardTitle,
  PlanDetailsWrapper,
  PlanEmployees,
  UpgradePlanButton,
} from './styles';

type PlanDetailsProps = {
  isActive: boolean;
  name: BillingPlanName;
  employeesSize: string;
  pricePerMonth: number;
  dispatch: React.Dispatch<React.SetStateAction<Action>>;
  interval: BillingInterval;
  ctaKey: string;
};

const t = initTranslations('simple_pricing.billing_page.plans.card');

const PlanDetails = ({
  isActive,
  name,
  employeesSize,
  pricePerMonth,
  dispatch,
  interval,
  ctaKey,
}: PlanDetailsProps) => {
  const { configs: publicConfigs } = usePublicConfigs();
  const planBrandColor = usePlanBrandColors({ name });
  const planTitle = usePlanTitle({ name });
  const navigateToBillingOverlay = useRedirectToBillingOverlay();
  const { status } = useCurrentAccount();
  const isTrialingOrTrialEnded = ['trialing', 'trial_ended'].includes(status);
  const talkToSalesLink = isTrialingOrTrialEnded ? 'TALK_TO_SALES_TRIAL' : 'TALK_TO_SALES_ACTIVE';
  const isCustomPlan = name === 'custom';
  const hasEmployeesAndNotCustom = employeesSize && !isCustomPlan;
  const employees = !isCustomPlan ? employeesSize : 0;
  const employeesText = employees
    ? t('employees.count', { count: employees })
    : isCustomPlan
    ? t('employees.custom')
    : t('employees.unlimited');
  const showAnnuallyBilledText = !!employees && interval === 'year';

  const handleClickUpgradePlan = () => {
    if (ctaKey == 'contact_us') {
      window.open(publicConfigs['HELP_PAGE_URL'], '_blank');
    } else if (ctaKey == 'talk_to_sales') {
      window.open(publicConfigs[talkToSalesLink], '_blank');
    } else {
      navigateToBillingOverlay({ plan: name, interval });
    }
  };

  return (
    <PlanDetailsWrapper>
      <PlanCardTitle planBrandColor={planBrandColor} text={planTitle} />
      <PlanPriceWrapper>
        {hasEmployeesAndNotCustom ? (
          <>
            <PlanPrice text={getFormattedPrice(pricePerMonth)} />
            <PlanInterval>{t('per_month')}</PlanInterval>
          </>
        ) : (
          !isActive && <PlanPrice text={t('cta.talk_to_sales')} />
        )}
      </PlanPriceWrapper>
      {showAnnuallyBilledText && <AnnuallyBilledText text={t('billed_annually')} />}
      <PlanEmployees>{employeesText}</PlanEmployees>
      {isActive ? (
        <ActivePlanIndicator>{t('cta.active_plan')}</ActivePlanIndicator>
      ) : (
        <UpgradePlanButton
          buttonType='brand'
          fullWidth
          id={`upgrade-to-${name}-billing-plan`}
          onClick={handleClickUpgradePlan}
          {...(dispatch && { onMouseEnter: () => dispatch({ type: name, isHovered: true }) })}
          {...(dispatch && { onMouseLeave: () => dispatch({ type: name, isHovered: false }) })}
          text={t(`cta.${ctaKey}`)}
        />
      )}
    </PlanDetailsWrapper>
  );
};

export default PlanDetails;
