import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useReducer } from 'react';
import { useTheme } from 'styled-components';

import { getBadgeTypeByPublishStatus } from '../../../../../lib/all_curriculums_report/getBadgeTypeByPublishStatus';
import { getBadgeTypeByVerifyContentStatus } from '../../../../../lib/all_curriculums_report/getBadgeTypeByVerifyContentStatus';
import initTranslations from '../../../../../lib/initTranslations';
import {
  AllCurriculumsFilters,
  setAllCurriculumsReportFilters,
} from '../../../../../redux/domains/reports/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useGetAllCurriculumsReportFiltersDataQuery } from '../../../../../redux/services/resourceApis/reports/reportsApi';
import {
  PublishStatus,
  VerifyContentStatus,
} from '../../../../../redux/services/resourceApis/reports/types';
import { groupsToOptions } from '../../../../../types/Group';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import Badge from '../../../../design_system/display/badge';
import Checkbox from '../../../../design_system/input/controls/Checkbox';
import MultiSelectField from '../../../../design_system/Triage/fields/MultiSelectField';
import CollapsibleFiltersSection from '../../../shared/CollapsibleFiltersSection/CollapsibleFiltersSection';
import CompletionScoreSection from '../../CompletionScoreSection/CompletionScoreSection';
import ReportFiltersSlideout from '../../ReportFiltersSlideout/ReportFiltersSlideout';
import { filterReducer } from './reducer';

const t = initTranslations('reports.all_curriculums_report_filters');

const AllCurriculumsReportFilters = () => {
  const dispatch = useAppDispatch();
  const { curriculum } = useAccountTerminology();
  const {
    constants: { spacerSm3 },
  } = useTheme();
  const { appliedFiltersCount, filters } = useAppSelector(
    (state) => state.reports.allCurriculumsReport
  );

  const initialFilterState: AllCurriculumsFilters = useMemo(
    (): AllCurriculumsFilters => ({
      completionScoreMin: 0,
      completionScoreMax: 100,
      groupIds: [],
      teamIds: [],
      roleIds: [],
      ownerIds: [],
      verifyContentStatuses: [],
      publishStatuses: ['published'],
    }),
    []
  );

  const [filterState, dispatchState] = useReducer(filterReducer, initialFilterState);
  const {
    completionScoreMax = 100,
    completionScoreMin = 0,
    groupIds,
    verifyContentStatuses: verifyContentStatusesState,
    publishStatuses: publishStatusesState,
  } = filterState;

  const noAppliedFilters = appliedFiltersCount === 0;
  const isDisabledApplyBtn = isEqual(filters, filterState) && noAppliedFilters;

  const clearFilters = () => {
    dispatch(setAllCurriculumsReportFilters(initialFilterState));
    dispatchState({ type: 'setDefaultFilters' });
  };

  const applyFilters = () => {
    dispatch(setAllCurriculumsReportFilters(filterState));
  };

  useEffect(() => {
    dispatch(setAllCurriculumsReportFilters(initialFilterState));
  }, [dispatch, initialFilterState]);

  const { data } = useGetAllCurriculumsReportFiltersDataQuery();

  if (!data) return <></>;

  const { completionData, groups, verifyContentStatuses, publishStatuses } = data;

  const teams = groups.filter(({ kind }) => kind === 'team');
  const roles = groups.filter(({ kind }) => kind === 'role');

  const curriculumsCompletionData = Object.entries(completionData).map((item) => ({
    name: item[0],
    count: item[1],
  }));

  const handleChangeVerifyContentStatus = (status: VerifyContentStatus, isChecked: boolean) => {
    const newState = isChecked
      ? verifyContentStatusesState.filter((filter) => filter !== status)
      : [...verifyContentStatusesState, status];

    dispatchState({
      type: 'setVerifyContentStatuses',
      verifyContentStatuses: newState,
    });
  };

  const handleChangePublishStatus = (status: PublishStatus, isChecked: boolean) => {
    const newState = isChecked
      ? publishStatusesState.filter((filter) => filter !== status)
      : [...publishStatusesState, status];

    dispatchState({
      type: 'setPublishStatuses',
      publishStatuses: newState,
    });
  };

  return (
    <ReportFiltersSlideout
      applyFilters={applyFilters}
      clearFilters={clearFilters}
      disabledApplyButton={isDisabledApplyBtn}
      disabledClearButton={noAppliedFilters}
      slideoutType='all-curriculums-report-filter-slideout'
      title={t('title', { curriculums: curriculum.plural.toLowerCase() })}
    >
      <div id='curriculums-report-filters'>
        <CompletionScoreSection
          completionScoreMax={completionScoreMax}
          completionScoreMin={completionScoreMin}
          data={curriculumsCompletionData}
          setMaxCompletionScore={(values) => {
            dispatchState({
              type: 'setMaxCompletionScore',
              completionScoreMax: values,
            });
          }}
          setMinCompletionScore={(values) => {
            dispatchState({
              type: 'setMinCompletionScore',
              completionScoreMin: values,
            });
          }}
        />

        <CollapsibleFiltersSection title={t('group')}>
          <MultiSelectField
            defaultValue={[]}
            onNonDefaultSelected={(value: string[]) => {
              dispatchState({ type: 'setGroups', groupIds: value });
              const teamIds = teams.map(({ id }) => `${id}`);
              const roleIds = roles.map(({ id }) => `${id}`);
              const newTeamIds = value.filter((id) => teamIds.includes(id));
              const newRoleIds = value.filter((id) => roleIds.includes(id));
              dispatchState({ type: 'setRoles', roleIds: newRoleIds });
              dispatchState({ type: 'setTeams', teamIds: newTeamIds });
            }}
            options={groupsToOptions(groups)}
            placeholder={t('group_placeholder')}
            value={groupIds}
          />
        </CollapsibleFiltersSection>

        <CollapsibleFiltersSection title={t('verification')}>
          {verifyContentStatuses.map((status) => {
            const isChecked = filterState.verifyContentStatuses.includes(status);
            const checkboxIdentifier = `all-curriculums-report-filter-${status}`;

            return (
              <Checkbox
                bottomMargin={spacerSm3}
                centeredCheckboxes
                checked={isChecked}
                id={checkboxIdentifier}
                key={status}
                label={
                  <Badge
                    clickable
                    size='md'
                    text={t(status)}
                    type={getBadgeTypeByVerifyContentStatus(status)}
                  />
                }
                name={checkboxIdentifier}
                onCheck={() => handleChangeVerifyContentStatus(status, isChecked)}
              />
            );
          })}
        </CollapsibleFiltersSection>

        <CollapsibleFiltersSection title={t('status')}>
          {publishStatuses.map((status) => {
            const isChecked = filterState.publishStatuses.includes(status);
            const checkboxIdentifier = `all-curriculums-report-filter-${status}`;

            return (
              <Checkbox
                bottomMargin={spacerSm3}
                centeredCheckboxes
                checked={isChecked}
                id={checkboxIdentifier}
                key={status}
                label={
                  <Badge
                    clickable
                    size='md'
                    text={t(status)}
                    type={getBadgeTypeByPublishStatus(status)}
                  />
                }
                name={checkboxIdentifier}
                onCheck={() => handleChangePublishStatus(status, isChecked)}
              />
            );
          })}
        </CollapsibleFiltersSection>
      </div>
    </ReportFiltersSlideout>
  );
};

export default AllCurriculumsReportFilters;
