import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

import { useFlashNotification } from '../components/FlashNotificationContext';

const useFlashNoticeCookie = (path?: string) => {
  const { flash } = useFlashNotification();
  const [cookies, , removeCookie] = useCookies(['flash_notice']);

  useEffect(() => {
    const { flash_notice } = cookies;
    const { message, type = 'info' } = flash_notice || {};
    if (message) {
      const decodedMessage = decodeURIComponent(message.replace(/\+/g, ' '));
      flash(type, decodedMessage);
      removeCookie('flash_notice', { path: path || '/' });
    }
  }, [cookies, flash, path, removeCookie]);
};

export default useFlashNoticeCookie;
