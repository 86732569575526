import dayjs from 'dayjs';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { useDispatchSetShowModal } from '../../../../../../redux/domains/modalsSlice/modalsSlice';
import { useStatusBannerDataQuery } from '../../../../../../redux/services/resourceApis/accounts/accountsApi';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import AccountStatusBanner from '../AccountStatusBanner';

const t = initTranslations('per_user_pricing.account_status_banners.past_due');

const PastDueBanner = () => {
  const { data } = useStatusBannerDataQuery();
  const { productTerm } = useAccountTerminology();
  const dispatchShowModal = useDispatchSetShowModal();

  const messageTitle = data?.cancelAt
    ? t('title_with_cancellation_date', {
        date: dayjs.unix(data.cancelAt).format('MMMM D, YYYY'),
      })
    : t('title');
  return (
    <>
      <AccountStatusBanner
        buttonOnClick={() => dispatchShowModal('updatePaymentMethod', true)}
        buttonText={t('button')}
        className='past-due-banner'
        emoji='😱'
        messageBody={t('body', { productTerm })}
        messageTitle={messageTitle}
      />
    </>
  );
};

export default PastDueBanner;
