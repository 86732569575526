import { formatOptions } from '../components/design_system/core/CoreSelectField/CoreSelectField';
import { BaseOption, Option } from '../components/design_system/core/SelectOption/types';
import { AccountStatus } from '../types/AccountState';
import { BillingPlanName } from '../types/BillingPlanName';
import useCurrentAccount from './useCurrentAccount';

type SeatsOptions = [string[], Option[]];

// Get salesGatingThreshold split config for a given plan and account status
// Returns an array of objects with min, max and step properties - [{ min: 10, max: 1000, step: 1 }]
const useSeatsRangeConfig = (plan: BillingPlanName, status: AccountStatus) => {
  const {
    splitFeatures: { salesGatingThreshold },
  } = useCurrentAccount();
  if (salesGatingThreshold) {
    const validPlan = plan === 'train' ? 'train' : 'scale';
    const validStatus = status === 'trialing' || status === 'trial_ended' ? 'trialing' : 'active';

    return salesGatingThreshold[validPlan][validStatus];
  }

  return [];
};

// Returns an array of strings - ['10', '11', ..., '1000']
function useGenerateSeatsRange(plan: BillingPlanName, status: AccountStatus) {
  const result: string[] = [];
  const seatsRangeConfig = useSeatsRangeConfig(plan, status);

  for (const range of seatsRangeConfig) {
    for (let i = range.min; i <= range.max; i += range.step) {
      result.push(i.toString());
    }
  }

  return result;
}

// Transform data from ['10', '11', ..., '1000'] to [{ label: '10', value: '10' }, ..., { label: '1000+', value: '1000+' }]
function generateSeatsOptions(seatsRange: string[]) {
  const maxValue = seatsRange[seatsRange.length - 1];
  const selectOptions: BaseOption[] = seatsRange.map((value) => ({
    label: value === maxValue ? `${maxValue}+` : value,
    value,
  }));

  return formatOptions(selectOptions);
}

export const useSeatsOptions = (plan: BillingPlanName, status: AccountStatus): SeatsOptions => {
  const seatsRange = useGenerateSeatsRange(plan, status);

  return [seatsRange, generateSeatsOptions(seatsRange)];
};
