import React from 'react';
import { useTheme } from 'styled-components';

import { useRedirectToBillingOverlay } from '../../../../../hooks/useRedirectToBillingOverlay';
import initTranslations from '../../../../../lib/initTranslations';
import AccountStatusBanner from './AccountStatusBanner';

type TrialAccountStatusBannerProps = {
  className: string;
  emoji: string;
  title: string;
};

const TrialAccountStatusBanner = ({ className, emoji, title }: TrialAccountStatusBannerProps) => {
  const { vars } = useTheme();
  const navigateToBillingOverlay = useRedirectToBillingOverlay();

  const t = initTranslations('per_user_pricing.account_status_banners.trial_account_status_banner');

  return (
    <AccountStatusBanner
      backgroundColor={vars.stateBackgroundInfo}
      buttonOnClick={() => navigateToBillingOverlay({ type: 'compare-plans' })}
      buttonText={t('primary_button')}
      className={className}
      emoji={emoji}
      messageBody={t('body')}
      messageTitle={title}
    />
  );
};

export default TrialAccountStatusBanner;
