import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const HeaderContent = ({ children }: { children: ReactNode }) => {
  return <StyledDiv id='header-content'>{children}</StyledDiv>;
};

export default HeaderContent;
