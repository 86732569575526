import { GroupResponse } from '../../../../redux/services/resourceApis/groups/types';
import { Curriculum } from '../../../../types';
import { GroupKind } from '../../../../types/Group';
import { User } from '../../../../types/User';
import { AssignmentPermission } from '../curriculums/types';

type BaseCurriculumAssignable = {
  id: number;
  name: string;
};

type UserCurriculumAssignable = Pick<User, 'permission' | 'avatar' | 'email'> & {
  type: 'User';
} & BaseCurriculumAssignable;

type GroupCurriculumAssignable = {
  memberCount: number;
  type: 'Group';
  kind: GroupKind;
} & BaseCurriculumAssignable;

export type CurriculumAssignable = UserCurriculumAssignable | GroupCurriculumAssignable;

type GroupResourceCompositeId = `Group-${number}`;
type UserResourceCompositeId = `User-${number}`;

export type ResourceCompositeId = GroupResourceCompositeId | UserResourceCompositeId;

export type UserWithCompositeId = User & {
  compositeId: UserResourceCompositeId;
  type: 'User';
};
export type GroupWithCompositeId = GroupResponse & {
  compositeId: GroupResourceCompositeId;
  type: 'Group';
};

type UserCompArgs = ['User', number];
type GroupCompArgs = ['Group', number];

type GetCompositeIdArgs = UserCompArgs | GroupCompArgs;

export function getCompositeId(...args: UserCompArgs): UserResourceCompositeId;
export function getCompositeId(...args: GroupCompArgs): GroupResourceCompositeId;
export function getCompositeId(...args: GetCompositeIdArgs): ResourceCompositeId {
  return `${args[0]}-${args[1]}`;
}

export type CurriculumAssignmentPermission = AssignmentPermission | null;

export type UserCurriculumAssignment = {
  permission: CurriculumAssignmentPermission;
  completionRequired: boolean;
  curriculumAssignable: UserCurriculumAssignable;
  curriculumAssignableType: 'User';
};
export type GroupCurriculumAssignment = {
  permission: AssignmentPermission;
  completionRequired: boolean;
  curriculumAssignable: GroupCurriculumAssignable;
  curriculumAssignableType: 'Group';
};

type UserCurriculumAssignmentWithId = UserCurriculumAssignment & {
  id: number;
};
type GroupCurriculumAssignmentWithId = GroupCurriculumAssignment & {
  id: number;
};

export type CurriculumAssignmentWithId =
  | UserCurriculumAssignmentWithId
  | GroupCurriculumAssignmentWithId;

export type CurriculumWithAssignmentsResponse = Pick<
  Curriculum,
  'accessControl' | 'id' | 'published'
> & {
  completionRequired: boolean;
  curriculumAssignments: CurriculumAssignmentWithId[];
};

type AddAction = 'add';
type RemoveAction = 'remove';
type UpdateAction = 'update';
type UnchangedAction = 'unchanged';
export type CurriculumAssignmentAction = AddAction | RemoveAction | UpdateAction | UnchangedAction;

type BaseCurriculumAssignmentWithAction<ActionType, AssignmentType, AssignableCompositeIdType> = {
  assignableCompositeId: AssignableCompositeIdType;
  action: ActionType;
} & AssignmentType;

export type UserCurriculumAssignmentWithAction =
  | BaseCurriculumAssignmentWithAction<AddAction, UserCurriculumAssignment, UserResourceCompositeId>
  | BaseCurriculumAssignmentWithAction<
      UnchangedAction | UpdateAction | RemoveAction,
      UserCurriculumAssignmentWithId,
      UserResourceCompositeId
    >;

export type GroupCurriculumAssignmentWithAction =
  | BaseCurriculumAssignmentWithAction<
      AddAction,
      GroupCurriculumAssignment,
      GroupResourceCompositeId
    >
  | BaseCurriculumAssignmentWithAction<
      UnchangedAction | UpdateAction | RemoveAction,
      GroupCurriculumAssignmentWithId,
      GroupResourceCompositeId
    >;

export type CurriculumAssignmentWithAction =
  | GroupCurriculumAssignmentWithAction
  | UserCurriculumAssignmentWithAction;

export type CurriculumAssignmentsPayload = Pick<Curriculum, 'accessControl' | 'id'> & {
  curriculumAssignments: CurriculumAssignmentWithAction[];
};
