import React from 'react';
import styled from 'styled-components';
import { Route } from 'type-route';

import { User } from '../../../../../types/User';
import Avatar from '../../../../design_system/display/avatar';
import { AvatarShape, AvatarSize } from '../../../../design_system/display/avatar/AvatarTypes';
import { routes } from '../../../publicApplication/applicationRouter';
import ProfileRouterLink from '../../Profile/ProfileRouterLink';

const AvatarLink = styled(ProfileRouterLink)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

const AvatarButton = styled.button`
  padding: 0;
  border: none;
  background: none;
`;

//DS Override: Cursor when hovering Avatar did not look "clickable"
export const StyledAvatar = styled(Avatar)`
  cursor: pointer;
`;

type ActionProps =
  | { breadcrumb: Route<typeof routes>; onClick?: never }
  | { breadcrumb?: never; onClick: () => void };

type AvatarProps = {
  user: User;
  avatarShape: AvatarShape;
  avatarSize: AvatarSize;
  ariaLabel: string;
} & ActionProps;

export const ClickableAvatar = ({
  breadcrumb,
  user,
  avatarShape,
  avatarSize,
  onClick,
  ariaLabel,
}: AvatarProps) => {
  const actionProps = breadcrumb ? { breadcrumb, id: user.id } : { onClick };

  const Action = (breadcrumb ? AvatarLink : AvatarButton) as React.ElementType;

  return (
    <Action aria-label={ariaLabel} {...actionProps}>
      <StyledAvatar image={user.avatar} name={user.name} shape={avatarShape} size={avatarSize} />
    </Action>
  );
};
