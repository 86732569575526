import React from 'react';
import styled from 'styled-components';

import usePublicConfigs from '../../../../../../../hooks/usePublicConfigs';
import initTranslations from '../../../../../../../lib/initTranslations';
import Link from '../../../../../../design_system/Link';
import { CardWrapper } from '../../../../per_user_pricing/shared/styles';
import { BillingIntervalTitle } from '../DiscountsInlineSection/styles';
import { Divider, PlanText } from '../styles';

const t = initTranslations('per_user_pricing.billing_page.plan_card.empty_state');

const EmptyStateWrapper = styled(CardWrapper)`
  max-width: 50%;
`;

const InfoWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm1};
`;

const EmptyState = () => {
  const { configs } = usePublicConfigs();
  const helpPageUrl = configs['HELP_PAGE_URL'];

  return (
    <EmptyStateWrapper id='billing-page-plan-card-empty-state'>
      <PlanText dangerouslySetInnerHTML={{ __html: t('no_plan_selected_html') }} />
      <Divider />
      <InfoWrapper>
        <BillingIntervalTitle>{t('no_plan_attached')}</BillingIntervalTitle>
        <Link
          behavesAs='button'
          onClick={() => window.open(helpPageUrl, '_blank')}
          text={t('contact_support')}
          underlineBehavior='dynamic'
        />
      </InfoWrapper>
    </EmptyStateWrapper>
  );
};

export default EmptyState;
