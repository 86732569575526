import React from 'react';

import initTranslations from '../../../../lib/initTranslations';
import { setActivityLogTimeRangeFilter } from '../../../../redux/domains/reports/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import DropdownWithPoppableMenu from '../../shared/DropdownWithPoppableMenu';
import { ActivityLogFilterMenuOption, SelectedActivityLogFilterMenuOption } from '../Styles';
import { ActivityLogFilters } from './ActivityLogTab/ActivityLogTabTypes';

const t = initTranslations('reports.activity_log.filter.time_range');

const ActivityLogTimeFilter = () => {
  const dispatch = useAppDispatch();
  const { timeRange } = useAppSelector((state) => state.reports.activityLog.filters);
  const timeRangeOptions: ActivityLogFilters['timeRange'][] = ['month', 'year', 'all'];

  const menuOptions = timeRangeOptions.map((option) => (
    <ActivityLogFilterMenuOption key={option}>{t(option)}</ActivityLogFilterMenuOption>
  ));

  return (
    <DropdownWithPoppableMenu
      id='activity-log-time-range-filter-dropdown'
      menuId='activity-log-time-range-filter-dropdown-menu'
      menuPlacement='bottom-start'
      options={menuOptions}
      selectedOption={
        <SelectedActivityLogFilterMenuOption>{t(timeRange)}</SelectedActivityLogFilterMenuOption>
      }
      setSelectedOption={(optionIndex) =>
        dispatch(setActivityLogTimeRangeFilter({ timeRange: timeRangeOptions[optionIndex] }))
      }
      style='default'
    />
  );
};

export default ActivityLogTimeFilter;
