import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { FetchBaseQueryMeta, QueryDefinition } from '@reduxjs/toolkit/query';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import initTranslations from '../lib/initTranslations';
import { embedlyApi } from '../redux/services/embedlyService';
import { EmbedlyParams, EmbedlyResponse, EmbedlyType } from '../types/EmbedlyResponse';

type EmbedlyContext = {
  trigger: LazyQueryTrigger<
    QueryDefinition<
      EmbedlyParams,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>,
      never,
      EmbedlyResponse,
      'embedlyApi'
    >
  >;
  isLoading: boolean;
  errorMessage: string | null;
  resetForm: boolean;
  setResetForm: React.Dispatch<React.SetStateAction<boolean>>;
  embedlyData: EmbedlyResponse | null;
  provider: EmbedlyType[];
  setProviderType: React.Dispatch<React.SetStateAction<EmbedlyType[]>>;
  inputUrl: string | null;
  setInputUrl: React.Dispatch<React.SetStateAction<string | null>>;
  setEmbedlyData: React.Dispatch<React.SetStateAction<EmbedlyResponse | null>>;
  setIsFormValid: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
};

type Props = {
  children: ReactNode;
};

const EmbedlyContext = createContext({} as EmbedlyContext);
export const useEmbedlyContext = () => useContext(EmbedlyContext);

const t = initTranslations('editor.embedly');

const EmbedlyProvider = ({ children }: Props) => {
  const [resetForm, setResetForm] = useState(false);
  const [isFormValid, setIsFormValid] = useState<boolean | null>(null);
  const [provider, setProviderType] = useState<EmbedlyType[]>([]);
  const [inputUrl, setInputUrl] = useState<string | null>(null);
  const [embedlyData, setEmbedlyData] = useState<EmbedlyResponse | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [
    trigger,
    { data: content, isLoading: initialLoading, isFetching, isError: isRequestError, requestId },
  ] = embedlyApi.useLazyGetEmbedQuery();

  const isLoading = initialLoading || isFetching;

  useEffect(() => {
    if (isRequestError || (isFormValid !== null && !isFormValid)) {
      setErrorMessage(t('error.generic'));
    }
  }, [isFormValid, isRequestError]);

  useEffect(() => {
    if (content && requestId) {
      if (provider.includes(content.type)) {
        setEmbedlyData(content);
        setErrorMessage(null);
      } else {
        setErrorMessage(t('error.provider', { provider }));
      }
    }
  }, [content, provider, requestId]);

  return (
    <EmbedlyContext.Provider
      value={{
        trigger,
        isLoading,
        errorMessage,
        resetForm,
        setResetForm,
        embedlyData,
        provider,
        setProviderType,
        setEmbedlyData,
        setIsFormValid,
        setErrorMessage,
        setInputUrl,
        inputUrl,
      }}
    >
      {children}
    </EmbedlyContext.Provider>
  );
};

export { EmbedlyProvider };
