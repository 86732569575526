import React from 'react';
import { useDispatch } from 'react-redux';

import useWindowResize from '../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../lib/initTranslations';
import { closeResponsibilityDetailsModal } from '../../../../../redux/domains/responsibilityDetailsModal/responsibilityDetailsModalSlice';
import { useGetViewOnlyResponsibilityDetailsModalDataQuery } from '../../../../../redux/services/resourceApis/responsibilities/responsibilitiesApi';
import { GetResponsibilityDetailsModalParams } from '../../../../../redux/services/resourceApis/responsibilities/types';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import CoreModal from '../../../../design_system/core/CoreModal';
import { mediaBreakpointSm } from '../../../../styled/Breakpoint';
import {
  ControlButtonsGroup,
  ModalContentWrapper,
  ModalHeaderContainer,
  PrimaryButtonWrapper,
} from '../../../DelegationPlanner/modals/ResponsibilityDetailsModal/styles';
import ModalHeader from './ModalHeader/ModalHeader';
import ResponsibilityDetails from './ResponsibilityDetails/ResponsibilityDetails';
import { ViewOnlyResponsibilityDetailsModalProps } from './types';

const t = initTranslations('delegation_planner.modals.responsibility_details_modal');

const ViewOnlyResponsibilityDetailsModal = ({
  id,
  responsibiliableId,
  type,
}: ViewOnlyResponsibilityDetailsModalProps) => {
  const dispatch = useDispatch();

  const { width } = useWindowResize();
  const displayControlButtonsFullWidth = width < mediaBreakpointSm;

  const responsibilityQuery: GetResponsibilityDetailsModalParams = {
    id,
    type,
    responsibiliable_id: responsibiliableId as number,
  };

  const { data: responsibilityResponse } =
    useGetViewOnlyResponsibilityDetailsModalDataQuery(responsibilityQuery);

  const handleCloseRequest = () => {
    dispatch(closeResponsibilityDetailsModal());
  };

  if (!responsibilityResponse) return <></>;

  return (
    <CoreModal
      closeIconAriaLabel={t('aria_label_cancel')}
      desktopSize='xl'
      disabledPadding={{ horizontal: { all: true }, vertical: { all: true } }}
      heapModalName='responsibility-details-modal'
      onCloseRequest={handleCloseRequest}
    >
      <ModalHeaderContainer isError={!!responsibilityResponse.name}>
        <ModalHeader responsibility={responsibilityResponse} type={type} />
      </ModalHeaderContainer>
      <ModalContentWrapper>
        <ResponsibilityDetails
          contentUrls={responsibilityResponse.contentUrls}
          description={responsibilityResponse.description}
          workingHours={responsibilityResponse.workingHours}
        />
      </ModalContentWrapper>
      <ControlButtonsGroup>
        <PrimaryButtonWrapper>
          <DefaultButton
            buttonType='primary'
            fullWidth={displayControlButtonsFullWidth}
            id='close-responsibility-details-modal'
            onClick={() => handleCloseRequest()}
            size='md'
            text={t('okay')}
            type='button'
          />
        </PrimaryButtonWrapper>
      </ControlButtonsGroup>
    </CoreModal>
  );
};

export default ViewOnlyResponsibilityDetailsModal;
