import React, { useMemo } from 'react';
import styled from 'styled-components';

import DateInput, { DateInputProps } from '../../../design_system/Triage/DateInput/DateInput';

const StyledDateInput = styled(DateInput)`
  width: 9rem;
  margin-right: auto;
`;

type ScheduledDateInputProps = Omit<DateInputProps, 'min' | 'max' | 'value'> & {
  value: string | null;
  minDate: string;
};

const ScheduledDateInput = (props: ScheduledDateInputProps) => {
  const { errorText, disabled, value, onChange, name, minDate } = props;

  const { min, max } = useMemo(() => {
    const oneYearFromNow = new Date(minDate);
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

    return {
      min: minDate,
      max: oneYearFromNow.toISOString().slice(0, 10),
    };
  }, [minDate]);

  const inputValue = useMemo(() => {
    if (!value) return min;

    const date = new Date(value);
    const minDate = new Date(min);

    if (date < minDate || date.toString() === 'Invalid Date') return min;

    return date.toISOString().slice(0, 10);
  }, [min, value]);

  return (
    <StyledDateInput
      className='scheduled-date-input'
      disabled={disabled}
      errorText={errorText}
      max={max}
      min={min}
      name={name}
      onChange={onChange}
      required
      value={inputValue}
    />
  );
};

export default ScheduledDateInput;
