import React, { useContext } from 'react';

import { PaywallContext } from '../../../../../../contexts/PaywallContext';
import { useGetIndividualUserReportElementsQuery } from '../../../../../../redux/services/resourceApis/reports/reportsApi';
import { BodyRow, StyledColumn } from '../../../AllUsersReportPage/AllUsersReportTable/styles';
import TableColumn from './Columns/TableColumn';
import { getColumns } from './ElementsColumn';
import LoadingTableRows from './LoadingTableRows';
import { ElementTableProps } from './types';

const ElementsTable = ({ userId, curriculumId, curriculumElementsCount }: ElementTableProps) => {
  const paywallCtx = useContext(PaywallContext);
  const isSignaturePaywalled = paywallCtx.includes('e_signature');
  const { data, isLoading } = useGetIndividualUserReportElementsQuery({
    userId,
    curriculumId,
  });

  if (isLoading) return <LoadingTableRows rowsCount={curriculumElementsCount} />;
  if (!data) return <></>;

  return (
    <>
      {data.elements.map((element) => {
        return (
          <BodyRow
            className='individual-user-report-elements-tr'
            isSubTableRow
            key={`individual-user-report-elements-tr-${element.elementType}-${element.id}`}
          >
            {getColumns(isSignaturePaywalled).map(({ columnName, contentAlignment }) => (
              <StyledColumn
                contentAlignment={contentAlignment}
                id={`individual-user-report-elements-column-${element.id}-${columnName}`}
                key={`individual-user-report-elements-column-${element.id}-${columnName}`}
              >
                <TableColumn columnName={columnName} record={element} />
              </StyledColumn>
            ))}
          </BodyRow>
        );
      })}
    </>
  );
};

export default ElementsTable;
