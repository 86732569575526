import React, { useState } from 'react';

import { useAccountTerminology } from '../../../../../../components/AccountTerminologyProvider';
import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import { unixTimestampToFormattedDate } from '../../../../../../lib/billing/unixTimestampToFormattedDate';
import initTranslations from '../../../../../../lib/initTranslations';
import { useStatusBannerDataQuery } from '../../../../../../redux/services/resourceApis/accounts/accountsApi';
import { useReactivateMutation } from '../../../../../../redux/services/resourceApis/subscriptions/subscriptionsApi';
import ReactivatedAccountCelebrationModal from '../../../per_user_pricing/ReactivatedAccountCelebrationModal/ReactivatedAccountCelebrationModal';
import AccountStatusBanner from '../AccountStatusBanner';

const t = initTranslations('per_user_pricing.account_status_banners.pending_cancellation');

const PendingCancellationBanner = () => {
  const [showPendingCancellationConfirmationModal, setShowPendingCancellationConfirmationModal] =
    useState(false);
  const { data } = useStatusBannerDataQuery();
  const [reactivate, reactivateResult] = useReactivateMutation();
  const { productTerm } = useAccountTerminology();

  useDisplayFlashOnResponse({
    result: reactivateResult,
    successFunction: () => {
      setShowPendingCancellationConfirmationModal(true);
    },
    errorMessage: t('error.unable_to_reactivate_account', { productTerm }),
  });

  if (!data?.cancelAt) return <></>;

  const formattedCancellationDate = unixTimestampToFormattedDate(data.cancelAt);

  return (
    <>
      <AccountStatusBanner
        buttonOnClick={reactivate}
        buttonText={t('button')}
        className='pending-cancellation-banner'
        emoji='🥲'
        isLoading={reactivateResult.isLoading}
        messageBody={t('body', { productTerm })}
        messageTitle={t('title', {
          date: formattedCancellationDate,
        })}
      />
      {showPendingCancellationConfirmationModal && <ReactivatedAccountCelebrationModal />}
    </>
  );
};

export default PendingCancellationBanner;
