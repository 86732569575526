import styled from 'styled-components';

export const CompletionChartLayer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin: ${({ theme: { constants } }) => constants.spacerSm3} 0;
`;

export const CompletionChartResult = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  .recharts-surface {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;
