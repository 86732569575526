import React, { ReactElement, ReactNode, useCallback } from 'react';

import { FullWidthDiv } from '../styled/Div';

interface Props {
  children: ReactNode | ReactNode[];
  setIsHovered: (hovered: boolean) => void;
  className?: string;
  id?: string;
}

const Hoverable = ({ className = '', children, id, setIsHovered }: Props): ReactElement => {
  const setHovered = useCallback(() => {
    setIsHovered(true);
  }, [setIsHovered]);
  const setNotHovered = useCallback(() => {
    setIsHovered(false);
  }, [setIsHovered]);

  return (
    <FullWidthDiv
      className={className}
      id={id}
      onMouseLeave={setNotHovered}
      onMouseOver={setHovered}
      onTouchStart={setHovered}
    >
      {children}
    </FullWidthDiv>
  );
};

export default Hoverable;
