import React from 'react';
import { Range } from 'react-range';

import { RangeBar, RangeContainer, RangeProgressBar, RangeThumb } from './styles';
import { InputRangeProps } from './types';

const InputRange = ({ maxValue, minValue, values, setValues, step }: InputRangeProps) => {
  return (
    <Range
      max={maxValue}
      min={minValue}
      onChange={setValues}
      renderThumb={({ props: thumbProps }) => (
        <RangeThumb
          {...thumbProps}
          style={{
            ...thumbProps.style,
          }}
        />
      )}
      renderTrack={({ props: trackProps, children }) => (
        <RangeContainer
          style={{
            ...trackProps.style,
          }}
        >
          <RangeBar ref={trackProps.ref}>{children}</RangeBar>
          <RangeProgressBar rangeValue={values}></RangeProgressBar>
        </RangeContainer>
      )}
      step={step}
      values={values}
    />
  );
};

export default InputRange;
