import React from 'react';
import {
  ActionMeta,
  InputActionMeta,
  MultiValueRemoveProps,
  components as SelectComponents,
} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styled, { useTheme } from 'styled-components';

import { CreatableOption } from '../../../../application/account_settings/AdvancedSettings/RestrictByIpSection/CreatableSelectField/types';
import { fontSm4 } from '../../../../styled/TypeSystem';
import FieldLabel from '../../../core/FieldLabel';
import Icon from '../../../display/icons/Icon';
import { SelectProp } from '../MultiSelectField/MultiSelectField';
import { StyleProps } from '../SharedSelect/Styles';
import { CreatableSelectFieldProps } from './types';

export const ErrorText = styled.span`
  color: ${({ theme: { vars } }) => vars.stateError};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
  ${fontSm4};
`;

const CustomMultiValueRemove: React.FC<MultiValueRemoveProps> = (
  props,
  onValueRemove: (value: string) => void
) => {
  const handleRemoveClick = () => {
    const data = props.data as { label: string; value: string };
    onValueRemove(data.value);
  };

  return (
    <SelectComponents.MultiValueRemove
      {...props}
      innerProps={{ ...props.innerProps, onClick: handleRemoveClick }}
    >
      <Icon name='xmark' weight='regular' />
    </SelectComponents.MultiValueRemove>
  );
};

const CreatableSelectField = (props: CreatableSelectFieldProps) => {
  const {
    id,
    autoFocus,
    errorText,
    onValueChanged,
    fieldLabelText,
    placeholder = '',
    className = '',
    isValid = true,
    isValidNewOption,
    onInputChange,
    name,
    selectedValues,
    onValueRemove,
    inputValue,
    onBlur,
    onFocus,
  } = props;

  const CreatableSelectStyles = {
    control: (
      provided: object,
      { isFocused, isSelected, isDisabled, selectProps: { vars, constants, isValid } }: StyleProps
    ) => {
      const isHighlighted = isFocused || isSelected;
      return {
        ...provided,
        boxShadow: '0',
        height: 'auto',
        minHeight: '3.25rem',
        pointerEvents: 'auto',
        backgroundColor: vars.foundationSurface1,
        cursor: isDisabled ? 'not-allowed' : 'text',
        border: `${constants.borderRadiusXs} solid ${
          isDisabled
            ? vars.borderDisabled
            : !isValid
            ? vars.stateError
            : isHighlighted
            ? vars.accentPrimaryDefault
            : vars.borderDefault
        } !important`,
      };
    },

    valueContainer: (provided: object, { selectProps: { constants } }: SelectProp) => ({
      ...provided,
      padding: `${constants.spacerSm3} ${constants.spacerMd2}`,
      paddingBottom: constants.spacerSm2,
      '.react-select__input-container': {
        overflow: 'hidden',
        gridTemplateColumns: 'max-content',
      },
    }),

    multiValue: (provided: object, { selectProps: { constants, vars } }: SelectProp) => {
      return {
        ...provided,
        margin: 0,
        borderRadius: constants.borderRadiusEndcap,
        alignItems: 'center',
        color: vars.textSurface,
        backgroundColor: vars.accentSubdued1,
        padding: `${constants.spacerSm3} ${constants.spacerMd2}`,
        marginRight: constants.spacerSm2,
        marginBottom: constants.spacerSm2,
      };
    },

    menu: () => ({
      display: 'none',
    }),

    multiValueLabel: (provided: object, { selectProps: { constants, vars } }: SelectProp) => {
      return {
        ...provided,
        padding: 0,
        paddingLeft: 0,
        cursor: 'pointer',
        color: vars.accentPrimaryDefault,
        marginRight: constants.spacerSm3,
        fontSize: '0.889rem',
        lineHeight: '1.125rem',
      };
    },

    multiValueRemove: (provided: object, { selectProps: { vars, constants } }: SelectProp) => {
      return {
        ...provided,
        padding: 0,
        cursor: 'pointer',
        borderRadius: constants.borderRadiusCircle,
        color: vars.accentPrimaryDefault,
        ':hover': {
          color: vars.accentPrimaryDefault,
        },
      };
    },
  };

  const handleInputChange = (value: string, meta: InputActionMeta) => {
    if (meta.action === 'input-change') {
      onInputChange(value);
    }
  };

  const handleValueChanged = (_: string, actionMeta: ActionMeta<CreatableOption>) => {
    if (actionMeta.action === 'remove-value' || actionMeta.action === 'pop-value') {
      onValueRemove(actionMeta.removedValue.value);
    } else {
      onValueChanged();
    }
  };

  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onValueChanged();
    }
  };

  const theme = useTheme();
  const CreatableSelectProps: object = {
    id,
    autoFocus,
    className,
    classNamePrefix: 'react-select',
    constants: theme.constants,
    isMulti: true,
    isClearable: false,
    isValid,
    isValidNewOption,
    name,
    onChange: handleValueChanged,
    openMenuOnClick: false,
    openMenuOnFocus: false,
    placeholder,
    styles: CreatableSelectStyles,
    value: selectedValues || null,
    vars: theme.vars,
    inputValue,
    onInputChange: handleInputChange,
    onKeyDown: handleEnterKeyPress,
    onBlur,
    onFocus,
  };

  return (
    <>
      {fieldLabelText && <FieldLabel text={fieldLabelText} />}
      <CreatableSelect
        components={{
          DropdownIndicator: null,
          MultiValueRemove: (props) => CustomMultiValueRemove(props, onValueRemove),
        }}
        {...CreatableSelectProps}
      />
      {!!errorText && <ErrorText>{errorText}</ErrorText>}
    </>
  );
};

export default CreatableSelectField;
