import styled from 'styled-components';

import { summarySectionDividerStyles } from '../BillingFullScreenOverlay/Summary/styles';

export const AddonsSectionList = styled.ul`
  margin: 0;
  padding-left: 0;
`;

export const Divider = styled.div`
  ${summarySectionDividerStyles};
`;
