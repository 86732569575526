import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { routes } from '../../../publicApplication/applicationRouter';
import routeTo from '../../../publicApplication/routeTo';
import { SuccessIcon, UploadStatusText } from './styles';

const t = initTranslations('document_uploads.upload_progress_item');

const SuccessIndicator = ({ curriculumId }: { curriculumId: number | null }) => {
  const { slug } = useCurrentAccount();

  const handleButtonClick = () => {
    !!curriculumId && routeTo(routes.curriculumEdit({ slug, id: curriculumId }));
  };

  return (
    <>
      <UploadStatusText>{t('success')}</UploadStatusText>
      <SuccessIcon name='circle-check' size='sm' weight='solid' />
      <DefaultButton id='view-imported-curriculum' onClick={handleButtonClick} text={t('view')} />
    </>
  );
};

export default SuccessIndicator;
