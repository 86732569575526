import { Curriculum } from '../../../../types';
import { Course } from '../../../../types/Course';
import Step from '../../../../types/Step';

type ShareChangesCurriculum = Pick<Curriculum, 'id' | 'title' | 'completion_required'>;
type BaseModalData = {
  id: number;
  curriculum: ShareChangesCurriculum;
  signature_required: boolean;
  notified_users_count: number;
};

export type ModalDataByStep = {
  step: Step;
  course: Course;
} & BaseModalData;

export type ModalDataByCurriculum = BaseModalData;

export enum Completions {
  Keep = '0',
  Step = '1',
  Course = '2',
  Curriculum = '3',
}

export enum CompletionNotification {
  ActionableUpdate = '0',
  GeneralUpdate = '1',
}

export type DoneEditingCurriculumParams = {
  id: number;
  clearCompletions: CompletionNotification; // update to boolean with backend refactor in GS2-3729
  completions: Completions;
  message: string;
  shareChangesImmediately: '0' | '1'; // update to boolean with backend refactor in GS2-3731
  dataSource: 'curriculum' | 'step';
};

export type DoneEditingStepParams = {
  curriculumId: number;
  courseId: number;
} & DoneEditingCurriculumParams;
