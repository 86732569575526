import { Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { useEmbedlyContext } from '../../../../../contexts/EmbedlyProvider';
import usePrivateConfigs from '../../../../../hooks/usePrivateConfigs';
import initTranslations from '../../../../../lib/initTranslations';
import { ErrorText } from '../../../../design_system/Triage/InputField';
import Loader from '../../../../design_system/Triage/Loader';
import NonClickablePreviewState from '../../../shared/EmptyPreviewState/NonClickablePreviewState';
import { EditorInputContainer, EmbedLinkStyles } from '../../shared/styles';
import EmbedlyContent from './EmbedlyContent';
import EmbedlyForm, { Values, embedlySchema } from './EmbedlyForm';

const t = initTranslations('editor');

const EmbedlyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  iframe {
    height: 12rem;
    ${EmbedLinkStyles};
  }

  .embed-card {
    height: auto;
    ${EmbedLinkStyles};
  }
`;

const LoaderWrapper = styled.div`
  padding-top: ${({ theme: { constants } }) => constants.spacerLg3};
`;

export type FormProps = {
  formLabel?: string;
};

const EmbedlyInput = (formProps: FormProps) => {
  const { configs } = usePrivateConfigs();
  const apiKey = configs['EMBEDLY_KEY'];
  const { trigger, embedlyData, setEmbedlyData, isLoading, errorMessage, provider, setInputUrl } =
    useEmbedlyContext();

  const initialValues: Values = {
    url: '',
  };

  const onSubmit = ({ url }: Values) => {
    if (embedlyData) setEmbedlyData(null);

    setInputUrl(url);
    trigger({ apiKey, url });
  };

  return (
    <EditorInputContainer>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={embedlySchema}>
        <EmbedlyForm {...formProps} />
      </Formik>
      {!embedlyData && !isLoading && !errorMessage && (
        <NonClickablePreviewState
          ariaLabel={t('aria_label_media_preview')}
          label={t(`${provider[0]}.empty_preview`)}
        />
      )}
      <EmbedlyWrapper>
        {isLoading && (
          <LoaderWrapper>
            <Loader size='lg' />
          </LoaderWrapper>
        )}
        {errorMessage && !isLoading && (
          <ErrorText data-testid='embedly-error-message'>{errorMessage}</ErrorText>
        )}
        {!isLoading && !errorMessage && embedlyData && <EmbedlyContent />}
      </EmbedlyWrapper>
    </EditorInputContainer>
  );
};

export default EmbedlyInput;
