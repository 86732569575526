import { useCallback } from 'react';

import { EmbedlyMenuItemProps } from '../plugins/Embedly/EmbedlyMenu/EmbedlyMenu';

const useRecentEmbedlyItems = () => {
  const getRecentEmbedlyItems = () => {
    try {
      const recentItems = localStorage.getItem('recentEmbedlyItems');
      return recentItems ? JSON.parse(recentItems) : [];
    } catch (error) {
      console.error('Failed to parse recent Embedly items from localStorage:', error);
      return [];
    }
  };

  const recentEmbedlyItems = getRecentEmbedlyItems();

  const updateRecentEmbedlyItems = useCallback(
    (newRecentItem: EmbedlyMenuItemProps) => {
      const itemIndex = recentEmbedlyItems.findIndex(
        (item: EmbedlyMenuItemProps) => item.id === newRecentItem.id
      );

      if (itemIndex === 0) return;

      const updatedItems = recentEmbedlyItems.filter(
        (item: EmbedlyMenuItemProps) => item.id !== newRecentItem.id
      );

      updatedItems.unshift(newRecentItem);

      const slicedItems = updatedItems.slice(0, 4);

      localStorage.setItem('recentEmbedlyItems', JSON.stringify(slicedItems));
    },
    [recentEmbedlyItems]
  );

  return { recentEmbedlyItems, updateRecentEmbedlyItems };
};

export default useRecentEmbedlyItems;
