import React from 'react';

import { ActivityCardProps } from './ActivityCard/types';
import AssignedResponsibilityActivitiesDetails from './AssignedResponsibilityActivities/AssignedResponsibilityActivitiesDetails';
import CompletionActivitiesDetails from './CompletionActivities/CompletionActivitiesDetails';
import CourseActivitiesDetails from './CourseActivities/CourseActivitiesDetails';
import CurriculumActivitiesDetails from './CurriculumActivities/CurriculumActivitiesDetails';
import FlowchartActivitiesDetails from './FlowchartActivities/FlowchartActivitiesDetails';
import GroupActivitiesDetails from './GroupActivities/GroupActivitiesDetails';
import RequestActivitiesDetails from './RequestActivities/RequestActivitiesDetails';
import StepActivitiesDetails from './StepActivities/StepActivitiesDetails';
import SurveyActivitiesDetails from './SurveyActivities/SurveyActivitiesDetails';
import SurveyAttemptActivitiesDetails from './SurveyAttemptActivities/SurveyAttemptActivitiesDetails';
import { Activity } from './types';
import UserActivitiesDetails from './UserActivities/UserActivitiesDetails';

interface ActivityDetailsProps {
  'Survey::Attempt': React.FC<ActivityCardProps>;
  'Survey::Survey': React.FC<ActivityCardProps>;
  AssignedResponsibility: React.FC<ActivityCardProps>;
  Completion: React.FC<ActivityCardProps>;
  Course: React.FC<ActivityCardProps>;
  Curriculum: React.FC<ActivityCardProps>;
  Group: React.FC<ActivityCardProps>;
  Request: React.FC<ActivityCardProps>;
  Step: React.FC<ActivityCardProps>;
  User: React.FC<ActivityCardProps>;
  Flowchart: React.FC<ActivityCardProps>;
}

const DETAILS: ActivityDetailsProps = {
  'Survey::Attempt': SurveyAttemptActivitiesDetails,
  'Survey::Survey': SurveyActivitiesDetails,
  AssignedResponsibility: AssignedResponsibilityActivitiesDetails,
  Completion: CompletionActivitiesDetails,
  Course: CourseActivitiesDetails,
  Curriculum: CurriculumActivitiesDetails,
  Group: GroupActivitiesDetails,
  Request: RequestActivitiesDetails,
  Step: StepActivitiesDetails,
  User: UserActivitiesDetails,
  Flowchart: FlowchartActivitiesDetails,
};

const ActivityDetails = ({
  activity,
  productTerm,
}: {
  activity: Activity;
  productTerm?: string;
}) => {
  const ActivityDetailedMessage = DETAILS[activity.trackable_type as keyof ActivityDetailsProps];

  return <ActivityDetailedMessage activity={activity} productTerm={productTerm} />;
};

export default ActivityDetails;
