import React from 'react';
import styled from 'styled-components';
import { Route } from 'type-route';

import { routes } from '../../../publicApplication/applicationRouter';
import RouterLink from '../../../publicApplication/RouterLink';
import { Title } from '../../../shared/DetailedTitle/Title';
import {
  ClickableLabelText,
  sharedClickableStyles,
} from '../../../shared/DetailedTitle/TitleStyles';
import { FontSize, FontSizeSelected } from '../../Slideouts/Shared/FontSize';
import { LabelWrapper } from './styles';

export const LabelText = styled.p<{ fontSize?: FontSize }>`
  margin: 0;
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${FontSizeSelected};
`;

const Link = styled(RouterLink)`
  text-decoration: none;
  margin: 0;
  width: fit-content;
  display: initial;
  vertical-align: top;
  ${sharedClickableStyles};
`;

type ActionProps =
  | {
      to: Route<typeof routes>;
      onClick?: never;
    }
  | {
      to?: never;
      onClick?: () => void;
    }
  | {
      to?: never;
      onClick?: never;
    };

type UserDetailProps = {
  labelText: string;
  labelValue: string;
  className?: string;
  fontSize?: FontSize;
} & ActionProps;

const SecondaryUserDetail = ({
  labelText,
  labelValue,
  to,
  className = '',
  onClick,
  fontSize = 'sm5',
}: UserDetailProps) => {
  return (
    <LabelWrapper>
      <Title fontSize={fontSize} fontWeight='semibold' title={labelValue} />

      {!to && !onClick && (
        <LabelText className={className} fontSize={fontSize}>
          {labelText}
        </LabelText>
      )}

      {to && (
        <Link className={className} fontSize={fontSize} to={to}>
          {labelText}
        </Link>
      )}

      {onClick && (
        <ClickableLabelText className={className} fontSize={fontSize} onClick={onClick}>
          {labelText}
        </ClickableLabelText>
      )}
    </LabelWrapper>
  );
};

export default SecondaryUserDetail;
