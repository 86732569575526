import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import { fontSm5 } from '../../../../styled/TypeSystem';
import { ElementType, elementInPolicyText, removeSignatureText } from './utils';

export const ModalBody = styled.div`
  p:not(:last-child) {
    padding-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  }
`;

export const ModalText = styled.p`
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm5};
`;

export interface RestrictMoveCurriculumModalProps {
  elementType: ElementType | undefined;
  onCloseRequest: () => void;
}

const RestrictMoveCurriculumModal = ({
  elementType,
  onCloseRequest,
}: RestrictMoveCurriculumModalProps) => {
  const t = initTranslations('restrict_curriculum_modal');
  const accountTerminology = useAccountTerminology();

  const taskModalArgs: TaskModalProps = {
    secondaryButtonText: t('btn_text'),
    title: t('title'),
    onCloseRequest,
    heapModalName: 'restrict-move-curriculum-modal',
    processing: false,
  };

  return (
    <TaskModal {...taskModalArgs} desktopSize='md'>
      <ModalBody>
        <ModalText
          dangerouslySetInnerHTML={{
            __html: elementInPolicyText(elementType, accountTerminology),
          }}
        />
        <ModalText
          dangerouslySetInnerHTML={{
            __html: removeSignatureText(elementType, accountTerminology),
          }}
        />
      </ModalBody>
    </TaskModal>
  );
};

export default RestrictMoveCurriculumModal;
