import { BadgeType } from '../../../design_system/display/badge/BadgeTypes';
import { ExpandedEntityKeys, TypeName } from './types';

export const LOW_LEVELS_SEARCH_RESULT_KEYS: Partial<Record<TypeName, ExpandedEntityKeys>> = {
  [TypeName.Course]: ExpandedEntityKeys.Course,
  [TypeName.Curriculum]: ExpandedEntityKeys.Curriculum,
};

export const TYPE_HIERARCHY: Record<TypeName, { lower?: TypeName; higher?: TypeName }> = {
  [TypeName.Step]: { higher: TypeName.Course },
  [TypeName.Course]: { lower: TypeName.Step, higher: TypeName.Curriculum },
  [TypeName.Curriculum]: { lower: TypeName.Course },
};

export const BADGE_TYPES: Record<TypeName, BadgeType> = {
  [TypeName.Curriculum]: 'light',
  [TypeName.Course]: 'info',
  [TypeName.Step]: 'general',
};
