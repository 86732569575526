import { routes } from '../../components/application/publicApplication/applicationRouter';
import { CardType } from '../../components/application/searches/SearchResultsPage/search_result_cards/SearchResultsCard/types';
import useCurrentAccount from '../useCurrentAccount';

type getSearchResultCardUrlArgs = {
  itemId: number;
  cardType: CardType;
  search: string;
  filter?: string;
  page?: number;
};

export function useGetSearchResultCardRoute({
  itemId: id,
  cardType,
  search = '', // default empty string is needed to prevent errors in storybook
  filter,
  page,
}: getSearchResultCardUrlArgs) {
  const { slug } = useCurrentAccount();
  const breadcrumbParams = { slug, page, filter, search };

  const generateRoute = () => {
    switch (cardType) {
      case 'Curriculum':
        return routes.curriculumShow({ slug, id, breadcrumb: routes.search(breadcrumbParams) });
      case 'Course':
        return routes.courseConsume({ slug, id });
      case 'Flowchart':
        return routes.flowchartConsume({ slug, id });
      case 'Step':
        return routes.consume({ slug, id });
      case 'Survey::Survey':
      case 'Survey::Question':
        return routes.surveyConsume({ slug, id });
      case 'Group':
        return routes.group({ slug, id, tab: 'overview' });
      case 'User':
        return routes.userProfile({
          slug,
          id,
          breadcrumb: routes.search({ ...breadcrumbParams, kind: 'company' }),
        });
      case 'Template':
        return routes.template({
          slug,
          id,
          breadcrumb: routes.search({ ...breadcrumbParams, kind: 'templates' }),
        });
    }
  };

  return generateRoute();
}
