import React, { useContext } from 'react';
import styled from 'styled-components';

import { PaywallContext } from '../../../../../contexts/PaywallContext';
import { useSuperShare } from '../../../../../contexts/SuperShareContext';
import initTranslations from '../../../../../lib/initTranslations';
import { CurriculumAssignmentWithAction } from '../../../../../redux/services/resourceApis/curriculumAssignments/types';
import Checkbox from '../../../../design_system/input/controls/Checkbox';
import Scrollbar from '../../../../styled/Scrollbar';
import { fontSm5 } from '../../../../styled/TypeSystem';
import ContentAccessFlyout from '../ContentAccessFlyout/ContentAccessFlyout';
import SharedWithDetailedAvatar from './SharedWithDetailedAvatar';

const SharedWithRowListItem = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  &:hover {
    background: ${({ theme: { vars } }) => vars.foundationBase1};
    color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  }

  &:last-child {
    margin-bottom: 0;
  }
  ${Scrollbar};
`;

export const SharedWithRowWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 60% 20% 20%;
  align-items: center;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${fontSm5};
`;

const t = initTranslations('supershare.shared_with_row');

export type SharedWithRowProps = {
  assignmentWithAction: CurriculumAssignmentWithAction;
};

const SharedWithRow = ({ assignmentWithAction }: SharedWithRowProps) => {
  const { modifyCurriculumAssignment } = useSuperShare();
  const paywallCtx = useContext(PaywallContext);
  const completionsPaywalled = paywallCtx.includes('completions');

  const { completionRequired, assignableCompositeId } = assignmentWithAction;

  return (
    <SharedWithRowListItem className='shared-with-row' id={assignableCompositeId}>
      <SharedWithRowWrapper>
        <SharedWithDetailedAvatar assignmentWithAction={assignmentWithAction} />
        <ColumnWrapper>
          <Checkbox
            checked={completionRequired}
            disabled={completionsPaywalled}
            id={`supershare-completion-required-checkbox-${assignableCompositeId}`}
            label={t('required_to_complete')}
            name='completion-required'
            onCheck={() => {
              modifyCurriculumAssignment({
                ...assignmentWithAction,
                completionRequired: !completionRequired,
              });
            }}
          />
        </ColumnWrapper>
        <ColumnWrapper>
          <ContentAccessFlyout assignmentWithAction={assignmentWithAction} />
        </ColumnWrapper>
      </SharedWithRowWrapper>
    </SharedWithRowListItem>
  );
};

export default SharedWithRow;
