import { QuickSearchSectionData } from '../../types/QuickSearch';

export function getQuickSearchDropdownSectionData(): Record<string, QuickSearchSectionData> {
  return {
    company: {
      translationKey: 'search_in_company_name',
    },
    templates: {
      translationKey: 'search_in_templates',
    },
  };
}
