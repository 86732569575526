import React, { useEffect, useReducer } from 'react';
import { useCookies } from 'react-cookie';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import EmptyStateDark from '../../../../../images/searches_empty_state_dark.svg';
import EmptyState from '../../../../../images/searches_empty_state.svg';
import initTranslations from '../../../../../lib/initTranslations';
import useDebounce from '../../../../../lib/useDebounce';
import { setAllUsersReportPage } from '../../../../../redux/domains/reports/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useGetAllUsersReportQuery } from '../../../../../redux/services/resourceApis/reports/reportsApi';
import NoResults from '../../../../design_system/Triage/NoResults';
import Pagination from '../../../../design_system/Triage/Paginate';
import LoadingTable from '../../../people/UsersOutline/Table/LoadingTable';
import { sortIcon } from '../../SortIcon';
import TableActionsHeader from './ActionsHeader/TableActionsHeader';
import { columns } from './AllUsersReportColumn';
import AllUsersReportTableHeader from './AllUsersReportTableHeader';
import TableColumn from './Columns/TableColumn';
import { tableReducer } from './reducer';
import { BodyRow, StyledColumn, TableStyled, TableWrapper } from './styles';
import { ManageAllUsersReportOption } from './types';

const t = initTranslations('reports.all_users_report.table');

const AllUsersReportTable = () => {
  const { slug } = useCurrentAccount();
  const cookiePath = `/${slug}/all_users_report`;
  const [cookies, setCookie] = useCookies(['all_users_report_selected_sort']);
  const isCookies = cookies.all_users_report_selected_sort;
  const {
    page: activePage,
    searchTerm,
    filters,
  } = useAppSelector((state) => state.reports.allUsersReport);
  const dispatchSlice = useAppDispatch();
  const sortColumn = isCookies && cookies.all_users_report_selected_sort['column'];
  const sortDirection = isCookies && cookies.all_users_report_selected_sort['dir'];
  const debouncedSearchValue = useDebounce<string>(searchTerm.trim(), 500);

  const initialState: ManageAllUsersReportOption = {
    sortColumn: sortColumn ?? 'name',
    sortDirection: sortDirection ?? 'asc',
    sortIcon: sortIcon(sortDirection),
  };
  const [tableState, dispatch] = useReducer(tableReducer, initialState);

  useEffect(() => {
    setCookie(
      'all_users_report_selected_sort',
      JSON.stringify({ column: tableState.sortColumn, dir: tableState.sortDirection }),
      { path: cookiePath }
    );
  }, [cookiePath, setCookie, tableState.sortDirection, tableState.sortColumn]);

  const { data, isFetching, isError } = useGetAllUsersReportQuery({
    page: activePage,
    sortCol: tableState.sortColumn,
    sortColDir: tableState.sortDirection,
    searchTerm: debouncedSearchValue,
    ...filters,
  });

  if (isError) return <div>{t('error')}</div>;
  if (!data) return <LoadingTable />;

  const {
    users,
    limit_value: limitValue,
    total_users_count: totalUsersCount,
    total_pages: totalPages,
  } = data;

  return (
    <>
      <TableActionsHeader
        isFetching={isFetching}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        totalUsersCount={totalUsersCount}
      />
      {isFetching ? (
        <LoadingTable />
      ) : (
        <>
          {users.length ? (
            <>
              <TableWrapper>
                <TableStyled className='all-users-report-table'>
                  <AllUsersReportTableHeader
                    columns={columns}
                    dispatch={dispatch}
                    tableHeaderState={tableState}
                  />
                  <tbody>
                    {users.map((user) => {
                      return (
                        <BodyRow
                          id={`all-users-report-tr-${user.id}`}
                          key={`all-users-report-tr-${user.id}`}
                        >
                          {columns.map(({ columnName }) => (
                            <StyledColumn
                              id={`all-users-report-column-${user.id}-${columnName}`}
                              key={`all-users-report-column-${user.id}-${columnName}`}
                            >
                              <TableColumn columnName={columnName} record={user} />
                            </StyledColumn>
                          ))}
                        </BodyRow>
                      );
                    })}
                  </tbody>
                </TableStyled>
              </TableWrapper>
              <Pagination
                activePage={activePage}
                itemsCountPerPage={limitValue}
                onChange={(page) => {
                  dispatchSlice(setAllUsersReportPage(page));
                }}
                showPaginationDetails
                totalItemsCount={totalUsersCount}
                totalPages={totalPages}
              />
            </>
          ) : (
            <NoResults
              darkImage={EmptyStateDark}
              heading={t('empty_state')}
              iconWidth='45%'
              lightImage={EmptyState}
            />
          )}
        </>
      )}
    </>
  );
};

export default AllUsersReportTable;
