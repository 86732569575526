import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled from 'styled-components';

import TrainualLogoBlack from '../../../../assets/images/search/trainual-logo-black.svg';
import { CardType } from '../../components/application/searches/SearchResultsPage/search_result_cards/SearchResultsCard/types';
import Icon from '../../components/design_system/display/icons/Icon';
import { IconSize } from '../../components/design_system/display/icons/Icon/IconTypes';

const iconMap = {
  Curriculum: 'file-lines',
  Course: 'file-lines',
  Step: 'file-lines',
  'Survey::Survey': 'clipboard-check',
  'Survey::Question': 'square-check',
  Group: 'users',
  User: 'user',
  Template: 'layer-group',
  Flowchart: 'shapes',
};

const DefaultPlaceholderImg = styled.img`
  height: ${({ theme: { constants } }) => constants.heightXs};
`;

export const getSearchResultCardPlaceholderIcon = (
  cardType: CardType,
  iconSize = 'sm' as Exclude<IconSize, 'xs' | 'md' | 'lg' | '2xl'>
) => {
  const iconName = iconMap[cardType] as IconName;

  return iconName ? (
    <Icon name={iconName} size={iconSize} weight='regular' />
  ) : (
    <DefaultPlaceholderImg src={TrainualLogoBlack} />
  );
};
