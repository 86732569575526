import { AllCurriculumsFilters } from '../../../../../redux/domains/reports/reportsSlice';
import { Action } from './types';

export const filterReducer = (
  state: AllCurriculumsFilters,
  action: Action
): AllCurriculumsFilters => {
  switch (action.type) {
    case 'setGroups': {
      return {
        ...state,
        groupIds: action.groupIds,
      };
    }
    case 'setTeams': {
      return {
        ...state,
        teamIds: action.teamIds,
      };
    }
    case 'setRoles': {
      return {
        ...state,
        roleIds: action.roleIds,
      };
    }
    case 'setMinCompletionScore': {
      return {
        ...state,
        completionScoreMin: action.completionScoreMin,
      };
    }
    case 'setMaxCompletionScore': {
      return {
        ...state,
        completionScoreMax: action.completionScoreMax,
      };
    }
    case 'setVerifyContentStatuses': {
      return {
        ...state,
        verifyContentStatuses: action.verifyContentStatuses,
      };
    }
    case 'setPublishStatuses': {
      return {
        ...state,
        publishStatuses: action.publishStatuses,
      };
    }
    case 'setDefaultFilters': {
      return {
        ...state,
        completionScoreMin: 0,
        completionScoreMax: 100,
        groupIds: [],
        teamIds: [],
        verifyContentStatuses: [],
        publishStatuses: ['published'],
      };
    }
  }
};
