import styled from 'styled-components';

import { fontMd3 } from '../../../../styled/TypeSystem';

export const NotificationsGroupWrapper = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerLg2};
`;

export const NotificationsDate = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontMd3};
`;
