import React from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import Avatar from '../../../../../design_system/display/avatar';
import { routes } from '../../../../publicApplication/applicationRouter';
import RouterLink from '../../../../publicApplication/RouterLink';
import { ColumnComponentProps } from '../types';
import { AvatarWrapper, BoldText, UserDetailsWrapper, UserInfoWrapper, UserTitle } from './styles';

const Name = ({ record }: ColumnComponentProps) => {
  const { slug } = useCurrentAccount();
  const { id, name, avatar, title } = record;
  const individualUserReportRoute = routes.individualUserReport({
    slug,
    id,
    breadcrumb: routes.allUsersReport({ slug }),
    breadcrumbText: name,
  });

  return (
    <UserInfoWrapper>
      <AvatarWrapper to={individualUserReportRoute}>
        <Avatar image={avatar} name={name} shape='circle' size='md' />
      </AvatarWrapper>
      <UserDetailsWrapper>
        <RouterLink to={individualUserReportRoute}>
          <BoldText className='notranslate'>{name}</BoldText>
        </RouterLink>
        <UserTitle>{title}</UserTitle>
      </UserDetailsWrapper>
    </UserInfoWrapper>
  );
};

export default Name;
