import React, { FC } from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../../AccountTerminologyProvider';
import {
  SectionItemContainer,
  SectionItemTitle,
} from '../../../../../DelegationPlanner/modals/ResponsibilityDetailsModal/styles';
import { DescriptionWrapper, Textarea } from './styles';
import { DescriptionProps } from './types';

const t = initTranslations('delegation_planner.modals.responsibility_details_modal.description');

const Description: FC<DescriptionProps> = ({ description }) => {
  const {
    responsibility: { singular: responsibilitySingular },
  } = useAccountTerminology();

  return (
    <SectionItemContainer>
      <DescriptionWrapper>
        <SectionItemTitle>
          {t('title', { responsibility: responsibilitySingular })}
        </SectionItemTitle>
        <Textarea>{description || t('readonly_placeholder')}</Textarea>
      </DescriptionWrapper>
    </SectionItemContainer>
  );
};

export default Description;
