import React, { useEffect, useReducer } from 'react';
import { useCookies } from 'react-cookie';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import EmptyStateDark from '../../../../../images/reports-empty-state-dark.svg';
import EmptyState from '../../../../../images/reports-empty-state.svg';
import initTranslations from '../../../../../lib/initTranslations';
import useDebounce from '../../../../../lib/useDebounce';
import { setAllCurriculumsReportPage } from '../../../../../redux/domains/reports/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useGetAllCurriculumsReportQuery } from '../../../../../redux/services/resourceApis/reports/reportsApi';
import NoResults from '../../../../design_system/Triage/NoResults';
import Pagination from '../../../../design_system/Triage/Paginate';
import LoadingTable from '../../../people/UsersOutline/Table/LoadingTable';
import { TableStyled, TableWrapper } from '../../AllUsersReportPage/AllUsersReportTable/styles';
import { sortIcon } from '../../SortIcon';
import TableActionsHeader from './ActionsHeader/TableActionsHeader';
import AllCurriculumsReportTableHeader from './AllCurriculumsReportTableHeader';
import { tableHeaderReducer } from './reducer';
import TableRow from './TableRow';
import { TableHeaderState } from './types';

const AllCurriculumsReportTable = () => {
  const t = initTranslations('reports');
  const { page, filters, searchTerm } = useAppSelector(
    (state) => state.reports.allCurriculumsReport
  );
  const dispatch = useAppDispatch();
  const { slug } = useCurrentAccount();
  const cookiePath = `/${slug}/curriculum_report`;
  const [cookies, setCookie] = useCookies(['all_curriculums_report_selected_sort']);
  const isCookies = cookies.all_curriculums_report_selected_sort;
  const sortColumn = isCookies && cookies.all_curriculums_report_selected_sort['column'];
  const sortDirection = isCookies && cookies.all_curriculums_report_selected_sort['dir'];

  const initialHeaderState: TableHeaderState = {
    sortColumn: sortColumn ?? 'title',
    sortDirection: sortDirection ?? 'asc',
    sortIcon: sortIcon(sortDirection),
  };

  const [tableHeaderState, dispatchTableHeader] = useReducer(
    tableHeaderReducer,
    initialHeaderState
  );

  useEffect(() => {
    setCookie(
      'all_curriculums_report_selected_sort',
      JSON.stringify({ column: tableHeaderState.sortColumn, dir: tableHeaderState.sortDirection }),
      { path: cookiePath }
    );
  }, [cookiePath, setCookie, tableHeaderState.sortDirection, tableHeaderState.sortColumn]);

  const debouncedSearchTerm = useDebounce<string>(searchTerm, 500);

  const { data, isFetching, isError } = useGetAllCurriculumsReportQuery({
    ...filters,
    searchTerm: debouncedSearchTerm,
    sortCol: tableHeaderState.sortColumn,
    sortColDir: tableHeaderState.sortDirection,
    page,
  });

  if (isError) return <div>{t('error')}</div>;
  if (!data) return <LoadingTable />;

  const {
    curriculums,
    limit_value: limitValue,
    total_pages: totalPages,
    total_curriculums_count: totalCurriculumCount,
  } = data;

  return (
    <>
      <TableActionsHeader isFetching={isFetching} totalCurriculumsCount={totalCurriculumCount} />
      {isFetching ? (
        <LoadingTable />
      ) : (
        <>
          {curriculums.length ? (
            <>
              <TableWrapper>
                <TableStyled className='all-curriculums-report-table'>
                  <AllCurriculumsReportTableHeader
                    dispatch={dispatchTableHeader}
                    tableHeaderState={tableHeaderState}
                  />
                  <tbody>
                    {curriculums.map((curriculum) => {
                      return (
                        <TableRow
                          curriculum={curriculum}
                          key={`all-curriculums-report-tr-${curriculum.id}`}
                        />
                      );
                    })}
                  </tbody>
                </TableStyled>
              </TableWrapper>
              <Pagination
                activePage={page}
                itemsCountPerPage={limitValue}
                onChange={(page) => {
                  dispatch(setAllCurriculumsReportPage(page));
                }}
                showPaginationDetails
                totalItemsCount={totalCurriculumCount}
                totalPages={totalPages}
              />
            </>
          ) : (
            <NoResults
              darkImage={EmptyStateDark}
              heading={t('no_results_found')}
              iconWidth='45%'
              lightImage={EmptyState}
            />
          )}
        </>
      )}
    </>
  );
};

export default AllCurriculumsReportTable;
