import React, { useState } from 'react';

import { useGetDefaultAccountPlan } from '../../../../../../hooks/billing/useGetDefaultAccountPlan';
import { getBillingPlansByInterval } from '../../../../../../lib/billing/getBillingPlansByInterval';
import { FetchSimplePricingPlansDataResponse } from '../../../../../../redux/services/resourceApis/billing/simplePricingTypes';
import { BillingInterval } from '../../../../../../types/BillingInterval';
import {
  SimplePricingBillingPlanName,
  simplePricingV3BillingPlanTypes,
} from '../../../../../../types/BillingPlanName';
import { CardsWrapper } from '../../../shared/BillingPage/shared/styles';
import PlanCardsHeader from '../shared/PlanCardHeader/PlanCardHeader';
import PlansCard from './PlansCard/PlansCard';
import { BillingPagePlansWithTrainualPlusWrapper } from './styles';

type BillingPagePlansWithTrainualPlusProps = {
  billingPlansPriceData: FetchSimplePricingPlansDataResponse;
};

const BillingPagePlansWithTrainualPlus = ({
  billingPlansPriceData,
}: BillingPagePlansWithTrainualPlusProps) => {
  const defaultPlanName = useGetDefaultAccountPlan();
  const [selectedPlanName, setSelectedPlanName] =
    useState<SimplePricingBillingPlanName>(defaultPlanName);
  const [selectedInterval, setSelectedInterval] = useState<BillingInterval>('year');
  const plans = getBillingPlansByInterval(selectedInterval, billingPlansPriceData.billingPlans);
  const mainPlans = plans.filter((plan) => simplePricingV3BillingPlanTypes.includes(plan.name));
  const selectedPlan = mainPlans.find((plan) => plan.name === selectedPlanName) || mainPlans[0];

  return (
    <BillingPagePlansWithTrainualPlusWrapper className='billing-page-plans-with-trainual-plus'>
      <PlanCardsHeader
        selectedInterval={selectedInterval}
        setSelectedInterval={setSelectedInterval}
      />
      <CardsWrapper>
        <PlansCard
          plans={mainPlans}
          selectedPlan={selectedPlan}
          setSelectedPlanName={setSelectedPlanName}
        />
        <div>Trainual+ card</div>
      </CardsWrapper>
    </BillingPagePlansWithTrainualPlusWrapper>
  );
};

export default BillingPagePlansWithTrainualPlus;
