import styled, { css } from 'styled-components';

import P from '../../../../design_system/text/P';
import SearchField from '../../../../design_system/Triage/fields/SearchField';
import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { mediaBreakpointPxLg, mediaBreakpointPxMd } from '../../../../styled/Breakpoint';
import Scrollbar from '../../../../styled/Scrollbar';
import { fontSm5 } from '../../../../styled/TypeSystem';
import { FixedCellProps, StyledTextProps } from './TableTypes';

const stickyColumnStyles = css`
  background: ${({ theme }) => theme.vars.foundationSurface1};

  @media (min-width: ${mediaBreakpointPxMd}) {
    position: sticky;
  }
`;

const sharedCellStyles = css`
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: left;
`;

const sharedFixedCellBorder = css`
  border-bottom: ${({ theme }) =>
    `${theme.constants.borderWidthSm} solid ${theme.vars.borderSurface2}`};

  @media (min-width: ${mediaBreakpointPxMd}) {
    border-bottom: 0;
  }
`;

export const TableStyled = styled.table`
  width: 100%;
  border-collapse: collapse;
  ${fontSm5};
`;

export const TableActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const TableWrapper = styled.div`
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  border-radius: ${({ theme }) => theme.constants.borderRadiusLg};
  position: relative;
  overflow: auto;
  white-space: nowrap;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${Scrollbar};

  @media (min-width: ${mediaBreakpointPxMd}) {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }
`;

export const HeaderRow = styled.tr`
  background: ${({ theme }) => theme.vars.foundationSurface1};
`;

export const BodyRow = styled.tr<{ elementType?: string }>`
  background: ${({ theme }) => theme.vars.foundationSurface1};

  &:hover {
    background: ${({ theme, elementType }) => {
      switch (elementType) {
        case 'Course':
        case 'Survey::Survey':
          return theme.vars.foundationBase1;
        case 'Flowchart':
        default:
          return 'transparent';
      }
    }};
    .left-table-data {
      background: ${({ theme }) => theme.vars.foundationBase1};
    }
  }
`;

export const DefaultHeaderStyled = styled.th`
  padding: 0;
`;

export const LeftAlignedHeaderStyled = styled.th`
  ${stickyColumnStyles};
  padding: 0;
  left: -1px;
  max-width: 18rem;
`;

export const RightAlignedHeaderStyled = styled.th`
  ${stickyColumnStyles};
  right: -1px;
  width: 3rem;
`;

export const RightAlignedDataStyled = styled.td`
  ${stickyColumnStyles};
  ${sharedFixedCellBorder};
  right: -1px;
  width: 3rem;
`;

export const LeftAlignedDataStyled = styled.td`
  ${stickyColumnStyles};
  ${sharedFixedCellBorder};
  left: -1px;
  max-width: 18rem;
`;

export const DefaultDataStyled = styled.td`
  padding: 0 ${({ theme }) => theme.constants.spacerMd3};
  border: ${({ theme }) => `${theme.constants.borderWidthSm} solid ${theme.vars.foundationBase2}`};
  border-top-width: 0;
  a {
    text-decoration: none;
  }
`;

export const TruncatedTableCell = styled(DefaultDataStyled)`
  max-width: 18rem;
`;

export const LoadingTableCell = styled(DefaultDataStyled)`
  height: 3.5rem;
`;

export const Loader = styled.div<{ $width: string }>`
  background: ${({ theme: { vars } }) => vars.foundationBase1};
  filter: blur(3.5px);
  height: 50%;
  width: ${({ $width }) => $width};
`;

export const HeaderCell = styled.div<FixedCellProps>`
  ${sharedCellStyles};
  padding: 0 ${({ theme }) => theme.constants.spacerMd3};

  ${({ isSortable, theme: { vars } }) =>
    isSortable &&
    `
    &:hover {
      cursor: pointer;
      border-color: ${vars.accentPrimaryDefault};
    }
  `};

  ${({ active, theme: { constants, vars } }) => `
      border-bottom: ${constants.borderWidthSm} solid ${
    active ? vars.accentPrimaryDefault : vars.borderSurface2
  };
  `};

  ${({ stickyPosition, theme }) =>
    stickyPosition === 'left' &&
    `
    min-width: ${theme.constants.spacerSm3};

    @media (min-width: ${mediaBreakpointPxMd}) {
      box-shadow: 0 5px 10px ${theme.vars.shadowBackground1};
    }
  `};

  @media (min-width: ${mediaBreakpointPxMd}) {
    ${({ stickyPosition, theme }) =>
      stickyPosition === 'right' &&
      `box-shadow: -5px 5px 10px ${theme.vars.shadowBackground1};
    `};
  }
`;

export const NameCell = styled.div`
  ${sharedCellStyles};
  padding: 0 ${({ theme }) => theme.constants.spacerMd3};
  min-width: ${({ theme }) => theme.constants.spacerSm3};

  @media (min-width: ${mediaBreakpointPxMd}) {
    box-shadow: 0 5px 10px ${({ theme }) => theme.vars.shadowBackground1};
  }
`;

export const ThreeDotCell = styled.div`
  ${sharedCellStyles};
  padding: ${({ theme }) => `0 ${theme.constants.spacerSm3}`};
  justify-content: center;

  @media (min-width: ${mediaBreakpointPxMd}) {
    box-shadow: 0 5px 10px ${({ theme }) => theme.vars.shadowBackground1};
  }
`;

export const StyledTextWrapper = styled.div<StyledTextProps>`
  max-width: 16rem;
  display: flex;
  justify-content: ${({ contentAlignment }) => contentAlignment};
`;

export const TableLink = styled.a`
  text-decoration: none;
  color: ${({ theme: { vars } }) => vars.textDefault};

  &:hover {
    text-decoration: none;
    color: ${({ theme: { vars } }) => vars.textDefault};
  }
`;

export const StyledText = styled.div`
  ${TruncatedText({})};
  padding-right: ${({ theme }) => theme.constants.spacerSm2};
`;

export const UserNameWrapper = styled.div<{ hiddenUser: boolean; pseudoElementContent: string }>`
  padding-right: ${({ theme }) => theme.constants.spacerSm2};
  ${TruncatedText({})};
  /* Min width is required to ensure the hidden label doesn't overlap when table just has users with small names */
  min-width: 5.5rem;

  a {
    color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
    text-decoration: none;

    ${({ hiddenUser, pseudoElementContent }) =>
      hiddenUser &&
      css`
        &::before {
          float: right;
          content: '\\00a0(${pseudoElementContent})';
        }
      `};
  }
`;

export const AvatarWrapper = styled.a`
  text-decoration: none;
  padding-right: ${({ theme }) => theme.constants.spacerSm2};

  &:hover {
    text-decoration: none;
  }
`;

export const BrandText = styled.span`
  color: ${({ theme }) => theme.vars.accentPrimaryDefault};
  cursor: default;
`;

export const SearchFieldWrapper = styled.div`
  width: 100%;
  @media (min-width: ${mediaBreakpointPxMd}) {
    width: 11.75rem;
  }
  @media (min-width: ${mediaBreakpointPxLg}) {
    width: 16rem;
  }
`;

export const SplitButtonWrapper = styled.div`
  margin-left: ${({ theme: { constants } }) => constants.spacerMd2};
`;

// DS Override - need fontSemibold and bottom padding to match design
export const StyledP = styled(P)`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  padding-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const TableActionsHeaderElementsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  & > div {
    &:nth-of-type(1) {
      padding-top: ${({ theme: { constants } }) => constants.spacerSm3};
      order: 1;
    }
    &:nth-of-type(2) {
      order: 2;
    }
    &:nth-of-type(3) {
      order: 3;
    }
  }
  @media (min-width: ${mediaBreakpointPxMd}) {
    flex-flow: row nowrap;
    & > div {
      &:nth-of-type(1) {
        order: 1;
        flex: 1;
      }
      &:nth-of-type(2) {
        padding-top: 0;
        order: 2;
      }
      &:nth-of-type(3) {
        order: 3;
      }
    }
  }
`;

export const CheckboxWrapper = styled.div`
  margin-right: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const StyledSearchField = styled(SearchField)`
  @media (min-width: ${mediaBreakpointPxMd}) {
    width: 40%;
  }
`;
