import { flatMap } from 'lodash';

import { ContextQuestions } from '../../../../redux/services/resourceApis/curriculums/types';
import { ChatMessage } from '../../../../redux/services/resourceApis/openAI/types';

export const CONTENT_PROMPT_MESSAGES: ChatMessage[] = [
  {
    role: 'system',
    content:
      'You are a helpful AI writing assistant. Given a user inputted list you will output paragraphs of text matching their topic.',
  },
  {
    role: 'user',
    content:
      'Given a user inputted list you will output paragraphs of text closely matching the topic they are writing. Each response should be adequate length and include lots of relevant details. Wrap each paragraph with html <p></p> tags. Start after the word Process:',
  },
];

export const NO_ANSWER_RESPONSE =
  "We couldn't find an answer for your search. You can rephrase the question and try again.";

export const QA_PROMPT_MESSAGES: ChatMessage[] = [
  {
    role: 'user',
    content:
      'You are a helpful question/answer assistant. Answer naturally as an informed employee of the company, using only the information provided. ' +
      `Today's date is ${new Date().toLocaleDateString()}. ` +
      `If the information to answer the question is not in the provided content, respond strictly with ${NO_ANSWER_RESPONSE} ` +
      'Do not provide explanations or additional information. Tone is friendly and written as an employee of the company. Do not ask follow-up questions. ' +
      'If the answer involves steps or instructions, start your response with "To [task], you need to follow these steps:" and then list each step clearly as "Step 1:", "Step 2:", and so on. ' +
      'Wrap each step in HTML <p> tags for proper formatting.',
  },
  {
    role: 'assistant',
    content: 'What is your question?',
  },
];

type ContentOutlinePromptInputs = {
  accountName: string;
  industry: string | null;
  employeeSize: string | null;
  curriculumTitle: string;
  currentTopicTitles: string[];
  contextQuestions: ContextQuestions | null;
};

export const contentOutlinePromptMessages = ({
  accountName,
  industry,
  employeeSize,
  curriculumTitle,
  currentTopicTitles,
  contextQuestions,
}: ContentOutlinePromptInputs): ChatMessage[] => {
  const accountDetails = [`Name: ${accountName}`];
  if (industry) accountDetails.push(`Industry: ${industry}`);
  if (employeeSize) accountDetails.push(`# employees: ${employeeSize}`);

  const subjectDetails = [`Title: ${curriculumTitle}`];
  if (currentTopicTitles.length > 0) {
    const topicTitlesString = currentTopicTitles.map((title) => `"${title}"`).join(',');
    subjectDetails.push(`Current topics: ${topicTitlesString}`);
  }

  if (contextQuestions) {
    const { key_learning_objectives, who_is_it_for, info_to_cover } = contextQuestions;
    if (key_learning_objectives)
      subjectDetails.push(`Key learning objectives: ${key_learning_objectives}`);
    if (who_is_it_for)
      subjectDetails.push(`The content of this subject is meant for: ${who_is_it_for}`);
    if (info_to_cover)
      subjectDetails.push(
        `Information that should be covered in this subject includes: ${info_to_cover}`
      );
  }
  return [
    {
      role: 'system',
      content:
        'You will be provided with a subject title and information about our organization. Please provide between 6-12 ' +
        'topic titles for the subject, with one emoji per topic. If a topic already exists in the subject, do not provide it as a suggestion.\n\n' +
        'Your response should be a list with one emoji and topic on each line, and nothing else.\n\n' +
        'Example:\ninput:\nSubject title: "Financial Management"\n\n' +
        'output:\n📅|Budgeting and Planning\n💰|Taxation',
    },
    {
      role: 'assistant',
      content: 'Please provide information about your organization.',
    },
    {
      role: 'user',
      content: accountDetails.join('\n'),
    },
    {
      role: 'assistant',
      content: 'Tell me about the subject.',
    },
    {
      role: 'user',
      content: subjectDetails.join('\n'),
    },
  ];
};

export const refreshCoursePromptMessages = ({
  previousCourseCompletions,
}: {
  previousCourseCompletions: string[];
}): ChatMessage[] => {
  return flatMap(previousCourseCompletions, (courseCompletion) => [
    {
      role: 'system',
      content: courseCompletion,
    },
    {
      role: 'user',
      content: 'Please provide 6-12 new topics.',
    },
  ]);
};

type CourseStepsPromptInputs = {
  accountName: string;
  industry: string | null;
  employeeSize: string | null;
  curriculumTitle: string;
  courseTitle: string;
};

export const courseStepsPromptMessages = ({
  accountName,
  industry,
  employeeSize,
  curriculumTitle,
  courseTitle,
}: CourseStepsPromptInputs): ChatMessage[] => {
  const accountDetails = [`Name: ${accountName}`];
  if (industry) accountDetails.push(`Industry: ${industry}`);
  if (employeeSize) accountDetails.push(`# employees: ${employeeSize}`);

  const subjectAndTopic = `Subject title: ${curriculumTitle}\nTopic title: ${courseTitle}`;

  return [
    {
      role: 'system',
      content:
        'You will be provided with information about our organization along with a subject title and one topic title ' +
        'within that subject. Please provide between 3-8 sub-topics for the topic, with one emoji and a one-sentence ' +
        'description per sub-topic.\n\n' +
        'Your response should be a list with one emoji, sub-topic title, and description on each line, and nothing else.\n\n' +
        'Example:\ninput:\nSubject title: "Financial Management"\nTopic title: "Budgeting and Planning"\n\n' +
        'output:\n' +
        '🎯|Setting Financial Goals|Understand the process of setting meaningful and achievable financial targets.\n' +
        '📊|Creating a Budget|Develop a detailed plan for allocating financial resources based on projected income and expenses.',
    },
    {
      role: 'assistant',
      content: 'Please provide information about your organization.',
    },
    {
      role: 'user',
      content: accountDetails.join('\n'),
    },
    {
      role: 'assistant',
      content: 'Tell me about the subject and topic.',
    },
    {
      role: 'user',
      content: subjectAndTopic,
    },
  ];
};
