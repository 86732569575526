import { camelCase } from 'lodash';
import React, { useState } from 'react';

import { getFormattedPrice } from '../../../../../../../../lib/getFormattedPrice';
import initTranslations from '../../../../../../../../lib/initTranslations';
import {
  useCheckModalState,
  useDispatchSetShowModal,
} from '../../../../../../../../redux/domains/modalsSlice/modalsSlice';
import { AddonItem as AddonItemType } from '../../../../../../../../redux/services/resourceApis/billing/types';
import Hoverable from '../../../../../../../design_system/Hoverable';
import AddonCancellationModal from '../AddonCancellationModal/AddonCancellationModal';
import {
  AddonItemBillingPeriod,
  AddonItemName,
  AddonItemPrice,
  AddonItemPriceWrapper,
  AddonItemText,
  AddonListItem,
  StyledLink,
} from './styles';

type ManageableAddonItemProps = {
  interval: string;
} & Omit<AddonItemType, 'status'>;

const t = initTranslations('billing.addons_section');

const ManageableAddonItem = ({ name, price, interval, kind }: ManageableAddonItemProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const formattedPrice = getFormattedPrice(price);
  const dispatchShowModal = useDispatchSetShowModal();
  const modalName = camelCase(`addonCancellation${name}`);
  const showAddonCancellationModal = useCheckModalState(modalName);

  return (
    <>
      <Hoverable setIsHovered={setIsHovered}>
        <AddonListItem isHovered={isHovered}>
          <AddonItemText>
            <AddonItemName>{name}</AddonItemName>
            {t('add_on')}
          </AddonItemText>
          <StyledLink
            behavesAs='button'
            color='monochrome'
            isHovered={isHovered}
            onClick={() => dispatchShowModal(modalName, true)}
            size='sm'
            text={t('manage_add_ons')}
          />
          <AddonItemPriceWrapper isHovered={isHovered}>
            <AddonItemPrice>{formattedPrice}</AddonItemPrice>
            <AddonItemBillingPeriod>{interval}</AddonItemBillingPeriod>
          </AddonItemPriceWrapper>
        </AddonListItem>
      </Hoverable>

      {showAddonCancellationModal && (
        <AddonCancellationModal kind={kind} modalName={modalName} name={name} />
      )}
    </>
  );
};

export default ManageableAddonItem;
