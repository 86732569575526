import React, { useCallback, useRef, useState } from 'react';

import useMutationObservable from '../../../../../hooks/useMutationObservable';
import initTranslations from '../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import { ModalBody, ModalText } from '../RestrictMoveCurriculumModal/RestrictMoveCurriculumModal';
import {
  ElementType,
  elementInPolicyText,
  removeSignatureText,
} from '../RestrictMoveCurriculumModal/utils';
import { DeprecatedRestrictMoveCurriculumModalProps } from './types';

const DeprecatedRestrictMoveCurriculumModal = ({
  showModal,
  closeModal,
}: DeprecatedRestrictMoveCurriculumModalProps) => {
  const t = initTranslations('restrict_curriculum_modal');
  const accountTerminology = useAccountTerminology();
  const [showCurrentModal, setShowCurrentModal] = useState(false);

  const modalRef = useRef(null);
  const onModalMutation = useCallback((mutationList) => {
    const mutation = mutationList.shift();
    if (mutation && mutation.target.classList.contains('show')) {
      mutation.target.classList.remove('show');
      setShowCurrentModal(true);
    }
  }, []);
  useMutationObservable(modalRef.current, onModalMutation);

  const closeCurrentModal = () => {
    setShowCurrentModal(false);
    closeModal && closeModal();
  };

  const modalWrapper = document.getElementById('deprecated-restrict-move-curriculum-modal-wrapper');
  const elementType = modalWrapper?.getAttribute('element_type');
  if (elementType) {
    modalWrapper?.removeAttribute('element_type');
  }

  const taskModalArgs: TaskModalProps = {
    secondaryButtonText: t('btn_text'),
    title: t('title'),
    onCloseRequest: closeCurrentModal,
    heapModalName: 'deprecated-restrict-move-curriculum-modal',
    processing: false,
    primaryButtonText: '',
    primaryButtonTask: () => ({}),
  };

  return (
    <div id='deprecated-restrict-move-curriculum-modal-wrapper' ref={modalRef}>
      {(showModal || showCurrentModal) && (
        <TaskModal {...taskModalArgs} desktopSize='md'>
          <ModalBody>
            <ModalText
              dangerouslySetInnerHTML={{
                __html: elementInPolicyText(elementType as ElementType, accountTerminology),
              }}
            />
            <ModalText
              dangerouslySetInnerHTML={{
                __html: removeSignatureText(elementType as ElementType, accountTerminology),
              }}
            />
          </ModalBody>
        </TaskModal>
      )}
    </div>
  );
};

export default DeprecatedRestrictMoveCurriculumModal;
