import styled from 'styled-components';

import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import Icon from '../../../../../design_system/display/icons/Icon';
import { fontSm4, fontSm5 } from '../../../../../styled/TypeSystem';
import { SpaceBetweenWrapper } from '../../styles';

export const PlansHeaderWrapper = styled(SpaceBetweenWrapper)`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const PlanCardWrapper = styled.div<{
  isHovered: boolean;
  isTrainPlan: boolean;
  isActive: boolean;
}>`
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};

  ${({ isHovered, isTrainPlan, isActive, theme: { constants, vars } }) =>
    (isHovered || isActive) && {
      outline: `${constants.borderWidth2xl} solid ${
        isTrainPlan ? vars.trainualBrandPurpleMedium : vars.trainualBrandGreenMedium
      }`,
    }};
`;

export const PlanDetailsWrapper = styled.div<{ isComparePlanOverlay: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ isComparePlanOverlay, theme: { constants } }) =>
    isComparePlanOverlay ? 0 : `${constants.spacerLg1} ${constants.spacerLg2}`};
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  ${({ isComparePlanOverlay, theme: { vars } }) =>
    !isComparePlanOverlay && `background-color: ${vars.foundationBase1};`};
`;

export const PlanFeaturesListWrapper = styled.div`
  padding: ${({ theme: { constants } }) => `${constants.spacerLg3} ${constants.spacerLg2}`};
`;

export const PerBillingPeriodText = styled.span`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  color: ${({ theme: { vars } }) => vars.foundationSurface2};
  ${fontSm4};
`;

export const AvailableSeatsText = styled.span`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  text-align: center;
  ${fontSm4};
`;

export const ActivePlanIndicator = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 10.625rem;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerMd3}`};
  color: ${({ theme: { vars } }) => vars.foundationSurface2};
  border: ${({ theme: { vars, constants } }) =>
    `${constants.borderWidthSm} solid ${vars.foundationSurface2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  &:before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
    background-color: ${({ theme: { vars } }) => vars.foundationSurface2};
  }
  ${fontSm5};
`;

export const UpgradePlanButton = styled(DefaultButton)`
  min-width: 10.625rem;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd3} ${constants.spacerMd3}`};
`;

export const FeatureIcon = styled(Icon)<{ isTrainPlan?: boolean; isFeatureIncluded?: boolean }>`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ isTrainPlan, isFeatureIncluded, theme: { vars } }) =>
    isFeatureIncluded
      ? isTrainPlan
        ? vars.trainualBrandPurpleMedium
        : vars.trainualBrandGreenMedium
      : vars.textPlaceholder};
`;
