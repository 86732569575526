import styled from 'styled-components';

import { mediaBreakpointPxLg } from '../../styled/Breakpoint';
import { fontSm5 } from '../../styled/TypeSystem';

export const MessageCardWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-radius: ${(props) => props.theme.constants.borderRadiusLg};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm5};
`;

export const MessageCardAvatarWrapper = styled.div`
  display: flex;
  padding-right: ${({ theme: { constants } }) => constants.spacerSm2};
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const MessageCardDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 2.75rem);
`;

export const MessageCardContent = styled.div<{ hasActionElement: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm1};
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxLg}) {
    width: ${({ hasActionElement }) => `calc(100% - ${hasActionElement ? '18rem' : '8rem'})`};
  }

  b {
    font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  }
`;

export const MessageCardTimeCreated = styled.span`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-left: auto;
  padding-left: ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxLg}) {
    margin-top: 0;
  }
`;

export const MessageCardButtonWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-left: ${({ theme: { constants } }) => constants.spacerMd2};
  display: flex;

  @media (min-width: ${mediaBreakpointPxLg}) {
    justify-content: flex-end;
    width: 9rem;
    margin-top: 0;
  }
`;
