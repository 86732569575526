import React, { useContext } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { AccountTerminologyContext } from '../../../../AccountTerminologyProvider';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { routes } from '../../../publicApplication/applicationRouter';
import NotificationUserName from '../../notifications/NotificationUserName/NotificationUserName';
import { ActivityAssociationParams } from '../types';

const t = initTranslations('reports.activity_log.curriculum');

export interface CurriculumUnassignProps {
  trackable: ActivityAssociationParams;
  recipient: ActivityAssociationParams;
}

const Unassign = ({ trackable, recipient }: CurriculumUnassignProps) => {
  const { slug } = useCurrentAccount();
  const { curriculum } = useContext(AccountTerminologyContext);

  return (
    <>
      {t('unassign_owner')}
      <NotificationUserName
        user={{
          is_active: recipient.is_active,
          id: recipient.id,
          name: recipient.name,
        }}
      />
      {t('for', { curriculum: curriculum.singular.toLowerCase() })}
      <SourceBadge
        readOnly={!trackable.is_active}
        sourceName='curriculum'
        sourceRoute={routes.curriculumEdit({ slug, id: trackable.id })}
        sourceText={trackable.name}
      />
    </>
  );
};

export default Unassign;
