import React from 'react';
import styled from 'styled-components';

import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import Chip from '../../../../design_system/buttons/Chip/Chip';
import { fontSm4 } from '../../../../styled/TypeSystem';

const ChipContainer = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  justify-content: space-between;

  > button {
    height: 3rem;
    gap: ${({ theme: { constants } }) => constants.spacerSm3};
  }

  > button > div {
    min-height: ${({ theme: { constants } }) => constants.heightSm};
    min-width: 2rem;
    justify-content: center;
  }

  > button > div > span {
    font-weight: ${({ theme: { constants } }) => constants.fontRegular};
    ${fontSm4};
  }
`;

export type ProcessChipType = 'company' | 'policy' | 'process' | null | undefined;

type Props = {
  disabled: boolean;
  onChipSelect: (selectedChip: ProcessChipType) => void;
  sector: ProcessChipType;
};

const SectorChipSelect = ({ disabled, onChipSelect, sector }: Props) => {
  const {
    process: { plural: processTermPlural },
    policy: { plural: policyTermPlural },
    company: { singular: companyTermSingular },
  } = useAccountTerminology();

  return (
    // TODO: update colors once design is finalized
    <ChipContainer id='modal-sector-chips'>
      <Chip
        active={sector === 'company'}
        disabled={disabled}
        icon='file-alt'
        id='company-chip'
        onClick={() => onChipSelect('company')}
        palette='yellow'
        text={companyTermSingular}
      />
      <Chip
        active={sector === 'policy'}
        disabled={disabled}
        icon='file-alt'
        id='policy-chip'
        onClick={() => onChipSelect('policy')}
        palette='magenta'
        text={policyTermPlural}
      />
      <Chip
        active={sector === 'process'}
        disabled={disabled}
        icon='file-alt'
        id='process-chip'
        onClick={() => onChipSelect('process')}
        palette='blue'
        text={processTermPlural}
      />
    </ChipContainer>
  );
};

export default SectorChipSelect;
