import React from 'react';
import { isMobileOnly } from 'react-device-detect';

import useWindowResize from '../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../lib/initTranslations';
import CopyableContainer from '../../../shared/CopyableContainer';
import { Title } from '../../../shared/DetailedTitle/Title';
import { ClickableLinkText } from '../../../shared/DetailedTitle/TitleStyles';
import { LabelText } from './SecondaryUserDetail';
import { LabelWrapper, mobileFontSize } from './styles';

interface Props {
  phoneNumberValue: string | null;
}

const t = initTranslations('user_profile');

const UserPhone = ({ phoneNumberValue }: Props) => {
  const { isExtraSmWindow } = useWindowResize();
  if (!phoneNumberValue) return <></>;

  return (
    <LabelWrapper>
      <Title fontSize={mobileFontSize(isExtraSmWindow)} fontWeight='semibold' title={t('phone')} />
      {isMobileOnly ? (
        <ClickableLinkText
          fontSize={mobileFontSize(isExtraSmWindow)}
          href={`tel:${phoneNumberValue}`}
        >
          {phoneNumberValue}
        </ClickableLinkText>
      ) : (
        <CopyableContainer toolTipId='phone-label-tooltip' valueToCopy={phoneNumberValue}>
          <LabelText
            data-for='phone-label-tooltip'
            data-tip
            fontSize={mobileFontSize(isExtraSmWindow)}
          >
            {phoneNumberValue}
          </LabelText>
        </CopyableContainer>
      )}
    </LabelWrapper>
  );
};

export default UserPhone;
