import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { ColumnComponentProps } from '../types';

const t = initTranslations('reports.curriculums_report_table.columns');

const AssignedUsersCount = ({ record: { assigned_users_count } }: ColumnComponentProps) => {
  return <div>{t('users', { count: assigned_users_count || 0 })}</div>;
};

export default AssignedUsersCount;
