import React from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useDeleteSubjectMutation } from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import ConfirmationModal from '../../../../design_system/overlays/modals/ConfirmationModal';

export interface Props {
  id: number;
  closeDeleteModal: () => void;
  title: string;
}

const t = initTranslations('delete_subject_modal');

const DeleteSubjectModal = ({ id, closeDeleteModal, title }: Props) => {
  const [deleteSubject, result] = useDeleteSubjectMutation();
  const { isLoading } = result;
  const {
    curriculum: { singular: subjectTermSingular },
  } = useAccountTerminology();

  useDisplayFlashOnResponse({
    result,
    successFunction: closeDeleteModal,
    errorMessage: t('flash_error', { subject: subjectTermSingular }),
  });

  return (
    <ConfirmationModal
      actionFunction={() => {
        deleteSubject({ id });
      }}
      actionText={t('delete')}
      desktopSize='md'
      heapModalName='delete-subject-modal'
      message={t('description', { subject: title })}
      onCloseRequest={closeDeleteModal}
      processing={isLoading}
      secondaryButtonText={t('cancel')}
      title={t('title')}
    />
  );
};

export default DeleteSubjectModal;
