import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';

import { LocalesDropdownOption } from '../../../../../../components/application/locales/types';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import { RegisteredId } from '../../../../../../lib/idRegistry';
import initTranslations from '../../../../../../lib/initTranslations';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import AnimatedCheckmark from '../../../../../design_system/Triage/AnimatedCheckmark/AnimatedCheckmark';
import { forceCapitalization } from '../../../../../helpers/cssTranslation';
import { fontSm4, fontSm5 } from '../../../../../styled/TypeSystem';
import OutsideClickHandler from '../../../../shared/OutsideClickHandler';
import { GroupKindValue, SortByValue } from '../../libraryReducer';

const DropdownWrapper = styled.div`
  height: ${({ theme: { constants } }) => constants.heightMd};
`;

const DropdownButton = styled(DefaultButton)`
  height: 100%;
  justify-content: space-between;
  width: ${({ className }) => (className == 'full-width' ? '100%' : '')};
`;

const DropdownMenuListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
  width: 18rem;
  position: absolute;
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const DropdownMenuWrapper = styled.div`
  z-index: 10;
  width: ${({ className }) => (className == 'full-width' ? '100%' : 'fit-content')};
`;

const SetDefaultOption = styled.span<{ groupRowHovered: boolean }>`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  display: ${({ groupRowHovered }) => (groupRowHovered ? 'inline' : 'none')};

  ${fontSm4};
`;

const MenuButton = styled.button<{ selected: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  background: ${({ selected, theme: { vars } }) =>
    selected ? vars.accentSubdued1 : 'transparent'};
  border: none;
  color: ${({ selected, theme: { vars } }) => (selected ? vars.textDefault : vars.textSubdued)};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};

  &:hover {
    background: ${({ selected, theme: { vars } }) => !selected && vars.foundationHover};
    color: ${({ theme: { vars } }) => vars.textDefault};
    cursor: pointer;
  }

  ${fontSm5};
`;

const ButtonLabel = styled.span`
  margin-right: ${({ theme: { constants } }) => constants.spacerLg1};
`;

const MenuHeaderSpacer = styled.span`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const MenuHeader = styled.span`
  padding: ${({ theme: { constants } }) => constants.spacerSm3}
    ${({ theme: { constants } }) => constants.spacerMd2};
  border-bottom: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.borderSurface1};

  ${fontSm5};
  ${forceCapitalization};
`;

export interface DropdownMenuOption {
  id: number;
  label: string;
  value: GroupKindValue | SortByValue;
}

const t = initTranslations('curriculums.content');

interface DropdownMenuListProps {
  dropdownOpen: boolean;
  selectedOption: DropdownMenuOption | LocalesDropdownOption;
  setSelectedOption: (IDropdownMenuOption: DropdownMenuOption | LocalesDropdownOption) => void;
  toggleDropdown: () => void;
  options: DropdownMenuOption[] | LocalesDropdownOption[];
  defaultCookieName: 'view-by-content-option' | 'sort-by-content-option' | 'none';
  header: string;
  subHeader: string;
}

const DropdownMenuList = ({
  dropdownOpen,
  setSelectedOption,
  options,
  toggleDropdown,
  selectedOption,
  defaultCookieName,
  header,
  subHeader,
}: DropdownMenuListProps) => {
  const { slug } = useCurrentAccount();
  const [cookies, setCookie] = useCookies();
  const [showCheckMark, setShowCheckMark] = useState<NodeJS.Timeout | undefined>();
  const [hoveredId, setHoveredId] = useState<number | undefined>();
  const currentDefault = cookies[defaultCookieName] || options[0];
  const filteredOptions = options.filter((option) => option.id !== currentDefault.id);
  const showDefaultOption = defaultCookieName != 'none';

  return (
    <>
      {dropdownOpen && (
        <DropdownMenuListWrapper className='open-dropdown-menu-list'>
          <MenuHeader>{header}</MenuHeader>
          <MenuHeaderSpacer />
          <MenuButton
            onClick={() => {
              toggleDropdown();
              setSelectedOption(currentDefault);
            }}
            selected={currentDefault.id === selectedOption.id}
          >
            <ButtonLabel>{currentDefault.label}</ButtonLabel>
            {showCheckMark && <AnimatedCheckmark />}
          </MenuButton>
          <MenuHeaderSpacer />
          <MenuHeader>{subHeader}</MenuHeader>
          <MenuHeaderSpacer />
          {filteredOptions.map((option) => {
            return (
              <MenuButton
                key={`${option.id}-${option.label}-button`}
                onClick={() => {
                  toggleDropdown();
                  setHoveredId(undefined);
                  setSelectedOption(option);
                }}
                onMouseEnter={() => setHoveredId(option.id)}
                onMouseLeave={() => setHoveredId(undefined)}
                selected={option.id === selectedOption.id}
              >
                <ButtonLabel id={`${defaultCookieName}-${option.id}`}>{option.label}</ButtonLabel>
                {showDefaultOption && (
                  <SetDefaultOption
                    groupRowHovered={hoveredId === option.id}
                    id={`set-default-${defaultCookieName}-${option.id}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setCookie(defaultCookieName, option, { path: `/${slug}` });
                      if (showCheckMark) clearTimeout(showCheckMark);
                      const id = setTimeout(() => setShowCheckMark(undefined), 2000);
                      setShowCheckMark(id);
                    }}
                  >
                    {t('view_by_options.set_as_default')}
                  </SetDefaultOption>
                )}
              </MenuButton>
            );
          })}
        </DropdownMenuListWrapper>
      )}
    </>
  );
};

export interface Props {
  className?: string;
  options: DropdownMenuOption[] | LocalesDropdownOption[];
  selectedOption: DropdownMenuOption | LocalesDropdownOption;
  setSelectedOption: (IDropdownMenuOption: DropdownMenuOption | LocalesDropdownOption) => void;
  label: string;
  id: RegisteredId;
  defaultCookieName: 'view-by-content-option' | 'sort-by-content-option' | 'none';
  menuHeader: string;
  menuSubHeader: string;
}

const SetDefaultDropdownMenu = ({
  className,
  options,
  selectedOption,
  setSelectedOption,
  label,
  id,
  defaultCookieName,
  menuHeader,
  menuSubHeader,
}: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  return (
    <DropdownMenuWrapper className={className}>
      <OutsideClickHandler onOutsideClick={() => setDropdownOpen(false)}>
        <DropdownWrapper>
          <DropdownButton
            buttonType='tertiary'
            className={className}
            iconName={dropdownOpen ? 'angle-up' : 'angle-down'}
            iconPosition='right'
            iconWeight='solid'
            id={id}
            onBlur={() => setTimeout(() => setDropdownOpen(false), 200)}
            onClick={toggleDropdown}
            text={label}
          />

          <DropdownMenuList
            defaultCookieName={defaultCookieName}
            dropdownOpen={dropdownOpen}
            header={menuHeader}
            options={options}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            subHeader={menuSubHeader}
            toggleDropdown={toggleDropdown}
          />
        </DropdownWrapper>
      </OutsideClickHandler>
    </DropdownMenuWrapper>
  );
};

export default SetDefaultDropdownMenu;
