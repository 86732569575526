import { FormikErrors, FormikTouched } from 'formik';
import React, { ChangeEvent, useContext, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { PaywallContext } from '../../../../../contexts/PaywallContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import Icon from '../../../../design_system/display/icons/Icon';
import Checkbox from '../../../../design_system/input/controls/Checkbox';
import InputField from '../../../../design_system/Triage/InputField';
import RadioField from '../../../../design_system/Triage/RadioField';
import { fontMd1, fontSm5 } from '../../../../styled/TypeSystem';
import { getChevronIconName } from '../../../shared/helpers';
import PaywallTooltip from '../../../shared/tooltips/PaywallTooltip';
import { AdvancedSettings, FormValues } from './SubjectModalForm';

const OptionalSettingsWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerLg1};
`;

const LinkStyledButton = styled.button`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  padding-left: 0;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  display: flex;
  align-items: center;
  ${fontMd1};
`;

const OptionalSettingsDiv = styled.div<{ visible: boolean }>`
  max-height: ${(props) => (props.visible ? '600px' : '0')};
  padding: ${(props) => (props.visible ? '1rem 0' : '0')};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  overflow: hidden;
  -webkit-transition: opacity 0.2s ease-in;
  -moz-transition: opacity 0.2s ease-in;
  -ms-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;

  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm5};
`;

const NumDaysDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-left: ${({ theme: { constants } }) => constants.spacerLg1};
`;

const NumDaysInputField = styled(InputField)`
  width: 4rem;
`;

const StyledChevronIcon = styled(Icon)`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const StyledLockIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textDisabled};
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  height: 0.8rem;
`;

const RadioFieldWrapper = styled.div`
  margin-left: ${({ theme: { constants } }) => constants.spacerLg1};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd1};

  label {
    input[type='radio'] {
      margin-right: ${({ theme: { constants } }) => constants.spacerSm3} !important;
    }
  }
`;

const PaywallContainer = styled.div<{ settingsExpanded: boolean }>`
  position: absolute;
  bottom: ${({ settingsExpanded }) => (settingsExpanded ? '27rem' : '13.4rem')};
`;

const CHECKBOX_BOTTOM_MARGIN = '1rem';

type OptionalSettingsButtonProps = {
  setShowOptionalSettings: React.Dispatch<React.SetStateAction<boolean>>;
  showOptionalSettings: boolean;
  advancedSettingsLocked: boolean;
  subjectTermSingular: string;
};

const OptionalSettingsButton = ({
  subjectTermSingular,
  advancedSettingsLocked,
  setShowOptionalSettings,
  showOptionalSettings,
}: OptionalSettingsButtonProps) => (
  <LinkStyledButton onClick={() => setShowOptionalSettings((prev) => !prev)} type='button'>
    {t('title', { subject: subjectTermSingular.toLowerCase() })}
    <StyledChevronIcon
      name={getChevronIconName({ isActive: showOptionalSettings, initialDirection: 'up' })}
      weight='solid'
    />
    {advancedSettingsLocked && <StyledLockIcon name='lock' weight='regular' />}
  </LinkStyledButton>
);

const t = initTranslations('curriculums_modal.optional_settings');

interface FormikValues
  extends Omit<
    FormValues,
    | 'checkBoxes'
    | 'dueDate'
    | 'dueDateRadio'
    | 'expiration'
    | 'expirationRadio'
    | 'verifyContentFrequency'
    | 'verifyContentRadio'
  > {
  checkBoxes: AdvancedSettings;
  dueDate: string;
  dueDateRadio: string;
  expiration: string;
  expirationRadio: string;
  verifyContentFrequency: string;
  verifyContentRadio: string;
}

interface Props {
  isOpen: boolean;
  subjectTermSingular: string;
  subjectTermPlural: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  values: FormikValues;
  touched: FormikTouched<FormValues>;
  errors: FormikErrors<FormValues>;
  isAdminPlus: boolean;
  isOwner: boolean;
  signatureLoss: boolean;
  ownerAssignment: boolean;
}

const OptionalSettings = ({
  isOpen,
  subjectTermSingular,
  values,
  handleChange,
  touched,
  errors,
  isAdminPlus,
  isOwner,
  signatureLoss,
  ownerAssignment,
}: Props) => {
  const [showOptionalSettings, setShowOptionalSettings] = useState(isOpen);
  const {
    dueDateRadio,
    expirationRadio,
    verifyContentRadio,
    checkBoxes: {
      completionRequired,
      forcedOrder,
      dueDate,
      expiringCompletion,
      completionCertificates,
      locked,
      displayOwner,
      verifyContent,
      disableTranslation,
    },
  } = values;
  const { multiLanguageEnabled } = useCurrentAccount();
  const [showSignatureWarning] = useState(!completionRequired && signatureLoss);
  const [isStatusHovered, setIsStatusHovered] = useState(false);
  const paywallCtx = useContext(PaywallContext);
  const dueDateLocked = paywallCtx.includes('due_date');
  const advancedSettingsLocked = paywallCtx.includes('curriculum_advanced_settings');
  const lockedDueDateOnly = dueDateLocked && !advancedSettingsLocked;

  const dueDateOptions = [
    { value: '7', text: `7 ${t('radio_options.days')}` },
    { value: '14', text: `14 ${t('radio_options.days')}` },
    { value: 'custom', text: t('radio_options.custom') },
  ];
  const expirationOptions = [
    { value: '30', text: `30 ${t('radio_options.days')}` },
    { value: '90', text: `90 ${t('radio_options.days')}` },
    { value: '365', text: `1 ${t('radio_options.year')}` },
    { value: 'custom', text: t('radio_options.custom') },
  ];
  const verifyContentOptions = [
    {
      value: '30',
      name: 'every',
      text: `${t('radio_options.every')} 30 ${t('radio_options.days')}`,
    },
    {
      value: '365',
      name: 'every',
      text: `${t('radio_options.every')} ${t('radio_options.year')}`,
    },
    { value: 'custom', name: 'custom-frequency', text: t('radio_options.custom') },
  ];
  const {
    constants: { spacerSm2 },
  } = useTheme();

  const renderCompletionCertificatesCheckbox = completionRequired;
  const renderDisableTranslationCheckbox = multiLanguageEnabled;
  const renderRestrictEditingCheckbox = isAdminPlus || isOwner;
  const renderDisplayOwnerCheckbox = isAdminPlus;
  const disableVerifyContent = advancedSettingsLocked || !ownerAssignment;
  const tooltipTextTranslationKey = disableVerifyContent ? 'disabled' : 'enabled';
  const tooltipText = t(`verify_content_tooltip.${tooltipTextTranslationKey}`, {
    subject: subjectTermSingular.toLowerCase(),
  });
  const tooltipHeightOffset = showOptionalSettings ? -65 : -101;

  return (
    <OptionalSettingsWrapper
      id='optional-settings'
      onMouseEnter={() => setIsStatusHovered(true)}
      onMouseLeave={() => setIsStatusHovered(false)}
    >
      {advancedSettingsLocked && isStatusHovered && (
        <PaywallContainer settingsExpanded={showOptionalSettings}>
          <PaywallTooltip
            description={t('paywall.description')}
            modifiers={[{ name: 'offset', options: { offset: [285, tooltipHeightOffset] } }]}
            shouldBeVisible={isStatusHovered}
            title={t('title', { subject: subjectTermSingular.toLowerCase() })}
          />
        </PaywallContainer>
      )}
      <OptionalSettingsButton
        advancedSettingsLocked={advancedSettingsLocked}
        setShowOptionalSettings={setShowOptionalSettings}
        showOptionalSettings={showOptionalSettings}
        subjectTermSingular={subjectTermSingular}
      />
      <OptionalSettingsDiv visible={showOptionalSettings}>
        <Checkbox
          bottomMargin={showSignatureWarning ? spacerSm2 : CHECKBOX_BOTTOM_MARGIN}
          checked={completionRequired}
          disabled={advancedSettingsLocked}
          id='checkbox_completion_required'
          label={t('assignment_require_completion_default', {
            subject: subjectTermSingular.toLowerCase(),
          })}
          name='checkBoxes.completionRequired'
          onCheck={handleChange}
          subLabel={showSignatureWarning ? t('signature_warning') : undefined}
          tooltipHoverIcon
          tooltipId='require-completion-tooltip'
          tooltipText={t('assignment_require_completion_tooltip', {
            subject: subjectTermSingular.toLowerCase(),
          })}
        />
        <Checkbox
          bottomMargin={CHECKBOX_BOTTOM_MARGIN}
          checked={forcedOrder}
          disabled={advancedSettingsLocked}
          id='checkbox_enforced_order'
          label={t('enforce_order')}
          name='checkBoxes.forcedOrder'
          onCheck={handleChange}
          tooltipHoverIcon
          tooltipId='enforce-order-tooltip'
          tooltipText={t('enforce_order_tooltip')}
        />
        <Checkbox
          bottomMargin={Boolean(dueDate) ? '0' : CHECKBOX_BOTTOM_MARGIN}
          checked={dueDate}
          disabled={advancedSettingsLocked || dueDateLocked}
          id='checkbox_duedate'
          label={t('due_date')}
          name='checkBoxes.dueDate'
          onCheck={handleChange}
          tooltipHoverIcon
          tooltipId='due-date-tooltip'
          tooltipText={
            lockedDueDateOnly
              ? t('due_date_locked_tooltip', { subject: subjectTermSingular.toLowerCase() })
              : t('due_date_tooltip', { subject: subjectTermSingular.toLowerCase() })
          }
        />
        {dueDate && (
          <>
            <RadioFieldWrapper>
              <RadioField
                errorText={touched.dueDateRadio && errors.dueDateRadio}
                name='dueDateRadio'
                onChange={handleChange}
                options={dueDateOptions}
                value={dueDateRadio}
              />
            </RadioFieldWrapper>
            {dueDateRadio === 'custom' && (
              <NumDaysDiv>
                <NumDaysInputField
                  errorText={touched.dueDate && errors.dueDate}
                  id='dueDate'
                  label={t('radio_options.custom_days')}
                  name='dueDate'
                  onChange={handleChange}
                  type='text'
                  value={values.dueDate}
                />
              </NumDaysDiv>
            )}
          </>
        )}
        <Checkbox
          bottomMargin={Boolean(expiringCompletion) ? '0' : CHECKBOX_BOTTOM_MARGIN}
          checked={expiringCompletion}
          disabled={advancedSettingsLocked}
          id={t('expiration')}
          label={t('expiration')}
          name='checkBoxes.expiringCompletion'
          onCheck={handleChange}
          tooltipHoverIcon
          tooltipId='expiration-tooltip'
          tooltipText={t('expiration_tooltip')}
        />
        {expiringCompletion && (
          <>
            <RadioFieldWrapper>
              <RadioField
                errorText={touched.expirationRadio && errors.expirationRadio}
                name='expirationRadio'
                onChange={handleChange}
                options={expirationOptions}
                value={values.expirationRadio}
              />
            </RadioFieldWrapper>
            {expirationRadio === 'custom' && (
              <NumDaysDiv>
                <NumDaysInputField
                  errorText={touched.expiration && errors.expiration}
                  id='expiration'
                  label={t('radio_options.custom_days')}
                  name='expiration'
                  onChange={handleChange}
                  type='text'
                  value={values.expiration}
                />
              </NumDaysDiv>
            )}
          </>
        )}
        <>
          <Checkbox
            bottomMargin={Boolean(verifyContent) ? '0' : CHECKBOX_BOTTOM_MARGIN}
            checked={verifyContent}
            disabled={disableVerifyContent}
            id='checkbox_verify_content'
            label={t('verify_content')}
            name='checkBoxes.verifyContent'
            onCheck={handleChange}
            tooltipHoverIcon
            tooltipId='verify-content-tooltip'
            tooltipText={tooltipText}
          />
          {verifyContent && (
            <>
              <RadioFieldWrapper>
                <RadioField
                  disabled={disableVerifyContent}
                  errorText={touched.verifyContentRadio && errors.verifyContentRadio}
                  name='verifyContentRadio'
                  onChange={handleChange}
                  options={verifyContentOptions}
                  value={values.verifyContentRadio}
                />
              </RadioFieldWrapper>
              {verifyContentRadio === 'custom' && (
                <NumDaysDiv>
                  <NumDaysInputField
                    disabled={disableVerifyContent}
                    errorText={touched.verifyContentFrequency && errors.verifyContentFrequency}
                    id='verifyContentFrequency'
                    label={t('radio_options.custom_days')}
                    name='verifyContentFrequency'
                    onChange={handleChange}
                    type='text'
                    value={values.verifyContentFrequency}
                  />
                </NumDaysDiv>
              )}
            </>
          )}
        </>
        {renderCompletionCertificatesCheckbox && (
          <Checkbox
            bottomMargin={CHECKBOX_BOTTOM_MARGIN}
            checked={completionCertificates}
            disabled={advancedSettingsLocked}
            id={t('completion_certificates')}
            label={t('completion_certificates')}
            name='checkBoxes.completionCertificates'
            onCheck={handleChange}
            tooltipHoverIcon
            tooltipId='completion-certificates-tooltip'
            tooltipText={t('completion_certificates_tooltip', {
              subject: subjectTermSingular.toLowerCase(),
            })}
          />
        )}
        {renderRestrictEditingCheckbox && (
          <Checkbox
            bottomMargin={CHECKBOX_BOTTOM_MARGIN}
            checked={locked}
            disabled={advancedSettingsLocked}
            id={t('restrict_editing')}
            label={t('restrict_editing')}
            name='checkBoxes.locked'
            onCheck={handleChange}
            tooltipHoverIcon
            tooltipId='restrict-editing-tooltip'
            tooltipText={t('restrict_editing_tooltip', {
              subject: subjectTermSingular.toLowerCase(),
            })}
          />
        )}
        {renderDisableTranslationCheckbox && (
          <Checkbox
            bottomMargin={CHECKBOX_BOTTOM_MARGIN}
            checked={disableTranslation}
            id={t('disable_translation')}
            label={t('disable_translation')}
            name='checkBoxes.disableTranslation'
            onCheck={handleChange}
            tooltipHoverIcon
            tooltipId='disable-translation-tooltip'
            tooltipText={t('disable_translation_tooltip', {
              subject: subjectTermSingular.toLowerCase(),
            })}
          />
        )}
        {renderDisplayOwnerCheckbox && (
          <Checkbox
            bottomMargin={CHECKBOX_BOTTOM_MARGIN}
            checked={displayOwner}
            disabled={advancedSettingsLocked}
            id={t('display_owner')}
            label={t('display_owner')}
            name='checkBoxes.displayOwner'
            onCheck={handleChange}
            tooltipHoverIcon
            tooltipId='display-owner-tooltip'
            tooltipText={t('display_owner_tooltip', { subject: subjectTermSingular.toLowerCase() })}
          />
        )}
      </OptionalSettingsDiv>
    </OptionalSettingsWrapper>
  );
};

export default OptionalSettings;
