import React, { useState } from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { useResetCompletedCompletionsMutation } from '../../../../../redux/services/resourceApis/steps/stepsApi';
import Checkbox from '../../../../design_system/input/controls/Checkbox';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import {
  CheckBoxWrapper,
  ModalTitleText,
  ResettingText,
  ResettingTextWrapper,
  StyledFooterText,
  StyledIcon,
  TitleWrapper,
} from './styles';
import { ResetCompletionSignatureModalProps } from './types';

const t = initTranslations('e_signature.reset_completion_modal');

const ResetCompletionSignatureModal = ({
  courseId,
  courseTitle,
  closeRequest,
}: ResetCompletionSignatureModalProps) => {
  const [isUnderstood, setIsUnderstood] = useState(false);
  const [resetCompletions, setResetCompletions] = useState(false);
  const closeModal = () => {
    closeRequest(false);
  };
  const [resetCompletion] = useResetCompletedCompletionsMutation();

  const resetStepsCompletions = () => {
    resetCompletion(courseId);
    closeRequest(false);
    setResetCompletions(true);
  };

  const taskModalArgs: TaskModalProps = {
    secondaryButtonText: t('back'),
    title: t('title'),
    onCloseRequest: closeModal,
    heapModalName: 'reset-completion-e-signature-modal',
    isDisabled: !isUnderstood,
    processing: false,
    primaryButtonText: t('reset_and_editing'),
    primaryButtonTask: resetStepsCompletions,
  };

  return (
    <>
      {!resetCompletions && (
        <TaskModal {...taskModalArgs} desktopSize='lg'>
          <ResettingTextWrapper>
            <TitleWrapper>
              <StyledIcon name='triangle-exclamation' weight='solid' />
              <ModalTitleText>{t('resetting_e_signature')}</ModalTitleText>
            </TitleWrapper>
            <ResettingText
              dangerouslySetInnerHTML={{
                __html: t('resetting_text', { course_title: courseTitle }),
              }}
            />
          </ResettingTextWrapper>
          <StyledFooterText
            dangerouslySetInnerHTML={{
              __html: t('footer_text'),
            }}
          />
          <CheckBoxWrapper>
            <Checkbox
              checked={isUnderstood}
              id='reset-completion-checkbox'
              label={t('checkbox_label')}
              name='reset-completion-checkbox'
              onCheck={() => setIsUnderstood(!isUnderstood)}
            />
          </CheckBoxWrapper>
        </TaskModal>
      )}
    </>
  );
};

export default ResetCompletionSignatureModal;
