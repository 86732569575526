import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { RegisteredMenuId } from '../../../../../../lib/idRegistry';
import initTranslations from '../../../../../../lib/initTranslations';
import Flyout from '../../../../../design_system/overlays/flyout';
import SearchField from '../../../../../design_system/Triage/fields/SearchField';
import useActiveMenuHandler from '../../../../publicApplication/utils/useActiveMenuHandler';
import useRecentEmbedlyItems from '../../../hooks/useRecentEmbedlyItems';
import LabelToolbarButton from '../../../toolbar/buttons/components/LabelToolbarButton';
import { items } from '../EmbedlyItems';
import EmbedlyMenu, { EmbedlySectionsKeys, SearchResult } from '../EmbedlyMenu/EmbedlyMenu';

const t = initTranslations('editor.embedly');

const EmbedlyFlyoutContainer = styled.div`
  width: 19.5rem;
  padding-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

//DS Override: removed border to match design
const StyledSearchField = styled(SearchField)`
  > span {
    border-left: none;
  }
`;

//DS Override: changed Flyout width to match design specs in Figma
const StyledFlyout = styled(Flyout)`
  width: 22.5rem;
  height: 24.125rem;
  overflow-y: scroll;
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopSmall};

  .flyout-header {
    display: none;
  }
`;

export const EmbedlyFlyoutId: RegisteredMenuId = 'embedly-flyout';

const EmbedlyFlyout = () => {
  const { isMenuOpen } = useActiveMenuHandler({ menuId: EmbedlyFlyoutId });
  const [searchValue, setSearchValue] = useState('');
  const { recentEmbedlyItems } = useRecentEmbedlyItems();

  const combinedItems = useMemo(() => {
    return recentEmbedlyItems.length ? { recent: recentEmbedlyItems, ...items } : items;
  }, [recentEmbedlyItems]);

  const searchResult = useMemo(() => {
    let result = {} as SearchResult;

    Object.keys(items).forEach((key: EmbedlySectionsKeys) => {
      const filteredItems = items[key].filter(({ title, caption }) => {
        const parsedSearchValue = searchValue.toLowerCase();

        return (
          title.toLowerCase().includes(parsedSearchValue) ||
          caption?.toLowerCase().includes(parsedSearchValue)
        );
      });

      if (filteredItems.length) {
        result = {
          ...result,
          [key]: filteredItems,
        };
      }
    });

    return result;
  }, [searchValue]);

  return (
    <StyledFlyout
      className='embedly-flyout'
      hideHeader
      id={EmbedlyFlyoutId}
      placement='bottom-start'
      triggerButton={
        <LabelToolbarButton
          active={isMenuOpen}
          ariaLabel={t('aria_label_embed_button')}
          className='flyout-trigger'
          iconName='bolt'
          iconType='name'
          id='embed-flyout-button'
          label={t('embed')}
        />
      }
    >
      <EmbedlyFlyoutContainer id='embed-flyout'>
        <StyledSearchField
          autoFocus
          placeholder={t('placeholder')}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <EmbedlyMenu
          items={searchValue ? searchResult : combinedItems}
          setSearchValue={setSearchValue}
        />
      </EmbedlyFlyoutContainer>
    </StyledFlyout>
  );
};

export default EmbedlyFlyout;
