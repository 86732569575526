import React from 'react';

import HeaderImage from '../../../../../../images/billing/past_due_modal_header.svg';
import initTranslations from '../../../../../../lib/initTranslations';
import { useDispatchSetShowModal } from '../../../../../../redux/domains/modalsSlice/modalsSlice';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import TaskModal, { TaskModalProps } from '../../../../../design_system/overlays/modals/TaskModal';

export type PastDueModalProps = {
  closeModal: () => void;
  showPastDueModal: boolean;
};

const t = initTranslations('per_user_pricing.account_status_modals.past_due');

const PastDueModal = ({ closeModal, showPastDueModal }: PastDueModalProps) => {
  const { productTerm } = useAccountTerminology();
  const dispatchShowModal = useDispatchSetShowModal();
  const taskModalArgs: TaskModalProps = {
    title: t('title', { productTerm }),
    onCloseRequest: () => closeModal(),
    headerImage: HeaderImage,
    heapModalName: 'account-past-due-modal',
    processing: false,
    primaryButtonTask: () => openUpdatePaymentMethodModal(),
    primaryButtonText: t('primary_button_text'),
    secondaryButtonText: t('secondary_button_text'),
    disabledPadding: {
      vertical: { top: true },
    },
  };

  function openUpdatePaymentMethodModal() {
    closeModal();
    dispatchShowModal('updatePaymentMethod', true);
  }

  return (
    <>
      {showPastDueModal && (
        <TaskModal {...taskModalArgs} desktopSize='md'>
          {t('body', { productTerm })}
        </TaskModal>
      )}
    </>
  );
};

export default PastDueModal;
