import React, { useCallback } from 'react';

import { useAppSelector } from '../../../../../../redux/hooks';
import { reportsApi } from '../../../../../../redux/services/resourceApis/reports/reportsApi';
import DownloadCsvReport from '../../../DownloadCsvReport/DownloadCsvReport';
import { UsersDownloadCsvReportProps } from './types';

const UsersDownloadCsvReport = ({ sortColumn, sortDirection }: UsersDownloadCsvReportProps) => {
  const { searchTerm, filters } = useAppSelector((state) => state.reports.allUsersReport);
  const [getCsv] = reportsApi.useLazyGetUsersReportCsvQuery();

  const downloadCsvDocument = useCallback(() => {
    getCsv({
      ...filters,
      searchTerm,
      sortCol: sortColumn,
      sortColDir: sortDirection,
    });
  }, [sortColumn, sortDirection, filters, getCsv, searchTerm]);

  return (
    <DownloadCsvReport
      btnSize='md'
      downloadCsvDocument={downloadCsvDocument}
      id='users-button-download-csv'
    />
  );
};

export default UsersDownloadCsvReport;
