import styled from 'styled-components';

import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { fontLg1 } from '../../../../styled/TypeSystem';
import Icon from '../../../display/icons/Icon';
import { MenuButtonStylingProps } from './types';

export const MenuButton = styled.button<{ menuButtonStyling?: MenuButtonStylingProps }>`
  width: ${({ theme: { constants } }) => constants.spacerMd3};
  height: ${({ theme: { constants } }) => constants.heightXs};
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  padding: 0;
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontLg1};

  &:hover {
    color: ${({ theme: { vars } }) => vars.textDefault};
    cursor: pointer;
  }

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: ${({ menuButtonStyling }) => (menuButtonStyling ? menuButtonStyling.width : `2rem`)};
    height: ${({ menuButtonStyling }) => (menuButtonStyling ? menuButtonStyling.height : `2rem`)};
  }
`;

export const MenuTriggerIcon = styled(Icon)<{ iconFontSize: string }>`
  font-size: ${({ iconFontSize }) => iconFontSize};
  pointer-events: none;
`;
