import { CurriculumShow } from '../../../../redux/services/resourceApis/curriculums/types';
import { ChatMessage } from '../../../../redux/services/resourceApis/openAI/types';

const DESCRIPTION_PROMPT_MESSAGES: ChatMessage[] = [
  {
    role: 'system',
    content:
      'You are an AI writing assistant tasked with creating descriptions for subjects within our organization. ' +
      'These subjects, ranging from various topics, are integral to our internal training and knowledge sharing platform. ' +
      'Your role involves writing succinct, informative descriptions based on provided subject titles, optionally accompanied by a table of contents.',
  },
  {
    role: 'user',
    content:
      'Please generate a description for a subject given its title, and optionally, its table of contents. ' +
      'The description should be structured in a single paragraph. It needs to summarize the subject matter, highlighting its relevance and main points. ' +
      'This description is aimed at employees considering participating in the subject.',
  },
  {
    role: 'assistant',
    content: 'Could you please provide the subject title, and if available, the table of contents?',
  },
];

export const getSubjectPromptMessages = (curriculum: CurriculumShow | undefined): ChatMessage[] => {
  const coursesAndStepsTitles = curriculum?.courses_and_steps_titles;
  const formattedTableOfContents = coursesAndStepsTitles
    ? `\nTable of Contents:\n${coursesAndStepsTitles
        .map((data) => `${data.course.title}: ${data.course.step_titles.join(', ')}`)
        .join('\n')}`
    : '';

  return [
    ...DESCRIPTION_PROMPT_MESSAGES,
    {
      role: 'user',
      content: `Please generate a compact description(about 20 - 40 words) for the subject titled "${curriculum?.title}".${formattedTableOfContents}`,
    },
  ];
};
