import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { DefaultHeaderStyled } from '../../../people/UsersOutline/Table/Styles';
import { StyledSortIcon } from '../../Styles';
import { StyledHeaderCell } from './styles';
import { TableHeaderCellProps } from './types';

const t = initTranslations('reports.all_users_report.table');

const AllUsersReportTableHeaderCell = ({
  column: { columnName, stickyPosition = 'center' },
  activeHeader,
  setActiveHeader,
  sortIcon,
  isSortable,
}: TableHeaderCellProps) => {
  return (
    <DefaultHeaderStyled>
      <StyledHeaderCell
        active={activeHeader}
        className={isSortable ? `sortable-column ${columnName}` : undefined}
        isSortable={isSortable}
        onClick={() => setActiveHeader && setActiveHeader()}
        stickyPosition={stickyPosition}
      >
        {t(`headers.${columnName}`)}
        {sortIcon && <StyledSortIcon name={sortIcon} size='2xs' weight='solid' />}
      </StyledHeaderCell>
    </DefaultHeaderStyled>
  );
};

export default AllUsersReportTableHeaderCell;
