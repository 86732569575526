import React from 'react';

import initTranslations from '../../../../../../../../lib/initTranslations';
import { ESignatureSubscriptionInfo } from '../../../../../../../../redux/services/resourceApis/billing/types';
import { SpaceBetweenWrapper } from '../../../../../shared/styles';
import { StyledP } from '../../../styles';
import { SignaturesUsedRow, TooltipWrapper } from '../styles';

const t = initTranslations('per_user_pricing.billing_page.plan_card.e_signature.tooltip');

type ESignatureTooltipBodyProps = {
  eSignatureSubscriptionInfo: ESignatureSubscriptionInfo;
};

const ESignatureTooltipBody = ({ eSignatureSubscriptionInfo }: ESignatureTooltipBodyProps) => {
  return (
    <TooltipWrapper>
      <SpaceBetweenWrapper>
        <StyledP text={t('signature_included')} />
        <StyledP
          text={t('signature_per_interval', {
            count: eSignatureSubscriptionInfo.signaturesCount,
            interval: eSignatureSubscriptionInfo.billingInterval === 'year' ? 'yr' : 'mo',
          })}
        />
      </SpaceBetweenWrapper>
      <SpaceBetweenWrapper>
        <StyledP text={t('signatures_remaining')} />
        <StyledP text={eSignatureSubscriptionInfo.signaturesRemaining} />
      </SpaceBetweenWrapper>
      <SignaturesUsedRow>
        <StyledP
          text={t('additional_used_this_interval', {
            interval: eSignatureSubscriptionInfo.billingInterval,
          })}
        />
        <StyledP text={eSignatureSubscriptionInfo.signaturesUsed} />
      </SignaturesUsedRow>

      <StyledP text={eSignatureSubscriptionInfo.nextChargeInfo} />
    </TooltipWrapper>
  );
};

export default ESignatureTooltipBody;
