import { Ability, AbilityBuilder } from '@casl/ability';
import {
  Subject as CaslSubject,
  SubjectType as CaslSubjectType,
} from '@casl/ability/dist/types/types';
import { useMemo } from 'react';

import AbilityRule, { ActionType } from '../types/AbilityRule';
import useCurrentUser from './useCurrentUser';

type Subjects = { id: number | string; __typename: string } | string;
type AppAbility = Ability<[ActionType, Subjects]>;

const detectSubjectFromTypeName = (subject: Exclude<CaslSubject, CaslSubjectType>) => {
  return subject.__typename;
};

const buildAbilities = (rules: AbilityRule[]) => {
  const { can, build } = new AbilityBuilder<AppAbility>(Ability);
  rules.forEach(({ action, subject, conditions }) => {
    can(action, subject, conditions);
  });

  return build({ detectSubjectType: detectSubjectFromTypeName });
};

const useCurrentUserAbilities = (): Ability<[ActionType, Subjects]> => {
  const currentUser = useCurrentUser();
  return useMemo(() => buildAbilities(currentUser.abilities), [currentUser.abilities]);
};

export default useCurrentUserAbilities;
