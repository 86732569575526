import useCurrentUser from '../useCurrentUser';

const PERMISSIONS = ['admin', 'billing_admin'];

export const useIsCurriculumOwner = (ownerId: number | null | undefined) => {
  const { id: currentUserId } = useCurrentUser();

  return ownerId === currentUserId;
};

export const useIsAdmin = () => {
  const { permission } = useCurrentUser();

  return PERMISSIONS.slice(0, 2).includes(permission);
};
