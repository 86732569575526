import { BillingPlanName } from '../../../../../../../types/BillingPlanName';

type UpgradeButtonsHoverState = {
  trainButtonIsHovered: boolean;
  scaleButtonIsHovered: boolean;
};

export type Action = { type: BillingPlanName; isHovered: boolean };

export const upgradeButtonsReducer = (
  state: UpgradeButtonsHoverState,
  action: Action
): UpgradeButtonsHoverState => {
  switch (action.type) {
    case 'train': {
      return { ...state, trainButtonIsHovered: action.isHovered };
    }
    case 'scale': {
      return { ...state, scaleButtonIsHovered: action.isHovered };
    }
    default: {
      return state;
    }
  }
};
