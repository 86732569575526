import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import Link from '../../../../../design_system/Link';
import { RestoreButtonInner, RestoreIcon, StyledIcon, StyledTooltip } from './styles';

const t = initTranslations('reports.activity_log');

type ActivityRestoreButtonProps = {
  restorable: boolean;
  setRecoverContentModalOpen: (open: boolean) => void;
  inRecoveryProcess: boolean;
  id: number;
};

const ActivityRestoreButton = ({
  restorable,
  setRecoverContentModalOpen,
  inRecoveryProcess,
  id,
}: ActivityRestoreButtonProps) => {
  const tooltipText = inRecoveryProcess
    ? t('recover_content_tooltip.processing_text')
    : t('recover_content_tooltip.text');

  if (!restorable) return <></>;

  return (
    <>
      <StyledTooltip id={`${id}-recovery-button-tooltip`} text={tooltipText} />
      <div data-for={`${id}-recovery-button-tooltip`} data-tip>
        {inRecoveryProcess ? (
          <StyledIcon name='arrows-rotate' size='sm' spinSpeed='slow' />
        ) : (
          <Link
            behavesAs='button'
            className={`activity-content-recovery-btn activity-content-recovery-btn-${id}`}
            onClick={() => setRecoverContentModalOpen(true)}
            text={
              <RestoreButtonInner>
                <RestoreIcon name='arrow-rotate-left' />
                {t('restore')}
              </RestoreButtonInner>
            }
            underlineBehavior='dynamic'
          />
        )}
      </div>
    </>
  );
};

export default ActivityRestoreButton;
