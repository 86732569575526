import React from 'react';

import { StyledButton } from './styles';

type ButtonProps = {
  autoFocus: boolean;
  displayText: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  selected: boolean;
};

const GroupKindButton = ({ autoFocus, displayText, onClick, selected }: ButtonProps) => {
  const buttonClassName = selected ? 'selected' : '';

  return (
    <StyledButton
      autoFocus={autoFocus}
      className={buttonClassName}
      onClick={onClick}
      selected={selected}
    >
      {displayText}
    </StyledButton>
  );
};

export default GroupKindButton;
