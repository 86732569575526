import React from 'react';

import Link from '../../../../design_system/Link';

interface Props {
  linkUrl: string;
  linkText: string;
  isActive: boolean;
}

const ActivityLinkTemplate = ({ linkText, linkUrl, isActive }: Props) => {
  return isActive ? (
    <Link href={linkUrl} text={linkText} underlineBehavior='dynamic' />
  ) : (
    <b>{linkText}</b>
  );
};

export default ActivityLinkTemplate;
