import { DropdownMenuOption } from '../../../components/application/ContentLibrary/ContentLibraryPage/FilterSection/SetDefaultDropdownMenu/SetDefaultDropdownMenu';

enum SelectLocale {
  English = 'en',
  Spanish = 'es',
  French = 'fr',
  Portuguese = 'pt',
  German = 'de',
}

export type Locales =
  | SelectLocale.English
  | SelectLocale.Spanish
  | SelectLocale.French
  | SelectLocale.Portuguese
  | SelectLocale.German;

export interface LocalesDropdownOption extends Omit<DropdownMenuOption, 'value'> {
  value: Locales;
}

export const LOCALE_OPTIONS: LocalesDropdownOption[] = [
  { id: 0, label: 'English', value: SelectLocale.English },
  { id: 1, label: 'Spanish', value: SelectLocale.Spanish },
  { id: 2, label: 'French', value: SelectLocale.French },
  { id: 3, label: 'Portuguese', value: SelectLocale.Portuguese },
  { id: 4, label: 'German', value: SelectLocale.German },
];
