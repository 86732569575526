import styled from 'styled-components';

export const FooterButtonWrapper = styled.div`
  width: calc(50% - 0.75rem);
`;

export const ReportFiltersFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 1px;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd1} ${constants.spacerLg1}`};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
`;

export const ReportFiltersBody = styled.div`
  padding-inline: ${({ theme: { constants } }) => constants.spacerMd2};
  padding-bottom: 4rem;
`;
