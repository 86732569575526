import React from 'react';
import styled, { css } from 'styled-components';

import initTranslations from '../../../../lib/initTranslations';
import Tabs, { Tab } from '../../../design_system/navigation/Tabs';
import { TeammateModalTab } from './Modal';

const TabsWrapper = styled.div(
  ({ theme: { constants, vars } }) => css`
    border-bottom: ${constants.borderWidthSm} solid ${vars.borderSurface1};
  `
);
const t = initTranslations('teammate_modal');

const MODAL_TABS: Tab[] = [
  {
    name: t('shared.tabs.general'),
    tabId: 'general',
  },
  {
    name: t('shared.tabs.advanced'),
    tabId: 'advanced',
  },
];

type ModalTabsProps = {
  activeTab: TeammateModalTab;
  setActiveTab: (tab: TeammateModalTab) => void;
};

const ModalTabs = ({ activeTab, setActiveTab }: ModalTabsProps) => {
  return (
    <TabsWrapper id='modal-tabs'>
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={MODAL_TABS} />
    </TabsWrapper>
  );
};

export default ModalTabs;
