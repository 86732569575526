import isChromatic from 'chromatic';
import { useEffect, useState } from 'react';

type Props = {
  configureAnimation: () => void;
};

const useMoAnimation = ({ configureAnimation }: Props) => {
  const [animationConfigured, setAnimationConfigured] = useState(false);

  useEffect(() => {
    if (animationConfigured || isChromatic()) return;

    configureAnimation();
    setAnimationConfigured(true);
  }, [animationConfigured, configureAnimation]);
};

export default useMoAnimation;
