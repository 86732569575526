import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { forOwn } from 'lodash';
import { useEffect } from 'react';

import { useFlashNotification } from '../components/FlashNotificationContext';
import { formErrorsFromError } from '../redux/errors/helpers';

interface Props {
  error: FetchBaseQueryError | SerializedError | undefined;
  setFieldError: (field: string, message: string | undefined) => void;
  setStatus: (status: string) => void;
  setSubmitting: (isSubmitting: boolean) => void;
  wrapErrors?: boolean;
}

const useFieldErrorsFromFormik = ({
  error,
  setFieldError,
  setStatus,
  setSubmitting,
  wrapErrors = false,
}: Props) => {
  const { flash } = useFlashNotification();

  useEffect(() => {
    if (!error) return;

    const errorObject = formErrorsFromError(error);

    if (errorObject) {
      forOwn(errorObject, (value, key) => {
        setFieldError(key, wrapErrors ? value.join('\n ') : value.join(', '));
      });
    }

    setStatus('error');
    setSubmitting(false);
  }, [error, flash, setFieldError, setStatus, setSubmitting, wrapErrors]);
};

export default useFieldErrorsFromFormik;
