import { truncate } from 'lodash';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import { CurriculumAssignmentWithAction } from '../../../../../redux/services/resourceApis/curriculumAssignments/types';
import { AccessLevel } from '../../../../../types/SuperShare';
import Icon from '../../../../design_system/display/icons/Icon';
import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';

const t = initTranslations('supershare.content_access_flyout');

const MenuOption = styled.div<{ isDisabled: boolean }>(
  ({ isDisabled, theme: { constants, vars } }) => css`
    width: 261px;
    font-size: 0.889rem;
    line-height: 1.333rem;
    display: flex;
    align-items: center;
    margin: ${isDisabled
      ? `-${constants.spacerSm3} -${constants.spacerMd2}`
      : `0 -${constants.spacerMd2}`};
    color: ${isDisabled ? vars.textSubdued : vars.textDefault};
    background-color: ${isDisabled ? vars.foundationHover : 'transparent'};
    padding: ${isDisabled ? `${constants.spacerSm3} 0` : 0};
    cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
  `
);

const CheckmarkWrapper = styled.div`
  width: ${({ theme: { constants } }) => constants.spacerLg3};
  padding: 0 ${({ theme: { constants } }) => constants.spacerMd2};
`;

const OptionWrapper = styled.div`
  padding-right: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const Level = styled.div<{ level: AccessLevel; isDisabled: boolean }>(
  ({ isDisabled, level, theme: { constants, vars } }) => css`
    display: flex;
    gap: ${constants.spacerSm2};
    color: ${() => {
      switch (level) {
        case 'manage':
          return isDisabled ? vars.textSubdued : vars.textDefault;
        case 'remove':
          return vars.stateError;
        default:
          return vars.textDefault;
      }
    }};
    font-weight: ${constants.fontMedium};
    ${TruncatedText({})};
  `
);

const Description = styled.div`
  font-weight: ${({ theme: { constants } }) => constants.fontLight};
`;

export type ContentAccessFlyoutMenuOptionData = {
  level: AccessLevel;
  description: string;
};

type ContentAccessFlyoutMenuOptionProps = ContentAccessFlyoutMenuOptionData & {
  assignmentWithAction: CurriculumAssignmentWithAction;
  isAdmin: boolean;
  isSelected: boolean;
};

const ContentAccessFlyoutMenuOption = ({
  level,
  description,
  assignmentWithAction,
  isAdmin,
  isSelected,
}: ContentAccessFlyoutMenuOptionProps) => {
  const { curriculumAssignable } = assignmentWithAction;
  const isDisabled = useMemo(() => {
    return isAdmin && level === 'manage';
  }, [isAdmin, level]);

  return (
    <MenuOption isDisabled={isDisabled}>
      <CheckmarkWrapper>
        {isSelected && <Icon name='check' size='2xs' weight='solid' />}
      </CheckmarkWrapper>
      <OptionWrapper>
        <Level isDisabled={isDisabled} level={level}>
          {t(`access_level.${level}`)}
          {level === 'remove' ? ` ${truncate(curriculumAssignable.name, { length: 13 })}` : ''}
        </Level>
        <Description>{description}</Description>
      </OptionWrapper>
    </MenuOption>
  );
};

export default ContentAccessFlyoutMenuOption;
