import styled from 'styled-components';

import { fontMd3 } from '../../../../../styled/TypeSystem';

export const ResponsibilityNameWrapper = styled.div`
  max-width: 100%;
  width: fit-content;
  height: fit-content;
  overflow-wrap: break-word;
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontMd3};
`;
