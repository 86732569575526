import styled from 'styled-components';

import { mediaBreakpointPxMd } from '../../../../styled/Breakpoint';
import Scrollbar from '../../../../styled/Scrollbar';
import { fontSm4, fontSm5 } from '../../../../styled/TypeSystem';
import { HeaderCell } from '../../../people/UsersOutline/Table/Styles';
import { StyledTextProps } from '../../../people/UsersOutline/Table/TableTypes';
import { StyledRowProps } from './types';

export const TableStyled = styled.table`
  width: 100%;
  border-collapse: collapse;
  ${fontSm5};
`;

export const TableWrapper = styled.div`
  position: relative;
  overflow: auto;
  white-space: nowrap;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  ${Scrollbar};

  @media (min-width: ${mediaBreakpointPxMd}) {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }
`;

export const HeaderRow = styled.tr`
  background: ${({ theme: { vars } }) => vars.foundationBase1};
`;

export const BodyRow = styled.tr<StyledRowProps>`
  background: ${({ theme: { vars }, isSubTableRow }) =>
    isSubTableRow ? vars.foundationBase2 : vars.foundationSurface1};

  &:hover {
    background: ${({ theme: { vars }, isSubTableRow }) =>
      isSubTableRow ? vars.foundationBase2 : vars.foundationBase1};
  }
`;

export const StyledHeaderCell = styled(HeaderCell)<StyledTextProps>`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  justify-content: ${({ contentAlignment }) => contentAlignment};
  ${fontSm4}
`;

export const StyledColumn = styled.td<StyledTextProps>`
  height: 3.5rem;
  padding: 0 ${({ theme: { constants } }) => constants.spacerMd3};
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  text-align: ${({ contentAlignment }) => (contentAlignment ? contentAlignment : 'start')};

  &:first-child {
    max-width: 15rem;
  }
`;
