import React from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import { AddonsSectionList } from '../../../AddonsSection/styles';
import { AddonsSectionProps } from '../AddonsSectionFactory/AddonsSectionFactory';
import ManageableAddonItem from './ManageableAddonItem/ManageableAddonItem';

const t = initTranslations('billing.addons_section');

const ManageableAddonsSection = ({ addonItems, billingInterval = 'month' }: AddonsSectionProps) => {
  const interval = billingInterval === 'year' ? t('per_year') : t('per_month');

  return (
    <AddonsSectionList id='manageable-addons-section'>
      {addonItems.map(({ name, price, kind }, index) => (
        <ManageableAddonItem
          interval={interval}
          key={`${name.split(' ').join('-').toLowerCase()}-${index}`}
          kind={kind}
          name={name}
          price={price}
        />
      ))}
    </AddonsSectionList>
  );
};

export default ManageableAddonsSection;
