import { snakeCase } from 'lodash';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { useEditorToolbarContext } from '../../../../../../contexts/EditorToolbarContext';
import { RegisteredMenuId } from '../../../../../../lib/idRegistry';
import initTranslations from '../../../../../../lib/initTranslations';
import useActiveMenuHandler from '../../../../publicApplication/utils/useActiveMenuHandler';
import useRecentEmbedlyItems from '../../../hooks/useRecentEmbedlyItems';
import { EmbedlyMenuItemProps, EmbedlyMenuItemSource } from '../EmbedlyMenu/EmbedlyMenu';
import EmbedlyMenuItem from '../EmbedlyMenuItem/EmbedlyMenuItem';

const EmbedlyMenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmbedlySource = styled.div`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export type Props = {
  source: EmbedlyMenuItemSource;
  items: EmbedlyMenuItemProps[];
};

const t = initTranslations('editor');

type handleOnClickProps = {
  item: EmbedlyMenuItemProps;
  setActiveMenuId: React.Dispatch<React.SetStateAction<RegisteredMenuId | null>>;
  setSelectedEmbedMenuItem: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const handleFlyout = ({ item, setActiveMenuId, setSelectedEmbedMenuItem }: handleOnClickProps) => {
  switch (item.source) {
    case 'file':
      return () => {
        setActiveMenuId('file-flyout');
        setSelectedEmbedMenuItem(item.source);
      };
    case 'video':
      return () => {
        setSelectedEmbedMenuItem(item.id);
        setActiveMenuId('video-flyout');
      };
    case 'generic':
      return () => {
        setSelectedEmbedMenuItem(item.source);
        setActiveMenuId('embed-link-flyout');
      };
    case 'trainual':
      return () => {
        setSelectedEmbedMenuItem(item.source);
        setActiveMenuId('trainual-flyout');
      };
    case 'trainual_capture':
      return () => {
        setActiveMenuId('trainual-capture-flyout');
        setSelectedEmbedMenuItem(item.source);
      };
    case 'embed-iframe':
      return () => {
        setActiveMenuId('embed-iframe-flyout');
        setSelectedEmbedMenuItem(item.source);
      };
    case 'maps':
    case 'engagement':
    case 'socialMedia':
    case 'design':
    case 'project':
    case 'development':
      return () => {
        setSelectedEmbedMenuItem(item.title);
        setActiveMenuId('embed-link-flyout');
      };
  }
};

const EmbedlyMenuSection = ({ source, items }: Props) => {
  const { setActiveMenuId } = useActiveMenuHandler({ menuId: 'embedly-flyout' });
  const { setSelectedEmbedMenuItem } = useEditorToolbarContext();
  const { updateRecentEmbedlyItems } = useRecentEmbedlyItems();

  const handleClick = useCallback(
    (item: EmbedlyMenuItemProps) => {
      const onClickAction = handleFlyout({
        item,
        setActiveMenuId,
        setSelectedEmbedMenuItem,
      });

      onClickAction();
      updateRecentEmbedlyItems(item);
    },
    [setActiveMenuId, setSelectedEmbedMenuItem, updateRecentEmbedlyItems]
  );

  return (
    <EmbedlyMenuItemContainer>
      <EmbedlySource>{t(`embedly.${snakeCase(source)}`)}</EmbedlySource>
      {items.map((item) => {
        return <EmbedlyMenuItem item={item} key={item.title} onClick={() => handleClick(item)} />;
      })}
    </EmbedlyMenuItemContainer>
  );
};

export default EmbedlyMenuSection;
