import styled from 'styled-components';

import { BillingPlanBrandColor } from '../../../../../../types/SimplePricing';
import {
  mediaBreakpointPxLg,
  mediaBreakpointPxMd,
  mediaBreakpointPxXl,
} from '../../../../../styled/Breakpoint';

export const CardsWrapper = styled.div<{ isSimplePricingV3: boolean }>`
  display: flex;
  flex-flow: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerLg1};

  @media (min-width: ${mediaBreakpointPxLg}) {
    gap: ${({ theme: { constants } }) => constants.spacerLg1};
  }

  @media (min-width: ${mediaBreakpointPxMd}) {
    ${({ isSimplePricingV3 }) => `${isSimplePricingV3 && 'flex-wrap: wrap'}`};
    flex-direction: row;
  }
`;

export const PlanCardWrapper = styled.div<{
  isHovered: boolean;
  planBrandColor: BillingPlanBrandColor;
  isActive: boolean;
  isSimplePricingV3: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 17rem;
  padding: ${({ theme: { constants } }) => constants.spacerMd3};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};

  ${({ isHovered, planBrandColor, isActive, theme: { constants } }) =>
    (isHovered || isActive) && {
      outline: `${constants.borderWidth2xl} solid ${planBrandColor.color}`,
      backgroundColor: `${isActive && planBrandColor.backgroundColor}`,
    }};

  @media (min-width: ${mediaBreakpointPxMd}) {
    ${({ isSimplePricingV3 }) => `${isSimplePricingV3 && 'max-width: calc((100% - 2rem) / 2)'}`};
  }

  @media (min-width: ${mediaBreakpointPxLg}) {
    padding: ${({ theme: { constants } }) => `${constants.spacerLg1} ${constants.spacerLg2}`};
  }

  @media (min-width: ${mediaBreakpointPxXl}) {
    ${({ isSimplePricingV3 }) => `${isSimplePricingV3 && 'max-width: calc((100% - 6rem) / 4)'}`};
  }
`;
