import { ResponsibiliableResponse, ResponsibilitiesListTag } from './types';

type TResponsibilitiesListTagsParams = {
  result?: ResponsibiliableResponse;
  invalidateDelegationPlannerTags?: boolean;
};

export const responsibilitiesListTags = ({
  result,
  invalidateDelegationPlannerTags,
}: TResponsibilitiesListTagsParams): ResponsibilitiesListTag[] => {
  let tags: ResponsibilitiesListTag[] = [{ type: 'Responsibility', id: 'LIST_RESPONSIBILITIES' }];

  if (result) {
    const { responsibiliable_id, responsibiliable_type } = result;
    tags = [
      {
        type: 'Responsibility',
        id: `LIST_RESPONSIBILITIES_${responsibiliable_id}_${responsibiliable_type}`,
      },
    ];

    if (invalidateDelegationPlannerTags) tags.push({ type: 'BoardColumn', id: 'LIST' });
  }
  return tags;
};
