import React from 'react';

import { Activity } from '../types';
import CommonTemplate, { AssignedResponsibilityCommonTemplateProps } from './CommonTemplate';

interface AssignedResponsibilityActivitiesProps {
  'assigned_responsibility.create': React.FC<AssignedResponsibilityCommonTemplateProps>;
  'assigned_responsibility.update': React.FC<AssignedResponsibilityCommonTemplateProps>;
  'assigned_responsibility.destroy': React.FC<AssignedResponsibilityCommonTemplateProps>;
}

const ACTIVITY_KEYS: AssignedResponsibilityActivitiesProps = {
  'assigned_responsibility.create': CommonTemplate,
  'assigned_responsibility.update': CommonTemplate,
  'assigned_responsibility.destroy': CommonTemplate,
};

interface AssignedResponsibilitiesActivity extends Omit<Activity, 'parameters'> {
  parameters: { responsibiliable_type: string; trackable_name: string };
}

interface AssignedResponsibilityCardProps {
  activity: AssignedResponsibilitiesActivity;
}

const AssignedResponsibilityActivitiesDetails = ({ activity }: AssignedResponsibilityCardProps) => {
  const ActivityMessage =
    ACTIVITY_KEYS[activity.key as keyof AssignedResponsibilityActivitiesProps];

  return (
    <ActivityMessage
      activityKey={activity.key}
      parameters={activity.parameters}
      recipient={activity.recipient}
    />
  );
};

export default AssignedResponsibilityActivitiesDetails;
