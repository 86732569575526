import DOMPurify from 'dompurify';
import React, { useCallback } from 'react';

import { useEditorContext } from '../../../../contexts/EditorContext';
import { useEditorCourseContext } from '../../../../contexts/EditorCourseContext';
import { insertEditorContent } from '../../editor/lib/insertEditorContent';
import ComposeStepModal, { Props } from '../ComposeStepModal/ComposeStepModal';

const EditorComposeStepModal = (props: Props) => {
  const { editor } = useEditorContext();
  const { stepId } = useEditorCourseContext();

  const onSuccess = useCallback(
    (generatedAiContent: string) => {
      // Strip newlines from generated content to prevent extra spacing into the editor
      const generatedContent = generatedAiContent.replace(/\n/g, '');

      insertEditorContent({
        editor,
        content: DOMPurify.sanitize(generatedContent),
        initialFocus: 'end',
      });
    },
    [editor]
  );

  return <ComposeStepModal {...props} metadata={{ stepId }} onSuccess={onSuccess} />;
};

export default EditorComposeStepModal;
