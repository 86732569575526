import React from 'react';

import initTranslations from '../../../../../../../../lib/initTranslations';
import { StyledP } from '../../../styles';

const t = initTranslations('per_user_pricing.billing_page.plan_card.legacy_plan_info_tooltip');

const LegacyPlanInfoTooltipBody = () => {
  return (
    <>
      <StyledP text={t('plan_no_longer_supported')} />
      <br />
      <StyledP text={t('select_to_update')} />
    </>
  );
};

export default LegacyPlanInfoTooltipBody;
