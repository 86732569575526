import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import AddonItem from './AddonItem/AddonItem';
import { AddonsSectionList } from './styles';
import { AddonsSectionProps } from './types';

const t = initTranslations('billing.addons_section');

const AddonsSection = ({
  addonItems,
  billingInterval = 'month',
  divideItems,
  primaryColor,
}: AddonsSectionProps) => {
  const interval = billingInterval === 'year' ? t('per_year') : t('per_month');
  const addonItemsLength = addonItems.length;

  return (
    <AddonsSectionList id='addons-section'>
      {addonItems.map(({ name, price, status, kind }, index) => (
        <AddonItem
          divideItems={divideItems}
          interval={interval}
          isLastItem={index === addonItemsLength - 1}
          key={`${name.split(' ').join('-').toLowerCase()}-${index}`}
          kind={kind}
          name={name}
          price={price}
          primaryColor={primaryColor}
          status={status}
        />
      ))}
    </AddonsSectionList>
  );
};

export default AddonsSection;
