import React, { useEffect, useRef } from 'react';

import useKeyPress from '../../../../../../hooks/useKeyPress';
import { getQuickSearchDropdownSectionData } from '../../../../../../lib/search/getQuickSearchDropdownSectionData';
import { QuickSearchDropdownProps, QuickSearchKind } from '../../../../../../types/QuickSearch';
import QuickSearchDropdownItem from '../QuickSearchDropdownItem/QuickSearchDropdownItem';
import { QuickSearchDropdownDivider, QuickSearchDropdownWrapper } from './styles';

const QuickSearchDropdown = ({
  searchValue,
  quickSearchResultData,
  isLoading,
  searchInputRef,
}: QuickSearchDropdownProps) => {
  const activeLinkIndexRef = useRef(-1);
  const quickSearchResultLinksRef = useRef<HTMLAnchorElement[] | []>([]);
  const quickSearchDropdownSectionKeys = Object.keys(getQuickSearchDropdownSectionData());

  const updateActiveLink = (newActiveLinkIndex: number) => {
    // Remove 'selected' class from currently selected link
    document.querySelector('#quick-search-dropdown .selected')?.classList.remove('selected');

    activeLinkIndexRef.current = newActiveLinkIndex;

    if (newActiveLinkIndex >= 0) {
      const activeLink = quickSearchResultLinksRef.current[newActiveLinkIndex];
      activeLink?.classList.add('selected');
      searchInputRef.current?.blur();
    } else {
      searchInputRef.current?.focus();
    }
  };

  const navigateItems = (direction: 'up' | 'down') => {
    let newIndex;
    const itemCount = quickSearchResultLinksRef.current.length;
    const currentIndex = activeLinkIndexRef.current; // "-1" is the initial state when the search field is focused

    if (direction === 'down') {
      newIndex =
        currentIndex + 1 < itemCount
          ? currentIndex + 1 // Set next item as active
          : -1; // We should set focus to the search field when pressing "down" on the last item;
    } else {
      newIndex =
        currentIndex < 0
          ? itemCount - 1 // Set last item as active when pressing "up" for the first time
          : currentIndex < itemCount
          ? currentIndex - 1 // Set previous item as active
          : -1; // We should set focus to the search field when pressing "down" on the last item;
    }

    updateActiveLink(newIndex);
  };

  useKeyPress('ArrowUp', () => navigateItems('up'));
  useKeyPress('ArrowDown', () => navigateItems('down'));
  useKeyPress('Enter', () => {
    const activeLink = quickSearchResultLinksRef.current[activeLinkIndexRef.current ?? 0];
    activeLink?.click();
  });

  // We should reset refs when the component unmounts or we get new items
  useEffect(() => {
    return () => {
      quickSearchResultLinksRef.current = [];
      activeLinkIndexRef.current = -1;
    };
  }, [isLoading]);

  return (
    <QuickSearchDropdownWrapper id='quick-search-dropdown' role='menu'>
      <QuickSearchDropdownDivider />
      {quickSearchDropdownSectionKeys.map((kind: QuickSearchKind) => (
        <QuickSearchDropdownItem
          key={kind}
          kind={kind}
          quickSearchResultData={quickSearchResultData}
          quickSearchResultLinksRef={quickSearchResultLinksRef}
          searchValue={searchValue}
        />
      ))}
    </QuickSearchDropdownWrapper>
  );
};

export default QuickSearchDropdown;
