import React, { FC } from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import { StyledIcon } from '../../../../../DelegationPlanner/DurationTimeSetter/styles';
import { SectionWrapper } from '../../styles';
import { ResponsibilityDurationWrapper, StyledTitle } from './styles';
import { ResponsibilityDurationProps } from './types';

const t = initTranslations('delegation_planner.modals.responsibility_details_modal.time_setter');

const ResponsibilityDuration: FC<ResponsibilityDurationProps> = ({ workingHours }) => {
  return (
    <SectionWrapper>
      <StyledTitle>{t('time_commitment')}</StyledTitle>
      <ResponsibilityDurationWrapper>
        <StyledIcon name='clock' size='2xs' weight='regular' />
        {workingHours.workingTime || '-'}h/{workingHours.frequency || 'week'}
      </ResponsibilityDurationWrapper>
    </SectionWrapper>
  );
};

export default ResponsibilityDuration;
