import React from 'react';
import styled from 'styled-components';

import { useAccountTerminology } from '../../../../components/AccountTerminologyProvider';
import usePublicConfigs from '../../../../hooks/usePublicConfigs';
import initTranslations from '../../../../lib/initTranslations';
import IconButton from '../../../design_system/buttons/IconButton';
import { mediaBreakpointPxMd } from '../../../styled/Breakpoint';

const HelpIconButton = styled(IconButton)`
  display: none;
  padding: ${(props) => props.theme.constants.spacerSm2};

  @media (min-width: ${mediaBreakpointPxMd}) {
    display: flex;
  }
`;

const t = initTranslations('aria');

const HelpIcon = () => {
  const { configs } = usePublicConfigs();
  const helpPageUrl = configs['HELP_PAGE_URL'];
  const { productTerm } = useAccountTerminology();

  return (
    <HelpIconButton
      ariaLabel={t('aria_label_help_and_training', { productTerm })}
      id='top-nav-help'
      name='question-circle'
      onClick={() => window.open(helpPageUrl, '_blank')}
    />
  );
};

export default HelpIcon;
