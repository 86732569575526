import React from 'react';

import { useTeammateModal } from '../../../../../contexts/TeammateContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import { SendInviteOption } from '../InterfaceAndTypes';
import TeammateModal from '../TeammateModal';
import TeammateConfirmationModal from './ConfirmationModal';

type Props = {
  isDirectoryPage?: boolean;
  showInviteConfirmation?: boolean;
  showAddConfirmation?: boolean;
};

const AddTeammateWithConfirmation = ({
  isDirectoryPage = false,
  showAddConfirmation = false,
  showInviteConfirmation = false,
}: Props) => {
  const {
    splitFeatures: { perUserPricingEnabled },
  } = useCurrentAccount();

  const {
    teammateConfirmationModalVisible,
    setTeammateConfirmationModalVisible,
    setTeammateState,
  } = useTeammateModal();

  const showConfirmationModal = (sendInvite: SendInviteOption) => {
    const shouldSendInviteLater = sendInvite === 'later';
    const shouldShowInviteConfirmation = showInviteConfirmation && !shouldSendInviteLater;
    const shouldShowAddConfirmation =
      (showAddConfirmation || perUserPricingEnabled) && shouldSendInviteLater;

    if (shouldShowInviteConfirmation || shouldShowAddConfirmation) {
      setTeammateConfirmationModalVisible(true);
    }
  };

  return (
    <>
      <TeammateModal action='add' onSuccess={showConfirmationModal} />
      {teammateConfirmationModalVisible && !isDirectoryPage && (
        <TeammateConfirmationModal
          closeModal={() => {
            setTeammateConfirmationModalVisible(false);
            setTeammateState(null);
          }}
          setShowTeammateConfirmationModal={setTeammateConfirmationModalVisible}
        />
      )}
    </>
  );
};

export default AddTeammateWithConfirmation;
