import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { routes } from '../../../publicApplication/applicationRouter';
import { TextFragment } from '../styles';
import { AssociatedCurriculumParams } from '../types';

const t = initTranslations('reports.activity_log.request');

export interface RequestCreateProps {
  activityKey?: string;
  recipient?: object;
  trackable: { associated_params: AssociatedCurriculumParams };
}

const Create = ({ trackable }: RequestCreateProps) => {
  const { slug } = useCurrentAccount();
  const { curriculum_id: id, curriculum_title, is_active_curriculum } = trackable.associated_params;

  return (
    <>
      <TextFragment>{t('create')}</TextFragment>
      <SourceBadge
        readOnly={!is_active_curriculum}
        sourceName='curriculum'
        sourceRoute={routes.curriculumEdit({ slug, id })}
        sourceText={curriculum_title}
      />
    </>
  );
};

export default Create;
