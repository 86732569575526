import React from 'react';

import { usePlanBrandColors } from '../../../../../../../hooks/billing/usePlanBrandColors';
import { usePlanTitle } from '../../../../../../../hooks/billing/usePlanTitle';
import useCurrentAccount from '../../../../../../../hooks/useCurrentAccount';
import {
  RedirectToBillingOverlayProps,
  useRedirectToBillingOverlay,
} from '../../../../../../../hooks/useRedirectToBillingOverlay';
import Recommended from '../../../../../../../images/billing/recommended.svg';
import initTranslations from '../../../../../../../lib/initTranslations';
import { BillingInterval } from '../../../../../../../types/BillingInterval';
import { BillingPlanName } from '../../../../../../../types/BillingPlanName';
import { PlanCardTitle } from '../../../../per_user_pricing/shared/styles';
import { getFormattedPlanPricePerUser } from '../../../../per_user_pricing/utils/getFormattedPlanPricePerUser';
import { PlanPrice } from '../../../styles';
import {
  ActivePlanIndicator,
  AvailableSeatsText,
  PlanDetailsWrapper,
  UpgradePlanButton,
} from '../styles';
import { Action } from '../utils/upgradeButtonsReducer';
import { CardTitleWrapper, RecommendedImg } from './styles';

const t = initTranslations('per_user_pricing.billing_page.plans');

type PlanDetailsProps = {
  planName: BillingPlanName;
  seatsIncluded: number;
  interval: BillingInterval;
  isTrialingOrTrialEnded: boolean;
  unitPrice: number;
  dispatch?: React.Dispatch<React.SetStateAction<Action>>;
  isComparePlanOverlay?: boolean;
};

const PlanDetails = ({
  planName,
  seatsIncluded,
  interval,
  isTrialingOrTrialEnded,
  unitPrice,
  dispatch,
  isComparePlanOverlay = false,
}: PlanDetailsProps) => {
  const { accountPlan, onLegacyPlan, onHoldPlan, status } = useCurrentAccount();
  const navigateToBillingOverlay = useRedirectToBillingOverlay();
  const isLegacyOrHoldPlan = onLegacyPlan || onHoldPlan;
  const isTrainPlan = planName === 'train';
  const isActive = accountPlan === planName && !isTrialingOrTrialEnded && !onLegacyPlan;
  const isPastDue = status === 'past_due';
  const planBrandColor = usePlanBrandColors({ name: planName }).color;
  const planTitle = usePlanTitle({ name: planName });

  const getUpgradeButtonText = () => {
    if (isTrialingOrTrialEnded) {
      return t('get_this_plan');
    } else if (isTrainPlan && !isLegacyOrHoldPlan) {
      return t('downgrade_plan');
    } else {
      return t('upgrade_plan');
    }
  };

  const getBillingOverlayParams = (
    billingPlanName: BillingPlanName,
    interval: BillingInterval,
    quantity: number
  ): RedirectToBillingOverlayProps => {
    const isTrainPlan = billingPlanName === 'train';

    if (isTrainPlan && !isLegacyOrHoldPlan && !isTrialingOrTrialEnded) {
      return { type: 'downgrade', plan: billingPlanName, interval };
    } else if (isTrialingOrTrialEnded) {
      return { type: 'configure-plan', plan: billingPlanName, interval };
    } else if (onHoldPlan) {
      return { type: 'upgrade', plan: billingPlanName, interval, quantity };
    } else {
      return { type: 'upgrade', plan: billingPlanName, interval };
    }
  };

  return (
    <PlanDetailsWrapper isComparePlanOverlay={isComparePlanOverlay}>
      {isComparePlanOverlay ? (
        <CardTitleWrapper>
          <PlanCardTitle planBrandColor={planBrandColor} text={planTitle} />
          {!isTrainPlan && isTrialingOrTrialEnded && (
            <RecommendedImg alt={t('alt_recommended')} src={Recommended} />
          )}
        </CardTitleWrapper>
      ) : (
        <PlanCardTitle planBrandColor={planBrandColor} text={planTitle} />
      )}
      <PlanPrice text={getFormattedPlanPricePerUser(unitPrice)} />
      <AvailableSeatsText>
        {t('starting_at_count_seats', { count: seatsIncluded })}
      </AvailableSeatsText>
      {isActive ? (
        <ActivePlanIndicator>{t('active_plan')}</ActivePlanIndicator>
      ) : (
        <UpgradePlanButton
          buttonType='brand'
          disabled={isPastDue}
          id={`upgrade-to-${planName}-billing-plan`}
          onClick={() => {
            navigateToBillingOverlay(getBillingOverlayParams(planName, interval, seatsIncluded));
          }}
          {...(dispatch && { onMouseEnter: () => dispatch({ type: planName, isHovered: true }) })}
          {...(dispatch && { onMouseLeave: () => dispatch({ type: planName, isHovered: false }) })}
          text={getUpgradeButtonText()}
        />
      )}
    </PlanDetailsWrapper>
  );
};

export default PlanDetails;
