import { GoogleOAuthProvider } from '@react-oauth/google';
import { Formik, FormikErrors } from 'formik';
import React, { useCallback } from 'react';

import usePrivateConfigs from '../../../../../hooks/usePrivateConfigs';
import isValidGoogleDocsUrl from '../../../../../lib/document_import/isValidGoogleDocsUrl';
import initTranslations from '../../../../../lib/initTranslations';
import DocumentImportModalInner from './DocumentImportModalInner';
import { FormValues } from './types';

const t = initTranslations('document_import_modal');

const ImportFromDocumentModal = () => {
  const { configs } = usePrivateConfigs();
  const initialValues: FormValues = {
    documentLink: '',
    currentFormStep: '',
  };

  const validateForm = useCallback((values: FormValues) => {
    const { documentLink } = values;
    const errors: FormikErrors<FormValues> = {};
    if (!isValidGoogleDocsUrl(documentLink)) {
      errors.documentLink = t('errors.invalid_link');
    }
    return errors;
  }, []);

  return (
    <GoogleOAuthProvider clientId={configs['GOOGLE_OAUTH_CLIENT_ID']}>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {
          /* API request occurs in `/DocumentImportModalInner.tsx` */
        }}
        validate={validateForm}
        validateOnMount
      >
        <DocumentImportModalInner />
      </Formik>
    </GoogleOAuthProvider>
  );
};

export default ImportFromDocumentModal;
