import React from 'react';
import { Slide, toast } from 'react-toastify';

import usePublicConfigs from '../../../../hooks/usePublicConfigs';
import { useFlashNotification } from '../../../FlashNotificationContext';
import { ToastNotificationWithStructuredContentContainer } from './toastNotificationWithStructuredContentContainerStyles';

const ToastNotification = () => {
  const { containerId, flashLimit } = useFlashNotification();
  const { configs } = usePublicConfigs();
  const autoClose = Number(configs['REACT_TOASTIFY_AUTOCLOSE_DELAY']);

  return (
    <ToastNotificationWithStructuredContentContainer
      autoClose={autoClose}
      closeButton
      closeOnClick={false}
      containerId={containerId}
      enableMultiContainer
      hideProgressBar
      limit={flashLimit}
      pauseOnFocusLoss={false}
      pauseOnHover
      position={toast.POSITION.TOP_RIGHT}
      transition={Slide}
    />
  );
};

export default ToastNotification;
