import React, { ReactElement, ReactNode, createContext, useContext } from 'react';

import { GroupWithAssociations } from '../redux/services/resourceApis/groups/types';

type Props = {
  children: ReactNode;
  group: GroupWithAssociations;
};

const GroupContext = createContext({} as GroupWithAssociations);
export const useGroup = () => useContext(GroupContext);

export const GroupProvider = ({ children, group }: Props): ReactElement => {
  return <GroupContext.Provider value={group}>{children}</GroupContext.Provider>;
};
