import { SimplePricingBillingPlanName } from '../../types/BillingPlanName';
import { LegacyOrganizationSize, OrganizationSize } from '../../types/OrganizationSize';
import useCurrentAccount from '../useCurrentAccount';

export function useGetTrialDefaultPlan(): SimplePricingBillingPlanName {
  const { employeeSize } = useCurrentAccount();

  switch (employeeSize as OrganizationSize & LegacyOrganizationSize) {
    case '10 or fewer':
    case '11 – 24':
      return 'small';
    case '25 – 50':
      return 'medium';
    case '51 – 100':
      return 'growth';
    case '101 – 250':
    case '251 – 500':
    case '251 – 1,000':
    case '501 – 1,000':
    case '1,001 – 5,000':
    case '5,001+':
      return 'custom';
    default:
      return 'medium';
  }
}
