import React from 'react';
import styled, { keyframes } from 'styled-components';

// Define keyframes for animations
const stroke = keyframes`
  100% {
    stroke-dashoffset: 0;
  }
`;

const scale = keyframes`
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
`;

const fillSuccess = (checkMarkColor: string) => keyframes`
  100% {
    box-shadow: inset 0 0 0 75px ${checkMarkColor};// Adjust size and color as needed
  }
`;

const fillError = (stateError: string) => keyframes`
  100% {
    box-shadow: inset 0 0 0 75px ${stateError}; // Adjust size and color as needed
  }
`;

// Styled components
const Checkmark = styled.svg`
  width: 1rem;
  height: ${({ theme: { constants } }) => constants.height2xs};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  display: block;
  stroke-width: 5px;
  stroke: white;
  stroke-miterlimit: 10;

  &.success {
    box-shadow: inset 0 0 0 ${({ theme: { vars } }) => vars.stateSuccess};
    animation: ${({ theme: { vars } }) => fillSuccess(vars.stateSuccess)} 0.4s ease-in-out 0.4s
        forwards,
      ${scale} 0.3s ease-in-out 0.9s both;
  }
  &.error {
    box-shadow: inset 0 0 0 ${({ theme: { vars } }) => vars.stateError};
    animation: ${({ theme: { vars } }) => fillError(vars.stateError)} 0.4s ease-in-out 0.4s forwards,
      ${scale} 0.3s ease-in-out 0.9s both;
  }
`;

const CheckmarkCircle = styled.circle<{ success: boolean }>`
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 5px;
  stroke-miterlimit: 10;
  stroke: ${({ theme: { vars }, success }) => (success ? vars.stateSuccess : vars.stateError)};
  animation: ${stroke} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
`;

const CheckmarkCheck = styled.path`
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: ${stroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.9s forwards;
`;

export type Props = {
  success?: boolean;
  checkMarkColor?: string;
};

const AnimatedCheckmark = ({ success = true }: Props) => {
  if (!success)
    return (
      <Checkmark className='checkmark error' viewBox='0 0 52 52' xmlns='http://www.w3.org/2000/svg'>
        <CheckmarkCircle
          className='checkmark_circle_error'
          cx='26'
          cy='26'
          fill='none'
          r='25'
          success={success}
        />
        <CheckmarkCheck
          className='checkmark_check'
          d='M16 16 36 36 M36 16 16 36'
          fill='none'
          strokeLinecap='round'
        />
      </Checkmark>
    );

  return (
    <Checkmark className='checkmark success' viewBox='0 0 52 52' xmlns='http://www.w3.org/2000/svg'>
      <CheckmarkCircle
        className='checkmark_circle_success'
        cx='26'
        cy='26'
        fill='none'
        r='25'
        success={success}
      />
      <CheckmarkCheck
        className='checkmark_check'
        d='M14.1 27.2l7.1 7.2 16.7-16.8'
        fill='none'
        strokeLinecap='round'
      />
    </Checkmark>
  );
};

export default AnimatedCheckmark;
