import useCurrentAccount from './useCurrentAccount';

export const useDisableNavigationItem = () => {
  const {
    status,
    splitFeatures: { fullScreenManageTrialPlanOverlayEnabled },
    onHoldPlan,
  } = useCurrentAccount();
  const isTrialEnded = status === 'trial_ended';
  const isDisabledItemTrialEndedCondition = fullScreenManageTrialPlanOverlayEnabled && isTrialEnded;

  return {
    isDisabledItemOnHoldCondition: onHoldPlan,
    isDisabledItemTrialEndedCondition,
    isDisabledNavItem: isDisabledItemTrialEndedCondition || onHoldPlan,
  };
};
