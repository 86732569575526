import styled from 'styled-components';

import { fontSm3 } from '../../../../../styled/TypeSystem';

export const ReportHeadTeamsOrGroup = styled.div`
  display: flex;
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontSm3};
`;

export const ReportHeadTeamsOrGroupInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const ReportHeadTeamsOrGroupTitle = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const ProgressBarWrapper = styled.div`
  span:first-child {
    color: ${({ theme: { vars } }) => vars.textDefault};
  }
`;
