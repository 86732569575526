import styled from 'styled-components';

import Icon from '../../../../../design_system/display/icons/Icon';
import { TruncatedText } from '../../../../../design_system/Triage/TruncatedText';
import { fontMd1 } from '../../../../../styled/TypeSystem';
import RouterLink from '../../../../publicApplication/RouterLink';

export const QuickSearchDropdownItemWrapper = styled.li`
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerSm2}`};

  &:last-of-type {
    margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  }
`;

export const QuickSearchDropdownIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textSubdued};
`;

export const QuickSearchArrowIcon = styled(Icon)`
  margin-left: auto;
  opacity: 0;
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
`;

export const QuickSearchDropdownSection = styled(RouterLink)`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  color: ${({ theme: { vars } }) => vars.textHover};
  cursor: pointer;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};

  &:hover,
  &.selected {
    background: ${({ theme: { vars } }) => vars.accentSubdued1};

    > ${QuickSearchDropdownIcon} {
      color: ${({ theme: { vars } }) => vars.textHover};
    }

    > ${QuickSearchArrowIcon} {
      opacity: 1;
    }
  }
`;

export const QuickSearchLinkText = styled.span`
  display: block;

  ${fontMd1};
  ${TruncatedText({})};
`;

export const QuickSearchResultCardWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};

    &:first-of-type {
      margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
