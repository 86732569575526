import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import { routes } from '../../../publicApplication/applicationRouter';
import ActivityLinkTemplate from '../ActivityItemTemplates/ActivityLinkTemplate';
import { TextFragment } from '../styles';
import { SurveyAttemptTrackable } from '../types';

const t = initTranslations('reports.activity_log');

export interface SurveyAttemptResultTemplateProps {
  activityKey: string;
  trackable: SurveyAttemptTrackable;
}

const ResultTemplate = ({ trackable, activityKey }: SurveyAttemptResultTemplateProps) => {
  const { slug } = useCurrentAccount();
  const { curriculum, survey } = trackable.associated_params;
  const isActiveTrackable = survey.is_active_survey && curriculum.is_active_curriculum;
  const surveyEditUrl = routes.surveyEditor({ slug, id: survey.survey_id }).href;
  const attemptUrl = isActiveTrackable
    ? routes.surveyByUserReport({
        slug,
        attemptId: trackable.id,
        surveyId: survey.survey_id,
        curriculumId: curriculum.curriculum_id,
      }).href
    : '#';

  return (
    <>
      <TextFragment>{t(activityKey)}</TextFragment>
      <SourceBadge
        readOnly={!isActiveTrackable}
        sourceHref={surveyEditUrl}
        sourceName='curriculum'
        sourceText={survey.survey_title}
      />
      <TextFragment>{t('survey_attempt.on')}</TextFragment>
      <ActivityLinkTemplate
        isActive={isActiveTrackable && trackable.is_active}
        linkText={t('survey_attempt.attempt', { position: trackable.position || 1 })}
        linkUrl={attemptUrl}
      />
      <TextFragment>{t('survey_attempt.with_score', { score: trackable.score })}</TextFragment>
    </>
  );
};

export default ResultTemplate;
