import { MouseEvent, useState } from 'react';

import useAnalyticsUserEvents from '../../../../hooks/useAnalyticsUserEvents';
import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import isBrowserSafariOrFirefox from '../../../../lib/isBrowserSafariOrFirefox';
import { messageFromError } from '../../../../redux/errors/helpers';
import { useTrainualUniversitySetupMutation } from '../../../../redux/services/resourceApis/accounts/accountsApi';
import routeTo from '../routeTo';

const useTrainualUniversity = (completeFunction?: () => void) => {
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [trainualUniversitySetup, trainualUniversitySetupResult] =
    useTrainualUniversitySetupMutation();
  const { resetAnalyticsUser } = useAnalyticsUserEvents();
  const { isLoading } = trainualUniversitySetupResult;

  const handleTrainualUniversitySetupSuccess = () => {
    resetAnalyticsUser();
    if (trainualUniversitySetupResult.data) {
      isBrowserSafariOrFirefox(navigator.userAgent)
        ? routeTo(trainualUniversitySetupResult.data.redirectUrl)
        : window.open(
            trainualUniversitySetupResult.data.redirectUrl,
            '_blank',
            'noopener,noreferrer'
          );
      setIsEnrolled(true);
    }

    completeFunction && completeFunction();
  };

  useDisplayFlashOnResponse({
    result: trainualUniversitySetupResult,
    successFunction: handleTrainualUniversitySetupSuccess,
    errorMessage: messageFromError(trainualUniversitySetupResult.error)?.join(', '),
    skip: isEnrolled,
    errorFunction: completeFunction,
  });

  const handleEnroll = (e?: MouseEvent<HTMLButtonElement>) => {
    isEnrolled && setIsEnrolled(false);
    trainualUniversitySetup();
    e?.currentTarget && e.currentTarget.blur();
  };

  return {
    isLoading,
    handleEnroll,
  };
};

export default useTrainualUniversity;
