import React, { ReactNode, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';

import initTranslations from '../../../../lib/initTranslations';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';

const CopyableContainerWrapper = styled.div`
  overflow: hidden;
  cursor: pointer;
`;

export interface Props {
  valueToCopy: string;
  toolTipId: string;
  children: ReactNode;
}

const t = initTranslations('copyable_container');

const CopyableContainer = ({ valueToCopy, toolTipId, children }: Props) => {
  const [valueCopied, setValueCopied] = useState(false);

  useEffect(() => {
    if (valueCopied) {
      setTimeout(() => setValueCopied(false), 3000);
    }
  }, [valueCopied]);

  return (
    <CopyableContainerWrapper>
      <Tooltip id={toolTipId} text={valueCopied ? t('copied') : t('copy')} />
      <CopyToClipboard onCopy={() => setValueCopied(true)} text={valueToCopy}>
        {children}
      </CopyToClipboard>
    </CopyableContainerWrapper>
  );
};

export default CopyableContainer;
