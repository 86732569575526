import React from 'react';

import { usePlanBrandColors } from '../../../../../../../hooks/billing/usePlanBrandColors';
import { usePlanTitle } from '../../../../../../../hooks/billing/usePlanTitle';
import { useRedirectToBillingOverlay } from '../../../../../../../hooks/useRedirectToBillingOverlay';
import { getFormattedPrice } from '../../../../../../../lib/getFormattedPrice';
import initTranslations from '../../../../../../../lib/initTranslations';
import PlansCardTabs, { PlansCardTabsProps } from '.././PlansCardTabs/PlansCardTabs';
import {
  PlansCardBillingInterval,
  PlansCardContentWrapper,
  PlansCardFooter,
  PlansCardPlanText,
  PlansCardPrice,
  PlansCardPriceWrapper,
  PlansCardText,
  PlansCardTitle,
  PlansCardTitleWrapper,
  PlansCardWrapper,
  StyledActivePlanIndicator,
  UpgradeButton,
} from './styles';

const t = initTranslations('simple_pricing.billing_page.plans_with_trainual_plus');

const PlansCard = ({ selectedPlan, plans, setSelectedPlanName }: PlansCardTabsProps) => {
  const { name, employeesSize, pricePerMonth, active, ctaKey, interval } = selectedPlan;
  const planTitle = usePlanTitle({ name });
  const { color: planBrandColor } = usePlanBrandColors({ name });
  const navigateToBillingOverlay = useRedirectToBillingOverlay();
  const isCustomPlan = name === 'custom';

  const getCardTitle = () => {
    return (
      <>
        {planTitle}
        <PlansCardPlanText>{t('plan')}</PlansCardPlanText>
      </>
    );
  };

  const handleClickUpgradePlan = () => {
    navigateToBillingOverlay({ plan: name, interval });
  };

  return (
    <PlansCardWrapper id='plans-card'>
      <PlansCardTabs
        plans={plans}
        selectedPlan={selectedPlan}
        setSelectedPlanName={setSelectedPlanName}
      />
      <PlansCardContentWrapper>
        <PlansCardTitleWrapper>
          <PlansCardTitle planBrandColor={planBrandColor} text={getCardTitle()} />
          <PlansCardText id='employees-size'>{t('employees', { employeesSize })}</PlansCardText>
        </PlansCardTitleWrapper>
        <PlansCardText dangerouslySetInnerHTML={{ __html: t('description') }} />
        <PlansCardFooter>
          {!isCustomPlan && (
            <PlansCardPriceWrapper>
              <PlansCardPrice>{getFormattedPrice(pricePerMonth)}</PlansCardPrice>
              <PlansCardBillingInterval>{t('per_month')}</PlansCardBillingInterval>
            </PlansCardPriceWrapper>
          )}

          {active ? (
            <StyledActivePlanIndicator>{t('cta.active_plan')}</StyledActivePlanIndicator>
          ) : (
            <UpgradeButton
              buttonType='brand'
              id={`upgrade-to-${name}-billing-plan`}
              onClick={handleClickUpgradePlan}
              size='md'
              text={t(`cta.${ctaKey}`)}
            />
          )}
        </PlansCardFooter>
      </PlansCardContentWrapper>
    </PlansCardWrapper>
  );
};

export default PlansCard;
