import React from 'react';

import { useGetAllCurriculumsColumns } from '../../../../../hooks/all_curriculums_report/useGetAllCurriculumsColumns';
import { BodyRow } from '../../../people/UsersOutline/Table/Styles';
import { StyledColumn } from '../../AllUsersReportPage/AllUsersReportTable/styles';
import TableColumn from './Columns/TableColumn';
import { TableRowProps } from './types';

const TableRow = ({ curriculum }: TableRowProps) => {
  const allCurriculumsColumns = useGetAllCurriculumsColumns();
  const { id } = curriculum;

  return (
    <>
      <BodyRow id={`all-curriculums-report-tr-${id}`}>
        {allCurriculumsColumns.map(({ columnName, contentAlignment }) => (
          <StyledColumn
            contentAlignment={contentAlignment}
            id={`all-curriculums-report-column-${id}-${columnName}`}
            key={`all-curriculums-report-column-${id}-${columnName}`}
          >
            <TableColumn columnName={columnName} record={curriculum} />
          </StyledColumn>
        ))}
      </BodyRow>
    </>
  );
};

export default TableRow;
