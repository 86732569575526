import React from 'react';

import { Activity, ActivityAssociationParams } from '../types';
import CommonTemplate, { GroupCommonTemplateProps } from './CommonTemplate';
import RecipientsManageTemplate, {
  GroupRecipientsManageTemplateProps,
} from './RecipientsManageTemplate';

interface GroupsActivitiesProps {
  'group.create': React.FC<GroupCommonTemplateProps>;
  'group.destroy': React.FC<GroupCommonTemplateProps>;
  'group.name_changed': React.FC<GroupCommonTemplateProps>;
  'group.description_changed': React.FC<GroupCommonTemplateProps>;
  'group.curriculums_added': React.FC<GroupRecipientsManageTemplateProps>;
  'group.curriculums_removed': React.FC<GroupRecipientsManageTemplateProps>;
  'group.users_added': React.FC<GroupRecipientsManageTemplateProps>;
  'group.users_removed': React.FC<GroupRecipientsManageTemplateProps>;
  'group.manager_added': React.FC<GroupRecipientsManageTemplateProps>;
  'group.manager_removed': React.FC<GroupRecipientsManageTemplateProps>;
}

const ACTIVITY_KEYS: GroupsActivitiesProps = {
  'group.create': CommonTemplate,
  'group.destroy': CommonTemplate,
  'group.name_changed': CommonTemplate,
  'group.description_changed': CommonTemplate,
  'group.curriculums_added': RecipientsManageTemplate,
  'group.curriculums_removed': RecipientsManageTemplate,
  'group.users_added': RecipientsManageTemplate,
  'group.users_removed': RecipientsManageTemplate,
  'group.manager_added': RecipientsManageTemplate,
  'group.manager_removed': RecipientsManageTemplate,
};

interface GroupsActivity extends Omit<Activity, 'trackable' | 'parameters'> {
  trackable: ActivityAssociationParams;
  parameters: { recipients_count: number; name_was: string; new_name: string };
}

interface GroupsCardProps {
  activity: GroupsActivity;
}

const GroupActivitiesDetails = ({ activity }: GroupsCardProps) => {
  const ActivityMessage = ACTIVITY_KEYS[activity.key as keyof GroupsActivitiesProps];

  return (
    <ActivityMessage
      activityKey={activity.key}
      parameters={activity.parameters}
      recipient={activity.recipient}
      recipientType={activity.recipient_type}
      trackable={activity.trackable}
    />
  );
};

export default GroupActivitiesDetails;
