import React from 'react';
import styled, { css } from 'styled-components';

import { TruncatedText } from '../../../design_system/Triage/TruncatedText';
import { forceCapitalization } from '../../../helpers/cssTranslation';
import { fontSm5 } from '../../../styled/TypeSystem';
import Tag from '../../display/Tag';

const TabsWrapper = styled.nav`
  overflow: auto hidden;
  white-space: nowrap;
`;

const StyledTabs = styled.ul`
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
`;

const TabItem = styled.li<{ isActive: boolean }>(
  ({ theme: { constants, vars }, isActive }) => css`
    a {
      color: ${isActive ? vars.textDefault : vars.textSubdued};
      font-weight: ${constants.fontSemibold};
      align-items: center;
      display: flex;
      ${fontSm5};
      padding: ${constants.spacerSm3} 0;

      cursor: pointer;
      text-decoration: none;
      &:hover,
      &:focus {
        color: ${vars.textDefault};
      }
      max-width: 165px;
    }
    margin-right: ${constants.spacerMd2};
    display: flex;
    border-bottom: ${isActive
      ? `${constants.borderWidthLg} solid ${vars.accentPrimaryDefault}`
      : ''};

    ${TruncatedText({})};
    ${forceCapitalization};
  `
);

export type Tab = {
  count?: string;
  name: string;
  is_beta?: boolean;
  tabId: string;
};

export type Props = {
  id?: string;
  tabs: Tab[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
};

const Tabs = ({ activeTab, id, setActiveTab, tabs }: Props) => {
  if (tabs.length <= 1) return <></>;
  return (
    <TabsWrapper id={id}>
      <StyledTabs>
        {tabs.map(({ name, tabId, is_beta = false }, index) => {
          return (
            <TabItem
              id={tabId}
              isActive={activeTab == tabId}
              key={`${name}-${index}`}
              onClick={() => setActiveTab(tabId)}
            >
              <a>
                {name}
                {is_beta && <Tag />}
              </a>
            </TabItem>
          );
        })}
      </StyledTabs>
    </TabsWrapper>
  );
};

export default Tabs;
