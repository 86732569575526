import styled from 'styled-components';

import Icon from '../../design_system/display/icons/Icon';
import { mediaBreakpointPxSm } from '../../styled/Breakpoint';
import { fontSm5 } from '../../styled/TypeSystem';
import RouterLink from '../publicApplication/RouterLink';

export const StyledSortIcon = styled(Icon)`
  margin: 0 0 ${({ theme: { constants } }) => constants.spacerSm1}
    ${({ theme: { constants } }) => constants.spacerSm2};
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

export const TabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1.875rem;

  @media (max-width: ${mediaBreakpointPxSm}) {
    flex-wrap: wrap-reverse;
  }
`;

export const FiltersWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ReportTableWrapper = styled.div`
  min-height: 22rem;
`;

export const AvatarWrapper = styled(RouterLink)`
  padding-right: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const ActivityLogFilterMenuOption = styled.span`
  ${fontSm5};
`;

export const SelectedActivityLogFilterMenuOption = styled(ActivityLogFilterMenuOption)`
  padding: 0 ${({ theme: { constants } }) => constants.spacerSm3};
`;
