import { css } from 'styled-components';

import { ButtonType } from '../../DefaultButton/ButtonTypes';

const DropdownButtonDisabledState = css`
  &:disabled {
    background-color: ${({ theme: { vars } }) => vars.foundationBase3};
    color: ${({ theme: { vars } }) => vars.textDisabled};
    border-color: ${({ theme: { vars } }) => vars.foundationBase3};
  }
`;

const PrimaryDropdownButton = css`
  background-color: ${({ theme: { vars } }) => vars.accentPrimaryPressed};
  color: ${({ theme: { vars } }) => vars.foundationSurface1};

  &:hover,
  &:focus {
    color: ${({ theme: { vars } }) => vars.foundationSurface1};
    background-color: ${({ theme: { vars } }) => vars.foundationSurface2};
    border: ${({ theme: { constants, vars } }) =>
      `${constants.borderWidthSm} solid ${vars.accentSubdued4}`};
  }

  ${DropdownButtonDisabledState};
`;

const SecondaryDropdownButton = css`
  background-color: ${({ theme: { vars } }) => vars.accentSubdued2};
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};

  &:hover,
  &:focus {
    background-color: ${({ theme: { vars } }) => vars.accentSubdued1};
    border: ${({ theme: { constants, vars } }) =>
      `${constants.borderWidthSm} solid ${vars.accentPrimaryDefault}`};
  }

  ${DropdownButtonDisabledState};
`;

const TertiaryDropdownButton = css`
  background-color: ${({ theme: { vars } }) => vars.foundationBase2};
  color: ${({ theme: { vars } }) => vars.textDefault};

  &:hover,
  &:focus {
    border: ${({ theme: { constants, vars } }) =>
      `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  }

  ${DropdownButtonDisabledState};
`;

const BrandDropdownButton = css`
  background-color: ${({ theme: { vars } }) => vars.trainualBrandBlueStrong};
  color: ${({ theme: { vars } }) => vars.textSurface};

  &:hover,
  &:focus {
    color: ${({ theme: { vars } }) => vars.textSurface};
    background-color: ${({ theme: { vars } }) => vars.trainualBrandGreenSubdued};
  }

  ${DropdownButtonDisabledState};
`;

const PrimaryLoader = css`
  background-color: ${({ theme: { vars } }) => vars.accentPrimaryPressed};
  color: ${({ theme: { vars } }) => vars.foundationSurface1};
`;

const SecondaryLoader = css`
  background-color: ${({ theme: { vars } }) => vars.accentSubdued2};
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
`;

const TertiaryLoader = css`
  background-color: ${({ theme: { vars } }) => vars.foundationBase3};
  color: ${({ theme: { vars } }) => vars.accentSubdued3};
`;

const BrandLoader = css`
  background-color: ${({ theme: { vars } }) => vars.trainualBrandBlueStrong};
  color: ${({ theme: { vars } }) => vars.textSurface};
`;

export const getDropdownButtonStyles = (buttonType: ButtonType) => {
  switch (buttonType) {
    case 'tertiary':
      return TertiaryDropdownButton;
    case 'secondary':
      return SecondaryDropdownButton;
    case 'primary':
      return PrimaryDropdownButton;
    case 'brand':
      return BrandDropdownButton;
  }
};

export const getLoaderStyles = (buttonType: ButtonType) => {
  switch (buttonType) {
    case 'tertiary':
      return TertiaryLoader;
    case 'secondary':
      return SecondaryLoader;
    case 'primary':
      return PrimaryLoader;
    case 'brand':
      return BrandLoader;
  }
};
