import styled from 'styled-components';

import Icon from '../../../design_system/display/icons/Icon';
import { fontSm5 } from '../../../styled/TypeSystem';

export const DurationTimeSetterWrapper = styled.div`
  display: flex;
  height: 1.875rem;
  width: fit-content;
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderDefault}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};

  > * {
    border-radius: inherit;
  }
`;

export const DurationTimeSetterValue = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  padding: 0.281rem ${({ theme: { constants } }) => constants.spacerSm3} 0.375rem;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};

  input[type='number']:focus + .input-dash-overlay,
  &:focus-within .input-dash-overlay {
    opacity: 0;
  }
`;

export const DurationFrequencyBox = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerMd1};
  width: 4.5rem;
  background-color: ${({ theme: { vars } }) => vars.foundationBase2};
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderDefault}`};
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textSubdued};
`;

export const HoursInput = styled.input`
  width: ${({ theme: { constants } }) => constants.spacerLg2};
  height: 1.31rem;
  border: none;
  outline: none;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  color: ${({ theme: { vars } }) => vars.textDefault};
  text-align: right;

  ${fontSm5};
  &::placeholder {
    color: ${({ theme: { vars } }) => vars.textPlaceholder};
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
`;

export const HoursInputDashOverlay = styled.div`
  position: absolute;
  top: 10%;
  right: 15%;
  bottom: 0;
  pointer-events: none;
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
`;
