import React from 'react';
import styled from 'styled-components';

import useWindowResize from '../../../../../../hooks/useWindowResize';
import LoadingContainer from '../../../../../design_system/LoadingContainer';
import { mediaBreakpointSm } from '../../../../../styled/Breakpoint';
import { FunctionProps } from './interfaces';
import { PillName, PillText, StyledIcon, sharedPillButton } from './styles';

const SuggestedPillsDiv = styled.div`
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  background-color: 'unset';
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} 0`};
`;

const EmptySectionText = styled.p`
  text-align: center;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const SuggestedPillButton = styled.button`
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants } }) => constants.borderRadiusXs} solid
    ${({ theme: { vars } }) => vars.textSubdued};
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${sharedPillButton};

  &:hover,
  &:focus {
    background-color: ${({ theme: { vars } }) => vars.foundationBase2};
  }
`;

interface Props extends FunctionProps {
  dataIsLoading: boolean;
  emptyText: string;
  maxMobileCount?: number;
  maxDesktopCount?: number;
}

const UnselectedPills = ({
  emptyText,
  resource,
  suggestions,
  onModify,
  dataIsLoading,
  maxMobileCount = 5,
  maxDesktopCount = 8,
}: Props) => {
  const { width } = useWindowResize();
  const maxSuggestionCount = width <= mediaBreakpointSm ? maxMobileCount : maxDesktopCount;

  if (dataIsLoading) {
    return (
      <SuggestedPillsDiv>
        <LoadingContainer />
      </SuggestedPillsDiv>
    );
  }

  return (
    <SuggestedPillsDiv className='suggested-pill-section'>
      {suggestions.length === 0 ? (
        <EmptySectionText>{emptyText}</EmptySectionText>
      ) : (
        suggestions
          .sort((suggestion, nextSuggestion) =>
            suggestion.frequency <= nextSuggestion.frequency ? 1 : -1
          )
          .slice(0, maxSuggestionCount)
          .map((suggestion) => {
            return (
              <SuggestedPillButton
                className='suggested-pill-button'
                key={`suggested-${resource}-${suggestion.id}`}
                onClick={() => onModify(suggestion.id)}
              >
                <PillText>
                  <PillName>{suggestion.name}</PillName> <StyledIcon name='plus' />
                </PillText>
              </SuggestedPillButton>
            );
          })
      )}
    </SuggestedPillsDiv>
  );
};

export default UnselectedPills;
