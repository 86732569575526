import { LoadingSize } from '../../../people/UsersOutline/Table/Column';
import { Column } from '../../../people/UsersOutline/Table/TableTypes';
import { AllUsersReportColumn } from './types';

export interface TableColumn extends Omit<Column, 'columnName'> {
  columnName: AllUsersReportColumn;
}

export const columns: TableColumn[] = [
  { columnName: 'name', loadingSize: LoadingSize.large, sortable: true },
  { columnName: 'completion_percentage', loadingSize: LoadingSize.medium, sortable: true },
  { columnName: 'groups', loadingSize: LoadingSize.medium, sortable: false },
  { columnName: 'last_active_at', loadingSize: LoadingSize.medium, sortable: true },
  { columnName: 'actions', loadingSize: LoadingSize.medium, sortable: false },
];
