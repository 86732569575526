import { kebabCase } from 'lodash';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';
import { TooltipIconWrapper, TooltipStyledIcon } from '../../../per_user_pricing/shared/styles';
import { TooltipWrapper } from './styles';

type AddonAlreadyIncludedProps = {
  name: string;
};

const t = initTranslations('billing.addons_section');

const AddonAlreadyIncluded = ({ name }: AddonAlreadyIncludedProps) => {
  const tooltipId = `${kebabCase(name)}-tooltip`;

  return (
    <>
      <TooltipIconWrapper data-for={tooltipId} data-tip>
        <TooltipStyledIcon name='circle-info' weight='regular' />
      </TooltipIconWrapper>
      <Tooltip
        content={<TooltipWrapper>{t('add_on_already_included', { name })}</TooltipWrapper>}
        id={tooltipId}
        place='bottom'
      />
    </>
  );
};

export default AddonAlreadyIncluded;
